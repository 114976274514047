import { Box, Button, Stack } from "@mui/material";

import LoadingButton from "../../../../Components/LoadingButton";
import { text } from "../../consts";

type Props = {
  accessKey?: string | null;
  loadingDelete: boolean;
  loadingGenerate: boolean;
  actionsDisabledForNonOwners: boolean;
  handleGenerateKey: () => Promise<void>;
  generatedToken: string;
  toggleDeleteDialogue: () => void;
  handleClose?: () => void;
};

export const FooterActionsModal = ({
  accessKey,
  loadingDelete,
  loadingGenerate,
  actionsDisabledForNonOwners,
  handleClose,
  handleGenerateKey,
  generatedToken,
  toggleDeleteDialogue,
}: Props) => (
  <Box sx={{ padding: 1 }}>
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        flexDirection: accessKey ? "row" : "row-reverse",
      }}
    >
      {!!accessKey && (
        <LoadingButton
          variant="text"
          color="error"
          loading={loadingDelete}
          onClick={toggleDeleteDialogue}
          disabled={loadingGenerate || actionsDisabledForNonOwners}
          data-cy="delete-api-key"
          mixpanelEventId="user-view.apiKey.delete"
        >
          {text.API_TAB_DELETE_BUTTON_TEXT}
        </LoadingButton>
      )}

      <Box>
        <Button
          variant={generatedToken ? "contained" : "text"}
          color="primary"
          data-cy="close-modal"
          onClick={handleClose}
        >
          {generatedToken ? "Done" : "Close"}
        </Button>

        {!accessKey && (
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loadingGenerate}
            onClick={handleGenerateKey}
            disabled={loadingDelete || actionsDisabledForNonOwners}
            data-cy="generate-api-key"
            mixpanelEventId="user-view.apiKey.generate"
          >
            {text.API_TAB_GENERATE_BUTTON_TEXT}
          </LoadingButton>
        )}
      </Box>
    </Stack>
  </Box>
);
