import { useMemo } from "react";

import { CurrencyCodes } from "@doitintl/cmp-models";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import MultiCoupletCard, { type MultiCoupletContentData } from "../../../Components/CountUp/MultiCoupletCard";
import { formatCurrency } from "../../../utils/common";
import { type MpaTableRowData } from "../table/MpaTableRow";

type Props = {
  masterPayerAccounts: MpaTableRowData[];
  selectedMonth: string;
  masterPayerAccountsLoading: boolean;
};

export const cyIds = {
  cards: {
    billingIssues: "mpa-cards-billing-issues",
    status: "mpa-cards-status",
    supportTiers: "mpa-cards-support-tiers",
  },
  controls: {
    month: "mpa-cards-controls-month",
  },
};

export const formatMonth = (month: string) => {
  const date = DateTime.fromISO(month, { zone: "utc" });
  return date.toUTC().toFormat("MMMM yyyy");
};

const MpaHeaderCards = ({ masterPayerAccounts, masterPayerAccountsLoading, selectedMonth }: Props) => {
  const billingIssuesData = useMemo<MultiCoupletContentData>(() => {
    const nonPendingMpas = masterPayerAccounts.filter((mpa) => mpa.status !== "pending");
    const revenues = nonPendingMpas.map((mpa) => mpa.revenuesByMonth[selectedMonth]);
    const { ppaShortfall, edpShortfall, supportShortfall } = revenues.reduce(
      (acc, revenue) => {
        if (revenue?.ppa && revenue.ppa < 0) {
          acc.ppaShortfall += revenue.ppa;
        }
        if (revenue?.edp && revenue.edp < 0) {
          acc.edpShortfall += revenue.edp;
        }
        if (revenue?.support && revenue.support < 0) {
          acc.supportShortfall += revenue.support;
        }

        return acc;
      },
      { ppaShortfall: 0, edpShortfall: 0, supportShortfall: 0 }
    );

    const ppaIssues = revenues.filter((revenue) => (revenue?.ppa ? revenue.ppa < 0 : false)).length;
    const edpIssues = revenues.filter((revenue) => (revenue?.edp ? revenue.edp < 0 : false)).length;
    const supportIssues = revenues.filter((revenue) => (revenue?.support ? revenue.support < 0 : false)).length;

    return {
      "PPA issues:": { value: ppaIssues, loading: masterPayerAccountsLoading },
      "EDP issues:": { value: edpIssues, loading: masterPayerAccountsLoading },
      "Support issues:": { value: supportIssues, loading: masterPayerAccountsLoading },
      "Margin shortfall:": {
        value: (
          <Typography variant="body2" component="span">
            {formatCurrency(Math.abs(ppaShortfall + edpShortfall + supportShortfall), CurrencyCodes.USD)}
          </Typography>
        ),
        loading: masterPayerAccountsLoading,
      },
    };
  }, [masterPayerAccounts, masterPayerAccountsLoading, selectedMonth]);

  const mpaStatusData = useMemo<MultiCoupletContentData>(() => {
    const pending = masterPayerAccounts.filter((mpa) => mpa.status === "pending").length;
    const active = masterPayerAccounts.filter((mpa) => mpa.status === "active").length;
    const retired = masterPayerAccounts.filter((mpa) => mpa.status === "retired").length;

    return {
      "Total MPAs:": { value: pending + active + retired, loading: masterPayerAccountsLoading },
      "Pending:": { value: pending, loading: masterPayerAccountsLoading },
      "Active:": { value: active, loading: masterPayerAccountsLoading },
      "Retired:": { value: retired, loading: masterPayerAccountsLoading },
    };
  }, [masterPayerAccounts, masterPayerAccountsLoading]);

  const supportTiersData = useMemo<MultiCoupletContentData>(() => {
    const basic = masterPayerAccounts.filter((mpa) => mpa.supportTier === "basic").length;
    const developer = masterPayerAccounts.filter((mpa) => mpa.supportTier === "developer").length;
    const business = masterPayerAccounts.filter((mpa) => mpa.supportTier === "business").length;
    const enterprise = masterPayerAccounts.filter((mpa) => mpa.supportTier === "enterprise").length;

    return {
      "Basic:": { value: basic, loading: masterPayerAccountsLoading },
      "Developer:": { value: developer, loading: masterPayerAccountsLoading },
      "Business:": { value: business, loading: masterPayerAccountsLoading },
      "Enterprise:": { value: enterprise, loading: masterPayerAccountsLoading },
    };
  }, [masterPayerAccounts, masterPayerAccountsLoading]);

  return (
    <Box sx={{ mt: 2 }}>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid size={4}>
          <MultiCoupletCard
            title={
              <Typography
                variant="inherit"
                component="span"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                }}
              >
                {`Billing issues (${formatMonth(selectedMonth)})`}
              </Typography>
            }
            contentData={billingIssuesData}
            data-cy={cyIds.cards.billingIssues}
          />
        </Grid>
        <Grid size={4}>
          <MultiCoupletCard
            title={
              <Typography
                variant="inherit"
                component="span"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                }}
              >
                MPA status
              </Typography>
            }
            contentData={mpaStatusData}
            data-cy={cyIds.cards.status}
          />
        </Grid>
        <Grid size={4}>
          <MultiCoupletCard
            title={
              <Typography
                variant="inherit"
                component="span"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "medium",
                }}
              >
                Support tiers
              </Typography>
            }
            contentData={supportTiersData}
            data-cy={cyIds.cards.supportTiers}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default MpaHeaderCards;
