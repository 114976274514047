import { DoitRole } from "@doitintl/cmp-models";
import { Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useDoitRoleCheck } from "../../../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { CustomerEditPackagePreferencesBtn } from "./CustomerEditPackagePreferencesBtn";
import { DeleteCustomer } from "./DeleteCustomer";
import { SetPresentationModeBtn } from "./SetPresentationModeBtn";

export function CustomerActionButtons() {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isCustomerSettingsAdmin = useDoitRoleCheck(DoitRole.CustomerSettingsAdmin);
  const { customer } = useCustomerContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTrialCustomer = !!customer.trialEndDate;

  if (!isDoitEmployee) {
    return null;
  }

  return (
    <Stack
      spacing={2}
      direction={isMobile ? "column" : "row"}
      sx={{
        alignItems: "flex-start",
      }}
    >
      {!isTrialCustomer && isCustomerSettingsAdmin && <SetPresentationModeBtn />}
      {isCustomerSettingsAdmin && <DeleteCustomer />}
      <CustomerEditPackagePreferencesBtn />
    </Stack>
  );
}
