import { type PointOfSaleType } from "@doitintl/cmp-models";
import { type InputProps as StandardInputProps } from "@mui/material/Input/Input";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { type TextFieldProps } from "@mui/material/TextField/TextField";

import { pointOfSaleMap } from "../../const";

type Props = TextFieldProps & {
  value?: PointOfSaleType;
  onChange?: StandardInputProps["onChange"];
};

const PointOfSaleSelect = (props: Props) => {
  const { value, onChange, ...otherProps } = props;

  return (
    <TextField
      select
      label="Point of Sale"
      variant="outlined"
      data-cy="point-of-sale-select"
      value={value ?? ""}
      onChange={onChange}
      {...otherProps}
      slotProps={{
        select: {
          MenuProps: {
            MenuListProps: {
              dense: true,
            },
          },
        },
      }}
    >
      {pointOfSaleMap.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default PointOfSaleSelect;
