import { useCallback } from "react";

import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useAsyncLoadingFunction } from "../../../Components/hooks/useAsyncLoadingFunction";
import LoadingButton from "../../../Components/LoadingButton";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { CloudBox } from "../../Customer/SaaSConsoleOnboarding/Common/CloudSelector/CloudBox";
import useCloudSelection from "../../Customer/SaaSConsoleOnboarding/Common/CloudSelector/hooks";
import { updateCustomerCloudProviders } from "../../Customer/SaaSConsoleOnboarding/Common/db";

const cloudProviderOptions = [SaaSConsoleType.GCP, SaaSConsoleType.AWS, SaaSConsoleType.AZURE];

export const CloudSelector = () => {
  const { customer } = useCustomerContext();
  const { selectedCloudProviders, handleCloudSelection } = useCloudSelection([]);

  const [isLoading, onNextClick] = useAsyncLoadingFunction(
    useCallback(
      () => updateCustomerCloudProviders(customer.id, selectedCloudProviders),
      [customer.id, selectedCloudProviders]
    )
  );

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
            pb: 1,
          }}
        >
          Which cloud providers do you use?
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
          }}
        >
          This will let us tailor your onboarding experience
        </Typography>
      </Grid>
      {cloudProviderOptions.map((cloud) => (
        <Grid
          key={cloud}
          size={{
            xs: 12,
            sm: 6,
            md: 4,
          }}
        >
          <CloudBox
            cloud={{ type: cloud }}
            selected={selectedCloudProviders.includes(cloud)}
            onClick={handleCloudSelection}
            sx={{ maxWidth: "unset" }}
          />
        </Grid>
      ))}
      <Grid size={12}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={onNextClick}
          disabled={selectedCloudProviders.length === 0}
          mixpanelEventId="home.cloud-selector.next"
        >
          Next
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
