import { memo, useEffect, useRef, useState } from "react";

import { Box, TextField, useTheme } from "@mui/material";

const styles = {
  titleInput: {
    maxWidth: "100%",
    minWidth: 10,
    width: { xs: 1, md: "auto" },

    ".MuiOutlinedInput-root": {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "general.outlineBorder",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary.main",
      },
    },
  },
  inputText: {
    position: "absolute",
    opacity: 0,
    zIndex: "-100",
    whiteSpace: "pre",
    maxWidth: "100%",
  },
};

type Props = {
  title?: string;
  placeholder: string;
  disabled?: boolean;
  onUpdateTitle: (name: string) => void;
  onBlur: () => void;
};

const Title = ({ title, placeholder, onUpdateTitle, disabled, onBlur }: Props) => {
  const [cursor, setCursor] = useState<number | null>(null);
  const [inputWidth, setInputWidth] = useState(1);
  const inputTextRef = useRef<HTMLElement>();
  const inputRef = useRef<HTMLInputElement>(null);

  const theme = useTheme();

  useEffect(() => {
    const offsetWidth = inputTextRef.current?.offsetWidth;
    setInputWidth(!offsetWidth ? 5 : offsetWidth + 1);
  }, [title, inputTextRef.current?.offsetWidth]);

  useEffect(() => {
    inputRef.current?.setSelectionRange(cursor, cursor);
  }, [inputRef, cursor, title]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart);
    onUpdateTitle(e.target.value);
  };

  return (
    <TextField
      inputRef={inputRef}
      name="title"
      variant="outlined"
      value={title}
      disabled={disabled}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={onBlur}
      sx={{
        ...styles.titleInput,
        ".MuiOutlinedInput-input": {
          width: { xs: inputWidth },
          maxWidth: 350,
          fontWeight: theme.typography.h4.fontWeight,
        },
      }}
      slotProps={{
        input: {
          endAdornment: (
            <Box component="span" ref={inputTextRef} sx={styles.inputText}>
              {title || placeholder}
            </Box>
          ),
        },
      }}
    />
  );
};

// TODO: Investigate the cause of infinite re-renders when adding a new node in the cloud flow.
const MemoizedTitle = memo(Title);
export default MemoizedTitle;
