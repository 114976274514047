import { type ReactNode, type SyntheticEvent, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { type SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useAuthContext } from "../../../Context/AuthContext";
import mixpanel from "../../../utils/mixpanel";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { useInsights } from "../api";
import { useInsightsContext } from "../context";
import { NotFound } from "../NotFound/NotFound";
import {
  ALL_CATEGORIES,
  ALL_PROVIDERS,
  type BreakdownData,
  type Category,
  type Insight,
  type InsightsListViewedProperties,
  type ProviderTag,
  type SavingsPeriod,
} from "../types";
import { calculateTotalSavingsForTimePeriod, isOptimized, sortByTotalPotentialDailySavings } from "../utils";
import { InsightsListRow } from "./InsightsListRow";
import { InsightsListRowUpsell } from "./InsightsListRowUpsell";

const VALUE_ASCENDING = "ascending";
const VALUE_DESCENDING = "descending";
const CREATE_CUSTOM_INSIGHT = "Create new insight";

function capitalizeProvider(provider: string): string {
  const capitalizationMap: Record<string, string> = {
    aws: "AWS",
    gcp: "Google Cloud",
    azure: "Azure",
    snowflake: "Snowflake",
  };
  return capitalizationMap[provider] || "";
}

const SOURCE_MAP = {
  core: "DoiT Cloud Intelligence™",
  custom: "DoiT experts",
  "bq-lens": "BigQuery Lens",
  other: "Other",
};

interface Filters {
  providers: ProviderTag[];
  categories: Category[];
  sources: string[];
}

interface FilterConfig {
  title: string;
  items: string[];
  filterKey: keyof Filters;
  getLabel: (item: string) => string;
  filterFunction: (insight: Insight, item: string) => boolean;
}

function FilterSection({
  config,
  filters,
  handleFilterChange,
  insights,
}: {
  config: FilterConfig;
  filters: Filters;
  handleFilterChange: (category: keyof Filters, value: string) => void;
  insights: Insight[] | undefined;
}) {
  const { title, items, filterKey, getLabel, filterFunction } = config;
  const currentFilterValues = filters[filterKey].map(String);
  return (
    <Box key={title} sx={{ mb: 3 }}>
      <Typography
        variant="subtitle2"
        sx={{
          mb: 1,
        }}
      >
        {title}
      </Typography>
      <FormGroup>
        {items.map((item) => (
          <FormControlLabel
            key={item}
            control={
              <Checkbox
                size="small"
                checked={currentFilterValues.includes(item)}
                onChange={() => {
                  handleFilterChange(filterKey, item);
                }}
              />
            }
            label={
              <Typography variant="body2" component="span">
                {getLabel(item)}{" "}
                <Typography variant="body2" component="span" color="textSecondary">
                  ({insights?.filter((insight) => filterFunction(insight, item)).length ?? 0})
                </Typography>
              </Typography>
            }
            sx={{ mb: 1 }}
          />
        ))}
      </FormGroup>
    </Box>
  );
}

function PageWrapper({ children }: { children: ReactNode }) {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          mt: 6,
          mb: 4,
        }}
      >
        <Typography
          data-cy="insights-title"
          variant="h1"
          sx={{
            mb: 1,
          }}
        >
          Insights
        </Typography>
        <Typography
          data-cy="insights-description"
          variant="body1"
          color="textSecondary"
          sx={{
            fontWeight: 400,
            mb: 6,
          }}
        >
          Uncover optimization opportunities based on your cloud billing data.
        </Typography>
        {children}
      </Box>
    </Container>
  );
}

export function InsightsList() {
  const { insights, isFetching, nonEntitledSummary } = useInsights();

  const { isDoitEmployee } = useAuthContext();
  const theme = useTheme();
  const [sortOrder, setSortOrder] = useState(VALUE_DESCENDING);
  const { savingsPeriod, setSavingsPeriod } = useInsightsContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [filters, setFilters] = useState<Filters>({
    providers: [],
    categories: [],
    sources: [],
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [isFirstRender, setIsFirstRender] = useState(true);

  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();

  const { trackEvent } = useSegmentTrackEvent();

  const createNewInsight = () => {
    history.push(`${routeMatchURL}/new-insight`);
  };

  useEffect(() => {
    mixpanel.track("insights.list.open");
    setIsFirstRender(false);
  }, []);

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const changeSavingsPeriod = (event: SelectChangeEvent) => {
    const newValue = event.target.value as SavingsPeriod;
    mixpanel.track("insights.list.change-savings-period", {
      oldValue: savingsPeriod,
      newValue,
    });
    setSavingsPeriod(newValue);
  };

  const changeSortOrder = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    mixpanel.track("insights.list.change-sort-order", {
      oldValue: sortOrder,
      newValue,
    });
    setSortOrder(newValue);
  };

  const handleFilterChange = (category: keyof Filters, value: string) => {
    setFilters((prev) => {
      const currentValues = prev[category] as string[];
      const index = currentValues.indexOf(value);
      const newValues = index === -1 ? [...currentValues, value] : currentValues.filter((item) => item !== value);
      return {
        ...prev,
        [category]: newValues,
      };
    });
  };

  const allSources = useMemo(() => {
    const sourceSet = new Set<string>(insights?.map((insight) => SOURCE_MAP[insight.providerId] || SOURCE_MAP.other));
    return Array.from(sourceSet);
  }, [insights]);

  const FILTER_CONFIGS = useMemo<FilterConfig[]>(
    () => [
      {
        title: "Provider",
        items: ALL_PROVIDERS,
        filterKey: "providers",
        getLabel: capitalizeProvider,
        filterFunction: (insight, item) => insight.cloudTags.includes(item as ProviderTag),
      },
      {
        title: "Category",
        items: ALL_CATEGORIES,
        filterKey: "categories",
        getLabel: (item) => item,
        filterFunction: (insight, item) =>
          insight.categories?.some((cat) => cat.toLowerCase() === item.toLowerCase()) ?? false,
      },
      {
        title: "Source",
        items: allSources,
        filterKey: "sources",
        getLabel: (item) => item,
        filterFunction: (insight, item) => (SOURCE_MAP[insight.providerId] || SOURCE_MAP.other) === item,
      },
    ],
    [allSources]
  );

  const filteredInsights = insights?.filter((insight) => insight.status === "success") || [];
  const sortedInsights = sortByTotalPotentialDailySavings(filteredInsights, sortOrder === VALUE_ASCENDING);

  interface TabConfig {
    label: string;
    filterFunction: (insight: Insight) => boolean;
  }

  const TAB_CONFIGS = useMemo<TabConfig[]>(
    () => [
      {
        label: "All",
        filterFunction: () => true,
      },
      {
        label: "Actionable",
        filterFunction: (insight) =>
          (insight.results?.isRelevant && !isOptimized(insight) && insight.userStatusChanges?.status !== "dismissed") ??
          false,
      },
      {
        label: "Optimized",
        filterFunction: (insight) => isOptimized(insight),
      },
      {
        label: "Dismissed",
        filterFunction: (insight) => !insight.results?.isRelevant || insight.userStatusChanges?.status === "dismissed",
      },
    ],
    []
  );

  const insightsByTab = useMemo(
    () => TAB_CONFIGS.map((tabConfig) => sortedInsights.filter(tabConfig.filterFunction)),
    [sortedInsights, TAB_CONFIGS]
  );

  const nonEntitledDailySavings = nonEntitledSummary?.potentialDailySavings.value || 0;
  const customProblems = nonEntitledSummary?.customProblems.numberOfActionableInsights || 0;
  const potentialDailySaving = nonEntitledSummary?.potentialDailySavings.numberOfActionableInsights || 0;
  const nonEntitledTotalInsights = customProblems + potentialDailySaving;

  const selectedInsights: (Insight | { isUpsell: true })[] = useMemo(() => {
    const selected = insightsByTab[selectedTab]
      .filter((insight) => {
        const searchText = searchQuery.toLowerCase();
        return (
          insight.title.toLowerCase().includes(searchText) ||
          insight.shortDescription.toLowerCase().includes(searchText) ||
          insight.detailedDescriptionMdx.toLowerCase().includes(searchText)
        );
      })
      .filter((insight) =>
        FILTER_CONFIGS.every(({ filterKey, filterFunction }) => {
          const currentFilterValues = filters[filterKey].map(String);
          if (currentFilterValues.length === 0) return true;
          return currentFilterValues.some((item) => filterFunction(insight, item));
        })
      );

    return selected;
  }, [insightsByTab, selectedTab, searchQuery, FILTER_CONFIGS, filters]);

  const totalSavings = useMemo(
    () => calculateTotalSavingsForTimePeriod(selectedInsights as Insight[], savingsPeriod),
    [selectedInsights, savingsPeriod]
  );

  const insightsHighestSavings = useMemo(
    () =>
      (selectedInsights as Insight[]).reduce((max, insight) => {
        const dailySavings =
          insight.results?.potentialDailySavings?.breakdown?.data.reduce(
            (sum: number, dataItem: BreakdownData) => sum + dataItem.value,
            0
          ) || 0;
        const totalSavings = dailySavings * (savingsPeriod === "daily" ? 1 : savingsPeriod === "monthly" ? 30 : 365);
        return Math.max(max, totalSavings);
      }, 0),
    [selectedInsights, savingsPeriod]
  );

  const insightsOrderOnSelectedTab = useMemo(
    () => (selectedInsights as Insight[]).map((insight) => insight.key),
    [selectedInsights]
  );

  const customInsightsCount = useMemo(
    () => (selectedInsights as Insight[]).filter((insight) => insight.providerId === "custom").length,
    [selectedInsights]
  );

  useEffect(() => {
    if (isFetching || !insights) {
      return;
    }

    const eventProperties: InsightsListViewedProperties = {
      pageType: "Governance",
      pageSubType: "Insights",
      feature: "Insights",
      isPageOpenEvent: isFirstRender,
      insightsSortOrder: sortOrder,
      insightsSavingsPeriod: savingsPeriod,
      insightsFiltersSelected: [...filters.providers, ...filters.categories, ...filters.sources],
      insightsSearchTerms: searchQuery,
      selectedInsightsCount: selectedInsights.length,
      customInsightsCount,
      insightsTotalSavings: totalSavings.toString(),
      insightsHighestSavings,
      insightsOrderOnSelectedTab,
      actionableInsights: insightsByTab[1].length,
      optimizedInsights: insightsByTab[2].length,
      dismissedInsights: insightsByTab[3].length,
      selectedInsightsTab: TAB_CONFIGS[selectedTab].label,
    };

    trackEvent("Insight List Viewed", eventProperties);
  }, [
    isFetching,
    insights,
    sortOrder,
    savingsPeriod,
    filters,
    searchQuery,
    selectedTab,
    FILTER_CONFIGS,
    isFirstRender,
    selectedInsights,
    customInsightsCount,
    totalSavings,
    insightsHighestSavings,
    insightsOrderOnSelectedTab,
    insightsByTab,
    TAB_CONFIGS,
    trackEvent,
  ]);

  if (isFetching) {
    return (
      <PageWrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
            width: "100%",
          }}
        >
          <SyncLoader size={10} color={theme.palette.primary.main} loading />
        </Box>
      </PageWrapper>
    );
  }

  if (!insights?.length) {
    return (
      <PageWrapper>
        <NotFound />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Box sx={{ width: { xs: "100%", md: 250 }, mb: { xs: 3, md: 0 }, mr: { md: 3 } }}>
          {FILTER_CONFIGS.map((config) => (
            <FilterSection
              key={config.title}
              config={config}
              filters={filters}
              handleFilterChange={handleFilterChange}
              insights={insights}
            />
          ))}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{
              alignItems: { xs: "flex-start", md: "center" },
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search insights..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              sx={{ flex: 1, minWidth: 250, maxWidth: 500 }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: "center",
                mt: { xs: 2, md: 0 },
              }}
            >
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Savings period</InputLabel>
                <Select label="Savings period" value={savingsPeriod} onChange={changeSavingsPeriod}>
                  <MenuItem value="yearly">Yearly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Sort by</InputLabel>
                <Select label="Sort by" value={sortOrder} onChange={changeSortOrder}>
                  <MenuItem value={VALUE_DESCENDING}>Value high to low</MenuItem>
                  <MenuItem value={VALUE_ASCENDING}>Value low to high</MenuItem>
                </Select>
              </FormControl>
              {isDoitEmployee && (
                <Button size="large" variant="contained" color="primary" onClick={createNewInsight}>
                  {CREATE_CUSTOM_INSIGHT}
                </Button>
              )}
            </Stack>
          </Stack>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Insights Categories"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              minHeight: "48px",
              marginBottom: 3,
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            {TAB_CONFIGS.map((tabConfig, index) => (
              <Tab
                key={tabConfig.label}
                label={`${tabConfig.label} (${insightsByTab[index].length})`}
                sx={{
                  textTransform: "none",
                }}
              />
            ))}
          </Tabs>

          {selectedInsights.length ? (
            <Stack direction="column" spacing={2}>
              {selectedInsights.map((item, index) =>
                "isUpsell" in item ? (
                  <InsightsListRowUpsell
                    key={`upsell-${index}`}
                    totalInsights={nonEntitledTotalInsights}
                    savings={nonEntitledDailySavings}
                  />
                ) : (
                  <InsightsListRow key={`${item.providerId}#${item.key}`} insight={item} />
                )
              )}
            </Stack>
          ) : (
            <NotFound message="We couldn't find any relevant insights with the selected filters." showIcon={false} />
          )}
        </Box>
      </Stack>
    </PageWrapper>
  );
}
