import { type CustomerSegment, CustomerSegmentValues, type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import { generalTxt } from "../../../../../assets/texts/Settings/general";
import { useCheckFlexsaveEnabled } from "../../../../../Components/hooks/useCheckFlexsaveEnabled";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type Contract } from "../../../../../types";
import { usePartnerDataOfCustomer } from "../../hooks";
import TierInfoRow from "../MenuItems/TierInfoRow";
import { FlexsaveRow } from "./FlexsaveRow";

function formatFlexsaveName(standaloneCustomer: boolean, saasEnabled = false, enabledFlexsave = false) {
  if (saasEnabled) {
    return "SaaS";
  }
  if (!standaloneCustomer && enabledFlexsave) {
    return "Resold";
  }

  return "N/A";
}

type Props = {
  saasEnabled?: Record<FlexsaveType, boolean>;
};

const buildAdvantageString = (contracts: Contract[]) => {
  let hasGcp = "";
  let hasAws = "";
  let hasAzure = "";

  for (const contract of contracts) {
    if (!contract.active) {
      continue;
    }

    switch (contract.type) {
      case "amazon-web-services":
        hasAws = "AWS";
        break;
      case "google-cloud":
        hasGcp = "GCP";
        break;
      case "microsoft-azure":
        hasAzure = "Azure";
        break;
    }

    if (hasAws && hasGcp && hasAzure) {
      break;
    }
  }

  return [hasGcp, hasAws, hasAzure].filter(Boolean).join(", ");
};

export const getCustomerSegmentOverride = (customerSegment?: CustomerSegment) =>
  customerSegment?.overrideSegment ? customerSegment?.overrideSegment : null;

export const getCustomerSegment = (customerSegment?: CustomerSegment) =>
  getCustomerSegmentOverride(customerSegment) ?? customerSegment?.currentSegment ?? CustomerSegmentValues.NA;

export const CustomerInfo = ({ saasEnabled }: Props) => {
  const { customer, isProductOnlyCustomer, contracts, contractsLoading } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const [flexsaveEnabled, loading] = useCheckFlexsaveEnabled(customer.id);
  const partnerData = usePartnerDataOfCustomer(customer);
  const { customerSegment } = customer;

  return (
    <Stack
      spacing={1}
      sx={{
        py: 0.5,
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box>Advantage:</Box>
        {contractsLoading ? (
          <CircularProgress size={14} />
        ) : (
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              pl: 0.5,
            }}
          >
            {buildAdvantageString(contracts) || "N/A"}
          </Typography>
        )}
      </Stack>
      <TierInfoRow />
      {isDoitEmployee && (
        <FlexsaveRow
          loading={loading}
          type={FlexsaveTypes.AWS}
          value={formatFlexsaveName(isProductOnlyCustomer, saasEnabled?.AWS, flexsaveEnabled.AWS)}
        />
      )}
      {isDoitEmployee && (
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box>{generalTxt.SEGMENT}: </Box>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              pl: 0.5,
            }}
          >
            {getCustomerSegment(customerSegment)}
          </Typography>
        </Stack>
      )}
      {isDoitEmployee && !!partnerData && (
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box>Partner: </Box>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              pl: 0.5,
            }}
          >
            {partnerData.name}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
