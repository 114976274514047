import { useMemo } from "react";

import { Box, FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { SavingsTimeFilter } from "../types";

export type TimeFilterProps = {
  value: SavingsTimeFilter;
  onTimeFilterChanged: (filter: SavingsTimeFilter) => void;
  showPartialOptions?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 150,
    },
  })
);

export const TimeFilter = ({ value, onTimeFilterChanged, showPartialOptions = false }: TimeFilterProps) => {
  const classes = useStyles();

  const filters = useMemo(() => {
    const result = [SavingsTimeFilter.CurrentMonth, SavingsTimeFilter.LastMonth];

    if (!showPartialOptions) {
      result.push(SavingsTimeFilter.LastThreeMonths);
      result.push(SavingsTimeFilter.AllTime);
    }
    return result;
  }, [showPartialOptions]);

  const handleChange = (event: SelectChangeEvent<SavingsTimeFilter>) => {
    onTimeFilterChanged(event.target.value as SavingsTimeFilter);
  };

  return (
    <Box
      sx={{
        width: 150,
      }}
    >
      <FormControl margin="dense" className={classes.formControl}>
        <InputLabel>Time range</InputLabel>
        <Select size="small" value={value} onChange={handleChange} label="Time period" data-cy="time-filter-select">
          {filters.map((filter, index) => (
            <MenuItem key={index} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
