import { Stack, Typography } from "@mui/material";

import NoDataIcon from "../../../assets/no_data.png";

interface NotFoundProps {
  readonly message?: string;
  readonly showIcon?: boolean;
}

export function NotFound({ message, showIcon = true }: Readonly<NotFoundProps>) {
  return (
    <Stack
      spacing={4}
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 6,
      }}
    >
      {showIcon && <img src={NoDataIcon} alt="No Data Icon" style={{ width: "15vw" }} />}
      <Typography
        variant="h1"
        sx={{
          mb: 3,
          fontWeight: 500,
        }}
      >
        No insights found
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 3,
          fontWeight: 400,
          maxWidth: "70%",
        }}
      >
        {message ?? "We're sorry, but we couldn't find any relevant insights with the selected filters."}
      </Typography>
    </Stack>
  );
}
