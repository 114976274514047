import { type ReactNode } from "react";

import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

import blurredChart from "./Assets/blurred-chart.png";

type Props = {
  contactSalesButton?: ReactNode;
};

export const UpsellNotification = ({ contactSalesButton }: Props) => (
  <Card style={{ position: "relative" }}>
    <CardMedia component="img" image={blurredChart} alt="blurred chart" />
    <CardContent
      style={{
        position: "absolute",
        top: "5%",
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Typography variant="subtitle1" style={{ color: "#000000DE", fontWeight: 500, fontFamily: "Roboto" }}>
        Ava can give you cost insights in Analytics
      </Typography>
      <Typography
        variant="body1"
        style={{ color: "#00000099", fontWeight: 400, fontFamily: "Roboto" }}
        sx={{
          mt: 1,
        }}
      >
        Upgrade your DoiT Cloud Navigator plan to get cost insights
      </Typography>
      <Typography
        variant="body1"
        style={{ color: "#00000099", fontWeight: 400, fontFamily: "Roboto", textAlign: "center" }}
      >
        into your cloud spend
      </Typography>
      <Box
        sx={{
          mt: 1,
        }}
      >
        {contactSalesButton}
      </Box>
    </CardContent>
  </Card>
);
