import { Stack } from "@mui/material";
import Box from "@mui/material/Box";

const COLORS = {
  Success: "success.light",
  Warning: "warning.light",
  Error: "error.light",
  Empty: "#D9D9D9",
} as const;

type Color = (typeof COLORS)[keyof typeof COLORS];

type MeterProps = {
  value: number;
  limit: number;
};

export const UsageMeterBar = ({ value, limit }: MeterProps) => {
  let color: Color = value >= limit ? COLORS.Error : COLORS.Warning;
  if (value / limit <= 0.75) {
    color = COLORS.Success;
  }

  const getSegmentColor = (index: number) => (index < value ? color : COLORS.Empty);

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "center",
        mt: 0.5,
      }}
    >
      {Array.from({ length: limit }).map((_, i) => (
        <Box
          key={i}
          sx={{
            flexGrow: 1,
            height: "5px",
            backgroundColor: getSegmentColor(i),
            mr: "2px",
            "&:first-child": { borderRadius: "2px 0 0 2px" },
            "&:last-child": { borderRadius: "0 2px 2px 0", mr: 0 },
          }}
        />
      ))}
    </Stack>
  );
};
