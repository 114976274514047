import { useCallback, useEffect, useMemo, useState } from "react";

import {
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudStandalone,
  type GoogleCloudAssetModel,
  type GoogleCloudProjectAssetModel,
  ProductEnum,
} from "@doitintl/cmp-models";
import { Box, Button } from "@mui/material";
import intersection from "lodash/intersection";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { type FilterTableToolbarProps } from "../../../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type Asset } from "../../../../../types";
import { extractAssetsFromAssetObject } from "../../../assetUtils";
import NoAssets from "../../../Tabs/components/NoAssets";
import { mapEntitiesByKey } from "../../utils";
import { gcpProjectsColumns, gcpProjectsHeaders } from "./GcAssetColumnsProject";
import GcAssetRowProjects, { type GcpProjectAssetRowData, populateRowFromAsset } from "./GcAssetRowProjects";

type Props = {
  items: Asset<GoogleCloudProjectAssetModel>[];
  onRemoveAsset: (asset) => () => void;
  toolbar: FilterTableToolbarProps;
};

const GcAssetTableProjects = ({ items, onRemoveAsset, toolbar }: Props) => {
  const { entities, assets, assetsLoading, customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const [tableData, setTableData] = useState<GcpProjectAssetRowData[]>([]);

  const showNoAssets = useMemo<boolean>(() => {
    const productEnums = [
      ProductEnum.GoogleCloud,
      ProductEnum.GoogleCloudStandalone,
      ProductEnum.GoogleCloudProject,
      ProductEnum.GoogleCloudProjectStandalone,
    ];
    return !intersection(customer.assets, productEnums).length || !items.length;
  }, [customer.assets, items.length]);
  const entitiesByKey = useMemo(() => mapEntitiesByKey(entities), [entities]);

  useEffect(() => {
    if (assetsLoading) {
      return;
    }

    const allAssets = extractAssetsFromAssetObject(assets);
    const partnerAssets = allAssets.filter(
      (asset): asset is Asset<GoogleCloudAssetModel> =>
        asset.data.type === AssetTypeGoogleCloud || asset.data.type === AssetTypeGoogleCloudStandalone
    );
    const billingAccountMap = {};
    partnerAssets.forEach((asset) => {
      billingAccountMap[asset.data.properties.billingAccountId] = asset.data.properties.displayName;
    });

    setTableData(items.map((item) => populateRowFromAsset(item, entitiesByKey, billingAccountMap)));
  }, [assets, assetsLoading, entitiesByKey, items]);

  toolbar.allowToEditColumns = !showNoAssets;

  const rowComponent = useCallback(
    ({ row }) => <GcAssetRowProjects row={row} onRemoveAsset={onRemoveAsset} />,
    [onRemoveAsset]
  );

  const tableContent = (
    <FilterTable<GcpProjectAssetRowData>
      toolbarProps={toolbar}
      emptyTableComponent={
        <NoAssets assetType={AssetTypeGoogleCloudProject}>
          <Button variant="contained" onClick={toolbar.primaryButton?.onClick}>
            New subscription
          </Button>
        </NoAssets>
      }
      rowComponent={rowComponent}
      headerColumns={gcpProjectsHeaders}
      filterColumns={gcpProjectsColumns}
      tableItems={showNoAssets ? [] : tableData}
      defaultSortingColumnValue="projectId"
      defaultSortDirection="asc"
      filterBarPlaceholder="Filter assets"
      persistenceKey="gcp_assets_projects"
      showFilterBar={!showNoAssets}
      showHeader={!showNoAssets}
    />
  );

  return (
    <Box
      data-testid="gc-assets-table"
      sx={{
        mb: 4,
      }}
    >
      {tableContent}
    </Box>
  );
};

export default GcAssetTableProjects;
