import { type PurchaseStatus } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";
import groupBy from "lodash/groupBy";

import { getStatusText } from "../../utils/purchaseStatusUtils";
import { type StatusTooltipProps } from "./types";

type StatusTextProps = {
  showStatusCount: boolean;
  statusText: string;
  statusCount: number;
};

const StatusText = ({ showStatusCount, statusCount, statusText }: StatusTextProps) => {
  if (showStatusCount) {
    return (
      <Stack
        direction="row"
        sx={{
          gap: 0.5,
        }}
      >
        <Typography variant="caption">{`${statusText}:`}</Typography>
        <Typography variant="caption" style={{ fontWeight: 600 }}>
          {statusCount}
        </Typography>
      </Stack>
    );
  }
  return null;
};

export const StatusBreakdown = ({ status, workloads }: StatusTooltipProps) => {
  const groupedWorkloads = groupBy(workloads, (workload) => workload.status);
  return (
    <>
      <Typography
        variant="caption"
        style={{ fontWeight: 600 }}
        sx={{
          mb: 0.5,
        }}
      >
        {" "}
        Status breakdown{" "}
      </Typography>
      {status === "not-enough-data" ? (
        <Typography variant="caption">
          Either the customer is new and there are no workloads for this customer or the workload status has not been
          updated in the last 24 hours
        </Typography>
      ) : (
        <>
          {Object.keys(groupedWorkloads).map((status) => {
            const statusCount = groupedWorkloads[status].length;
            return (
              <StatusText
                key={status}
                showStatusCount={!!statusCount}
                statusText={getStatusText(status as PurchaseStatus)}
                statusCount={statusCount}
              />
            );
          })}
        </>
      )}
    </>
  );
};
