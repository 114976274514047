import { Box } from "@mui/material";

import partnerSuccessImg from "../assets/flexsave/blank-marketplace-success.svg";
import flexsaveSubscriptionSuccessImg from "../assets/flexsave/general-marketplace-success.svg";
import doitSuccess from "../assets/standalone/doit-success-img.svg";
import partnerSuccess from "../assets/standalone/partner-success-img.svg";
import { domainConfig } from "../domainConfig";
import { isPartnerDomain } from "./domains";

export const getSuccessOnboardingImage = () => {
  if (isPartnerDomain()) {
    return partnerSuccess;
  }
  return doitSuccess;
};

export const getMarketplaceSuccessImage = () => {
  if (isPartnerDomain()) {
    return (
      <Box sx={{ position: "relative" }}>
        <img src={partnerSuccessImg} alt="Marketplace Success" />
        <Box
          component="img"
          src={domainConfig.assets.logoURL}
          sx={{
            height: 90,
            position: "absolute",
            right: 10,
            top: 18,
          }}
        />
      </Box>
    );
  }
  return flexsaveSubscriptionSuccessImg;
};
