import React from "react";

import { Box, Button, Divider, Typography } from "@mui/material";

import { ToolTipWrapper } from "../TooltipWrapper";
import { getSubscriptionParentTextName, useSubscriptionPermissions } from "./helpers";
import { SubscriptionForm } from "./SubscriptionForm";
import { SubscriptionPanelItem } from "./SubscriptionPanelItem";
import { type SubscriptionByType, type SubscriptionParentByType, type SubscriptionType } from "./types";

export function SubscriptionsPanel<ST extends SubscriptionType>({
  subscriptions,
  handleClose,
  subscriptionParent,
  subscriptionType,
}: {
  subscriptionType: ST;
  subscriptions: SubscriptionByType<ST>[];
  subscriptionParent: SubscriptionParentByType<ST>;
  handleClose: () => void;
}) {
  const [isSubscriptionFormOpen, setIsSubscriptionFormOpen] = React.useState(false);
  const { allowedToCreate } = useSubscriptionPermissions({
    subscriptionType,
    subscriptionParent,
    subscription: undefined,
  });

  const parentTextName = getSubscriptionParentTextName(subscriptionType);

  const onCreate = () => {
    setIsSubscriptionFormOpen(true);
  };

  const onClose = () => {
    setIsSubscriptionFormOpen(false);
    handleClose();
  };

  const panelPadding = 2;
  const panelWidth = 325;

  return (
    <Box
      sx={{
        width: panelWidth,
        minHeight: 450,
        maxHeight: 600,
        p: panelPadding,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="body2"
        gutterBottom
        sx={{
          fontWeight: 500,
        }}
      >
        Subscriptions ({subscriptions.length})
      </Typography>
      <Divider sx={{ mt: 1, width: panelWidth, mx: -panelPadding }} />
      {subscriptions.length > 0 ? (
        <Box sx={{ flexGrow: 1, overflowY: "auto", mb: 2 }}>
          {subscriptions.map((subscription) => (
            <SubscriptionPanelItem
              key={subscription.id}
              subscription={subscription}
              subscriptionType={subscriptionType}
              subscriptionParent={subscriptionParent}
            />
          ))}
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="body2" color="textSecondary">
            No current subscriptions
          </Typography>
        </Box>
      )}
      <Box>
        <Divider sx={{ mt: -2, mb: 2, width: panelWidth, mx: -panelPadding }} />

        {
          <ToolTipWrapper
            title={
              allowedToCreate
                ? undefined
                : `You don't have permission to create a subscription for this ${parentTextName.toLowerCase()}`
            }
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!allowedToCreate}
              onClick={allowedToCreate ? onCreate : undefined}
            >
              Create new subscription
            </Button>
          </ToolTipWrapper>
        }
      </Box>
      {isSubscriptionFormOpen && (
        <SubscriptionForm
          isOpen={isSubscriptionFormOpen}
          onClose={onClose}
          closeFormDialog={onClose}
          subscriptionParent={subscriptionParent}
          mode="create"
          subscription={undefined}
          subscriptionType={subscriptionType}
        />
      )}
    </Box>
  );
}
