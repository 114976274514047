import { type SaaSConsoleType } from "@doitintl/cmp-models";
import { Link, Stack, Typography } from "@mui/material";

import { ContactSupportButton } from "../../../Flexsave/Standalone/GCP/Steps/Common/ContactSupportButton";

type Props = {
  cloud: SaaSConsoleType;
  showContactSupportButton?: boolean;
};

export const UsefulLinks = ({ cloud, showContactSupportButton = true }: Props) => (
  <Stack
    direction="row"
    spacing={2}
    sx={{
      alignItems: "center",
      pb: 10,
      pt: 2,
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: "500",
      }}
    >
      Useful links:
    </Typography>

    {showContactSupportButton && <ContactSupportButton />}

    <Link
      href={`https://help.doit.com/docs/general/saas/console-${cloud.toLocaleLowerCase()}`}
      target="_blank"
      underline="hover"
    >
      Help documentation
    </Link>
  </Stack>
);
