import { type ApprovalConfig, type CloudFlowNodeType, TimeUnits } from "@doitintl/cmp-models";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";

import { useNodeConfigurationContext } from "../../NodeConfigurationContext";

type Props = {
  onUpdateConfig: (name: keyof ApprovalConfig, value: any) => void;
};

export const AutoRejectApproval = ({ onUpdateConfig }: Props) => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const { rejectApprovalAfterTime, rejectTimeValue, rejectTimeUnit } = nodeConfig.approval || {};
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name="rejectApprovalAfterTime"
            checked={rejectApprovalAfterTime}
            onChange={(e) => {
              onUpdateConfig("rejectApprovalAfterTime", e.target.checked);
            }}
          />
        }
        label="Reject approval after certain time"
      />
      {rejectApprovalAfterTime && (
        <Stack
          spacing={2}
          direction={"row"}
          sx={{
            pt: 1,
          }}
        >
          <TextField
            fullWidth
            name="rejectTimeValue"
            label="Number"
            type="number"
            value={rejectTimeValue}
            onChange={(e) => {
              onUpdateConfig("rejectTimeValue", parseInt(e.target.value));
            }}
            slotProps={{
              input: { inputProps: { min: 1 } },
            }}
          />
          <FormControl fullWidth size="small">
            <InputLabel>Time unit</InputLabel>
            <Select
              name="rejectTimeUnit"
              label="Time unit"
              value={rejectTimeUnit || ""}
              onChange={(e) => {
                onUpdateConfig("rejectTimeUnit", e.target.value);
              }}
            >
              {Object.values(TimeUnits).map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )}
    </>
  );
};
