import { Configure, InstantSearch, useInstantSearch, useSearchBox } from "react-instantsearch";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import { AlgoliaHooks } from "../../Context/algoliaContext";
import { CustomHits } from "./components/CustomHits";
import { CustomSearchBox } from "./components/CustomSearchBox";
import EmptyQueryMessage from "./components/EmptyQueryMessage";
import { ExpandableFilters } from "./components/ExpandableFilters";
import { FilterProvider, useFilter } from "./FilterContext";
import { useStyles } from "./utils/styles";

const WhoisContent = () => {
  const classes = useStyles();
  const { isFiltersExpanded } = useFilter();
  const { query } = useSearchBox();
  const { results } = useInstantSearch();

  const hasResults = results && results.nbHits > 0;
  const showNoResults = query !== "" && !hasResults;

  return (
    <Box className={classes.root}>
      <Box className={classes.mainContent}>
        {!isFiltersExpanded && (
          <Box className={classes.topBar}>
            <Box className={classes.searchBoxWrapper}>
              <CustomSearchBox />
            </Box>
            {}
            <Box className={classes.filtersButtonWrapper}>
              <ExpandableFilters renderExpandedContent={false} />
            </Box>
          </Box>
        )}
        {isFiltersExpanded && (
          <Box className={classes.expandedFiltersContainer}>
            <ExpandableFilters renderExpandedContent={true} />
          </Box>
        )}
        <Box className={classes.hitsContainer}>
          <Grid container direction="row">
            {showNoResults ? (
              <EmptyQueryMessage>
                <ExpandableFilters renderExpandedContent={false} />
              </EmptyQueryMessage>
            ) : (
              <CustomHits itemsToRender={30} />
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export const Whois = () => {
  const algoliaContext = AlgoliaHooks.useAlgoliaDoer();
  if (!algoliaContext.searchClient) {
    return null;
  }

  return (
    <FilterProvider>
      <InstantSearch searchClient={algoliaContext.searchClient} indexName="whois-cre">
        <Configure
          hitsPerPage={300}
          enablePersonalization={true}
          queryType="prefixAll"
          removeWordsIfNoResults="allOptional"
          typoTolerance={true}
          attributesToHighlight={["name", "all_skills", "languages"]}
          advancedSyntax={true}
          highlightPreTag="<strong>"
          highlightPostTag="</strong>"
        />
        <WhoisContent />
      </InstantSearch>
    </FilterProvider>
  );
};
