import { useCallback, useState } from "react";

import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Container, Stack, Typography } from "@mui/material";

import LoadingButton from "../../../../../Components/LoadingButton";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type CloudProviderOptionType } from "../../types";
import { updateCustomerCloudProviders } from "../db";
import { trackCloudSelectorSelected } from "../mixpanelEvents";
import { CloudBox } from "./CloudBox";
import useCloudSelection from "./hooks";

export const cloudProviderOptions: CloudProviderOptionType[] = [
  { type: SaaSConsoleType.GCP },
  { type: SaaSConsoleType.AWS },
  { type: SaaSConsoleType.AZURE },
];

const CloudSelector = () => {
  const { customer } = useCustomerContext();

  const { selectedCloudProviders, handleCloudSelection } = useCloudSelection([]);
  const [loading, setLoading] = useState(false);

  const onNextClick = useCallback(async () => {
    setLoading(true);
    trackCloudSelectorSelected();
    await updateCustomerCloudProviders(customer.id, selectedCloudProviders);
    setLoading(false);
  }, [customer.id, selectedCloudProviders]);

  return (
    <Container maxWidth="md">
      <Stack
        spacing={4}
        sx={{
          alignItems: "center",
          my: 10,
        }}
      >
        <Stack
          sx={{
            mt: 4,
            alignSelf: "baseline",
            width: "100%",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: "500",
              pb: 1,
            }}
          >
            Which cloud providers do you use?
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
            }}
          >
            This will let us tailor your onboarding experience
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={4}
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {cloudProviderOptions.map((cloud) => (
            <CloudBox
              key={cloud.type}
              cloud={cloud}
              selected={selectedCloudProviders.includes(cloud.type)}
              onClick={handleCloudSelection}
            />
          ))}
        </Stack>
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ mt: 4, alignSelf: "flex-start" }}
          onClick={onNextClick}
          disabled={selectedCloudProviders.length === 0}
          mixpanelEventId="customer.cloud-selector.next"
        >
          Next
        </LoadingButton>
      </Stack>
    </Container>
  );
};

export default CloudSelector;
