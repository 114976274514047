import { type JSX } from "react";

import { SaaSConsoleType } from "@doitintl/cmp-models";
import { PlayCircleOutline } from "@mui/icons-material";
import { Box, Button, Card, Divider, Stack, Typography, useTheme } from "@mui/material";

import AWSLogoLight from "../../../assets/aws-logo.svg";
import AWSLogoDark from "../../../assets/aws-logo-dark-mode.svg";
import AzureLogo from "../../../assets/azure-logo.svg";
import GCPLogo from "../../../assets/gcp-logo.svg";
import SnowflakeLogo from "../../../assets/snowflake-logo.svg";
import { DEMO_LINK_AWS, DEMO_LINK_GCP, headerMap } from "./consts";

export type Props = {
  cloudType: SaaSConsoleType;
  cardContent: JSX.Element;
  onGetStarted?: () => void;
};
export const CloudConnectCard = ({ cloudType, cardContent, onGetStarted }: Props) => {
  const theme = useTheme();
  const AWSLogo = theme.palette.mode === "dark" ? AWSLogoDark : AWSLogoLight;

  let logo;
  switch (cloudType) {
    case SaaSConsoleType.AWS:
      logo = AWSLogo;
      break;
    case SaaSConsoleType.GCP:
      logo = GCPLogo;
      break;
    case SaaSConsoleType.SNOWFLAKE:
      logo = SnowflakeLogo;
      break;
    default:
      logo = AzureLogo;
  }

  const videoLinkText =
    cloudType === SaaSConsoleType.AWS
      ? "Watch how to connect your AWS account"
      : "Watch how to connect your Google Cloud";
  const videoLink = cloudType === SaaSConsoleType.AWS ? DEMO_LINK_AWS : DEMO_LINK_GCP;

  const header = `Connect your ${headerMap[cloudType]} account`;

  return (
    <Card sx={{ height: "100%" }} data-testid={`cloud-connect-card-${cloudType.toLocaleLowerCase()}`}>
      <Stack divider={<Divider />}>
        <Typography variant="h4" data-cy="title" sx={{ my: 2, ml: 3 }}>
          <Box component="img" src={logo} sx={{ height: "32px", width: "32px", mb: -1.23, mr: 1 }} />
          {header}
        </Typography>
        {cardContent}
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          ml: 3,
          my: 3,
          justifyContent: "flex-start",
        }}
      >
        <Button variant="contained" onClick={onGetStarted}>
          Get Started
        </Button>
        {cloudType !== SaaSConsoleType.AZURE && cloudType !== SaaSConsoleType.SNOWFLAKE ? (
          <Button variant="text" href={videoLink} startIcon={<PlayCircleOutline />} target="_blank">
            {videoLinkText}
          </Button>
        ) : null}
      </Stack>
    </Card>
  );
};
