import { useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import { Box, Link, Stack, Typography } from "@mui/material";

import useMountEffect from "../../../../../../Components/hooks/useMountEffect";
import mixpanel from "../../../../../../utils/mixpanel";
import { type Explainer } from "../../../types";
import { CurrencySelector } from "../../Components/CurrencySelector";
import { InvoiceExplainer } from "./Components/InvoiceExplainer";
import { TitleWithTooltip } from "./Components/TitleWithTooltip";

type Props = {
  explainer: Explainer;
  onCurrencyChange?: (currency: CurrencyCode) => void;
};

export function ExplainerSummaryPage({ explainer, onCurrencyChange }: Props) {
  const helpCenterLink = "https://help.doit.com/docs/billing/billing-explained/billing-differences";
  const [currency, setCurrency] = useState<CurrencyCode>("USD");

  const handleCurrencyChange = (currency: CurrencyCode) => {
    setCurrency(currency);
    if (onCurrencyChange) {
      onCurrencyChange(currency);
    }
  };

  useMountEffect(() => {
    mixpanel.track("invoices.invoice.explainer.summary.open");
  });

  return (
    <Stack
      spacing={5}
      sx={{
        maxWidth: 800,
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h3">Summary of your invoice</Typography>
        <Typography variant="body1">
          Here you can compare your usage and services costs with DoiT, to what DoiT is charged by AWS according to your
          usage.
        </Typography>
        <Typography variant="body1">
          There may be differences between your DoiT invoice and what DoiT is charged by AWS on your behalf. For more
          information, read about DoiT and AWS invoice differences in our{" "}
          <Link
            href={helpCenterLink}
            target="_blank"
            onClick={() => {
              mixpanel.track("invoices.invoice.explainer.helpcenter.open");
            }}
          >
            Help Center
          </Link>
          .
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              width: "150px",
            }}
          >
            <CurrencySelector onCurrencyChange={handleCurrencyChange} />
          </Box>
        </Box>
        <TitleWithTooltip tooltip="What you see in AWS Cost Explorer" wordToUnderline="invoice">
          AWS’ invoice to DoiT
        </TitleWithTooltip>
        <InvoiceExplainer type="aws" details={explainer.summary.aws} currency={currency} />
      </Stack>
      <Stack spacing={2}>
        <TitleWithTooltip tooltip="What you see in the DoiT Console" wordToUnderline="invoice">
          DoiT’s invoice to you
        </TitleWithTooltip>
        <InvoiceExplainer type="doit" details={explainer.summary.doit} currency={currency} />
      </Stack>
    </Stack>
  );
}
