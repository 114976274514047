import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import find from "lodash/find";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 320,
    height: 210,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    // width: '100%',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList({
  customerProjects,
  createSA,
  onSelectedProjects,
  assetData,
  loadingProjects,
  isTransferProject,
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [loading, setLoading] = useState(true);
  const [billingAccountList, setBillingAccountList] = useState([]);
  const [selectedBillingAccount, setSelectedBillingAccount] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setLeft([]);
    setRight([]);
    createSA();
    return () => {
      setLeft([]);
      setLoading(true);
    };
  }, [createSA]);
  useEffect(() => {
    if (isTransferProject) {
      return;
    }
    setLoading(loadingProjects);
    if (!loadingProjects) {
      const arr = billingAccountList;
      const tmpProjects = customerProjects.map((project) => {
        if (!billingAccountList.includes(project.billingName)) {
          arr.push(project.billingName);
        }
        return project.projectId;
      });
      setBillingAccountList(arr);
      setLeft(tmpProjects);
    }
  }, [billingAccountList, customerProjects, isTransferProject, loadingProjects]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    onSelectedProjects(right.concat(leftChecked));
    // console.log(left);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    onSelectedProjects(not(right, rightChecked));
  };
  const refresh = () => {
    setLeft([]);
    setRight([]);
    createSA();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (val) => {
    setSelectedBillingAccount(val);
    handleClose();
  };
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const sortBillingAccounts = (items) => {
    const arr = [];
    if (items.length === 0 || customerProjects.length === 0) {
      return [];
    }
    items.forEach((item) => {
      const obj = find(customerProjects, { projectId: item });
      if (obj.billingName === selectedBillingAccount || selectedBillingAccount === "all") {
        arr.push(item);
      }
    });
    return arr;
  };
  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        action={
          title === "Projects" && (
            <>
              <IconButton style={{ marginTop: 5 }} onClick={refresh} color="primary" size="large">
                <RefreshIcon />
              </IconButton>
              <IconButton style={{ marginTop: 5 }} onClick={handleFilterClick} size="large">
                <FilterIcon />
              </IconButton>
            </>
          )
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem selected={"all" === selectedBillingAccount} onClick={() => handleChange("all")} key="all" value="all">
          All Accounts
        </MenuItem>
        {billingAccountList.map((account) => (
          <MenuItem
            selected={account === selectedBillingAccount}
            onClick={() => handleChange(account)}
            key={account}
            value={account}
          >
            {account}
          </MenuItem>
        ))}
      </Menu>

      <Divider />

      <List className={classes.list} dense={true} component="div" role="list">
        {loading && (
          <div style={{ textAlign: "center", paddingTop: 40, position: "relative" }}>
            <CircularProgress disableShrink={true} size={40} thickness={4.5} />
          </div>
        )}
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              style={{ paddingBottom: 1, paddingTop: 1 }}
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              {find(customerProjects, { projectId: value }) ? (
                <>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    id={labelId}
                    primary={`${value}`}
                    secondary={find(customerProjects, { projectId: value }).billingName}
                  />
                </>
              ) : (
                <ListItemText id={labelId} primary={`${value}`} />
              )}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      className={classes.root}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid>
        {customList(
          "Projects",
          left.length === 0 && !loadingProjects ? ["No Projects are found"] : sortBillingAccounts(left)
        )}
      </Grid>
      <Grid>
        <Grid
          container
          direction="column"
          sx={{
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid>{customList(assetData.properties.displayName, right)}</Grid>
    </Grid>
  );
}
