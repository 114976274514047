import React from "react";

import { type DeliveryFrequency } from "@doitintl/cmp-models";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Alert, Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";

import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";
import { calcNextAt, getScheduleText } from "../helpers";
import { type SubscriptionFormMode, type SubscriptionFormValues } from "../types";

export function ScheduleInfoBar({ formMode }: { formMode: SubscriptionFormMode }) {
  const { values, isValid, initialValues } = useFormikContext<SubscriptionFormValues>();
  const isDarkTheme = useDarkThemeCheck();

  if (!isValid) {
    return null;
  }

  const scheduleText = getScheduleText({
    customPeriodAmount: values.customPeriodAmount,
    customPeriodUnit: values.customPeriodUnit,
    deliveryFrequency: values.deliveryFrequency as DeliveryFrequency,
    startDate: values.startDate,
    time: values.time,
    format: "long",
  });

  if (!scheduleText) {
    return null;
  }

  let nextAt: DateTime | null = null;
  try {
    nextAt = values.timeZone
      ? calcNextAt({
          deliveryFrequency: values.deliveryFrequency as DeliveryFrequency,
          startDate: values.startDate,
          time: values.time,
          timeZone: values.timeZone,
          customPeriodAmount: values.customPeriodAmount,
          customPeriodUnit: values.customPeriodUnit,
          currentDateTime: DateTime.now().setZone(values.timeZone),
        })
      : null;
  } catch (e) {
    // invalid time, ignore
  }

  const withinNextHour = nextAt && nextAt.diffNow("hours").hours < 1;
  const startTimeChanged = !values.time.equals(initialValues.time) || !values.startDate.equals(initialValues.startDate);
  const isNextAtSkipped = withinNextHour && (formMode === "create" || startTimeChanged);

  return (
    <>
      <Paper
        sx={{
          p: 1,
          mt: -1.5,
          backgroundColor: isDarkTheme ? "#2D2D39" : "#FAFAFA",
        }}
        elevation={0}
      >
        <Grid
          container
          sx={{
            alignItems: "center",
          }}
        >
          <Grid>
            <Typography variant="body2">
              <Box component="span" sx={{ mr: 1, display: "inline-flex", alignItems: "center" }}>
                <Typography component="span" sx={{ fontSize: 20, mr: 1, mt: 0.5 }}>
                  <AccessTimeIcon sx={{ fontSize: 20 }} />
                </Typography>
                {scheduleText}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {isNextAtSkipped && (
        <Alert severity="info" sx={{ mt: 1 }}>
          Processing new subscriptions can take up to an hour. Any notifications scheduled within this hour will not be
          delivered. Once your subscription is fully set up, you'll start receiving notifications as planned.
        </Alert>
      )}
    </>
  );
}
