import { Stack } from "@mui/material";

import { IntroducingThreadsContent } from "../IntroducingThreadsContent";

export const ThreadsOverviewNoJiraConnection = () => (
  <Stack
    spacing={1}
    sx={{
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      my: "70px",
    }}
  >
    <IntroducingThreadsContent isModal={false} fullContent={false} />
  </Stack>
);
