import { type JSX, useMemo } from "react";

import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import keys from "lodash/keys";

import DataCouplet, { type CoupletProps } from "../DataCouplet";
import { useDarkThemeCheck } from "../hooks/useDarkThemeCheck";

type ContentCoupletData = Omit<CoupletProps, "field">;

export type MultiCoupletContentData = Record<string, ContentCoupletData>;

export type MultiCoupletCardProps = {
  title?: string | JSX.Element;
  contentData: MultiCoupletContentData;
};

export const cyIds = {
  card: "multi-couplet-card",
  header: "multi-couplet-card-header",
  content: "multi-couplet-card-content",
  item: (field: string | null) => `multi-couplet-card-item-${field}`,
};

/**
 * Renders a card with data couplets in an accessible manner.
 * @param title the title of the card
 * @param contentData the data to be displayed in the card. Each key of the object will become a `field` prop in the {@link DataCouplet} component, whilst the value will be transposed to the other props of the couplet.
 * @param props {any[]} any other props to be passed to the card e.g. data-cy
 */
const MultiCoupletCard = ({ title, contentData, ...props }: MultiCoupletCardProps) => {
  const darkMode = useDarkThemeCheck();

  const content = useMemo<JSX.Element>(() => {
    const fields = keys(contentData);
    const couplets = fields.map((field) => <DataCouplet key={field} field={field} compact {...contentData[field]} />);

    return (
      <Box component="dl">
        <Grid container spacing={1}>
          {couplets.map((couplet) => (
            <Grid key={`grid-${couplet.key}`} data-cy={cyIds.item(`${couplet.key}`)} size={6}>
              {couplet}
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }, [contentData]);

  return (
    <Card data-cy={props["data-cy"]}>
      <CardHeader
        title={title}
        data-cy={cyIds.header}
        sx={{
          backgroundColor: darkMode ? "inherit" : grey[100],
          borderBottom: `1px solid ${darkMode ? "rgba(255, 255, 255, 0.083)" : grey[300]}`,
        }}
      />
      <CardContent sx={{ py: 0, mb: -3 }} data-cy={cyIds.content}>
        {content}
      </CardContent>
    </Card>
  );
};

export default MultiCoupletCard;
