import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import DefaultPreviewChart from "../../../assets/default-preview.svg";

const DefaultPreviewContent = () => {
  const { t } = useTranslation("login");
  return (
    <>
      <Typography
        variant="h1"
        color="white"
        sx={{
          fontSize: 36,
          lineHeight: 1.2,
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        {t("defaultContent.title")}
      </Typography>
      <Typography
        variant="body1"
        color="white"
        sx={{
          py: 2,
          textAlign: "center",
        }}
      >
        {t("defaultContent.description")}
      </Typography>
      <img src={DefaultPreviewChart} alt="Flexsave Chart" width="576px" height="auto" />
    </>
  );
};

export default DefaultPreviewContent;
