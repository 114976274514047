import { useMemo } from "react";

import { type AWSFlexsaveConfigurationModel, type FlexsaveRDS, type FlexsaveSageMaker } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";

import { MonthlySavingsChart } from "../AWSOnboarding/MonthlySavingsChart";
import { getTotalSavings } from "../AWSOnboarding/stateHeaderUtils";
import { OverviewCards } from "../Resold/Overview/SavingsCard/OverviewCards";

type Props = {
  computeData: AWSFlexsaveConfigurationModel;
  rdsData: FlexsaveRDS | undefined;
  sageMakerData: FlexsaveSageMaker | undefined;
};

export const OverviewCharts = ({ computeData, rdsData, sageMakerData }: Props) => {
  const totalSavings = useMemo(() => getTotalSavings(computeData), [computeData]);

  return (
    <Stack
      spacing={2}
      useFlexGap
      sx={{
        pt: 1,
      }}
    >
      <OverviewCards totalSavings={totalSavings} />
      <MonthlySavingsChart computeData={computeData} sageMakerData={sageMakerData} rdsData={rdsData} />
    </Stack>
  );
};
