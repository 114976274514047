import { type FC, type ReactNode } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Stack } from "@mui/material";

export const OptionalParamWrapper: FC<{ children: ReactNode; onRemove?: () => void }> = ({ children, onRemove }) => {
  if (!onRemove) {
    return <>{children}</>;
  }

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "flex-start",
      }}
    >
      {children}
      <IconButton size="small" onClick={onRemove} sx={{ mt: 0.5 }}>
        <ClearIcon />
      </IconButton>
    </Stack>
  );
};
