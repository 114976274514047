import { type ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { type CurrencyCode, type Office365AssetModel, type Office365AssetModelProperties } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/PlusOneRounded";
import ResetIcon from "@mui/icons-material/RefreshRounded";
import RemoveIcon from "@mui/icons-material/RemoveRounded";
import { Box, Card } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import { green, grey, red } from "@mui/material/colors";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { type PopoverProps } from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import Office365Icon from "../../../assets/office-365.png";
import { type LegacyDataFormat, useOffice365CatalogContext } from "../../../Components/Catalog/Catalog.context";
import { SkeletonCard } from "../../../Components/Dashboard/SkeletonCard";
import { type WidgetItemProps, type WidgetItemWithWidgetIdProps } from "../../../Components/Dashboard/types";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useUserContext } from "../../../Context/UserContext";
import { ThemeModes } from "../../../muiThemeTypes";
import { type Asset, type Contract } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { formatCurrency } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { WidgetCardHeader } from "../../Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import AckDialog from "../Dialogs/AckDialog";
import AssetSettingsDialog from "../Dialogs/AssetSettingsDialog";
import { toSentenceCase } from "../utils";
import { useAssetDataProps } from "./assetCardUtils";
import { RemoveDialog } from "./components/RemoveDialog";

const useStyles = makeStyles((theme) => ({
  highlight: {
    backgroundColor: theme.palette.mode === ThemeModes.DARK ? grey[600] : grey[300],
  },
  actions: {
    height: 64,
  },
  overrode: {
    color: red[500],
  },
  cardContent: {
    paddingBottom: 0,
  },
  infoDiv: {
    height: 72,
  },
  increase: {
    color: green[500],
  },
  decrease: {
    color: red[500],
  },
  startAction: {
    marginRight: theme.spacing(1),
  },
  endAction: {
    marginLeft: theme.spacing(1),
  },
  snackBarCloseButton: {
    htmlColor: theme.palette.mode === ThemeModes.DARK ? "black" : "white",
  },
}));

type Props = WidgetItemProps & {
  highlight?: boolean;
  widget: boolean;
  onRemoveAsset?: () => void;
  onAssignToEntity?: () => void;
  unassignedAction?: ReactNode;
  onAddTag?: () => void;
  asset: Asset<Office365AssetModel>;
  contract?: Contract;
};

export default function Office365AssetCardWithProps({
  asset,
  contract,
  widget,
  highlight,
  onAssignToEntity,
  onAddTag,
  onRemoveAsset,
  unassignedAction,
  widgetHeight = 200,
}: Props) {
  const domRef = useRef<HTMLInputElement>();
  const { isDoitEmployee, isDoitOwner } = useAuthContext();
  const classes = useStyles();
  const api = useApiContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const [catalog, catalogLoading] = useOffice365CatalogContext();
  const [subscription, setSubscription] = useState<Office365AssetModelProperties["subscription"]>();
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<PopoverProps["anchorEl"] | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState(false);
  const [settingsDialog, setSettingsDialog] = useState(false);
  const [ackSubscriptionChangeQuantityDialog, setAckSubscriptionChangeQuantityDialog] = useState(false);
  const [ackAssetRemoveDialog, setAckAssetRemoveDialog] = useState(false);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [catalogItem, setCatalogItem] = useState<LegacyDataFormat | null>();

  useEffect(() => {
    if (highlight && domRef.current) {
      window.scrollTo({
        top: domRef.current.offsetTop - 14,
        behavior: "smooth",
      });
    }
  }, [domRef, highlight]);

  useEffect(() => {
    const { subscription, settings } = asset.data.properties;
    let planName: string;
    let payment: string;
    if (settings?.plan) {
      planName = settings.plan.planName;
    } else {
      planName = "FLEXIBLE";
    }

    if (settings?.payment) {
      payment = settings.payment;
    } else {
      payment = planName === "FLEXIBLE" ? "MONTHLY" : "YEARLY";
    }

    const currentCatalogItem = catalog
      ? catalog.find((item) => {
          let skuCheck: boolean;
          if (subscription?.productType?.id === "OnlineServicesNCE" || item.data.skuId.includes(":")) {
            // New Commerce Experience (NCE) check
            const productAndSku = subscription.offerId.split(":", 2).join(":");
            skuCheck = item.data.skuId === productAndSku;
          } else {
            skuCheck = item.data.skuId === subscription.offerId;
          }
          return skuCheck && item.data.plan === planName && item.data.payment === payment;
        })
      : null;

    setSyncing(asset.data.properties.syncing ?? false);
    setSubscription(asset.data.properties.subscription);
    setIsEditable(
      !["DZH318Z0BPS6:0001:DZH318Z0BMGV", "MS-AZR-0145P"].includes(asset.data.properties.subscription.offerId)
    );
    setCatalogItem(currentCatalogItem);
  }, [asset.data.properties, catalog]);

  useEffect(() => {
    setIsSubmitting(asset.data.properties.syncing ?? false);
    setSubscription(asset.data.properties.subscription);
    setSyncing(asset.data.properties.syncing ?? false);
  }, [asset.data.properties.subscription, asset.data.properties.syncing]);

  const getTotal = useCallback(
    (quantity: number) => {
      if (!catalogItem) {
        return;
      }
      const monthlyPay = catalogItem.data.payment === "MONTHLY";
      let currency: CurrencyCode = "USD";

      if (quantity > 0) {
        let total: number;
        let discount = 0;
        if (contract?.discount) {
          discount = contract.discount;
        }

        if (asset.data.properties.settings?.currency) {
          currency = asset.data.properties.settings.currency;
        }
        const price = catalogItem.data.price[currency];

        if (price === undefined) {
          return;
        }

        if (monthlyPay) {
          total = quantity * price;
        } else {
          let endTime: DateTime | undefined = undefined;
          if (asset.data.properties.settings?.plan) {
            const { plan } = asset.data.properties.settings;
            if (plan.isCommitmentPlan) {
              endTime = DateTime.fromMillis(plan.commitmentInterval.endTime);
            }
          }

          if (endTime) {
            const now = DateTime.utc().startOf("day");
            const diff = endTime.diff(now, ["months"]);
            total = (quantity * price * diff.months) / 12;
          } else {
            total = quantity * price;
          }
        }

        total = total * (1 - discount * 0.01);
        return formatCurrency(total, currency);
      }

      return "";
    },
    [asset.data.properties.settings, catalogItem, contract?.discount]
  );

  const handleSubscriptionChangeQuantity = useCallback(
    async (currentQuantity: number, quantity: number) => {
      setAckSubscriptionChangeQuantityDialog(false);
      setIsSubmitting(true);
      const n = quantity - currentQuantity;
      const total = getTotal(n);

      if (!catalogItem || !subscription) {
        return;
      }

      try {
        await api.post(
          `v1/customers/${asset.data.customer.id}/licenses/microsoft/${asset.data.properties.customerId}` +
            `/subscriptions/${asset.data.properties.subscription.id}/changeQuantity`,
          {
            quantity: subscription.quantity,
            type: asset.data.type,
            total,
            payment: catalogItem.data.payment,
          }
        );
        mixpanel.track(`assets.office.${n > 0 ? "increase" : "decrease"}`, {
          quantity: n,
          sku: catalogItem.data.skuId,
          subscriptionId: asset.data.properties.subscription.id,
        });

        const updateMessage = syncing
          ? "Subscription quantity will be updated soon."
          : "Subscription successfully updated";
        showSnackbar({
          message: updateMessage,
          variant: syncing ? "info" : "success",
          autoHideDuration: 20000,
          action: [
            <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
              <CloseIcon className="snackBarCloseButton" />
            </IconButton>,
          ],
        });
      } catch (error: any) {
        consoleErrorWithSentry(error);
        showSnackbar({
          message: error.response.data?.error || "Order failed ",
          variant: "error",
          autoHideDuration: 20000,
          action: [
            <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
              <CloseIcon className="snackBarCloseButton" />
            </IconButton>,
          ],
        });
      } finally {
        if (!asset.data.properties.syncing) {
          setIsSubmitting(false);
          setSyncing(false);
        }
      }
    },
    [
      api,
      asset.data.customer.id,
      asset.data.properties.customerId,
      asset.data.properties.subscription.id,
      asset.data.properties.syncing,
      asset.data.type,
      catalogItem,
      getTotal,
      hideSnackbar,
      showSnackbar,
      subscription,
      syncing,
    ]
  );

  const handleModifyAssetQuantity = useCallback(
    (val: number) => () => {
      setSubscription((prevSubscription) => {
        if (!prevSubscription) {
          return prevSubscription;
        }

        let quantity = prevSubscription.quantity;

        quantity += val;
        if (quantity <= 0) {
          quantity = 0;
        }
        return {
          ...prevSubscription,
          quantity,
        };
      });
    },
    []
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRemoveDialogOK = useCallback(() => {
    if (!onRemoveAsset) {
      return;
    }

    onRemoveAsset();
    setAckAssetRemoveDialog(false);
  }, [onRemoveAsset]);

  const changeQuantityAckText = useCallback(
    (currentQuantity: number, quantity: number) => {
      if (!catalogItem) {
        return;
      }
      const monthlyPay = catalogItem.data.payment === "MONTHLY";
      const currency = "USD";

      if (quantity === 0) {
        return (
          <>
            {`Approve suspending `}
            <b>{asset.data.properties.subscription.offerName}</b>
            {` for domain `}
            <b>{asset.data.properties.customerDomain}</b>.
          </>
        );
      } else if (currentQuantity < quantity) {
        const n = quantity - currentQuantity;
        const total = getTotal(n);

        return (
          <>
            {`Approve adding `}
            <b>{quantity - currentQuantity}</b>
            {` license/s of `}
            <b>{asset.data.properties.subscription.offerName}</b>
            {` for domain `}
            <b>{asset.data.properties.customerDomain}</b>
            {!!total && (
              <>
                {` at total of `}
                <b>{formatCurrency(total, currency)}</b>
                <b>{monthlyPay ? " per month." : " pro-rated."}</b>
              </>
            )}
          </>
        );
      } else {
        return (
          <>
            {`Approve removing `}
            <b>{currentQuantity - quantity}</b>
            {` license/s of `}
            <b>{asset.data.properties.subscription.offerName}</b>
            {` for domain `}
            <b>{asset.data.properties.customerDomain}</b>.
          </>
        );
      }
    },
    [asset.data.properties.customerDomain, asset.data.properties.subscription.offerName, catalogItem, getTotal]
  );

  const handleResetChanges = useCallback(() => {
    setSubscription(asset.data.properties.subscription);
    setSyncing(asset.data.properties.syncing ?? false);
  }, [asset.data.properties.subscription, asset.data.properties.syncing]);

  if (!subscription) {
    return null;
  }

  const endDate = DateTime.fromISO(asset.data.properties.subscription.commitmentEndDate);
  const isExpire = endDate.year < 2100;

  const currentQuantity = asset.data.properties.subscription.quantity;
  const quantity = subscription.quantity;
  const hasPendingChanges = currentQuantity !== quantity;
  let removeTooltip = "Remove license";
  let removeDisabled = false;

  let expiration = (
    <Typography
      variant="subtitle2"
      sx={{
        display: "inline",
      }}
    >
      {isExpire ? endDate.toLocaleString(DateTime.DATE_FULL) : "Never"}
    </Typography>
  );

  let title = (
    <Typography
      variant="button"
      sx={{
        whiteSpace: "normal",
        display: "inline",
      }}
    >
      {`${toSentenceCase(asset.data.properties.subscription.offerName)} flexible`}
    </Typography>
  );

  if (asset.data.properties.settings) {
    if (asset.data.properties.settings.plan) {
      title = (
        <>
          <Typography
            variant="button"
            sx={{
              whiteSpace: "normal",
              display: "inline",
            }}
          >
            {asset.data.properties.subscription.offerName}
          </Typography>{" "}
          <Typography
            variant="button"
            className={clsx({
              [classes.overrode]: isDoitEmployee,
            })}
            sx={{
              whiteSpace: "normal",
              display: "inline",
            }}
          >
            {asset.data.properties.settings.plan.planName}
          </Typography>
        </>
      );

      if (asset.data.properties.settings.plan.isCommitmentPlan) {
        removeDisabled = true;
        removeTooltip = "Cannot remove seats on a commitment plan";
        const endTime = DateTime.fromMillis(asset.data.properties.settings.plan.commitmentInterval.endTime);
        if (endTime.isValid) {
          expiration = (
            <Typography
              variant="subtitle2"
              className={clsx({
                [classes.overrode]: isDoitEmployee,
              })}
              sx={{
                display: "inline",
              }}
            >
              {endTime.toLocaleString(DateTime.DATE_FULL)}
            </Typography>
          );
        }
      }
    }
  }

  if (catalogLoading) {
    return null;
  }

  return (
    <>
      <Box ref={domRef}>
        <Grid
          style={{
            height: !widget
              ? undefined
              : (() => {
                  switch (widgetHeight) {
                    case 2:
                      return 456;
                    case 1.5:
                      return 356;
                    default:
                      return 256;
                  }
                })(),
          }}
        >
          <Card className={clsx({ [classes.highlight]: highlight })}>
            <WidgetCardHeader
              avatar={<Avatar alt="Office 365" src={Office365Icon} />}
              action={
                <>
                  <IconButton
                    aria-owns={anchorEl ? "settings-menu" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="settings-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    role="button"
                    tabIndex={0}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                  >
                    {isDoitEmployee && (
                      <MenuItem key="reseller" disabled divider>
                        {asset.data.properties.reseller}
                      </MenuItem>
                    )}
                    {(isDoitEmployee || userRoles.billingProfilesAdmin) && !widget && (
                      <MenuItem key="assign" onClick={onAssignToEntity} divider={!isDoitOwner}>
                        Billing Profile Assignment
                      </MenuItem>
                    )}
                    {isDoitOwner && [
                      <MenuItem
                        key="settings"
                        onClick={() => {
                          setSettingsDialog(true);
                        }}
                      >
                        Asset Settings
                      </MenuItem>,
                      !widget && (
                        <MenuItem
                          key="remove"
                          onClick={() => {
                            setAckAssetRemoveDialog(true);
                          }}
                          divider
                        >
                          Remove Asset
                        </MenuItem>
                      ),
                    ]}
                    {userRoles.invoicesViewer && (
                      <MenuItem key="tag" onClick={onAddTag}>
                        Tag Asset
                      </MenuItem>
                    )}
                    <MenuItem href="https://admin.microsoft.com/AdminPortal/Home#/homepage" component="a">
                      Manage Domain
                    </MenuItem>
                  </Menu>
                </>
              }
              title={title}
              subheader={
                <div style={{ height: 25 }}>
                  {asset.data.properties.customerDomain}
                  {asset.data.tags?.map((tag) => (
                    <Chip style={{ marginRight: 4, marginLeft: 4 }} size="small" key={tag} label={tag} />
                  ))}
                </div>
              }
            />
            <CardContent className={classes.cardContent} sx={{ height: widgetHeight - 64 }}>
              <div className={classes.infoDiv}>
                <div>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "inline",
                    }}
                  >
                    Quantity:{" "}
                  </Typography>
                  {!syncing ? (
                    <>
                      <Typography
                        variant="subtitle2"
                        className={clsx({
                          [classes.increase]: currentQuantity < quantity,
                          [classes.decrease]: currentQuantity > quantity,
                        })}
                        sx={{
                          display: "inline",
                        }}
                      >
                        {quantity}
                      </Typography>
                      {hasPendingChanges && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            display: "inline",
                          }}
                        >
                          {` (${currentQuantity < quantity ? "+" : ""}${quantity - currentQuantity})`}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        display: "inline",
                      }}
                    >
                      Updating...
                    </Typography>
                  )}
                </div>
                <div>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "inline",
                    }}
                  >
                    Expiration:{" "}
                  </Typography>
                  {expiration}
                </div>
                {isExpire && (
                  <div>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "inline",
                      }}
                    >
                      Renewal:{" "}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        display: "inline",
                      }}
                    >
                      {asset.data.properties.subscription.autoRenewEnabled ? "Auto Renew" : "Cancel"}
                    </Typography>
                  </div>
                )}
              </div>
            </CardContent>

            <CardActions className={classes.actions} disableSpacing>
              {userRoles.assetsManager && (
                <Grid
                  container
                  wrap="nowrap"
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  {isEditable && (
                    <Grid container>
                      <Tooltip title={removeTooltip}>
                        <Grid>
                          <IconButton
                            onMouseDown={handleModifyAssetQuantity(-1)}
                            disabled={removeDisabled || isSubmitting || syncing}
                            className={classes.startAction}
                            size="large"
                          >
                            <RemoveIcon
                              color="inherit"
                              className={clsx({
                                [classes.decrease]: !(removeDisabled || isSubmitting || syncing),
                              })}
                              fontSize="small"
                            />
                          </IconButton>
                          <></>
                        </Grid>
                      </Tooltip>
                      <Tooltip title="Add license">
                        <Grid>
                          <IconButton
                            onMouseDown={handleModifyAssetQuantity(1)}
                            className={classes.startAction}
                            disabled={isSubmitting || syncing}
                            size="large"
                          >
                            <AddIcon
                              color="inherit"
                              className={clsx({
                                [classes.increase]: !(isSubmitting || syncing),
                              })}
                              fontSize="small"
                            />
                          </IconButton>
                          <></>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  )}

                  <Grid
                    container
                    sx={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {hasPendingChanges && !asset.data.properties.syncing && (
                      <Fade in={hasPendingChanges}>
                        <Grid
                          container
                          sx={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Reset changes">
                            <Grid>
                              <IconButton
                                onClick={handleResetChanges}
                                disabled={isSubmitting}
                                className={classes.endAction}
                                size="large"
                              >
                                <ResetIcon color="inherit" fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Tooltip>

                          <Grid>
                            <LoadingButton
                              color="primary"
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                setAckSubscriptionChangeQuantityDialog(true);
                              }}
                              disabled={!hasPendingChanges || isSubmitting}
                              loading={isSubmitting}
                              className={classes.endAction}
                              mixpanelEventId="assets.office365-asset-card.apply"
                            >
                              APPLY
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Fade>
                    )}

                    <Grid>{unassignedAction}</Grid>
                  </Grid>
                </Grid>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Box>
      {settingsDialog && (
        <AssetSettingsDialog
          onClose={() => {
            setSettingsDialog(false);
          }}
          asset={asset}
          catalogItem={catalogItem}
        />
      )}
      {ackSubscriptionChangeQuantityDialog && (
        <AckDialog
          open={ackSubscriptionChangeQuantityDialog}
          onCancel={() => {
            setAckSubscriptionChangeQuantityDialog(false);
          }}
          onAccept={() => handleSubscriptionChangeQuantity(currentQuantity, quantity)}
          title={
            quantity === 0 ? "Suspend subscription" : currentQuantity < quantity ? "Add licenses" : "Remove licenses"
          }
          text={changeQuantityAckText(currentQuantity, quantity)}
        />
      )}
      {isDoitOwner && ackAssetRemoveDialog && (
        <RemoveDialog
          open={ackAssetRemoveDialog}
          onCancel={() => {
            setAckAssetRemoveDialog(false);
          }}
          onAccept={handleRemoveDialogOK}
          name={asset.data.properties.subscription.offerName}
        />
      )}
    </>
  );
}

export const Office365AssetCard = ({
  widgetId,
  fallbackComponent,
  widgetHeight = 200,
}: WidgetItemWithWidgetIdProps) => {
  const data = useAssetDataProps(widgetId);

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (data === null) {
    return fallbackComponent;
  }

  return (
    <Office365AssetCardWithProps
      asset={data.asset as Asset<Office365AssetModel>}
      contract={data.contract}
      widget={true}
      fallbackComponent={fallbackComponent}
      widgetHeight={widgetHeight}
    />
  );
};
