import { type ChangeEvent, Fragment, useEffect, useMemo, useState } from "react";

import { DoitRole, type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  type TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { Formik, type FormikProps, useFormikContext } from "formik";
import { DateTime } from "luxon";
import { object, string } from "yup";

import { useAsyncLoadingFunction } from "../../../Components/hooks/useAsyncLoadingFunction";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { useFullScreen } from "../../../utils/dialog";
import { TimestampFromDate } from "../../../utils/firebase";
import accountNumberExists from "../accountNumberExists";
import { PartialSubmitForm } from "./PartialSubmitForm";
import { type MasterPayerAccountActivationModel } from "./types";
import { type RequestMPAValidation } from "./useMPAValidationRequest";

export type MPAActivationDialogProps = {
  masterPayerAccount: MasterPayerAccountsModel;
  masterPayerAccountId: string;
  onClose: () => void;
  onPartialSubmit: (mpaActivationData: Partial<MasterPayerAccountActivationModel>) => Promise<boolean>;
  onSubmit: (mpaActivationData: MasterPayerAccountActivationModel) => Promise<boolean>;
  onValidate: RequestMPAValidation;
};

const cypressFieldsIds: Omit<Record<keyof MasterPayerAccountActivationModel, string>, "features"> = {
  accountNumber: "mpa-account-number-field",
  cur_bucket: "mpa-cur-bucket-field",
  cur_path: "mpa-cur-path-field",
  roleARN: "mpa-role-arn-field",
  rootEmail: "mpa-root-email-field",
};

export const cypressIds = {
  validateButton: "mpa-validate-button",
  cancelButton: "mpa-cancel-button",
  activateButton: "mpa-activate-button",
  validationSuccessMessage: "mpa-validation-success-message",
  validationErrorMessage: "mpa-validation-error-message",
  fields: cypressFieldsIds,
};

const FIELD_LABELS: Omit<Record<keyof MasterPayerAccountActivationModel, string>, "features"> = {
  accountNumber: "AWS Account Number",
  cur_bucket: "CUR bucket",
  cur_path: "CUR path",
  roleARN: "Role ARN",
  rootEmail: "Root",
};

function getCommonFieldProps(
  { isSubmitting, errors, handleChange, handleBlur, values }: FormikProps<MasterPayerAccountActivationModel>,
  fieldName: keyof MasterPayerAccountActivationModel
): TextFieldProps & { "data-cy": string } {
  const errorMessage = errors[fieldName];
  const hasError = Boolean(values[fieldName] && errorMessage !== undefined);
  const trimValueOnChange = (changeEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeEvent.target.value = changeEvent.target.value.trim();
    handleChange(changeEvent);
  };

  return {
    name: fieldName,
    disabled: isSubmitting,
    helperText: hasError ? errorMessage : undefined,
    error: hasError,
    value: values[fieldName],
    onChange: trimValueOnChange,
    onBlur: handleBlur,
    variant: "outlined",
    label: FIELD_LABELS[fieldName],
    InputLabelProps: { shrink: true },
    fullWidth: true,
    required: true,
    ["data-cy"]: cypressFieldsIds[fieldName],
  };
}

const AccountNumberTextField = ({ formProps }: { formProps: FormikProps<MasterPayerAccountActivationModel> }) => {
  const {
    values: { accountNumber, roleARN },
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<MasterPayerAccountActivationModel>();
  useEffect(() => {
    if (!errors.accountNumber && roleARN === "") {
      setFieldTouched("roleARN");
      setFieldValue("roleARN", `arn:aws:iam::${accountNumber}:role/doitintl_cmp`);
    }
  }, [accountNumber, errors.accountNumber, roleARN, setFieldTouched, setFieldValue, touched.accountNumber]);

  return (
    <Tooltip title="Editing account number is only possible from 'Edit MPA' modal" placement="top">
      <TextField {...getCommonFieldProps(formProps, "accountNumber")} disabled />
    </Tooltip>
  );
};

function getOrgBillingStartMonthOptions(): DateTime[] {
  const now = DateTime.utc();
  const startOfCurrentMonth = now.startOf("month");
  const startOfPreviousMonth = startOfCurrentMonth.minus({ months: 1 });
  const startOfPreviousPreviousMonth = startOfCurrentMonth.minus({ months: 2 });
  const startOfNextMonth = startOfCurrentMonth.plus({ months: 1 });
  const startOfNextTwoMonths = startOfCurrentMonth.plus({ months: 2 });

  return [
    startOfNextTwoMonths,
    startOfNextMonth,
    startOfCurrentMonth,
    startOfPreviousMonth,
    startOfPreviousPreviousMonth,
  ];
}

export const MPAActivationDialog = ({
  masterPayerAccount,
  masterPayerAccountId,
  onClose,
  onPartialSubmit,
  onSubmit,
  onValidate,
}: MPAActivationDialogProps) => {
  const { fullScreen } = useFullScreen();
  const [validationSchema] = useState(() =>
    object({
      accountNumber: string()
        .required()
        .label(FIELD_LABELS.accountNumber)
        .matches(/^\d{12}$/, "Must be a valid AWS account number.")
        .test("accountNumber", "Already used AWS account number", async (value) =>
          value && /^\d{12}$/.test(value) ? !(await accountNumberExists(value, masterPayerAccountId)) : true
        ),
      cur_bucket: string().required().label(FIELD_LABELS.cur_bucket),
      cur_path: string().required().label(FIELD_LABELS.cur_path),
      roleARN: string()
        .required()
        .label(FIELD_LABELS.roleARN)
        .matches(/^arn:aws:iam:\w*:\d{12}:role\/[\w.-]+$/i, "Must be a valid role ARN."),
      rootEmail: string().required().label(FIELD_LABELS.rootEmail).email(),
    })
  );
  const [arePermissionsValid, setPermissionsValid] = useState<boolean>();
  const [validationError, setValidationError] = useState<string>();
  const [validationErrorDetails, setValidationErrorDetails] = useState<string>();
  const [isValidating, validate] = useAsyncLoadingFunction(async (values: MasterPayerAccountActivationModel) => {
    const validationResult = await onValidate(masterPayerAccount.customerId, values);
    setPermissionsValid(validationResult.valid);
    if (!validationResult.valid) {
      setValidationError(validationResult.message);
      setValidationErrorDetails(validationResult.details);
    }
  });

  const isActive = masterPayerAccount.status === "active";
  const orgBillingStartMonthOptions = useMemo(() => getOrgBillingStartMonthOptions(), []);
  const isMpaOpsAdmin = useDoitRoleCheck(DoitRole.MasterPayerAccountOpsAdmin);

  let submitButtonText: string;
  if (isActive) {
    submitButtonText = "Re-confirm activation";
  } else {
    submitButtonText = "Confirm activation";
  }

  const title = `${isActive ? "Edit active" : "Activate"} MPA for ${masterPayerAccount?.friendlyName}`;
  const { accountNumber, cur_bucket, cur_path, roleARN, rootEmail, features } = masterPayerAccount;

  return (
    <Dialog fullScreen={fullScreen} aria-labelledby="mpa-activation-dialog" open maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Formik<MasterPayerAccountActivationModel>
        initialValues={{ accountNumber, cur_bucket, cur_path, roleARN, rootEmail, features }}
        validateOnMount={true}
        validationSchema={validationSchema}
        onSubmit={async (mpaActivationData) => {
          await onSubmit(mpaActivationData);
          onClose();
        }}
      >
        {(formProps) => (
          <PartialSubmitForm<MasterPayerAccountActivationModel> onPartialSubmit={onPartialSubmit}>
            <DialogContent>
              <Stack
                sx={{
                  gap: 2,
                  mt: 1,
                }}
              >
                <AccountNumberTextField formProps={formProps} />
                <TextField {...getCommonFieldProps(formProps, "cur_bucket")} />
                <TextField {...getCommonFieldProps(formProps, "cur_path")} />
                <TextField {...getCommonFieldProps(formProps, "roleARN")} />
                <TextField {...getCommonFieldProps(formProps, "rootEmail")} />
                <FormControlLabel
                  data-cy="new-mpa-dialog-org-import"
                  name="features.import-org"
                  control={<Checkbox />}
                  checked={formProps.values.features?.["import-org"]}
                  disabled={formProps.isSubmitting}
                  onChange={() => {
                    formProps.setFieldValue("features.import-org", !formProps.values.features?.["import-org"]);
                    if (formProps.values.features?.["import-org"] === true) {
                      formProps.setFieldValue("features.org-billing-start-month", undefined);
                    }
                  }}
                  label="Organization import"
                />
                <TextField
                  select
                  disabled={formProps.values.features?.["import-org"] !== true || formProps.isSubmitting}
                  name="features.org-billing-start-month"
                  onChange={(event) =>
                    formProps.setFieldValue(
                      "features.org-billing-start-month",
                      event?.target.value ? TimestampFromDate(new Date(event?.target.value)) : undefined
                    )
                  }
                  label="Billing start month"
                  size="small"
                  onBlur={formProps.handleBlur}
                  error={
                    formProps.touched.features?.["org-billing-start-month"] &&
                    formProps.errors.features?.["org-billing-start-month"] !== undefined
                  }
                  slotProps={{
                    inputLabel: { shrink: true },
                  }}
                >
                  <MenuItem value={undefined}>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      None
                    </Typography>
                  </MenuItem>
                  {orgBillingStartMonthOptions.map((date) => (
                    <MenuItem key={date.toISO()} value={date.toISO() ?? ""}>
                      {date.toFormat("LLLL yyyy")}
                    </MenuItem>
                  ))}
                </TextField>
                {!formProps.values.features?.["import-org"] && (
                  <TextField
                    name="features.no-import-org-reason"
                    value={formProps.values.features?.["no-import-org-reason"] ?? ""}
                    onChange={formProps.handleChange}
                    label="Reason for not importing organization"
                    size="small"
                    slotProps={{
                      inputLabel: { shrink: true },
                    }}
                  />
                )}
                <FormControlLabel
                  data-cy="new-mpa-dialog-nra"
                  name="features.no-root-access"
                  control={<Checkbox />}
                  checked={formProps.values.features?.["no-root-access"]}
                  onChange={formProps.handleChange}
                  label="No root access"
                  disabled={formProps.isSubmitting || !isMpaOpsAdmin}
                />
                <LoadingButton
                  onClick={async () => {
                    await validate(formProps.values);
                    formProps.resetForm({ values: formProps.values });
                  }}
                  loading={isValidating}
                  disabled={!formProps.isValid}
                  variant="outlined"
                  color="primary"
                  sx={{ alignSelf: "flex-start" }}
                  data-cy={cypressIds.validateButton}
                >
                  Validate settings
                </LoadingButton>
                {arePermissionsValid === true && !formProps.dirty && (
                  <Alert severity="success" data-cy={cypressIds.validationSuccessMessage}>
                    The CUR and required permissions were successfully validated.
                  </Alert>
                )}
                {arePermissionsValid === false && !formProps.dirty && (
                  <Alert severity="error" data-cy={cypressIds.validationErrorMessage}>
                    {validationErrorDetails ? (
                      <>
                        <AlertTitle>{validationError}</AlertTitle>
                        {validationErrorDetails?.split("\n").map((line, index) => (
                          <Fragment key={index.toString()}>
                            {index !== 0 && <br />}
                            {line}
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      validationError
                    )}
                  </Alert>
                )}
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={onClose} color="primary" data-cy={cypressIds.cancelButton}>
                Cancel
              </Button>
              <LoadingButton
                onClick={() => {
                  formProps.handleSubmit();
                }}
                loading={formProps.isSubmitting}
                disabled={formProps.dirty || formProps.isSubmitting || !arePermissionsValid}
                variant="contained"
                color="primary"
                data-cy={cypressIds.activateButton}
              >
                {submitButtonText}
              </LoadingButton>
            </DialogActions>
          </PartialSubmitForm>
        )}
      </Formik>
    </Dialog>
  );
};
