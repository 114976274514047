import { useCallback } from "react";

import { generatePath } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/system/Stack";

import { CellsWrapper } from "../../../../../Components/FilterTable/Toolbar/CellsWrapper";
import useRouteMatchURL from "../../../../../Components/hooks/useRouteMatchURL";
import { CoverageCell } from "../../../Common/TableCell/FormattedCoverageCell";
import { DateTimeCell } from "../../../Common/TableCell/FormattedDateTimeCell";
import { RouterLinkCell } from "../../../Common/TableCell/RouterLinkCell";
import { formatAsDollars } from "../../../utils/costUtils";
import { AggregatedPurchaseStatus } from "../Common/Status/AggregatedPurchaseStatus";
import { type CustomerAggregatedPurchaseItem } from "../types";
import { getWorkloadCountByStatus } from "../utils/workloadUtils";

type Props = {
  row: CustomerAggregatedPurchaseItem;
  onEditClick: (purchase: CustomerAggregatedPurchaseItem) => void;
};

const ProgressDetailsCell = ({ row }: Pick<Props, "row">) => {
  const { status, workloads } = row;
  const inProgressWorkloads = getWorkloadCountByStatus(workloads.all, "in-progress");

  if (status === "refreshing") {
    return <CircularProgress size={22} />;
  }
  if (status === "in-progress") {
    return <Typography>{inProgressWorkloads}</Typography>;
  }
  return <></>;
};

const CustomerColumnCell = ({ row }: Pick<Props, "row">) => {
  const { customerId, primaryDomain, status } = row;
  const routeMatchURL = useRouteMatchURL();
  const pathToViewPlan = generatePath(`${routeMatchURL.replace(/\/$/, "")}/:customerId`, {
    customerId: customerId ?? "",
  });
  if (status !== "refreshing" && status !== "not-enough-data") {
    return (
      <RouterLinkCell
        linkText={primaryDomain}
        to={{
          pathname: pathToViewPlan,
          state: { aggregatedPurchase: row },
        }}
      />
    );
  }
  return <TableCell>{primaryDomain}</TableCell>;
};

export const AggregatedPurchasesRow = ({ row, onEditClick }: Props) => {
  const {
    customerType,
    currentOverallCoverage,
    optimizerCoverage,
    purchasePlanPrice,
    targetCoverage,
    timePurchaseDone,
    timeRecommendationUpdated,
    status,
    workloads,
  } = row;
  const handleDialog = useCallback(() => {
    onEditClick(row);
  }, [row, onEditClick]);
  const customerWorkloads = [...workloads.all, ...workloads.pending];
  return (
    <CellsWrapper>
      <CustomerColumnCell row={row} />
      <TableCell>{customerType}</TableCell>
      <TableCell>
        <AggregatedPurchaseStatus status={status} workloads={customerWorkloads} />
      </TableCell>
      <CoverageCell coverage={status === "not-enough-data" ? 0 : currentOverallCoverage} />
      <CoverageCell coverage={status === "not-enough-data" ? 0 : optimizerCoverage} />
      <CoverageCell coverage={targetCoverage} />
      <TableCell>{row.forceTargetCoverage ? "True" : "False"}</TableCell>
      <TableCell>{row.excludeCustomerFromBulk ? "False" : "True"}</TableCell>
      {timePurchaseDone ? <DateTimeCell dateTime={timePurchaseDone} /> : <TableCell>No purchase available</TableCell>}
      {timeRecommendationUpdated ? (
        <DateTimeCell dateTime={timeRecommendationUpdated} />
      ) : (
        <TableCell>No recommendation available</TableCell>
      )}
      <TableCell>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{formatAsDollars(purchasePlanPrice || 0)}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <ProgressDetailsCell row={row} />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleDialog}>
          <EditIcon />
        </IconButton>
      </TableCell>
    </CellsWrapper>
  );
};
