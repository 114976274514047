import { useMemo } from "react";

import { useParams } from "react-router-dom";
import { RampModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { getDateTimeFromFirestoreTimestamp } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import FutureRampPlanAlert from "../components/FutureRampPlanAlert";
import PerformanceChart from "../components/PerformanceChart";
import useGetRampPlan from "../hooks/useGetRampPlan";
import useGetRampPlanChartData from "../hooks/useGetRampPlanChartData";
import { type RampPlanModel } from "../types";
import { RampPlanHeader } from "./Header/RampPlanHeader";
import PeriodList from "./PeriodTables/Tables/PeriodList";
import { RampPlanMetadata } from "./RampPlanMetadata";

const useStyles = makeStyles(() => ({
  rampPage: {
    flexWrap: "nowrap",
    flexDirection: "column",
  },
  chartArea: {
    marginBottom: 10,
    display: "grid",
  },
}));

const updateFuturePeriodFromDialog = (
  planCopy: RampPlanModel,
  periodKey: number,
  cellBaselineCheckbox: boolean,
  thisMonthVal: number,
  cellGrowthPerMonth: number | null,
  periodIndex: number,
  eventCellIndex: number
) => {
  const periodPlanned = planCopy.commitmentPeriods?.[periodKey].planned;
  periodPlanned?.forEach((el, cellKey) => {
    const prevPeriodPlanned = planCopy.commitmentPeriods?.[periodKey - 1]?.planned;
    const lastPlace = prevPeriodPlanned && prevPeriodPlanned?.length - 1;
    const lastInPreviousPeriod = lastPlace ? prevPeriodPlanned[lastPlace] : undefined;
    const prevCell = periodPlanned[cellKey - 1] ?? (planCopy?.commitmentPeriods ? lastInPreviousPeriod : el);
    const baselineVal = cellBaselineCheckbox ? thisMonthVal : periodPlanned[cellKey];
    const newCellVal = // if % input in dialog is set - compound percentage
      cellGrowthPerMonth !== null && cellGrowthPerMonth > 0 ? prevCell * (1 + cellGrowthPerMonth * 0.01) : baselineVal;

    if (periodKey === periodIndex && cellKey > eventCellIndex) {
      periodPlanned[cellKey] = newCellVal;
    }
    if (periodKey > periodIndex) {
      periodPlanned[cellKey] = newCellVal;
    }
  });
};

const updatePlanFromDialogInput = (
  planData: RampPlanModel,
  periodIndex: number,
  eventCellIndex: number,
  cellBaselineCheckbox: boolean,
  cellGrowthPerMonth: number | null
) => {
  const planCopy = planData;
  let thisMonthVal: number;
  planCopy.commitmentPeriods?.forEach((period, periodKey) => {
    if (periodKey === periodIndex) {
      // save curr.month val
      thisMonthVal = period.planned[eventCellIndex];
    }
    if (periodKey >= periodIndex) {
      updateFuturePeriodFromDialog(
        planCopy,
        periodKey,
        cellBaselineCheckbox,
        thisMonthVal,
        cellGrowthPerMonth,
        periodIndex,
        eventCellIndex
      );
    }
  });
};

const RampPlan = () => {
  const { userRoles } = useUserContext();
  const { contracts } = useCustomerContext();
  const hasEditPermission = userRoles?.invoicesViewer ?? false;
  const { rampId } = useParams<{ rampId: string }>();
  const { rampPlanData } = useGetRampPlan(rampId);
  const classes = useStyles();

  const thisPlanContract = contracts.filter((contract) => contract.id === rampPlanData?.contractId)[0];

  mixpanel.track("customers.ramps.ramp-plan-view");

  const { actualDataForChart, planDataForChart, originalPlanForChart, dateTimeEstEndDate } =
    useGetRampPlanChartData(rampPlanData);

  const startDateTime: DateTime<boolean> = useMemo<DateTime<boolean>>((): DateTime<boolean> => {
    if (!rampPlanData?.startDate) {
      return DateTime.invalid("missing");
    }

    return getDateTimeFromFirestoreTimestamp(rampPlanData.startDate).toUTC().startOf("day");
  }, [rampPlanData?.startDate]);

  const futureRampPlan = useMemo<boolean>(
    () => startDateTime.isValid && startDateTime > DateTime.now().toUTC(),
    [startDateTime]
  );

  const updatePlanData = async (
    periodIndex: number,
    cellGrowthPerMonth: number | null,
    eventCellIndex: number,
    cellBaselineCheckbox: boolean,
    isFromDialog: boolean
  ) => {
    if (rampPlanData?.commitmentPeriods) {
      isFromDialog &&
        updatePlanFromDialogInput(rampPlanData, periodIndex, eventCellIndex, cellBaselineCheckbox, cellGrowthPerMonth);
      await getCollection(RampModel).doc(rampId).update({
        commitmentPeriods: rampPlanData.commitmentPeriods,
      });
    }
  };

  if (!rampPlanData) {
    return null;
  }

  return (
    <>
      {futureRampPlan && (
        <Box
          sx={{
            my: 2,
          }}
        >
          <FutureRampPlanAlert startDateTime={startDateTime} />
        </Box>
      )}
      <RampPlanHeader rampPlan={rampPlanData} />
      <Stack
        data-testid="ramp-page"
        className={classes.rampPage}
        sx={{
          gap: 6,
        }}
      >
        <PerformanceChart
          actualDataForChart={actualDataForChart}
          planDataForChart={planDataForChart}
          originalPlanDataForChart={originalPlanForChart}
          performanceChartProps={{
            cardProps: {},
            options: {},
          }}
        />
        <RampPlanMetadata rampPlan={rampPlanData} dateTimeEstEndDate={dateTimeEstEndDate} />
        <PeriodList
          hasEditPermission={hasEditPermission}
          planData={rampPlanData}
          updatePlanData={updatePlanData}
          showRollover={thisPlanContract?.commitmentRollover}
        />
      </Stack>
    </>
  );
};

export default RampPlan;
