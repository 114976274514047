import { Box } from "@mui/material";

import { PageHeader } from "../../../Components/PageLayout/PageHeader/PageHeader";
import { type Customer } from "../../../types";
import { TimeFilter, type TimeFilterProps } from "./TimeFilter";

type Props = {
  customer: Customer;
  title: string;
  backButtonTakeSpotScalingMainPage?: boolean;
  timeFilterProps: TimeFilterProps;
};
export const PageHeaderWithTimeFilter = ({
  customer,
  title,
  backButtonTakeSpotScalingMainPage = false,
  timeFilterProps,
}: Props) => (
  <PageHeader
    title={title}
    subheader={customer.name}
    backButtonRoute={`/customers/${customer.id}${backButtonTakeSpotScalingMainPage ? "/spot-scaling" : ""}`}
    action={
      <Box
        sx={{
          pr: 2,
        }}
      >
        <TimeFilter {...timeFilterProps} />
      </Box>
    }
  />
);
