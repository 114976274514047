import "react-phone-input-2/lib/style.css";

import { useCallback, useState } from "react";

import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import { UserModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import has from "lodash/has";

import LoadingButton from "../../Components/LoadingButton";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardsContext } from "../../Context/DashboardContext";
import { useUserContext } from "../../Context/UserContext";
import { ThemeModes } from "../../muiThemeTypes";
import { jobFunction, jsUcfirst } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";

const useStyles = makeStyles({
  root: {},
  content: {
    minHeight: 300,
    flex: 0,
  },
  dropDown: {
    marginTop: 0,
  },
  actions: {
    marginRight: 16,
    marginBottom: 16,
  },
  margin: {
    marginTop: 16,
  },
});

type Props = {
  onClose: () => void;
};

const PhoneDialog = ({ onClose }: Props) => {
  const { userModel: user } = useUserContext({ allowNull: false });
  const { userId } = useAuthContext({ mustHaveUser: true });
  const { dashboards, setDefaultDashboard } = useDashboardsContext();
  const [phone, setPhone] = useState(user.phone || "");
  const [phoneError, setPhoneError] = useState(false);
  const [userJobFunction, setUserJobFunction] = useState(user.jobFunction);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [phoneExtension, setPhoneExtension] = useState(user.phoneExtension || "");
  const { customer } = useCustomerContext();
  const history = useHistory();
  const [selectedDashboard, setSelectedDashboard] = useState<string>();
  const classes = useStyles();
  const { fullScreen } = useFullScreen();
  const theme = useTheme();

  const handleChangePhoneNumber = useCallback((value) => {
    setPhone(value);
    setPhoneError(false);
  }, []);

  const handleChangeJobFunction = useCallback((event) => {
    setUserJobFunction(event.target.value);
    setError(false);
  }, []);

  const handleChangeExt = useCallback((data) => {
    setPhoneExtension(data.target.value);
  }, []);

  const updateDashboard = useCallback(async () => {
    if (!selectedDashboard) {
      return;
    }

    await setDefaultDashboard(selectedDashboard);
    await getCollection(UserModel).doc(userId).update({ phone, phoneExtension, jobFunction: userJobFunction });
  }, [phone, phoneExtension, selectedDashboard, setDefaultDashboard, userId, userJobFunction]);

  const handleSubmit = useCallback(() => {
    if (phone.length > 4 || userJobFunction || selectedDashboard) {
      setLoading(true);
      updateDashboard()
        .then(() => {
          setError(false);
          setPhoneError(false);
          history.push(`/customers/${customer.id}/dashboards/${selectedDashboard}`);
          onClose();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!userJobFunction) {
        setError(true);
      }
      if (phone.length < 4) {
        setPhoneError(true);
      }
    }
  }, [customer.id, history, onClose, phone.length, selectedDashboard, updateDashboard, userJobFunction]);

  let country = "us";
  if (has(user, "enrichment.geo.countryCode") && user?.enrichment?.geo?.countryCode) {
    country = user.enrichment.geo.countryCode.toLowerCase();
  }

  const displayName = user.firstName;
  const organization = customer.name;

  return (
    <Dialog open={true} fullScreen={fullScreen} aria-labelledby="form-dialog-phone" maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        {displayName?.length > 0
          ? `${jsUcfirst(displayName)}, please complete your profile`
          : `Please complete your profile`}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>To make your experience even better, could you please provide the missing information?</Typography>
        <br />
        <Typography variant="caption">Please enter phone number where we can reach you</Typography>
        <Grid container spacing={1}>
          <Grid size={9}>
            <PhoneInput
              value={phone}
              country={country}
              inputStyle={{
                height: 40,
                width: "100%",
                backgroundColor: "transparent",
                color: theme.palette.text.primary,
              }}
              searchStyle={{
                margin: 0,
                width: "calc(100% - 10px)",
              }}
              buttonStyle={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.secondary,
              }}
              dropdownStyle={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.mode === ThemeModes.DARK ? theme.palette.primary.main : theme.palette.text.primary,
              }}
              enableSearch
              disableSearchIcon
              searchPlaceholder="Search..."
              inputProps={{
                required: true,
              }}
              onChange={handleChangePhoneNumber}
            />
          </Grid>
          <Grid size={3}>
            <TextField
              id="standard-select-phone-ext"
              name="phoneExtension"
              variant="outlined"
              label="Ext"
              fullWidth
              value={phoneExtension}
              onChange={handleChangeExt}
              style={{ marginTop: 0 }}
              margin="dense"
            />
          </Grid>
        </Grid>
        {phoneError && <Typography color="error">Please enter your phone number</Typography>}
        <div style={{ height: 24 }} />
        <Typography variant="caption">What best describes your role at {organization}</Typography>
        <TextField
          id="standard-select-jobFunction"
          className={classes.dropDown}
          name="jobFunction"
          select
          variant="outlined"
          fullWidth
          value={userJobFunction}
          onChange={handleChangeJobFunction}
          margin="dense"
          error={error}
        >
          {jobFunction.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          value={selectedDashboard ?? ""}
          onChange={(e) => {
            setSelectedDashboard(e.target.value);
          }}
          margin="dense"
          label="Default Dashboard"
          variant="outlined"
          select
          fullWidth
          className={classes.margin}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        >
          {dashboards.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} color="primary">
          Not Now
        </Button>

        <LoadingButton
          onClick={handleSubmit}
          size="medium"
          color="primary"
          variant="contained"
          disabled={loading || !selectedDashboard}
          loading={loading}
          mixpanelEventId="support.phone.save"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PhoneDialog;
