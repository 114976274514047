import { useMemo } from "react";

import { LinearProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";

import { misc } from "../../../constants/cypressIds";

const StyledLinearProgress = styled((props) => <LinearProgress {...props} />)`
  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.barcolor};
  }
  .MuiLinearProgress-colorPrimary {
    background-color: ${(props) => props.rootcolor};
  }
`;

const BudgetUtilizationProgressBar = ({ value, color }) => {
  const theme = useTheme();
  const progressValue = isNaN(value) ? 0 : value > 100 ? 100 : value;
  const labelValue = useMemo(() => {
    if (isNaN(value)) {
      return "0%";
    }
    if (value > 1000) {
      return ">1000%";
    }
    return `${Math.round(value)}%`;
  }, [value]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          mr: 1,
        }}
      >
        <StyledLinearProgress
          style={{ height: "16px", borderRadius: theme.spacing(1) }}
          variant="buffer"
          valueBuffer={100}
          value={progressValue}
          color="primary"
          barcolor={color.bar}
          rootcolor={color.root}
        />
      </Box>
      <Box
        sx={{
          minWidth: 35,
        }}
      >
        <Typography variant="body2" color="textSecondary" data-cy={misc.percentage}>
          {labelValue}
        </Typography>
      </Box>
    </Box>
  );
};

export default BudgetUtilizationProgressBar;
