import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  barColorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    marginTop: 9,
  },
  barLabel: {},
});
function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid size={8}>
        <LinearProgress
          classes={{
            colorPrimary: classes.barColorPrimary,
          }}
          className={classes.bar}
          variant="determinate"
          {...props}
        />
      </Grid>
      <Grid>
        <Typography variant="caption" color="textSecondary">
          {props.label}
        </Typography>
      </Grid>
    </Grid>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ progress, label }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel label={label} value={progress} />
    </div>
  );
}
