import { Box } from "@mui/material";

import { useDocumentElement } from "../../Components/hooks/useDocumentElement";
import { type IntegrationsPageId } from "../../Navigation/config/pages";
import { Jira } from "./Jira/Jira";
import { MsTeams } from "./MsTeams/MsTeams";
import { Slack } from "./Slack/Slack";
import Zapier from "./Zapier/Zapier";

const Integrations = ({ pageId }: { pageId: IntegrationsPageId }) => {
  const [zapierScriptsLoaded] = useDocumentElement({
    scriptSrc: "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js",
    linkHref: "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css",
  });

  return (
    <Box
      sx={{
        pt: 1,
      }}
    >
      {pageId === "integrations:zapier" && zapierScriptsLoaded && <Zapier />}
      {pageId === "integrations:slack" && <Slack />}
      {pageId === "integrations:jira" && <Jira />}
      {pageId === "integrations:ms-teams" && <MsTeams />}
    </Box>
  );
};

export default Integrations;
