import { Fragment } from "react";

import { Link } from "react-router-dom";
import { AssetTypeOffice365, type Office365AssetModel } from "@doitintl/cmp-models";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { amber } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type Asset } from "../../../types";
import Office365AssetCard from "../Cards/Office365AssetCard";
import { type AssetsTabPropsOuterProps } from "../types";
import NoAssets from "./components/NoAssets";

const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {},
  cardsArea: {
    padding: 0,
    marginTop: 5,
  },
  cardContent: {},
  cardActions: {
    paddingTop: theme.spacing(2),
    justifyContent: "flex-end",
  },
  warningIcon: {
    color: amber[500],
  },
}));

type Props = AssetsTabPropsOuterProps;

const Office365AssetsTab = (props: Props) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { contracts } = useCustomerContext();
  const routeMatchURL = useRouteMatchURL();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const classes = useStyles();
  const { assets } = props;

  if (assets === undefined) {
    return null;
  }

  if (assets.length === 0) {
    return (
      <NoAssets assetType={AssetTypeOffice365}>
        {userRoles.assetsManager ? (
          <Button variant="contained" component={Link} to={`${routeMatchURL}/create`}>
            New subscription
          </Button>
        ) : (
          <></>
        )}
      </NoAssets>
    );
  }

  const now = DateTime.utc().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return (
    <Fragment>
      <CardActions className={classes.cardActions}>
        {userRoles?.assetsManager && [
          <Button
            key="add-office-365-subscription"
            color="primary"
            variant="contained"
            size="small"
            aria-label="Add New Subscription"
            component={Link}
            to={`${routeMatchURL}/create`}
            data-cy="office365AssetsNewButton"
          >
            New subscription
          </Button>,
        ]}
      </CardActions>
      <CardContent className={classes.cardsArea}>
        <Grid container spacing={2} direction="row">
          {assets.map((asset) => {
            const highlight = asset.id === props.highlight;
            const unassignedAction = isDoitEmployee && !asset.data.entity && (
              <Tooltip title="Asset is not assigned to an entity">
                <IconButton onClick={props.onAssignToEntity(asset)} size="large">
                  <ErrorIcon className={classes.warningIcon} />
                </IconButton>
              </Tooltip>
            );
            const contract = contracts.find((contractItem) => {
              if (!contractItem.entity || !asset.data.entity) {
                return false;
              }
              if (contractItem.entity.id !== asset.data.entity.id) {
                return false;
              }
              if (!contractItem.endDate) {
                return true;
              }
              const endDate = DateTime.fromJSDate(contractItem.endDate.toDate()).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              });
              return now <= endDate;
            });
            return (
              <Grid
                key={asset.ref.path}
                size={{
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4,
                  xl: 4,
                }}
              >
                <Office365AssetCard
                  asset={asset as Asset<Office365AssetModel>}
                  contract={contract}
                  unassignedAction={unassignedAction}
                  highlight={highlight}
                  onAssignToEntity={props.onAssignToEntity(asset)}
                  onRemoveAsset={props.onRemoveAsset(asset)}
                  onAddTag={() => {
                    props.onAddTag(asset);
                  }}
                  widget={false}
                  isCustomizeMode={false}
                  fallbackComponent={null}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default Office365AssetsTab;
