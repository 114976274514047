import { type FlexsaveType } from "@doitintl/cmp-models";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";

type Props = {
  type: FlexsaveType;
  value: string;
  loading: boolean;
};

export const FlexsaveRow = ({ type, value, loading }: Props) => (
  <Stack
    sx={{
      flexDirection: "row",
      alignItems: "center",
    }}
  >
    <Box>Flexsave {type}: </Box>
    {loading ? (
      <CircularProgress size={14} />
    ) : (
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 500,
          pl: 0.5,
        }}
      >
        {value}
      </Typography>
    )}
  </Stack>
);
