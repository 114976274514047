import { Box, Container, Stack, Typography } from "@mui/material";

import { FAQAccordion } from "../../../Components/FAQ/FAQAccordion";
import { type JourneyStep } from "./constants";
import { getFAQStep } from "./utils";

type Props = {
  step: JourneyStep;
};

export default function AWSFAQ({ step }: Props) {
  return (
    <Container>
      <Box
        sx={{
          pt: 5,
        }}
      >
        <Stack spacing={2}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "500",
            }}
          >
            Frequently asked questions
          </Typography>
          <FAQAccordion faqs={getFAQStep(step)} />
        </Stack>
      </Box>
    </Container>
  );
}
