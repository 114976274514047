import { Stack } from "@mui/material";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { Loader } from "../../../Components/Loader";
import { filterColumns, headerColumns } from "./columns";
import { GCPPriceListRow } from "./FlexsaveGCPPricelistRow";
import { FlexsaveGCPPricelistTitle } from "./FlexsaveGCPPricelistTitle";
import { useGCPSkuPrices } from "./hooks";
import { type GCPPricelistItem } from "./types";

export const FlexsaveGCPPricelist = () => {
  const [items] = useGCPSkuPrices();
  return (
    <Stack
      spacing={0.5}
      sx={{
        py: 2,
      }}
    >
      <FlexsaveGCPPricelistTitle />
      <Loader loading={!items}>
        <FilterTable<GCPPricelistItem>
          tableItems={items}
          rowComponent={GCPPriceListRow}
          headerColumns={headerColumns}
          filterColumns={filterColumns}
          persistenceKey="flexsave_gcp_sku_prices"
        />
      </Loader>
    </Stack>
  );
};
