import { useState, useEffect } from "react";
import { object, string, number } from "yup";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";

import memoizeOne from "memoize-one";
import { BillingModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { assetTypeName } from "../../../utils/common";
import { serverTimestamp } from "../../../utils/firebase";

import LoadingButton from "../../../Components/LoadingButton";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import { consoleErrorWithSentry } from "../../../utils";
import { CurrencyOptions } from "../../CloudAnalytics/utilities";
import { globalText } from "../../../assets/texts";
import { useApiContext } from "../../../api/context";
const initForm = { type: "", value: "", currency: "USD", description: "" };
const cogsRef = memoizeOne(() => getCollection(BillingModel).doc("cogs").collection("cogsLines"));

const Schema = object().shape({
  type: string().required(),
  description: string(),
  currency: string().required(),
  value: number().typeError("Must be a number").required(),
});

function AddCogs({ open, onClose, date }) {
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState(initForm);
  const api = useApiContext();
  const { fullScreen } = useFullScreen();

  const updateField =
    (name) =>
    async ({ target }) => {
      switch (name) {
        case "value": {
          const { value } = target;
          let v = "";
          if (value !== "") {
            v = Number(value);
          }
          setFormData({
            ...formData,
            [name]: v,
          });
          break;
        }
        default:
          setFormData({
            ...formData,
            [name]: target.value,
          });
      }
    };

  useEffect(() => {
    (async () => {
      try {
        await Schema.validate(formData);
        setIsValid(true);
      } catch (error) {
        setIsValid(false);
      }
    })();
  }, [formData]);

  const handleSubmit = async () => {
    setLoading(true);
    let { value } = formData;
    if (formData.currency !== "USD") {
      try {
        const result = await api.request({
          method: "get",
          url: "/fixer/convert",
          params: {
            from: formData.currency,
            to: "USD",
            amount: formData.value,
            date: date.endOf("month").toFormat("yyyy-LL-dd"),
          },
        });
        if (result.data.success) {
          value = result.data.result;
        } else {
          setLoading(false);
          return;
        }
      } catch (error) {
        consoleErrorWithSentry(error);
        setLoading(false);
        return;
      }
    }

    try {
      await cogsRef().add({
        value,
        type: formData.type,
        description: formData.description,
        date: date.toJSDate(),
        timestamp: serverTimestamp(),
      });
      onClose();
    } catch (error) {
      consoleErrorWithSentry(error);
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      onClose={preventOnCloseWhile(loading, onClose)}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      TransitionProps={{
        onEnter: () => {
          setLoading(false);
          setIsValid(false);
          setFormData(initForm);
        },
      }}
    >
      <DialogTitle id="form-dialog-title">Cost of goods sold - {date.toFormat("LLLL yyyy")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid size={12}>
            <TextField
              select
              fullWidth
              label="Vendor"
              margin="dense"
              variant="outlined"
              value={formData.type}
              onChange={updateField("type")}
              slotProps={{
                select: {
                  MenuProps: {
                    MenuListProps: {
                      dense: true,
                    },
                  },
                },
              }}
            >
              {["amazon-web-services", "g-suite", "google-cloud", "microsoft-azure", "office-365"].map((option) => (
                <MenuItem key={option} value={option}>
                  {assetTypeName(option)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid size={6}>
            <TextField
              fullWidth
              label="Amount"
              margin="dense"
              variant="outlined"
              type="number"
              value={formData.value}
              onChange={updateField("value")}
              slotProps={{
                htmlInput: { step: "0.01" },
              }}
            />
          </Grid>

          <Grid size={6}>
            <TextField
              select
              fullWidth
              label="Currency"
              margin="dense"
              variant="outlined"
              value={formData.currency}
              onChange={updateField("currency")}
              slotProps={{
                select: {
                  MenuProps: {
                    MenuListProps: {
                      dense: true,
                    },
                  },
                },
              }}
            >
              {CurrencyOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid size={12}>
            <TextField
              fullWidth
              label="Description"
              margin="dense"
              variant="outlined"
              value={formData.description}
              onChange={updateField("description")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} disabled={loading}>
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={loading || !isValid}
          loading={loading}
          mixpanelEventId="billing.cogs.insert"
        >
          {globalText.INSERT}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddCogs;
