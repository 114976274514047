import { useCallback, useMemo, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Checkbox,
  DialogActions,
  Divider,
  FormControlLabel,
  InputAdornment,
  Link,
  Menu,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { labelsText } from "../../../../assets/texts";
import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type AnalyticsResources } from "../../analyticsResources/types";
import IndicatorLabel from "../../labels/IndicatorLabel";
import { LABEL_COLORS } from "../consts";
import { useCreateLabel } from "../hooks";
import { type LabelColor, type LabelWithRef } from "../types";
import useSelectedLabels from "./useSelectedLabels";

type AssignLabelsMenuProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onSave: (labelsIds: { add: string[]; remove: string[] }) => void;
  labels: LabelWithRef[];
  selectedResources: AnalyticsResources[];
};

// Shows a list of available labels and returns array of selected label IDs through onClose callback
export const AssignLabelsMenu = ({
  labels,
  selectedResources,
  anchorEl,
  open,
  onClose,
  onSave,
}: AssignLabelsMenuProps) => {
  const [filter, setFilter] = useState<string>("");
  const customerId = useCustomerId();
  const { selectedLabels, setSelectedLabels, getSelectedLabelsResult } = useSelectedLabels(labels, selectedResources);
  const createLabel = useCreateLabel();
  const filtered = labels?.filter((l) => filter === "" || l.data.name.includes(filter));
  const getRandomColor = useCallback(
    (): string => LABEL_COLORS[Math.floor(Math.random() * LABEL_COLORS.length)].color,
    []
  );
  const handleCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      const labelId = e.target.value;
      setSelectedLabels(labelId, checked);
    },
    [setSelectedLabels]
  );

  const saveDisabled = useMemo(() => {
    const { add, remove } = getSelectedLabelsResult();
    return !add.length && !remove.length;
  }, [getSelectedLabelsResult]);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  return (
    <Menu
      id="assign-labels-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{ "aria-labelledby": "assign-labels-button" }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <TextField
        variant="outlined"
        autoFocus
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        value={filter}
        sx={{ paddingX: "8px", width: "360px" }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
      <Stack
        spacing={1}
        sx={{
          px: 2,
          py: 1,
          maxHeight: "300px",
          overflow: "auto",
        }}
      >
        {filtered.map((label) => (
          <FormControlLabel
            key={label.ref.id}
            label={<IndicatorLabel label={label.data.name} color={label.data.color as LabelColor} indicatorSize={10} />}
            control={
              <Checkbox
                value={label.ref.id}
                checked={selectedLabels[label.ref.id]?.checked ?? false}
                indeterminate={selectedLabels[label.ref.id]?.indeterminate ?? false}
                onChange={handleCheckbox}
              />
            }
          />
        ))}
        {labels.length === 0 && (
          <Link
            component={RouterLink}
            to={`/customers/${customerId}/analytics/labels`}
            color="primary"
            variant="body2"
            underline="none"
            sx={{
              my: 1,
            }}
          >
            Create your first label
          </Link>
        )}
        {filter && !filtered.filter((l) => l.data.name === filter).length ? (
          <Button
            onClick={() =>
              createLabel(
                { name: filter, color: getRandomColor() } as any,
                () => {
                  successSnackbar(labelsText.CREATE_LABEL_SUCCESS);
                },
                () => {
                  errorSnackbar(labelsText.CREATE_LABEL_ERROR);
                }
              )
            }
          >
            <Stack direction="row" spacing={1} sx={{ maxWidth: "310px", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Typography noWrap>{filter}</Typography>
              <Typography>(Create&nbsp;new&nbsp;label)</Typography>
            </Stack>
          </Button>
        ) : null}
      </Stack>
      <Divider />
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onSave(getSelectedLabelsResult());
          }}
          disabled={saveDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Menu>
  );
};
