import { AvaOpeningSource, useAvaDialogContext } from "@doitintl/ava-components/src/Common/AvaDialogContextProvider";
import { Divider, Link, Stack, type SxProps, type Theme, Typography } from "@mui/material";

import { useIsDCIEssentialsTier } from "../hooks/useIsDCIEssentialsTier";

type Props = {
  sx?: SxProps<Theme>;
};

const UnlockSupportOptionsDropdownBox = ({ sx }: Props) => {
  const { handleOpenAvaDialog } = useAvaDialogContext();
  const isDCIEssentialsTier = useIsDCIEssentialsTier();
  return (
    <>
      <Divider />
      <Stack
        sx={[
          {
            display: "flex",
            justifyContent: "center",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          Unlock all support options
        </Typography>
        {isDCIEssentialsTier ? (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              justifyContent: "center",
            }}
          >
            <Typography>Contact your Account Manager to upgrade or</Typography>
            <Link
              variant="body1"
              underline="none"
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onClick={() => {
                handleOpenAvaDialog(AvaOpeningSource.PLATFORM_DROPDOWN);
              }}
              sx={{
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              Ask Ava
            </Link>
          </Stack>
        ) : (
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            Contact your Account Manager to upgrade your Solve plan
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default UnlockSupportOptionsDropdownBox;
