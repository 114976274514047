import { type ReactNode, type SyntheticEvent, useCallback, useEffect, useState } from "react";

import { type CloudFlowNodeType } from "@doitintl/cmp-models/src/Cloudflow";
import { Alert, Box, Button, Tab, Tabs, Typography } from "@mui/material";
import noop from "lodash/noop";

import { Loader } from "../../../../../Components/Loader";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { httpStatusMap } from "../../../../../utils/common";
import {
  ApiActionParametersForm,
  GenericApiActionParametersForm,
} from "../../ApiActionParametersForm/ApiActionParametersForm";
import CloudSpecificParameterForm from "../../ApiActionParametersForm/CloudSpecificParameterForm";
import { useGetOperationById } from "../../Common/hooks/useGetOperationById";
import { useUnwrappedApiActionModel } from "../../Common/hooks/useUnwrappedApiActionModel";
import { removeReferenceFields } from "../../utils/testUtils";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import { useTestNode } from "../TestNodeContext";

type TabKeys = "in" | "out";

type TabProps = {
  children?: ReactNode;
  index: TabKeys;
  value: TabKeys;
};

function TabPanel({ index, value, children }: TabProps) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box
          sx={{
            pt: 2,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const TestsTab = () => {
  const { state, isLoading, mutate: testNode, testInputValid } = useTestNode();
  const [currentTab, setCurrentTab] = useState<TabKeys>("in");
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const handleTestNode = () => {
    testNode(undefined, {
      onSuccess: () => {
        successSnackbar("Test node successful");
      },
      onError: () => {
        errorSnackbar("Test node failed");
      },
    });
  };

  const handleChange = (_: SyntheticEvent, newVal: TabKeys) => {
    setCurrentTab(newVal);
  };

  useEffect(() => {
    if (isLoading && currentTab !== "out") {
      setCurrentTab("out");
    }
  }, [isLoading, currentTab]);

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 500,
        }}
      >
        Test this step
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          mt: 1,
        }}
      >
        Testing allows you to confirm if the correct data is moving in and out of this step in your CloudFlow.
      </Typography>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Button variant="outlined" onClick={handleTestNode} disabled={!testInputValid}>
          Test
        </Button>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={currentTab} onChange={handleChange} sx={{ pt: 2 }}>
          <Tab label="Data in" value="in" sx={{ textTransform: "none" }} />
          <Tab label="Data out" value="out" sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index="in">
        <DataInTab />
      </TabPanel>
      <TabPanel value={currentTab} index="out">
        <DataOutTab {...state} loading={isLoading} />
      </TabPanel>
    </Box>
  );
};

function DataInTab() {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const {
    operationData: { operation, operationPointer },
    loading: isOperationLoading,
  } = useGetOperationById(nodeConfig.parameters.operation);
  const { modelId, model } = useUnwrappedApiActionModel(operationPointer, operation?.inputModel);

  const { testInput, setTestInput, setTestInputValid } = useTestNode();

  const handleFormValuesChange = useCallback(
    (values: any) => {
      setTestInput((old) => ({ ...old, formValues: values }));
    },
    [setTestInput]
  );

  const handleConfigurationValuesChange = useCallback(
    (values: any) => {
      setTestInput((old) => ({ ...old, configurationValues: values }));
    },
    [setTestInput]
  );

  const handleCopyNodeConfigValues = () => {
    setTestInput({
      configurationValues: nodeConfig.parameters.configurationValues,
      formValues: removeReferenceFields(model, nodeConfig.parameters.formValues),
    });
  };

  return (
    <Loader loading={isOperationLoading || modelId !== operation?.inputModel}>
      <Button variant="outlined" onClick={handleCopyNodeConfigValues}>
        Use Data from Parameters
      </Button>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 500,
          pt: 2,
        }}
      >
        Parameters
      </Typography>
      <Box
        sx={{
          pt: 2,
        }}
      >
        {operation?.parameters && (
          <ApiActionParametersForm
            inputModel={operation.parameters}
            values={testInput.configurationValues}
            onValidityChange={setTestInputValid}
            onValuesChange={handleConfigurationValuesChange}
            enableReinitialize
          >
            <CloudSpecificParameterForm
              inputModel={operation.parameters}
              provider={nodeConfig.parameters?.operation?.provider}
            />
          </ApiActionParametersForm>
        )}
      </Box>
      {model !== null && (
        <Box
          sx={{
            pt: 2,
          }}
        >
          <GenericApiActionParametersForm
            inputModel={model}
            values={testInput?.formValues}
            onValuesChange={handleFormValuesChange}
            onValidityChange={noop}
            enableReinitialize
          />
        </Box>
      )}
    </Loader>
  );
}

type DataOutProps = {
  data: string;
  status: string | number;
  isError: boolean;
  loading: boolean;
};

function DataOutTab({ data, status, isError, loading }: DataOutProps) {
  if (loading) {
    return (
      <Loader loading={loading}>
        <></>
      </Loader>
    );
  }

  if (!data || !status) {
    return (
      <Typography variant="body2" color="textSecondary">
        Data output will show here after you run a test
      </Typography>
    );
  }

  return (
    <>
      {isError ? (
        <Alert severity="error" sx={{ mb: 1 }}>
          Test failed
        </Alert>
      ) : null}
      <Box
        component="pre"
        sx={{
          p: 2,
          wordWrap: "break-word",
          color: "text.primary",
          backgroundColor: "general.backgroundDark",
          m: 0,
          border: 0,
        }}
      >
        <Box>
          <strong>Status:</strong> {httpStatusMap[status] ?? status}
        </Box>
        <Box>
          <strong>Message:</strong> {data.toString()}
        </Box>
      </Box>
    </>
  );
}

export default TestsTab;
