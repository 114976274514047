import { useCallback, useMemo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Formik } from "formik";
import psl from "psl";
import * as yup from "yup";

import { useApiContext } from "../../../api/context";
import { globalText, tooltipText } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import EntitySelect from "../../../Components/Selects/EntitySelect";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Customer } from "../../../types";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { type PayerAccountOption } from "../types";
import { discernError, FormField, helperText } from "./utils";

function onKeyDown(event) {
  if (event.key === "Enter") {
    event.preventDefault();
  }
}

const getCustomerDomainsRegex = (domains: string[]) => {
  // temporary: allow creating accounts with @gmail.com addresses so added to domains
  const etlds = domains.map((domain: string) => psl.get(domain)).filter((domain) => !!domain);
  const allDomainsRes = [...etlds, ...domains, "gmail.com"].map((domain: string) => domain.replace(/([.])/gim, "\\$1"));
  const domainsRe = allDomainsRes.join("|");
  return new RegExp(`^[^@\\s]+@(${domainsRe})$`, "gmi");
};

const getFormSchema = (customer: Customer) =>
  yup.object().shape({
    entity: yup.string().required().label("Billing profile"),
    email: yup
      .string()
      .email("Must be a valid email address")
      .matches(getCustomerDomainsRegex(customer.domains), "Email address must be on your domain")
      .label("Root email")
      .required(),
    name: yup.string().required().label("Account name"),
    payerAccountId: yup.string().nullable().required().label("Payer account"),
    deleteAwsOrganizationRole: yup.bool().required(),
  });

type Props = {
  entities: Entity[];
  onClose: () => void;
  payerAccountOptions: PayerAccountOption[];
};

type Values = {
  payerAccountId: string | null;
  name: string;
  entity: string;
  email: string;
  deleteAwsOrganizationRole: boolean;
};

const CreateAmazonWebServicesAsset = ({ onClose, entities, payerAccountOptions }: Props) => {
  const { isMobile: matches } = useFullScreen("sm");
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const disabledOptions = useMemo<string[]>(
    () => payerAccountOptions.filter((opt) => opt.disabled).map((opt) => opt.value),
    [payerAccountOptions]
  );

  const optionDisabled = useCallback((option) => disabledOptions.includes(option), [disabledOptions]);

  const transformPayerAccountOptions = useMemo(
    () => payerAccountOptions.map((p: PayerAccountOption) => p.value),
    [payerAccountOptions]
  );

  if (!customer) {
    return null;
  }

  return (
    <Formik<Values>
      initialValues={{
        payerAccountId: isDoitEmployee || payerAccountOptions.length === 1 ? payerAccountOptions[0].value : null,
        name: "",
        entity: entities.length > 0 ? entities[0].id : "",
        email: "",
        deleteAwsOrganizationRole: true,
      }}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      validationSchema={() => getFormSchema(customer)}
      onSubmit={async (
        values,
        {
          setSubmitting,
          resetForm,
          // setFieldError
        }
      ) => {
        try {
          await api.request({
            method: "post",
            url: `/v1/customers/${customer.id}/entities/${values.entity}/amazon-web-services/create`,
            data: {
              email: values.email,
              name: values.name,
              payerAccountId: values.payerAccountId,
              deleteAwsOrganizationRole: values.deleteAwsOrganizationRole,
            },
          });
          mixpanel.track("assets.amazon-web-services.account.create", {
            email: values.email,
            name: values.name,
            payerAccountId: values.payerAccountId,
            deleteAwsOrganizationRole: values.deleteAwsOrganizationRole,
          });
          showSnackbar({
            message: "Account created successfully! Check your email for further instructions",
            variant: "success",
            autoHideDuration: 60000,
            action: [
              <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
                <CloseIcon />
              </IconButton>,
            ],
          });
          resetForm();
          onClose();
        } catch (error: any) {
          if (error.response) {
            showSnackbar({
              message: error.response.data.error || "Create account operation failed, try again later",
              variant: "error",
              autoHideDuration: 5000,
              action: [
                <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
                  <CloseIcon />
                </IconButton>,
              ],
            });
          }
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid, values, setFieldValue, handleChange, handleBlur, errors, touched, handleSubmit }) => (
        <Dialog
          open={true}
          aria-labelledby="amazon-web-services-create-form"
          onClose={preventOnCloseWhile(isSubmitting, onClose)}
          fullScreen={matches}
          fullWidth
          maxWidth="sm"
          data-cy="awsCreateAssetDialog"
        >
          <form onKeyDown={onKeyDown} onSubmit={handleSubmit}>
            <DialogTitle id="amazon-web-services-create-form" data-cy="dialogTitle">
              Create new account
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  gap: 2,
                  mt: 1,
                }}
              >
                {entities.length !== 1 && (
                  <EntitySelect
                    name="entity"
                    value={values.entity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    helperText={helperText(touched, errors, FormField.ENTITY)}
                    error={discernError(touched, errors, FormField.ENTITY)}
                    fullWidth
                    data-cy="entitySelect"
                  />
                )}
                <Autocomplete
                  id="payerAccountSelection"
                  options={transformPayerAccountOptions}
                  getOptionLabel={(option) => payerAccountOptions.find((item) => item.value === option)?.label || ""}
                  getOptionDisabled={optionDisabled}
                  value={values.payerAccountId}
                  onChange={(event, value) => setFieldValue("payerAccountId", value)}
                  onBlur={handleBlur}
                  disabled={isSubmitting || payerAccountOptions.length === 1}
                  data-cy="payerAccountAutocomplete"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="payerAccountId"
                      label="Payer Account"
                      variant="outlined"
                      helperText={helperText(touched, errors, FormField.PAYER_ACCOUNT)}
                      error={discernError(touched, errors, FormField.PAYER_ACCOUNT)}
                      fullWidth
                      data-cy="payerAccountInput"
                      slotProps={{
                        inputLabel: { shrink: true },
                      }}
                    />
                  )}
                />
                <TextField
                  name="name"
                  label="Account Name"
                  variant="outlined"
                  disabled={isSubmitting}
                  helperText={helperText(touched, errors, FormField.NAME)}
                  error={discernError(touched, errors, FormField.NAME)}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  data-cy="accountNameInput"
                  slotProps={{
                    inputLabel: { shrink: true },
                  }}
                />
                <TextField
                  name="email"
                  label="Root Email"
                  variant="outlined"
                  disabled={isSubmitting}
                  helperText={helperText(touched, errors, FormField.EMAIL)}
                  error={discernError(touched, errors, FormField.EMAIL)}
                  value={values.email.trim()}
                  onChange={(e) => {
                    e.target.value = e.target.value.trim();
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  data-cy="rootEmailInput"
                  slotProps={{
                    inputLabel: { shrink: true },
                  }}
                />
                <FormControlLabel
                  name="deleteAwsOrganizationRole"
                  control={<Checkbox />}
                  onChange={handleChange}
                  checked={values.deleteAwsOrganizationRole}
                  label={
                    <Stack direction="row">
                      Delete AWS Organization role
                      <Tooltip
                        arrow
                        placement="top"
                        sx={{ m: 0.5 }}
                        title={<div style={{ whiteSpace: "pre-line" }}>{tooltipText.BILLING_ACCOUNT_AWS_CREATE}</div>}
                      >
                        <InfoOutlinedIcon htmlColor={grey[600]} fontSize="inherit" />
                      </Tooltip>
                    </Stack>
                  }
                />
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button variant="text" onClick={onClose} disabled={isSubmitting} data-cy="cancelBtn">
                {globalText.CANCEL}
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
                data-cy="createBtn"
                mixpanelEventId="assets.aws.create"
              >
                {globalText.CREATE}
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default CreateAmazonWebServicesAsset;
