import { useEffect, useState } from "react";

import { type CompanyInfo, type CurrencyCode } from "@doitintl/cmp-models";
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";

import { useCompanies } from "../../../Components/CreateBillingProfile/BillingForm/hooks";

function findCompanyByCountry(companies: CompanyInfo[] | undefined, country: string): CompanyInfo | undefined {
  return companies?.find(({ countries }) => countries.includes(country));
}

export const WireTransfer = ({
  country,
  currency,
  showPaymentMethodType,
}: {
  country: string;
  currency?: CurrencyCode | "";
  showPaymentMethodType?: boolean;
}) => {
  const companies = useCompanies();
  // Present US bank account to customers in other countries
  const companyDetails = findCompanyByCountry(companies, country) ?? findCompanyByCountry(companies, "United States");
  const [selectedKey, setSelectedKey] = useState("");
  const filteredWireTransfers = Object.keys(companyDetails?.wireTransfers || {}).filter((key) =>
    key.includes(currency ?? "")
  );

  useEffect(() => {
    if (filteredWireTransfers.length > 0) {
      setSelectedKey(filteredWireTransfers[0]);
    }
  }, [filteredWireTransfers]);

  return (
    <Stack
      sx={{
        pt: 2,
      }}
    >
      {showPaymentMethodType && (
        <Stack direction="row" spacing={1}>
          <Typography
            sx={{
              fontWeight: "500",
            }}
          >
            Payment method:
          </Typography>
          <Typography>Wire transfer</Typography>
        </Stack>
      )}
      <FormControl sx={{ marginBottom: 2 }}>
        <InputLabel>Select Bank Account for Wire Transfer</InputLabel>
        <Select
          label="Select Bank Account for Wire Transfer"
          sx={{ marginBottom: 2 }}
          value={selectedKey}
          onChange={(event) => {
            setSelectedKey(event.target.value);
          }}
        >
          {filteredWireTransfers.map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {Object.entries(companyDetails?.wireTransfers[selectedKey] || {}).map(([key, value]) => (
        <Stack sx={{ marginBottom: 1 }} key={key} direction="row" spacing={1}>
          <Typography
            sx={{
              fontWeight: "500",
            }}
          >
            {value.key}:
          </Typography>
          <Typography>{value.value}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
