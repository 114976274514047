import { useState } from "react";

import { useParams } from "react-router";
import { Button, DialogContent, Typography } from "@mui/material";

import { globalText } from "../../../../../../assets/texts";
import SimpleDialog from "../../../../../../Components/SimpleDialog";
import { useErrorSnackbar } from "../../../../../Integrations/Slack/utils";
import contractHooks, { useBackToList } from "../../../../hooks";
import { isDciContract, isNext10Contract } from "../../../../utils";
import { useContractFormContext } from "../../../ContractsFormContext";

export const CancelContractButton = () => {
  const { state } = useContractFormContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { contractId } = useParams<{ contractId: string }>();
  const errorSnackbar = useErrorSnackbar();
  const cancelContract = contractHooks.useCancelContract();
  const updateContractStatus = contractHooks.useUpdateContractStatus(state);
  const backToList = useBackToList();

  const handleCancel = async () => {
    await cancelContract(contractId, backToList, () => {
      errorSnackbar("Failed to cancel contract");
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={() => {
          setDialogOpen(true);
        }}
        data-cy="cancel-contract-button"
      >
        Cancel contract
      </Button>
      <SimpleDialog
        open={dialogOpen}
        title="Cancel contract"
        onConfirm={
          isNext10Contract(state.type) || isDciContract(state.type)
            ? handleCancel
            : () => updateContractStatus(false, false)
        }
        onCancel={() => {
          setDialogOpen(false);
        }}
        confirmButtonText="Cancel contract"
        confirmButtonColor="error"
        cancelButtonText={globalText.CLOSE}
      >
        <DialogContent>
          <Typography
            sx={{
              mt: 3,
            }}
          >
            Are you sure you want to cancel this contract?
          </Typography>
        </DialogContent>
      </SimpleDialog>
    </>
  );
};
