import { type ComponentType } from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { type Breakpoint } from "@mui/material/styles";
import { getDisplayName } from "recompose";

export type WithFullScreen = {
  fullScreen: boolean;
  isMobile: boolean; // same as fullScreen but some code path use that name
};

export function useFullScreen(key: Breakpoint | number = "md", options?: { noSsr?: boolean }): WithFullScreen {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(key), options);

  return { fullScreen, isMobile: fullScreen };
}

type Props = WithFullScreen;

export function withFullScreen<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent = (props: P) => {
    const { fullScreen } = useFullScreen();

    return <Component {...props} width="lg" fullScreen={fullScreen} isMobile={fullScreen} />;
  };

  WrappedComponent.displayName = `withFullScreen(${getDisplayName(Component)})`;

  return WrappedComponent;
}

export function preventOnCloseWhile(condition: boolean, onClose: () => void) {
  return () => {
    if (condition) {
      return;
    }
    onClose();
  };
}

export function preventOnCloseOnBackdropClick(onClose: () => void) {
  return (_, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      return;
    }
    onClose();
  };
}
