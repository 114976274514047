import { useMemo } from "react";

import { SaaSConsoleType, TierPackageTypes } from "@doitintl/cmp-models";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

import AWSLogoLight from "../../assets/aws-logo.svg";
import AWSLogoDark from "../../assets/aws-logo-dark-mode.svg";
import AzureLogo from "../../assets/azure-logo.svg";
import GCPLogo from "../../assets/gcp-logo.svg";
import SnowflakeLogo from "../../assets/snowflake-logo.svg";
import { homeTexts } from "../../assets/texts";
import { useShouldShowOnboardingCards } from "../../Components/Dashboard/CloudConnection/hooks";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { useRoutesConfig } from "../../Navigation/Components/hooks";
import { customersRoute } from "../../Navigation/core/utils";
import { isDCIEssentialsTier, isHeritageTier } from "../../Pages/Settings/General/utils";
import { CloudConnectCard } from "./CloudConnect/CloudConnectCard";
import SectionHeader from "./SectionHeader";

export const CloudConnect = () => {
  const routesConfig = useRoutesConfig();
  const { customer } = useCustomerContext();
  const { getCustomerTier } = useTier();
  const navigatorTier = getCustomerTier(TierPackageTypes.NAVIGATOR);

  const theme = useTheme();
  const {
    palette: { mode },
  } = theme;
  const {
    shouldShowNavigatorWelcomeBlock,
    shouldShowSolveWelcomeBlock,
    shouldShowAzureCloudConnect,
    shouldShowBillingProfileCard,
    shouldShowBillingProfileCardForHybridCustomer,
    shouldShowStandaloneCloudSelector,
    shouldShowSaasCloudSelector,
    shouldShowWelcomeBlock,
    shouldShowHeritageCloudConnect,
    shouldShowSnowflakeOnboardingCard,
  } = useShouldShowOnboardingCards();

  const allowedRoutes = useMemo(() => {
    const routes = [
      {
        pageId: "saas:onboarding:aws",
        logo: mode === "dark" ? AWSLogoDark : AWSLogoLight,
        cloudType: SaaSConsoleType.AWS,
        path: "cloud-onboarding-aws",
      },
      {
        pageId: "saas:onboarding:gcp",
        logo: GCPLogo,
        cloudType: SaaSConsoleType.GCP,
        path: "cloud-onboarding-gcp",
      },
      {
        pageId: "saas:onboarding:azure",
        logo: AzureLogo,
        cloudType: SaaSConsoleType.AZURE,
        path: "cloud-onboarding-azure",
      },
    ].filter((r) => routesConfig?.find((conf) => conf.pageId === r.pageId) !== undefined);

    if (shouldShowSnowflakeOnboardingCard) {
      routes.push({
        pageId: "saas:onboarding:snowflake",
        logo: SnowflakeLogo,
        cloudType: SaaSConsoleType.SNOWFLAKE,
        path: "cloud-onboarding-snowflake",
      });
    }

    return routes;
  }, [mode, routesConfig, shouldShowSnowflakeOnboardingCard]);

  if (
    shouldShowAzureCloudConnect ||
    shouldShowBillingProfileCard ||
    shouldShowBillingProfileCardForHybridCustomer ||
    shouldShowNavigatorWelcomeBlock ||
    shouldShowSolveWelcomeBlock ||
    shouldShowStandaloneCloudSelector ||
    shouldShowSaasCloudSelector ||
    shouldShowWelcomeBlock ||
    shouldShowHeritageCloudConnect ||
    !allowedRoutes.length ||
    isHeritageTier(navigatorTier) ||
    isDCIEssentialsTier(navigatorTier)
  ) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <SectionHeader text={homeTexts.CLOUD_CONNECT_TITLE} />
      </Grid>
      {allowedRoutes.map((c) => (
        <Grid
          key={c.pageId}
          size={{
            xs: 12,
            sm: 12,
            md: 4,
          }}
        >
          <CloudConnectCard logo={c.logo} cloudType={c.cloudType} route={customersRoute(customer.id, c.path)} />
        </Grid>
      ))}
    </Grid>
  );
};
