import { type JSX } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Divider, IconButton, StyledEngineProvider, Toolbar, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

import { cmpBaseColors } from "../../../cmpBaseColors";
import StartFreeTrialButton from "../../../Components/StartTrialButton/StartTrialButton";
import { navigationId } from "../../../constants";
import { useAuthContext } from "../../../Context/AuthContext";
import MenuBarSearch from "../../../Layout/MenuBarSearch";
import { AppBarStyles, ToolbarStyles } from "../../themes";
import { type CategoryContext } from "../../types";
import { AdditionalOptions } from "../Header/AdditionalOptions";
import AvatarMenu from "../Header/AvatarMenu/AvatarMenu";
import { useShowFreeTrialCard } from "../Header/hooks";
import { useMobileMenu } from "./hoooks";

type MobileHeaderProps = {
  onSignOut: () => unknown;
  children?: JSX.Element;
  categories: CategoryContext[];
  settings: CategoryContext[];
  showOptions?: boolean;
  hideWhatsNewOption?: boolean;
  openAnnounceKit?: () => void;
  presentationModeActive: boolean;
};

const MobileHeader = ({
  onSignOut,
  categories,
  settings,
  children,
  showOptions = false,
  hideWhatsNewOption = false,
  presentationModeActive = false,
}: MobileHeaderProps) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { menu, openMenu } = useMobileMenu(categories, settings, showOptions, hideWhatsNewOption);
  const theme = useTheme();
  const hideDomainName = useMediaQuery(theme.breakpoints.down("lg"));
  const showFreeTrialButton = useShowFreeTrialCard();

  return (
    <>
      <AppBar elevation={0} position="sticky" sx={AppBarStyles} id={navigationId}>
        <Toolbar
          sx={[
            (theme) => ({
              color: theme.palette.navigation.text,
              [theme.breakpoints.up("sm")]: {
                minHeight: 48,
              },
              borderImage: presentationModeActive
                ? `linear-gradient(90deg, ${cmpBaseColors.presentationModeOrange} 0.8%, ${cmpBaseColors.presentationModeOrange} 99.1%)`
                : theme.palette.navigation.gradient,
            }),
            ToolbarStyles,
          ]}
        >
          <Grid
            container
            direction="row"
            size="grow"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              zIndex: 2,
            }}
          >
            <Grid size={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    pr: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={openMenu} size="large">
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Box />
              </Box>
            </Grid>

            <Grid size={10}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <StyledEngineProvider injectFirst>{isDoitEmployee && <MenuBarSearch />}</StyledEngineProvider>
                {showOptions && <AdditionalOptions hideWhatsNewOption={hideWhatsNewOption} settings={settings} />}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={(theme) => ({ backgroundColor: theme.palette.general.divider })}
                />
                <Box
                  sx={{
                    pl: 1,
                  }}
                >
                  <AvatarMenu onSignOut={onSignOut} hideDomainName={hideDomainName} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
        {showFreeTrialButton && <StartFreeTrialButton />}
        {children}
      </AppBar>
      {menu}
    </>
  );
};

export default MobileHeader;
