import { useCallback, useState } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection, type ModelRef } from "@doitintl/models-firestore";
import { type AutocompleteRenderInputParams, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toLower from "lodash/toLower";

const UNICODE_END = "\uf8ff";

type Value = ModelRef<CustomerModel>;

type CustomerSelectProps = {
  value: any;
  disabled: boolean;
  error?: boolean;
  helperText?: string;
  setFieldValue: (field: "customer", value: Value | null) => void;
};

const CustomerSelect = ({ value, disabled, error, helperText, setFieldValue }: CustomerSelectProps) => {
  const [options, setOptions] = useState<Value[]>([]);
  const [loading, setLoading] = useState(false);

  const handleLoadOptions = useCallback(async (inputValue: string) => {
    const valueLower = toLower(inputValue);
    const valueEnd = valueLower + UNICODE_END;
    const querySnapshot = await getCollection(CustomerModel)
      .where("primaryDomain", ">=", valueLower)
      .where("primaryDomain", "<", valueEnd)
      .limit(10)
      .get();
    return querySnapshot.docs.map((docSnap) => {
      const data = docSnap.asModelData();
      return {
        ref: docSnap.modelRef,
        ...data,
      };
    });
  }, []);

  return (
    <Autocomplete<Value>
      getOptionLabel={(option) => option.primaryDomain}
      options={options}
      loading={loading}
      value={value}
      disabled={disabled}
      onChange={(_event, newValue) => {
        setFieldValue("customer", newValue);
      }}
      onInputChange={async (_event, value, reason) => {
        if (reason === "input") {
          setLoading(true);
          try {
            const newOptions = await handleLoadOptions(value);
            setOptions(newOptions);
          } finally {
            setLoading(false);
          }
        }
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label="Customer"
          variant="outlined"
          margin="dense"
          helperText={helperText}
          error={error}
          disabled={disabled}
          slotProps={{
            input: {
              ...params.InputProps,
              "data-cy": "customer-select",
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            } as any,

            inputLabel: { shrink: true },
          }}
        />
      )}
    />
  );
};
export default CustomerSelect;
