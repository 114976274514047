import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Chip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { reportText } from "../../../../assets/texts";
import { type PopoverAnchorEl } from "../../../../Pages/CloudAnalytics/report/types";
import { getFilterLabel, Positions } from "../../../../Pages/CloudAnalytics/utilities";

type Props = {
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl | null) => void;
};

const CountFieldMissing = ({ setPopoverAnchorEl }: Props) => {
  const handlePopoverCountChip = ({ currentTarget }) => {
    setPopoverAnchorEl({ mdCount: currentTarget });
  };
  const filterLabel = getFilterLabel(Positions.COUNT);

  return (
    <Box
      sx={{
        height: "100%",
        textAlign: "center",
        flexBasis: "100%",
        minHeight: "calc(100vh - 380px)",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "170px",
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          display: "inline",
        }}
      >
        {reportText.ERR_MISSING_COUNT_FIELD_TITLE}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          display: "inline",
          margin: 1.5,
          opacity: 0.6,
        }}
      >
        {reportText.ERR_MISSING_COUNT_FIELD_PREFIX}
        <i>{reportText.ERR_MISSING_COUNT_FIELD_VALUE}</i>
        {reportText.ERR_MISSING_COUNT_FIELD_SUFFIX}
      </Typography>
      <Grid key="filter-popover">
        <Chip
          id="missing-count"
          data-testid="missing-count"
          size="small"
          variant="outlined"
          color="primary"
          sx={{
            maxWidth: 240,
            margin: [0.4, 0.4, 0, 0],
          }}
          label={filterLabel}
          onClick={handlePopoverCountChip}
          icon={<AddIcon fontSize="small" />}
        />
      </Grid>
    </Box>
  );
};

export default CountFieldMissing;
