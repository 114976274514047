import { type ChangeEvent, useCallback, useState } from "react";

import { AccountManagerCompanies, Classification, CustomerModel, CustomerSegmentValues } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";

import { globalText } from "../../assets/texts";
import { generalTxt } from "../../assets/texts/Settings/general";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { isNakedDomainValid, ticketNotification } from "../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../utils/dialog";
import { serverTimestamp } from "../../utils/firebase";
import LoadingButton from "../LoadingButton";
import { usePackageSelection } from "./hooks";
import PackageSelector from "./PackageSelector";
import { isPackageOptionSelected, transformOnboardingData } from "./utils";

const ErrorInvalidDomain = "Invalid naked domain name";
const ErrorDomainAlreadyExists = "Domain name already exists";

const CustomerCreateDialog = ({ onClose }) => {
  const { accountManager } = AccountManagersHooks.useAccountManager();
  const { fullScreen: xsDown } = useFullScreen("sm");
  const [loading, setLoading] = useState(false);
  const [primaryDomain, setPrimaryDomain] = useState("");
  const [error, setError] = useState<string | null>(null);
  const initialPackageOptions = {
    advantage: [],
    navigator: [],
    solve: [],
  };
  const { packageOptions, handlePackageSelection } = usePackageSelection(initialPackageOptions);

  const handlePrimaryDomainChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const input = event.target.value.trim().toLowerCase();
      if (error !== null) {
        setError(!isNakedDomainValid(input) ? ErrorInvalidDomain : null);
      }
      setPrimaryDomain(input);
    },
    [error]
  );

  const handleSubmit = useCallback(async () => {
    if (!primaryDomain || !!error) {
      return;
    }
    if (!isNakedDomainValid(primaryDomain)) {
      setError(ErrorInvalidDomain);
      return;
    }

    if (!accountManager?.ref) {
      return;
    }
    setLoading(true);
    try {
      const querySnapshot = await getCollection(CustomerModel)
        .where("domains", "array-contains", primaryDomain)
        .limit(1)
        .get();

      if (querySnapshot.empty) {
        const docReference = await getCollection(CustomerModel).add({
          _name: primaryDomain,
          name: primaryDomain,
          primaryDomain,
          domains: [primaryDomain],
          entities: [],
          accountManager: accountManager.ref,
          classification: Classification.Business,
          enrichment: {},
          subscribers: [accountManager.email],
          sharedDriveFolderId: null,
          accountManagers: {
            [AccountManagerCompanies.DOIT]: {
              ["account_manager"]: {
                ref: accountManager.ref,
                notification: ticketNotification[3].value,
              },
              ["customer_engineer"]: null,
            },
            [AccountManagerCompanies.AWS]: { ["account_manager"]: null, ["customer_engineer"]: null },
            [AccountManagerCompanies.GCP]: { ["account_manager"]: null, ["customer_engineer"]: null },
            [AccountManagerCompanies.MicrosoftAzure]: { ["account_manager"]: null, ["customer_engineer"]: null },
          },
          accountTeam: [
            {
              company: AccountManagerCompanies.DOIT,
              supportNotificationLevel: ticketNotification[3].value,
              ref: accountManager.ref,
            },
          ],
          timeCreated: serverTimestamp(),
          onboarding: transformOnboardingData(packageOptions),
          customerSegment: {
            currentSegment: CustomerSegmentValues.Invest,
            overrideSegment: null,
          },
          presentationMode: {
            isPredefined: false,
            enabled: true,
            customerId: "presentationcustomerAWSAzureGCP",
          },
        });
        onClose(docReference.id);
      } else {
        setError(ErrorDomainAlreadyExists);
      }
    } finally {
      setLoading(false);
    }
  }, [accountManager?.email, accountManager?.ref, error, onClose, primaryDomain, packageOptions]);

  if (!accountManager) {
    return null;
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      onClose={preventOnCloseWhile(loading, () => onClose(null))}
      fullScreen={xsDown}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{generalTxt.CREATE_A_CUSTOMER}</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
          Customer details
        </Typography>
        <TextField
          name="primaryDomain"
          variant="outlined"
          margin="normal"
          label="Primary domain"
          type="text"
          helperText={error || "Enter the customer’s naked domain e.g. example.com"}
          value={primaryDomain}
          onChange={handlePrimaryDomainChange}
          disabled={loading}
          error={!!error}
          fullWidth
          autoFocus
          required
          slotProps={{
            htmlInput: { "data-cy": "primaryDomain" },
          }}
        />
        <PackageSelector packageOptions={packageOptions} onSelect={handlePackageSelection} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={() => onClose(null)} disabled={loading}>
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!!error || loading || !primaryDomain || !isPackageOptionSelected(packageOptions)}
          loading={loading}
          mixpanelEventId="customer.dialog.create"
        >
          {globalText.CREATE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerCreateDialog;
