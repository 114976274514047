import Grid from "@mui/material/Grid2";

import { homeTexts } from "../../assets/texts";
import CloudIncidentsCard from "../../Components/Dashboard/CloudIncidentsCard";
import InfoMessageWidgetCard from "../../Components/Dashboard/InfoMessageWidgetCard";
import SupportCard from "../../Components/Dashboard/SupportCard";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { useUserContext } from "../../Context/UserContext";
import SectionHeader from "./SectionHeader";

const CloudIncidentsSection = () => {
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const hasIssuesViewerPermission = userRoles.issuesViewer;
  const isInPresentationMode = isCustomerInPresentationMode(customer);

  if (!hasIssuesViewerPermission && isInPresentationMode) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <SectionHeader text={homeTexts.CLOUD_INCIDENTS_TITLE} />
      </Grid>
      {hasIssuesViewerPermission ? (
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <CloudIncidentsCard
            widgetHeight={300}
            fallbackComponent={<InfoMessageWidgetCard widgetHeight={300} name="activeCloudIncidents" />}
          />
        </Grid>
      ) : null}
      {!isInPresentationMode && (
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <SupportCard
            widgetHeight={300}
            fallbackComponent={<InfoMessageWidgetCard widgetHeight={300} name="supportGraph" />}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CloudIncidentsSection;
