import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";

type FilterBarProps = {
  placeholder?: string;
  handleChange: (string) => void;
};

export default function FilterBar({ placeholder = "Filter options", handleChange }: FilterBarProps) {
  const [value, setValue] = useState<string>("");

  return (
    <TextField
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        handleChange(e.target.value);
      }}
      variant="outlined"
      placeholder={placeholder}
      margin="dense"
      fullWidth={true}
      slotProps={{
        input: {
          "aria-label": placeholder,
          id: "search-bar-input",
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  setValue("");
                  handleChange("");
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
