import Grid from "@mui/material/Grid2";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useShowFreeTrialCard } from "../../../../Navigation/Components/Header/hooks";
import { renderPackageCloudConnectCards } from "../SaaSDashboard/WelcomeBlockWrapper";
import { FreeTrialCard } from "./FreeTrialCard";

type NavigatorOnlyWelcomeBlockProps = {
  showSnowflakeCard: boolean;
};

const NavigatorOnlyWelcomeBlock = ({ showSnowflakeCard }: NavigatorOnlyWelcomeBlockProps) => {
  const { customer } = useCustomerContext();
  const shouldShowFreeTrial = useShowFreeTrialCard();

  return shouldShowFreeTrial ? (
    <Grid
      size={{
        xs: 12,
        sm: 12,
        md: 6,
      }}
    >
      <FreeTrialCard />
    </Grid>
  ) : (
    <>{renderPackageCloudConnectCards(customer, "navigator", false, showSnowflakeCard)}</>
  );
};

export default NavigatorOnlyWelcomeBlock;
