import { useCallback, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import { preventOnCloseOnBackdropClick } from "../../../../../utils/dialog";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleReject: () => Promise<void>;
};

export const RejectPurchaseConfirmationDialog = ({ open, handleClose, handleReject }: Props) => {
  const [waitingForRejectionToFinish, setWaitingForRejectionToFinish] = useState<boolean>(false);

  const onRejectClicked = useCallback(async () => {
    setWaitingForRejectionToFinish(true);
    await handleReject();
    handleClose();
    setWaitingForRejectionToFinish(false);
  }, [handleReject, handleClose]);

  return (
    <Dialog open={open} disableEscapeKeyDown onClose={preventOnCloseOnBackdropClick(handleClose)} fullWidth>
      <DialogTitle>Reject item</DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            pt: 1,
          }}
        >
          This item will not be able to be purchased if it is rejected.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ borderTop: 1, borderColor: "divider" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={waitingForRejectionToFinish}
          disabled={waitingForRejectionToFinish}
          onClick={onRejectClicked}
          autoFocus
        >
          Reject
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
