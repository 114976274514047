import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";

type SetOpen = ({ region, open }: { region: string | null | undefined; open: boolean }) => void;

type Props = {
  state: { region: string | null | undefined; open: boolean };
  setOpen: SetOpen;
};

export function AwsSplitDialog({ state, setOpen }: Props) {
  const handleClose = () => {
    setOpen({ open: false, region: null });
  };

  const href1 = `https://${state.region}.console.aws.amazon.com/costmanagement/home?region=${state.region}#/settings`;
  const href2 = `https://${state.region}.console.aws.amazon.com/costmanagement/home?region=${state.region}#/bcm-data-exports`;
  const href3 = "https://docs.aws.amazon.com/cur/latest/userguide/enabling-split-cost-allocation-data.html";

  return (
    <Dialog open={state.open} onClose={handleClose}>
      <DialogTitle>Enable split cost allocation data</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            To enable “Split cost allocation data”, you need to complete a simple two-step opt-in process in your AWS
            Console:
          </Typography>

          <Typography>
            <ol style={{ paddingLeft: "1rem" }}>
              <li>
                <Typography
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  Enable EKS in Billing and Cost Management -&gt; Cost Management Preferences
                </Typography>
                <Link href={href1} target="_blank">
                  <Button color="primary" variant="contained" endIcon={<OpenInNewIcon />}>
                    Open Cost Management Preferences
                  </Button>
                </Link>
              </li>
              <li>
                <Typography
                  sx={{
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Enable "split cost allocation data" in the CUR configuration, for example: Billing and Cost Management
                  -&gt; Data Exports -&gt; doitintl-awsops-951. If the option is greyed out and a new CUR needs to be
                  created, please contact your account manager.
                </Typography>
                <Link href={href2} target="_blank">
                  <Button color="primary" variant="contained" endIcon={<OpenInNewIcon />}>
                    Open Data Export
                  </Button>
                </Link>
              </li>
            </ol>
          </Typography>

          <Typography>
            <Typography
              color="black"
              sx={{
                marginBottom: "20px",
              }}
            >
              Once enabled, it may take up to 24 hours to show up on DoiT Navigator.
            </Typography>
            <Typography>
              To learn more, visit the
              <Link href={href3} target="_blank" style={{ paddingLeft: "5px" }}>
                AWS documentation page.
                <OpenInNewIcon style={{ margin: "0px 0px -5px 4px", width: 20 }} />
              </Link>
            </Typography>
          </Typography>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
