import { useCallback, useState } from "react";

import { FlexsaveTypes, type StandaloneOnboardingErrorType } from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { type Theme, useTheme } from "@mui/material/styles";
import styled from "styled-components";

import { useCustomerId } from "../../../../../Components/hooks/useCustomerId";
import { useErrorSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useNoDialStyle } from "../../../../../Navigation/themes";
import { consoleErrorWithSentry } from "../../../../../utils";
import mixpanel from "../../../../../utils/mixpanel";
import { IconWithLink } from "../../../Common/IconWithLink";
import { NumberedList } from "../../../Common/NumberedList";
import { ErrorStepLocation, getErrorAlert } from "../../Common/helpers/errorsHelper";
import { HelpPanel } from "../../Common/HelpPanel";
import { OnBoardingAlert } from "../../Common/OnBoardingAlert";
import { OnboardLayout } from "../../Common/OnboardLayout";
import { useStandaloneAPI } from "../../hooks";
import { getEstimationCreationStackURL } from "../../utils/stackCreationUtils";
import { type CSVRouteData } from "../types";
import { UploadEstimationsCSV } from "../UploadEstimationsCSV";
import { csvEstimationSteps, estimationSteps } from "./Common/texts";

const StyledTab = styled(Tab)`
  text-transform: none;
`;

export enum GetEstimationsTab {
  uploadCSV = "uploadCSV",
  createCloudFormationStack = "createCloudFormationStack",
}

function a11yProps(index: GetEstimationsTab) {
  return {
    id: `get-estimation-tab-${index}`,
    "data-cy": `get-estimation-tab-${index}`,
    "aria-controls": `get-estimation-tab-${index}`,
  };
}

const expectedAccountIdLength = 12;

type Props = {
  error?: StandaloneOnboardingErrorType;
  allowToContinue: boolean;

  handleBack: () => void;
  handleDone: () => void;
};
export const EstimationPermissions = ({ error, allowToContinue, handleBack, handleDone }: Props) => {
  const customerId = useCustomerId();
  const standaloneAPI = useStandaloneAPI(FlexsaveTypes.AWS);
  const [createStackClicked, setCreateStackClicked] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<GetEstimationsTab>(GetEstimationsTab.uploadCSV);
  const [formData, setFormData] = useState<CSVRouteData>({ data: null, filename: "", accountId: "" });
  const showErrorSnackBar = useErrorSnackbar();
  const classes = useNoDialStyle();
  const [wasAccountIdBlurred, setWasAccountIdBlurred] = useState(false);
  const theme: Theme = useTheme();

  const handleStackCreation = useCallback(() => {
    mixpanel.track("flexsaveSA.openproviderviewestimate", { type: "aws" });
    setCreateStackClicked(true);
    const url = getEstimationCreationStackURL(customerId);
    window.open(url, "_blank");
  }, [customerId]);

  const handlePrimaryButtonClicked = useCallback(async () => {
    if (selectedTab === GetEstimationsTab.uploadCSV && formData.data && formData.accountId) {
      try {
        await standaloneAPI.savingsRecommendations(formData.accountId, formData.data[0]);
        handleDone();
      } catch (e) {
        showErrorSnackBar("Unable to view estimate");
        consoleErrorWithSentry(e);
      }
      return;
    }
    handleDone();
  }, [formData, handleDone, selectedTab, showErrorSnackBar, standaloneAPI]);

  return (
    <OnboardLayout
      information={
        <HelpPanel text="DoiT uses Cost Explorer's aggregated information about your spend with AWS. This information doesn’t contain names of your machines, buckets or tags. This information will enable the calculation of your potential savings with Flexsave.">
          <IconWithLink
            text="Review our data compliance information"
            icon={<OpenNewIcon />}
            url="https://www.doit-intl.com/compliance/"
          />
        </HelpPanel>
      }
      bottomAppBarProps={{
        primaryButtonName: "View my estimate",
        secondaryButtonName: "Back",
        handlePrimaryButtonClicked,
        handleSecondaryButtonClicked: handleBack,
        disabled:
          (selectedTab === GetEstimationsTab.createCloudFormationStack && !allowToContinue) ||
          (selectedTab === GetEstimationsTab.uploadCSV &&
            (!formData.filename || !formData.data || formData.accountId.length !== expectedAccountIdLength)),
      }}
    >
      <Stack
        spacing={4}
        sx={{
          maxWidth: "sm",
        }}
      >
        <Typography variant="h1">Create your savings estimate for AWS</Typography>

        {getErrorAlert(error, ErrorStepLocation.AWS_SAVINGS)}

        <Typography
          variant="h4"
          sx={{
            fontSize: 16,
          }}
        >
          Choose how to get your estimate
        </Typography>

        <TabContext value={selectedTab}>
          <Tabs
            value={selectedTab}
            onChange={(_, value) => {
              setSelectedTab(value);
              setWasAccountIdBlurred(false);
            }}
            indicatorColor="primary"
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", borderColor: theme.palette.general.divider }}
          >
            <StyledTab
              label="Upload a CSV"
              value={GetEstimationsTab.uploadCSV}
              {...a11yProps(GetEstimationsTab.uploadCSV)}
            />
            <StyledTab
              label="Create a CloudFormation Stack"
              value={GetEstimationsTab.createCloudFormationStack}
              {...a11yProps(GetEstimationsTab.createCloudFormationStack)}
            />
          </Tabs>

          <TabPanel sx={{ padding: 0 }} value={GetEstimationsTab.uploadCSV}>
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                mb: 3,
              }}
            >
              <TextField
                data-cy="get-estimation-tab-account-id-input"
                onBlur={() => {
                  setWasAccountIdBlurred(true);
                }}
                type="number"
                variant="outlined"
                label="Account ID"
                value={formData.accountId}
                onChange={(data) => {
                  setFormData({ ...formData, accountId: data.target.value.trim() });
                }}
                required
                error={wasAccountIdBlurred && formData.accountId.length !== expectedAccountIdLength}
                size="medium"
                slotProps={{
                  htmlInput: { className: classes.noDial },
                }}
              />

              <Typography
                variant="caption"
                sx={{
                  color: "text.disabled",
                }}
              >
                This will let you identify your estimate
              </Typography>
            </Box>

            <NumberedList list={csvEstimationSteps} />

            <Box
              sx={{
                pt: 3,
              }}
            >
              <UploadEstimationsCSV
                onChange={(value) => {
                  setFormData({ ...formData, ...value });
                }}
                CSVFileName={formData.filename}
                loadedCSVData={formData.data}
              />
            </Box>
          </TabPanel>

          <TabPanel sx={{ padding: 0 }} value={GetEstimationsTab.createCloudFormationStack}>
            You need a <b>management account</b> to get your estimate.
            <NumberedList list={estimationSteps} />
            {createStackClicked && !allowToContinue && (
              <OnBoardingAlert
                title="Accessing billing data"
                body="After you create stack in the AWS Console it may take up to 60 seconds for this page to update."
                type="info"
              />
            )}
            <Button
              data-cy="create-stack-button"
              variant="outlined"
              sx={{ alignSelf: "flex-start", marginTop: 3 }}
              startIcon={<OpenNewIcon />}
              onClick={handleStackCreation}
            >
              Open AWS CloudFormation Console
            </Button>
          </TabPanel>
        </TabContext>
      </Stack>
    </OnboardLayout>
  );
};
