import { useMemo, useState } from "react";

import { type ForecastMode, type ForecastSettings, type TimeInterval } from "@doitintl/cmp-models";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";

import { forecastTxt } from "../../../../../assets/texts/CloudAnalytics/report";
import SimpleDialog from "../../../../../Components/SimpleDialog";
import { forecastSettingsLimits, getFutureIntervalsLabel, getHistoricalIntervalsLabel } from "./utils";

export const getErrorText = (field: "historicalTimeIntervals" | "futureTimeIntervals", timeInterval: TimeInterval) =>
  `Number must be between ${forecastSettingsLimits[field][timeInterval].min} and ${forecastSettingsLimits[field][timeInterval].max}`;

const ForecastForm = ({
  forecastSettings,
  handleClose,
  handleApply,
  timeInterval,
}: {
  forecastSettings: ForecastSettings;
  handleClose: () => void;
  handleApply: (f: ForecastSettings) => void;
  timeInterval: TimeInterval;
}) => {
  const [settings, setSettings] = useState<ForecastSettings>(forecastSettings);
  const [errors, setErrors] = useState({ historicalTimeIntervals: false, futureTimeIntervals: false });

  const handleChangeNumber = (name: "historicalTimeIntervals" | "futureTimeIntervals", input: string) => {
    const value = parseInt(input);
    setSettings({ ...settings, [name]: value });
    let error = false;
    if (
      isNaN(value) ||
      !(
        value >= forecastSettingsLimits[name][timeInterval].min &&
        value <= forecastSettingsLimits[name][timeInterval].max
      )
    ) {
      error = true;
    }
    setErrors((errors) => ({ ...errors, [name]: error }));
  };

  const applyDisabled = useMemo(
    () => errors.historicalTimeIntervals || errors.futureTimeIntervals,
    [errors.futureTimeIntervals, errors.historicalTimeIntervals]
  );

  return (
    <SimpleDialog
      title={forecastTxt.FORECAST_FORM_TITLE}
      titleCy="forecast-from-title"
      open
      headingDividerVisible={false}
      confirmButtonText="Apply"
      confirmButtonCy="apply-button"
      disableConfirm={applyDisabled}
      onConfirm={() => {
        handleApply(settings);
      }}
      onCancel={handleClose}
    >
      <Stack
        sx={{
          gap: "12px",
          px: 3,
          pt: 1.5,
          pb: 2,
          width: "440px",
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }} data-cy="historical-data-title">
          {forecastTxt.HISTORICAL_DATA_TITLE}
        </Typography>
        <Typography variant="body2" data-cy="historical-data-text">
          {forecastTxt.HISTORICAL_DATA_TEXT}
        </Typography>
        <Box sx={{ my: 0.5 }}>
          <TextField
            fullWidth
            label={getHistoricalIntervalsLabel(timeInterval)}
            value={settings.historicalTimeIntervals}
            type="number"
            onChange={(event) => {
              handleChangeNumber("historicalTimeIntervals", event?.target.value);
            }}
            error={errors.historicalTimeIntervals}
            helperText={errors.historicalTimeIntervals && getErrorText("historicalTimeIntervals", timeInterval)}
            slotProps={{
              htmlInput: { "data-cy": "historical-intervals-field" },
            }}
          />
        </Box>
        <TextField
          fullWidth
          label={getFutureIntervalsLabel(timeInterval)}
          value={settings.futureTimeIntervals}
          type="number"
          onChange={(event) => {
            handleChangeNumber("futureTimeIntervals", event?.target.value);
          }}
          error={errors.futureTimeIntervals}
          helperText={errors.futureTimeIntervals && getErrorText("futureTimeIntervals", timeInterval)}
          slotProps={{
            htmlInput: { "data-cy": "future-intervals-field" },
          }}
        />

        <FormControl>
          <Typography
            variant="subtitle2"
            data-cy="granularity-section-title"
            sx={{
              fontWeight: 500,
              mb: 1,
            }}
          >
            {forecastTxt.GRANULARITY_SECTION_TITLE}
          </Typography>
          <RadioGroup
            onChange={(event) => {
              setSettings({ ...settings, mode: event.target.value as ForecastMode });
            }}
          >
            <FormControlLabel
              checked={settings.mode === "grouping"}
              value="grouping"
              control={<Radio size="small" sx={{ alignSelf: "flex-start" }} data-cy="forecast-mode-grouping" />}
              label={
                <Box
                  sx={{
                    pt: 1,
                  }}
                >
                  <Typography variant="body2">{forecastTxt.GROUPING_MODE_LABEL}</Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {forecastTxt.GROUPING_MODE_DESCRIPTION}
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              checked={settings.mode === "totals"}
              value="totals"
              control={<Radio size="small" sx={{ alignSelf: "flex-start" }} data-cy="forecast-mode-totals" />}
              label={
                <Box
                  sx={{
                    pt: 1,
                  }}
                >
                  <Typography variant="body2">{forecastTxt.TOTALS_MODE_LABEL}</Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {forecastTxt.TOTALS_MODE_DESCRIPTION}
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </Stack>
    </SimpleDialog>
  );
};

export default ForecastForm;
