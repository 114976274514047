import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useFullScreen } from "../../../utils/dialog";
import { type SectionTitles } from "../types";

export const PerkNavBar = ({ sectionTitles }: { sectionTitles: SectionTitles }) => {
  const { isMobile } = useFullScreen("md");

  return (
    <Box>
      <Container disableGutters={isMobile}>
        <Box
          data-cy="nav-bar"
          sx={{
            py: 1.75,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box>
            <Typography sx={{ paddingRight: "12px", fontWeight: 500, fontSize: "14px" }}>Jump to:</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Object.values(sectionTitles).map((value) => (
              <Box key={value}>
                <Link
                  key={value}
                  href={`#${value}`}
                  sx={{
                    py: 0,
                    px: { md: 0, lg: 1.5 },
                    pr: { md: 1.5, lg: 0 },
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    fontSize: "14px",
                  }}
                >
                  {value}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};
