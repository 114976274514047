import React, { type ChangeEvent, useEffect, useMemo, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Checkbox, Collapse, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

import { S3BucketNameRegex } from "../../../../Customer/SaaSConsoleOnboarding/AWS/consts";
import { type Feature } from "../../types";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

type FeaturesSelectionRowProps = {
  feature: Feature;
  supportedFeatures: string[];
  handleFeatureSelection: (featureName: string, checked: boolean) => void;
  disabled: boolean;
  smDown?: boolean;
  onRealTimeDataBucketNameChanged: (bucket: string) => void;
  realTimeDataBucketName: string;
};

export const FeaturesSelectionRow = ({
  feature,
  supportedFeatures,
  disabled,
  handleFeatureSelection,
  onRealTimeDataBucketNameChanged,
  realTimeDataBucketName,
}: FeaturesSelectionRowProps) => {
  const classes = useRowStyles();

  const [rowExpanded, setRowExpanded] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alreadySupported, setAlreadySupported] = useState<boolean>(false);
  const bucketNameIsValid = useMemo(() => S3BucketNameRegex.test(realTimeDataBucketName), [realTimeDataBucketName]);

  useEffect(() => {
    setAlreadySupported(supportedFeatures.includes(feature.name));
  }, [supportedFeatures, feature.name]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setChecked(event.target.checked);
    handleFeatureSelection(feature.name, event.target.checked);
  }

  const handleRealTimeDataBucketNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onRealTimeDataBucketNameChanged(event.target.value);
  };

  const detailedPermissions: string[] = useMemo(
    () => (feature.policies ? feature.policies : feature.permissions),
    [feature]
  );

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => {
              setRowExpanded(!rowExpanded);
            }}
          >
            {rowExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {feature.displayName}
        </TableCell>
        <TableCell padding="none">
          {alreadySupported ? (
            <Tooltip title="Feature already enabled">
              <Box>
                <Checkbox disabled={true} checked={true} />
              </Box>
            </Tooltip>
          ) : (
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleChange}
              disabled={disabled || feature.mandatory}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
              }}
            >
              <Typography variant="subtitle1">{feature.description}</Typography>
              <Box
                sx={{
                  py: 1,
                }}
              >
                <Typography variant="subtitle2">Required {feature.policies ? "policies" : "permissions"}:</Typography>
              </Box>
              {detailedPermissions.map((permission, i) => (
                <div key={`t${i}`}>
                  <Typography component="div" variant="body2" color="textPrimary">
                    - {permission}
                  </Typography>
                </div>
              ))}
              {feature.textFields && (
                <Box sx={{ mt: 2, mb: 1.5 }}>
                  {feature.textFields.map((tf, i) => (
                    <div key={i}>
                      <Box
                        sx={{
                          mb: 1.5,
                        }}
                      >
                        {tf.title}
                      </Box>
                      <TextField
                        value={realTimeDataBucketName}
                        onChange={handleRealTimeDataBucketNameChange}
                        required={tf.required}
                        label={tf.label}
                        placeholder={tf.placeholder}
                        helperText={tf.helperText}
                        fullWidth
                        error={!bucketNameIsValid}
                      />
                    </div>
                  ))}
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
