import { type SyntheticEvent, useCallback } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import FilterIcon from "@mui/icons-material/FilterListRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Badge, IconButton, Link, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { metricSplittingTxt } from "../../../../assets/texts/CloudAnalytics";

const text = {
  textOverflow: "ellipsis",
  maxWidth: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  userSelect: "none",
};

type Props = {
  label: string;
  numOfValues: number;
  description?: string;
  onRemove: (() => void) | undefined;
  onClick: ((event: SyntheticEvent) => void) | undefined;
  disabled?: boolean;
  filtered?: boolean;
  tooltip?: string;
  showSplitButton?: boolean;
  openSplitMenu?: (event: SyntheticEvent) => void;
  splitsLen?: number;
  includeOrigin?: boolean;
  onIncludeOrigin?: () => void;
};

const ReportItemContent = ({
  label,
  numOfValues,
  description,
  onRemove,
  onClick,
  disabled,
  filtered,
  tooltip,
  showSplitButton,
  openSplitMenu,
  splitsLen,
  includeOrigin,
  onIncludeOrigin,
}: Props) => {
  const onClearIconClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onRemove?.();
    },
    [onRemove]
  );

  const onSplitIconClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      openSplitMenu?.(event);
    },
    [openSplitMenu]
  );

  const onIncludeOriginClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onIncludeOrigin?.();
    },
    [onIncludeOrigin]
  );

  const enabledCursorStyle = !onClick ? "grab" : "pointer";

  return (
    <Tooltip title={tooltip}>
      <Grid
        container
        onClick={onClick}
        sx={{
          columnGap: 0.5,
          alignItems: "center",
          flexWrap: "nowrap",
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            flexDirection: "column",
            ml: 1,
            cursor: disabled ? "default" : enabledCursorStyle,
            minWidth: 0,
            py: 1,
          }}
        >
          <Typography variant="subtitle2" sx={text}>
            {label}
          </Typography>
          <Typography variant="caption" sx={text}>
            {description}
          </Typography>
          {!disabled && !!splitsLen && showSplitButton && (
            <>
              <Typography
                variant="caption"
                sx={[
                  {
                    color: "text.secondary",
                  },
                  ...(Array.isArray(text) ? text : [text]),
                ]}
              >
                {splitsLen}
                {splitsLen === 1 ? metricSplittingTxt.SPLIT_ITEM : metricSplittingTxt.SPLIT_ITEMS}
              </Typography>
              <Link
                component="button"
                color="text.secondary"
                variant="caption"
                underline="always"
                onClick={onIncludeOriginClick}
                sx={{ textAlign: "left", textWrap: "nowrap" }}
              >
                {includeOrigin ? metricSplittingTxt.ORIGIN_BTN.HIDE : metricSplittingTxt.ORIGIN_BTN.VIEW}
                {metricSplittingTxt.ORIGIN_BTN.TITLE}
              </Link>
            </>
          )}
        </Grid>
        <Grid container sx={{ alignItems: "center", width: "auto", ml: "auto", flexWrap: "nowrap" }}>
          {!disabled && filtered && (
            <Badge
              badgeContent={numOfValues}
              color="primary"
              max={9}
              sx={(theme) => ({
                pr: 0.2,
                "& .MuiBadge-badge": {
                  left: 0,
                  top: -7,
                  transform: "scale(0.9)",
                  fontSize: "0.62rem",
                  outline: `2px solid ${theme.palette.background.paper}`,
                  fontWeight: 600,
                },
                "& .MuiBadge-standard": {
                  mx: 1,
                },
              })}
            >
              <FilterIcon fontSize="small" color="action" sx={{ cursor: "pointer" }} />
            </Badge>
          )}
          {!disabled && (
            <IconButton onClick={onClearIconClick} size="small">
              <ClearIcon fontSize="small" color="action" sx={{ pointerEvents: "none" }} />
            </IconButton>
          )}
          {!disabled && showSplitButton && (
            <IconButton sx={{ ml: -0.8 }} onClick={onSplitIconClick} size="small">
              <MoreVertIcon fontSize="small" color="action" sx={{ pointerEvents: "none" }} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Tooltip>
  );
};

export default ReportItemContent;
