import Typography from "@mui/material/Typography";

import { cyIds } from "../LicenseChangeConfirmSummary";

type ParagraphTextProps = {
  quantity: number;
  skuName: string;
  domain: string;
};

const GSuiteConfirmationParagraphText = ({ quantity, skuName, domain }: ParagraphTextProps) => {
  const licencesOptionalPlural = Math.abs(quantity) === 1 ? "license" : "licenses";
  const licensesAction = quantity > 0 ? "Purchase" : "Remove";
  const licensesDelta = (
    <Typography
      variant="body1"
      component="strong"
      data-cy={cyIds.text.quantity}
      sx={{
        fontWeight: "bold",
      }}
    >
      {Math.abs(quantity)}
    </Typography>
  );
  const skuNameText = (
    <Typography
      variant="body1"
      component="strong"
      data-cy={cyIds.text.skuName}
      sx={{
        fontWeight: "bold",
      }}
    >
      {skuName}
    </Typography>
  );
  const domainText = (
    <Typography
      variant="body1"
      component="strong"
      data-cy={cyIds.text.domain}
      sx={{
        fontWeight: "bold",
      }}
    >
      {domain}
    </Typography>
  );

  return (
    <Typography
      variant="body1"
      component="span"
      data-cy={cyIds.section.summary}
      sx={{
        color: "text.primary",
      }}
    >
      {licensesAction} {licensesDelta} {licencesOptionalPlural}
      {" of "}
      {skuNameText}
      {" for the domain "}
      {domainText}
      {quantity > 0 ? " for a total of:" : "?"}
    </Typography>
  );
};

export default GSuiteConfirmationParagraphText;
