import { useState } from "react";

import { Alert, Stack, Typography } from "@mui/material";

import { onboardingTxt } from "../../../assets/texts/EKS/onboarding";
import LoadingButton from "../../../Components/LoadingButton";
import { useEKSApi } from "../hooks";
import { type ClusterConfigProps } from "../Steps/ConnectionStep";

export const CheckConnection = ({ clusterConfig, tab }: ClusterConfigProps) => {
  const eksApi = useEKSApi();
  const [loading, setLoading] = useState(false);
  const [hasClickedConnect, setHasClickedConnect] = useState(false);
  const [hasError, setHasError] = useState(false);

  const validateConnectionStepNumber = (): number => {
    switch (true) {
      case tab === onboardingTxt.HELM_TAB:
        if (clusterConfig.isEc2Cluster) {
          return 5;
        }
        return 2;
      case tab === onboardingTxt.KUBECTL_TAB:
        if (clusterConfig.isEc2Cluster) {
          return 5;
        }
        return 3;
      default:
        return 3;
    }
  };

  const handleVerifyConnection = async () => {
    setHasClickedConnect(true);
    setHasError(false);
    setLoading(true);
    try {
      const response = await eksApi.verifyConnection(clusterConfig);
      setHasError(response.status !== 200);
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      spacing={2}
      sx={{
        pb: 12,
      }}
    >
      <Typography variant="body2">
        {validateConnectionStepNumber()}. {onboardingTxt.VALIDATE_CONNECTION}
      </Typography>
      <LoadingButton
        variant="outlined"
        sx={{ width: "fit-content" }}
        loading={loading}
        onClick={handleVerifyConnection}
        mixpanelEventId="eks-onboarding-verify-connection"
        eventProperties={{ onboardingType: tab }}
      >
        {onboardingTxt.CHECK}
      </LoadingButton>
      {!hasError && hasClickedConnect && clusterConfig.status !== "active" && (
        <Alert severity="info" sx={{ width: "fit-content" }}>
          <Typography variant="body2">{onboardingTxt.CHECKING_MSG}</Typography>
        </Alert>
      )}
      {clusterConfig.status === "active" && !hasError && (
        <Alert severity="success" sx={{ width: "fit-content" }}>
          <Typography variant="body2">{onboardingTxt.CONNECTION_SUCCESSFUL}</Typography>
        </Alert>
      )}
      {hasError && (
        <Alert severity="error" sx={{ width: "fit-content" }}>
          <Typography variant="body2">{onboardingTxt.CONNECTION_UNSUCCESSFUL}</Typography>
        </Alert>
      )}
      {!!clusterConfig.verificationError && (
        <Alert severity="error" sx={{ width: "fit-content" }}>
          <Typography variant="body2">{clusterConfig.verificationError}</Typography>
        </Alert>
      )}
    </Stack>
  );
};
