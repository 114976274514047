import { Fragment, type JSX, type MouseEvent, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Menu, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { type ButtonProps } from "@mui/material/Button/Button";
import Divider from "@mui/material/Divider";
import { Stack } from "@mui/system";

type Props = {
  title: string;
  buttonText: string;
  buttonProps?: Omit<ButtonProps, "onClick">;
  children: JSX.Element;
};

export const ConfirmMenu = ({ title, buttonText, buttonProps, children }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // there is an issue with rendering the drag and drop children directly when the menu is opened,
  // there should be a delay after the menu is clicked, so do not remove this state!
  const [renderChildren, setRenderChildren] = useState<boolean>(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    // do not remove this timeout
    setTimeout(() => {
      setRenderChildren(true);
    }, 0);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRenderChildren(false);
  };

  return (
    <>
      <Button
        {...buttonProps}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<EditIcon />}
      >
        {buttonText}
      </Button>
      {open && (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <Stack spacing={1}>
            <Typography
              variant="h4"
              sx={{
                pl: 2,
                pt: 1,
              }}
            >
              {title}
            </Typography>
            <Divider />
            {renderChildren && children}
          </Stack>
        </Menu>
      )}
    </>
  );
};
