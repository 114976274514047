import { useCallback, useEffect, useMemo, useState } from "react";

import { IntegrationModel, type IntegrationModelFlexibleReservedInstancesModel } from "@doitintl/cmp-models";
import {
  getCollection,
  type QueryModel,
  type TransformMethod,
  useCollectionDataOnce,
} from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Box, Card, CircularProgress, Divider, IconButton, Switch, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import { PageHeader } from "../../../Components/PageLayout/PageHeader/PageHeader";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { addToObject, sanitizeDate } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { csvFileName } from "./constants";
import FlexsaveOrdersList from "./FlexsaveOrdersList";
import ThreeDotsMenu from "./ThreeDotsMenu";
import { autopilotVal, type FlexsaveOrder } from "./types";

export const calcOrderSavings = (order: FlexsaveOrder) => {
  if (order.execution === autopilotVal) {
    return -(order.autopilot.mtdFlexRIPenalty + order.autopilot.mtdFlexRISavings);
  } else if (order.normalizedUnits) {
    const credit = order.pricing.savingsPerHourNormalized * order.normalizedUnits.utilized;
    const penalty = order.pricing.flexibleNormalized * order.normalizedUnits.underUtilized;
    return credit - penalty;
  } else {
    return 0;
  }
};

const customerViewHeaders = {
  id: "ID",
  email: "Email",
  accountId: "AccountID",
  status: "Status",
  region: "Region",
  instanceFamily: "InstanceFamily",
  instanceType: "InstanceType",
  operatingSystem: "OperatingSystem",
  numInstances: "NumInstances",
  startDate: "StartDate",
  endDate: "EndDate",
  note: "Note",
  autoRenew: "Recurring",
  usedNFU: "UsedNFU",
  unusedNFU: "UnusedNFU",
  normFactor: "NormFactor",
  requiredNFU: "RequiredNFU",
};

const nonCustomerViewHeaders = () => {
  const tempNewObj = { ...customerViewHeaders };
  const customerIncluded = addToObject(tempNewObj, "customer", "customer", 1);
  return addToObject(customerIncluded, "payerAccountId", "payerAccountID", 4);
};

const useFlexsaveOrdersQuery = (getAllDocuments: boolean) => {
  const [query, setQuery] = useState<QueryModel<IntegrationModelFlexibleReservedInstancesModel>>();

  useEffect(() => {
    const baseQuery = getCollection(IntegrationModel)
      .doc("amazon-web-services")
      .collection("flexibleReservedInstances")
      .where("status", "in", ["new", "pending", "active", "retired", "failed", "canceled"]);

    if (getAllDocuments) {
      setQuery(baseQuery);
      return;
    }

    getCollection(IntegrationModel)
      .doc("flexsave")
      .get()
      .then((flexSaveMetaQuery) => {
        const data = flexSaveMetaQuery.asModelData();

        if (!data) {
          throw new Error("No flexsave metadata found");
        }

        const oldestIdToFetch = data.awsOrdersOffset;

        if (oldestIdToFetch) {
          setQuery(baseQuery.where("id", ">=", oldestIdToFetch));
          return;
        }

        setQuery(baseQuery);
      });
  }, [getAllDocuments]);

  return query;
};

export default function FlexsaveOrders() {
  const sharedSnackbar = useSnackbar();
  const api = useApiContext();
  const [getAllDocuments, setGetAllDocuments] = useState(false);
  const query = useFlexsaveOrdersQuery(getAllDocuments);

  const transform: TransformMethod<IntegrationModelFlexibleReservedInstancesModel, FlexsaveOrder> = useCallback(
    (docData, snapshot) => {
      const showPricingInfo =
        ["active", "retired"].includes(docData.status) && !!docData.normalizedUnits && !!docData.pricing;

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const data = {
        ...docData,
        _id: snapshot.id,
        savings: 0,
        showPricingInfo,
      } as FlexsaveOrder;

      return {
        ...data,
        savings: showPricingInfo ? calcOrderSavings(data) : 0,
      };
    },
    []
  );

  const [orderData] = useCollectionDataOnce(query, {
    transform,
  });

  const csvRows = useMemo(
    () =>
      (orderData ?? []).map((order) => {
        const isNormalizedUnits = order.normalizedUnits !== null;
        const cleanResult = (result: number | undefined): string | number => {
          if (isNormalizedUnits) {
            return result === 0 ? "0" : Number.isInteger(result) ? (result as number) : (result as number).toFixed(2);
          }

          return "";
        };
        const requiredNFU = cleanResult(order.normalizedUnits?.unitsPerHour);
        const usedInNFU = cleanResult(order.normalizedUnits?.utilized);
        const underUtilizedInNFU = cleanResult(order.normalizedUnits?.underUtilized);

        const sharedFields = {
          id: order.id,
          email: order.email,
          accountId: order.config.accountId.toString(),
          status: order.status,
          region: order.config.region,
          instanceFamily: order.config.instanceFamily,
          instanceType: order.config.instanceType,
          operatingSystem: order.config.operatingSystem,
          numInstances: order.config.numInstances,
          startDate: sanitizeDate(DateTime.fromJSDate(order.config.startDate.toDate())).toFormat("yyyy-LL-dd"),
          endDate: sanitizeDate(DateTime.fromJSDate(order.config.endDate.toDate())).toFormat("yyyy-LL-dd"),
          note: order.config.note ? order.config.note : "",
          autoRenew: order.config?.autoRenew
            ? DateTime.fromJSDate(order.config.autoRenew.toDate()).toFormat("yyyy-LL-dd")
            : "",
          usedNFU: usedInNFU,
          unusedNFU: underUtilizedInNFU,
          normFactor: cleanResult(order.normalizedUnits?.factor),
          requiredNFU,
        };

        const customData = () => {
          const tempNewObj = { ...sharedFields };
          const customerIncluded = addToObject(tempNewObj, "customer", order.metadata.customer.primaryDomain, 1);
          return addToObject(customerIncluded, "payerAccountId", order.config.payerAccountId, 4);
        };
        return customData();
      }),
    [orderData]
  );

  useEffect(() => {
    mixpanel.track("flexsave-aws.view");
  }, []);

  const handleActivateOrder = (order: FlexsaveOrder) => async () => {
    const response = await api.request({
      method: "patch",
      url: `/v1/customers/${order.customer.id}/flexsave/orders/${order._id}/activate`,
    });
    if (response.status === 200) {
      sharedSnackbar.onOpen({
        message: `Order ${order.id} was activated`,
        variant: "success",
        autoHideDuration: 10000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    }
  };

  return (
    <Card sx={{ border: orderData ? undefined : 0 }}>
      <PageHeader title="Flexsave" subheader="All Customers" backButtonRoute="/customers" />
      {orderData && (
        <>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                ml: 2,
              }}
            >
              <Typography>Show all items: </Typography>
              <Switch
                checked={getAllDocuments}
                onChange={(e) => {
                  setGetAllDocuments(e.target.checked);
                }}
              />
            </Box>

            <ThreeDotsMenu headers={nonCustomerViewHeaders()} rows={csvRows} fileName={csvFileName} />
          </Box>
          <FlexsaveOrdersList orders={orderData} onActivate={handleActivateOrder} />

          <Divider />
        </>
      )}
      {!orderData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
}
