import { type JSX } from "react";

import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";

type Props = {
  rightSideContent: JSX.Element;
  height?: string;
  children: JSX.Element;
};

export const SideContentLayout = ({ children, rightSideContent }: Props) => (
  <Container maxWidth="lg">
    <Grid
      container
      sx={{
        px: 1,
        alignItems: "center",
        justifyContent: { xs: "center", md: "space-between" },
        gap: { xs: 6 },
      }}
    >
      <Grid
        size={{
          xl: 5,
          lg: 6,
          md: 6,
        }}
      >
        {children}
      </Grid>
      <Grid
        sx={{
          ml: { lg: 4, md: 4 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%" },
            height: { sm: "100%" },
            p: { lg: 5 },
          }}
        >
          {rightSideContent}
        </Box>
      </Grid>
    </Grid>
  </Container>
);
