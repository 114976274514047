import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadingBox = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      p: 6,
    }}
  >
    <CircularProgress />
  </Box>
);
