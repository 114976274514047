import React, { useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import { InputAdornment, Stack, TextField } from "@mui/material";

import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";

interface QuartersNextPeriodPullOverProps {
  currency: CurrencyCode;
  disabled: boolean;
}

const QuartersNextPeriodPullOver: React.FC<QuartersNextPeriodPullOverProps> = ({ currency, disabled }) => {
  const initialQuarters = Array.from({ length: 4 }, (_, i) => {
    const quarterName = `Q${i + 1}`;
    return { [quarterName]: quarterName };
  }).reduce((acc, quarter) => ({ ...acc, ...quarter }), {});

  const [quarters, setQuarters] = useState<{ [key: string]: string }>(initialQuarters);

  const handleQuarterChange = (quarterKey: string, value: string) => {
    setQuarters((prev) => ({ ...prev, [quarterKey]: value }));
  };

  return (
    <Stack
      sx={{
        gap: 3,
      }}
    >
      {Object.keys(quarters).map((quarterKey, index) => (
        <TextField
          key={`QuartersNextPeriodPullOver${index}`}
          variant="outlined"
          type="number"
          fullWidth
          required
          disabled={!disabled}
          label={quarterKey}
          value={quarters[quarterKey]}
          onChange={(e) => {
            handleQuarterChange(quarterKey, e.target.value);
          }}
          onKeyUp={onKeyPressPreventNonNumeric}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
            },
          }}
        />
      ))}
    </Stack>
  );
};

export default QuartersNextPeriodPullOver;
