import { Link, Stack, Typography } from "@mui/material";

import { ContactSupportButton } from "../../../Flexsave/Standalone/GCP/Steps/Common/ContactSupportButton";

type Props = {
  azureLink?: string;
};

export const UsefulAzureLinks = ({ azureLink }: Props) => (
  <Stack
    direction="row"
    spacing={2}
    sx={{
      alignItems: "center",
      pb: 2,
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: "500",
      }}
    >
      Useful links:
    </Typography>

    <ContactSupportButton />

    <Link href="https://help.doit.com/docs/general/saas/console-azure" target="_blank" underline="hover">
      DoiT Help Center
    </Link>

    {azureLink ? (
      <Link href={azureLink} target="_blank" underline="hover">
        Azure Help Center
      </Link>
    ) : null}
  </Stack>
);
