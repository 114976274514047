import { type MouseEventHandler, useRef, useState } from "react";

import { NavLink } from "react-router-dom";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { useServicesMixpanelHandler } from "../../../Pages/Perks/hooks";
import mixpanel from "../../../utils/mixpanel";
import { type CategoryContext, type CategoryItemContext } from "../../types";

const useStyles = makeStyles<Theme, { dense: boolean }>((theme: Theme) => ({
  button: {
    padding: (props) => (props.dense ? "5px" : ""),
  },
  innerMenuLink: {
    textTransform: "none",
    textDecoration: "none",
    minWidth: 0,
  },
  menuItem: {
    color: theme.palette.text.secondary,
  },
  buttonWithoutIconPadding: {
    padding: "6px 8px",
  },
  buttonWithIconPadding: {
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: (props) => (props.dense ? "6px" : "10px"),
    paddingRight: (props) => (props.dense ? "1px" : "6px"),
  },
  hover: {
    "&:hover": {
      background: theme.palette.common.white,
      color: theme.palette.navigation.background,
    },
  },
}));

type Props = {
  category: CategoryContext;
  dense?: boolean;
};

export const HeaderCategory = ({ category, dense = false }: Props) => {
  const classes = useStyles({ dense });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [currentItem, setCurrentItem] = useState<CategoryItemContext | null>(null);
  const [secondaryMenuAnchor, setSecondaryMenuAnchor] = useState<Element | null>(null);
  const { mixpanelServicesHandler } = useServicesMixpanelHandler();

  const handleToggle = () => {
    if (category.displayName === "Services") {
      mixpanelServicesHandler("nav.services");
    }

    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleOpenSecondaryMenu = (anchor: Element | null, item: CategoryItemContext | null) => {
    if (!open) {
      return;
    }
    setSecondaryMenuAnchor(anchor);
    setCurrentItem(item);
  };

  const handleCloseSecondaryMenu = () => {
    setSecondaryMenuAnchor(null);
    setCurrentItem(null);
  };

  const createHandleItemClick =
    (item: CategoryItemContext): MouseEventHandler =>
    (e) => {
      if (e.defaultPrevented) {
        e.preventDefault();
        return;
      }
      mixpanel.track("navigation.click", { pageId: item.pageId, menuType: "navBar" });

      if (item.displayName === "Professional Services") {
        mixpanelServicesHandler("nav.services.ps");
      }
      setOpen(false);
      handleCloseSecondaryMenu();
    };

  const handleSubItemClick = (subItem: CategoryItemContext) => {
    mixpanel.track("navigation.click", { pageId: subItem.pageId, menuType: "subItems" });
    handleCloseSecondaryMenu();
  };

  const CategoryName = (
    <Box
      sx={{
        fontSize: 14,
      }}
    >
      {category.displayName}
    </Box>
  );
  const buttonVariant = category.isSelected ? "contained" : "text";

  return (
    <Box>
      {category.fullRoute ? (
        <Button
          component={NavLink}
          to={category.fullRoute}
          className={clsx(classes.button, category.isSelected && classes.hover)}
          variant={buttonVariant}
          classes={{
            contained: clsx(classes.buttonWithoutIconPadding),
          }}
          color="header"
        >
          {CategoryName}
        </Button>
      ) : (
        <Button
          className={clsx(classes.button, category.items.map((c) => c.isSelected).some((c) => c) && classes.hover)}
          classes={{
            contained: clsx(classes.buttonWithIconPadding),
          }}
          ref={anchorRef}
          aria-haspopup="true"
          variant={buttonVariant}
          onClick={handleToggle}
          disableFocusRipple
          color="header"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              lineHeight: 1.43,
            }}
          >
            {CategoryName}
            {!category.fullRoute && <ExpandMoreIcon fontSize="small" />}
          </Box>
        </Button>
      )}
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="top-start">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Box
              sx={{
                pt: 1.8,
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    {category.items.map((item) => (
                      <MenuItem
                        key={item.displayName}
                        component={NavLink}
                        to={item.fullRoute}
                        onClick={createHandleItemClick(item)}
                        onMouseLeave={handleCloseSecondaryMenu}
                        sx={{ gap: 2, py: 0 }}
                      >
                        <Box
                          sx={{
                            fontSize: 14,
                            color: "navigation.subItemColor",
                            py: 0.75,
                          }}
                        >
                          {item.displayName}
                        </Box>

                        {item.icon && <item.icon sx={{ fontSize: "14px" }} />}

                        {!!item.subItems?.length && (
                          <Box
                            sx={{ ml: "auto", mr: -2, py: 0.3, px: 1 }}
                            onMouseEnter={(e) => {
                              handleOpenSecondaryMenu(e.currentTarget.parentElement, item);
                            }}
                          >
                            <IconButton
                              size="small"
                              disableTouchRipple
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <ArrowRight fontSize="small" color="action" />
                            </IconButton>
                          </Box>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Box>
          </Grow>
        )}
      </Popper>
      <Popper
        open={!!secondaryMenuAnchor}
        anchorEl={secondaryMenuAnchor}
        transition
        disablePortal
        placement="right-start"
        sx={{ pointerEvents: "none", minWidth: "180px" }}
        onMouseEnter={() => {
          handleOpenSecondaryMenu(secondaryMenuAnchor, currentItem);
        }}
        onMouseLeave={handleCloseSecondaryMenu}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Paper sx={{ pointerEvents: "auto" }}>
              <MenuList autoFocusItem={!!secondaryMenuAnchor} id="menu-list-grow-2">
                {currentItem?.subItems?.map((subItem) => (
                  <MenuItem
                    key={subItem.displayName}
                    component={NavLink}
                    to={subItem.fullRoute}
                    onClick={() => {
                      handleSubItemClick(subItem);
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: 14,
                        color: "navigation.subItemColor",
                      }}
                    >
                      {subItem.displayName}
                    </Box>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
