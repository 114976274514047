import { Link, Stack, Typography } from "@mui/material";

import { ContactSupportButton } from "../../Standalone/GCP/Steps/Common/ContactSupportButton";
import { type UsefulLink } from "./types";

type Props = {
  links: UsefulLink[];
};

export const UsefulLinks = ({ links }: Props) => (
  <Stack spacing={1}>
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: "500",
      }}
    >
      Useful links:
    </Typography>

    <ContactSupportButton />

    {links.map((link) => (
      <Link href={link.url} key={link.title} target="_blank" underline="hover">
        {link.title}
      </Link>
    ))}
  </Stack>
);
