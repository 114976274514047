import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { type CustomerNote } from "../types";
import { getNoteLabelByKey, getNoteTagKeys } from "../utils";

type Props = {
  noteItem: CustomerNote;
};

export const NoteContent = ({ noteItem }: Props) => {
  const isDarkMode = useDarkThemeCheck();
  const tags = getNoteTagKeys(noteItem);
  const tagBgColor = isDarkMode ? "#252533" : grey[50];

  return (
    <>
      <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }} data-cy="note-content-body">
        {noteItem.body}
      </Typography>
      {tags.length > 0 && (
        <Stack
          direction="row"
          sx={{
            gap: 0.5,
            mt: 2,
          }}
        >
          <Typography variant="caption" sx={{ py: 0.5 }}>
            Tags:{" "}
          </Typography>
          {tags.map((tag) => (
            <Typography key={tag} variant="caption" sx={{ background: tagBgColor, p: 0.5 }} data-cy="note-tag-name">
              {getNoteLabelByKey(tag)}
            </Typography>
          ))}
        </Stack>
      )}
    </>
  );
};
