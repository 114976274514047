import { type PurchaseRisk } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { DateTime } from "luxon";

import { type Workload } from "../../types";
import { getChipConfigByPurchaseStatus } from "../../utils/purchaseStatusUtils";

const RiskyPurchaseStatus = ({ workload }: { workload: Workload }) => {
  const theme = useTheme();
  const { status, purchaseRisk } = workload;
  const { bgColor, textColor, text } = getChipConfigByPurchaseStatus(theme, status);

  const getTooltipText = (purchaseRisk?: PurchaseRisk) => {
    if (purchaseRisk?.approvedAt && (status === "in-progress" || status === "purchased")) {
      return `${purchaseRisk?.approvedBy} at ${DateTime.fromJSDate(purchaseRisk?.approvedAt.toDate()).toLocaleString(
        DateTime.DATETIME_MED
      )}`;
    }
    if (purchaseRisk?.rejectedAt && status === "rejected") {
      return `${purchaseRisk.rejectedBy} at ${DateTime.fromJSDate(purchaseRisk.rejectedAt.toDate()).toLocaleString(
        DateTime.DATETIME_MED
      )}`;
    }
  };

  const tooltipText = getTooltipText(purchaseRisk);

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
      }}
    >
      <Chip
        label={text}
        sx={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      />
      {!!tooltipText && (
        <Tooltip title={tooltipText} arrow placement="right">
          <IconButton aria-label="info about status">
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default RiskyPurchaseStatus;
