/* eslint-disable func-names */
import React, { useMemo, useState } from "react";

import {
  type CustomPeriodUnit,
  customPeriodUnits,
  type DeliveryFrequency,
  NotificationProviderType,
  type SlackChannel,
  UserNotification,
} from "@doitintl/cmp-models";
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import * as Yup from "yup";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { consoleErrorWithSentry } from "../../utils";
import LoadingButton from "../LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import { allTimezones } from "./allTimeZones";
import { useAddReportCollaborators } from "./api";
import {
  createDashboardSubscriptionDoc,
  createReportSubscriptionDoc,
  deleteSubscriptionDoc,
  getActualOrg,
  updateSubscriptionDoc,
} from "./db";
import { CustomPeriodInput } from "./FormItems/CustomRepetitions";
import { DeliveryFrequencyInput } from "./FormItems/DeliveryFrequency";
import { EmailInput } from "./FormItems/Emails";
import { ScheduleInfoBar } from "./FormItems/ScheduleInfo";
import { SlackChannelsInput } from "./FormItems/SlackChannels";
import { StartDateInput } from "./FormItems/StartDate";
import { TimeInput } from "./FormItems/Time";
import { TimeZoneInput } from "./FormItems/TimeZone";
import {
  constructFormInitialValues,
  getSubscriptionParentTextName,
  isThereInputError,
  useSubscriptionPermissions,
} from "./helpers";
import {
  type CommonFormCreationFields,
  type SubscriptionByType,
  type SubscriptionFormMode,
  type SubscriptionFormValues,
  type SubscriptionParentByType,
  type SubscriptionType,
} from "./types";

const validationSchema = Yup.object().shape({
  emails: Yup.array().of(Yup.string().email("Invalid email address")).max(100, "Must be less than 100 emails"),
  slackChannels: Yup.array()
    .of(
      Yup.object<SlackChannel>({
        id: Yup.string().min(2).required("slack channel id is required"),
        name: Yup.string().required("slack channel name is required"),
        customerId: Yup.string().min(2).required("slack channel customer id is required"),
        shared: Yup.boolean(),
        workspace: Yup.string(),
      })
    )
    .max(100, "Must be less than 100 slack channels"),
  timeZone: Yup.string().oneOf(allTimezones),
  deliveryFrequency: Yup.string().required("Required"),
  startDate: Yup.mixed()
    .required("Required")
    .test("is-luxon-date", "Invalid date", (value) => DateTime.isDateTime(value) && value.isValid),
  time: Yup.mixed()
    .required("Required")
    .test("is-luxon-time", "Invalid time", (value) => DateTime.isDateTime(value) && value.isValid),

  customPeriodAmount: Yup.number()
    .nullable()
    .test("custom-period-amount", "Invalid value", function (value) {
      const { deliveryFrequency } = this.parent;
      if (deliveryFrequency === "Custom") {
        return typeof value === "number" && value > 0;
      }
      return value === null;
    }),

  customPeriodUnit: Yup.string()
    .nullable()
    .test("custom-period-unit", "Invalid value", function (value) {
      const { deliveryFrequency } = this.parent;
      if (deliveryFrequency === "Custom") {
        return typeof value === "string" && customPeriodUnits.includes(value as any);
      }
      return value === null;
    }),
});

export function SubscriptionForm<ST extends SubscriptionType>({
  mode,
  closeFormDialog,
  isOpen,
  onClose,
  subscriptionType,
  subscriptionParent,
  subscription,
}: {
  closeFormDialog: () => void;
  mode: SubscriptionFormMode;
  isOpen: boolean;
  onClose: () => void;
  subscriptionType: ST;
  subscriptionParent: SubscriptionParentByType<ST>;
  subscription: SubscriptionByType<ST> | undefined;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const successSnackbar = useSuccessSnackbar();
  const errSnackbar = useErrorSnackbar();
  const [preventSubmitByEmailsChild, setPreventSubmitByEmailsChild] = useState(false);
  const { allowedToDelete } = useSubscriptionPermissions({ subscriptionType, subscriptionParent, subscription });
  const addReportCollaborators = useAddReportCollaborators();

  const { customer } = useCustomerContext();
  const { userModel } = useUserContext({ allowNull: false });
  const { isDoitEmployee } = useAuthContext();

  const parentTextName = getSubscriptionParentTextName(subscriptionType);

  const onDelete = async () => {
    try {
      setLoading(true);
      if (!subscription) {
        throw new Error("Missing subscription in delete mode");
      }
      await deleteSubscriptionDoc({ _subscriptionType: subscriptionType, doc: subscription });
      closeFormDialog();
      successSnackbar(`${parentTextName} subscription deleted`);
    } catch (error: any) {
      consoleErrorWithSentry(error);
      errSnackbar(error.message);
    } finally {
      setLoading(false);
    }
  };

  const subscriptionOrg = useMemo(
    () =>
      getActualOrg({
        orgs: userModel.organizations,
        customerId: customer.id,
        isDoitEmployee,
      }),
    [userModel.organizations, customer.id, isDoitEmployee]
  );

  const handleSubmit = async (values: SubscriptionFormValues) => {
    try {
      setLoading(true);

      if (mode === "create") {
        const commonFields: CommonFormCreationFields = {
          customerId: customer.id,
          createdBy: userModel.email,
          orgRef: subscriptionOrg,
          time: values.time,
          timeZone: values.timeZone as string,
          startDate: values.startDate,
          frequency: values.deliveryFrequency as DeliveryFrequency,
          customPeriodUnit: values.customPeriodUnit as CustomPeriodUnit,
          customPeriodAmount: values.customPeriodAmount,
          emails: values.emails,
          slackChannels: values.slackChannels,
        };

        switch (subscriptionType) {
          case UserNotification.DashboardSubscription:
            await createDashboardSubscriptionDoc({
              ...commonFields,
              dashboardPath: subscriptionParent.ref.path,
              dashboardName: subscriptionParent.name,
            });
            break;
          case UserNotification.ReportSubscription:
            await addReportCollaborators(
              values.emails,
              subscriptionParent as SubscriptionParentByType<UserNotification.ReportSubscription>
            );
            await createReportSubscriptionDoc({
              ...commonFields,
              reportRef: subscriptionParent.ref,
              reportName: subscriptionParent.name,
            });
        }
      } else {
        if (!subscription) {
          throw new Error("Missing subscription in edit mode");
        }

        if (subscriptionType === UserNotification.ReportSubscription) {
          await addReportCollaborators(
            values.emails,
            subscriptionParent as SubscriptionParentByType<UserNotification.ReportSubscription>
          );
        }

        await updateSubscriptionDoc({
          doc: subscription,
          startDate: values.startDate,
          time: values.time,
          timeZone: values.timeZone as string,
          frequency: values.deliveryFrequency as DeliveryFrequency,
          customPeriodUnit: values.customPeriodUnit as CustomPeriodUnit,
          customPeriodAmount: values.customPeriodAmount,
          emails: values.emails,
          slackChannels: values.slackChannels,
          subscriptionType,
        });
      }
      setLoading(false);
      successSnackbar(`${parentTextName} subscription successfully ${mode === "create" ? "created" : "updated"} `);
      closeFormDialog();
    } catch (error: any) {
      setLoading(false);
      consoleErrorWithSentry(error);
      errSnackbar(error.message);
    }
  };

  const showNoReportsAlert =
    subscriptionType === UserNotification.DashboardSubscription &&
    (!("widgets" in subscriptionParent) ||
      !subscriptionParent.widgets.some((widget) => widget.name.startsWith("cloudReports:")));

  const showPrivateDashboardAlert =
    subscriptionType === UserNotification.DashboardSubscription &&
    !showNoReportsAlert &&
    (!("isPublic" in subscriptionParent) || !subscriptionParent.isPublic);

  return (
    <Formik
      initialValues={constructFormInitialValues({
        subscription,
        mode,
        userEmail: userModel.email,
        subscriptionType,
      })}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, isValid, dirty }) => (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle>{`${parentTextName} subscription`}</DialogTitle>
          <DialogContent>
            <Form>
              <Divider sx={{ mb: 1.5, mx: -3 }} />
              <Grid container spacing={2}>
                <Grid sx={{ mb: -1.5 }} size={12}>
                  <EmailInput
                    initialEmails={subscription?.providerTarget?.[NotificationProviderType.EMAIL] || []}
                    ownerEmail={subscription?.createdBy || userModel.email}
                    disabled={isSubmitting}
                    preventSubmit={preventSubmitByEmailsChild}
                    setPreventSubmit={setPreventSubmitByEmailsChild}
                    subscriptionOrg={subscriptionOrg}
                  />
                </Grid>
                <Grid size={12}>
                  <SlackChannelsInput disabled={isSubmitting} />
                </Grid>
                <Grid size={12}>
                  <TimeZoneInput disabled={isSubmitting} />
                </Grid>
                <Grid size={6}>
                  <StartDateInput disabled={isSubmitting} />
                </Grid>
                <Grid size={6}>
                  <TimeInput disabled={isSubmitting} />
                </Grid>
                <Grid size={12}>
                  <DeliveryFrequencyInput disabled={isSubmitting} />
                </Grid>
                <Grid size={12}>
                  <CustomPeriodInput disabled={isSubmitting} />
                </Grid>
                <Grid size={12}>
                  <ScheduleInfoBar formMode={mode} />
                </Grid>
                {showNoReportsAlert && (
                  <Grid size={12}>
                    <Alert severity="info" sx={{ mb: 2 }}>
                      This dashboard has no cloud analytics reports yet. The subscription will still be delivered but
                      will not show any reports. Add reports using the ‘Add existing reports’ button.
                    </Alert>
                  </Grid>
                )}
                {showPrivateDashboardAlert && (
                  <Grid size={12}>
                    <Alert severity="info" sx={{ mb: 2 }}>
                      As this is a private dashboard, any users subscribed won’t see this dashboard in the DoiT Console.
                      Only in Slack or email
                    </Alert>
                  </Grid>
                )}
                <Grid size={12}>
                  <Divider sx={{ mx: -3 }} />
                </Grid>
                <Grid sx={{ my: -1 }} size={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {mode === "edit" && allowedToDelete && (
                      <LoadingButton
                        loading={loading}
                        variant="text"
                        color="error"
                        onClick={onDelete}
                        mixpanelEventId={`${parentTextName.toLowerCase()}-subscription.delete`}
                      >
                        Delete subscription
                      </LoadingButton>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="text"
                        color="primary"
                        onClick={closeFormDialog}
                        disabled={isSubmitting}
                        sx={{ mr: 2 }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        loading={loading || isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={
                          loading ||
                          isSubmitting ||
                          !isValid ||
                          isThereInputError(errors) ||
                          (!dirty && mode === "edit") ||
                          preventSubmitByEmailsChild
                        }
                        mixpanelEventId={`${parentTextName.toLowerCase()}-subscription.${mode === "create" ? "create" : "update"}`}
                      >
                        {mode === "create" ? "Save" : "Update"}
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  );
}
