import { Box, StyledEngineProvider } from "@mui/material";

type Props = {
  title: string;
  subtitle: string;
};
export const SettingsHeader = ({ title, subtitle }: Props) => (
  <StyledEngineProvider injectFirst>
    <Box
      sx={{
        px: 2,
        pt: 0.5,
        pb: 1,
      }}
    >
      <Box
        sx={{
          fontSize: 16,
          fontWeight: "bold",
          color: "text.primary",
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          fontSize: 12,
          color: "text.secondary",
        }}
      >
        {subtitle}
      </Box>
    </Box>
  </StyledEngineProvider>
);
