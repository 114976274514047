import { type RefObject, useCallback, useEffect, useRef, useState } from "react";

import {
  AnalyticsDataSource,
  type CalcMetricFormat,
  type ComparativeFeature,
  CurrencyCodes,
  Metadata,
  type MetricVariable,
  Positions,
  Renderer,
  type ReportFilter,
  TimeInterval,
} from "@doitintl/cmp-models";
import TableIcon from "@mui/icons-material/TableChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Alert, AlertTitle, CardContent, Skeleton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import debounce from "lodash/debounce";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";

import { handleResponseError } from "../../../api/axiosClient";
import { useApiContext } from "../../../api/context";
import { metricText } from "../../../assets/texts";
import { type AnalyticsMetadata } from "../../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import useUpdateEffect from "../../../Components/hooks/useUpdateEffect";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type AttributionWRef, type MetricWSnap } from "../../../types";
import mixpanel from "../../../utils/mixpanel";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import { getCols } from "../previewReport/utils";
import ChartsRenderer from "../renderers/ChartsRenderer";
import TableRenderer from "../renderers/TableRenderer";
import { executeQueryRequest, type QueryRequest, QueryType } from "../report/ReportQuery";
import { calculatedMetricParam, defaultTimeSettings } from "../report/ReportQueryUtils";
import ReportData, { type DataRecord } from "../ReportData";
import { FixedFilters } from "../utilities";
import metricStyles from "./MetricStyles";

const useStyles = metricStyles;

type MetricPreviewComponentProps = {
  startPreview: boolean;
  metric?: MetricWSnap;
  variables: MetricVariable[];
  formula: string;
  format: CalcMetricFormat;
  metadataSnapshots: AnalyticsMetadata;
  attributions: AttributionWRef[];
  formatter: (value: any, short?: any, comparative?: ComparativeFeature) => any;
  contentRef: RefObject<HTMLDivElement>;
  alertBoxHidden: boolean;
  smDown: boolean;
};

const MetricPreview = ({
  startPreview,
  metric,
  variables,
  formula,
  format,
  metadataSnapshots,
  attributions,
  formatter,
  contentRef,
  alertBoxHidden,
  smDown,
}: MetricPreviewComponentProps) => {
  const classes = useStyles();
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const { datahubMetrics } = useAnalyticsContext();
  const [renderer, setRenderer] = useState(Renderer.LINE_CHART);
  const [queryRunning, setQueryRunning] = useState(false);
  const [reportError, setReportError] = useState<string | null>(null);
  const reportData = useRef<ReportData | null>(null);
  const highchartRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRun = useCallback(
    debounce(
      async ({ formula, variables, format }) => {
        const accounts = uniq(
          flatten(
            metadataSnapshots
              .filter((md) => md.id === `${Metadata.FIXED}:${FixedFilters.ACCOUNT}`)
              .map((md) => md.get("values") ?? [])
          )
        );

        const timeSettings = defaultTimeSettings();

        const rows: DataRecord[] = [];
        const cols = getCols(metadataSnapshots, TimeInterval.DAY);

        const calculatedMetric = calculatedMetricParam(variables, attributions, formula, format);

        const filterValues = variables.map((v) => v?.attribution?.id).filter((v, i, self) => self.indexOf(v) === i);

        const hasDatahubMetrics = variables.some((v) => datahubMetrics.some((m) => m.key === v.extendedMetric));

        const filter: ReportFilter = {
          id: `${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}`,
          type: Metadata.ATTRIBUTION,
          key: Metadata.ATTRIBUTION,
          position: Positions.ROW,
          allowNull: false,
          values: filterValues,
          inverse: false,
          limit: 0,
          includeInFilter: true,
          field: "",
          regexp: "",
          composite: [],
        };

        const request: QueryRequest = {
          type: QueryType.METRIC,
          id: metric?.snapshot.id ?? "",
          accounts,
          timeSettings,
          rows,
          cols,
          filters: [filter],
          currency: CurrencyCodes.USD,
          cloudProviders: null,
          calculatedMetric,
          dataSource: hasDatahubMetrics ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING,
        };

        try {
          const response = await executeQueryRequest(api, customer.id, request);
          const vals = [cols.length + 3];
          const numMetrics = 1;

          reportData.current = new ReportData({
            data: response.data.rows,
            rows,
            cols,
            vals,
            numMetrics,
          });
        } catch (error: any) {
          handleResponseError(
            error,
            (errorCode) => {
              const reportErr = errorCode;
              reportData.current = null;
              setReportError(reportErr);
            },
            [404, 413]
          );
        }
        setQueryRunning(false);
      },
      2000,
      {
        trailing: true,
        leading: false,
      }
    ),
    []
  );

  useUpdateEffect(() => {
    if (startPreview) {
      setQueryRunning(true);
      setReportError(null);
      handleRun({
        formula,
        variables,
        format,
      });
    }
  }, [startPreview, formula, variables, format]);

  const previewHeight = `calc(100vh - ${alertBoxHidden ? 124 : 154}px - ${
    contentRef.current?.getBoundingClientRect().height ?? 0
  }px)`;

  useEffect(() => {
    reportError && mixpanel.track("analytics.metrics.error", { errorType: reportError });
  }, [reportError]);

  return (
    <>
      {queryRunning && (
        <div className={classes.skeleton}>
          <Typography variant="h6" className={classes.skeletonText} color="textSecondary">
            Fetching Preview...
          </Typography>
          <Skeleton animation="pulse" variant="rectangular" height={previewHeight} width="100%" />
        </div>
      )}
      {!queryRunning && !!reportError && (
        <CardContent>
          <Alert variant="standard" severity="error">
            <AlertTitle>Error</AlertTitle>
            {reportError === "result_empty" ? metricText.PREVIEW_RESULT_EMPTY : metricText.PREVIEW_ERROR}
          </Alert>
        </CardContent>
      )}
      {!queryRunning && reportData.current && (
        <>
          <CardContent className={smDown ? classes.headerCardContentSmall : classes.headerCardContent}>
            <Grid
              container
              sx={{
                alignItems: "center",
              }}
            >
              <Grid
                container
                className={classes.toggleRenderer}
                sx={{
                  justifyContent: "flex-end",
                }}
                size={12}
              >
                <ToggleButtonGroup
                  size="small"
                  value={renderer}
                  exclusive
                  onChange={(e, newRenderer) => {
                    if (newRenderer) {
                      setRenderer(newRenderer);
                    }
                  }}
                >
                  <ToggleButton value={Renderer.LINE_CHART}>
                    <TimelineIcon />
                  </ToggleButton>
                  <ToggleButton value={Renderer.TABLE}>
                    <TableIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </CardContent>
          {renderer === Renderer.TABLE ? (
            <CardContent className={classes.tableCardContent}>
              <TableRenderer
                data={reportData.current}
                formatter={formatter}
                smDown={smDown}
                isForecast={false}
                reverseColors={false}
                height={previewHeight}
              />
            </CardContent>
          ) : (
            <CardContent className={classes.chartCardContent}>
              <ChartsRenderer
                title="metric"
                data={reportData.current}
                type={renderer}
                height={previewHeight}
                formatter={formatter}
                showForecast={false}
                isWidget={false}
                forceRender={false}
                highchartRef={highchartRef}
              />
            </CardContent>
          )}
        </>
      )}
    </>
  );
};

export default MetricPreview;
