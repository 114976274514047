import { Box } from "@mui/material";

import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { CardsSkeleton } from "../Common/CardsSkeleton";

export const AsgsOverviewSkeleton = () => (
  <>
    <Box
      sx={{
        pb: 3,
      }}
    >
      <CardsSkeleton />
    </Box>
    <FilterTableSkeleton />
  </>
);
