import { useEffect, useMemo, useRef } from "react";

import { Autocomplete, type AutocompleteRenderInputParams, TextField } from "@mui/material";

import { marketplaceText } from "../../../../assets/texts";
import { type AccountConfig } from "../hooks/useMarketplaceAccounts";
import { type CustomerWithRef } from "../hooks/useMarketplaceCustomers";
import { useMarketplaceContext } from "../MarketplaceContext";

type Props = {
  customer: CustomerWithRef | null;
  setProcurementAccount: (account: AccountConfig | null) => void;
  procurementAccount: AccountConfig | null;
  disabled?: boolean;
};

export const ProcurementBillingAccountAutocomplete = ({
  customer,
  setProcurementAccount,
  procurementAccount,
  disabled,
}: Props) => {
  const { accounts } = useMarketplaceContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const customerAccounts = useMemo(() => {
    if (!customer || !accounts) {
      return [];
    }
    return accounts.filter(
      (account) => account.customer?.ref === customer.ref && account.data.billingAccountId && account.costAnomalyEnabled
    );
  }, [customer, accounts]);

  const handleSelectOption = (option: AccountConfig | null) => {
    setProcurementAccount(option);
  };

  useEffect(() => {
    if (customerAccounts.length > 0) {
      setProcurementAccount(null);
      inputRef?.current?.focus();
    }
  }, [customerAccounts, setProcurementAccount]);

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      options={customerAccounts}
      filterSelectedOptions={true}
      getOptionLabel={(option) => option.data.billingAccountId ?? ""}
      isOptionEqualToValue={(option, value) => option.data.billingAccountId === value.data.billingAccountId}
      size="medium"
      onChange={(_, newValue, reason) => {
        switch (reason) {
          case "clear":
            handleSelectOption(null);
            break;
          case "selectOption":
            handleSelectOption(newValue);
            break;
        }
      }}
      openOnFocus
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={marketplaceText.BILLING_ACCOUNT}
          variant="outlined"
          margin="dense"
          size="medium"
          inputRef={inputRef}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
      )}
      value={procurementAccount}
    />
  );
};
