import { type ChangeEvent, type FocusEvent, useCallback, useEffect, useMemo, useState } from "react";

import { TierPackageTypes, TierTypes } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";

import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { preventOnCloseOnBackdropClick } from "../../../utils/dialog";
import { firestoreTimestamp } from "../../../utils/firebase";
import { defaultTierFormData } from "../consts";
import { createTier, updateTier } from "../db";
import { type TierFormData, type TierModelWithId } from "../types";
import { validateSKU, validateTierForm } from "../utils";
import PriceInput from "./PriceInput";

type Props = {
  handleClose: () => void;
  selectedTier?: TierModelWithId | null;
  open: boolean;
};

export const CreateTierDialog = ({ handleClose, open, selectedTier }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<TierFormData>(defaultTierFormData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [invalidSkuFormat, setInvalidSkuFormat] = useState(false);
  const errorSnackbar = useErrorSnackbar();

  const initialFormData = useMemo(() => {
    if (selectedTier) {
      const { id, ...rest } = selectedTier;
      return { ...rest, price: { ...defaultTierFormData.price, ...selectedTier.price } };
    }
    return defaultTierFormData;
  }, [selectedTier]);

  useEffect(() => {
    setFormData((prevState) => ({ ...prevState, ...initialFormData }));
  }, [initialFormData]);

  useEffect(() => {
    setIsFormValid(validateTierForm(formData));
  }, [formData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "sku") {
      setInvalidSkuFormat(false);
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = useCallback(async () => {
    setLoading(true);
    try {
      if (selectedTier?.id) {
        await updateTier(formData, selectedTier?.id);
      } else {
        await createTier({ ...formData, timeCreated: firestoreTimestamp(), timestamp: firestoreTimestamp() });
      }
    } catch (e) {
      errorSnackbar("Failed to save tier details");
    }
    setLoading(false);
    handleClose();
  }, [formData, selectedTier, handleClose, errorSnackbar]);

  const handleCancel = () => {
    setFormData(defaultTierFormData);
    handleClose();
  };

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>, currency: string) => {
    const { value } = event.target;
    setFormData({ ...formData, price: { ...formData.price, [currency]: Number(value) } });
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "sku") {
      setInvalidSkuFormat(!validateSKU(value.trim()));
    }
  };

  return (
    <Dialog open={open} onClose={preventOnCloseOnBackdropClick(handleClose)} fullWidth>
      <DialogTitle>{selectedTier ? "Edit tier" : "Create new tier"} </DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          disabled={!!selectedTier}
        />
        <TextField
          name="displayName"
          label="Display name"
          value={formData.displayName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="description"
          label="Description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="packageType"
          label="Package type"
          value={formData.packageType}
          onChange={handleChange}
          fullWidth
          margin="normal"
          select
        >
          {Object.values(TierPackageTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="sku"
          label="SKU"
          value={formData.sku}
          onBlur={handleBlur}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          disabled={!!selectedTier}
          error={invalidSkuFormat}
        />
        <TextField
          name="type"
          label="Type"
          value={formData.type}
          onChange={handleChange}
          fullWidth
          margin="normal"
          select
        >
          {Object.values(TierTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        <Grid container spacing={2}>
          {Object.entries(formData.price).map(([currency, value]) => (
            <Grid
              key={currency}
              size={{
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
              }}
            >
              <PriceInput
                label={`Price (${currency})`}
                value={value}
                onChange={(e) => {
                  handlePriceChange(e, currency);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSave}
          loading={loading}
          disabled={!isFormValid}
          mixpanelEventId="tiers.tier-details.save"
        >
          {selectedTier ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
