import { useMemo } from "react";

import { type GSuiteAssetModel } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import { DateTime } from "luxon";

import { type LegacyDataFormat } from "../../../Components/Catalog/Catalog.context";
import { type Asset, type Contract } from "../../../types";
import { getTotal } from "../Tabs/components/gsuite/gSuiteAssetUtils";
import GSuiteConfirmationCostText from "./gsuiteConfirmation/GSuiteConfirmationCostText";
import GSuiteConfirmationParagraphText from "./gsuiteConfirmation/GSuiteConfirmationParagraphText";

type Props = {
  currentMaxSeats: number;
  maxSeats: number;
  asset: Asset<GSuiteAssetModel>;
  contract?: Contract;
  catalogItem?: LegacyDataFormat;
};

export const cyIds = {
  main: "summaryContainer",
  text: {
    quantity: "quantity",
    skuName: "skuName",
    domain: "customerDomain",
    initial: "initialCost",
    recurring: "recurringCost",
    single: "singlePaymentAmount",
    discountInitial: "initialDiscount",
    discountRecurring: "recurringDiscount",
    discountSingle: "singleChargeDiscount",
    endDate: "singlePaymentEndDate",
  },
  section: {
    summary: "summaryParagraph",
    initial: "initialCostSection",
    recurring: "recurringCostSection",
    single: "singleCostSection",
  },
};
export const { text, section, main } = cyIds;

/**
 * Summary text for license change confirmation
 *
 * @param currentMaxSeats The existing license cap
 * @param maxSeats The proposed new license count
 * @param asset The asset to which this pertains
 * @param contract The contract on which the asset is held
 * @param catalogItem The catalog item pertaining to the asset and contract
 */
const LicenseChangeConfirmSummary = ({ currentMaxSeats, maxSeats, asset, contract, catalogItem }: Props) => {
  const planName = useMemo<"ANNUAL" | "FLEXIBLE">(() => {
    const { settings, subscription } = asset.data.properties;
    return (settings?.plan?.planName ?? subscription.plan.isCommitmentPlan) ? "ANNUAL" : "FLEXIBLE";
  }, [asset.data.properties]);

  const payment = useMemo<"MONTHLY" | "YEARLY">(() => {
    const { settings } = asset.data.properties;
    return settings?.payment ?? (planName === "FLEXIBLE" ? "MONTHLY" : "YEARLY");
  }, [asset.data.properties, planName]);

  return useMemo(() => {
    const quantity = maxSeats - currentMaxSeats;
    const total = getTotal({ asset, quantity, planName, payment, catalogItem, contract });
    const { isCommitmentPlan } = asset.data.properties.subscription.plan;

    return (
      <Stack
        component="span"
        data-cy={main}
        sx={{
          gap: 3,
        }}
      >
        <GSuiteConfirmationParagraphText
          domain={asset.data.properties.customerDomain}
          quantity={quantity}
          skuName={asset.data.properties.subscription.skuName}
        />
        <GSuiteConfirmationCostText
          quantity={quantity}
          amount={total.amount}
          discount={total.discount}
          payment={payment}
          currency={total.currency}
          endTime={
            asset.data.properties.subscription.plan.isCommitmentPlan
              ? DateTime.fromMillis(asset.data.properties.subscription.plan.commitmentInterval.endTime, { zone: "utc" })
              : undefined
          }
          isCommitmentPlan={isCommitmentPlan}
        />
      </Stack>
    );
  }, [asset, catalogItem, contract, currentMaxSeats, maxSeats, payment, planName]);
};

export default LicenseChangeConfirmSummary;
