import Grid from "@mui/material/Grid2";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { renderPackageCloudConnectCards } from "../SaaSDashboard/WelcomeBlockWrapper";
import CloudConnectionStartCard from "./CloudConnectionStartCard";

const SolveOnlyWelcomeBlock = () => {
  const { customer } = useCustomerContext();
  const { onboarding } = customer;
  const showStartConnectionCard = !onboarding?.startTrialInitiated;

  return showStartConnectionCard ? (
    <Grid
      size={{
        xs: 12,
        sm: 12,
        md: 6,
      }}
    >
      <CloudConnectionStartCard />
    </Grid>
  ) : (
    <>{renderPackageCloudConnectCards(customer, "solve")}</>
  );
};

export default SolveOnlyWelcomeBlock;
