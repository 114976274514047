import { type ChangeEvent, Fragment, useState } from "react";

import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { InnerScreenHeader } from "../InnerScreenHeader";
import { AddArn } from "./AddARN";
import { FeaturesSelection } from "./FeaturesSelection/FeaturesSelection";

enum Mode {
  CloudFormation = "cf",
  ARN = "arn",
}

type LinkAccountProps = {
  backPageCallback: () => void;
};

/**
 * Screen to link new account to CMP
 */
export const LinkAccount = ({ backPageCallback }: LinkAccountProps) => {
  const { customer } = useCustomerContext();
  const [mode, setMode] = useState<string>(Mode.CloudFormation);

  const handleModeSelection = (event: ChangeEvent<HTMLInputElement>) => {
    setMode((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <Card>
        <InnerScreenHeader
          data-cy="link-account-back-button"
          title="Link account"
          subtitle={customer.name}
          backPageCallback={backPageCallback}
        />
        <CardContent>
          <Grid container>
            <Grid
              size={{
                md: 3,
              }}
            />
            <Grid
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Box
                sx={{
                  pb: 3,
                }}
              >
                <FormControl component="fieldset">
                  <Box
                    sx={{
                      pb: 1,
                    }}
                  >
                    <Typography data-cy="link-account-header" variant="subtitle1">
                      Select one of the following options to link your AWS account
                    </Typography>
                  </Box>
                  <RadioGroup value={mode} onChange={handleModeSelection}>
                    <FormControlLabel
                      value={Mode.CloudFormation}
                      control={<Radio data-cy="auto-role-radio" color="primary" />}
                      label="Create a role automatically."
                    />
                    <FormControlLabel
                      value={Mode.ARN}
                      control={<Radio data-cy="manual-role-radio" color="primary" />}
                      label="Create a role manually."
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Divider />

              <Box
                hidden={mode === Mode.ARN}
                sx={{
                  pt: 3,
                }}
              >
                <Typography data-cy="link-account-features-header" variant="subtitle1">
                  Select features you'd like to enable on your account
                </Typography>

                <FeaturesSelection onUpdate={backPageCallback} />
              </Box>

              <Box
                hidden={mode === Mode.CloudFormation}
                sx={{
                  pt: 3,
                }}
              >
                <AddArn />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
