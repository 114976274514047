import { useMemo } from "react";

import { Badge, Divider } from "@mui/material";
import { Box } from "@mui/system";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useAnnounceKit } from "../../../utils/announcekit";
import { type CategoryContext } from "../../types";
import { AVAButton } from "./AVAButton";
import HeaderMenuItem from "./HeaderMenuItem";
import HelpMenu from "./HelpMenu";
import { InAppNotifications } from "./InAppNotifications/InAppNotifications";
import { SettingsMenu } from "./Settings/SettingsMenu";

type Props = {
  hideWhatsNewOption: boolean;
  settings: CategoryContext[];
};

// 3 options on nav bar: What's new, settings and help menu
export const AdditionalOptions = ({ hideWhatsNewOption, settings }: Props) => {
  const { openAnnounceKit, unread } = useAnnounceKit();
  const { customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee } = useAuthContext();
  const isEntitledAvaIcon = useIsFeatureEntitled("platform:ava:icon");

  const canHaveAva = useMemo<boolean>(() => isDoitEmployee || !!isEntitledAvaIcon, [isDoitEmployee, isEntitledAvaIcon]);

  return (
    <>
      <Box
        sx={{
          px: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {!hideWhatsNewOption && (
          <HeaderMenuItem
            customHandler={openAnnounceKit}
            innerMenuItemComponent={
              <Badge
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: "#CAD2FF",
                  },
                }}
                invisible={!unread}
              >
                What's new
              </Badge>
            }
            items={[]}
            id="notification"
          />
        )}

        {canHaveAva && <AVAButton />}
        {customer && customerOrPresentationModeCustomer && <InAppNotifications />}
        <HelpMenu />
        {settings.length > 0 && <SettingsMenu settingsCategory={settings[0]} />}
      </Box>
      <Divider style={{ backgroundColor: "grey" }} orientation="vertical" flexItem />
    </>
  );
};
