import { Stack, Typography } from "@mui/material";

export const NoInvoicesFound = () => (
  <Stack
    spacing={2}
    sx={{
      alignItems: "center",
      my: "70px",
    }}
  >
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "32px",
      }}
    >
      No invoices found
    </Typography>
    <Typography
      sx={{
        fontSize: "16px",
        lineHeight: "24px",
      }}
    >
      To find invoices, try another time range or change the filter criteria
    </Typography>
  </Stack>
);
