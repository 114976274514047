import { useHistory } from "react-router-dom";
import { FlexsaveTypes } from "@doitintl/cmp-models";
import { Container, Stack, Typography } from "@mui/material";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { customersRoute } from "../../../../Navigation/core/utils";
import mixpanel from "../../../../utils/mixpanel";
import { CloudBox } from "./CloudBox";

export const SelectCloud = () => {
  const history = useHistory();
  const customerId = useCustomerId();

  return (
    <Container maxWidth="xl">
      <Stack
        sx={{
          width: "100%",
          alignContent: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "500",
            pt: 10,
            pb: 1,
          }}
        >
          Select a cloud provider for your first Flexsave estimate
        </Typography>
        <Typography
          sx={{
            pb: 6,
          }}
        >
          You will be able to complete the estimate for the other cloud provider later
        </Typography>

        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <CloudBox
            type={FlexsaveTypes.AWS}
            onClick={() => {
              mixpanel.track("flexsaveSA.cloudselected", { type: "aws" });
              history.push(customersRoute(customerId, "flexsave-aws"));
            }}
          />
          <CloudBox
            type={FlexsaveTypes.GCP}
            onClick={() => {
              mixpanel.track("flexsaveSA.cloudselected", { type: "gcp" });
              history.push(customersRoute(customerId, "flexsave-gcp"));
            }}
          />
        </Stack>
      </Stack>
    </Container>
  );
};
