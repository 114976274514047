import { useCallback, useEffect, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Paper, TextField, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import { Stack } from "@mui/system";

import { attributionGroupsText } from "../../../assets/texts";
import { DraggableContainer } from "../../../Components/DragAndDrop/DraggableContainer";
import { DraggableGroup } from "../../../Components/DragAndDrop/DraggableGroup";
import { AttributionSelect } from "../../../Components/Selects/CloudAnalytics/AttributionSelect";
import { type AttributionWRef } from "../../../types";
import { AttributionGroupDraggableItem, DraggableGroupContent } from "./AttributionGroupDraggable";

export const maxFallbackLength = 64;

type Props = {
  attributions: AttributionWRef[];
  attributionsInGroup: Record<string, string[]>;
  setAttributionsInGroup: (newGroups: Record<string, string[]>) => void;
  handleNullFallbackChange: (event: { type: string; target: { value: string } }) => void;
  nullFallback: string;
};

export const AttributionGroupDragAndDrop = ({
  attributions,
  attributionsInGroup,
  setAttributionsInGroup,
  handleNullFallbackChange,
  nullFallback,
}: Props) => {
  const [attributionSelectKey, setAttributionSelectKey] = useState(0);
  const [attributionsState, setAttributionsState] = useState(attributionsInGroup);

  useEffect(() => {
    if (attributionsState.main.length !== attributionsInGroup.main.length) {
      setAttributionsState(attributionsInGroup);
    }
  }, [attributionsInGroup, attributionsState.length, attributionsState.main.length]);

  const removeAttributionFromGroup = useCallback(
    (attributionId: string) => {
      const items = { ...attributionsInGroup };
      items.main = items.main.filter((id) => id !== attributionId);
      setAttributionsInGroup(items);
    },
    [attributionsInGroup, setAttributionsInGroup]
  );

  const handleAddAttribution = useCallback(
    (attribution: AttributionWRef | null) => {
      if (attribution !== null) {
        setAttributionSelectKey((key) => key + 1);
        const items = { ...attributionsInGroup };
        items.main = [...items.main, attribution.ref.id];
        setAttributionsInGroup(items);
      }
    },
    [attributionsInGroup, setAttributionsInGroup]
  );

  const replaceAttributionInGroup = useCallback(
    (newAttributionId: string, oldAttributionId: string) => {
      const items = { ...attributionsInGroup };
      items.main = items.main.map((id) => (id === oldAttributionId ? newAttributionId : id));
      setAttributionsInGroup(items);
      setAttributionsState(items);
    },
    [attributionsInGroup, setAttributionsInGroup]
  );

  const renderDraggingItem = useCallback(
    (id: string) => (
      <AttributionGroupDraggableItem
        initalAttributionID={id}
        grabbing
        attributions={attributions}
        removeAttributionFromGroup={removeAttributionFromGroup}
        replaceAttributionInGroup={replaceAttributionInGroup}
        disabledAttributions={attributionsInGroup.main}
      />
    ),
    [attributions, attributionsInGroup.main, replaceAttributionInGroup, removeAttributionFromGroup]
  );

  const renderContent = useCallback(
    (items: string[]) => (
      <DraggableGroupContent
        items={items}
        attributions={attributions}
        removeAttributionFromGroup={removeAttributionFromGroup}
        disabledAttributions={attributionsInGroup.main}
        replaceAttributionInGroup={replaceAttributionInGroup}
      />
    ),
    [attributions, attributionsInGroup.main, replaceAttributionInGroup, removeAttributionFromGroup]
  );

  return (
    <Grid
      container
      direction="column"
      sx={{ flexWrap: "nowrap" }}
      size={{
        xs: 12,
        sm: 10,
        md: 6,
      }}
    >
      <Grid>
        <DraggableContainer
          values={attributionsState}
          renderDraggingItem={renderDraggingItem}
          onItemsOrderChanged={setAttributionsInGroup}
        >
          <Stack direction="column">
            <DraggableGroup id="main" renderContent={renderContent} />
          </Stack>
        </DraggableContainer>
      </Grid>
      <Grid container>
        <Grid sx={{ ml: "24px" }} size={11}>
          <AttributionSelect
            key={`AttributionSelect-${attributionSelectKey}`}
            sx={{ pb: 2 }}
            onChange={handleAddAttribution}
            attributions={attributions.filter((a) => a.data.type !== "managed")}
            disabledAttributions={attributionsInGroup.main}
          />
        </Grid>
      </Grid>
      <Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              width: "24px",
            }}
          >
            <Typography>{attributionsInGroup.main.length + 1}</Typography>
          </Grid>
          <Grid size={11}>
            <Paper
              variant="outlined"
              sx={{
                alignItems: "center",
                display: "flex",
                height: "54px",
              }}
            >
              <TextField
                name="title"
                variant="outlined"
                value={nullFallback}
                placeholder={attributionGroupsText.UNALLOCATED}
                onChange={handleNullFallbackChange}
                onBlur={handleNullFallbackChange}
                sx={{ px: 1 }}
                slotProps={{
                  htmlInput: { maxLength: maxFallbackLength },
                }}
              />
              <Tooltip title={attributionGroupsText.UNALLOCATED_TOOLTIP} arrow>
                <InfoOutlinedIcon htmlColor={grey[600]} sx={{ fontSize: "16px", top: "3px", left: "3px" }} />
              </Tooltip>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
