import { type HTMLAttributes, useCallback, useMemo } from "react";

import { type DashboardModelAttributionModel } from "@doitintl/cmp-models";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  type AutocompleteRenderGetTagProps,
  type AutocompleteRenderInputParams,
  Avatar,
  Box,
  Checkbox,
  Chip,
  Stack,
  type SxProps,
  TextField,
  type TextFieldProps,
  type Theme,
  Typography,
} from "@mui/material";

import icons from "../../assets/icons";
import { invoicesTxt } from "../../assets/texts/Billing/invoices";
import { createFilterOptions } from "../../Pages/CloudAnalytics/utilities";

export type InvoiceBucketOption = {
  id: string;
  name: string;
  group: string;
  level: number;
  disabled?: boolean;
  data?: DashboardModelAttributionModel;
  icon?: string;
};

type Props = {
  options: InvoiceBucketOption[];
  onClose?: () => void;
  onChange: (scope: InvoiceBucketOption[]) => void;
  scope: InvoiceBucketOption[];
  hasAllOption?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
};

const allOption = {
  id: invoicesTxt.ALL,
  name: invoicesTxt.ALL,
  group: invoicesTxt.SUPPORTED_ASSETS,
  level: 0,
};

const maxNumTags = 3;

const getOptionLabel = (option: InvoiceBucketOption) => option.name;

const handleRenderTags = (values: InvoiceBucketOption[], getTagProps: AutocompleteRenderGetTagProps) => {
  const numTags = values.length;
  const visibleValues = values.slice(0, maxNumTags);
  return (
    <>
      {visibleValues.map((option, index) => (
        <Chip
          size="small"
          label={option.name}
          {...getTagProps({ index })}
          key={option.id}
          deleteIcon={<CancelIcon />}
        />
      ))}
      <Box
        sx={{
          m: 0.25,
        }}
      >
        {numTags > maxNumTags && `+${numTags - maxNumTags}`}
      </Box>
    </>
  );
};

const filterOptions = createFilterOptions<InvoiceBucketOption>({
  trim: true,
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
});

export const InvoiceBucketSelect = ({
  options,
  disabled,
  onClose,
  onChange,
  scope,
  hasAllOption,
  sx,
  textFieldProps = { size: "medium" },
}: Props) => {
  const allOptions = useMemo(() => (hasAllOption ? [allOption, ...options] : options), [hasAllOption, options]);
  const getOptionDisabled = useCallback((option: InvoiceBucketOption) => !!option.disabled, []);
  const enabledOptions = useMemo(
    () => options.filter((option) => !getOptionDisabled(option)),
    [options, getOptionDisabled]
  );
  const allSelected = useMemo(() => enabledOptions.length === scope.length, [enabledOptions.length, scope.length]);

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      variant="outlined"
      label={invoicesTxt.INVOICE_BUCKETS}
      placeholder=""
      fullWidth
      {...textFieldProps}
      slotProps={{
        inputLabel: {
          shrink: true,
        },
      }}
    />
  );

  const handleRenderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: InvoiceBucketOption, { selected }) => (
      <li {...props} key={option.id}>
        <Stack direction="row" sx={{ alignItems: "center", ml: option.level * 3, width: "100%" }}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={option.id === invoicesTxt.ALL ? allSelected : selected}
            data-cy={option.id === invoicesTxt.ALL ? "all-option" : ""}
          />
          <Typography variant="body1">{option.name}</Typography>
          {!!option.icon && <Avatar src={icons[option.icon]} sx={{ width: 20, height: 20, ml: "auto" }} />}
        </Stack>
      </li>
    ),
    [allSelected]
  );

  const handleChange = useCallback(
    (_, values: InvoiceBucketOption[]) => {
      const allOptionChanged = values.findIndex((value) => value.id === invoicesTxt.ALL) !== -1;
      let newValues = values;
      if (allOptionChanged) {
        newValues = allSelected ? [] : enabledOptions;
      }
      onChange(newValues);
    },
    [allSelected, onChange, enabledOptions]
  );

  return (
    <Autocomplete
      disableCloseOnSelect
      disabled={disabled}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      groupBy={(option) => option.group}
      filterOptions={filterOptions}
      multiple
      onChange={handleChange}
      onClose={onClose}
      options={allOptions}
      sx={sx}
      value={scope}
      data-cy="invoice-buckets-select"
      renderTags={handleRenderTags}
      renderOption={handleRenderOption}
      renderInput={handleRenderInput}
    />
  );
};
