import { useEffect, useMemo } from "react";

import { useSearchBox } from "react-instantsearch";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useFilter } from "../FilterContext";

export const CustomSearchBox = () => {
  const { refine, clear, query } = useSearchBox();
  const { isFiltersExpanded } = useFilter();
  const theme = useTheme();

  useEffect(() => {
    if (isFiltersExpanded) {
      clear();
    }
  }, [isFiltersExpanded, clear]);

  const searchIconColor = isFiltersExpanded
    ? "gray"
    : theme.palette.mode === "dark"
      ? theme.palette.text.secondary
      : "black";

  const searchBox = useMemo(
    () => (
      <TextField
        fullWidth
        variant="outlined"
        placeholder={isFiltersExpanded ? "Filters active" : "Search people or skills (e.g spanish data engineer)"}
        value={query}
        onChange={(event) => {
          refine(event.currentTarget.value);
        }}
        disabled={isFiltersExpanded}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: isFiltersExpanded ? "gray" : "inherit",
            },
          },
        }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" sx={{ color: searchIconColor }} />
              </InputAdornment>
            ),
            endAdornment: query ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={() => {
                    clear();
                  }}
                  edge="end"
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : null,
          },
        }}
      />
    ),
    [clear, isFiltersExpanded, query, refine, searchIconColor]
  );

  return isFiltersExpanded ? (
    <Tooltip title="Close the filters section to use the search box" arrow placement="bottom-start">
      <div style={{ width: "100%" }}>{searchBox}</div>
    </Tooltip>
  ) : (
    searchBox
  );
};
