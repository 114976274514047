import OpenInNew from "@mui/icons-material/OpenInNew";
import { Alert, Button, Container, Stack, Typography } from "@mui/material";

import { domainConfig } from "../../../domainConfig";
import FeatureList from "../FeatureList";

export const SolveOverviewStep = () => (
  <Container maxWidth="md">
    <Stack
      spacing={3}
      sx={{
        pb: 5,
        width: "fit-content",
        alignItems: "start",
      }}
    >
      <Typography variant="h1">DoiT Cloud Solve overview</Typography>
      <Stack spacing={1}>
        <Typography variant="h3">Connect your cloud to start using DoiT Cloud Solve</Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
            display: "inline",
          }}
        >
          Access DoiT’s global team of experts with decades of experience in cloud architecture, Kubernetes, machine
          learning, and more.
        </Typography>
      </Stack>
      <Stack
        spacing={2}
        sx={{
          width: "100%",
          alignItems: "start",
          border: 1,
          p: 2,
          borderRadius: 1,
          borderColor: "divider",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
          }}
        >
          Also, try out DoiT Cloud Navigator free for 45 days
        </Typography>
        <FeatureList />
        <Button
          variant="text"
          href={domainConfig.urls.navigatorSubscriptionURL}
          startIcon={<OpenInNew />}
          target="_blank"
        >
          View full feature list
        </Button>
        <Alert severity="info" sx={{ width: "100%" }}>
          Your trial will begin once your data has been imported into the DoiT Cloud Navigator
        </Alert>
      </Stack>
    </Stack>
  </Container>
);

export default SolveOverviewStep;
