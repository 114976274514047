import { type JSX } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableRow,
  Tooltip,
  Typography,
  type TypographyProps,
} from "@mui/material";

import { useSessionStorage } from "../../../../../../../Components/hooks/storageHooks";
import { formatCurrency } from "../../../../../../../utils/common";
import { ExplainerTableCell } from "../../../Components/ExplainerTableCell";

type Props = {
  title: string;
  value?: number;
  variant?: TypographyProps["variant"];
  fontWeight?: TypographyProps["fontWeight"];
  children?: JSX.Element[] | JSX.Element | boolean;
  tooltip?: string;
  isChild?: boolean;
  isLastRow?: boolean;
  currency: CurrencyCode;
  type: "doit" | "aws";
};

export function ExplainerRow({
  title,
  value,
  children,
  isChild = false,
  isLastRow = false,
  variant = "body1",
  fontWeight = 400,
  tooltip,
  currency,
  type,
}: Readonly<Props>) {
  const [expanded, setExpanded] = useSessionStorage(
    `explainer_${type}_${title.toLowerCase().replace(" ", "_")}`,
    false
  );

  if (value === undefined) {
    return null;
  }

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Table size="small">
        <TableBody>
          <TableRow hover sx={isLastRow ? { "& > *": { borderStyle: "none" } } : {}}>
            <ExplainerTableCell align="left" sx={{ pl: isChild ? 3 : 0 }}>
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                }}
              >
                {children ? (
                  <IconButton
                    size="small"
                    onClick={handleToggleExpand}
                    sx={{ width: "24px", height: "24px", marginX: "12px" }}
                  >
                    {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                ) : (
                  <Box sx={{ paddingX: "24px" }} />
                )}
                <Tooltip title={tooltip} arrow placement="top">
                  <Typography
                    variant={variant}
                    sx={[
                      {
                        fontWeight,
                      },
                      tooltip ? { textDecoration: "underline  dotted", textUnderlineOffset: "5px" } : {},
                    ]}
                  >
                    {title}
                  </Typography>
                </Tooltip>
              </Stack>
            </ExplainerTableCell>
            <ExplainerTableCell align="right">
              <Typography
                variant={variant}
                sx={{
                  fontWeight,
                }}
              >
                {formatCurrency(value, currency)}
              </Typography>
            </ExplainerTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {children && <Collapse in={expanded}>{children}</Collapse>}
    </>
  );
}
