import { type ChangeEvent, useState } from "react";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Alert, Button, Link, Stack, TextField, Typography } from "@mui/material";

import { onboardingTxt } from "../../../assets/texts/EKS/onboarding";
import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import { useEKSApi, useEKSClusters } from "../hooks";
import { type ClusterConfig } from "../types";
import { isThereTerraformDeploymentInRegion } from "../utils";

const configureTerraformCluster =
  "https://registry.terraform.io/providers/hashicorp/kubernetes/latest/docs/guides/getting-started";
const configureAWSProvider = "https://registry.terraform.io/providers/hashicorp/aws/latest/docs";

export const ConfigureStep = ({ clusterConfig }: { clusterConfig: ClusterConfig }) => {
  const eksApi = useEKSApi();
  const [rawClusters] = useEKSClusters();
  const [openIdUrl, setOpenIdUrl] = useState(clusterConfig?.clusterOIDCIssuerURL ?? "");
  const [error, setError] = useState(false);

  const validateOpenIdUrl = (): boolean => {
    if (!openIdUrl) {
      setError(false);
    }
    const pattern = /^https:\/\/oidc\.eks\.[a-z0-9-]+\.amazonaws\.com\/id\/.*/;
    const isValid = pattern.test(openIdUrl);
    setError(!isValid);
    return isValid;
  };

  const handleOpenIdUrlChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setOpenIdUrl(value);
    setError(false);
  };

  const handleClusterFileDownload = async () => {
    if (!validateOpenIdUrl()) {
      return;
    }
    await eksApi.downloadClusterFile(clusterConfig, openIdUrl);
  };

  const handleRegionFileDownload = async () => {
    await eksApi.downloadRegionFile(clusterConfig);
  };

  const isNewRegion = !isThereTerraformDeploymentInRegion(rawClusters, clusterConfig.accountId, clusterConfig.region);

  let step = 1;

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h1">{onboardingTxt.CONFIGURE_AND_APPLY}</Typography>
        <Typography
          sx={{
            color: "text.secondary",
          }}
        >
          Account: {clusterConfig.accountId}, Region: {clusterConfig.region}, Cluster: {clusterConfig.clusterName},{" "}
          Deployment ID: {clusterConfig.desiredDeploymentId}
        </Typography>
      </Stack>
      {isNewRegion && (
        <Stack spacing={2}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "flex-start",
            }}
          >
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {step++}. {onboardingTxt.DEPLOYMENT_TERRAFORM_REGION(clusterConfig.accountId, clusterConfig.region)}
            </Typography>
            <Button
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              onClick={handleRegionFileDownload}
              sx={{ width: "fit-content", py: 1, whiteSpace: "nowrap" }}
              disabled={error}
            >
              {onboardingTxt.DEPLOYMENT_TERRAFORM_REGION_BUTTON}
            </Button>
          </Stack>
        </Stack>
      )}
      <Stack spacing={2}>
        <Typography variant="body2">
          {step++}. {onboardingTxt.CONFIGURE_TERRAFORM_2(clusterConfig.clusterName)}
        </Typography>
        <Typography variant="body2">{onboardingTxt.CONFIGURE_TERRAFORM_2_OIDC}</Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "flex-start",
          }}
        >
          <TextField
            label={onboardingTxt.CONFIGURE_TERRAFORM_2_LABEL}
            sx={{ flexGrow: 1 }}
            value={openIdUrl}
            onChange={handleOpenIdUrlChange}
            onBlur={validateOpenIdUrl}
            error={error}
            helperText={error ? onboardingTxt.INVALID_OPENID_URL : ""}
            required
          />
          <Button
            startIcon={<FileDownloadIcon />}
            variant="outlined"
            onClick={handleClusterFileDownload}
            sx={{ width: "fit-content", py: 1 }}
            disabled={error}
          >
            {onboardingTxt.CONFIGURE_TERRAFORM_2_BUTTON}
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Stack direction="row">
          <Typography variant="body2">
            {step++}. {onboardingTxt.CONFIGURE_TERRAFORM_4}
          </Typography>
          <Link target="_blank" href={configureTerraformCluster}>
            <Typography sx={{ marginLeft: 0.5 }} variant="body2">
              {onboardingTxt.LEARN_MORE}
              <OpenNewIcon sx={{ fontSize: 16, ml: 0.5, mb: 0.5, verticalAlign: "middle" }} />
            </Typography>
          </Link>
        </Stack>
        <CopyCodeBlock
          base={onboardingTxt.CONFIGURE_TERRAFORM_4_CODE(
            clusterConfig.accountId,
            clusterConfig.region,
            clusterConfig.clusterName
          )}
        />
      </Stack>
      <Stack spacing={1}>
        <Stack direction="row">
          <Typography variant="body2">
            {step++}. {onboardingTxt.CONFIGURE_TERRAFORM_5}
          </Typography>
          <Link target="_blank" href={configureAWSProvider}>
            <Typography sx={{ marginLeft: 0.5 }} variant="body2">
              {onboardingTxt.LEARN_MORE}
              <OpenNewIcon sx={{ fontSize: 16, ml: 0.5, mb: 0.5, verticalAlign: "middle" }} />
            </Typography>
          </Link>
        </Stack>
        <CopyCodeBlock base={onboardingTxt.CONFIGURE_TERRAFORM_5_CODE} />
      </Stack>
      <Stack
        spacing={1}
        sx={{
          pb:
            (clusterConfig.status === "active" && !clusterConfig.verificationError) || !!clusterConfig.verificationError
              ? 0
              : 10,
        }}
      >
        <Typography variant="body2">
          {step++}. {onboardingTxt.CONFIGURE_TERRAFORM_6}
        </Typography>
        <CopyCodeBlock base={onboardingTxt.CONFIGURE_TERRAFORM_6_CODE_1} />
        <CopyCodeBlock base={onboardingTxt.CONFIGURE_TERRAFORM_6_CODE_2} />
        <CopyCodeBlock base={onboardingTxt.CONFIGURE_TERRAFORM_6_CODE_3} />
        <Typography variant="body2">{onboardingTxt.CONFIGURE_TERRAFORM_6_COMPLETE}</Typography>
      </Stack>
      {clusterConfig.status === "active" && !clusterConfig.verificationError && (
        <Stack
          sx={{
            pb: 10,
          }}
        >
          <Alert severity="success" sx={{ width: "fit-content" }}>
            <Typography variant="body2">{onboardingTxt.CONNECTION_SUCCESSFUL}</Typography>
          </Alert>
        </Stack>
      )}
      {!!clusterConfig.verificationError && (
        <Stack
          sx={{
            pb: 10,
          }}
        >
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <Typography variant="body2">{clusterConfig.verificationError}</Typography>
          </Alert>
        </Stack>
      )}
    </Stack>
  );
};
