import { AssetTypeAmazonWebServices, AssetTypeAwsStandalone } from "@doitintl/cmp-models";
import { Container, FormControlLabel, Switch, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { contractsText } from "../../../../../assets/texts";
import { formatCurrency } from "../../../../../utils/common";
import { useContractFormContext } from "../../ContractsFormContext";
import { type CommitmentPeriodType } from "../../types";
import { hasCommitmentPeriodDiscount } from "../../utils";
import { CommitmentPeriodsList } from "./components/CommitmentPeriodsList";

export const CommitmentStep = () => {
  const { state, handleChange, setState } = useContractFormContext();
  const { commitmentPeriods, commitmentRollover, isEditForbidden, type, startDate, endDate } = state;

  const handleCommitPeriodsChange = (commitmentPeriods: CommitmentPeriodType[]) => {
    setState((prevState) => ({
      ...prevState,
      commitmentPeriods,
      commitmentRollover: commitmentPeriods.length < 2 ? false : prevState.commitmentRollover,
    }));
  };

  return (
    <Container maxWidth="sm" data-cy={"commitment-step"}>
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h4" data-cy="total-commitment">
            Total Commitment:{" "}
            {formatCurrency(
              commitmentPeriods.reduce((acc, cp) => acc + (cp.value ?? 0), 0),
              "USD"
            )}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            pt: 2,
          }}
        >
          {type !== AssetTypeAmazonWebServices && type !== AssetTypeAwsStandalone && (
            <Grid size={12}>
              <FormControlLabel
                control={
                  <Switch
                    data-cy="commitment-rollover"
                    disabled={commitmentPeriods.length < 2 || isEditForbidden}
                    checked={commitmentRollover}
                    value={commitmentRollover}
                    onChange={handleChange("commitmentRollover")}
                    color="primary"
                  />
                }
                label={contractsText.COMMITMENT_ROLLOVER_LABEL}
              />
            </Grid>
          )}

          <CommitmentPeriodsList
            commitmentPeriods={commitmentPeriods}
            contractEndDate={endDate}
            contractStartDate={startDate}
            disabled={isEditForbidden}
            onChange={handleCommitPeriodsChange}
            showDiscount={hasCommitmentPeriodDiscount(type)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
