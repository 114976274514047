import { useCallback, useState } from "react";

import { useParams } from "react-router";
import { Button, DialogContent, Typography } from "@mui/material";

import { globalText } from "../../../../../../assets/texts";
import SimpleDialog from "../../../../../../Components/SimpleDialog";
import { useErrorSnackbar } from "../../../../../Integrations/Slack/utils";
import contractHooks, { useBackToList } from "../../../../hooks";

export const DeleteContractButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const deleteContract = contractHooks.useDeleteContract();
  const errorSnackbar = useErrorSnackbar();
  const { contractId } = useParams<{ customerId: string; contractId: string }>();
  const backToList = useBackToList();

  const handleDeleteContract = useCallback(async () => {
    await deleteContract(contractId, backToList, () => {
      errorSnackbar("Failed to delete contract");
    });
  }, [backToList, contractId, deleteContract, errorSnackbar]);

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={() => {
          setDialogOpen(true);
        }}
        data-cy="delete-contract-button"
      >
        Delete contract
      </Button>
      <SimpleDialog
        open={dialogOpen}
        title="Delete contract"
        onConfirm={handleDeleteContract}
        onCancel={() => {
          setDialogOpen(false);
        }}
        confirmButtonText="Delete contract"
        confirmButtonColor="error"
        cancelButtonText={globalText.CLOSE}
      >
        <DialogContent>
          <Typography
            sx={{
              mt: 3,
            }}
          >
            Are you sure you want to delete this contract?
          </Typography>
        </DialogContent>
      </SimpleDialog>
    </>
  );
};
