import { useEffect, useMemo, useRef } from "react";

import { Card, CardContent, Chip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { alpha, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import sortBy from "lodash/sortBy";
import { DateTime } from "luxon";

import { useTicketsStatistics } from "../../Context/TicketStatistics";
import { getTreeMapColors } from "../../Pages/CloudAnalytics/utilities";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { formatNumber } from "../../utils/common";
import { SkeletonCard } from "./SkeletonCard";
import { type WidgetItemProps } from "./types";

const checkYears = (data: [number, any][]) => {
  const nowYear = new Date().getFullYear();
  const test = [nowYear, nowYear - 1, nowYear - 2, nowYear - 3];
  data.forEach((element) => {
    const indexArr = test.indexOf(element[0]);
    if (indexArr > -1) {
      test.splice(indexArr, 1);
    }
  });
  return test;
};

type SupportCardProps = WidgetItemProps & {
  secondaryColor?: string;
  title?: string;
};

export default function SupportCard({
  isCustomizeMode,
  raised,
  secondaryColor,
  title,
  fallbackComponent,
  widgetHeight = 200,
}: SupportCardProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const theme = useTheme();

  const { data, loading } = useTicketsStatistics();

  const ticketData = useMemo(() => {
    if (loading) {
      return;
    }
    if (!data) {
      return { data: [], satisfaction: 0 };
    }

    let ticketsData: { x: string; y: number }[] = [];
    const entries = Object.entries(data.history);
    const diffYear = checkYears(entries.map(([year]) => [parseInt(year), {}]));
    if (diffYear.length > 0) {
      diffYear.forEach((element) => {
        entries.push([element.toString(), {}]);
      });
    }

    const now = DateTime.utc();
    const startYear = now.minus({ months: 36 }).year;
    let countTickets = 0;

    entries.forEach(([year, months]) => {
      const yearInt = parseInt(year);
      if (yearInt < startYear) {
        return;
      }
      let startMonth = 1;
      if (yearInt === startYear) {
        startMonth = now.minus({ months: 36 }).month;
      }
      for (let month = startMonth; month <= 12; month++) {
        if (!months[month]) {
          months[month] = 0;
        }
        if (yearInt < now.year || month <= now.month) {
          const addZero = month < 10 ? "0" : "";
          ticketsData.push({
            x: `${year}-${addZero}${month}`,
            y: months[month],
          });
          countTickets += months[month];
        }
      }
    });

    ticketsData = sortBy(ticketsData, ["x"]);
    if (countTickets === 0) {
      ticketsData = [];
    }

    return {
      data: ticketsData,
      satisfaction: data.satisfaction,
    };
  }, [data, loading]);

  useEffect(() => {
    if (isCustomizeMode && chartRef.current?.chart) {
      chartRef.current.chart.reflow();
    }
  }, [isCustomizeMode]);

  const chartOptions = useMemo<Highcharts.Options>(
    () => ({
      xAxis: {
        categories: ticketData?.data?.map((item) => DateTime.fromFormat(item.x, "yyyy-LL").toFormat("LLL yy")),
        tickInterval: 4,
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      credits: { enabled: false },
      chart: { type: "column", backgroundColor: "transparent", animation: false, height: 200 },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          groupPadding: 0.05,
        },
        series: {
          pointWidth: 8,
          animation: false,
        },
      },
      tooltip: {
        formatter() {
          return (
            `<b>Period: ${DateTime.fromFormat(this.x as string, "LLL yy").toFormat("LLLL yyyy")}</b><br/>` +
            `Created Tickets: ${this.y}`
          );
        },
      },
      series: [
        {
          type: "column",
          showInLegend: false,
          data: ticketData?.data?.map((item) => item.y),
          color: secondaryColor ?? alpha(getTreeMapColors(theme.palette.mode)[1], 0.8),
        },
      ],
      exporting: {
        enabled: false,
      },
    }),
    [ticketData?.data, theme.palette.text.primary, theme.palette.mode, secondaryColor]
  );

  if (ticketData?.data && ticketData?.data.length === 0) {
    return fallbackComponent;
  }

  if (loading) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Grid>
      <Card raised={raised}>
        <WidgetCardHeader
          title={
            <>
              {title ?? "Support Requests"}
              {ticketData?.data && (
                <Tooltip title="Satisfaction Rating">
                  <Chip size="small" label={`${formatNumber(ticketData?.satisfaction)}%`} sx={{ ml: 1 }} />
                </Tooltip>
              )}
            </>
          }
          subheader="Your monthly total requests over the last 36 months"
        />
        <CardContent
          sx={{
            ":last-child": {
              p: 0,
            },
            height: widgetHeight,
          }}
        >
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: "100%" } }}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
