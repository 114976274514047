import { type ReactNode } from "react";

import { Link } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { ADDRESS_PANEL_ID } from "../panels/AddressPanel";
import { scrollToFormElement } from "../utils";

export const AnchorLink = ({ id, children }: { id: string; children?: ReactNode }) => (
  <Grid
    sx={{
      display: "flex",
    }}
    size={{
      xs: 1,
      sm: "auto",
    }}
  >
    <Link
      href={`#${id}`}
      variant="body2"
      onClick={(e) => {
        e.preventDefault();

        if (id === ADDRESS_PANEL_ID) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          scrollToFormElement(id);
        }
      }}
      sx={{
        textDecoration: "none",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Link>
  </Grid>
);
