import { useEffect, useMemo, useState } from "react";

import { SaaSConsoleType, type StandaloneOnboardingErrorType } from "@doitintl/cmp-models";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Alert, Checkbox, Link, Stack, TextField, Typography } from "@mui/material";

import { replaceWithJSX } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { ErrorStepLocation, getErrorAlert } from "../../../Flexsave/Standalone/Common/helpers/errorsHelper";
import { trackConnectView } from "../Common/mixpanelEvents";
import { UsefulLinks } from "../Common/UsefulLinks";
import { BillingCommands, getGCPCommandsSet } from "./Commands";
import { DetailedUsageCostSetupDialog } from "./DetailedUsageCostSetupDialog";
import RequiredRolesAlert from "./RequiredRolesAlert";
import { RunGCPCommandsExplanation } from "./RunGCPCommandsExplanation";

type Props = {
  billingAccountId: string;
  projectId: string;
  datasetId: string;
  enabledDetailedCost: boolean;
  setBillingAccountId: (billingAccountId: string) => void;
  setProjectId: (projectId: string) => void;
  setDatasetId: (datasetId: string) => void;
  setEnabledDetailedCost: (enabledDetailedCost: boolean) => void;
  billingAccountIdError: (string) => string;
  error?: StandaloneOnboardingErrorType;
  serviceAccount?: string;
};

export const BillingPermissions = ({
  billingAccountId,
  projectId,
  datasetId,
  enabledDetailedCost,
  setBillingAccountId,
  setProjectId,
  setDatasetId,
  setEnabledDetailedCost,
  billingAccountIdError,
  error,
  serviceAccount,
}: Props) => {
  const [copyClicked, setCopyClicked] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const [billingAccountIdInput, setBillingAccountIdInput] = useState<string>(billingAccountId);

  useMountEffect(() => {
    trackConnectView(SaaSConsoleType.GCP);
  });

  const codeVariables = useMemo(
    () => ({
      $PROJECT_ID: { value: projectId, name: "Project Id" },
      $DATASET_ID: { value: datasetId, name: "Dataset Id" },
      $BILLING_ACCOUNT_ID: { value: billingAccountId, name: "Billing account Id" },
    }),
    [datasetId, billingAccountId, projectId]
  );

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const { codeToCopy, emptyVariables } = useMemo(() => {
    const commands = getGCPCommandsSet(serviceAccount);
    const base = commands.reduce((acc, curr) => `${acc} && ${curr}`);
    return replaceWithJSX(base, codeVariables);
  }, [codeVariables, serviceAccount]);

  const handleCopyAllCommandsClick = () => {
    setShowError(Boolean(emptyVariables.length));
  };

  const handleCopyClicked = (copyClicked: boolean) => {
    setCopyClicked(copyClicked);
    setShowError(false);
  };

  const billingAccountIdErrorText = useMemo(
    () => billingAccountIdError(billingAccountIdInput),
    [billingAccountIdError, billingAccountIdInput]
  );

  useEffect(() => {
    if (billingAccountIdErrorText === "") {
      setBillingAccountId(billingAccountIdInput);
    }
  }, [billingAccountIdErrorText, setBillingAccountId, billingAccountIdInput]);

  return (
    <Stack spacing={4}>
      <Typography
        variant="h1"
        sx={{
          fontWeight: "500",
        }}
      >
        Connect your Google Cloud to DoiT Console
      </Typography>
      <RequiredRolesAlert />
      {getErrorAlert(error, ErrorStepLocation.GCP_ACTIVATION)}
      <Stack spacing={1}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "500",
          }}
        >
          1. Enable detailed usage cost
        </Typography>
        <Link
          onClick={handleOpenDialog}
          underline="none"
          type="button"
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          How to enable detailed usage cost <HelpOutlineIcon fontSize="small" sx={{ ml: 0.5 }} />
        </Link>
        <Typography>
          <Checkbox
            data-cy="commitment-sharing"
            checked={enabledDetailedCost}
            onChange={() => {
              setEnabledDetailedCost(!enabledDetailedCost);
            }}
          />
          I have enabled detailed usage cost
        </Typography>
        <Alert severity="info">
          Please wait up to 1 hour to activate if you have just enabled detailed usage cost.
        </Alert>
      </Stack>
      <Stack spacing={2.5}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "500",
            mb: -1.5,
          }}
        >
          2. Provide billing export of detailed usage cost
        </Typography>
        <TextField
          data-cy="billing-account-id"
          variant="outlined"
          label="Billing Account you would like to onboard"
          value={billingAccountIdInput}
          error={billingAccountIdErrorText !== ""}
          helperText={billingAccountIdErrorText}
          onChange={(e) => {
            setBillingAccountIdInput(e.target.value.trim());
          }}
          required
          size="medium"
        />
        <TextField
          data-cy="project-id"
          variant="outlined"
          label="Project ID containing your BigQuery data"
          value={projectId}
          error={!projectId && (copyClicked || showError)}
          onChange={(e) => {
            setProjectId(e.target.value.trim());
          }}
          required
          size="medium"
        />
        <TextField
          data-cy="dataset-id"
          variant="outlined"
          label="Dataset name containing your BigQuery data"
          value={datasetId}
          error={!datasetId && (copyClicked || showError)}
          onChange={(e) => {
            setDatasetId(e.target.value.trim());
          }}
          required
          size="medium"
        />
      </Stack>
      <Stack>
        <RunGCPCommandsExplanation
          codeToCopy={codeToCopy}
          emptyVariables={emptyVariables}
          handleCopyAllCommandsClick={handleCopyAllCommandsClick}
          showError={showError}
        />
        <BillingCommands
          codeVariables={codeVariables}
          setCopyClicked={handleCopyClicked}
          serviceAccount={serviceAccount}
        />
      </Stack>
      <UsefulLinks cloud={SaaSConsoleType.GCP} />
      <DetailedUsageCostSetupDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
      />
    </Stack>
  );
};
