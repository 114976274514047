import { type SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import { type TextFieldProps } from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import { isProduction } from "../../../constants";

const loadScript = (src: string, position, id: string, callback: () => void) => {
  const script = document.createElement("script"); // create script tag
  script.type = "text/javascript";
  script.setAttribute("async", "");
  script.setAttribute("id", id);

  script.onload = () => {
    callback();
  };

  script.src = src;
  position.appendChild(script);
};

const autocompleteService: {
  current?: google.maps.places.AutocompleteService;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
} = {};
const placesService: { current?: google.maps.places.PlacesService } = {};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleMaps({
  textFieldProps,
  onChange,
  onClear,
  country,
  placeholder,
  bigInput,
}: {
  textFieldProps?: TextFieldProps;
  onChange: (place: google.maps.places.PlaceResult | null) => void;
  onClear: () => void;
  country?: string;
  placeholder?: string;
  bigInput?: boolean;
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      const key = isProduction ? "AIzaSyBtfYPd47JeEj7psr5aqePI5GYB3R9fVwQ" : "AIzaSyDWeLIZ2IkB-SuUBeg0fCnDeCtuCks_d0k";
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`,
        document.querySelector("head"),
        "google-maps",
        () => {
          setScriptLoaded(true);
        }
      );
    }

    loaded.current = true;
  }

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const fetch = useMemo(
    () =>
      throttle((request: google.maps.places.AutocompletionRequest, callback) => {
        autocompleteService.current?.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && scriptLoaded) {
      autocompleteService.current = new google.maps.places.AutocompleteService();
    }
    if (!placesService.current && scriptLoaded) {
      placesService.current = new google.maps.places.PlacesService(document.createElement("div"));
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (!placesService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    if (!autocompleteService.sessionToken) {
      autocompleteService.sessionToken = new google.maps.places.AutocompleteSessionToken();
    }

    const request: google.maps.places.AutocompletionRequest = {
      input: inputValue,
      sessionToken: autocompleteService.sessionToken,
      types: ["address"],
    };
    if (country) {
      request.componentRestrictions = {
        country,
      };
    }
    fetch(request, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, country, fetch, scriptLoaded]);

  return (
    <Autocomplete
      id="google-map-places-search"
      getOptionLabel={(option: string | google.maps.places.AutocompletePrediction) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      freeSolo
      includeInputInList
      onChange={(event: SyntheticEvent, value: string | google.maps.places.AutocompletePrediction | null) => {
        if (value) {
          const request = {
            placeId: typeof value === "string" ? value : value.place_id,
            sessionToken: autocompleteService.sessionToken,
            fields: ["address_components"],
          };
          placesService.current?.getDetails(request, (place, status) => {
            if (status === "OK") {
              onClear();
              onChange(place);
            }
          });
          autocompleteService.sessionToken = new google.maps.places.AutocompleteSessionToken();
        } else {
          onClear();
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label={placeholder || "Search address..."}
          size={bigInput ? "medium" : "small"}
          onChange={handleChange}
        />
      )}
      renderOption={(props, option: string | google.maps.places.AutocompletePrediction | null) => {
        if (typeof option !== "string" && option !== null) {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props}>
              <Grid
                container
                sx={{
                  alignItems: "center",
                }}
              >
                <Grid>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid size="grow">
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }
      }}
    />
  );
}
