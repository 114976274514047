import { type MouseEvent, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { CollectionModel, type CollectionModelDebtAnalyticsModel, type CurrencyCode } from "@doitintl/cmp-models";
import { getCollection, type QueryModel } from "@doitintl/models-firestore";
import CreditCardIcon from "@mui/icons-material/CreditCardRounded";
import MoreVertRounded from "@mui/icons-material/MoreVertRounded";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { TableFooter } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import throttle from "lodash/throttle";
import { DateTime } from "luxon";

import { useCustomerCache } from "../../Components/CustomerCache";
import DebtGraph from "../../Components/DebtGraph";
import Hide from "../../Components/HideChildren/Hide";
import { usePaginateQuery } from "../../Components/hooks/usePaginateQuery";
import { formatCurrency } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import ContactPopover from "./ContactPopover";
import { useDebtGraphData } from "./DebtGraphData";
import { useStyles } from "./styles";
import { type DebtItem } from "./types";

export const CollectionDebt = () => {
  const classes = useStyles();
  const [strategic, setStrategic] = useState(false);
  const [severity, setSeverity] = useState([]);
  const query = useMemo(() => {
    let q: QueryModel<CollectionModelDebtAnalyticsModel> = getCollection(CollectionModel)
      .doc("debt-analytics")
      .collection("debtAnalytics");

    if (strategic) {
      q = q.where("strategic", "==", true);
    }

    if (severity.length > 0) {
      q = q.where("severity", "in", severity);
    }

    return q;
  }, [strategic, severity]);
  const {
    items,
    handlePrevPage,
    handleNextPage,
    createSortHandler,
    rowsPerPage,
    orderBy: debtOrderBy,
    orderDirection: debtOrderDirection,
    setRowsPerPage,
    hasNextPage,
    hasPrevPage,
  } = usePaginateQuery(query, { defaultOrderBy: "weight" });
  const { isMobile: smDown } = useFullScreen();

  const customerIds = useMemo(() => items?.map((item) => item.data.customer) ?? [], [items]);
  const customers = useCustomerCache(customerIds);
  const debtGraphData = useDebtGraphData();

  const [totals, setTotals] = useState<{ [key: string]: number }>({});

  const [popover, setPopover] = useState<{
    entity: DebtItem["data"]["entityData"] | null;
    anchorEl: any;
  }>({
    entity: null,
    anchorEl: null,
  });

  const handleOpenContact = (item: DebtItem) => (event: MouseEvent) => {
    setPopover({
      entity: item.data.entityData,
      anchorEl: event.currentTarget,
    });
  };

  const handleCloseContact = () => {
    setPopover((prev) => ({
      ...prev,
      entity: null,
    }));
  };

  useEffect(() => {
    setTotals(
      (prevTotals) =>
        items?.reduce((memo, item) => {
          for (const [currency, total] of Object.entries(item.data.totals)) {
            if (!(currency in memo)) {
              memo[currency] = 0;
            }
            memo[currency] += total;
          }
          return memo;
        }, prevTotals) ?? prevTotals
    );
  }, [items]);

  const handlePrevPageHandler = () => {
    setTotals({});
    handlePrevPage();
  };

  const handleNextPageHandler = () => {
    setTotals({});
    handleNextPage();
  };

  return (
    <>
      {popover.entity && (
        <ContactPopover onClose={handleCloseContact} entity={popover.entity} anchorEl={popover.anchorEl} />
      )}
      <DebtGraph data={debtGraphData} />
      <Card className={classes.cardTable}>
        <CardContent style={{ padding: 0 }}>
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={strategic}
                  onChange={(event) => {
                    setStrategic(event.target.checked);
                  }}
                  color="primary"
                  size="small"
                />
              }
              label="Strategic"
            />
            <ToggleButtonGroup
              size="small"
              value={severity}
              onChange={(_event, severity) => {
                setSeverity(severity);
              }}
              className={classes.toggleButtonGroup}
            >
              <ToggleButton
                disableRipple
                value={10}
                classes={{
                  root: classes.toggleButton,
                  selected: classes.selected,
                }}
              >
                {`<35`}
              </ToggleButton>
              <ToggleButton
                disableRipple
                value={20}
                classes={{
                  root: classes.toggleButton,
                  selected: classes.selected,
                }}
              >
                36-60
              </ToggleButton>
              <ToggleButton
                disableRipple
                value={30}
                classes={{
                  root: classes.toggleButton,
                  selected: classes.selected,
                }}
              >
                61-90
              </ToggleButton>
              <ToggleButton
                disableRipple
                value={40}
                classes={{
                  root: classes.toggleButton,
                  selected: classes.selected,
                }}
              >
                91+
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Divider />
          {items && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <Hide smDown>
                    <TableCell align="left">Billing Profile</TableCell>
                  </Hide>
                  <TableCell align="left">
                    <TableSortLabel
                      active={debtOrderBy === "date"}
                      direction={debtOrderDirection}
                      onClick={createSortHandler("date")}
                    >
                      Debt Age
                    </TableSortLabel>
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <TableSortLabel
                      active={debtOrderBy === "weight"}
                      direction={debtOrderDirection}
                      onClick={createSortHandler("weight")}
                    >
                      Debt
                    </TableSortLabel>
                  </TableCell>
                  <TableCell padding="none" align="right" />
                </TableRow>
              </TableHead>

              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.ref.id} hover>
                    <TableCell>
                      {customers[item.data.customer.id] ? (
                        <Typography component={Link} to={item.data.customer.path} variant="body2" color="inherit">
                          {customers[item.data.customer.id].name}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <Hide smDown>
                      <TableCell align="left">
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            alignItems: "center",
                          }}
                        >
                          <Grid>
                            <Typography
                              component={Link}
                              to={`/customers/${item.data.customer.id}/invoices`}
                              variant="body2"
                              color="inherit"
                            >
                              {item.data.entityData.name}
                            </Typography>
                          </Grid>
                          {item.data.entityData.payment &&
                            item.data.entityData.payment.type === "credit_card" &&
                            item.data.entityData.payment.card !== null && (
                              <Tooltip title="Paying with credit card">
                                <Grid>
                                  <CreditCardIcon fontSize="small" />
                                </Grid>
                              </Tooltip>
                            )}
                        </Grid>
                      </TableCell>
                    </Hide>
                    <TableCell align="left">
                      {smDown
                        ? DateTime.fromJSDate(item.data.date.toDate()).toLocaleString(DateTime.DATE_SHORT)
                        : DateTime.fromJSDate(item.data.date.toDate()).toLocaleString(DateTime.DATE_MED)}
                    </TableCell>
                    <Tooltip
                      title={
                        item.data.totals && (
                          <>
                            {Object.keys(item.data.totals)
                              .filter((k) => item.data.totals[k] > 0)
                              .map((k) => formatCurrency(item.data.totals[k], k as CurrencyCode, 0))
                              .join(", ")}
                          </>
                        )
                      }
                    >
                      <TableCell padding="none" align="center">
                        {formatCurrency(item.data.weight, "USD", 0)}
                      </TableCell>
                    </Tooltip>
                    <TableCell align="right" padding="checkbox">
                      <IconButton onClick={handleOpenContact(item)} size="small">
                        <MoreVertRounded fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {items.length < rowsPerPage && (
                  <TableRow style={{ height: 48 * (rowsPerPage - items.length) }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
              <Hide smDown>
                {items.length > 0 && (
                  <TableHead>
                    <TableRow>
                      <TableCell size="small" colSpan={5} className={classes.tablePaginationContainer}>
                        {Object.entries(totals).flatMap(([currency, total]) => {
                          if (!total) {
                            return [];
                          }
                          return [
                            <Tooltip key={currency} title={`Total ${currency} for current page`}>
                              <Chip
                                className={clsx(classes[`chip${currency}`], classes.chip)}
                                label={formatCurrency(total, currency as CurrencyCode, 0)}
                              />
                            </Tooltip>,
                          ];
                        })}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
              </Hide>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5} padding="none" className={classes.tablePaginationContainer}>
                    <div className={classes.tablePagination}>
                      <Typography variant="body2" className={classes.pageSizeSelectLabel}>
                        Rows per page:
                      </Typography>
                      <div className={classes.pageSizeSelectContainer}>
                        <FormControl>
                          <Select
                            value={rowsPerPage}
                            onChange={(event) => {
                              setRowsPerPage(event.target.value as number);
                            }}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className={classes.navigateButtons}>
                        <IconButton
                          aria-label="Back"
                          onClick={throttle(handlePrevPageHandler, 500, {
                            leading: true,
                            trailing: false,
                          })}
                          disabled={!hasPrevPage}
                          size="large"
                        >
                          <NavigateBeforeIcon />
                        </IconButton>
                        <IconButton
                          aria-label="Next"
                          onClick={throttle(handleNextPageHandler, 500, {
                            leading: true,
                            trailing: false,
                          })}
                          disabled={!hasNextPage}
                          size="large"
                        >
                          <NavigateNextIcon />
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </CardContent>
      </Card>
    </>
  );
};
