import { useMemo } from "react";

import { useHistory } from "react-router-dom";
import { Button, Stack, TableCell, Typography } from "@mui/material";

import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { ThreeDotsMenu } from "../../Components/ThreeDotsMenu";
import { useDataHubContext } from "../../Context/DataHubContext";
import { formatNumber, formatStringDate } from "../../utils/common";
import { type DatasetItem } from "./DataHubSources";
import { deleteProcessingMinutes, isAnySelectedUpdatedWithinLastXMinutes, metadataProcessingMinutes } from "./utils";

interface DataHubSourceRowProps {
  data: DatasetItem;
  handleExplore: (dataset?: string) => void;
  handleDelete: (dataset: DatasetItem) => void;
  onImportData: (datasetName: string) => void;
  datasetsRefreshed: boolean;
}

const DataHubSourceRow = ({
  data,
  handleExplore,
  handleDelete,
  onImportData,
  datasetsRefreshed,
}: DataHubSourceRowProps) => {
  const history = useHistory();
  const customerId = useCustomerId();
  const { fetchDatasetBatches } = useDataHubContext();
  const deleteDisabled = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes([data], "lastUpdated", deleteProcessingMinutes),
    [data]
  );

  const openInReportsDisabled = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes([data], "lastUpdated", metadataProcessingMinutes),
    [data]
  );

  const threeDotsMenuOptions = useMemo(() => {
    const options = [
      {
        label: <Typography>{datahubTxt.OPEN_IN_REPORTS}</Typography>,
        action: () => {
          handleExplore(data.dataset);
        },
        key: datahubTxt.OPEN_IN_REPORTS,
        tooltip: openInReportsDisabled ? datahubTxt.DATASET_REPORT_DATA_UNAVAILABLE : undefined,
        disabled: openInReportsDisabled,
      },
      {
        label: <Typography>{datahubTxt.IMPORT_DATA}</Typography>,
        action: () => {
          onImportData(data.dataset);
        },
        key: datahubTxt.IMPORT_DATA,
      },
      {
        label: <Typography>{datahubTxt.DELETE}</Typography>,
        action: () => {
          handleDelete(data);
        },
        key: datahubTxt.DELETE,
        tooltip: deleteDisabled ? datahubTxt.DATASET_RECENTLY_INGESTED : undefined,
        disabled: deleteDisabled,
      },
    ];
    return options.filter((item) => !!item);
  }, [data, deleteDisabled, handleDelete, handleExplore, onImportData, openInReportsDisabled]);

  const handleClickViewDetails = () => {
    if (datasetsRefreshed) {
      fetchDatasetBatches(data.dataset, true);
    }
    history.push(`/customers/${customerId}/datasets/${data.dataset}`);
  };

  return (
    <>
      <TableCell>{data.dataset}</TableCell>
      <TableCell>{data.updatedBy}</TableCell>
      <TableCell>
        {data.processing ? <i>Processing {data.processing} records...</i> : formatNumber(data.records)}
      </TableCell>
      <TableCell>{formatStringDate(data.lastUpdated)}</TableCell>
      <TableCell padding="none">
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Button variant="outlined" onClick={handleClickViewDetails} sx={{ whiteSpace: "nowrap", mr: 1 }}>
            {datahubTxt.VIEW_DETAILS}
          </Button>
          <ThreeDotsMenu options={threeDotsMenuOptions} disabled={false} />
        </Stack>
      </TableCell>
    </>
  );
};

export default DataHubSourceRow;
