/* eslint-disable @eslint-react/dom/no-missing-iframe-sandbox */
import { type PerkModel } from "@doitintl/cmp-models";
import { Box, Divider, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useFullScreen } from "../../../utils/dialog";
import { type SectionTitles } from "../types";

export const youtubeEmbedBaseUrl = "https://www.youtube.com/embed";
const videoHeight = 333;

export const getEmbedYoutubeUrl = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  const id = match?.[2];
  return `${youtubeEmbedBaseUrl}/${id}`;
};

const NavLink = styled(Link)({
  fontSize: "14px",
  paddingTop: 16,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  display: "block",
});

export const PerkVideo = ({
  perk,
  setExpandedModule,
  sectionTitles,
}: {
  perk: PerkModel;
  setExpandedModule: (string) => void;
  sectionTitles: SectionTitles;
}) => {
  const { isMobile } = useFullScreen("md");
  if (!perk.fields?.videoUrl || typeof perk.fields?.videoUrl !== "string") {
    return null;
  }

  return (
    <Box
      data-cy="perk-video-section"
      sx={{
        display: "flex",
        mt: "32px",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Box>
        <iframe
          width={isMobile ? "100%" : "592"}
          height={videoHeight}
          style={{ border: 0 }}
          src={getEmbedYoutubeUrl(perk.fields?.videoUrl)}
          allowFullScreen
        />
      </Box>
      <Box sx={{ maxHeight: videoHeight, overflowY: "scroll" }}>
        {Array.isArray(perk.modules) && !!perk.modules?.length && (
          <Box
            data-cy="video-section-nav-bar"
            sx={{
              flexGrow: 1,
              pl: "19px",
            }}
          >
            <Typography variant="h4" sx={{ paddingBottom: "16px" }}>
              Key Features
            </Typography>
            <Divider />
            {perk.modules.map(({ title }) => (
              <NavLink
                key={title}
                href={`#${sectionTitles.modules}`}
                onClick={() => {
                  setExpandedModule(title);
                }}
              >
                {title}
              </NavLink>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
