import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { AssetTypeGoogleCloud, type GoogleCloudAssetModel, ProductEnum } from "@doitintl/cmp-models";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { type FilterTableToolbarProps } from "../../../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { useEntitiesOrPresentationModeEntities } from "../../../../../Components/hooks/useEntitiesOrPrenstationModeEntities";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type Asset, type Contract } from "../../../../../types";
import NoAssets from "../../../Tabs/components/NoAssets";
import { mapEntitiesByKey } from "../../utils";
import { gcpPartnerColumns, gcpPartnerHeaders } from "./GcAssetColumnsPartner";
import { GcAssetRowPartner, type GcpPartnerAssetRowData, populateRowFromAsset } from "./GcAssetRowPartner";
import TransferGCProjects from "./TransferGCProjects";
import { useCustomerGenuineGCPPartnerAssets } from "./useCustomerGenuineGCPPartnerAssets";

type Props = {
  items: Asset<GoogleCloudAssetModel>[];
  onRemoveAsset: (asset) => () => void;
  toolbar: FilterTableToolbarProps;
};

const GcAssetTablePartner = ({ items, onRemoveAsset, toolbar }: Props) => {
  const [assetToTransfer, setAssetToTransfer] = useState<Asset<GoogleCloudAssetModel> | null>(null);
  const [transferDialogsOpen, setTransferDialogsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<GcpPartnerAssetRowData[]>([]);
  const { customer, contracts } = useCustomerContext();
  const { entities } = useEntitiesOrPresentationModeEntities();
  const customerGenuineGCPPartnerAssets = useCustomerGenuineGCPPartnerAssets();
  const showNoAssets = useMemo<boolean>(
    () => !customer.assets?.includes(ProductEnum.GoogleCloud) && !items.length,
    [customer.assets, items.length]
  );

  const entitiesByKey = useMemo(() => mapEntitiesByKey(entities), [entities]);

  // Asset data has reference to contract, whilst I want the actual data.
  const contractsByAssetId = useMemo<Record<string, Contract | undefined>>(
    () =>
      items.reduce((acc, asset) => {
        const contract = contracts.filter((c) => c.id === asset.data.contract?.id);
        acc[asset.id] = contract.pop();
        return acc;
      }, {}),
    [contracts, items]
  );

  useEffect(() => {
    setTableData(
      items
        .concat(customerGenuineGCPPartnerAssets)
        .map((asset) => populateRowFromAsset(asset, entitiesByKey, contractsByAssetId))
    );
  }, [items, entitiesByKey, contractsByAssetId, customerGenuineGCPPartnerAssets]);

  const onTransferProjectsAction = useCallback(
    (assetId: string) => {
      setAssetToTransfer(items.filter((a) => a.id === assetId)[0]);
      setTransferDialogsOpen(true);
    },
    [items]
  );

  const onTransferProjectsClick = () => {
    setTransferDialogsOpen(true);
  };
  const onTransferProjectsCancel = () => {
    setTransferDialogsOpen(false);
  };

  toolbar.allowToEditColumns = !showNoAssets;

  const componentRow = useCallback(
    ({ row }) => (
      <GcAssetRowPartner row={row} onTransferProjectsAction={onTransferProjectsAction} onRemoveAsset={onRemoveAsset} />
    ),
    [onRemoveAsset, onTransferProjectsAction]
  );

  return (
    <Box
      data-testid="gc-assets-table"
      sx={{
        mb: 4,
      }}
    >
      <>
        <FilterTable<GcpPartnerAssetRowData>
          toolbarProps={toolbar}
          tableItems={showNoAssets ? [] : tableData}
          emptyTableComponent={
            <NoAssets assetType={AssetTypeGoogleCloud}>
              <Button variant="contained" onClick={toolbar.primaryButton?.onClick}>
                New subscription
              </Button>
            </NoAssets>
          }
          rowComponent={componentRow}
          headerColumns={gcpPartnerHeaders}
          filterColumns={gcpPartnerColumns}
          filterBarPlaceholder="Filter assets"
          persistenceKey="gcp_assets_partner"
          itemUniqIdentifierField="id"
          defaultSortingColumnValue="displayName"
          showFilterBar={!showNoAssets}
          showHeader={!showNoAssets}
        />
        {transferDialogsOpen && assetToTransfer !== null && (
          <TransferGCProjects
            onTransferProjectsClick={onTransferProjectsClick}
            onTransferProjectsCancel={onTransferProjectsCancel}
            assetId={assetToTransfer.id}
            firstDialogOpen={transferDialogsOpen}
          />
        )}
      </>
    </Box>
  );
};
export default GcAssetTablePartner;
