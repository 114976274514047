import { useCallback, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../Integrations/Slack/utils";
import { ActiveWidgetsListStyles } from "./ActiveWidgetsList.styles";
import { doitConnectSA } from "./ConnectDialog";
import { ServiceAccountStatusChip } from "./ServiceAccountStatusChip";
import { type GCPCloudConnectData } from "./useCloudConnectData";
import { useWorkloadIdentityFederationConnectionCheck } from "./useWorkloadIdentityFederationConnectionCheck";

export type WorkloadIdentityFederationStatusProps = {
  serviceAccount: GCPCloudConnectData;
};

export const WorkloadIdentityFederationStatus = ({ serviceAccount }: WorkloadIdentityFederationStatusProps) => {
  const theme = useTheme();
  const showErrorSnackbar = useErrorSnackbar();
  const classes = ActiveWidgetsListStyles(theme);
  const [expanded, setExpanded] = useState(false);
  const [checkingConnection, checkConnection] = useWorkloadIdentityFederationConnectionCheck(serviceAccount);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const doConnectionCheck = useCallback(() => {
    (async () => {
      try {
        const result = await checkConnection();
        if (!result.isConnectionEstablished) {
          setErrorMessage(result.errorDescription);
        }
      } catch {
        showErrorSnackbar("Unable to check connection. Please try again in a few minutes.");
      }
    })();
  }, [checkConnection, showErrorSnackbar]);

  return (
    <Accordion
      onChange={(_event, expanded) => {
        setExpanded(expanded);
        setErrorMessage(null);
      }}
      expanded={expanded}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="workload-identity-federation-details"
        id="workload-identity-federation-header"
        classes={{
          content: classes.accordionSummaryCategory,
        }}
        sx={{
          ".MuiAccordionSummary-expandIconWrapper": {
            color: theme.palette.action.active,
          },
        }}
      >
        <FormControlLabel
          disabled
          control={
            <Checkbox
              color="primary"
              checked
              onChange={(e) => {
                e.stopPropagation();
              }}
            />
          }
          label="Service account impersonation"
        />
        <Box sx={{ width: 160 }}>
          <ServiceAccountStatusChip
            status={serviceAccount === undefined ? 0 : (serviceAccount.data.workloadIdentityFederationStatus ?? 3)}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails id="workload-identity-federation-details">
        <Typography
          variant="body2"
          sx={{
            mb: 2,
          }}
        >
          Bind ServiceAccountTokenCreator role to the service account under DoiT organization
        </Typography>
        <CopyCodeBlock
          base={`gcloud iam service-accounts add-iam-policy-binding ${serviceAccount.data.clientEmail} --member=serviceAccount:${doitConnectSA} --role=roles/iam.serviceAccountTokenCreator`}
        />
        <LoadingButton
          onClick={doConnectionCheck}
          color="primary"
          variant="outlined"
          disabled={checkingConnection}
          loading={checkingConnection}
          sx={{ marginTop: 2, marginBottom: 2 }}
          mixpanelEventId="settings.workload-identity-federation-status.test-connection"
        >
          Test Connection
        </LoadingButton>
        {errorMessage !== null && (
          <Alert
            severity="error"
            onClose={() => {
              setErrorMessage(null);
            }}
          >
            {errorMessage}
          </Alert>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
