import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { Alert, Box, MenuItem, Stack, TextField, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { getTenantId } from "../../../../utils/Auth/tenant";
import { useErrorSnackbar } from "../../../Integrations/Slack/utils";
import { constructIdpLoginUrl, getCallbackUrl, getSpProviderId, interpetateIdpFromUrl } from "../helpers";
import { useIsLegacySso } from "../hooks";
import { type SSOLabels, type SSOProviders } from "../types";

const defaultLabels = {
  callbackUrl: "Callback URL",
  idpLoginUrl: "IdP Login URL",
  spProviderId: "Service provider entity ID",
};

const useTenantId = () => {
  const { customer } = useCustomerContext();
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const [tenantId, setTenantId] = useState<string | undefined | null>(undefined);

  useEffect(() => {
    if (isDoitEmployee) {
      getTenantId(customer.id)
        .then(setTenantId)
        .catch((e: any) => {
          consoleErrorWithSentry(e);
          setTenantId(null);
        });
    } else {
      if (!currentUser.tenantId) {
        setTenantId(null);
        return;
      }

      setTenantId(currentUser.tenantId);
    }
  }, [currentUser.tenantId, customer.id, isDoitEmployee]);

  return tenantId;
};

export const SsoInfoBox = ({ idpUrl }: { idpUrl?: string }) => {
  const { ssoType } = useParams<{ ssoType: SSOProviders }>();
  const {
    customer: { primaryDomain },
  } = useCustomerContext();
  const tenantId = useTenantId();
  const idpLoginUrl = constructIdpLoginUrl(tenantId ?? "");
  const callbackUrl = getCallbackUrl(false);
  const spProviderId = getSpProviderId(primaryDomain);
  const ssoFieldsValues =
    ssoType === "saml" ? { callbackUrl, spProviderId, idpLoginUrl } : { callbackUrl, idpLoginUrl };
  const [providersMap, setProvidersMap] = useState<Record<string, string>>({});
  const [labelsByProvider, setLabelsByProvider] = useState<{ [ssoProviderName: string]: SSOLabels }>({});
  const [selectedProvider, setSelectedProvider] = useState<string>("default");
  const [ssoProvidersTexts] = useDocumentDataOnce(getCollection(AppModel).doc("sso-providers-texts"));
  const isLegacySSO = useIsLegacySso(ssoType);
  const showErrorSnackbar = useErrorSnackbar();

  useEffect(() => {
    if (!idpUrl) {
      return;
    }
    setSelectedProvider(interpetateIdpFromUrl(idpUrl));
  }, [idpUrl]);

  useEffect(() => {
    if (ssoProvidersTexts?.[ssoType]) {
      setLabelsByProvider(ssoProvidersTexts[ssoType]);
      setProvidersMap(buildProvidersMap(Object.keys(ssoProvidersTexts[ssoType])));
    }
  }, [ssoProvidersTexts, ssoType]);

  useEffect(() => {
    if (tenantId === null) {
      showErrorSnackbar("Could not get tenant id");
    }
  }, [showErrorSnackbar, tenantId]);

  // wait for load
  if (tenantId === undefined) {
    return null;
  }
  return (
    <>
      <Typography
        variant="h4"
        sx={{
          pt: 4,
        }}
      >
        Add the following information to your provider
      </Typography>
      <Stack
        spacing={2}
        sx={{
          pt: 1,
          pb: 6,
        }}
      >
        <TextField
          sx={{ width: 164 }}
          id="select-provider"
          select
          label="Provider"
          value={selectedProvider}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedProvider(event.target.value);
          }}
        >
          {[
            <MenuItem key="default" value="default">
              Default
            </MenuItem>,
            ...Object.entries(providersMap).map(([providerValue, providerName]) => (
              <MenuItem key={providerValue} value={providerValue}>
                {providerName}
              </MenuItem>
            )),
          ]}
        </TextField>
        {Object.entries(ssoFieldsValues).map(([label, value]) => {
          const actualLabel = labelsByProvider?.[selectedProvider]?.[label] || defaultLabels[label];
          return (
            <Box key={label}>
              <Typography variant="subtitle2">{actualLabel}:</Typography>
              {isLegacySSO && label === "callbackUrl" && (
                <Alert severity="info" sx={{ display: "flex", paddingY: 0 }}>
                  {`There's an error with your SSO login.`}
                  <br />
                  {`Copy the URL from the ${actualLabel} field, go to your SSO provider, and set it in the ${actualLabel} field of the ${ssoType.toUpperCase()} settings page for the DoiT application.`}
                </Alert>
              )}
              <CopyCodeBlock base={value} />
            </Box>
          );
        })}
      </Stack>
    </>
  );
};

function formatName(name: string) {
  if (name.includes(" ")) {
    return name;
  }
  const withSpaces = name.replace(/([A-Z])/g, " $1");
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
}

function buildProvidersMap(providersList: string[]) {
  return providersList.reduce((providersMap, providerName) => {
    providersMap[providerName] = formatName(providerName);
    return providersMap;
  }, {});
}
