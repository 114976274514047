import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import { type AttributionFilter, Relation } from "@doitintl/cmp-models";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { lightBlue } from "@mui/material/colors";

import { attributionText } from "../../../../assets/texts";
import useTransforms from "../../../../Components/hooks/cloudAnalytics/useTransforms";
import { useFullScreen } from "../../../../utils/dialog";

export type AttributionBuilderChipsProps = {
  filter: AttributionFilter;
  nullFallback: string | null;
  lightStyle?: boolean;
  guidedExperienceMode?: boolean;
  shouldWrap?: boolean;
};

type FilterChipProps = {
  defaultColor: "primary" | "secondary";
  guidedExperienceMode?: boolean;
  label: string;
  lightStyle?: boolean;
};

const FilterChip = ({ defaultColor, guidedExperienceMode, label, lightStyle }: FilterChipProps) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      setIsOverflow(ref.current.clientWidth >= 250);
    }
  }, []);

  return (
    <Tooltip title={label} disableHoverListener={!isOverflowed}>
      <Box ref={ref}>
        <Chip
          data-cy="filter-chip"
          label={label}
          size="small"
          color={lightStyle ? "default" : defaultColor}
          sx={{
            color: lightStyle ? lightBlue[900] : "",
            maxWidth: 250,
          }}
          variant={guidedExperienceMode ? "outlined" : "filled"}
        />
      </Box>
    </Tooltip>
  );
};

const AttributionBuilderChips = ({
  filter,
  nullFallback,
  lightStyle,
  guidedExperienceMode,
  shouldWrap,
}: AttributionBuilderChipsProps) => {
  const { isMobile: smDown } = useFullScreen("sm");
  const [transforms] = useTransforms();

  const wideScreenMaxChips = filter.allowNull ? 4 : 5;
  const maxChips = smDown ? 1 : wideScreenMaxChips;
  const defaultColor = filter.inverse ? "secondary" : "primary";

  const OrChip = useCallback(() => {
    if (lightStyle) {
      return (
        <Typography
          sx={{
            width: "15px",
          }}
        >
          {Relation.OR.toLowerCase()}
        </Typography>
      );
    } else if (guidedExperienceMode) {
      return <Typography variant="caption">{Relation.OR.toLowerCase()}</Typography>;
    } else {
      return (
        <Chip
          data-cy="or-chip"
          sx={{
            maxWidth: 250,
          }}
          label={Relation.OR}
          variant="outlined"
          size="small"
          color={defaultColor}
        />
      );
    }
  }, [lightStyle, guidedExperienceMode, defaultColor]);

  const ChipRow = useCallback(
    ({ orCondition, label }) => (
      <Stack
        direction="row"
        sx={{
          columnGap: guidedExperienceMode ? 0.8 : lightStyle ? 0.5 : 0.2,
          alignItems: "center",
          width: orCondition && lightStyle && !shouldWrap ? "100%" : "initial",
        }}
      >
        {orCondition && <OrChip />}
        <FilterChip
          defaultColor={defaultColor}
          guidedExperienceMode={guidedExperienceMode}
          label={label}
          lightStyle={lightStyle}
        />
      </Stack>
    ),
    [guidedExperienceMode, lightStyle, shouldWrap, OrChip, defaultColor]
  );

  // filter the 'values' and remove 'existingNullFallback'
  // because it added to the end
  const filteredValues = filter.values?.filter((v) => v !== nullFallback);

  const showMaxChip = filteredValues?.length && filteredValues.length > maxChips;

  return (
    <Stack
      direction="row"
      data-cy="chips-container"
      sx={{
        flexWrap: lightStyle || shouldWrap ? "wrap" : "nowrap",
        columnGap: guidedExperienceMode ? 0.8 : 0.4,
        rowGap: 1,
        alignItems: "center",
      }}
    >
      {(!!filteredValues?.length || filter.allowNull) && (
        <>
          <Typography
            data-cy="description-is"
            sx={{
              mr: 0.2,
              minWidth: lightStyle ? "15px" : "initial",
            }}
            variant={guidedExperienceMode ? "caption" : undefined}
          >
            {filter.inverse ? attributionText.IS_NOT : attributionText.IS}
          </Typography>
          {filteredValues?.slice(0, maxChips).map((v, i) => (
            <Fragment key={v}>
              <ChipRow orCondition={i > 0} label={transforms?.[filter.id]?.(v, nullFallback) ?? v} />
            </Fragment>
          ))}
          {filter.allowNull && <ChipRow orCondition={!!filteredValues?.length} label={nullFallback ?? "[N/A]"} />}
          {showMaxChip && guidedExperienceMode ? (
            <Typography data-cy="max-chip" variant="caption">{`+${filteredValues.length - maxChips}`}</Typography>
          ) : showMaxChip ? (
            <Chip
              data-cy="max-chip"
              variant={lightStyle ? "outlined" : "filled"}
              label={`+${filteredValues.length - maxChips}`}
              size="small"
              color="default"
              sx={{
                maxWidth: 250,
              }}
            />
          ) : null}
        </>
      )}
      {!!filter.regexp?.length && (
        <>
          <Typography variant={guidedExperienceMode ? "caption" : undefined}>
            {filter.inverse ? attributionText.REGEX_NO_MATCH : attributionText.REGEX_MATCH}
          </Typography>
          <FilterChip
            defaultColor={defaultColor}
            guidedExperienceMode={guidedExperienceMode}
            label={filter.regexp}
            lightStyle={lightStyle}
          />
        </>
      )}
    </Stack>
  );
};

export default AttributionBuilderChips;
