import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import { Box, Checkbox, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";

import { type ContractCredits } from "../../types";

export const creditsByType = {
  customerContract: {
    [AssetTypeAmazonWebServices]: [
      { label: "AWS credits", creditsName: "aws", tooltipText: "From AWS to customer" },
      { label: "DoiT credits", creditsName: "doit", tooltipText: "From DoiT to customer" },
    ],
    [AssetTypeGoogleCloud]: [
      { label: "Google credits", creditsName: "google", tooltipText: "From Google to customer" },
      { label: "Google PSF credits", creditsName: "googlePsf", tooltipText: "From Google to customer" },
      { label: "DoiT credits", creditsName: "doit", tooltipText: "From DoiT to customer" },
    ],
  },
  vendorContract: [{ label: "Vendor credits", creditsName: "vendor", tooltipText: "From vendor to DoiT" }],
};

type Props = {
  values: ContractCredits;
  vendor: string;
  type: "customerContract" | "vendorContract";
  handleChangeCredits: (credits: any) => void;
  errors?: Record<string, Record<string, boolean>>;
  isEditForbidden: boolean;
};

export const Credits = ({ values, vendor, type, handleChangeCredits, errors, isEditForbidden }: Props) => (
  <Box
    sx={{
      mt: 4,
    }}
  >
    <Typography>Credits (optional)</Typography>
    {(type === "customerContract" ? creditsByType[type][vendor] : creditsByType[type]).map(
      ({ label, creditsName, tooltipText }) => (
        <Box key={label}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Checkbox
              data-cy={`${creditsName}-credits-checkbox`}
              disabled={isEditForbidden}
              checked={Boolean(values[creditsName])}
              onChange={({ target }) => {
                const updatedValues = { ...values };
                if (target.checked) {
                  updatedValues[creditsName] = { amount: "", description: "" };
                } else {
                  delete updatedValues[creditsName];
                }

                handleChangeCredits({ ...updatedValues });
              }}
            />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip title={tooltipText} placement="right">
                <Typography>{label}</Typography>
              </Tooltip>
            </Box>
          </Box>

          {values[creditsName] && (
            <Box
              sx={{
                pl: "40px",
              }}
            >
              <TextField
                name="amount"
                disabled={isEditForbidden}
                data-cy="credits-amount"
                variant="outlined"
                margin="dense"
                label="Credits amount"
                value={values[creditsName]?.amount}
                onChange={({ target }) => {
                  handleChangeCredits({
                    ...values,
                    [creditsName]: { ...values[creditsName], amount: target.value },
                  });
                }}
                type="number"
                error={errors?.[creditsName]?.amount}
                fullWidth
                required
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  },
                }}
              />
              <TextField
                name="description"
                disabled={isEditForbidden}
                data-cy="credits-description"
                variant="outlined"
                margin="dense"
                label="Credits description"
                type="text"
                value={values[creditsName]?.description}
                onChange={({ target }) => {
                  handleChangeCredits({
                    ...values,
                    [creditsName]: { ...values[creditsName], description: target.value },
                  });
                }}
                sx={{ marginTop: "15px" }}
                multiline
                minRows={5}
                error={errors?.[creditsName]?.description}
                fullWidth
                required
              />
            </Box>
          )}
        </Box>
      )
    )}
  </Box>
);
