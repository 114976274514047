import { useCallback, useState } from "react";

import { type StandaloneOnboardingErrorType } from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button, Stack, TextField, Typography } from "@mui/material";

import { useCustomerId } from "../../../../../Components/hooks/useCustomerId";
import mixpanel from "../../../../../utils/mixpanel";
import { IconWithLink } from "../../../Common/IconWithLink";
import { NumberedList } from "../../../Common/NumberedList";
import { ContactSupport } from "../../Common/ContactSupport";
import { ErrorStepLocation, getErrorAlert } from "../../Common/helpers/errorsHelper";
import { HelpPanel } from "../../Common/HelpPanel";
import { InviteCollegeLink } from "../../Common/InviteCollegeLink";
import { OnBoardingAlert } from "../../Common/OnBoardingAlert";
import { OnboardLayout } from "../../Common/OnboardLayout";
import { getBillingStackURL } from "../stackCreationUtils";
import { step1Billing, step2Billing, step3Billing } from "./Common/texts";

type Props = {
  error?: StandaloneOnboardingErrorType;
  allowToContinue: boolean;
  isMissingAWSRole: boolean;
  handleDone: () => void;
  handleBack: () => void;
};
export const BillingPermissions = ({ error, allowToContinue, isMissingAWSRole, handleDone, handleBack }: Props) => {
  const customerId = useCustomerId();
  const [bucket, setBucket] = useState<string>("");
  const [path, setPath] = useState<string>("");

  const [accessOrganizationClicked, setAccessOrganizationClicked] = useState<boolean>(false);

  const handleBucketChange = (e) => {
    setBucket(e.target.value.trim());
  };

  const handlePathChange = (e) => {
    setPath(e.target.value.trim());
  };

  const handleStackCreation = useCallback(() => {
    mixpanel.track("flexsaveSA.openproviderviewestimate", { type: "aws" });
    setAccessOrganizationClicked(true);
  }, []);

  const secondStep = (
    <>
      {step2Billing}
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 3,
        }}
      >
        <TextField
          data-cy="s3-bucket"
          variant="outlined"
          label="S3 Bucket"
          value={bucket}
          onChange={handleBucketChange}
          required
          size="medium"
        />
        <TextField
          data-cy="cur-path"
          variant="outlined"
          label="Report path prefix"
          value={path}
          onChange={handlePathChange}
          required
          size="medium"
        />
      </Stack>
    </>
  );

  const billingSteps = [step1Billing, secondStep, step3Billing];

  return (
    <OnboardLayout
      information={
        <HelpPanel text="Flexsave works by automatically applying discounts from Savings Plans to your uncovered On-Demand workloads. By providing access to Flexsave SPs, you're just one step away from realizing substantial savings for your company.">
          <IconWithLink
            text="Learn about required policies"
            icon={<OpenNewIcon />}
            url="https://help.doit.com/docs/flexsave/onboarding/aws/activate"
          />
          <IconWithLink
            text="Find your S3 bucket and Report path prefix"
            icon={<OpenNewIcon />}
            url="https://help.doit.com/docs/flexsave/sign-up/aws/activate#set-up-your-report-files"
          />
          <ContactSupport />
          <InviteCollegeLink />
        </HelpPanel>
      }
      bottomAppBarProps={{
        primaryButtonName: "Done",
        secondaryButtonName: "Back",
        handlePrimaryButtonClicked: handleDone,
        handleSecondaryButtonClicked: handleBack,
        disabled: !allowToContinue,
      }}
    >
      <Stack
        spacing={4}
        sx={{
          maxWidth: "sm",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "500",
          }}
        >
          Provide access to activate Flexsave for AWS
        </Typography>
        {getErrorAlert(error, ErrorStepLocation.AWS_ACTIVATION)}
        <NumberedList list={billingSteps} />
        <Stack spacing={3}>
          {accessOrganizationClicked && !error && (
            <OnBoardingAlert
              title="Accessing organization"
              body="After you create stack in the AWS Console it may take up to 60 seconds for this page to update."
              type="info"
            />
          )}

          <Button
            href={getBillingStackURL(customerId, bucket, path, isMissingAWSRole)}
            target="_blank"
            data-cy="create-stack-button"
            disabled={!path || !bucket}
            variant="outlined"
            sx={{ alignSelf: "flex-start" }}
            startIcon={<OpenNewIcon />}
            onClick={handleStackCreation}
          >
            Open AWS CloudFormation Console
          </Button>
        </Stack>
      </Stack>
    </OnboardLayout>
  );
};
