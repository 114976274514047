import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Card, CardContent, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { getCustomerProspect } from "../../Dashboard/CloudConnection/utils";
import StartFreeTrialButton from "../../StartTrialButton/StartTrialButton";

type Props = {
  title: string;
  detail: string;
  tryFor?: string | string[];
};
export const PremiumFeatureCard = ({ title, detail, tryFor }: Props) => {
  const { customer } = useCustomerContext();
  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        backgroundColor: theme.palette.mode === "light" ? "#FAFAFA" : "#2D2D39",
        mt: 1,
        mb: 2,
        pt: 4,
        pb: 4,
        pl: 15,
        pr: 15,
        ".MuiCardContent-root": {
          p: 0,
          "&:last-child": {
            pb: 0,
          },
        },
        [theme.breakpoints.down("md")]: {
          pl: 4,
          pr: 4,
        },
      })}
    >
      <CardContent sx={{ maxWidth: 750 }}>
        <Stack spacing={2.25} direction="column">
          <Stack spacing={1} direction="column">
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body1" color="textSecondary">
              {detail}
            </Typography>
          </Stack>
          {!!tryFor && getCustomerProspect(customer) !== "advantage" && (
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                color: "text.primary",
                flexWrap: "wrap",
                rowGap: 2,
              }}
            >
              <Stack
                spacing={0.5}
                direction="row"
                sx={{
                  alignItems: "center",
                  mr: 2,
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: 24 }} color="inherit" />
                {Array.isArray(tryFor) ? (
                  tryFor.map((t, i) => (
                    <Typography key={i} variant="body2">
                      {t}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body2">Try {tryFor} with your own data for free for 45 days</Typography>
                )}
              </Stack>
              <StartFreeTrialButton />
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
export default PremiumFeatureCard;
