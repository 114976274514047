import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Link, Stack } from "@mui/material";
import { useClipboard } from "use-clipboard-copy";

type btnColor = undefined | "inherit" | "info" | "success" | "warning" | "error" | "default" | "primary" | "secondary";

type Props = {
  text: string;
  label?: string;
  color?: btnColor;
  onClick?: () => void;
  underline?: boolean;
  fontSize?: string;
};

export const CopyToClipboardButton = ({ text, label, color, onClick, underline = true, fontSize }: Props) => {
  const clipboard = useClipboard({
    copiedTimeout: 1400,
  });

  const handleCopy = (event) => {
    event.stopPropagation();
    clipboard.copy(text);
    onClick?.();
  };

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        ml: -1,
      }}
    >
      <IconButton color={color} onClick={handleCopy} data-cy="copy-token-to-clipboard">
        {clipboard.copied ? <CheckIcon color="primary" sx={{ fontSize }} /> : <ContentCopyIcon sx={{ fontSize }} />}
      </IconButton>
      <Link
        onClick={handleCopy}
        underline={underline ? "always" : "none"}
        color={color || "text.secondary"}
        variant="body2"
        sx={{ cursor: "pointer" }}
      >
        {label}
      </Link>
    </Stack>
  );
};
