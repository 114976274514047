import { useCallback } from "react";

import {
  type InviteModel,
  NotificationAudienceType,
  NotificationProviderType,
  type NotificationSettings,
  type UserModel,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";

import { userNotificationTexts } from "../../../assets/texts";
import { Loader } from "../../../Components/Loader";
import { NotificationsForm } from "../../../Components/Notifications/NotificationsForm";
import { useNotificationDescriptors } from "../../../Components/Notifications/useNotificationDescriptors";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";
import { useUserEmailNotification } from "./useUserEmailNotification";

type UserEmailNotificationsProps = {
  userRef: ModelReference<UserModel> | ModelReference<InviteModel>;
  permissions: Set<string> | undefined;
};

export const UserEmailNotifications = ({ userRef, permissions }: UserEmailNotificationsProps) => {
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [notificationDescriptors, loadingNotificationDescriptors] = useNotificationDescriptors(
    NotificationAudienceType.USER,
    NotificationProviderType.EMAIL,
    permissions
  );

  const { loadingUserNotifications, updateUserEmailNotification, userEmailNotification } =
    useUserEmailNotification(userRef);

  const saveNotifications = useCallback(
    async (configuredNotificationSettings: NotificationSettings) => {
      try {
        await updateUserEmailNotification(configuredNotificationSettings);
        successSnackbar(userNotificationTexts.NOTIFICATION_PREFERENCES_SAVED);
      } catch (error) {
        if (error instanceof Error) {
          errorSnackbar(error.message);
        }
        consoleErrorWithSentry(error);
      }
    },
    [errorSnackbar, successSnackbar, updateUserEmailNotification]
  );

  return (
    <Loader loading={loadingNotificationDescriptors || loadingUserNotifications}>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
        }}
      >
        Set the notifications you want to receive via email
      </Typography>
      <NotificationsForm
        notificationDescriptors={notificationDescriptors}
        selectedNotifications={userEmailNotification?.selectedNotifications || {}}
        disableTargetUpdate={userEmailNotification?.disableTargetUpdate}
        onSubmit={saveNotifications}
      />
    </Loader>
  );
};
