import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";

import { LinkCell } from "../../../Common/TableCell/LinkCell";
import { type WorkloadDetailsItem } from "../types";
import { getFormattedCoverage } from "../utils/common";
import { buildDetailsUrl } from "../utils/linkUtils";
import { typeMappingConverter } from "../utils/workloadUtils";

export function FamilyTypeTableCell({ type }: { type: string }) {
  return (
    <TableCell>
      <Tooltip title={type}>
        <Box>{typeMappingConverter(type)}</Box>
      </Tooltip>
    </TableCell>
  );
}

export function BillingAccountTableCell({ row }: { row: WorkloadDetailsItem }) {
  return <LinkCell linkText={row.billingAccountId} to={buildDetailsUrl(row)} />;
}

export function TargetCoverageTableCell({
  targetCoverage,
  onEditClick,
}: {
  targetCoverage: number;
  onEditClick: () => void;
}) {
  return (
    <TableCell>
      <Stack
        direction="row"
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>{getFormattedCoverage(targetCoverage)}</Box>
        <IconButton onClick={onEditClick}>
          <EditIcon />
        </IconButton>
      </Stack>
    </TableCell>
  );
}
