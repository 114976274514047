import { type JSX } from "react";

import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import { IncludedWithPlanInfo } from "../../../Components/IncludedWithPlanInfo/IncludedWithPlanInfo";
import { ContactSalesButton } from "../../../Components/NoEntitlement/NoEntitlement";
import { useIsFeatureEntitledMultiple } from "../../../Context/TierProvider";
import { entitlementsToContactSupport } from "../../../Support/consts";
import mixpanel from "../../../utils/mixpanel";

export const ContactSupport = (props: { newSupportTicketUrl: string; mixpanelInsight: string }): JSX.Element => {
  // If the customer has any of the relevant entitlements that would allow them to contact support for this insight
  const isEntitledToContactSupportMap = useIsFeatureEntitledMultiple(entitlementsToContactSupport);
  const isEntitledToContactSupport = entitlementsToContactSupport.some((key) => isEntitledToContactSupportMap[key]);

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          mb: 1,
          fontWeight: 500,
        }}
      >
        Get expert advice from DoiT
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mb: 2,
          display: "inline",
        }}
      >
        Our Cloud Reliability Engineers can help you implement these changes.
      </Typography>
      {isEntitledToContactSupport ? (
        <>
          <IncludedWithPlanInfo isIncluded={true} />
          <Button
            component={Link}
            to={props.newSupportTicketUrl}
            onClick={() => {
              mixpanel.track("insights.details.contact-support", {
                insight: props.mixpanelInsight,
              });
            }}
            variant="contained"
            size="medium"
            color="primary"
            fullWidth
          >
            Contact an expert
          </Button>
        </>
      ) : (
        <>
          <IncludedWithPlanInfo isIncluded={false} />
          <ContactSalesButton variant="contained" fullWidth />
        </>
      )}
    </>
  );
};
