import { type ParsedAttribute } from "@algolia/autocomplete-preset-algolia/dist/esm/highlight/ParsedAttribute";
import { Avatar, Box, ListItemAvatar } from "@mui/material";

import { Highlight } from "./Highlight";

type Props = {
  avatarLogo?: string;
  avatarFallback?: string;
  highlightedTitle: ParsedAttribute[];
  highlightedSubtitle?: ParsedAttribute[];
};
export const AlgoliaResultListItem = ({ avatarLogo, avatarFallback, highlightedTitle, highlightedSubtitle }: Props) => (
  <>
    <ListItemAvatar sx={{ minWidth: 12, mr: 2 }}>
      <Avatar variant="circular" src={avatarLogo} sx={{ mt: 0, height: 28, width: 28 }}>
        {avatarFallback}
      </Avatar>
    </ListItemAvatar>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      <Highlight typographyProps={{ variant: "body1", color: "text.primary" }} parts={highlightedTitle} />
      {highlightedSubtitle && (
        <Highlight typographyProps={{ variant: "caption", color: "text.secondary" }} parts={highlightedSubtitle} />
      )}
    </Box>
  </>
);
