import { type Dispatch, type SetStateAction } from "react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Alert, Button, IconButton, Link, type SxProps, type Theme } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { attributionText } from "../../../assets/texts";

type AttributionOwnerBannerProps = {
  setHidden: Dispatch<SetStateAction<boolean>>;
  sx?: SxProps<Theme>;
};

const AttributionOwnerBanner = ({ setHidden, sx }: AttributionOwnerBannerProps) => (
  <Grid
    container
    spacing={1}
    data-testid="gke-alert"
    sx={[
      {
        alignItems: "center",
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Grid size={12}>
      <Alert
        severity="info"
        action={[
          <Button
            key="info"
            color="primary"
            size="small"
            component={Link}
            href="https://help.doit.com/cloud-analytics/attributing-cloud-spend"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Learn more"
            startIcon={<OpenNewIcon />}
          >
            {attributionText.NON_EDITOR_ALERT_BUTTON_TEXT}
          </Button>,
          <IconButton
            key="dismiss"
            aria-label="Dismiss"
            size="small"
            onClick={() => {
              setHidden(true);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>,
        ]}
      >
        {attributionText.NON_EDITOR_ALERT}
      </Alert>
    </Grid>
  </Grid>
);

export default AttributionOwnerBanner;
