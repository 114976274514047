import "react-phone-input-2/lib/style.css";

import { type ChangeEvent, useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import isEmail from "is-email";
import findIndex from "lodash/findIndex";
import remove from "lodash/remove";

import { globalText } from "../../assets/texts";
import LoadingButton from "../../Components/LoadingButton";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { transparentPhoneInput2 } from "../../Navigation/themes";
import { companiesLookup, sortAlphabetically } from "../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../utils/dialog";
import { restrictedCompaniesKeys } from "./AccountManagers";

const useStyles = makeStyles((theme) => ({
  phoneContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    ...transparentPhoneInput2(theme),
  },
  textField: {},
  menu: {},
}));

type FormData = {
  email?: string;
  name?: string;
  phone?: string;
  photo?: string | null;
  lineManager?: string | null;
  role?: string;
  company?: string;
};

type EditData = {
  email: string;
  name: string;
  phone: string;
  photoURL: string;
  role: string;
  lineManager?: string | null;
  company: string;
};

type Props = {
  open: boolean;
  edit?: EditData;
  handleClose: () => void;
  submit: (fields: FormData, edit?: EditData) => void;
};

type ErrorData = {
  name?: boolean;
  email?: boolean;
  role?: boolean;
  company?: boolean;
};

const EmailValidIcon = ({ emailValid }: { emailValid: boolean }) =>
  emailValid ? <DoneIcon style={{ color: green[400] }} /> : <CloseIcon style={{ color: red[400] }} />;

const AccountManagerForm = (props: Props) => {
  const { open } = props;
  const classes = useStyles();
  const [roles] = AccountManagersHooks.useAccountManagersRoles();
  const [allAccountManagers] = AccountManagersHooks.useAllAccountManagers();
  const { isMobile: smDown } = useFullScreen();

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const [photo, setPhoto] = useState<string | null>();
  const [lineManager, setLineManager] = useState<string | null>();
  const [role, setRole] = useState<string>();
  const [company, setCompany] = useState<string>();

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [errorObj, setErrorObj] = useState<ErrorData>({
    name: false,
    email: false,
    role: false,
    company: false,
  });
  const [loading, setLoading] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [lineManagerPartial, setLineManagerPartial] = useState<string>("");
  const isEdit = !!props.edit;

  const clearFields = useCallback(() => {
    setName("");
    setEmail("");
    setCompany(undefined);
    setRole(undefined);
    setLineManager(undefined);
    setPhoto(undefined);
    setPhone(undefined);
  }, []);
  const isEmailValid = useCallback(
    (email: string) => {
      // case-insensitive
      email = email.toLowerCase();

      if (allAccountManagers === undefined) {
        return false;
      }

      const tmpAC = [
        ...allAccountManagers.map((am) => ({ ...am, email: am.email ? am.email.toLowerCase() : undefined })),
      ];
      if (props.edit) {
        remove(tmpAC, (i) => props.edit && i.email === props.edit.email);
      }
      const index = findIndex(tmpAC, { email });
      if (!isEmail(email)) {
        return false;
      }
      if (index !== -1) {
        // not valid
        return false;
      }

      // valid
      return email !== "";
    },
    [allAccountManagers, props.edit]
  );

  useEffect(() => {
    clearFields();
    if (open) {
      setLoading(false);
      if (props.edit) {
        setEmailValid(isEmailValid(props.edit.email));
      }

      const fields = {
        name: false,
        email: false,
        role: false,
        company: false,
      };
      setErrorObj(fields);
    }
  }, [clearFields, isEmailValid, open, props.edit]);

  useEffect(() => {
    if (props.edit) {
      setName(props.edit.name);
      setEmail(props.edit.email);
      setPhone(props.edit.phone);
      setPhoto(props.edit.photoURL);
      setRole(props.edit.role);
      setCompany(props.edit.company);
      setLineManager(props.edit.lineManager ?? undefined);
    }
  }, [props.edit]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const inputVal = event.target.value;

    switch (name) {
      case "name":
        errorObj.name = !inputVal;
        setName(inputVal);
        break;
      case "email":
        setEmailValid(isEmailValid(inputVal));
        errorObj.email = !isEmailValid(inputVal);
        setEmail(inputVal);
        break;
      case "company":
        errorObj.company = !inputVal;
        setLineManagerPartial("");
        setLineManager("");
        setCompany(inputVal);
        break;
      case "role":
        errorObj.role = !inputVal;
        setRole(inputVal);
        break;
    }
  };

  const handleChangeLineManager = (lineManagerEmail: FormData["lineManager"]) => {
    setLineManager(lineManagerEmail ?? undefined);
  };

  const cancelEdit = () => {
    clearFields();
    props.handleClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    const tmpObj = { ...errorObj };
    const formFields: FormData = {
      email,
      name,
      phone,
      photo,
      lineManager,
      role,
      company,
    };

    let isHaveError = false;
    Object.keys(formFields).forEach((key) => {
      if (typeof tmpObj[key] !== "undefined") {
        tmpObj[key] = 0;
      }
    });
    Object.keys(tmpObj).forEach((key) => {
      tmpObj[key] = tmpObj[key] !== 0;
      if (tmpObj[key]) {
        isHaveError = true;
      }
    });
    if (props.edit) {
      isHaveError = false;
      Object.keys(errorObj).forEach((key) => {
        if (!formFields[key]) {
          tmpObj[key] = tmpObj[key] !== 0;
          isHaveError = true;
        }
      });
    }

    // add null to empty fields
    let allFields = Object.assign({}, formFields);
    if (!photo) {
      allFields = { ...formFields, photo: null };
    }
    if (!lineManager) {
      allFields.lineManager = null;
    }

    setErrorObj(tmpObj);

    if (!isHaveError) {
      props.submit(allFields, props.edit);
      clearFields();
    } else {
      setLoading(false);
    }
  };

  // validation
  useEffect(() => {
    if (![name, email, company, role].every((checkField) => !!checkField)) {
      setIsFormValid(false);
      return;
    }
    const checkName = name === name.trim() && name.trim().length > 0;
    const checkEmail = isEmailValid(email);
    setIsFormValid(checkName && checkEmail);
  }, [company, email, errorObj, isEmailValid, name, role]);

  if (!allAccountManagers) {
    return null;
  }

  return (
    <Dialog
      fullScreen={smDown}
      open={open}
      onClose={preventOnCloseWhile(loading, props.handleClose)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{isEdit ? "Edit" : "Create new"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid size={12}>
            <TextField
              onChange={handleChange}
              margin="dense"
              id="name"
              value={name ?? props.edit?.name ?? ""}
              name="name"
              label="Name"
              type="text"
              variant="outlined"
              fullWidth
              required
              error={!!name && name !== name.trim()}
              data-cy="amForm-name"
            />
          </Grid>
          <Grid size={12}>
            <TextField
              onChange={handleChange}
              margin="dense"
              id="email"
              name="email"
              variant="outlined"
              value={email ?? props.edit?.email ?? ""}
              label="Email Address"
              type="email"
              fullWidth
              required
              error={!!email && !isEmailValid(email)}
              helperText={!!email && !isEmailValid(email) ? "This email may already exist or may not be valid." : ""}
              data-cy="amForm-email"
              slotProps={{
                input: {
                  endAdornment: email ? <EmailValidIcon emailValid={emailValid} /> : <div style={{ width: 24 }} />,
                },
              }}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              id="company"
              name="company"
              select
              label="Company"
              variant="outlined"
              className={classes.textField}
              value={company ?? props.edit?.company ?? ""}
              onChange={handleChange}
              margin="dense"
              fullWidth
              required
              data-cy="amForm-company"
              error={errorObj.company}
              slotProps={{
                select: {
                  MenuProps: {
                    className: classes.menu,
                  },
                },
              }}
            >
              {Object.entries(companiesLookup).map(([c, companyData]) => {
                if (!restrictedCompaniesKeys.includes(c)) {
                  return (
                    <MenuItem key={c} value={c} data-cy={`amForm-company-${companyData.name.replaceAll(" ", "_")}`}>
                      {companyData.name}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </TextField>
          </Grid>
          <Grid size={12}>
            <TextField
              id="role"
              name="role"
              select
              label="Role"
              variant="outlined"
              className={classes.textField}
              value={role ?? props.edit?.role ?? ""}
              onChange={handleChange}
              data-cy="amForm-role"
              margin="dense"
              fullWidth
              required
              error={errorObj.role}
              slotProps={{
                select: {
                  MenuProps: {
                    className: classes.menu,
                  },
                },
              }}
            >
              {roles
                ?.filter((r) => r.vendors.includes(company ?? "") || r.vendors.includes(props?.edit?.company ?? ""))
                .map((r) => (
                  <MenuItem key={r.value} value={r.value} data-cy={`amForm-roleOption-${r.name}`}>
                    {r.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid size={12}>
            <Autocomplete
              id="lineManagerAutocomplete"
              autoSelect
              filterSelectedOptions
              options={
                allAccountManagers
                  ?.filter(
                    (am) =>
                      am.email !== email &&
                      am.name !== "" &&
                      am.company === company &&
                      am.name?.toLowerCase().trim().startsWith(lineManagerPartial.toLowerCase())
                  )
                  .sort(({ name: a }, { name: b }) => sortAlphabetically(a, b))
                  .map((accman) => accman.name) ?? []
              }
              value={lineManager ?? props.edit?.lineManager ?? ""}
              onInputChange={(_event, lineManagerAutocompletePartial) => {
                setLineManagerPartial(lineManagerAutocompletePartial);
              }}
              onChange={(_event, newValue) => {
                handleChangeLineManager(newValue);
              }}
              sx={{ mt: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Line manager"
                  size="small"
                  data-cy="amForm-lineManager"
                  variant="outlined"
                  className={classes.textField}
                  slotProps={{
                    inputLabel: { shrink: true },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={cancelEdit}>{globalText.CANCEL}</Button>
        <LoadingButton
          data-cy="amForm-submit"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={loading || !isFormValid}
          loading={loading}
          mixpanelEventId={`account-manager.${isEdit ? "Save" : "Create"}`}
        >
          {isEdit ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AccountManagerForm;
