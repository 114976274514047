import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";

import useFetchAmTamEmail from "../../../Components/NoEntitlement/hooks";
import { ContactSalesButton } from "../../../Components/NoEntitlement/NoEntitlement";
import { formatDecimalNumber } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { useInsightsContext } from "../context";

interface UpsellPreviewProps {
  totalInsights: number;
  savings: number;
}

export const UpsellPreview = ({ totalInsights, savings }: UpsellPreviewProps) => {
  const { accountManagers, loading } = useFetchAmTamEmail();
  const { isMobile } = useFullScreen();
  const { calculateSavingsForSelectedPeriod, savingsPeriod } = useInsightsContext();

  return (
    <Box sx={{ position: "relative" }}>
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: "center",
          gap: 2,
          px: 7,
          zIndex: 1,
          py: isMobile ? 1.5 : 4,
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            alignItems: isMobile ? "center" : "flex-start",
            gap: isMobile ? 1.7 : 2,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              textAlign: isMobile ? "center" : "start",
            }}
          >
            Upgrade your DoiT Cloud Navigator tier to access more insights{" "}
          </Typography>
          <ContactSalesButton sendTo={accountManagers} loading={loading} />
        </Stack>
        {savings > 50 && (
          <>
            <Box
              sx={{
                mb: 1,
              }}
            />
            <Stack
              direction="row"
              spacing={3.5}
              divider={<Divider orientation="vertical" sx={{ height: "54px", width: "1px" }} />}
              sx={{
                alignItems: "center",
                height: "28px",
              }}
            >
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  alignItems: "center",
                }}
              >
                <Typography variant="h1">{totalInsights}</Typography>
                <Typography variant="body1" sx={{ textWrap: "nowrap" }}>
                  New insights
                </Typography>
              </Stack>
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  alignItems: "center",
                }}
              >
                <Typography variant="h1">{`$${formatDecimalNumber(calculateSavingsForSelectedPeriod(savings), 0)}`}</Typography>
                <Typography variant="body1" sx={{ textWrap: "nowrap" }}>
                  Estimated {savingsPeriod} savings
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
      <Box sx={{ filter: "blur(13.5px)", pointerEvents: "none", opacity: 0.8 }}>
        <Typography variant="h3">Elastic Block Storage (EBS): gp2 to gp3 migration</Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
            mt: 1.5,
          }}
        >
          EBS gp2 volumes can be replaced by less expensive and equally performant gp3 volumes.
        </Typography>

        <Box sx={{ my: 2.25 }}>
          <Chip label="Optimized" variant="filled" size="small" color="success" />
        </Box>

        <Stack
          direction="row"
          spacing={1.5}
          divider={<Divider orientation="vertical" sx={{ height: "22px" }} />}
          sx={{
            alignItems: "center",
            height: "28px",
            color: "text.secondary",
          }}
        >
          <Stack
            direction="row"
            spacing={0.75}
            sx={{
              alignItems: "center",
            }}
          >
            <Typography variant="body2">Last checked on 25 Oct 2023</Typography>
            <Tooltip title="Based on recent billing data, excluding the last three days" arrow>
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Stack>

          <Tooltip title="Insight type" arrow>
            <Typography variant="body2" sx={{ borderBottom: "1px dotted", cursor: "pointer" }}>
              High effort, Cost optimization
            </Typography>
          </Tooltip>

          <Typography variant="body2">Owned by Team Bruteforce</Typography>
        </Stack>
      </Box>
    </Box>
  );
};
