import CheckIcon from "@mui/icons-material/Check";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const RoleItem = ({ feature }: { feature: string }) => (
  <Box>
    <CheckIcon sx={{ display: "inline", mr: 1, verticalAlign: "middle" }} color="success" />
    <Typography
      variant="body1"
      sx={{
        color: "text.secondary",
        display: "inline",
        mb: 1,
      }}
    >
      {feature}
    </Typography>
  </Box>
);

const FeatureList = () => (
  <Grid container spacing={1}>
    <Grid
      size={{
        xs: 12,
        md: 6,
      }}
    >
      <Stack spacing={1}>
        <RoleItem feature="Cloud analytics" />
        <RoleItem feature="Cost anomalies" />
        <RoleItem feature="Insights" />
      </Stack>
    </Grid>
    <Grid
      size={{
        xs: 12,
        md: 6,
      }}
    >
      <Stack spacing={1}>
        <RoleItem feature="Dashboards" />
        <RoleItem feature="Alerts" />
        <RoleItem feature="Budgets" />
      </Stack>
    </Grid>
  </Grid>
);

export default FeatureList;
