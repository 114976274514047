import { type ChangeEvent, memo, useCallback } from "react";

import { useHistory } from "react-router";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Link, Switch, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import isEqual from "lodash/isEqual";

import { authAutoProvisionTexts } from "../../../../assets/texts";
import { arrangeAutoProvisionDomains } from "../helpers";
import { type AuthSettingsActionType, type AuthSettingsType } from "../types";
import { AUTH_DATA_TEST_IDS } from "./testIds";

export type AutoProvisionProps = {
  isEnabled: AuthSettingsType["autoProvision"]["enabled"];
  allowedDomains: AuthSettingsType["autoProvision"]["allowedDomains"];
  dispatch: React.Dispatch<AuthSettingsActionType>;
  customerId: string;
  defaultRoleName: string;
  primaryDomain: string;
  domains: string[]; // this is what stored under customer.domains in the database don't confuse with customer.autoProvision.allowedDomains
};

function AutoProvisionComp({
  isEnabled,
  allowedDomains,
  dispatch,
  customerId,
  defaultRoleName,
  primaryDomain,
  domains,
}: AutoProvisionProps) {
  const history = useHistory();
  const onAutoProvisionEnableToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: "SET_AUTO_PROVISION_ENABLED", payload: event.target.checked });
    },
    [dispatch]
  );

  const onAutoProvisionDomainToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: event.target.checked ? "ADD_AUTO_PROVISION_DOMAIN" : "REMOVE_AUTO_PROVISION_DOMAIN",
        payload: event.target.value,
      });
    },
    [dispatch]
  );

  const arrangedDomains = arrangeAutoProvisionDomains({ domains, primaryDomain, allowedDomains });

  return (
    <FormControl>
      <Stack direction="row" spacing={1.5}>
        <Typography variant="h4" sx={{ mb: 1.5, mt: -0.6 }}>
          {authAutoProvisionTexts.TITLE}
        </Typography>
        <Tooltip
          title={authAutoProvisionTexts.TOOLTIP}
          placement="right"
          arrow={true}
          slotProps={{
            popper: {
              sx: { maxWidth: 320 },
            },
          }}
        >
          <InfoIcon fontSize="inherit" />
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          display: "inline",
          color: "text.secondary",
          mb: 1.5,
        }}
      >
        <Typography
          sx={{
            display: "inline",
          }}
        >
          {authAutoProvisionTexts.SUBTITLE.PART1}
        </Typography>
        <Tooltip
          title={`${authAutoProvisionTexts.DEFAULT_ROLE_TOOLTIP_PREAMBLE} ${defaultRoleName}`}
          placement="top"
          arrow={true}
          slotProps={{
            popper: { sx: { maxWidth: 320 } },
          }}
        >
          <Typography
            style={{ borderBottom: "2px dotted" }}
            sx={{
              display: "inline",
            }}
          >
            {authAutoProvisionTexts.SUBTITLE.DEFAULT_ROLE}
          </Typography>
        </Tooltip>
        <Typography
          sx={{
            display: "inline",
          }}
        >
          {authAutoProvisionTexts.SUBTITLE.PART2}
        </Typography>
        <Link
          underline="hover"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/customers/${customerId}/iam/roles`);
          }}
          data-testid={AUTH_DATA_TEST_IDS.AUTO_PROVISION.LINK}
        >
          {authAutoProvisionTexts.SUBTITLE.IDENTITY_AND_ACCESS}
        </Link>
      </Stack>
      <FormControlLabel
        label={authAutoProvisionTexts.SWITCH_LABEL}
        sx={{ mb: 4, width: "fit-content" }}
        control={
          <Switch
            checked={isEnabled}
            onChange={onAutoProvisionEnableToggle}
            data-testid={AUTH_DATA_TEST_IDS.AUTO_PROVISION.SWITCH}
          />
        }
      />
      <Typography variant="h4" sx={{ mb: 1.5 }}>
        {authAutoProvisionTexts.DOMAINS_SELECTION_TITLE}
      </Typography>
      <FormGroup aria-labelledby="select-auth-auto-provision-checkboxes-group-label">
        {arrangedDomains.map((domain) => (
          <FormControlLabel
            sx={{ position: "relative", left: 10, width: "fit-content" }}
            key={domain}
            value={domain}
            control={
              <Checkbox
                onChange={onAutoProvisionDomainToggle}
                size="small"
                checked={allowedDomains.includes(domain)}
                disabled={!isEnabled}
                data-testid={`${AUTH_DATA_TEST_IDS.AUTO_PROVISION.DOMAINS_CHECKBOX}-${domain}`}
              />
            }
            label={domain === primaryDomain ? `${domain} (Primary domain)` : domain}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
export const AutoProvision = memo(AutoProvisionComp, isEqual);
