import { useState } from "react";

import { Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useFullScreen } from "../../../utils/dialog";

type Props = {
  setSelectedGoals: (value: string[]) => void;
  options: string[];
  selectedGoals: string[];
};

const CompanyGoalsSelector = ({ selectedGoals, setSelectedGoals, options }: Props) => {
  const [othersText, setOthersText] = useState<string>("");
  const [isOthersSelected, setIsOthersSelected] = useState<boolean>(false);
  const { isMobile } = useFullScreen();

  const handleSelectChange = (option: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if (option === "Other") {
        setIsOthersSelected(true);
      }
      setSelectedGoals([...selectedGoals, option]);
    } else {
      const updatedSelectedGoals = selectedGoals.filter((item) =>
        option === "Other" ? item !== `Other: ${othersText}` : item !== option
      );
      if (option === "Other") {
        setIsOthersSelected(false);
        setOthersText("");
      }
      setSelectedGoals(updatedSelectedGoals);
    }
  };

  const handleOthersTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOthersText(event.target.value);
    const updatedSelectedGoals = selectedGoals.map((goal) =>
      goal.includes("Other") ? `Other: ${event.target.value}` : goal
    );
    setSelectedGoals(updatedSelectedGoals);
  };

  return (
    <Stack spacing={1}>
      <Typography variant="h4">What do you want to achieve?</Typography>
      <FormGroup>
        <Grid
          container
          sx={{
            pl: 1,
          }}
        >
          {options.map((option) => (
            <Grid key={option} size={isMobile ? 12 : 6}>
              <FormControlLabel
                control={<Checkbox checked={selectedGoals.includes(option)} onChange={handleSelectChange(option)} />}
                label={option}
              />
            </Grid>
          ))}
          <Grid size={6}>
            <FormControlLabel
              control={<Checkbox checked={isOthersSelected} onChange={handleSelectChange("Other")} />}
              label="Other"
            />
          </Grid>
          <Grid
            sx={{
              mt: 2,
            }}
            size={12}
          >
            <TextField
              id="other-goal"
              label="Please specify"
              value={othersText}
              onChange={handleOthersTextChange}
              disabled={!isOthersSelected}
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </FormGroup>
    </Stack>
  );
};

export default CompanyGoalsSelector;
