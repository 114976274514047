import { useMemo } from "react";

import { type AssetType } from "@doitintl/cmp-models";
import { Avatar, Stack } from "@mui/material";
import identity from "lodash/identity";

import AWSIconWhite from "../../../../../assets/amazon-web-services-logo-white.png";
import AWSIcon from "../../../../../assets/amazon-web-services-new-wide.png";
import AzureIcon from "../../../../../assets/azure-logo.svg";
import GSuiteIcon from "../../../../../assets/g-suite.png";
import GoogleCloudIcon from "../../../../../assets/google-cloud.png";
import Office365Icon from "../../../../../assets/office-365.png";
import { useDarkThemeCheck } from "../../../../hooks/useDarkThemeCheck";
import { type AssetDescriptor } from "../../../BillingProfileForm.models";

const darkThemeIcons: Partial<Record<AssetType, string>> = {
  "amazon-web-services": AWSIconWhite,
  "g-suite": GSuiteIcon,
  "google-cloud": GoogleCloudIcon,
  "google-cloud-project": GoogleCloudIcon,
  "microsoft-azure": AzureIcon,
  "office-365": Office365Icon,
};

const lightThemeIcons: Partial<Record<AssetType, string>> = {
  "amazon-web-services": AWSIcon,
  "g-suite": GSuiteIcon,
  "google-cloud": GoogleCloudIcon,
  "google-cloud-project": GoogleCloudIcon,
  "microsoft-azure": AzureIcon,
  "office-365": Office365Icon,
};

export const AssetsLogos = ({ assets }: { assets: AssetDescriptor[] }) => {
  const isDark = useDarkThemeCheck();

  const logos = useMemo(
    () =>
      Array.from(
        new Set(
          assets
            .map(({ data: { type } }) => type)
            .sort()
            .map((type) => (isDark ? darkThemeIcons[type] : lightThemeIcons[type]))
            .filter(identity)
        )
      ),
    [assets, isDark]
  );

  return (
    <Stack direction="row" spacing={1}>
      {logos.map((logo) => (
        <Avatar
          key={logo}
          src={logo}
          variant="square"
          sx={{ width: 24, height: 24 }}
          slotProps={{
            img: { sx: { objectFit: "contain" } },
          }}
        />
      ))}
    </Stack>
  );
};
