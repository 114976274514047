import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";

export const ViewBudgetHeader = ({ name }: { name: string }) => {
  const { customer } = useCustomerContext();

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        mb: 3,
        mt: 6,
      }}
    >
      <IconButton aria-label="Back" component={Link} to={`/customers/${customer.id}/analytics/budgets`} size="small">
        <ArrowBackIcon color="primary" />
      </IconButton>
      <Typography
        variant="h1"
        sx={{
          ml: 1,
          mr: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {name}
      </Typography>
    </Stack>
  );
};
