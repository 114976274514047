import { useCallback, useState } from "react";

import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Chip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import styled from "styled-components";

import { cmpBaseColors } from "../../../cmpBaseColors";
import { CopyToClipboardButton } from "../../../Components/CopyToClipboardButton";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useCurrency } from "../../../Components/hooks/useCurrency";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { OverflowToolTip } from "../../../Components/OverflowToolTip/OverflowToolTip";
import { ThemeModes } from "../../../muiThemeTypes";
import { formatDecimalNumber } from "../../../utils/common";
import { type AsgBasicInfo, type AsgTableItem, OptimizationStatus, type SavingsTimeFilter } from "../types";

// const CAPACITY_COLUMNS_WIDTH = 15;

type Props = {
  row: AsgTableItem;
  timeFilter: SavingsTimeFilter;
  handleAsgRefresh: (params?: AsgBasicInfo) => Promise<boolean>;
};

export const StyledChip = styled(Chip)`
  ${(props) => {
    if (props.color === "warning") {
      return useTheme().palette.mode === ThemeModes.LIGHT
        ? `background-color: ${cmpBaseColors.notOptimisedBackgroundLight}; color: ${cmpBaseColors.notOptimisedColor};`
        : `background-color: ${cmpBaseColors.notOptimisedBackgroundDark}`;
    }
  }}
`;

export const AsgsOverviewTableRow = ({ row, handleAsgRefresh }: Props) => {
  const [loading, setLoading] = useState(false);
  const routeMatchURL = useRouteMatchURL();
  const { customerCurrencySymbol } = useCurrency();
  const refreshAsg = useCallback(async () => {
    setLoading(true);
    const options: AsgBasicInfo = {
      scope: "asg",
      asgName: row.asgName,
      accountId: row.accountId,
      region: row.region,
    };
    const result = await handleAsgRefresh(options);
    setLoading(false);
    return result;
  }, [row, handleAsgRefresh]);

  // generate path with escape characters
  const pathToEditAsg = generatePath(`${routeMatchURL}/:asgId`, { asgId: row.id });

  return (
    <CellsWrapper>
      <TableCell data-cy="asgtable-asg-name">
        <Box
          sx={{
            maxWidth: { md: 150, lg: 220, xl: 400 },
          }}
        >
          <OverflowToolTip>
            <>
              <Typography
                sx={{ float: "left" }}
                component={Link}
                to={{ pathname: pathToEditAsg, state: { asg: row.asgItem } }}
                color="inherit"
                variant="body2"
              >
                {row.asgName}
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  float: "left",
                  marginLeft: "2px",
                  marginTop: "-2px",
                }}
              >
                <CopyToClipboardButton color="inherit" text={row.asgName} fontSize="12px" />
              </Typography>
            </>
          </OverflowToolTip>

          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              marginBottom: -2,
            }}
          >
            {row.region}
          </Typography>
        </Box>
      </TableCell>
      <TableCell data-cy="asgtable-optimization-status" sx={{ maxWidth: "40vw", minWidth: 100 }}>
        <Box
          sx={{
            maxWidth: { lg: 120, xl: 200 },
          }}
        >
          <StyledChip size="small" color={row.optimisationStatusColor} label={row.optimizationStatus} />
        </Box>
      </TableCell>
      <TableCell
        data-cy="asgtable-desired-min-max-capacity"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        {row.desiredMinMax}
      </TableCell>
      <TableCell
        data-cy="asgtable-od-spot-bc-capacity"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        {row.onDemandSpotBaseCapacity}
      </TableCell>
      <TableCell
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        <Box sx={{ whiteSpace: "nowrap" }}>{row.region}</Box>
      </TableCell>
      <TableCell
        data-cy="asgtable-account-name"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        <Typography variant="body2">{row.accountName}</Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            marginBottom: "-20px",
            float: "left",
          }}
        >
          {row.accountId}
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
            marginBottom: "-20px",
            marginTop: "-3px",
            marginLeft: "2px",
            float: "left",
          }}
        >
          <CopyToClipboardButton color="inherit" text={row.accountId} fontSize="12px" />
        </Typography>
      </TableCell>
      <TableCell
        data-cy="asgtable-account-id"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        {row.accountId}
      </TableCell>
      <TableCell
        data-cy="asgtable-spotSpending"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        {customerCurrencySymbol}
        {formatDecimalNumber(row.spotSpending, 0)}
      </TableCell>
      <TableCell
        data-cy="asgtable-odSpending"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        {customerCurrencySymbol}
        {formatDecimalNumber(row.odSpending, 0)}
      </TableCell>
      <TableCell
        data-cy="asgtable-savings"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        {customerCurrencySymbol}
        {formatDecimalNumber(row.savings, 0)}
        {row.optimizationStatus === OptimizationStatus.NotOptimized && "(Est/Month)"}
      </TableCell>
      <TableCell
        data-cy="asgtable-actions"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        <LoadingButton
          color="inherit"
          size="small"
          loading={loading}
          startIcon={<RefreshIcon />}
          onClick={refreshAsg}
        />
      </TableCell>
    </CellsWrapper>
  );
};
