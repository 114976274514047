import { type CurPath } from "@doitintl/cmp-models";
import {
  Box,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import { useDarkThemeCheck } from "../../../../../Components/hooks/useDarkThemeCheck";
import { S3ReportDetails } from "../../../../Flexsave/Standalone/AWS/Steps/Common/texts";

type Props = {
  curPath?: CurPath;
  bucket: string;
  selectedCur?: string;
  setSelectedCur: (cur?: string) => void;
};

const FormattedText = ({ text, fontWeight = 500 }: { text: string; fontWeight?: number }) => (
  <Typography
    sx={{
      fontWeight,
    }}
  >
    {text}
  </Typography>
);

const ReportHeader = ({ title, subTitle, bucket }: { title: string; subTitle: string; bucket: string }) => (
  <Box>
    <Typography sx={{ display: "inline" }}>{title} </Typography>
    <Typography
      sx={{
        fontWeight: 500,
        display: "inline",
        whiteSpace: "normal",
      }}
    >
      {bucket}{" "}
    </Typography>
    <Typography sx={{ display: "inline" }}>{subTitle}</Typography>
  </Box>
);

const SingleInvalidDoitReport = ({ bucket, curPath }: Pick<Props, "bucket" | "curPath">) => {
  const isGranularityMatched = curPath?.paths?.[0].granularity === S3ReportDetails.granularity.value;
  const isFormatMatched = S3ReportDetails.format.value.includes(curPath?.paths?.[0].format ?? "");
  const hasNoResourceId = curPath?.paths?.[0].noResource;

  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "flex-start",
      }}
    >
      <ReportHeader
        title="In the S3 bucket"
        subTitle="we did not find any valid Cost and Usage Reports."
        bucket={bucket}
      />
      <Typography>We did detect a CUR we believe was intended to be used:</Typography>
      <Stack spacing={0.5}>
        <FormattedText text={`${curPath?.paths?.[0].reportName}`} />
        <Stack
          spacing={0.5}
          sx={{
            pl: 3,
          }}
        >
          {!isGranularityMatched && (
            <Stack direction="row" spacing={0.5}>
              <FormattedText text="• Invalid time granularity -" />
              <Typography>Required:</Typography>
              <FormattedText text={`${S3ReportDetails.granularity.value},`} />
              <Typography>Currently:</Typography>
              <FormattedText text={`${curPath?.paths?.[0].granularity}`} />
            </Stack>
          )}
          {!isFormatMatched && (
            <Stack direction="row" spacing={0.5}>
              <FormattedText text="• Invalid format -" />
              <Typography>Required:</Typography>
              <FormattedText text={`${S3ReportDetails.format.value},`} />
              <Typography>Currently:</Typography>
              <FormattedText text={`${curPath?.paths?.[0].format}`} />
            </Stack>
          )}
          {hasNoResourceId && <Typography>• Report content does not contain Resource IDs</Typography>}
        </Stack>
      </Stack>
      <Typography>Update all required validation, return to this page and click Refresh</Typography>
    </Stack>
  );
};

const SingleValidReport = ({ bucket, curPath }: Pick<Props, "bucket" | "curPath">) => {
  const isDarkMode = useDarkThemeCheck();
  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "flex-start",
      }}
    >
      <ReportHeader title="In the S3 bucket" subTitle="we found one valid Cost and Usage Report:" bucket={bucket} />
      <Typography
        variant="h2"
        sx={{ background: isDarkMode ? "#2D2D39" : "#F5F5F5", borderRadius: 1, px: 1, py: 0.5, display: "inline" }}
      >
        {curPath?.paths?.[0].reportName}
      </Typography>
    </Stack>
  );
};

const NoReport = ({ bucket }: Pick<Props, "bucket">) => (
  <ReportHeader title="In the S3 bucket" subTitle="we did not find any valid Cost and Usage Reports:" bucket={bucket} />
);

export const CreateReportInstructions = () => (
  <>
    <Typography
      sx={{
        display: "inline",
      }}
    >
      Ensure you have a valid Cost and Usage Report in this S3 Bucket, configured as:{" "}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        ml: 2,
      }}
    >
      <List sx={{ listStyleType: "disc", pl: 4, pt: 0 }}>
        {Object.values(S3ReportDetails).map((item) => (
          <ListItem key={item.label} sx={{ display: "list-item", mt: -2, pl: 0 }}>
            <Box>
              <Typography sx={{ display: "inline" }}>{item.label}: </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  display: "inline",
                  whiteSpace: "normal",
                }}
              >
                {item.value}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </Typography>
  </>
);

const MultipleValidReports = ({ bucket, curPath, setSelectedCur, selectedCur }: Props) => {
  const paths = curPath?.paths;
  if (selectedCur === undefined && curPath?.paths?.[0].reportName) {
    setSelectedCur(curPath?.paths?.[0].reportName);
  }
  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "flex-start",
      }}
    >
      <ReportHeader
        title="In the S3 bucket"
        subTitle="we found multiple valid Cost and Usage Reports. Please select the correct Cost and Usage Report:"
        bucket={bucket}
      />
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={paths?.[0].reportName}
          name="radio-buttons-group"
          onChange={(event) => {
            setSelectedCur(event.target.value);
          }}
        >
          {paths?.map((path) => (
            <FormControlLabel
              value={path.reportName}
              control={<Radio />}
              label={path.reportName}
              key={path.reportName}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

const CurDiscoveryUI = ({ bucket, curPath, setSelectedCur, selectedCur }: Props) => {
  const state = curPath?.state;
  const paths = curPath?.paths;

  if (state === "NAN") {
    setSelectedCur(undefined);
    return <NoReport bucket={bucket} />;
  }

  if (state === "valid" && paths && paths.length === 1) {
    setSelectedCur(paths?.[0].reportName);
    return <SingleValidReport bucket={bucket} curPath={curPath} />;
  }

  if (state === "valid" && paths && paths.length > 1) {
    return (
      <MultipleValidReports
        bucket={bucket}
        curPath={curPath}
        selectedCur={selectedCur}
        setSelectedCur={setSelectedCur}
      />
    );
  }

  if (state === "invalid" && paths && paths.length >= 1) {
    setSelectedCur(undefined);
    return <SingleInvalidDoitReport bucket={bucket} curPath={curPath} />;
  }

  return null;
};

export default CurDiscoveryUI;
