import { useCallback, useState } from "react";

import { Box, Card, CardContent, Divider, Link, Typography } from "@mui/material";

import { handleResponseError, ResponseErrorCode } from "../../../../api/axiosClient";
import { useApiContext } from "../../../../api/context";
import DeleteDialog from "../../../../Components/DeleteDialog";
import { SafeHtml } from "../../../../Components/SafeHtml";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../../Context/AuthContext";
import { type User } from "../../../../types";
import mixpanel from "../../../../utils/mixpanel";
import { API_LINK_ADDRESS, text } from "../../consts";
import { FooterActions } from "./FooterActions";
import { FooterActionsModal } from "./FooterActionsModal";
import { GeneratedTokenBlock } from "./GeneratedTokenBlock";

/*
The form has 3 states (actions are enabled only for the owner of the key):
1. AccessKey doesn't exist, button: 'Generate'
2. AccessKey has just been generated, show it, allow to copy and download, show warning, button: 'Delete'
3. AccessKey exists, button: 'Delete'
*/

export type UserApiFormProps = {
  user: Pick<User, "email" | "accessKey" | "id">;
  modal?: boolean;
  open?: boolean;
  handleClose?: () => void;
};

export const UserApiForm = ({
  user: { email, accessKey, id: profileUserId },
  modal,
  handleClose,
}: UserApiFormProps) => {
  const api = useApiContext();
  const { userId } = useAuthContext();
  const actionsDisabledForNonOwners = profileUserId !== userId;
  const [generatedToken, setGeneratedToken] = useState("");
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const snackbar = useSnackbar();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleDeleteDialogue = useCallback(() => {
    setOpenDeleteDialog((show) => !show);
  }, []);

  const handleGenerateKey = useCallback(async () => {
    setLoadingGenerate(true);
    try {
      const response = await api.request({
        method: "post",
        url: `/v1/users/generateApiToken`,
        data: {},
      });
      setGeneratedToken(response.data.AccessToken);
      mixpanel.track("iam.users.api-key.create");
    } catch (error: any) {
      handleResponseError(error, (errorCode) => {
        snackbar.onOpen({
          message: errorCode,
          variant: "error",
          autoHideDuration: 5000,
        });

        if (errorCode !== ResponseErrorCode.NETWORK_ERROR) {
          setGeneratedToken("");
        }
      });
    }
    setLoadingGenerate(false);
  }, [api, snackbar]);

  const handleDeleteKey = useCallback(async () => {
    setLoadingDelete(true);
    try {
      await api.request({
        method: "post",
        url: `/v1/users/deleteApiKey`,
        data: {},
      });
      setGeneratedToken("");
    } catch (error: any) {
      handleResponseError(error, () => {
        snackbar.onOpen({
          message: error?.response?.data.error,
          variant: "error",
          autoHideDuration: 5000,
        });
      });
    }
    setLoadingDelete(false);
  }, [api, snackbar]);

  return (
    <>
      <Card sx={{ width: 600, border: modal ? "" : "none" }} data-cy="userApiForm">
        <CardContent sx={{ py: 2, px: 3 }}>
          <Typography variant="h3">{accessKey ? text.API_TAB_HEADER : text.API_TAB_GENERATE_KEY_HEADER}</Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            sx={{
              mt: 4,
              mb: 4,
            }}
          >
            {!!accessKey && !generatedToken && text.API_TAB_EXISTING_KEY_DESCRIPTION}
            {!accessKey && <SafeHtml html={text.API_TAB_GENERATE_KEY_DESCRIPTION} />}

            {(!accessKey || !!generatedToken) && (
              <Box>
                {text.API_TAB_LEARN_TO_USE_DESCRIPTION}
                <Link target="_blank" rel="noreferrer" href={`https://${API_LINK_ADDRESS}`}>
                  {API_LINK_ADDRESS}
                </Link>
                .
              </Box>
            )}
          </Typography>

          {!!generatedToken && <GeneratedTokenBlock generatedToken={generatedToken} email={email} />}
        </CardContent>

        {modal ? (
          <>
            <Divider />
            <FooterActionsModal
              accessKey={accessKey}
              loadingDelete={loadingDelete}
              loadingGenerate={loadingGenerate}
              actionsDisabledForNonOwners={actionsDisabledForNonOwners}
              toggleDeleteDialogue={toggleDeleteDialogue}
              handleGenerateKey={handleGenerateKey}
              generatedToken={generatedToken}
              handleClose={handleClose}
            />
          </>
        ) : (
          <FooterActions
            variant={accessKey ? "text" : "contained"}
            color={accessKey ? "error" : "primary"}
            loading={accessKey ? loadingDelete : loadingGenerate}
            onClick={accessKey ? toggleDeleteDialogue : handleGenerateKey}
            disabled={loadingDelete || actionsDisabledForNonOwners}
            dataCy={accessKey ? "delete-api-key" : "generate-api-key"}
            buttonText={accessKey ? text.API_TAB_DELETE_BUTTON_TEXT : text.API_TAB_GENERATE_BUTTON_TEXT}
            actionsDisabledForNonOwners={actionsDisabledForNonOwners}
          />
        )}
      </Card>
      <DeleteDialog
        open={openDeleteDialog}
        title={text.API_TAB_DELETE_DIALOGUE_TITLE}
        message={text.API_TAB_DELETE_DIALOGUE_TEXT}
        onDelete={handleDeleteKey}
        onClose={toggleDeleteDialogue}
      />
    </>
  );
};
