import { type CurrencyCode } from "@doitintl/cmp-models";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { invoicesText } from "../../../../assets/texts";
import { CircularProgressLoader } from "../../../../Components/Loader";
import { formatCurrency, formatDecimalNumber } from "../../../../utils/common";
import { type CreditCardFees } from "../utils";

type Props = {
  amount: number;
  creditCardFees: CreditCardFees;
  fetchingCCFees: boolean;
  symbol: CurrencyCode;
};

export const CreditCardFeesSection = ({ amount, creditCardFees, fetchingCCFees, symbol }: Props) => (
  <Box sx={{ position: "relative" }}>
    {fetchingCCFees && <CircularProgressLoader />}
    <Box sx={{ opacity: fetchingCCFees ? 0.5 : 1 }}>
      <Grid sx={{ pb: "1rem" }} size={12}>
        <Grid sx={{ pt: "1rem" }}>
          <Typography sx={{ display: "inline" }}>{`${invoicesText.CREDIT_CARD_FEES} (${formatDecimalNumber(
            creditCardFees.percentage,
            2,
            0
          )}%): `}</Typography>
          <Typography sx={{ display: "inline", fontWeight: "bold" }}>
            {formatCurrency(creditCardFees.totalFees / 100, symbol)}
          </Typography>
        </Grid>

        {creditCardFees.feesTaxPercentage > 0 && (
          <Grid>
            <Typography sx={{ display: "inline" }}>{`${invoicesText.TAX} (${formatDecimalNumber(
              creditCardFees.feesTaxPercentage,
              2,
              0
            )}%): `}</Typography>
            <Typography sx={{ display: "inline", fontWeight: "bold" }}>
              {formatCurrency(creditCardFees.feesTaxAmount / 100, symbol)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid>
        <Typography>
          {creditCardFees.feesTaxAmount > 0
            ? invoicesText.TOTAL_PAYMENT_AMOUNT
            : invoicesText.TOTAL_PAYMENT_AMOUNT_NOT_INCLUDING_TAX}
          :
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {formatCurrency((creditCardFees.feesTaxAmount + creditCardFees.totalFees) / 100 + amount, symbol)}
        </Typography>
      </Grid>
    </Box>
  </Box>
);
