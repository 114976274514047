import CommentIcon from "@mui/icons-material/Comment";
import { Badge, Stack, Typography } from "@mui/material";

import { useNotesContext } from "../../../../../../Context/customer/NotesContext";
import { type Customer } from "../../../../../../types";

type Props = {
  customerData?: Customer;
};

export const CustomerNotesIcon = ({ customerData }: Props) => {
  const notesContextValue = useNotesContext();
  if (!customerData) {
    return null;
  }
  return (
    <Stack
      direction="row"
      sx={{
        gap: 1.5,
      }}
    >
      <Badge
        badgeContent={notesContextValue.data?.pinned || notesContextValue.data?.unpinned}
        color="primary"
        onClick={notesContextValue.toggle}
        max={20}
        invisible={!notesContextValue.data?.unpinned}
      >
        <CommentIcon />
      </Badge>
      <Typography>Notes</Typography>
    </Stack>
  );
};
