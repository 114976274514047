import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Typography } from "@mui/material";

export default function BlackDot({ content }: { content: string }) {
  return (
    <Box
      data-cy={`not-ok-${content}`}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <FiberManualRecordIcon
        sx={{
          color: "black",
          fontSize: 5,
          marginLeft: "10px",
          marginRight: "14px",
        }}
      />
      <Typography
        sx={{
          fontSize: 14,
        }}
      >
        {content}
      </Typography>
    </Box>
  );
}
