import { Fragment, type ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Aggregator, CurrencyCodes, Metric, TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { budgetTxt } from "../../../assets/texts/CloudAnalytics/budget";
import { DefinitionList, DefinitionListDesc, DefinitionListTerm } from "../../../Components/DefinitionList";
import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import useTransforms from "../../../Components/hooks/cloudAnalytics/useTransforms";
import useFormatter, { formatValueWithCurrency } from "../../../Components/hooks/useFormatter";
import { MetadataCard } from "../../../Components/MetadataCard";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { sanitizeDate } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { BudgetTypes, type TimeRangeOption } from "../utilities";
import { getAttributionScope } from "../utils/getScope";
import BudgetPerformanceChart from "./BudgetPerformanceChart";
import { type BudgetDefinitionListItem } from "./budgetViewTypes";
import { useBudgetRefresh } from "./hooks";
import LoadingBudgetCardWrapper from "./LoadingBudgetCardWrapper";
import { getAlertAmountFromPercentage } from "./shared";
import {
  buildRange,
  filterByCurrentDay,
  filterByCurrentMonth,
  filterByCurrentQuarter,
  filterByCurrentWeek,
  filterByCurrentYear,
  formatTimeIntervalPhrase,
  getFrequencyPhrase,
  getStartAndEndOfDayUTC,
  getStartAndEndOfMonthUTC,
  getStartAndEndOfQuarterUTC,
  getStartAndEndOfWeekUTC,
  getStartAndEndOfYearUTC,
  populateActualsYValues,
  populateForecastedYValues,
  previewAmountByInterval,
  transformTimestampsToCategories,
} from "./utils";
import { ViewBudgetHeader } from "./ViewBudgetHeader";
import type { Budget } from "../../../types";

const previewTimeByInterval = {
  [TimeInterval.DAY]: TimeInterval.HOUR,
  [TimeInterval.WEEK]: TimeInterval.DAY,
  [TimeInterval.MONTH]: TimeInterval.DAY,
  [TimeInterval.QUARTER]: TimeInterval.WEEK,
  [TimeInterval.YEAR]: TimeInterval.MONTH,
};

const rangeByInterval = {
  [TimeInterval.DAY]: getStartAndEndOfDayUTC(),
  [TimeInterval.WEEK]: getStartAndEndOfWeekUTC(),
  [TimeInterval.MONTH]: getStartAndEndOfMonthUTC(),
  [TimeInterval.QUARTER]: getStartAndEndOfQuarterUTC(),
  [TimeInterval.YEAR]: getStartAndEndOfYearUTC(),
};

const filterForecastedByInterval = {
  [TimeInterval.DAY]: filterByCurrentDay,
  [TimeInterval.WEEK]: filterByCurrentWeek,
  [TimeInterval.MONTH]: filterByCurrentMonth,
  [TimeInterval.QUARTER]: filterByCurrentQuarter,
  [TimeInterval.YEAR]: filterByCurrentYear,
};

const MAX_DIMENSIONS_NUM = 5;
const BUDGET_CHART_HEIGHT = "40vh";

export const ViewBudget = () => {
  const { filteredAttributions: attributions } = useAttributionsContext();
  const [, budgets] = useBudgets();
  const [transforms] = useTransforms();
  const [budget, setBudget] = useState<Budget | undefined>(undefined);
  const [isBudgetLoading, setBudgetLoading] = useState<boolean>(true);

  const { budgetId } = useParams<{ budgetId: string }>();
  const { handleRefresh, loading: loadingRefreshedBudget } = useBudgetRefresh({
    isViewPage: true,
    budgetParams: {
      budgetId,
    },
  });

  const theme = useTheme();

  const variancePositiveColor = theme.palette.success.main;
  const varianceNegativeColor = theme.palette.error.main;
  const amount = budget?.data.config.amount || 1000;
  const alerts = budget?.data.config.alerts.map((alert) => ({
    ...alert,
    amount: getAlertAmountFromPercentage(alert.percentage, amount),
  }));

  const scope = budget && getAttributionScope(budget.data.config.scope, attributions);
  const timeInterval = budget?.data.config.timeInterval;
  const actual = budget?.data?.utilization?.current || 0;
  const type = budget?.data.config.type;
  const frequency = type === BudgetTypes.RECURRING ? "recurring" : "one-time";
  const currency = budget?.data.config.currency || CurrencyCodes.USD;
  const startPeriod = budget?.data.config.startPeriod
    ? DateTime.fromMillis(budget?.data.config.startPeriod.seconds * 1000).toUTC()
    : sanitizeDate(DateTime.utc());
  const endPeriod = budget?.data.config.endPeriod
    ? DateTime.fromMillis(budget?.data.config.endPeriod.seconds * 1000).toUTC()
    : sanitizeDate(DateTime.utc());
  const isBudgetEndDateExists = !!budget?.data?.config?.endPeriod;
  const budgetDateTerm = `Budget ${isBudgetEndDateExists ? "dates:" : "start date"}`;
  const budgetDates = `${startPeriod.toFormat("d MMM yyyy")}${isBudgetEndDateExists ? ` - ${endPeriod.toFormat("d MMM yyyy")}` : ""}`;
  const forecastedDateSeconds = budget?.data.utilization?.forecastedTotalAmountDate?.seconds;

  const previewTime = timeInterval && previewTimeByInterval[timeInterval];

  const actualDataPreviewAmount = timeInterval && previewAmountByInterval[timeInterval];

  const newTimeRangeOptions: TimeRangeOption = {
    mode:
      timeInterval === TimeInterval.DAY ||
      timeInterval === TimeInterval.WEEK ||
      timeInterval === TimeInterval.MONTH ||
      timeInterval === TimeInterval.QUARTER ||
      timeInterval === TimeInterval.YEAR
        ? TimeSettingsMode.Fixed
        : TimeSettingsMode.Last,
    time: previewTime,
    amount: actualDataPreviewAmount,
    includeCurrent: true,
  };

  const range = timeInterval && rangeByInterval[timeInterval];
  if (range) {
    newTimeRangeOptions.range = range;
  }

  const newTimeInterval =
    (range && type === BudgetTypes.RECURRING
      ? buildRange(range.start, range.end, timeInterval)
      : buildRange(
          budget?.data.config.startPeriod
            ? DateTime.fromMillis(budget.data.config.startPeriod.seconds * 1000)
            : DateTime.utc(),
          budget?.data.config.endPeriod
            ? DateTime.fromMillis(budget.data.config.endPeriod.seconds * 1000)
            : DateTime.utc(),
          timeInterval ?? TimeInterval.DAY
        )) || [];

  const categories = transformTimestampsToCategories(newTimeInterval, timeInterval ?? TimeInterval.DAY);
  const isForecastedDateInBudgetRange = (forecastedDate: number): boolean => {
    const forecastedDateToUTC = DateTime.fromMillis(forecastedDate * 1000).toUTC();
    return forecastedDateToUTC >= startPeriod && forecastedDateToUTC <= endPeriod;
  };

  const { isMobile } = useFullScreen("lg");
  const formatBudgetValue = (value: number) => formatValueWithCurrency(value, 2, currency, false);

  // Budget amount total data
  const budgetAmountData = newTimeInterval.map((timestamp) => ({ x: timestamp, y: amount }));

  // Actual data
  const actualDataRaw = newTimeInterval
    .slice(0, actualDataPreviewAmount)
    .map((timestamp, index) => ({ x: timestamp, y: index === actualDataPreviewAmount - 1 ? actual : 0 }));
  const actualData = populateActualsYValues(actualDataRaw, actual);
  const actualDataDates = actualData.map((i) => i.x);
  const lastActualData = actualData[actualData.length - 1];

  // Forecast data
  const forecasted = budget?.data?.utilization?.forecasted || 0;
  const forecastDataPeriod = newTimeInterval.slice(actualDataPreviewAmount);
  const initializedForecastDataPoints = forecastDataPeriod.map((timestamp, index) => ({
    x: timestamp,
    y: index === forecastDataPeriod.length - 1 ? forecasted : 0,
  }));
  // forecast excluding current spend
  const exclusiveForecastValues = forecasted - lastActualData?.y;
  // mapping cumulative forecast data points with remaining periods in budget
  const forecastData = populateForecastedYValues(
    initializedForecastDataPoints,
    exclusiveForecastValues,
    lastActualData?.y
  );
  const forecastForTimeInterval = timeInterval && filterForecastedByInterval[timeInterval](forecastData);
  const forecastWithoutDuplicates = useMemo(
    () =>
      forecastForTimeInterval?.filter((forecastItem: { x: any }) => !actualDataDates.includes(forecastItem.x)) || [],
    [forecastForTimeInterval, actualDataDates]
  );

  const lastAlert = alerts?.[alerts.length - 1];
  const maxBudget = lastAlert?.amount || 0;
  const maxBudgetByInterval = maxBudget / newTimeInterval.length;

  // Budget amount to date data
  const budgetData = newTimeInterval.slice(0, actualDataPreviewAmount).map((i, index) => ({
    x: i,
    y: maxBudgetByInterval * (index + 1),
  }));

  // Current period data
  const budgetAmountToDate = budgetData[budgetData.length - 1]?.y ?? null;
  const forecastAmountForEntirePeriod =
    forecastWithoutDuplicates.length && type === BudgetTypes.RECURRING
      ? forecastWithoutDuplicates[forecastWithoutDuplicates.length - 1]?.y
      : forecasted;
  const variance = budget ? budgetAmountToDate - actual : null;

  // logic to connect two lines on chart
  const lastDataPoint = actualData[actualData.length - 1];

  if (lastDataPoint) {
    forecastWithoutDuplicates?.splice(0, 0, lastDataPoint);
  }
  const formatter = useFormatter({ aggregator: Aggregator.TOTAL, currency, metric: Metric.COST });

  const showSkeleton = !budget && !newTimeInterval.length;
  const isBudgetUnavailable = !budget;
  const isRefreshedBudgetLoading = isBudgetLoading || loadingRefreshedBudget;
  const isBudgetEmpty = isBudgetUnavailable && !showSkeleton && !isRefreshedBudgetLoading;
  const hasRefreshedRef = useRef(false);

  useEffect(() => {
    const refreshIfNeeded = async () => {
      const foundBudget = budgets.find((b) => b.snapshot.id === budgetId);

      if (foundBudget) {
        setBudget(foundBudget);

        if (!foundBudget.data.utilization && !hasRefreshedRef.current) {
          await handleRefresh();

          hasRefreshedRef.current = true;
        }
        setBudgetLoading(false);
      }
    };

    refreshIfNeeded();
  }, [budgets, budgetId, handleRefresh]);

  useEffect(() => {
    if (!budgetId) {
      return;
    }
    mixpanel.track("analytics.budgets.view", { budgetId });
  }, [budgetId]);

  const budgetDimensionArray = useMemo(() => {
    if (scope && scope.length > 0) {
      return scope.map((scopeItem) => scopeItem?.data?.name);
    }

    const filters = budget?.data?.config.filters ?? [];

    return filters.flatMap((filter) => filter.values?.map((value) => transforms?.[filter.id]?.(value) ?? value));
  }, [budget?.data?.config.filters, scope, transforms]);

  const chartContent = useMemo(() => {
    if (isRefreshedBudgetLoading) {
      return (
        <Stack
          sx={{
            position: "relative",
            height: BUDGET_CHART_HEIGHT,
          }}
        >
          <Skeleton animation="wave" variant="rectangular" width="100%" height={BUDGET_CHART_HEIGHT} />
        </Stack>
      );
    }

    if (isBudgetEmpty) {
      return (
        <Stack
          component={Paper}
          variant="outlined"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: BUDGET_CHART_HEIGHT,
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1.5 }}>{budgetTxt.DATA_AVAILABLE_SOON}</Typography>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {`${capitalize(timeInterval)}ly budgets are displayed at a ${formatTimeIntervalPhrase(previewTime)} granularity. Data will start being displayed after one ${previewTime}.`}
          </Box>
        </Stack>
      );
    }

    return (
      <Box
        sx={{
          height: BUDGET_CHART_HEIGHT,
        }}
      >
        <BudgetPerformanceChart
          theme={theme}
          categories={categories}
          valueFormatter={formatter}
          actualData={actualData}
          budgetAmountData={budgetAmountData}
          budgetAmountToDateData={budgetData}
          forecastData={forecastWithoutDuplicates}
          alerts={alerts ?? []}
        />
      </Box>
    );
  }, [
    isRefreshedBudgetLoading,
    isBudgetEmpty,
    theme,
    categories,
    formatter,
    actualData,
    budgetAmountData,
    budgetData,
    forecastWithoutDuplicates,
    alerts,
    timeInterval,
    previewTime,
  ]);

  const errorContent = (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1.5 }}>{budgetTxt.DATA_AVAILABLE_SOON}</Typography>
    </Stack>
  );

  const renderDefinitionListItems = (items: BudgetDefinitionListItem[]): ReactNode =>
    items.map(({ term, value, loading, color }) => (
      <Fragment key={term}>
        <DefinitionListTerm>{term}</DefinitionListTerm>
        <DefinitionListDesc>
          <LoadingBudgetCardWrapper loading={loading}>
            {color ? <span style={{ color }}>{value}</span> : value}
          </LoadingBudgetCardWrapper>
        </DefinitionListDesc>
      </Fragment>
    ));

  const budgetSummaryItems = [
    { term: budgetTxt.BUDGET_AMOUNT_TERM, value: formatBudgetValue(amount), loading: isBudgetUnavailable },
    {
      term: budgetTxt.TYPE_AND_FREQUENCY,
      value: getFrequencyPhrase(timeInterval ?? TimeInterval.DAY, frequency),
      loading: isBudgetUnavailable,
    },
    { term: budgetDateTerm, value: budgetDates, loading: isBudgetUnavailable },
    {
      term: budgetTxt.DIMENSION,
      value: (
        <>
          {budgetDimensionArray.slice(0, MAX_DIMENSIONS_NUM).join(", ")}
          {budgetDimensionArray.length > MAX_DIMENSIONS_NUM && ` +${budgetDimensionArray.length - MAX_DIMENSIONS_NUM}`}
        </>
      ),
      loading: isBudgetUnavailable,
    },
  ];

  const currentPeriodItems = [
    {
      term: budgetTxt.BUDGET_AMOUNT_TO_DATE_TERM,
      value: formatBudgetValue(budgetAmountToDate),
      loading: isRefreshedBudgetLoading,
    },
    { term: budgetTxt.ACTUALS_TO_DATE, value: formatBudgetValue(actual), loading: isRefreshedBudgetLoading },
    {
      term: budgetTxt.VARIANS_TO_DATE,
      value: variance ? (
        <span style={{ color: variance < 0 ? varianceNegativeColor : variancePositiveColor }}>
          {formatBudgetValue(variance)}
        </span>
      ) : (
        budgetTxt.DATA_LOADING
      ),
      loading: isRefreshedBudgetLoading,
    },
    {
      term: budgetTxt.FORECASTED_AMOUNT_FOR_ENTIRE_PERIOD,
      value: formatBudgetValue(forecastAmountForEntirePeriod),
      loading: isRefreshedBudgetLoading,
    },
    {
      term: budgetTxt.FORECASTED_TO_HIT_BUDGET_AMOUNT,
      value: forecastedDateSeconds
        ? isForecastedDateInBudgetRange(forecastedDateSeconds)
          ? DateTime.fromSeconds(forecastedDateSeconds).toFormat("d MMMM yyyy")
          : budgetTxt.NO_FORECASTED_DATE
        : budgetTxt.NO_FORECASTED_DATE,
      loading: isRefreshedBudgetLoading,
    },
  ];

  return (
    <>
      <ViewBudgetHeader name={budget?.data.name ?? ""} />
      <Stack
        data-cy="view-budget-content"
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          gap: 2.4,
        }}
      >
        {chartContent}
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{
            justifyContent: "stretch",
          }}
        >
          <MetadataCard title={budgetTxt.BUDGET_SUMMARY} error={isBudgetEmpty} errorContent={errorContent}>
            <DefinitionList>{renderDefinitionListItems(budgetSummaryItems)}</DefinitionList>
          </MetadataCard>

          <MetadataCard title={budgetTxt.CURRENT_PERIOD} error={isBudgetEmpty} errorContent={errorContent}>
            <DefinitionList>{renderDefinitionListItems(currentPeriodItems)}</DefinitionList>
          </MetadataCard>
        </Stack>
      </Stack>
    </>
  );
};
