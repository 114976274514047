import { useState } from "react";

import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Popover, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { alertTexts, cloudAnalyticsText, globalText } from "../../assets/texts";
import { useGKEAdoption } from "../../Components/hooks/cloudAnalytics/useGKEAdoption";
import useGKECostAllocation from "../../Components/hooks/cloudAnalytics/useGKECostAllocation";
import { LearnMoreAlert } from "./LearnMoreAlert";

const classes = {
  cardRoot: {
    border: "none",
    boxShadow: "none",
    pb: 0,
    height: 1,
  },
  cardHeader: {
    pb: 0,
    pt: 0,
    height: 0.15,
    pl: 4,
  },
  popoverPaper: {
    width: "600px",
    height: "401px",
  },
  subHeader: {
    ml: 1,
  },
  cardContent: {
    alignSelf: "stretch",
    height: "288px",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    pl: 3,
  },
  clustersList: {
    mt: 2,
    mb: 0,
    overflow: "auto",
    listStyleType: "disc",
    pl: 4,
    "& .MuiListItem-root": {
      display: "list-item",
    },
  },
  cardActions: {
    alignItems: "center",
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    pt: 1,
    borderTop: "1px solid #0000001F",
    height: 0.14,
  },
  listItem: {
    display: "list-item",
    listStyleType: "disc",
    pl: 1,
  },
  actionButton: {
    mr: 1,
  },
};

const GkePartialCostAllocationAlert = () => {
  const [gkeCostAllocation] = useGKECostAllocation();
  const [gkeAdoption] = useGKEAdoption();
  const [showClusters, setShowClusters] = useState(false);

  const handleShowClustersClick = () => {
    setShowClusters(true);
  };

  const onCloseShowClusters = () => {
    setShowClusters(false);
  };

  const showAlert =
    gkeAdoption?.timeFirstCompleted === null &&
    gkeCostAllocation?.enabled &&
    gkeCostAllocation?.unenabledClusters?.length > 0;

  if (!showAlert) {
    return null;
  }

  return (
    <Grid
      size={{
        sm: 12,
      }}
    >
      <LearnMoreAlert
        text={cloudAnalyticsText.GKE_COST_ALLOCATION_PARTIALLY_ENABLED_TEXT}
        btnText=""
        onClickInner={handleShowClustersClick}
        innerBtnText={cloudAnalyticsText.GKE_COST_ALLOCATION_ALERT_BTN_TEXT}
      />
      <Popover
        id="instance-details-popover"
        open={showClusters}
        onClose={onCloseShowClusters}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        PaperProps={{
          sx: classes.popoverPaper,
        }}
      >
        <Card square={true} sx={classes.cardRoot}>
          <CardHeader
            sx={classes.cardHeader}
            title={cloudAnalyticsText.GKE_COST_ALLOCATION_PARTIALLY_ENABLED_TITLE}
            titleTypographyProps={{ variant: "h3" }}
          />
          <CardContent sx={classes.cardContent}>
            <Typography key="clusters_bub_title" variant="body1" sx={classes.subHeader}>
              {cloudAnalyticsText.GKE_COST_ALLOCATION_PARTIALLY_ENABLED_SUBTITLE}
            </Typography>
            <Box component="ul" data-testid="clusters-list" sx={classes.clustersList}>
              {gkeCostAllocation.unenabledClusters.map((c) => (
                <Box component="li" key={`item-${c}`} sx={classes.listItem}>
                  {c}
                </Box>
              ))}
            </Box>
          </CardContent>
          <CardActions sx={classes.cardActions}>
            <Button
              key="learn_more_link"
              variant="text"
              color="primary"
              size="large"
              target="_blank"
              href={cloudAnalyticsText.GKE_COST_ALLOCATION_ENABLE_ALERT_HREF}
              startIcon={<OpenNewIcon />}
              sx={classes.actionButton}
            >
              {alertTexts.LEARN_MORE}
            </Button>
            <Button variant="contained" color="primary" size="medium" onClick={onCloseShowClusters}>
              {globalText.CLOSE}
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </Grid>
  );
};

export default GkePartialCostAllocationAlert;
