import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsResourceType } from "@doitintl/cmp-models";
import { Box, Button, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import eq from "lodash/eq";

import { globalText, reportText } from "../../../assets/texts";
import GkePartialCostAllocationAlert from "../../../Components/Alerts/GkePartialCostAllocationAlert";
import DeleteDialog from "../../../Components/DeleteDialog";
import { DoitConsoleTitle } from "../../../Components/DoitConsoleTitle";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import Hide from "../../../Components/HideChildren/Hide";
import { useCloudAnalyticsStatus } from "../../../Components/hooks/cloudAnalytics/reports/useCloudAnalyticsStatus";
import { useCAOwnerAssignerCheck } from "../../../Components/hooks/useCAOwnerAssignerCheck";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { useAuthContext } from "../../../Context/AuthContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { type ReportWSnap } from "../../../types";
import mixpanel from "../../../utils/mixpanel";
import { addAnalyticsWindowFunction } from "../../../utils/windowInit";
import { reportsToAnalyticsResources } from "../analyticsResources/utils";
import ShareDialog from "../dialogs/shareDialog/ShareDialog";
import AssignLabelsButton from "../labels/components/AssignLabelsButton";
import { useLabels } from "../labels/hooks";
import { useNewReportHandler } from "../report/hooks";
import ReportStatus from "../statuses/ReportStatus";
import { CloudAnalyticsEntities, isEditor } from "../utilities";
import { filterColumns, headers } from "./Columns";
import { useDeleteReportsHandler, useShareReportsHandler } from "./hooks";
import { ReportRow } from "./ReportRow";

const { report: reportIds } = cloudAnalytics;

type ReportBrowserProps = {
  reports: ReportWSnap[];
  loading: boolean;
};

const ReportBrowser = ({ reports, loading }: ReportBrowserProps) => {
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const routeMatchURL = useRouteMatchURL();
  const isEntitledAnalyticsReports = useIsFeatureEntitled("analytics:reports");
  const [selectedReports, setSelectedReports] = useState<ReportWSnap[]>([]);
  // State for share dialog
  const [clickedRow, setClickedRow] = useState<ReportWSnap>({} as ReportWSnap);
  // Dialog states
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [bulkShareDialogOpen, setBulkShareDialogOpen] = useState(false);
  const [shareDialogLoading, setShareDialogLoading] = useState(false);
  const [deleteDialog, setOpenDeleteDialog] = useState(false);
  const [labels, labelsLoading] = useLabels();

  const [status] = useCloudAnalyticsStatus();

  useEffect(() => {
    const updatedSelectedReports = reports.filter((report) =>
      selectedReports.some((x) => x.snapshot.id === report.snapshot.id)
    );

    setSelectedReports(updatedSelectedReports);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  const selectedReportsWithoutPresets = useMemo(
    () => selectedReports.filter((x) => x.data.type !== "preset"),
    [selectedReports]
  );

  const handleChangeSharing = useShareReportsHandler({
    selected: selectedReportsWithoutPresets.length ? selectedReportsWithoutPresets : [clickedRow],
    setShareDialogLoading,
    closeDialog: () => {
      setBulkShareDialogOpen(false);
      setShareDialogLoading(false);
      setShareDialogOpen(false);
    },
  });

  const handleNewReport = useNewReportHandler({ baseUrl: routeMatchURL, mixpanelEventName: "analytics.reports.new" });

  useEffect(() => {
    addAnalyticsWindowFunction({ handleNewReport });
  }, [handleNewReport]);

  const handleDeleteReport = useDeleteReportsHandler({ reports: selectedReports, setSelectedReports });

  useEffect(() => {
    mixpanel.track("analytics.reports.list");
  }, []);

  const ReportRowWrapper = useCallback(
    ({ row }: { row: ReportWSnap }) => (
      <ReportRow
        setOpenDeleteDialog={setOpenDeleteDialog}
        row={row}
        labels={labels}
        setClickedRow={setClickedRow}
        setShareDialogOpen={setShareDialogOpen}
        setSelectedReports={setSelectedReports}
      />
    ),
    [labels, setOpenDeleteDialog, setClickedRow, setShareDialogOpen, setSelectedReports]
  );

  const disableDelete = useMemo(
    () => !selectedReports.length || selectedReports?.some((report) => report.data.owner !== currentUser.email || ""),
    [currentUser.email, selectedReports]
  );

  const selectedReportsHaveSameOrganization = useMemo<boolean>(
    () =>
      selectedReportsWithoutPresets.length === 0 ||
      selectedReportsWithoutPresets.every((report) =>
        eq(report.data.organization, selectedReportsWithoutPresets[0].data.organization)
      ),
    [selectedReportsWithoutPresets]
  );

  const isCAOwnershipAssigner = useCAOwnerAssignerCheck();

  const canEditSelectedReports = useMemo<boolean>(
    () =>
      selectedReportsWithoutPresets.every((s) => isEditor(currentUser.email, s.data)) &&
      selectedReportsWithoutPresets.length > 0 &&
      selectedReportsHaveSameOrganization,
    [selectedReportsWithoutPresets, selectedReportsHaveSameOrganization, currentUser.email]
  );

  const canEditPermissions = useMemo<boolean>(
    () => canEditSelectedReports || (isCAOwnershipAssigner && selectedReportsWithoutPresets.length > 0),
    [canEditSelectedReports, isCAOwnershipAssigner, selectedReportsWithoutPresets.length]
  );

  const deleteTooltip = useMemo(() => {
    if (selectedReports?.some((report) => report.data.type === AnalyticsResourceType.PRESET)) {
      return reportText.REPORT_PRESET_NOT_DEL;
    }
    if (selectedReports?.some((report) => report.data.owner !== currentUser.email)) {
      return reportText.REPORT_NOT_OWNER;
    }
    return "";
  }, [currentUser.email, selectedReports]);

  const shareDialogOrganization = useMemo(() => {
    if (selectedReports.length > 0 && bulkShareDialogOpen) {
      return selectedReports[0].data.type === AnalyticsResourceType.CUSTOM
        ? selectedReports[0].data?.organization
        : null;
    }

    return clickedRow.data?.type === AnalyticsResourceType.CUSTOM ? clickedRow.data?.organization : null;
  }, [bulkShareDialogOpen, clickedRow.data?.organization, clickedRow.data?.type, selectedReports]);

  const loadingPage = useMemo(
    () => (
      <>
        <DoitConsoleTitle pageName="Reports" />
        <Box
          sx={{
            p: 1,
          }}
        >
          <FilterTableSkeleton />
        </Box>
      </>
    ),
    []
  );

  if (labelsLoading) {
    return loadingPage;
  }

  if (!labels) {
    return null;
  }

  return (
    <>
      {loading ? (
        loadingPage
      ) : (
        <>
          <DoitConsoleTitle pageName="Reports" />
          <GkePartialCostAllocationAlert />
          <FilterTable<ReportWSnap>
            showRowsSelection={true}
            onRowsSelected={setSelectedReports}
            tableItems={reports}
            rowComponent={ReportRowWrapper}
            headerColumns={headers}
            filterColumns={filterColumns}
            filterBarPlaceholder={reportText.FILTER_REPORTS}
            persistenceKey="cloud_analytics_reports_v1"
            itemUniqIdentifierField="snapshot.id"
            defaultSortingColumnValue="data.timeModified"
            toolbarProps={{
              title: "Reports",
            }}
          >
            <Grid>
              <Tooltip title={deleteTooltip}>
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpenDeleteDialog(true);
                    }}
                    disabled={disableDelete}
                    data-testid="report-browser-delete"
                    data-cy={reportIds.browser.delete}
                  >
                    {globalText.DELETE}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid>
              <AssignLabelsButton
                labels={labels}
                selectedResources={reportsToAnalyticsResources(selectedReports)}
                disabled={!canEditSelectedReports}
              />
            </Grid>
            <Grid>
              <Tooltip title={selectedReportsHaveSameOrganization ? "" : "All reports must have the same organization"}>
                <span>
                  <Button
                    variant="text"
                    onClick={() => {
                      setBulkShareDialogOpen(true);
                    }}
                    disabled={!canEditPermissions}
                  >
                    {globalText.EDIT_PERMISSIONS}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid data-cy={reportIds.browser.explore}>
              <Hide mdDown>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleNewReport}
                  data-testid="new-report-button"
                  disabled={!isDoitEmployee && !isEntitledAnalyticsReports}
                >
                  {reportText.CREATE_NEW_REPORT}
                </Button>
              </Hide>
            </Grid>
          </FilterTable>
          <ReportStatus key="status-dialog" statusSnap={status} />
        </>
      )}
      {deleteDialog && (
        <DeleteDialog
          open={deleteDialog}
          title={reportText.DELETE_SELECTED}
          message={reportText.DELETE_MESSAGE}
          onDelete={handleDeleteReport}
          onClose={() => {
            setOpenDeleteDialog(false);
          }}
        />
      )}
      {(shareDialogOpen || bulkShareDialogOpen) && (
        <ShareDialog
          open={shareDialogOpen || bulkShareDialogOpen}
          onClose={() => {
            setShareDialogOpen(false);
            setBulkShareDialogOpen(false);
          }}
          title={reportText.SHARE_PERMISSIONS}
          entity={CloudAnalyticsEntities.REPORT}
          handleChangeSharing={handleChangeSharing}
          loading={shareDialogLoading}
          organization={shareDialogOrganization}
          shareEntities={bulkShareDialogOpen ? selectedReportsWithoutPresets.map((x) => x.data) : [clickedRow.data]}
          showPresetExcludedAlert={selectedReportsWithoutPresets.length !== selectedReports.length}
        />
      )}
    </>
  );
};

export default ReportBrowser;
