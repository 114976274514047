import { type JSX } from "react";

import { Check } from "@mui/icons-material";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";

import { useFullScreen } from "../../../utils/dialog";
import { type PackageType, type ServiceType } from "../types";
import { CustomCard } from "./CustomCard";
import { PackageName } from "./PackageName";

type Props = {
  packageType: PackageType;
  onClick: () => void;
  active: boolean;
  subtitle?: string;
  amount: string;
  plus?: string;
  serviceList: (JSX.Element | string)[];
  note: string;
  selectedServiceType: ServiceType;
};

export const PackageCard = ({
  packageType,
  subtitle,
  amount,
  plus,
  onClick,
  active,
  serviceList,
  note,
  selectedServiceType,
}: Props) => {
  const { isMobile } = useFullScreen("md");
  return (
    <CustomCard active={active} onClick={onClick} sx={{ minWidth: 282, height: { md: 524 } }}>
      <Stack
        sx={{
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <PackageName packageType={packageType} />
          {!!subtitle && (
            <Typography
              variant="caption"
              sx={{
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              {subtitle}
            </Typography>
          )}
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "end",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: 36,
                textAlign: "center",
              }}
            >
              {amount}
            </Typography>
            {!!plus && (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "end",
                }}
              >
                <Typography variant="h4">plus</Typography>
                <Tooltip title={`This ${plus} is based on your monthly cloud spend`}>
                  <Typography variant="h4" sx={{ textDecoration: "underline", textDecorationStyle: "dotted" }}>
                    {plus}
                  </Typography>
                </Tooltip>
              </Stack>
            )}
          </Stack>
          <Typography
            variant="caption"
            sx={{
              textAlign: "center",
              color: "text.secondary",
            }}
          >
            {selectedServiceType === "subscription" ? "per month" : "Single payment"}
          </Typography>
          {!isMobile && (
            <Stack direction="column" spacing={1}>
              {serviceList.map((service, i) => (
                <Stack key={i} direction="row" spacing={1.2}>
                  <Check fontSize="small" sx={{ color: "success.light" }} />
                  <Box>{service}</Box>
                </Stack>
              ))}
            </Stack>
          )}
          {!isMobile && (
            <Typography
              variant="caption"
              sx={{
                textAlign: "center",
                color: "text.secondary",
                pt: 2,
              }}
            >
              {note}
            </Typography>
          )}
        </Stack>
        <Button variant="text">Learn more</Button>
      </Stack>
    </CustomCard>
  );
};
