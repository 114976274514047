import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";

export const BulkRefreshLoading = () => (
  <Stack
    sx={{
      justifyContent: "center",
      alignItems: "center",
      py: 20,
      gap: 2,
    }}
  >
    <CircularProgress />
    <Stack
      sx={{
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
        }}
      >
        Data refresh in progress
      </Typography>
      <Typography>This can take up to 3 minutes</Typography>
    </Stack>
  </Stack>
);
