import { useEffect, useMemo, useState } from "react";

import { Alert, Box, Card, CardContent, Portal, Typography } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";

import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import mixpanel from "../../../../utils/mixpanel";
import { setFirstTimeStartedToUseSpots, updateKeepAsgUpToDate } from "../../db";
import { type SpotScalingApi } from "../../spotScalingApi";
import { applyRecommendationsStrings } from "../../strings";
import {
  type AsgBasicInfo,
  type AsgConfigChange,
  type AsgConfigurationSummary,
  type AsgItem,
  type KeepUptoDate,
} from "../../types";
import { getPotentialSavings } from "../../Utils/costsUtils";
import { AsgConfiguration } from "../AsgConfiguration/AsgConfiguration";
import { AsgsConfigurationTitles } from "../AsgConfiguration/AsgsConfigurationTitles";
import { useCommonHeights } from "../AsgConfiguration/hooks";

export type AsgCompareCosts = {
  current: number;
  ratio: number;
  savings: number;
};

export type AsgItemWithMandatoryRecommendation = Pick<
  AsgItem,
  "id" | "accountId" | "region" | "asgName" | "isApplyChangesSupported" | "config"
> & {
  configurations: {
    current: AsgConfigurationSummary;
    recommended: AsgConfigurationSummary;
  };
};

type Props = {
  spotScalingApi: SpotScalingApi;
  asg: AsgItemWithMandatoryRecommendation;
  notificationContainer: any;
};

enum DisplayState {
  ShowRecommendations,
  ApplyRecommendation,
  ApplyFailed,
  ApplySuccessfullyFinished,
}

export const AsgNotOptimized = ({ spotScalingApi, asg, notificationContainer }: Props) => {
  const asgInfo: AsgBasicInfo = {
    accountId: asg.accountId,
    region: asg.region,
    asgName: asg.asgName,
  };

  const [instanceTypeHeights, setInstanceTypeHeights] = useCommonHeights();
  const [azHeights, setAzHeights] = useCommonHeights();
  const [configChange, setConfigChange] = useState<AsgConfigChange | undefined>(undefined);
  const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.ShowRecommendations);

  const [keepUptoDate, setKeepUptoDate] = useState<KeepUptoDate>({
    valueDirty: false,
    value: asg?.config?.keepUpToDate,
    origValue: asg?.config?.keepUpToDate,
  });

  const snackbar = useSnackbar();

  const applyDisabled = useMemo(() => {
    let disabled =
      displayState === DisplayState.ApplySuccessfullyFinished || displayState === DisplayState.ApplyRecommendation;
    if (keepUptoDate.valueDirty) {
      disabled = false;
    }
    return disabled;
  }, [displayState, keepUptoDate]);

  const [potentialSavings, setPotentialSavings] = useState<AsgCompareCosts>({
    current: 0,
    ratio: 0,
    savings: 0,
  });

  useEffect(() => {
    const currentCost = asg.configurations.current.monthlyCost;
    const tmpAsg: AsgItemWithMandatoryRecommendation = cloneDeep(asg);
    tmpAsg.configurations.recommended.onDemandPercentageAboveBaseCapacity =
      configChange?.onDemandPercentageAboveBaseCapacity === undefined
        ? asg.configurations.recommended.onDemandPercentageAboveBaseCapacity
        : configChange?.onDemandPercentageAboveBaseCapacity;
    const savings = Math.round(getPotentialSavings(tmpAsg as AsgItem));
    const ratio = Math.round((savings * 100) / currentCost);
    const newSavings = {
      current: currentCost,
      ratio: ratio > 0 ? ratio : 0,
      savings: savings > 0 ? savings : 0,
    };
    setPotentialSavings(newSavings);
  }, [asg, configChange?.onDemandPercentageAboveBaseCapacity]);

  const handleApplyClick = async () => {
    mixpanel.track("spot-scaling.apply-recommendations");
    if (keepUptoDate.value) {
      mixpanel.track("spot-scaling.automatically-update");
    }
    setDisplayState(DisplayState.ApplyRecommendation);
    if (keepUptoDate.valueDirty) {
      await updateKeepAsgUpToDate(asg.id, keepUptoDate.value);
      setKeepUptoDate({ valueDirty: false, value: keepUptoDate.value, origValue: keepUptoDate.value });
    }

    const applySuccess = await spotScalingApi.applyRecommendation(asgInfo, configChange);

    // in case this ASG was 100 percents on-demand before, save date that will be used as start point for statistics
    if (asg.configurations.current.onDemandPercentageAboveBaseCapacity === 100) {
      await setFirstTimeStartedToUseSpots(asg.id);
    }

    if (applySuccess) {
      mixpanel.track("spot-scaling.asg-optimization status");
      setDisplayState(DisplayState.ApplySuccessfullyFinished);
      snackbar.onOpen({
        message: applyRecommendationsStrings.SuccessfullyApplied,
        variant: "success",
        withClose: true,
      });
    } else {
      setDisplayState(DisplayState.ApplyFailed);
      snackbar.onOpen({
        message: applyRecommendationsStrings.FailedApply,
        variant: "error",
        withClose: true,
      });
    }
  };

  return (
    <>
      <Portal container={notificationContainer.current}>
        {(() => {
          switch (displayState) {
            case DisplayState.ShowRecommendations:
              return (
                <Alert severity="info">
                  Based on your Auto Scaling Group configuration, we have identified opportunity to save an estimated $
                  {potentialSavings.savings} on a monthly basis, representing a savings of {potentialSavings.ratio}%
                  versus on-demand costs.
                </Alert>
              );
          }
        })()}
      </Portal>
      <Card>
        <CardContent>
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Typography variant="h6">Recommendations</Typography>
          </Box>

          {asg.configurations.recommended && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <AsgsConfigurationTitles instanceTypeHeights={instanceTypeHeights} azHeights={azHeights} />
              {displayState === DisplayState.ApplySuccessfullyFinished ? (
                <AsgConfiguration
                  title="Current Values"
                  confKey="current"
                  instanceTypeHeights={instanceTypeHeights}
                  setInstanceTypeHeights={setInstanceTypeHeights}
                  azHeights={azHeights}
                  setAzHeights={setAzHeights}
                  asgId={asg.id}
                  allowEdit={false}
                  configuration={asg.configurations.recommended}
                  isApplyChangesSupported={asg.isApplyChangesSupported}
                  isOptimized={false}
                  isCurrent={true}
                  keepUptoDate={keepUptoDate}
                  keepUptoDateCallback={setKeepUptoDate}
                />
              ) : (
                <>
                  <AsgConfiguration
                    title="Current Values"
                    confKey="current"
                    instanceTypeHeights={instanceTypeHeights}
                    setInstanceTypeHeights={setInstanceTypeHeights}
                    azHeights={azHeights}
                    setAzHeights={setAzHeights}
                    allowEdit={false}
                    configuration={asg.configurations.current}
                    asgId={asg.id}
                    isApplyChangesSupported={asg.isApplyChangesSupported}
                    isOptimized={false}
                    isCurrent={true}
                    keepUptoDate={keepUptoDate}
                    keepUptoDateCallback={setKeepUptoDate}
                  />
                  <AsgConfiguration
                    title="Recommended values"
                    confKey="recommended"
                    instanceTypeHeights={instanceTypeHeights}
                    setInstanceTypeHeights={setInstanceTypeHeights}
                    azHeights={azHeights}
                    setAzHeights={setAzHeights}
                    allowEdit
                    configuration={asg.configurations.recommended}
                    onConfigurationChanged={setConfigChange}
                    spotScalingApi={spotScalingApi}
                    asgInfo={asgInfo}
                    actionButtonProps={{
                      text: "Apply Recommendations",
                      loading: displayState === DisplayState.ApplyRecommendation,
                      disabled: applyDisabled,
                      onClick: handleApplyClick,
                    }}
                    asgId={asg.id}
                    isApplyChangesSupported={asg.isApplyChangesSupported}
                    isCurrent={false}
                    isOptimized={false}
                    keepUptoDate={keepUptoDate}
                    keepUptoDateCallback={setKeepUptoDate}
                  />
                </>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};
