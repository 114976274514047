import LaunchIcon from "@mui/icons-material/Launch";
import { Link, Stack, Typography } from "@mui/material";

import { helpURLs } from "../../../assets/urls";

export const BillCom = ({ showPaymentMethodType }: { showPaymentMethodType?: boolean }) => (
  <Stack
    spacing={1}
    sx={{
      alignItems: "flex-start",
    }}
  >
    {showPaymentMethodType && (
      <Stack direction="row" spacing={1}>
        <Typography
          sx={{
            fontWeight: "500",
          }}
        >
          Payment method:
        </Typography>
        <Typography>Bill.com</Typography>
      </Stack>
    )}
    <Stack direction="row" spacing={1}>
      <Typography
        sx={{
          fontWeight: 500,
        }}
      >
        Payment Network ID:
      </Typography>
      <Typography>0190518948494939</Typography>
    </Stack>

    <Link href={helpURLs.CONNECT_BILL_COM} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none" }}>
      Bill.com help documentation&nbsp;
      <LaunchIcon sx={{ verticalAlign: "sub" }} fontSize="small" />
    </Link>
  </Stack>
);
