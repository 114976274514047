import React from "react";

import { customPeriodUnits } from "@doitintl/cmp-models/src/Notifications";
import { Autocomplete, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useField } from "formik";

import { useSetFieldValueTyped } from "../helpers";
import { type SubscriptionFormValues, type SubscriptionInputProps } from "../types";

export function CustomPeriodInput({ disabled }: SubscriptionInputProps) {
  const [frequency] = useField<SubscriptionFormValues["deliveryFrequency"]>("deliveryFrequency");
  const [customPeriodAmount, customPeriodAmountMata] =
    useField<SubscriptionFormValues["customPeriodAmount"]>("customPeriodAmount");
  const [customPeriodUnit, customPeriodUnitMeta] =
    useField<SubscriptionFormValues["customPeriodUnit"]>("customPeriodUnit");

  const setFieldValueTyped = useSetFieldValueTyped();

  if (frequency.value !== "Custom") {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid size={6}>
        <TextField
          label="Repeats every"
          type="number"
          variant="outlined"
          fullWidth
          error={!!customPeriodAmountMata.error}
          helperText={customPeriodAmountMata.error ? "Enter a number" : undefined}
          disabled={disabled}
          value={customPeriodAmount.value}
          onChange={(event) => {
            const amountAsNumber = Number(event.target.value);
            if (Number.isNaN(amountAsNumber) || amountAsNumber < 1) {
              return;
            }

            setFieldValueTyped("customPeriodAmount", amountAsNumber);
          }}
        />
      </Grid>
      <Grid size={6}>
        <Autocomplete
          options={customPeriodUnits}
          disabled={disabled}
          disableClearable={true}
          value={customPeriodUnit.value || undefined}
          getOptionLabel={(option) => (customPeriodAmount.value === 1 ? option : `${option}s`)}
          onChange={(_event, newValue) => {
            if (!newValue) {
              setFieldValueTyped("customPeriodUnit", null);
            }
            setFieldValueTyped("customPeriodUnit", newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Frequency"
              variant="outlined"
              error={!!customPeriodUnitMeta.error}
              helperText={customPeriodUnitMeta.error ? "Select a unit" : undefined}
              fullWidth
            />
          )}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
