import { type JSX } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Stack } from "@mui/material";

export const NotApplicable = "N/A";
export const NotAvailable = "-";
export const linkLabelWithIcon = (linkText: string): JSX.Element => (
  <Stack
    direction="row"
    sx={{
      alignItems: "center",
      gap: 1,
    }}
  >
    {linkText}
    <OpenInNewIcon
      sx={{
        width: 14,
        height: 14,
      }}
    />
  </Stack>
);
