import { type ChangeEvent, useCallback, useMemo, useState } from "react";

import { FixedSizeList, type ListChildComponentProps } from "react-window";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import { useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCurrentDashboardContext } from "../../../../../Context/CurrentDashboardContext";
import { type WidgetDescription } from "../../../../../Context/DashboardContext";
import { AddReportCard } from "./AddReportCard";

type Props = {
  open: boolean;
  loading: boolean;
  availableWidgets: WidgetDescription[];
  selectedWidgetNames: string[];
  onClose: () => void;
};

export function AddReportDialog({ open, loading, availableWidgets, selectedWidgetNames, onClose }: Props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const selectedNamesSet = useMemo(() => new Set(selectedWidgetNames), [selectedWidgetNames]);
  const successSnackbar = useSuccessSnackbar();

  const { addWidgetToDashboard } = useCurrentDashboardContext();

  const handleAddWidgets = useCallback(() => {
    selected.forEach((name) => {
      addWidgetToDashboard(name);
    });
    successSnackbar("Report(s) successfully added to dashboard");
    onClose();
  }, [addWidgetToDashboard, onClose, selected, successSnackbar]);

  const handleCardClicked = useCallback(
    (id: string) => {
      const newSet = new Set(selected);
      if (selected.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      setSelected(newSet);
    },
    [selected]
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value?.toLowerCase());
  };

  const filteredReports = useMemo(() => {
    let widgetsToReOrder = availableWidgets;
    if (searchQuery) {
      widgetsToReOrder = availableWidgets.filter((reportWidget) => {
        const title = reportWidget.title.toLowerCase();
        const description = reportWidget.description.toLowerCase();
        return title.includes(searchQuery) || description.includes(searchQuery);
      });
    }

    // move widgets from the selected to the end of the array
    const selectedReports = widgetsToReOrder.filter((reportWidget) => selectedNamesSet.has(reportWidget.name));
    const otherReports = widgetsToReOrder.filter((reportWidget) => !selectedNamesSet.has(reportWidget.name));
    return [...otherReports, ...selectedReports];
  }, [availableWidgets, searchQuery, selectedNamesSet]);

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;

    const reportWidget = filteredReports[index];

    return (
      <Box
        style={style}
        key={reportWidget.name}
        sx={{
          pl: 1.3,
          pb: 2,
          height: 110,
        }}
      >
        <AddReportCard
          disabled={selectedNamesSet.has(reportWidget.name)}
          widget={reportWidget}
          selected={selected.has(reportWidget.name)}
          onClick={handleCardClicked}
        />
      </Box>
    );
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle sx={{ m: 0, px: 3, py: 2 }}>Add existing reports to your dashboard</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            minHeight: 80,
          }}
        >
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search reports"
            autoFocus
            onChange={onChange}
            sx={{
              pb: 2,
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start" component="div">
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              pb: 2,
            }}
          >
            {selected.size} reports selected
          </Typography>
        </Box>
        <Box
          sx={{
            height: 400,
            overflowY: "auto",
          }}
        >
          {loading && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <FixedSizeList height={400} width="100%" itemSize={110} itemCount={filteredReports.length} overscanCount={10}>
            {renderRow}
          </FixedSizeList>
          {filteredReports.length === 0 && !loading && (
            <Typography
              variant="body2"
              align="center"
              sx={{
                color: "text.secondary",
              }}
            >
              No reports found matching your search
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={selected.size === 0} onClick={handleAddWidgets}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
