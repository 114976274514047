import { useCallback, useEffect, useMemo, useState } from "react";

import { type KnownIssuePlatforms, TimeInterval as TI, TimeSettingsMode } from "@doitintl/cmp-models";
import { Box, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import startCase from "lodash/startCase";
import { DateTime } from "luxon";

import MultiselectGroupedCheckboxes from "../../../Components/MultiSelectAutocompleteWithCheckboxes/MultiselectCheckboxes";
import { type TimeRangeOption } from "../../../Pages/CloudAnalytics/utilities";
import { type KnownIssue } from "../../../Pages/KnownIssues/types";
import { type CustomTimeRange } from "../../../types";
import { useFullScreen } from "../../../utils/dialog";
import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";
import { getHighchartsOptions } from "./funcs";
import { useCalculateKnownIssuesDownTime } from "./hooks";
import SimpleTimeSelection from "./SimpleTimeSelection";
import { type ProductsFilterMultiSelectProductOption, type TimeIntervalName } from "./types";

const defaultTimeRangeOption: TimeRangeOption = {
  mode: TimeSettingsMode.Last,
  amount: 7,
  time: TI.DAY,
  includeCurrent: true,
};

const defaultMobileTimeRangeOption: TimeRangeOption = {
  mode: TimeSettingsMode.Last,
  amount: 2,
  time: TI.DAY,
  includeCurrent: true,
};

const KnownIssuesUptimeChart = ({
  applyRegionCheck,
}: {
  applyRegionCheck: ((issues: KnownIssue[]) => KnownIssue[]) | null;
}) => {
  const { isMobile } = useFullScreen();
  const [timeInterval, setTimeInterval] = useState<TimeIntervalName>("Hour");
  const [productFilters, setProductFilters] = useState<string[]>([]);
  const [timeRangeOption, setTimeRangeOption] = useState<TimeRangeOption>(defaultTimeRangeOption);
  const [customTimeRange, setCustomTimeRange] = useState<CustomTimeRange | null>({
    to: DateTime.now().toUTC(),
    from: DateTime.now().minus({ days: defaultTimeRangeOption.amount }).toUTC(),
  });

  const { data, allAffectedProducts, updateStartEndTimes } = useCalculateKnownIssuesDownTime(
    customTimeRange?.from as DateTime,
    customTimeRange?.to as DateTime,
    timeInterval,
    productFilters,
    applyRegionCheck
  );

  // "isMobile" doesn't detect true instantly
  useEffect(() => {
    if (isMobile) {
      setTimeRangeOption(defaultMobileTimeRangeOption);
      setCustomTimeRange({
        to: DateTime.now().toUTC(),
        from: DateTime.now().minus({ days: defaultMobileTimeRangeOption.amount }).toUTC(),
      });
    } else {
      setTimeRangeOption(defaultTimeRangeOption);
      setCustomTimeRange({
        to: DateTime.now().toUTC(),
        from: DateTime.now().minus({ days: defaultTimeRangeOption.amount }).toUTC(),
      });
    }
  }, [isMobile]);

  const isDarkMode = useDarkThemeCheck();
  const highchartsOptions = useMemo(
    () => getHighchartsOptions(isDarkMode, data, timeInterval),
    [data, isDarkMode, timeInterval]
  );

  const handleTimeIntervalChange = useCallback((newTimeInterval: TimeIntervalName) => {
    setTimeInterval(newTimeInterval);
  }, []);

  useEffect(() => {
    if (!customTimeRange) {
      return;
    }
    if (DateTime.isDateTime(customTimeRange?.from) && DateTime.isDateTime(customTimeRange?.to)) {
      updateStartEndTimes(customTimeRange.from, customTimeRange.to);
    }
  }, [customTimeRange, updateStartEndTimes]);

  const optionDisplayLabel = (option: ProductsFilterMultiSelectProductOption) => option.product;
  const renderTags = (selectedValues: ProductsFilterMultiSelectProductOption[]) => {
    let output = "";
    if (selectedValues.length === allAffectedProducts.length && selectedValues.length > 0) {
      return (
        <Typography
          sx={{
            paddingLeft: 1,
          }}
        >
          All Products
        </Typography>
      );
    }
    if (selectedValues.length >= 1) {
      output += optionDisplayLabel(selectedValues[0]);
    }
    if (selectedValues.length > 1) {
      output += ` +${selectedValues.length - 1}`;
    }
    return (
      <Typography
        sx={{
          paddingLeft: 1,
        }}
      >
        {output}
      </Typography>
    );
  };

  return (
    <Stack>
      <Box sx={{ ml: 0.5, mt: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            ml: 1.5,
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h3">Cloud infrastructure availability</Typography>
            <Typography variant="body2" color="textSecondary">
              The graph below shows reported cloud incidents relating to your cloud infrastructure
            </Typography>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: isMobile ? "column" : "row",
              pr: 3,
              mt: isMobile ? 1 : 0,
            }}
          >
            <MultiselectGroupedCheckboxes<ProductsFilterMultiSelectProductOption>
              options={allAffectedProducts}
              optionDisplayLabel={optionDisplayLabel}
              optionGroupBy={(option) => startCase(option.platform.replace("-", " "))}
              onChange={(v) => {
                const actualFilterValues = v.map((filter) => filter.product);
                setProductFilters(actualFilterValues);
              }}
              fieldLabel="Filter by product"
              selectAllOption={{ product: "Select all", platform: "" as KnownIssuePlatforms }}
              renderTags={renderTags}
              defaultSelectAll={true}
            />

            <SimpleTimeSelection
              handleChangeTimeInterval={handleTimeIntervalChange}
              setCustomTimeRange={setCustomTimeRange}
              timeInterval={timeInterval}
              queryRunning={false}
              excludePartialData={false}
              timeRangeOption={timeRangeOption}
              setTimeRangeOption={setTimeRangeOption}
              onExcludePartialChange={() => {}}
              customTimeRange={customTimeRange}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 2, minHeight: 280 }}>
          <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
        </Box>
      </Box>
    </Stack>
  );
};

export default KnownIssuesUptimeChart;
