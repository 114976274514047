import { useCallback, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton, Link, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";

import { attributionGroupsText, globalText } from "../../../assets/texts";
import { DraggableItemHolder } from "../../../Components/DragAndDrop/DraggableItemHolder";
import { AttributionSelect } from "../../../Components/Selects/CloudAnalytics/AttributionSelect";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type AttributionWRef } from "../../../types";

type AttributionGroupDraggableItemProps = {
  replaceAttributionInGroup: (newAttributionId: string, oldAttributionId: string) => void;
  attributionNumber?: number;
  attributions: AttributionWRef[];
  disabledAttributions: string[];
  grabbing?: boolean;
  initalAttributionID: string;
  removeAttributionFromGroup: (initalAttributionID: string) => void;
};

export const AttributionGroupDraggableItem = ({
  replaceAttributionInGroup,
  attributionNumber,
  attributions,
  disabledAttributions,
  grabbing,
  initalAttributionID,
  removeAttributionFromGroup,
}: AttributionGroupDraggableItemProps) => {
  const [attribution, setAttribution] = useState<AttributionWRef | null>(
    attributions.find((a) => a.ref.id === initalAttributionID) || null
  );
  const { customer } = useCustomerContext();

  const displayNumber = attributionNumber !== undefined ? attributionNumber + 1 : undefined;

  const onAttributionChange = useCallback(
    (newAttribution: AttributionWRef | null) => {
      if (newAttribution !== null) {
        replaceAttributionInGroup(newAttribution.ref.id, attribution?.ref.id ?? "");
        setAttribution(newAttribution);
      } else {
        removeAttributionFromGroup(attribution?.ref.id ?? "");
        setAttribution(null);
      }
    },
    [replaceAttributionInGroup, attribution?.ref.id, removeAttributionFromGroup]
  );

  const onRemoveAttribution = useCallback(() => {
    if (attribution) {
      removeAttributionFromGroup(attribution.ref.id);
    }
  }, [attribution, removeAttributionFromGroup]);

  return (
    <Grid
      container
      sx={{
        pb: "16px",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          width: "24px",
        }}
      >
        <Typography>{displayNumber}</Typography>
      </Grid>
      <Grid size={11}>
        <DraggableItemHolder
          grabbing={grabbing}
          height="54px"
          id={attribution?.ref.id ?? ""}
          key={attribution?.ref.id ?? ""}
        >
          <Grid container>
            <Grid size={10}>
              <AttributionSelect
                attribution={attribution}
                attributions={attributions.filter((a) => a.data.type !== "managed")}
                disabledAttributions={disabledAttributions}
                onChange={onAttributionChange}
                sx={{ pr: "10px" }}
              />
            </Grid>
            <Grid container size={2}>
              <Grid
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "right",
                }}
                size={6}
              >
                <Link
                  href={`/customers/${customer.id}/analytics/attributions/${attribution?.ref.id}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Tooltip title={attributionGroupsText.OPEN_ATTRIBUTION_NEW_TAB}>
                    <IconButton size="medium">
                      <OpenInNewIcon fontSize="small" color="action" sx={{ pointerEvents: "none" }} />
                    </IconButton>
                  </Tooltip>
                </Link>
              </Grid>
              <Grid
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
                size={6}
              >
                <Tooltip title={globalText.REMOVE}>
                  <IconButton onClick={onRemoveAttribution} size="medium">
                    <ClearIcon fontSize="small" color="action" sx={{ pointerEvents: "none" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </DraggableItemHolder>
      </Grid>
    </Grid>
  );
};

type DraggableGroupContentProps = {
  replaceAttributionInGroup: (newAttributionId: string, oldAttributionId: string) => void;
  attributions: AttributionWRef[];
  disabledAttributions: string[];
  items: string[];
  removeAttributionFromGroup: (id: string) => void;
};

export const DraggableGroupContent = ({
  replaceAttributionInGroup,
  attributions,
  disabledAttributions,
  items,
  removeAttributionFromGroup,
}: DraggableGroupContentProps) => (
  <Box>
    {items.map((id, index) => (
      <AttributionGroupDraggableItem
        replaceAttributionInGroup={replaceAttributionInGroup}
        attributionNumber={index}
        attributions={attributions}
        disabledAttributions={disabledAttributions}
        initalAttributionID={id}
        key={id}
        removeAttributionFromGroup={removeAttributionFromGroup}
      />
    ))}
  </Box>
);
