import { type CloudSelectorType } from "@doitintl/cmp-models";
import { Container, Stack, Typography } from "@mui/material";

import { CloudBox } from "../../Customer/SaaSConsoleOnboarding/Common/CloudSelector/CloudBox";
import { cloudProviderOptions } from "../../Customer/SaaSConsoleOnboarding/Common/CloudSelector/CloudSelector";

type Props = {
  selectedCloudProviders: CloudSelectorType[];
  handleCloudSelection: (provider: CloudSelectorType) => void;
};

const CloudSelector = ({ selectedCloudProviders, handleCloudSelection }: Props) => (
  <Container maxWidth="lg">
    <Stack
      spacing={4}
      sx={{
        width: "100%",
        alignItems: "start",
      }}
    >
      <Stack
        sx={{
          mt: 4,
          alignSelf: "baseline",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "500",
            pb: 1,
          }}
        >
          Which cloud providers do you want to connect?
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
          }}
        >
          Choose which clouds you want to use with DoiT Cloud Solve
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        spacing={2}
        sx={{
          justifyContent: "space-between",
        }}
      >
        {cloudProviderOptions.map((cloud) => (
          <CloudBox
            key={cloud.type}
            cloud={cloud}
            selected={selectedCloudProviders.includes(cloud.type)}
            onClick={handleCloudSelection}
          />
        ))}
      </Stack>
    </Stack>
  </Container>
);

export default CloudSelector;
