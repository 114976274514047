import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import PersonAdd from "@mui/icons-material/PersonAdd";
import AttachFile from "@mui/icons-material/AttachFile";
import NoSsr from "@mui/material/NoSsr";

import uniqBy from "lodash/uniqBy";
import concat from "lodash/concat";
import map from "lodash/map";
import forEach from "lodash/forEach";
import toLower from "lodash/toLower";
import split from "lodash/split";
import trim from "lodash/trim";
import LoadingButton from "../../Components/LoadingButton";
import ConfirmDialog from "./ConfirmDialog";

import Editor from "./Editor/Editor";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";

let isDoneLoading;

const styles = (theme) => ({
  card: {
    backgroundColor: theme.palette.comment,
  },
  content: {
    padding: theme.spacing(2),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    marginTop: -15,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "auto",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  chipsContainer: {
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  textField: {},
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rtl: {
    direction: "rtl",
  },
  spaceRow: {
    display: "flex",
    justifyContent: "space-between",
    // marginTop: 50,
  },
  form: {
    width: "100%",
  },
  textColor: {
    color: theme.palette.text.secondary,
  },
  fileInput: {
    display: "none",
  },
  editor: {
    transitionTimingFunction: "ease-out",
    transition: "height 300ms",
    [theme.breakpoints.up("md")]: {
      height: 270,
    },
    [theme.breakpoints.down("lg")]: {
      height: 290,
    },
    [theme.breakpoints.down("sm")]: {
      height: 314,
    },
  },
  bigEditor: {
    transitionTimingFunction: "ease-out",
    transition: "height 300ms",
    [theme.breakpoints.up("md")]: {
      height: 270 * 2,
    },
    [theme.breakpoints.down("lg")]: {
      height: 290 * 2,
    },
    [theme.breakpoints.down("sm")]: {
      height: 314 * 2,
    },
  },
  cardFullscreen: {
    height: "100%",
  },
});

class NewCommentCard extends Component {
  state = {
    body: "",
    solved: false,
    attachments: [],
    collaborators: "",
    collaboratorsError: false,
    acceptedCollaborators: [],
    openCollaborators: false,
    includeDraftConfirm: false,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeComment = (val) => {
    if (!this.state.includeDraftConfirm) {
      this.setState({
        body: val,
      });
    }
    const store = { ticketID: this.props.ticket.id, comment: val };
    try {
      localStorage.setItem("comment", JSON.stringify(store));
    } catch (e) {
      // do nothing the user has probbely pasted a big image into the comment
    }
  };

  handleChangeCheckbox = (event) => {
    this.setState({ solved: event.target.checked });
  };

  handleAddFile = (event) => {
    const files = Array.from(event.target.files);
    event.target.value = null;
    const attachments = uniqBy(concat(this.state.attachments.slice(), files), "name");
    this.setState({
      attachments,
    });
  };

  handleRemoveFile = (index) => () => {
    const attachments = this.state.attachments.slice();
    attachments.splice(index, 1);
    this.setState({
      attachments,
    });
  };

  getDraftComment() {
    const commentContentStr = localStorage.getItem("comment");
    const commentContent = JSON.parse(commentContentStr);
    const tmp = document.createElement("DIV");
    tmp.innerHTML = commentContent?.comment;
    const plainText = tmp.textContent || tmp.innerText || "";
    if (commentContentStr && commentContent?.ticketID?.toString() === this.props.ticketID && plainText?.length > 2) {
      this.setState({ includeDraftConfirm: true });
    }
  }

  confirmDraft() {
    const commentContentStr = localStorage.getItem("comment");
    let commentContent = null;
    commentContent = JSON.parse(commentContentStr);
    if (commentContent.ticketID?.toString() === this.props.ticketID) {
      this.setState({
        body: commentContent.comment,
      });
      setTimeout(() => {
        this.setState({
          includeDraftConfirm: false,
        });
      }, 1000);
    } else {
      this.setState({
        includeDraftConfirm: false,
      });
    }
  }

  cancelConfirm() {
    this.setState({ includeDraftConfirm: false });
    localStorage.removeItem("comment");
  }

  componentDidMount() {
    this.getDraftComment();
  }

  componentDidUpdate(prev) {
    if (prev.loading) {
      isDoneLoading = true;
    }
    if (isDoneLoading && !prev.loading) {
      isDoneLoading = false;
      this.submitCallback(true);
    }
  }

  handleSubmit = async () => {
    const { body, solved, attachments, acceptedCollaborators } = this.state;
    localStorage.removeItem("comment");
    this.props.onSubmit(body, solved, attachments, acceptedCollaborators, this.submitCallback);
  };

  submitCallback = (result) => {
    if (result) {
      this.setState({
        body: "",
        solved: false,
        attachments: [],
        collaborators: "",
        collaboratorsError: false,
        acceptedCollaborators: [],
        openCollaborators: false,
        resizeEditor: false,
      });
    }
  };

  toggleCollaborators = () => {
    this.setState((prevState) => ({ openCollaborators: !prevState.openCollaborators }));
  };

  handleAddCC = (event) => {
    event.preventDefault();

    this.setState((prevState) => {
      if (prevState.collaborators) {
        const emails = split(prevState.collaborators, ",");
        const acceptedCollaborators = prevState.acceptedCollaborators.slice();
        const rejected = [];
        let collaboratorsError = false;
        let openCollaborators = false;
        let collaborators = "";

        forEach(emails, (rawEmail) => {
          const email = toLower(trim(rawEmail));
          if (this.isEmailValid(email)) {
            acceptedCollaborators.push(email);
          } else if (trim(rawEmail) !== "") {
            rejected.push(rawEmail);
          }
        });

        if (rejected.length) {
          collaboratorsError = true;
          openCollaborators = true;
          collaborators = rejected.join(",");
        }

        return {
          collaborators,
          collaboratorsError,
          acceptedCollaborators,
          openCollaborators,
        };
      }

      return null;
    });
  };

  handleRemoveCC = (index) => () => {
    this.setState((prevState) => {
      const acceptedCollaborators = prevState.acceptedCollaborators.slice();
      acceptedCollaborators.splice(index, 1);
      return {
        acceptedCollaborators,
      };
    });
  };

  isEmailValid = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  onEditorFocus() {
    if (!this.props.smDown) {
      this.setState({ resizeEditor: true });
    }
  }

  onEditorBlur() {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = this.state.body;
    const txt = tmp.textContent || tmp.innerText || "";
    if (txt.length === 0) {
      this.setState({ resizeEditor: false });
    }
  }

  render() {
    const { classes, loading, username, comments, t } = this.props;

    const { body, solved, attachments, collaborators, collaboratorsError, acceptedCollaborators, openCollaborators } =
      this.state;

    let helperText;
    if (collaboratorsError) {
      helperText = t("NewCommentCard.helperError");
    } else {
      helperText = t("NewCommentCard.helperEmail");
    }
    const placeholder = `${t("NewCommentCard.helper1")} ${
      username ? username.substring(0, username.indexOf(" ")) : ""
    }${t("NewCommentCard.helper2")}`;

    return (
      <>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div className={this.state.resizeEditor ? classes.bigEditor : classes.editor}>
              <NoSsr>
                <Editor
                  style={{
                    height: this.state.resizeEditor ? 240 * 2 : this.props.smDown ? 300 : 225,
                    transition: "height 300ms",
                    transitionTimingFunction: "ease-out",
                  }}
                  placeholder={placeholder}
                  name="body"
                  value={body}
                  onFocus={this.onEditorFocus.bind(this)}
                  onBlur={this.onEditorBlur.bind(this)}
                  onChange={this.handleChangeComment}
                />
              </NoSsr>
            </div>
            <div className={classes.spaceRow}>
              {this.props.status !== "new" && this.props.status !== "closed" && (
                <FormGroup row>
                  <FormControlLabel
                    className={classes.textColor}
                    control={<Checkbox disabled={loading} checked={solved} onChange={this.handleChangeCheckbox} />}
                    label={this.props.xsDown ? t("NewCommentCard.markSolved") : t("NewCommentCard.considerSolved")}
                  />
                </FormGroup>
              )}
              <div className={classes.buttonGroup}>
                <Tooltip id="add-collaborator-tooltip" title="Add CCs">
                  <span>
                    <IconButton
                      aria-label="AddCollaborator"
                      onClick={this.toggleCollaborators}
                      disabled={loading}
                      size="large"
                    >
                      <PersonAdd />
                    </IconButton>
                  </span>
                </Tooltip>
                <input
                  id="file-upload-button"
                  type="file"
                  multiple
                  className={classes.fileInput}
                  onChange={this.handleAddFile}
                  disabled={loading}
                />
                <label htmlFor="file-upload-button">
                  <IconButton component="span" disabled={loading} size="large">
                    <AttachFile />
                  </IconButton>
                </label>
              </div>
            </div>
          </CardContent>

          <Collapse in={openCollaborators}>
            <CardActions className={classes.actions}>
              <form onSubmit={this.handleAddCC} className={classes.form}>
                <TextField
                  id="collaborators"
                  name="collaborators"
                  label={t("NewCommentCard.aditionalCCs")}
                  value={collaborators}
                  onChange={this.handleChange}
                  onBlur={this.handleAddCC}
                  type="text"
                  className={classes.textField}
                  placeholder="email@example.com"
                  helperText={helperText}
                  fullWidth
                  margin="normal"
                  error={collaboratorsError}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </form>
            </CardActions>
          </Collapse>

          {acceptedCollaborators.length > 0 && (
            <CardActions className={classes.actions}>
              <div className={classes.chipsContainer}>
                <Typography variant="body1">{t("NewCommentCard.aditionalCCs")}: </Typography>
                {map(acceptedCollaborators, (collaborator, i) => (
                  <Fade in key={i}>
                    <Chip
                      label={collaborator}
                      onDelete={this.handleRemoveCC(i)}
                      className={classes.chip}
                      size="small"
                    />
                  </Fade>
                ))}
              </div>
            </CardActions>
          )}

          {attachments.length > 0 && (
            <CardActions className={classes.actions}>
              <div className={classes.chipsContainer}>
                <Typography variant="body1">{t("NewCommentCard.attachedFiles")}: </Typography>
                {map(attachments, (file, i) => (
                  <Fade in key={i}>
                    <Chip label={file.name} onDelete={this.handleRemoveFile(i)} className={classes.chip} size="small" />
                  </Fade>
                ))}
              </div>
            </CardActions>
          )}

          <CardActions className={classes.actions}>
            <div />
            <LoadingButton
              color="primary"
              size="small"
              variant="contained"
              onClick={this.handleSubmit}
              disabled={
                loading || (!trim(body) && !attachments.length > 0 && !acceptedCollaborators.length > 0 && !solved)
              }
              loading={loading && comments}
              mixpanelEventId="support.newComment.submit"
            >
              {t("NewCommentCard.submit")}
            </LoadingButton>
          </CardActions>
        </Card>
        <ConfirmDialog
          title={t("NewCommentCard.unfinishedDialog")}
          text={t("NewCommentCard.unfinishedDialogText")}
          acceptTitle={t("NewCommentCard.yes")}
          cancelTitle={t("NewCommentCard.no")}
          onCancel={this.cancelConfirm.bind(this)}
          onAccept={this.confirmDraft.bind(this)}
          open={this.state.includeDraftConfirm}
        />
      </>
    );
  }
}

NewCommentCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation("services"))(NewCommentCard);
