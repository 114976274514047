import { type ReactNode } from "react";

import { type SaaSConsoleType } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { type Customer } from "../../../../types";
import { SaasConsoleTypes } from "../consts";
import { SaaSCloudConnectCard } from "../NavigatorOnlyDashboard/CloudConnectCard";
import { SolveOnlyCloudConnectCard } from "../SolveOnlyDashboard/CloudConnectCard";
import { type ProductOnlyCustomerProspects } from "../type";
import { shouldShowCard } from "../utils";

export const renderCloudConnectCard = (
  customer: Customer,
  cloudType: SaaSConsoleType,
  prospectType: ProductOnlyCustomerProspects,
  skipProvidersCheck = false,
  showSnowflakeOnboardingCard = false
) => {
  const isSnowflake = cloudType === "SNOWFLAKE";
  if (
    (skipProvidersCheck && !isSnowflake) ||
    (shouldShowCard(cloudType, customer, prospectType) && !isSnowflake) ||
    (isSnowflake && showSnowflakeOnboardingCard)
  ) {
    const CardComponent = prospectType === "navigator" ? SaaSCloudConnectCard : SolveOnlyCloudConnectCard;
    return (
      <Grid
        key={cloudType}
        size={{
          xs: 12,
          sm: 12,
          md: 6,
        }}
      >
        <CardComponent cloudType={cloudType} />
      </Grid>
    );
  }
  return null;
};

export const renderPackageCloudConnectCards = (
  customer: Customer,
  prospect: ProductOnlyCustomerProspects,
  skipProvidersCheck = false,
  showSnowflakeOnboardingCard = false
) =>
  SaasConsoleTypes.map((cloudType) =>
    renderCloudConnectCard(customer, cloudType, prospect, skipProvidersCheck, showSnowflakeOnboardingCard)
  );

const WelcomeBlockWrapper = ({ children }: { children: ReactNode }) => (
  <Stack
    spacing={1}
    sx={{
      ml: -1,
    }}
  >
    <Typography
      variant="h1"
      sx={{
        pl: 1,
      }}
    >
      Welcome to the DoiT Console
    </Typography>
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      {children}
    </Grid>
  </Stack>
);

export default WelcomeBlockWrapper;
