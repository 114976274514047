import { type ReactNode, useEffect } from "react";

import { Fade, Slide } from "react-awesome-reveal";
import { type AvaFeedback } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Avatar, Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { type MessageAva, type MessageUser } from "../Common/types";
import { type UseAvaType } from "../useAva";
import { AvaIcon } from "./AvaIcon";
import { AvaLoadingAnimation } from "./AvaLoadingAnimation";
import { AvaReportLoader } from "./AvaReportLoader";
import { ContentWithAvatar } from "./ContentWithAvatar";
import { CopyToClipboardButton } from "./CopyToClipboardButton";
import { CustomMarkdown } from "./CustomMarkdown";
import { Feedback } from "./Feedback";
import { UpsellNotification } from "./UpsellNotification";

type PropsUser = {
  message: MessageUser;
  disableAnimation: boolean;
  getAvatar?: () => string | null;
};

export const MessageUserComponent = ({ message, getAvatar, disableAnimation }: PropsUser) => {
  const { content } = message;

  const avatarSrc = getAvatar?.();

  return (
    <Slide direction="up" triggerOnce duration={disableAnimation ? 0 : undefined}>
      <Box
        sx={{
          borderTop: 1,
          borderBottom: 1,
          borderColor: "divider",
          px: 15,
          backgroundColor: "background.paper",
        }}
      >
        <ContentWithAvatar avatar={<Avatar sx={{ height: 32, width: 32 }} src={avatarSrc ?? undefined} />}>
          <Typography
            variant="subtitle2"
            sx={{
              py: 1.5,
            }}
          >
            {content}
          </Typography>
        </ContentWithAvatar>
      </Box>
    </Slide>
  );
};

type PropsAva = {
  message: MessageAva;
  widgetCards?: Record<string, ReactNode>;
  disableAnimation: boolean;
  showError?: string;
  onRetryClicked?: () => void;
  onFeedbackClicked: (answerId: string, feedback: AvaFeedback) => void;
  isDarkMode: boolean;
  onReportGenerated?: (report: string, mapKey: string) => void;
  overrideLongerWait: UseAvaType["overrideLongerWait"];
  contactSalesButton?: ReactNode;
  handleReportCreate?: (widgetKey: string) => Promise<void>;
  handleAskQuestion?: (question: string) => void;
};

export const MessageAvaComponent = ({
  message,
  widgetCards,
  disableAnimation,
  showError,
  onFeedbackClicked,
  onRetryClicked,
  isDarkMode,
  onReportGenerated,
  overrideLongerWait,
  contactSalesButton,
  handleReportCreate,
  handleAskQuestion,
}: PropsAva) => {
  const { content, completed, isReport, reportBlocked, additionalData } = message;
  const handleFeedbackSubmitted = (feedback: AvaFeedback) => {
    if (message.answerId) {
      onFeedbackClicked(message.answerId, feedback);
    }
  };

  useEffect(() => {
    if (message && onReportGenerated && message.isReport && message.answerId) {
      onReportGenerated(message.content, message.answerId);
    }
  }, [message.content]);

  return (
    <Box
      sx={{
        px: 15,
      }}
    >
      <ContentWithAvatar
        avatar={
          <Box
            sx={{
              px: 0.5,
            }}
          >
            <AvaIcon isDarkMode={isDarkMode} />
          </Box>
        }
      >
        <Stack
          sx={{
            width: "100%",
          }}
        >
          {!(content || additionalData) && !showError && !reportBlocked && (
            <Stack
              sx={{
                width: "100%",
              }}
            >
              {overrideLongerWait ? <AvaReportLoader /> : <AvaLoadingAnimation isDarkMode={isDarkMode} />}
            </Stack>
          )}
          {message.answerId && widgetCards?.[message.answerId]}
          {additionalData && (
            <Typography variant="body2" component="div">
              <CustomMarkdown
                line={additionalData ?? ""}
                handleAskQuestion={handleAskQuestion}
                isDarkMode={isDarkMode}
              />
            </Typography>
          )}
          {!isReport && !reportBlocked && !additionalData && (
            <Typography variant="body2" component="div">
              <CustomMarkdown line={content ?? ""} handleAskQuestion={handleAskQuestion} isDarkMode={isDarkMode} />
            </Typography>
          )}

          {!!showError && (
            <Alert
              severity="warning"
              action={
                <Button color="inherit" size="small" onClick={onRetryClicked}>
                  Ask again
                </Button>
              }
            >
              I couldn't get to your question due to a service issue.
              <br />
              {showError}
            </Alert>
          )}
          {reportBlocked && contactSalesButton && <UpsellNotification contactSalesButton={contactSalesButton} />}
          {completed && isReport && !reportBlocked && (
            <Typography
              variant="body2"
              component="div"
              sx={{
                mt: 1,
              }}
            >
              <Typography
                variant="body2"
                component="span"
                sx={{
                  cursor: "pointer",
                  color: "#3B40B5",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => {
                  if (handleReportCreate && message.answerId) {
                    handleReportCreate(message.answerId);
                  }
                }}
              >
                Open the report in Cloud Analytics
                <OpenInNewIcon fontSize="small" sx={{ verticalAlign: "middle", marginLeft: 1 }} />
              </Typography>
            </Typography>
          )}
          {completed && (content || additionalData) && !reportBlocked && (
            <Fade triggerOnce fraction={0.5} duration={disableAnimation ? 0 : 500}>
              <Stack direction="row">
                <Feedback
                  feedback={message.feedback}
                  onFeedbackClicked={handleFeedbackSubmitted}
                  disableAnimation={disableAnimation}
                >
                  {!isReport ? <CopyToClipboardButton color="primary" text={content ?? ""} /> : <> </>}
                </Feedback>
              </Stack>
            </Fade>
          )}
        </Stack>
      </ContentWithAvatar>
    </Box>
  );
};
