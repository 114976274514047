import { useCallback, useState } from "react";

import { type StatusData } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Box, Dialog, IconButton, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import { statusesTexts } from "../../../assets/texts";
import { type StatusWRef } from "../../../types/Status";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import { StatusOptions } from "./utilities";

export const getFormatDate = (lastUpdate: FirestoreTimestamp) =>
  DateTime.fromJSDate(lastUpdate.toDate()).toFormat("MMMM d, HH:mma").replace("AM", "am").replace("PM", "pm");

const ReportStatus = ({ statusSnap }: { statusSnap: StatusWRef | null }) => {
  const theme = useTheme();
  const data = statusSnap?.data;
  const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false);

  const getLabelKey = (label: string) => {
    if (label.includes("updated")) {
      return "date";
    } else if (label.includes("Status")) {
      return "status";
    }
    return label;
  };

  const renderRow = useCallback(
    (key: string, label: string, value: string) => (
      <Box>
        <Box
          component="span"
          sx={{
            marginRight: 0.5,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              display: "inline",
            }}
          >
            {label}
          </Typography>
        </Box>
        <Box component="span">
          <Typography
            variant="subtitle2"
            data-cy={`${key}-${getLabelKey(label)}`}
            sx={{
              display: "inline",
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    ),
    []
  );

  const renderStatusBlock = useCallback(
    (key: string, statusData: WithFirebaseModel<StatusData>) => {
      const dateFormatted = statusData.lastUpdate ? getFormatDate(statusData.lastUpdate) : null;
      return (
        <Box
          key={`${key}-status-details`}
          sx={{
            marginBottom: 3,
          }}
        >
          <Box>
            <Typography variant="subtitle1">{StatusOptions[key]}</Typography>
          </Box>
          {!!statusData.status && renderRow(key, statusesTexts.status, statusData.status)}
          {!!dateFormatted && renderRow(key, statusesTexts.dataLastUpdate, dateFormatted)}
        </Box>
      );
    },
    [renderRow]
  );

  const renderDialogContent = useCallback(
    (statusData: Record<string, WithFirebaseModel<StatusData>>) => (
      <Box
        sx={{
          padding: 3,
        }}
      >
        <Box data-cy="report-status-dialog">
          <Box
            sx={{
              marginBottom: 3.5,
            }}
          >
            <Typography data-cy="status-dialog-title" variant="h2">
              {statusesTexts.title}
            </Typography>
            <IconButton
              aria-label="Close"
              sx={{
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(2),
                color: theme.palette.grey[500],
              }}
              onClick={() => {
                setStatusDialogOpen(false);
              }}
              size="medium"
              data-cy="dialog-close-button"
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Box>
          <Box
            data-cy="status-dialog-content"
            sx={{
              marginBottom: 3.5,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                display: "block",
              }}
            >
              {statusesTexts.content}
            </Typography>
          </Box>
          <Box>
            {Object.keys(StatusOptions).map((key) => statusData[key] && renderStatusBlock(key, statusData[key]))}
          </Box>
        </Box>
      </Box>
    ),
    [renderStatusBlock, theme]
  );

  const renderReportStatusLink = useCallback(() => {
    if (data?.overallLastUpdate) {
      const lastUpdate = getFormatDate(data.overallLastUpdate);
      return (
        <Box
          data-cy="report-status-data"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <Typography
            variant="caption"
            data-cy="last-update-total"
            sx={{
              display: "block",
            }}
          >{`${statusesTexts.lastUpdate} ${lastUpdate}`}</Typography>
          <Link
            component="button"
            variant="caption"
            underline="always"
            color="inherit"
            data-cy="view-details"
            onClick={() => {
              setStatusDialogOpen(true);
            }}
          >
            {statusesTexts.buttonText}
          </Link>
          {data?.status && (
            <Dialog
              open={statusDialogOpen}
              onClose={() => {
                setStatusDialogOpen(false);
              }}
              title={statusesTexts.title}
              maxWidth="sm"
              PaperProps={{
                sx: {
                  width: { xs: "60%", sm: "50%", md: "40%", lg: "30%" },
                  height: "100%",
                  position: "fixed",
                  top: 50,
                  right: 0,
                  m: 0,
                },
              }}
            >
              {renderDialogContent(data.status)}
            </Dialog>
          )}
        </Box>
      );
    }
    return null;
  }, [data, statusDialogOpen, renderDialogContent]);

  return renderReportStatusLink();
};

export default ReportStatus;
