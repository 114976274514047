import { Fragment, type MouseEvent, useState } from "react";

import {
  AssetTypeMicrosoftAzure,
  type MicrosoftAzureAssetModel,
  type MicrosoftAzureStandaloneAssetModel,
} from "@doitintl/cmp-models";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { type Theme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";

import MicrosoftAzureIcon from "../../../assets/microsoft-azure.png";
import { useAuthContext } from "../../../Context/AuthContext";
import { useUserContext } from "../../../Context/UserContext";
import { ThemeModes } from "../../../muiThemeTypes";
import { type AssetTabProps } from "../types";
import { RemoveDialog } from "./components/RemoveDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlight: {
      backgroundColor: theme.palette.mode === ThemeModes.DARK ? grey[600] : grey[300],
    },
    actions: {
      height: 64,
    },
    cardContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      paddingBottom: 0,
    },
    infoDiv: {
      height: 72,
    },
  })
);

type Props = AssetTabProps<MicrosoftAzureAssetModel | MicrosoftAzureStandaloneAssetModel>;

const MicrosoftAzureAssetCard = ({
  asset,
  highlight,
  onAssignToEntity,
  unassignedAction,
  onRemoveAsset,
  onAddTag,
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [ackAssetRemoveDialog, setAckAssetRemoveDialog] = useState(false);
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isDoitOwner, isDoitEmployee } = useAuthContext();

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (dialog: string) => () => {
    if (dialog === "ackAssetRemoveDialog") {
      setAckAssetRemoveDialog(true);
    }
  };

  const handleCloseDialog = (dialog: string) => () => {
    if (dialog === "ackAssetRemoveDialog") {
      setAckAssetRemoveDialog(false);
    }
  };

  return (
    <Card className={classNames({ [classes.highlight]: highlight })}>
      {isDoitOwner && ackAssetRemoveDialog && (
        <RemoveDialog
          open={ackAssetRemoveDialog}
          onCancel={handleCloseDialog("ackAssetRemoveDialog")}
          onAccept={() => {
            onRemoveAsset();
            handleCloseDialog("ackAssetRemoveDialog")();
          }}
          name={asset.data.properties.subscription.displayName}
        />
      )}
      <CardHeader
        avatar={<Avatar alt="Microsoft Azure" src={MicrosoftAzureIcon} />}
        action={
          <Fragment>
            <IconButton
              aria-owns={anchorEl ? "settings-menu" : undefined}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="settings-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              role="button"
              tabIndex={0}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
            >
              {isDoitEmployee && (
                <MenuItem key="reseller" disabled divider>
                  {asset.data.properties.reseller}
                </MenuItem>
              )}
              {(isDoitEmployee || userRoles.billingProfilesAdmin) && asset.data.type === AssetTypeMicrosoftAzure && (
                <MenuItem key="assign" onClick={onAssignToEntity} divider={!isDoitOwner}>
                  Billing Profile Assignment
                </MenuItem>
              )}
              {isDoitOwner && (
                <MenuItem key="remove" onClick={handleOpenDialog("ackAssetRemoveDialog")} divider>
                  Remove Asset
                </MenuItem>
              )}
              {userRoles.invoicesViewer && (
                <MenuItem key="tag" onClick={onAddTag}>
                  Tag Asset
                </MenuItem>
              )}
            </Menu>
          </Fragment>
        }
        title={
          <Typography
            variant="button"
            sx={{
              display: "inline",
            }}
          >
            {asset.data.properties.subscription?.skuDescription ?? "Microsoft Azure Plan"}
            {asset.data.type !== AssetTypeMicrosoftAzure && " (standalone)"}
          </Typography>
        }
        subheader={
          <div style={{ height: 25 }}>
            {asset.data.properties.customerDomain}

            {asset.data.tags?.map((tag) => (
              <Chip style={{ marginRight: 4, marginLeft: 4 }} size="small" key={tag} label={tag} />
            ))}
          </div>
        }
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.infoDiv}>
          <div>
            <Typography
              variant="body2"
              sx={{
                display: "inline",
              }}
            >
              Display Name:{" "}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                display: "inline",
              }}
            >
              {asset.data.properties.subscription.displayName}
            </Typography>
          </div>
          <div>
            <Typography
              variant="body2"
              sx={{
                display: "inline",
              }}
            >
              Billing Status:{" "}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                display: "inline",
              }}
            >
              {asset.data.properties.subscription.subscriptionBillingStatus}
            </Typography>
          </div>
          <div>
            <Typography
              variant="body2"
              sx={{
                display: "inline",
              }}
            >
              Subscription ID:{" "}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                display: "inline",
              }}
            >
              {asset.data.properties.subscription.subscriptionId}
            </Typography>
          </div>
        </div>
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        {unassignedAction}
      </CardActions>
    </Card>
  );
};

export default MicrosoftAzureAssetCard;
