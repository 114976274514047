import { type JSX, type ReactElement, useMemo } from "react";

import { type FlexsaveType, FlexsaveTypes, type StandaloneOnboardingErrors } from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Alert, Container, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { IconWithLink } from "../../../Common/IconWithLink";
import { type SavingsEstimations } from "../../../types";
import { formatAsDollars } from "../../../utils/costUtils";
import { ContactSupport } from "../ContactSupport";
import { ErrorStepLocation, getShortErrorDescription } from "../helpers/errorsHelper";
import { InviteCollegeLink } from "../InviteCollegeLink";
import { OnboardingTask } from "./OnboardingTask";
import { OnboardingTasksHeader } from "./OnboardingTasksHeader";

type Props = {
  children?: JSX.Element;
  type: FlexsaveType;
  savings?: SavingsEstimations;
  emptySavings?: boolean;
  errors?: StandaloneOnboardingErrors;
  onBackButtonClick?: () => void;
  billingProfileName?: string;
  handleGetEstimate: () => void;
  handleViewEstimate: () => void;
  handleProvideDetails: () => void;
  handleViewPaymentDetails: () => void;
  handleActivateFlexsave: () => void;
  headingAction?: ReactElement;
};

export const OnboardingTasks = ({
  headingAction,
  children,
  type,
  savings,
  errors,
  emptySavings = false,
  billingProfileName,
  handleGetEstimate,
  handleViewEstimate,
  handleProvideDetails,
  handleViewPaymentDetails,
  handleActivateFlexsave,
  onBackButtonClick,
}: Props) => {
  const { customer } = useCustomerContext();

  const hasErrorInEstimationStep = useMemo(() => {
    const hasNonEmptySavingsError = Boolean(errors?.savings) && Boolean(errors?.savings?.type !== "empty_savings");
    return !savings && hasNonEmptySavingsError;
  }, [errors?.savings, savings]);
  const hasErrorInActivationStep = useMemo(() => Boolean(errors?.activation), [errors?.activation]);

  const estimationSubtitle = useMemo(() => {
    if (hasErrorInEstimationStep || emptySavings) {
      return getShortErrorDescription(
        errors?.savings?.type,
        type === FlexsaveTypes.AWS ? ErrorStepLocation.AWS_SAVINGS : ErrorStepLocation.GCP_SAVINGS
      );
    }
    const message =
      type === FlexsaveTypes.AWS
        ? "Get an estimate by uploading a CSV or creating a CloudFormation stack"
        : "We will need read-only access to your Google Cloud Savings Estimation for your organization.";
    return savings ? (
      <>
        Your estimated annual savings:{" "}
        <b>
          {formatAsDollars(savings?.annualSavings)} - {formatAsDollars(savings?.annualSavingsWithGrowth)}
        </b>
      </>
    ) : (
      message
    );
  }, [emptySavings, errors?.savings?.type, hasErrorInEstimationStep, savings, type]);

  const activationSubtitle = useMemo(() => {
    if (hasErrorInActivationStep) {
      return getShortErrorDescription(
        errors?.activation?.type,
        type === FlexsaveTypes.AWS ? ErrorStepLocation.AWS_ACTIVATION : ErrorStepLocation.GCP_ACTIVATION
      );
    }

    return type === FlexsaveTypes.AWS
      ? "We need access to your cloud environment to add and remove Flexsave accounts."
      : "We need access to your cloud environment to add and remove committed use discounts.";
  }, [errors?.activation?.type, hasErrorInActivationStep, type]);

  const subscribedToGCPMarketPlace = Boolean(customer.marketplace?.GCP?.accountExists);
  const canActivateFlexsave = Boolean(savings) && (billingProfileName || subscribedToGCPMarketPlace);

  const AWSPaymentInformationSubtitle = useMemo(
    () =>
      billingProfileName ? (
        <>
          Your billing profile: <b>{billingProfileName}</b>
        </>
      ) : (
        "DoiT will invoice you for a percentage of the savings generated by Flexsave."
      ),
    [billingProfileName]
  );

  const GCPMarketplaceSubtitle = useMemo(
    () =>
      subscribedToGCPMarketPlace
        ? "Google Cloud Marketplace subscription successful."
        : "You will be invoiced via Google Cloud Marketplace for a percentage of the savings generated by Flexsave.",
    [subscribedToGCPMarketPlace]
  );

  const disabled = type === FlexsaveTypes.GCP;

  return (
    <Container maxWidth="md">
      <Stack spacing={2}>
        {disabled && (
          <Alert severity="warning" sx={{ ml: -1, mb: 2, mt: -2 }}>
            Sorry, Flexsave is now only available for DoiT resold customers.
          </Alert>
        )}
        <OnboardingTasksHeader onBackButtonClick={onBackButtonClick} type={type}>
          {children}
        </OnboardingTasksHeader>

        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            pt: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 500,
              letterSpacing: 0.25,
            }}
          >
            First, get your free savings estimate
          </Typography>
          {headingAction}
        </Stack>

        <OnboardingTask
          title="Savings estimate"
          subtitle={estimationSubtitle}
          defaultButtonText="Get estimate"
          onClick={handleGetEstimate}
          onClickDone={handleViewEstimate}
          done={Boolean(savings)}
          error={hasErrorInEstimationStep}
          info={emptySavings}
          disabled={disabled}
        />

        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            pt: 4,
          }}
        >
          Then, complete the following to activate Flexsave for {type === "GCP" ? "Google Cloud" : type}
        </Typography>

        {type === FlexsaveTypes.AWS ? (
          <OnboardingTask
            title="Provide payment information"
            subtitle={AWSPaymentInformationSubtitle}
            defaultButtonText="Provide details"
            secondaryDisableText="Get your estimate before editing"
            onClick={handleProvideDetails}
            onClickDone={handleViewPaymentDetails}
            done={Boolean(billingProfileName)}
            disabled={!savings || disabled}
          />
        ) : (
          <OnboardingTask
            title="Subscribe via Google Cloud Marketplace"
            subtitle={GCPMarketplaceSubtitle}
            defaultButtonText="Subscribe"
            defaultButtonIcon={<OpenNewIcon />}
            onClick={handleProvideDetails}
            onClickDone={handleViewPaymentDetails}
            doneWithoutAction={subscribedToGCPMarketPlace}
            disabled={!savings || disabled}
          />
        )}

        <OnboardingTask
          title="Activate Flexsave"
          subtitle={activationSubtitle}
          defaultButtonText="Set up Flexsave"
          onClick={handleActivateFlexsave}
          onClickDone={() => {}}
          error={Boolean(errors?.activation)}
          disabled={!canActivateFlexsave || disabled}
        />

        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          sx={{
            justifyContent: "space-between",
            py: 4,
            color: "textPrimary",
          }}
        >
          <IconWithLink
            text="How it works"
            icon={<PlayCircleOutlineIcon />}
            url="https://doit-intl.wistia.com/medias/aali793hnz"
          />
          <InviteCollegeLink />
          <ContactSupport />
          <IconWithLink
            text="Learn more about Flexsave"
            icon={<OpenNewIcon />}
            url="https://help.doit.com/docs/flexsave/overview"
          />
        </Stack>
      </Stack>
    </Container>
  );
};
