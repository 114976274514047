import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import { cloudflowTexts } from "../../../assets/texts";
import { type CloudflowTemplate } from "../types";
import Template from "./Template";

type TemplatesRowProps = {
  templates?: Readonly<Array<CloudflowTemplate>>;
  templatesLoading?: boolean;
  onTemplateClick: (template: CloudflowTemplate) => void;
  onClickViewAll?: () => void;
  numTemplates?: number;
  disabled?: boolean;
};

const TemplatesRow = ({
  templates,
  onTemplateClick,
  onClickViewAll,
  templatesLoading,
  numTemplates = 3,
  disabled = false,
}: TemplatesRowProps) => {
  if (numTemplates < 1) {
    return null;
  }

  if (templatesLoading) {
    return (
      <Grid container={true} spacing={1}>
        <Grid size={12}>
          <Stack
            direction="row"
            sx={{
              py: 1,
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <Skeleton variant="rectangular" height={28} width={309} />
            <Skeleton variant="rectangular" height={28} width={162} />
          </Stack>
        </Grid>
        <Grid size={12}>
          <Stack
            direction="row"
            sx={{
              gap: 1,
            }}
          >
            {Array.from({ length: numTemplates }, (_, index) => (
              <Box key={index} sx={{ flexGrow: 1 }}>
                <Skeleton variant="rectangular" height={160} />
              </Box>
            ))}
          </Stack>
        </Grid>
      </Grid>
    );
  }

  if (!templates || templates.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid size={12}>
        <Stack
          direction="row"
          sx={{
            py: 1,
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            width: "100%",
          }}
        >
          <Typography variant="h4">{cloudflowTexts.TEMPLATES_ROW_TITLE}</Typography>
          <Button
            variant="text"
            onClick={onClickViewAll}
            sx={{
              cursor: "pointer",
            }}
          >
            {cloudflowTexts.VIEW_ALL_TEMPLATES} <ArrowForwardIcon sx={{ height: 16, width: 16, ml: 1 }} />
          </Button>
        </Stack>
      </Grid>
      <Grid size={12}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "stretch",
          }}
        >
          {(templates || []).slice(0, numTemplates).map((template) => (
            <Box key={template.id} sx={{ width: `${100 / numTemplates}%` }}>
              <Template
                key={template.id}
                template={template}
                onTemplateClick={onTemplateClick}
                clouds={template.tags?.cloud || []}
                disabled={disabled}
              />
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TemplatesRow;
