import { Stack, Typography } from "@mui/material";

export const EmptyWorkloadsTableState = () => (
  <Stack
    spacing={1}
    sx={{
      alignItems: "center",
    }}
  >
    <Typography variant="h5">No workloads available</Typography>
  </Stack>
);
