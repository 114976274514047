import { type Dispatch, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import { type InputProps } from "@mui/material/Input/Input";

import { globalText } from "../../../assets/texts";
import { useEmailSchema } from "../helpers";
import { useSendEmailSignInLink } from "../hooks";
import { type LoginHookAction, LoginHookActionType, type LoginState } from "../types";
import EmailFormTemplate from "./EmailFormTemplate";

const guideText = "Enter your email address and we'll send you a magic link to sign in";

export const EmailLinkLoginForm = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const sendEmailSignInLink = useSendEmailSignInLink({ dispatch, state, handleLoading: true });

  const emailInputHandler: InputProps["onChange"] = useCallback(
    (e) => {
      dispatch({ type: LoginHookActionType.setEmail, email: e.currentTarget.value });
    },
    [dispatch]
  );

  const { t } = useTranslation("login");
  const EmailSchema = useEmailSchema();

  return (
    <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
      <Typography
        variant="h1"
        sx={{
          alignSelf: "flex-start",
        }}
      >
        {t("magic.link.signin")}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          pb: 1,
        }}
      >
        {guideText}
      </Typography>
      <EmailFormTemplate
        email={state.email}
        isLoading={state.isLoading}
        submitHandler={(
          _values,
          formikHelpers: {
            setSubmitting: (isSubmitting: boolean) => void;
            setFieldError: (field: string, message: string) => void;
          }
        ) => sendEmailSignInLink(undefined, formikHelpers)}
        emailInputHandler={emailInputHandler}
        validationSchema={EmailSchema}
        submitButtonText="Send email"
        submitMixpanelEventId="login.with-email-link"
      />
      <Button
        fullWidth={false}
        color="primary"
        variant="text"
        onClick={() => {
          dispatch({ type: LoginHookActionType.setFormType, formType: "checkEmail" });
        }}
      >
        {globalText.BACK}
      </Button>
    </Stack>
  );
};
