import { type Dispatch } from "react";

import { Box, Link, Typography } from "@mui/material";

import { useFormSwitch } from "../hooks";
import { type LoginHookAction, type LoginState } from "../types";

const FormSwitch = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const { formSwitchConfig, handleClick } = useFormSwitch({ state, dispatch });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pb: 3,
      }}
    >
      <Typography variant="body2">{formSwitchConfig.caption}&nbsp;</Typography>
      <Link data-cy="formSwitchLink" underline="hover" component="button" variant="body2" onClick={handleClick}>
        <Typography variant="body2">{formSwitchConfig.buttonText}</Typography>
      </Link>
    </Box>
  );
};

export default FormSwitch;
