import { Relation } from "@doitintl/cmp-models";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { attributionText } from "../../../../assets/texts";

export const relationLabel = (relation: Relation): string => {
  if (relation === Relation.OR) {
    return "ANY";
  }
  return "ALL";
};

const useStyles = makeStyles((theme) => ({
  selectRelation: {
    margin: theme.spacing(0, 1.5),
    padding: theme.spacing(0),
    minWidth: 60,
    textAlign: "center",
  },
}));

export type AttributionBuilderRelationProps = {
  relation: Relation;
  setRelation: (relation: Relation) => void;
  disabled?: boolean;
};

const AttributionBuilderRelation = ({ relation, setRelation, disabled }: AttributionBuilderRelationProps) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: "flex",
        pb: 1,
      }}
    >
      <Typography variant="subtitle2">{attributionText.MEETS}</Typography>
      <FormControl>
        <Select
          value={relation}
          onChange={(e) => {
            setRelation(e.target.value as Relation);
          }}
          className={classes.selectRelation}
          variant="standard"
          disabled={disabled}
          name="relation"
          inputProps={{
            sx: { p: 0 },
          }}
        >
          <MenuItem value={Relation.AND}>
            <Typography sx={{ p: 0 }} variant="subtitle2">
              {relationLabel(Relation.AND)}
            </Typography>
          </MenuItem>
          <MenuItem value={Relation.OR}>
            <Typography sx={{ p: 0 }} variant="subtitle2">
              {relationLabel(Relation.OR)}
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Typography variant="subtitle2">{attributionText.FOLLOWING_CONDITIONS}</Typography>
    </Box>
  );
};

export default AttributionBuilderRelation;
