import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Divider, Link, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

import { currencyLabelFormatter } from "../../../Components/Charts/ColumnsChat/ColumnsChartUtils";
import { useCurrency } from "../../../Components/hooks/useCurrency";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import BorderedBox from "../BorderedBox";
import { scrollToId } from "./utils";

type Props = {
  monthSavings: number;
  serviceName: string;
  serviceIcon: string;
  linkText: string;
  linkUrl: string;
  itemWidth: number;
  lifetimeSavings: number;
  savingsRate: number;
  month: string;
  serviceInfo?: string;
};

export default function Service({
  serviceIcon,
  serviceName,
  linkUrl,
  linkText,
  monthSavings,
  itemWidth,
  lifetimeSavings,
  savingsRate,
  month,
  serviceInfo,
}: Props) {
  const { customerCurrencySymbol } = useCurrency();
  const darkMode = useDarkThemeCheck();
  const color = darkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)";

  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid data-cy={`savings-by-service-${serviceName}`} size={isLargeScreen ? itemWidth : 12}>
      <BorderedBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h2">
              {currencyLabelFormatter(Math.floor(monthSavings), customerCurrencySymbol)} in {month}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Typography sx={{ color }}>{serviceName}</Typography>
              {serviceInfo ? (
                <Tooltip title={serviceInfo} placement="right">
                  <InfoOutlinedIcon sx={{ fontSize: "inherit" }} />
                </Tooltip>
              ) : null}
            </Box>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                gap: "10px",
              }}
            >
              <Typography>
                Lifetime savings{" "}
                <Typography
                  sx={{
                    fontWeight: 500,
                    display: "inline",
                  }}
                >
                  {currencyLabelFormatter(lifetimeSavings, customerCurrencySymbol)}
                </Typography>
              </Typography>
              <Typography>•</Typography>
              <Typography>
                Savings rate{" "}
                <Typography
                  sx={{
                    fontWeight: 500,
                    display: "inline",
                  }}
                >
                  {savingsRate}%
                </Typography>
              </Typography>
            </Box>
          </Box>

          <img src={serviceIcon} alt={serviceName} width={56} height={56} />
        </Box>

        <Divider />

        {serviceName !== "Amazon RDS" ? (
          <Box sx={{ paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2 }}>
            <Link
              underline="none"
              href={`#${linkUrl}`}
              onClick={(e) => {
                e.preventDefault();
                scrollToId(linkUrl);
              }}
            >
              {linkText}
            </Link>
          </Box>
        ) : null}
      </BorderedBox>
    </Grid>
  );
}
