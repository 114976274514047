import { useCallback, useMemo } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  type AutocompleteRenderGetTagProps,
  type AutocompleteRenderInputParams,
  Checkbox,
  Chip,
  type SxProps,
  TextField,
  type TextFieldProps,
  type Theme,
} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";

import { type Entity } from "../../../Context/customer/EntitiesContext";
import { createFilterOptions } from "../../../Pages/CloudAnalytics/utilities";

type Props = {
  entities: Entity[];
  onClose?: () => void;
  onChange: (scope: Entity[]) => void;
  scope: Entity[];
  sx?: SxProps<Theme>;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  dataCy?: string;
};

const handleRenderTags = (value: Entity[], getTagProps: AutocompleteRenderGetTagProps) =>
  value.map((option, index) => (
    <Chip size="small" label={option.name} {...getTagProps({ index })} key={option.id} deleteIcon={<CancelIcon />} />
  ));

const handleRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: Entity, { selected }) => (
  <li {...props} key={option.id}>
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      checked={selected}
    />
    {option.name}
  </li>
);

const handleRenderInput = (textFieldProps?: TextFieldProps) => {
  const handleRenderInputFunc = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      required
      variant="outlined"
      label="Scope"
      placeholder="Select billing profiles"
      fullWidth
      {...textFieldProps}
      slotProps={{
        inputLabel: {
          shrink: true,
        },
      }}
    />
  );
  return handleRenderInputFunc;
};

const filterOptions = createFilterOptions<Entity>({
  trim: true,
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
});

export const EntitiesSelectMulti = ({
  entities,
  disabled,
  onClose,
  onChange,
  scope,
  sx,
  textFieldProps,
  dataCy = "entities-select",
}: Props) => {
  const sortedEntities = useMemo(() => cloneDeep(entities).sort((a, b) => a.name.localeCompare(b.name)), [entities]);

  const handleChange = useCallback(
    (_, value: Entity[]) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      disableCloseOnSelect
      disabled={disabled}
      getOptionLabel={(entity) => entity.name}
      filterOptions={filterOptions}
      limitTags={3}
      multiple
      onChange={handleChange}
      onClose={onClose}
      options={sortedEntities}
      value={scope}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={sx}
      data-cy={dataCy}
      renderTags={handleRenderTags}
      renderOption={handleRenderOption}
      renderInput={handleRenderInput(textFieldProps)}
    />
  );
};
