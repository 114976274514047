import { useMemo, useState } from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { updateKeepAsgUpToDate } from "../../db";
import { type SpotScalingApi } from "../../spotScalingApi";
import { applyRecommendationsStrings } from "../../strings";
import { type AsgBasicInfo, type AsgConfigChange, type AsgItem, type KeepUptoDate } from "../../types";
import { AsgConfiguration } from "../AsgConfiguration/AsgConfiguration";
import { AsgsConfigurationTitles } from "../AsgConfiguration/AsgsConfigurationTitles";
import { useCommonHeights } from "../AsgConfiguration/hooks";

type AsgOptimizedProps = {
  spotScalingApi: SpotScalingApi;
  asg: AsgItem;
  notificationContainer: any;
};

enum DisplayState {
  ShowSavings,
  ApplyConfigChanges,
  ApplyFailed,
  ApplySuccessfullyFinished,
}

export const AsgOptimized = ({ spotScalingApi, asg }: AsgOptimizedProps) => {
  const asgInfo: AsgBasicInfo = {
    accountId: asg.accountId,
    region: asg.region,
    asgName: asg.asgName,
  };

  const [instanceTypeHeights, setInstanceTypeHeights] = useCommonHeights();
  const [azHeights, setAzHeights] = useCommonHeights();
  //   instanceTypeHeights<InstanceTypeHeights>({
  //   current: DEFAULT_HEIGHT,
  //   recommended: DEFAULT_HEIGHT
  // })
  const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.ShowSavings);
  const [configChange, setConfigChange] = useState<AsgConfigChange | undefined>(undefined);

  const [keepUptoDate, setKeepUptoDate] = useState<KeepUptoDate>({
    valueDirty: false,
    value: asg?.config?.keepUpToDate,
    origValue: asg?.config?.keepUpToDate,
  });

  const snackbar = useSnackbar();

  const allowEdit = asg.configurations.current.isMixedTypesInstances;

  const applyButtonDisabled = useMemo(() => {
    let disabled = displayState === DisplayState.ApplyConfigChanges || !configChange;
    if (keepUptoDate.valueDirty) {
      disabled = false;
    }
    return disabled;
  }, [displayState, configChange, keepUptoDate]);

  const handleApplyClick = async () => {
    if (keepUptoDate.valueDirty) {
      await updateKeepAsgUpToDate(asg.id, keepUptoDate.value);
      setKeepUptoDate({ valueDirty: false, value: keepUptoDate.value, origValue: keepUptoDate.value });

      if (!configChange) {
        snackbar.onOpen({
          message: applyRecommendationsStrings.ChangesApplied,
          variant: "success",
          withClose: true,
        });
      }
    }

    if (configChange) {
      setDisplayState(DisplayState.ApplyConfigChanges);
      const updateSuccess = await spotScalingApi.updateConfiguration(asgInfo, configChange);

      if (updateSuccess) {
        setDisplayState(DisplayState.ApplySuccessfullyFinished);
        snackbar.onOpen({
          message: applyRecommendationsStrings.SuccessfullyApplied,
          variant: "success",
          withClose: true,
        });
      } else {
        setDisplayState(DisplayState.ApplyFailed);
        snackbar.onOpen({
          message: applyRecommendationsStrings.FailedApply,
          variant: "error",
          withClose: true,
        });
      }
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Typography variant="h6">Recommendations</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <AsgsConfigurationTitles instanceTypeHeights={instanceTypeHeights} azHeights={azHeights} />
            <AsgConfiguration
              title="Current Values"
              confKey="current"
              instanceTypeHeights={instanceTypeHeights}
              setInstanceTypeHeights={setInstanceTypeHeights}
              azHeights={azHeights}
              setAzHeights={setAzHeights}
              allowEdit={allowEdit}
              onConfigurationChanged={setConfigChange}
              configuration={asg.configurations.current}
              spotScalingApi={spotScalingApi}
              asgInfo={asgInfo}
              actionButtonProps={{
                text: "Apply Changes",
                loading: displayState === DisplayState.ApplyConfigChanges,
                disabled: applyButtonDisabled,
                onClick: handleApplyClick,
              }}
              asgId={asg.id}
              isApplyChangesSupported={asg.isApplyChangesSupported}
              isCurrent={true}
              isOptimized={true}
              keepUptoDate={keepUptoDate}
              keepUptoDateCallback={setKeepUptoDate}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
