import { type ReactNode, useId } from "react";

import { NodeTransformationType } from "@doitintl/cmp-models";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import upperFirst from "lodash/upperFirst";
import words from "lodash/words";
import * as yup from "yup";

import { cloudflowTexts } from "../../../../../../assets/texts";
import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { ConcatenationForm } from "./ConcatenationForm";
import { ExtractTransformationForm } from "./ExtractTransformationForm";
import { FirstItemTransformationForm } from "./FirstItemTransformationForm";
import { LastItemTransformationForm } from "./LastItemTransformationForm";
import { type TransformationNodeFormValues } from "./TransformationNodeForm";

const actionLabel = "Transform action";

export const baseTransformationSchema = yup.object({
  type: yup.string().nullable().default(null).required().label(actionLabel),
});

export const TransformationActionPicker = () => {
  const { getFieldProps, setFieldValue } = useFormikContext<TransformationNodeFormValues>();

  const actionPickerLabelId = useId();
  const actionProps = getFieldProps<NodeTransformationType>("type");
  const actionCommonProps = useFieldCommonProps(actionProps, actionLabel, true);

  let transformationActionNode: ReactNode;
  switch (actionProps.value) {
    case NodeTransformationType.EXTRACT:
      transformationActionNode = <ExtractTransformationForm />;
      break;
    case NodeTransformationType.FIRST_ITEM:
      transformationActionNode = <FirstItemTransformationForm />;
      break;
    case NodeTransformationType.LAST_ITEM:
      transformationActionNode = <LastItemTransformationForm />;
      break;
    case NodeTransformationType.CONCATENATION:
      transformationActionNode = <ConcatenationForm />;
      break;
    default:
      transformationActionNode = null;
  }

  return (
    <Stack
      spacing={2}
      sx={{
        mb: 2,
      }}
    >
      <FieldSectionHeader
        title={cloudflowTexts.DEFINE_TRANSFORM_ACTION}
        subtitle={cloudflowTexts.ADD_TRANSFORM_ACTION}
      />
      <FormControl fullWidth required error={actionCommonProps.error}>
        <InputLabel size="small" id={actionPickerLabelId}>
          {actionCommonProps.label}
        </InputLabel>
        <Select
          labelId={actionPickerLabelId}
          size="small"
          {...actionCommonProps}
          onChange={(e) => {
            setFieldValue("transformation", null);
            actionCommonProps.onChange(e);
          }}
        >
          {Object.values(NodeTransformationType).map((type) => (
            <MenuItem key={type} value={type}>
              {upperFirst(words(type).join(" "))}
            </MenuItem>
          ))}
        </Select>
        {actionCommonProps.error && <FormHelperText>{actionCommonProps.helperText}</FormHelperText>}
      </FormControl>
      {transformationActionNode}
    </Stack>
  );
};
