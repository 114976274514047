import { useMemo } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Divider, List, ListItem, ListItemText, ListSubheader, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";

import { type CommonHeights } from "./AsgConfiguration";
import { CONFIG_WIDTH, DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./consts";
import { useMaxHeight } from "./hooks";

type Props = {
  instanceTypeHeights: CommonHeights;
  azHeights: CommonHeights;
};

export const AsgsConfigurationTitles = ({ instanceTypeHeights, azHeights }: Props) => {
  const instanceTypeHeight = useMaxHeight(instanceTypeHeights);
  const azHeight = useMaxHeight(azHeights);

  const config = useMemo(
    () =>
      [
        {
          text: "On-Demand Base Capacity",
          tooltip:
            "Specify the minimum number of instances for the Auto Scaling group's initial capacity that must be fulfilled by On-Demand Instances",
        },
        {
          text: "On-Demand Instances",
          tooltip: "Define the percentage of On-Demand Instances for your additional capacity beyond the base portion.",
        },
        {
          text: "Spot Instances",
          tooltip: "Define the percentage of Spot Instances for your additional capacity beyond the base portion.",
        },
        {
          text: "Allowed Instance Types",
          tooltip: "The recommended instance types to provision based on the primary instance type.",
          height: instanceTypeHeight,
        },
        {
          text: "Availability Zones",
          tooltip: "The Auto Scaling balance the instances across the specified Availability Zones.",
          height: azHeight,
        },
        {
          text: "Desired Capacity",
          tooltip:
            "An Auto Scaling group will start by launching as many instances as are specified for the desired capacity.",
        },
        {
          text: "Minimum Capacity",
          tooltip:
            "The minimum limit ensures that the Auto Scaling Group always has a certain number of instances running at all times.",
        },
        {
          text: "Maximum Capacity",
          tooltip:
            "The maximum limit lets the Auto Scaling Group scale out the number of instances as needed to handle an increase in demand",
        },
        {
          text: "Automatically and continuously optimize and update this ASG",
          tooltip:
            "Spot Scaling constantly looks for savings opportunities and will automatically optimize the Auto Scaling Group.",
          width: 260,
          height: 50,
        },
      ] as { text: string; tooltip: string; height?: number; width?: number }[],
    [instanceTypeHeight, azHeight]
  );

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          minWidth: CONFIG_WIDTH,
        }}
      >
        <List dense subheader={<ListSubheader component="div">Property Name</ListSubheader>}>
          {config.map((current, index) => {
            const height = current.height ?? DEFAULT_HEIGHT;
            const width = current.width ?? DEFAULT_WIDTH;

            return (
              <ListItem
                key={index}
                style={{ height, width }}
                alignItems={height > DEFAULT_HEIGHT ? "flex-start" : "center"}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      pr: 1,
                    }}
                  >
                    <ListItemText primary={current.text} />
                  </Box>
                  <Tooltip title={current.tooltip} placement="top" arrow>
                    <InfoOutlinedIcon htmlColor={grey[600]} fontSize="small" />
                  </Tooltip>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box
        sx={{
          pb: 6,
          pl: 1,
          pr: 1,
        }}
      >
        <Divider orientation="vertical" />
      </Box>
    </Box>
  );
};
