import { Link as RouterLink } from "react-router-dom";
import { type CustomerAuth } from "@doitintl/cmp-models";
import { Link, Stack, Typography } from "@mui/material";

type Props = {
  auth?: CustomerAuth;
  customerId: string;
};
const UsersAuthHelper = ({ auth, customerId }: Props) => (
  <Stack direction="row">
    <Typography
      variant="body2"
      sx={{
        color: "text.secondary",
        pl: "8px",
      }}
    >
      Auto provisioning {auth?.autoProvision?.enabled ? "enabled " : "disabled "}
      <Link
        component={RouterLink}
        to={`/customers/${customerId}/iam/authentication/auth-provider`}
        variant="body2"
        sx={{ textDecoration: "none" }}
      >
        configure
      </Link>
    </Typography>
    <Typography
      variant="body2"
      sx={{
        color: "text.secondary",
        pl: "10px",
        pr: "10px",
      }}
    >
      |
    </Typography>
    <Typography
      variant="body2"
      sx={{
        color: "text.secondary",
      }}
    >
      Single sign-on {auth?.sso?.oidc === "enabled" || auth?.sso?.saml === "enabled" ? "enabled " : "disabled "}
      <Link
        component={RouterLink}
        to={`/customers/${customerId}/iam/authentication`}
        variant="body2"
        sx={{ textDecoration: "none" }}
      >
        configure
      </Link>
    </Typography>
  </Stack>
);

export default UsersAuthHelper;
