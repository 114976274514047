import { useCallback } from "react";

import { Box, Typography } from "@mui/material";

import { cloudflowTexts } from "../../../assets/texts";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { type CloudflowWSnap } from "../../../types/Cloudflow";
import CloudflowRow from "./CloudflowRow";
import { filters, headerColumns } from "./Columns";

type CloudflowBrowserProps = {
  cloudflows?: CloudflowWSnap[];
  loading: boolean;
  createCloudFlowLoading: boolean;
  actions: {
    onCreateCloudflow: () => void;
    onViewRunHistory: (row?: CloudflowWSnap) => void;
    onEdit: (id: string) => void;
    onUnpublish: (id: string) => void;
    onDelete: (id: string) => void;
    onNameClick: (link: string) => void;
  };
};

const CloudflowBrowser = ({
  cloudflows,
  loading,
  createCloudFlowLoading,
  actions: { onCreateCloudflow, onViewRunHistory, onEdit, onUnpublish, onDelete, onNameClick },
}: CloudflowBrowserProps) => {
  const CloudflowRowWrapper = useCallback(
    ({ row }: { row: CloudflowWSnap }) => (
      <CloudflowRow
        row={row}
        actions={{
          onEdit,
          onViewRunHistory,
          onUnpublish,
          onDelete,
          onNameClick,
        }}
      />
    ),
    [onDelete, onEdit, onNameClick, onUnpublish, onViewRunHistory]
  );

  return loading ? (
    <Box
      sx={{
        p: 1,
      }}
    >
      <FilterTableSkeleton />
    </Box>
  ) : (
    <FilterTable<CloudflowWSnap>
      showRowsSelection={true}
      tableItems={cloudflows}
      rowComponent={CloudflowRowWrapper}
      headerColumns={headerColumns}
      filterColumns={filters}
      filterBarPlaceholder={cloudflowTexts.FILTER_TABLE}
      persistenceKey="cloudflows_v1"
      emptyTableComponent={<Box data-cy="noCloudFlowsFound">{cloudflowTexts.NO_CLOUDFLOWS_FOUND}</Box>}
      defaultSortDirection="desc"
      itemUniqIdentifierField="ref.id"
      defaultSortingColumnValue="data.updatedAt"
      toolbarProps={{
        title: <Typography variant="h4">{cloudflowTexts.YOUR_CLOUDFLOWS}</Typography>,
        secondaryButtons: [
          {
            text: cloudflowTexts.VIEW_RUN_HISTORY,
            onClick: () => {
              onViewRunHistory();
            },
            "data-cy": "viewRunHistoryToolbar",
          },
        ],
        primaryButton: {
          text: cloudflowTexts.CREATE_CLOUDFLOW,
          onClick: onCreateCloudflow,
          "data-cy": "createCloudflow",
          disabled: createCloudFlowLoading,
        },
      }}
    />
  );
};

export default CloudflowBrowser;
