import {
  type AWSFlexsaveConfigurationModel,
  type CurrencySymbol,
  type FlexsaveRDS,
  type FlexsaveSageMaker,
  type MonthSavings,
  type TotalSavings,
} from "@doitintl/cmp-models";
import { Link, Typography } from "@mui/material";
import sumBy from "lodash/sumBy";

import { type StateInfoData } from "../Common/Onboarding/types";
import { calculateSavingsRateForActiveMonths } from "../utils/costUtils";
import { type JourneyStep } from "./constants";

export type SavingsOverview = {
  monthDate: Date;
  monthSavings: number;
  estimatedSavings: number;
  totalSavings: number;
  savingsRate: number;
  monthlySavings: MonthSavings[];
};

export const getTotalSavings = (data: { savingsHistory: Record<string, MonthSavings> | null }): TotalSavings => {
  const monthSavings: MonthSavings[] = Object.values(data?.savingsHistory ?? {});

  const savings = Math.round(sumBy(monthSavings, "savings"));
  const savingsRate = calculateSavingsRateForActiveMonths(monthSavings);

  return {
    savings,
    savingsRate,
  };
};

export const getMonthDate = (currentMonthString: string): Date => {
  const [month, year] = currentMonthString.split("_");
  const value = new Date(Number(year), Number(month) - 1);
  if (isNaN(value.getTime())) {
    throw new Error(`Invalid date: ${currentMonthString}`);
  }

  return value;
};

export const computeSavingsOverview = (computeData: AWSFlexsaveConfigurationModel): SavingsOverview => {
  const currentMonth = computeData.savingsSummary?.currentMonth?.month;
  const monthlySavings: MonthSavings[] = Object.values(computeData?.savingsHistory ?? {});
  const totalSavings = getTotalSavings(computeData);

  return {
    monthDate: currentMonth ? getMonthDate(currentMonth) : new Date(),
    monthSavings: (currentMonth && computeData.savingsHistory?.[currentMonth]?.savings) || 0,
    estimatedSavings: computeData.savingsSummary?.nextMonth?.savings ?? 0,
    totalSavings: totalSavings.savings,
    savingsRate: totalSavings.savingsRate,
    monthlySavings,
  };
};

export const generateServiceSavingsReport = <T extends FlexsaveSageMaker | FlexsaveRDS>(data: T): SavingsOverview => {
  const currentMonth = data.savingsSummary.currentMonth;
  const monthlySavings: MonthSavings[] = Object.values(data?.savingsHistory);

  return {
    monthDate: getMonthDate(currentMonth),
    monthSavings: data.savingsHistory[currentMonth]?.savings || 0,
    estimatedSavings: data.savingsSummary.nextMonthSavings,
    totalSavings: monthlySavings.reduce((acc, item) => acc + item.savings, 0),
    savingsRate: calculateSavingsRateForActiveMonths(monthlySavings),
    monthlySavings,
  };
};

export function unifiedSavingsOverview(overviews: SavingsOverview[]): SavingsOverview {
  const sortedOverviews = overviews.sort((a, b) => b.monthDate.getTime() - a.monthDate.getTime());

  const latestMonthDate = sortedOverviews[0].monthDate;

  const latestDateOverviews = sortedOverviews.filter(
    (overview) =>
      overview.monthDate.getFullYear() === latestMonthDate.getFullYear() &&
      overview.monthDate.getMonth() === latestMonthDate.getMonth()
  );

  const monthSavings = latestDateOverviews.reduce((sum, overview) => sum + overview.monthSavings, 0);
  const estimatedSavings = latestDateOverviews.reduce((sum, overview) => sum + overview.estimatedSavings, 0);

  const monthlySavings = overviews.flatMap((overview) => overview.monthlySavings);
  const totalSavings = monthlySavings.reduce((sum, ms) => sum + ms.savings, 0);
  const savingsRate = calculateSavingsRateForActiveMonths(monthlySavings);

  return {
    monthDate: latestMonthDate,
    monthSavings,
    estimatedSavings,
    totalSavings,
    savingsRate,
    monthlySavings,
  };
}

export const getStateHeader = (
  data: SavingsOverview | undefined,
  customerId: string,
  step: JourneyStep,
  doitEmployee: boolean,
  currencySymbol: CurrencySymbol
): StateInfoData => {
  const supportLink = `https://console.doit.com/customers/${customerId}/support/new`;
  const monthSavings = Math.round(data?.monthSavings || 0).toLocaleString();

  switch (step) {
    case "checkingForOpportunities":
      return {
        infoBoxStatus: "checking",
        title: "We are checking for savings opportunities",
        subtitle:
          "Flexsave is analyzing your billing data to identify eligible workloads. If there are savings opportunities we will begin applying discounts automatically.",
      };
    case "applyingSavingsPlans": {
      const estimatedSavings = Math.round(data?.estimatedSavings || 0);
      return {
        infoBoxStatus: "working",
        title:
          estimatedSavings > 0
            ? `You could save an estimated ${currencySymbol}${estimatedSavings} a month`
            : "You are on your way to saving with Flexsave",
        subtitle:
          "Congratulations! You're on your way to savings. We have begun to apply Flexsave to your workloads. Check back soon to see your progress.",
      };
    }
    case "generatingSavings": {
      return {
        infoBoxStatus: "active",
        title: `You've saved ${currencySymbol}${monthSavings}`,
        subtitle:
          "Flexsave is now covering your stable on-demand workloads. You no longer need to worry about commitment management or forecasting to save on your workloads.",
      };
    }
    case "invoicing": {
      const subtitle = (
        <Typography>
          Your first DoiT invoice with Flexsave is coming soon. Learn how{" "}
          <Link href="https://help.doit.com/docs/flexsave/aws/flexsave-aws-invoicing">
            Flexsave savings appear on your invoice.
          </Link>
        </Typography>
      );

      return {
        infoBoxStatus: "active",
        title: "Understand your first invoice with Flexsave",
        subtitle,
      };
    }
    case "onboarded": {
      const savingsRate = data?.savingsRate.toPrecision(2);
      const totalSavings = Math.round(data?.totalSavings || 0).toLocaleString();
      const month = data?.monthDate.toLocaleString("default", {
        month: "long",
      });

      const subtitle = (
        <Typography>
          Your all-time effective savings rate is{" "}
          <Typography
            sx={{
              fontWeight: 500,
              display: "inline",
            }}
          >
            {savingsRate}%
          </Typography>
          , resulting in a lifetime savings of{" "}
          <Typography
            sx={{
              fontWeight: 500,
              display: "inline",
            }}
          >
            {currencySymbol}
            {totalSavings}
          </Typography>{" "}
          on Flexsave covered workloads.
        </Typography>
      );

      return {
        infoBoxStatus: "active",
        title: `You have saved ${currencySymbol}${monthSavings} in ${month}`,
        subtitle,
      };
    }
    case "noSavingsOpportunities":
      return {
        infoBoxStatus: "disabled",
        title: "Sorry, no savings opportunities are available",
        subtitle:
          "We will continue to monitor your workloads. Flexsave will begin covering them as soon as savings opportunities are identified.",
      };
    case "disabled": {
      const subtitle = (
        <Typography>
          Your account is not currently enabled for Flexsave. If you believe this is an error or to activate, please{" "}
          <Link href={supportLink}> contact support</Link>
        </Typography>
      );
      return {
        infoBoxStatus: "disabled",
        title: "Flexsave is not currently enabled for this account",
        subtitle,
      };
    }
    case "hasActivateCredits": {
      const subtitle = (
        <Typography>
          We have detected credits on your account. Flexsave discount results in charges not covered by credits. Contact{" "}
          <Link href={supportLink}> support</Link> if you would still like to activate.
        </Typography>
      );
      return {
        infoBoxStatus: "disabled",
        title: "Flexsave is not currently enabled for this account",
        subtitle,
      };
    }
    case "noContract": {
      const subtitle = (
        <Typography>
          {" "}
          We have detected a problem with your configuration. Contact <Link href={supportLink}> support</Link> for
          assistance.{doitEmployee && <span style={{ fontWeight: "bold" }}> Error: No contract</span>}
        </Typography>
      );
      return {
        infoBoxStatus: "disabled",
        title: "Flexsave is not currently enabled for this account",
        subtitle,
      };
    }
    default: {
      const subtitle = (
        <Typography>
          An unexpected error has occurred. Please <Link href={supportLink}> contact support </Link>
          to get assistance with this issue. We apologize for the inconvenience.
        </Typography>
      );
      return {
        infoBoxStatus: "error",
        title: "We have detected an issue with your configuration",
        subtitle,
      };
    }
  }
};
