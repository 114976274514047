import { type FC } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { type SxProps, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { useAuthContext } from "../../../Context/AuthContext";

export const cyIds = {
  instructions: "ms-teams-instructions",
  button: "ms-teams-button",
};

export type MsTeamsInstructionsProps = {
  openApp: () => void; // This may change to Promise<void> when the integration backend is available
};

export const MsTeamsInstructions: FC<MsTeamsInstructionsProps> = ({ openApp }) => {
  const { isDoitEmployee } = useAuthContext();
  const theme = useTheme();

  const itemStyle: SxProps = { display: "list-item", pl: 0, ml: "2ch" };

  return (
    <Box
      data-cy={cyIds.instructions}
      sx={{
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: theme.shape.borderRadius / 4, // Achieves 4px from the designs whilst also tying it to the theme
        p: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
        }}
      >
        How to connect Microsoft Teams to the DoiT console
      </Typography>
      <List component="ol" sx={{ listStyle: "decimal" }}>
        <ListItem component="li" sx={itemStyle}>
          <Typography variant="body2" component="span">
            Use the link below to open the DoiT app and add it to a team. We will connect the general team from this
            channel.
          </Typography>
          <Button
            variant="outlined"
            onClick={openApp}
            disabled={isDoitEmployee}
            sx={{ display: "flex", mt: 2, ml: -2 }}
            data-cy={cyIds.button}
            startIcon={<OpenInNewIcon fontSize="small" />}
            aria-description="Open DoiT MS Teams app"
          >
            Open DoiT app
          </Button>
        </ListItem>
        <ListItem component="li" sx={itemStyle}>
          <Typography variant="body2" component="span">
            After installing the app, you'll receive a message in your channel. Select ‘Complete setup’ to continue.
          </Typography>
        </ListItem>
        <ListItem component="li" sx={itemStyle}>
          <Typography variant="body2" component="span">
            In the DoiT console on the Microsoft Teams page, approve the connection to the DoiT app for Microsoft Teams.
          </Typography>
        </ListItem>
      </List>
      <Typography variant="body2">
        <Typography
          component="strong"
          sx={{
            fontWeight: 500,
            mr: "0.5ch",
          }}
        >
          Note:
        </Typography>
        Repeat all the steps for each team you want to integrate with the app
      </Typography>
    </Box>
  );
};
