import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Link, Typography } from "@mui/material";

import { IncludedWithPlanInfo } from "../../../Components/IncludedWithPlanInfo/IncludedWithPlanInfo";

type Props = {
  isEntitled: boolean;
};

export const ThreadsTooltip = ({ isEntitled }: Props) => (
  <Box
    sx={{
      p: 2,
      width: "300px",
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: 500,
        mb: 1,
      }}
    >
      From potential to real: turn savings into reality with DoiT Threads
    </Typography>
    <IncludedWithPlanInfo isIncluded={isEntitled} />
    <Typography variant="body2">
      DoiT Threads connects Cloud Navigator with your Jira to generate and track actionable insights directly assigned
      to your Engineering teams.
    </Typography>
    <ul style={{ paddingLeft: "15px" }}>
      <li>
        <Typography variant="body2">Create tasks with a single click in Jira</Typography>
      </li>
      <li>
        <Typography variant="body2">Prioritize based on impact and implementation complexity</Typography>
      </li>
      <li>
        <Typography variant="body2">Collaborate across departments and with non-technical stakeholders</Typography>
      </li>
    </ul>
    <Link
      color="primary"
      sx={{ textDecoration: "none" }}
      href="https://help.doit.com/docs/governance/threads"
      target="_blank"
      rel="noreferrer"
    >
      <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
        Learn more about DoiT Threads
        <OpenInNewIcon sx={{ ml: 0.5, fontSize: "14px" }} />
      </Typography>
    </Link>
  </Box>
);
