import { IconButton, ListItemText, MenuItem, MenuList, Stack, Typography } from "@mui/material";

import { type SectionItem } from "./SideNavigationMenu";

type Props = {
  section: SectionItem;
};

export function MenuSection({ section }: Props) {
  return (
    <Stack data-cy={section.name}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          pl: 2,
          alignItems: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "text.secondary",
            pt: 2,
            pb: 1,
          }}
        >
          {section.name}
        </Typography>
        {section.sideIcon && (
          <IconButton
            size="small"
            disabled={section.sideIconDisabled}
            onClick={section.sideIconOnClick}
            sx={{
              mt: 0.5,
            }}
          >
            {section.sideIcon}
          </IconButton>
        )}
      </Stack>
      {section.items.length > 0 ? (
        <MenuList sx={{ px: 1, pt: 0 }}>
          {section.items.map((item) => (
            <MenuItem
              key={item.name}
              onClick={item.onClick}
              sx={
                item.selected
                  ? {
                      p: 0,
                      color: (theme) => theme.palette.primary.contrastText,
                    }
                  : {
                      p: 0,
                      borderRadius: "4px",
                      "&:hover": { bgcolor: (theme) => theme.palette.primary.hoverBackground },
                    }
              }
            >
              <ListItemText tabIndex={0} data-cy={item.name}>
                <Typography
                  noWrap={true}
                  variant="body2"
                  sx={{
                    p: 1,
                    borderRadius: "4px",
                    fontWeight: item.selected ? 500 : 400,
                    bgcolor: item.selected ? "primary.main" : undefined,
                  }}
                >
                  {item.name}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      ) : (
        <Typography
          variant="body2"
          sx={{
            color: "text.disabled",
            pt: 1,
            px: 2,
          }}
        >
          {section.emptyItemsLabel}
        </Typography>
      )}
    </Stack>
  );
}
