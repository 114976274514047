import { type ChangeEvent, useCallback, useEffect, useState } from "react";

import { Metric, type MetricFilter, MetricFilterOperator } from "@doitintl/cmp-models";
import { DialogContent, DialogContentText, MenuItem, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { metricFiltersText } from "../../../../assets/texts";
import SimpleDialog from "../../../../Components/SimpleDialog";
import { type MetadataOption, type MetricWSnap } from "../../../../types";
import { onKeyPressPreventNonNumericAllowFloatNegative } from "../../../../utils/common";
import { useFullScreen } from "../../../../utils/dialog";
import { textFieldBaseProps, textFieldSelectProps } from "../../budgets/shared";
import { CSPMetric, MetricFiltersOptions, MetricOptions } from "../../utilities";

const useStyles = makeStyles((theme) => ({
  inputField: {
    paddingLeft: theme.spacing(1),
  },
}));

export type MetricFiltersDialogProps = {
  calculatedMetric: MetricWSnap | null;
  extendedMetric?: string;
  isCSP: boolean;
  metricFilter: MetricFilter;
  onCancel: (item?: MetadataOption) => void;
  onConfirm: (filters: MetricFilter) => void;
  open: boolean;
  selected?: MetadataOption;
};

export const MetricFiltersDialog = ({
  calculatedMetric,
  extendedMetric,
  isCSP,
  metricFilter,
  onCancel,
  onConfirm,
  open,
  selected,
}: MetricFiltersDialogProps) => {
  const [metric, setMetric] = useState(metricFilter.metric);
  const [operator, setOperator] = useState(metricFilter.operator);
  // value1 - for all operators except BETWEEN is the value filter operates on
  //          for BETWEEN operator is a lower filter boundary
  const [value1, setValue1] = useState<number | undefined>(undefined);
  // value2 - for all operators except BETWEEN is undefined
  //          for BETWEEN operator is a higher filter boundary
  const [value2, setValue2] = useState<number | undefined>(undefined);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const classes = useStyles();
  const { isMobile } = useFullScreen("sm");

  const isBetweenOperators = useCallback(
    () => operator === MetricFilterOperator.BETWEEN || operator === MetricFilterOperator.NOT_BETWEEN,
    [operator]
  );

  useEffect(() => {
    setMetric(metricFilter.metric);
  }, [metricFilter.metric]);

  useEffect(() => {
    if (value1 === undefined || (isBetweenOperators() && (value2 === undefined || value2 < value1))) {
      setDisableConfirm(true);
    } else {
      setDisableConfirm(false);
    }
  }, [value1, value2, isBetweenOperators]);

  useEffect(() => {
    setOperator(metricFilter.operator);
  }, [metricFilter.operator]);

  useEffect(() => {
    setValue1(metricFilter.values?.length > 0 ? metricFilter.values[0] : undefined);
    setValue2(metricFilter.values?.length > 0 ? metricFilter.values[1] : undefined);
  }, [metricFilter.values]);

  const handleConfirm = useCallback(() => {
    let values: number[] = [];
    if (value1 !== undefined) {
      values = [value1];
    }
    if (isBetweenOperators() && value2 !== undefined) {
      values.push(value2);
    }
    const mf: MetricFilter = {
      metric,
      operator,
      values,
    };
    onConfirm(mf);
  }, [onConfirm, operator, value1, value2, metric, isBetweenOperators]);

  const handleCancel = useCallback(() => {
    if (!value1 || (isBetweenOperators() && !value2 && metricFilter.values?.length === 0)) {
      onCancel(selected);
      return;
    }
    onCancel();
  }, [isBetweenOperators, metricFilter.values?.length, onCancel, selected, value1, value2]);

  const getPositiveNumericValue = (v: string): number | undefined => {
    let parseValue = true;
    const idx = v.indexOf(".");
    if (idx !== -1) {
      parseValue = false;
      for (let i = idx + 1; i < v.length; i++) {
        parseValue = v[i] !== "0";
      }
    }
    return parseValue && v !== "" && v.slice(-1) !== "." ? parseFloat(v) : undefined;
  };

  const getNumericValue = (v: string): number | undefined => {
    if (v[0] === "-") {
      const value = getPositiveNumericValue(v.substring(1));
      return value !== undefined && value !== 0 ? -1 * value : undefined;
    }
    return getPositiveNumericValue(v);
  };

  return (
    <SimpleDialog
      open={open}
      title={metricFiltersText.TITLE}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmButtonText={metricFiltersText.DIALOG_CONFIRM}
      disableConfirm={disableConfirm}
      dialogProps={{
        fullWidth: true,
      }}
    >
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          <Typography variant="body2">{metricFiltersText.DIALOG_SUB_TITLE}</Typography>
        </DialogContentText>
        <Grid container size={12}>
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <TextField
              label={metricFiltersText.METRIC_VALUE_LABEL}
              value={metric}
              {...textFieldBaseProps}
              {...textFieldSelectProps}
              onChange={(e: ChangeEvent<any>) => {
                setMetric(e.target.value);
              }}
            >
              {MetricOptions.map((metricOption) => (
                <MenuItem key={metricOption.value} value={metricOption.value} dense>
                  {metricOption.label}
                </MenuItem>
              ))}
              {isCSP && (
                <MenuItem key={CSPMetric.value} value={CSPMetric.value} dense>
                  {CSPMetric.label}
                </MenuItem>
              )}
              {calculatedMetric && (
                <MenuItem key={calculatedMetric.snapshot.id} value={Metric.CALCULATED} dense>
                  {calculatedMetric.data.name}
                </MenuItem>
              )}
              {!!extendedMetric && (
                <MenuItem key={Metric.EXTENDED} value={Metric.EXTENDED} dense>
                  {extendedMetric}
                </MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid
            className={clsx({ [classes.inputField]: !isMobile })}
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <TextField
              label={metricFiltersText.OPERATOR_LABEL}
              value={operator}
              {...textFieldBaseProps}
              {...textFieldSelectProps}
              onChange={(e) => {
                setOperator(e.target.value as MetricFilterOperator);
              }}
            >
              {MetricFiltersOptions.map((mfo, i) => (
                <MenuItem key={i} value={mfo.value} dense>
                  {mfo.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            className={clsx({ [classes.inputField]: !isMobile })}
            size={{
              xs: 12,
              sm: isBetweenOperators() ? 2 : 4,
            }}
          >
            <TextField
              label={metricFiltersText.VALUE_LABEL}
              {...textFieldBaseProps}
              value={value1}
              onKeyPress={onKeyPressPreventNonNumericAllowFloatNegative}
              onChange={(e) => {
                setValue1(getNumericValue(e.target.value));
              }}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          {isBetweenOperators() && (
            <Grid
              className={clsx({ [classes.inputField]: !isMobile })}
              size={{
                xs: 12,
                sm: 2,
              }}
            >
              <TextField
                label={metricFiltersText.VALUE_LABEL}
                {...textFieldBaseProps}
                value={value2}
                onKeyPress={onKeyPressPreventNonNumericAllowFloatNegative}
                onChange={(e) => {
                  setValue2(getNumericValue(e.target.value));
                }}
                variant="outlined"
                margin="dense"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </SimpleDialog>
  );
};

export default MetricFiltersDialog;
