import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";

import lightLogo from "../../assets/404-graphic.svg";
import darkLogo from "../../assets/404-graphic-dark.svg";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { useUserContext } from "../../Context/UserContext";

const NotFound = () => {
  const darkMode = useDarkThemeCheck();
  const { userRoles } = useUserContext();

  return (
    <Stack
      sx={{
        mt: 25,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component="img"
        src={darkMode ? darkLogo : lightLogo}
        sx={{ height: "100px", mb: "-4px", mr: 1 }}
        alt="404 icon"
      />
      <Typography
        variant="h1"
        align="center"
        sx={{
          mt: { xs: 1, sm: 2 },
        }}
      >
        Sorry, this page could not be found
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{
          color: "text.secondary",
          mt: { xs: 1, sm: 1 },
        }}
      >
        The page you are looking for may have been moved or no longer exists.
      </Typography>
      <Button
        sx={{ mt: { xs: 1, sm: 2 }, width: "150px" }}
        color="primary"
        component={RouterLink}
        variant="contained"
        size="medium"
        data-cy="back-home-cta"
        to={userRoles?.doitEmployee ? "/customers" : "/"}
      >
        Go to {userRoles?.doitEmployee ? "Manage" : "Dashboard"}
      </Button>
    </Stack>
  );
};

export default NotFound;
