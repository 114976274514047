import { AnalyticsDataSource } from "@doitintl/cmp-models";
import { MailOutline, OpenInNew } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";

import { DATAHUB_LINK, datahubTxt, SASS_EMAIL } from "../../assets/texts/DataHub/datahub";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { IncludedWithPlanInfo } from "../IncludedWithPlanInfo/IncludedWithPlanInfo";

type Props = {
  clouds?: string[];
  customerId: string;
  dataSource: AnalyticsDataSource;
  showClouds: boolean;
};

const DataHubTooltip = ({ clouds, customerId, dataSource, showClouds }: Props) => {
  const isDataHubEntitled = useIsFeatureEntitled("pdi:datahub");

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
          mb: 1,
        }}
      >
        {dataSource === AnalyticsDataSource.BILLING
          ? datahubTxt.BILLING_TOOLTIP_TITLE
          : datahubTxt.DATA_HUB_TOOLTIP_TITLE}
      </Typography>
      <Typography variant="body2">
        {dataSource === AnalyticsDataSource.BILLING_DATAHUB && (
          <>
            {showClouds && clouds && (
              <>
                {datahubTxt.DATA_HUB_TOOLTIP_TEXT}
                <Box component="ul" sx={{ pl: 2 }}>
                  {clouds.slice(0, 3).map((c) => (
                    <li key={`data-hub-api-tooltip-${c}`}>{c}</li>
                  ))}
                </Box>
              </>
            )}

            {!showClouds && <Box component="p">{datahubTxt.DATA_HUB_QUERY_TOOLTIP_TEXT}</Box>}

            {datahubTxt.MANAGE_DATASETS}
            <Link onClick={() => window.open(`/customers/${customerId}/datasets`, "_blank")} target="_blank">
              {datahubTxt.CLICK_HERE}
            </Link>
          </>
        )}

        {dataSource === AnalyticsDataSource.BILLING && (
          <>
            <IncludedWithPlanInfo isIncluded={!!isDataHubEntitled} />
            {datahubTxt.BILLING_TOOLTIP_DESC}

            <Box component="ul" sx={{ pl: 2 }}>
              {datahubTxt.BILLING_DESC_OPTIONS.map((val) => (
                <li key={val}>{val}</li>
              ))}
            </Box>
            <Link
              href={isDataHubEntitled ? DATAHUB_LINK : SASS_EMAIL}
              underline="none"
              target="_blank"
              sx={{ display: "inline-flex", alignItems: "center", gap: 1 }}
            >
              {isDataHubEntitled ? datahubTxt.INCLUDE_IN_PLAN_LINK_TEXT : datahubTxt.NOT_INCLUDE_IN_PLAN_LINK_TEXT}
              {isDataHubEntitled ? <OpenInNew fontSize="inherit" /> : <MailOutline fontSize="inherit" />}
            </Link>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default DataHubTooltip;
