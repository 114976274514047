import TextField from "@mui/material/TextField";

type Props = {
  value: string;
};
export const BillingAccountField = (props: Props) => (
  <TextField
    name="billingAccountId"
    label="Billing account ID"
    variant="outlined"
    disabled
    value={props.value || ""}
    fullWidth
    size="medium"
    slotProps={{
      inputLabel: {
        shrink: true,
      },
    }}
  />
);
