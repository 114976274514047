import { useCallback, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { formatDecimalNumber } from "../../../../../utils/common";
import { preventOnCloseOnBackdropClick } from "../../../../../utils/dialog";
import { formatAsDollars } from "../../../utils/costUtils";
import { type PurchaseType } from "../types";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleApprove: () => Promise<void>;
  totalCost: number;
  customerCount: number;
  customerName?: string;
  cudCount: number;
  purchaseType?: PurchaseType;
};

export const PurchaseConfirmationDialog = ({
  open,
  handleClose,
  handleApprove,
  totalCost,
  customerCount,
  customerName,
  cudCount,
  purchaseType = "CustomerPurchase",
}: Props) => {
  const [waitingForPurchaseToStart, setWaitingForPurchaseToStart] = useState<boolean>(false);

  const onPurchaseClicked = useCallback(async () => {
    setWaitingForPurchaseToStart(true);
    await handleApprove();

    setWaitingForPurchaseToStart(false);
    handleClose();
  }, [handleApprove, handleClose]);

  return (
    <Dialog open={open} disableEscapeKeyDown onClose={preventOnCloseOnBackdropClick(handleClose)} fullWidth>
      <DialogTitle>Purchase confirmation</DialogTitle>
      <DialogContent>
        {customerCount === 1 && purchaseType === "CustomerPurchase" ? (
          <Typography
            sx={{
              pt: 1,
            }}
          >
            Purchase <b>{formatDecimalNumber(cudCount, 0)} CUDs</b> for <b>{customerName}</b>.
          </Typography>
        ) : (
          <Typography
            sx={{
              pt: 1,
            }}
          >
            Purchase <b>{formatDecimalNumber(cudCount, 0)} CUDs</b> for <b>{customerCount} customers</b>.
          </Typography>
        )}
        <Stack
          sx={{
            pt: 3,
          }}
        >
          <Typography>Total cost</Typography>
          <Typography>
            <b>{formatAsDollars(totalCost || 0)}</b>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: 1, borderColor: "divider" }}>
        <Button disabled={waitingForPurchaseToStart} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={waitingForPurchaseToStart}
          disabled={waitingForPurchaseToStart}
          onClick={onPurchaseClicked}
          autoFocus
        >
          Purchase
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
