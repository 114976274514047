import { type ChangeEvent, useCallback, useState } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import isEmail from "is-email";

import { useSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { useUserContext } from "../../../../../Context/UserContext";
import { useFullScreen } from "../../../../../utils/dialog";
import useCreateTicket from "../../../../MPA/hooks/useCreateTicket";

export const cyIds = {
  notice: "lookerLicenseDialogNotice",
  fields: {
    email: "lookerLicenseDialogEmail",
    description: "lookerLicenseDialogDescription",
  },
  buttons: {
    cancel: "lookerLicenseDialogCancel",
    submit: "lookerLicenseDialogSubmit",
  },
};

export type LookerLicenseRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  sku: string;
};

const LookerLicenseRequestDialog = ({ open, onClose, sku }: LookerLicenseRequestDialogProps) => {
  const { fullScreen } = useFullScreen();
  const { userModel } = useUserContext();
  const { customer } = useCustomerContext();
  const { createTicket } = useCreateTicket();
  const snackbar = useSnackbar();

  const [customerEmail, setCustomerEmail] = useState<string | undefined>(userModel?.email);
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [requestText, setRequestText] = useState<string | undefined>();
  const [requestTextError, setRequestTextError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      const response = await createTicket(customer.id, {
        categoryField: "contracts",
        platformField: "google_cloud_platform",
        productField: "looker",
        priority: "normal",
        subject: "Looker license query",
        description: `Request contact: ${customerEmail}, sku: ${sku}, Request: ${requestText}`,
        tags: [
          "gcp/platform",
          "gcp/service/looker",
          "gcp/category/data-analytics",
          "gcp/category/hybrid-and-multicloud",
        ],
      });
      snackbar.onOpen({
        message: `Successfully created ticket ${response.data.id}`,
        variant: "success",
        autoHideDuration: 20000,
        withClose: true,
      });
      setCustomerEmail(userModel?.email);
      setRequestText(undefined);
      onClose();
    } catch (e) {
      snackbar.onOpen({
        message: "Unable to create request ticket",
        variant: "error",
        autoHideDuration: 30000,
        withClose: true,
      });
    }
    setSubmitting(false);
  }, [createTicket, customer.id, customerEmail, onClose, requestText, sku, snackbar, userModel?.email]);

  const handleEmailChange = useCallback<(event: ChangeEvent<HTMLTextAreaElement>) => void>((event) => {
    const newEmail: string = event.target.value;
    if (!isEmail(newEmail)) {
      setEmailInputError(true);
      return;
    }
    setEmailInputError(false);
    setCustomerEmail(newEmail);
  }, []);

  const handleRequestTextChange = useCallback<(event: ChangeEvent<HTMLTextAreaElement>) => void>((event) => {
    const newRequest: string = event.target.value;
    if (!newRequest.trim().length) {
      setRequestTextError(true);
      return;
    }
    setRequestTextError(false);
    setRequestText(newRequest);
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle>Make license request</DialogTitle>
      <DialogContent sx={{ minWidth: fullScreen ? "100%" : 600, minHeight: 325, maxHeight: 464 }}>
        <Stack
          sx={{
            gap: 5,
            pt: 3,
          }}
        >
          <TextField
            onChange={handleEmailChange}
            label="Email address"
            defaultValue={customerEmail}
            error={emailInputError}
            helperText={emailInputError && "Please enter a valid email"}
            required
            fullWidth
            type="email"
            data-cy={cyIds.fields.email}
            size="medium"
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
          <TextField
            // value={requestText}
            onChange={handleRequestTextChange}
            label="Brief description of your request"
            error={requestTextError}
            helperText={requestTextError && "The request description cannot be empty"}
            required
            fullWidth
            type="text"
            data-cy={cyIds.fields.description}
            size="medium"
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
          <Alert severity="info" data-cy={cyIds.notice}>
            <Typography variant="body2" component="span">
              Completing this form will <Typography component="strong">NOT</Typography> change your license quantity. It
              will notify DoiT of your interest. Someone will contact you within 48 hours.
            </Typography>
          </Alert>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction="row"
          sx={{
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button onClick={onClose} variant="text" data-cy={cyIds.buttons.cancel}>
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            disabled={emailInputError || requestTextError || submitting}
            variant="contained"
            data-cy={cyIds.buttons.submit}
          >
            Send request
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LookerLicenseRequestDialog;
