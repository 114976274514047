import { Link } from "react-router-dom";
import { Link as MUILink, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../Context/CustomerContext";

export const EmptyAlertList = () => {
  const { customer } = useCustomerContext();
  const settingsUrl = `/customers/${customer.id}/notifications`;
  const costAnomaliesUrl = "https://help.doit.com/docs/governance/cloud-anomalies";

  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "center",
        my: "70px",
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "24px",
          lineHeight: "32px",
        }}
      >
        No cost anomalies detected
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        Great news, we haven’t detected any cost anomalies yet! When we do, they will be listed here.
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        In the meantime, learn about{" "}
        <MUILink
          component={Link}
          to={{ pathname: costAnomaliesUrl }}
          target="_blank"
          underline="hover"
          color="primary.main"
        >
          cost anomalies
        </MUILink>{" "}
        or review your{" "}
        <MUILink component={Link} to={settingsUrl} underline="hover" color="primary.main">
          notification settings
        </MUILink>
        .
      </Typography>
    </Stack>
  );
};
