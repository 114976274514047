import { NavLink } from "react-router-dom";
import { type FlexsaveType } from "@doitintl/cmp-models";
import { Box, Button, Typography } from "@mui/material";

import { type PotentialFlexsaveSavings } from "../../../Pages/Flexsave/types";
import { useCurrencyFormat } from "../../hooks/useCurrencyFormat";
import { FlexsaveWidgetCard } from "../Common/FlexsaveWidgetCard";
import { getFlexsaveWidgetTitle } from "./utils";

type Props = {
  canEnable: PotentialFlexsaveSavings;
  flexSaveLink: string;
  type: FlexsaveType;
  widgetHeight: number;
};

export const FlexsaveCardCanEnable = ({ canEnable, flexSaveLink, type, widgetHeight }: Props) => {
  const currencyFormat = useCurrencyFormat();

  return (
    <FlexsaveWidgetCard
      title={getFlexsaveWidgetTitle(type)}
      subheader="Get exclusive access to wholesale inventory of reserved compute resources"
      widgetHeight={widgetHeight}
    >
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "80%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">Your estimated monthly savings are:</Typography>
          <Typography variant="h6">{currencyFormat(canEnable.nextMonth.savings)}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button component={NavLink} variant="contained" color="primary" to={flexSaveLink}>
            Discover Flexsave
          </Button>
        </Box>
      </>
    </FlexsaveWidgetCard>
  );
};
