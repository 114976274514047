import { Fragment, type ReactNode, useEffect } from "react";

import { type NotificationSettings } from "@doitintl/cmp-models";
import { type ModelData } from "@doitintl/models-firestore";
import { Box, Divider, FormHelperText, Stack, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";

import { useUnsavedChanges } from "../../Context/UnsavedChangesContext";
import { useAsyncLoadingFunction } from "../hooks/useAsyncLoadingFunction";
import LoadingButton from "../LoadingButton";
import NotificationCheckbox from "./NotificationCheckbox";
import { CostAnomaliesFilters, isCostAnomaliesNotification } from "./NotificationFilters/CostAnomaliesFilters";
import { isInvoiceNotification, NotificationEntitiesFilter } from "./NotificationFilters/EntitiesFilter";
import {
  hasAttributionsFilter,
  NotificationAttributionsFilter,
} from "./NotificationFilters/NotificationAttributionsFilter";
import { type NotificationWithGroupDescriptor } from "./useNotificationDescriptors";
import { useNotificationsFormValidation } from "./useNotificationsFormValidation";

type NotificationsFormProps = {
  notificationDescriptors?: NotificationWithGroupDescriptor[];
  selectedNotifications: ModelData<NotificationSettings>;
  onSubmit: (notifications: ModelData<NotificationSettings>) => Promise<void>;
  handleValidationStatus?: (isValid: boolean) => void;
  disableTargetUpdate: boolean | undefined;
  children?: ReactNode;
};

const UnsavedChangesGuard = ({ dirty }: { dirty: boolean }) => {
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();

  useEffect(() => {
    dirty ? activatePendingPrompt() : clearPendingPrompt();
    return clearPendingPrompt;
  }, [activatePendingPrompt, clearPendingPrompt, dirty]);

  return null;
};

export const NotificationsForm = ({
  notificationDescriptors,
  selectedNotifications,
  onSubmit,
  handleValidationStatus,
  children,
  disableTargetUpdate,
}: NotificationsFormProps) => {
  const { palette } = useTheme();
  const validationSchema = useNotificationsFormValidation(notificationDescriptors);
  const [isSubmitting, doSubmit] = useAsyncLoadingFunction(onSubmit);

  return (
    <Formik<ModelData<NotificationSettings>>
      initialValues={selectedNotifications}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={doSubmit}
    >
      {(formProps) => {
        if (handleValidationStatus) {
          handleValidationStatus(formProps.isValid);
        }

        return (
          <Box
            sx={{
              mb: 9,
            }}
          >
            <UnsavedChangesGuard dirty={formProps.dirty} />
            <Stack>
              {notificationDescriptors?.map((notification) => (
                <Fragment key={notification.name}>
                  {notification.startOfGroup && (
                    <>
                      {notification.group && (
                        <Typography
                          variant="h4"
                          sx={{
                            pt: 2,
                            pb: 1,
                          }}
                        >
                          {notification.group.title}
                        </Typography>
                      )}
                      {notification.group?.description !== undefined && notification.group?.description.length > 0 && (
                        <FormHelperText>{notification.group.description}</FormHelperText>
                      )}
                    </>
                  )}
                  <NotificationCheckbox
                    notification={notification}
                    disabled={!notification.enabled || disableTargetUpdate}
                  >
                    {hasAttributionsFilter(notification) && (
                      <NotificationAttributionsFilter notification={notification} />
                    )}
                    {isCostAnomaliesNotification(notification) && <CostAnomaliesFilters notification={notification} />}
                    {isInvoiceNotification(notification) && (
                      <NotificationEntitiesFilter
                        notification={notification}
                        isOfBillingProfile={!!disableTargetUpdate}
                      />
                    )}
                  </NotificationCheckbox>
                  {notification.endOfGroup && <Divider sx={{ paddingTop: 2 }} />}
                </Fragment>
              ))}
            </Stack>
            {children}
            <Stack
              sx={{
                bottom: 0,
                left: 0,
                zIndex: 1,
                width: "100%",
                position: "fixed",
                borderTop: `1px solid ${palette.action.disabledBackground}`,
                backgroundColor: palette.background.paper,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  maxWidth: "md",
                  width: "md",
                  padding: 1,
                  marginLeft: 30,
                }}
              >
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={formProps.submitForm}
                  disabled={!formProps.dirty || !formProps.isValid}
                  loading={isSubmitting}
                  mixpanelEventId="notifications-form.save"
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        );
      }}
    </Formik>
  );
};
