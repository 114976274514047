import { Box, Divider, Skeleton, Typography } from "@mui/material";

import { UsageMeter, type UsageMeterProps } from "../../UsageMeter/UsageMeter";

export type FilterTableToolbarLimitTitleProps = UsageMeterProps & { loading: boolean };

const FilterTableToolbarMeteredTitle = (props: FilterTableToolbarLimitTitleProps) => {
  const { featureName, value, limit, loading } = props;

  return (
    <Box
      data-cy="filter-table-toolbar-usage-meter"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="h1">{featureName}</Typography>
      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
      {loading ? (
        <Skeleton variant="rounded" width={140} height={15} />
      ) : (
        <UsageMeter featureName={featureName} value={value} limit={limit} />
      )}
    </Box>
  );
};

export default FilterTableToolbarMeteredTitle;
