import { type ReactNode, useMemo, useState } from "react";

import { type GSuiteAssetModel } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Divider, IconButton, Link } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

import { useApiContext } from "../../../api/context";
import { changeGVoiceDialogTexts, globalText } from "../../../assets/texts";
import { CatalogHooks, type LegacyDataFormat } from "../../../Components/Catalog/Catalog.context";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type AnyAsset, type Contract } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { formatCurrency } from "../../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import { googleVoiceEditions } from "../../../utils/gsuite";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minHeight: 240,
  },
  textField: {
    paddingBottom: theme.spacing(1),
  },
}));

type Props = {
  onClose: () => void;
  asset: AnyAsset<GSuiteAssetModel>;
  contract?: Contract;
  catalogItem: LegacyDataFormat;
};

const ChangeGVoiceDialog = ({ onClose, asset, contract, catalogItem }: Props) => {
  const [catalog, catalogLoading] = CatalogHooks.useGSuiteCatalogContext();
  const api = useApiContext();
  const { fullScreen: matches } = useFullScreen("sm");
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState("");

  const { onOpen: openSnackbar, onClose: closeSnackbar } = useSnackbar();

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      await api.request({
        method: "post",
        url: `/licenses/gsuite/customers/${asset.data.properties.customerId}/subscriptions/${asset.data.properties.subscription.subscriptionId}/voice-change`,
        data: {
          asset: asset.id,
          skuId: value,
        },
      });
      openSnackbar({
        message: changeGVoiceDialogTexts.CHANGE_EDITION_SUCCESS,
        variant: "success",
        autoHideDuration: 3000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      onClose();
    } catch (error) {
      consoleErrorWithSentry(error);
      openSnackbar({
        message: changeGVoiceDialogTexts.CHANGE_EDITION_ERROR,
        autoHideDuration: 5000,
        variant: "error",
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    }
    setIsSubmitting(false);
  };

  const { isCommitmentPlan, text } = useMemo(() => {
    let text: ReactNode = null;
    let isCommitmentPlan = false;
    if (!value || !catalog) {
      return { isCommitmentPlan, text };
    }

    const prev = googleVoiceEditions.findIndex((e) => e.skuId === asset.data.properties.subscription.skuId);
    const curr = googleVoiceEditions.findIndex((e) => e.skuId === value);
    const newItem = catalog.find(
      (item) =>
        item.data.skuId === value &&
        item.data.plan === catalogItem.data.plan &&
        item.data.payment === catalogItem.data.payment
    );
    if (newItem) {
      isCommitmentPlan = newItem.data.plan === "ANNUAL";
      const discount = contract?.discount ?? 0;
      const cost = newItem.data.price.USD * (1 - discount * 0.01);
      text = (
        <DialogContentText>
          You are about to <b>{prev < curr ? "upgrade" : "downgrade"}</b> to <b>{newItem.data.skuName}</b> at{" "}
          <b>{formatCurrency(cost, "USD")}</b> per user/{isCommitmentPlan ? "year" : "month"}.
        </DialogContentText>
      );
    }

    return { isCommitmentPlan, text };
  }, [
    asset.data.properties.subscription.skuId,
    catalog,
    catalogItem.data.payment,
    catalogItem.data.plan,
    contract,
    value,
  ]);

  if (catalogLoading) {
    return null;
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="google-voice-dialog"
      onClose={preventOnCloseWhile(isSubmitting, onClose)}
      fullScreen={matches}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="google-voice-dialog">{changeGVoiceDialogTexts.TITLE}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.textField}
          select
          disabled={isSubmitting}
          label="Google Voice"
          margin="normal"
          variant="outlined"
          fullWidth
          data-cy="googleVoiceSelect"
          slotProps={{
            select: {
              value,
              onChange: (event) => {
                setValue(event.target.value as string);
              },
            },
          }}
        >
          {googleVoiceEditions
            .filter((edition) => edition.skuId !== asset.data.properties.subscription.skuId)
            .map((edition) => (
              <MenuItem key={edition.skuId} value={edition.skuId}>
                {edition.skuName}
              </MenuItem>
            ))}
        </TextField>
        <DialogContentText>
          <Link
            href="https://support.google.com/a/answer/9229433?hl=en&ref_topic=9231675#"
            target="_blank"
            rel="noopener noreferrer"
          >
            {changeGVoiceDialogTexts.COMPARE_EDITIONS}
          </Link>
        </DialogContentText>
        {!!value && (
          <>
            {text}
            {isCommitmentPlan && <DialogContentText>{changeGVoiceDialogTexts.COMMITMENT_SUB_TITLE}</DialogContentText>}
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          variant="text"
          onClick={onClose}
          disabled={isSubmitting}
          data-cy="googleVoiceCancelButton"
        >
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={!value || isSubmitting}
          loading={isSubmitting}
          data-cy="googleVoiceSaveButton"
          mixpanelEventId="assets.change-google-voice.save"
        >
          {globalText.SAVE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeGVoiceDialog;
