import { useEffect, useState } from "react";

import { Button, Divider, FormControlLabel, Stack, Switch, Typography } from "@mui/material";

import { SideDrawer } from "../../../Components/SideDrawer";
import { useAuthContext } from "../../../Context/AuthContext";
import { useNotesContext } from "../../../Context/customer/NotesContext";
import { type Customer } from "../../../types";
import { addNote, editNote, pinNote } from "./db";
import { FilterTagDropdown } from "./FilterTagDropdown";
import { useCustomerNotes, useNotesToDisplay, usePinnedNotes } from "./Hooks";
import Note from "./Note/Note";
import { NoteDialog } from "./NoteDialog";
import { type CustomerNote, type TagKeys } from "./types";
import { generateNoteEditPayload, generateNotePayload } from "./utils";

type Props = {
  customer: Customer;
  defaultTag?: TagKeys;
};

export const CustomerNotes = ({ customer, defaultTag }: Props) => {
  const [showMyNotes, setShowMyNotes] = useState(false);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTag, setSelectedTag] = useState<TagKeys | undefined>(defaultTag);
  const [selectedNote, setSelectedNote] = useState<CustomerNote | undefined>(undefined);

  const { set, open, toggle } = useNotesContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const customerNotes = useCustomerNotes(customer);
  const pinnedNotes = usePinnedNotes(customer);
  const customerNoteToShow = useNotesToDisplay(customerNotes, pinnedNotes, selectedTag, showMyNotes);

  useEffect(() => {
    set({
      pinned: pinnedNotes.length,
      unpinned: customerNotes.length,
    });
  }, [customerNotes.length, pinnedNotes.length, set]);

  const handleEditNote = (selectedNote: CustomerNote) => {
    setSelectedNote(selectedNote);
    setEditMode(true);
    setOpenNoteDialog(true);
  };

  const handlePinNote = async (selectedNote: CustomerNote) => {
    setSelectedNote(selectedNote);
    await pinNote(selectedNote);
    setSelectedNote(undefined);
  };

  const handleNoteDialogClose = () => {
    setOpenNoteDialog(false);
    setSelectedNote(undefined);
    setEditMode(false);
  };

  const handleSaveNote = async (note: string, tags: TagKeys[]) => {
    if (editMode && selectedNote) {
      const noteToEdit = generateNoteEditPayload(customer, selectedNote, note, tags, currentUser);
      await editNote(selectedNote.ref, noteToEdit);
    } else {
      const notePayload = generateNotePayload(customer, note, tags, currentUser);
      await addNote(notePayload);
    }
    setOpenNoteDialog(false);
    setSelectedNote(undefined);
    setEditMode(false);
  };

  return (
    <SideDrawer
      open={open}
      title="Customer Notes"
      onClose={toggle}
      variant="persistent"
      headerElement={
        <Button
          variant="outlined"
          sx={{ ml: 10 }}
          onClick={() => {
            setOpenNoteDialog(!openNoteDialog);
          }}
          data-cy="add-note-button"
        >
          Add note
        </Button>
      }
      sideDrawerSx={{ my: 12, boxShadow: 3 }}
    >
      <>
        <Stack
          direction="row"
          sx={{
            py: 1.5,
            justifyContent: "space-between",
          }}
        >
          <FilterTagDropdown
            defaultTag={defaultTag}
            handleTagSelect={(tag: TagKeys) => {
              setSelectedTag(tag);
            }}
          />
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showMyNotes}
                onChange={(e) => {
                  setShowMyNotes(e.target.checked);
                }}
              />
            }
            label="My notes"
          />
        </Stack>
        <Divider sx={{ mx: -2 }} />
        <Stack
          sx={{ mx: -2 }}
          divider={<Divider sx={{ background: (theme) => theme.palette.general.outlineBorder }} />}
        >
          {customerNoteToShow.length === 0 ? (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                alignSelf: "center",
                position: "absolute",
                top: "50%",
              }}
            >
              No notes for this customer
            </Typography>
          ) : (
            customerNoteToShow.map((note) => (
              <Note
                key={note.id}
                noteItem={note}
                onEditNote={() => {
                  handleEditNote(note);
                }}
                onPinNote={() => handlePinNote(note)}
              />
            ))
          )}
        </Stack>
        {openNoteDialog && (
          <NoteDialog
            handleClose={handleNoteDialogClose}
            selectedNote={selectedNote}
            open={openNoteDialog}
            editMode={editMode}
            defaultTag={defaultTag}
            handleSaveNote={(note: string, tags: TagKeys[]) => handleSaveNote(note, tags)}
          />
        )}
      </>
    </SideDrawer>
  );
};
