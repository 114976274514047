import { type JSX, useCallback, useEffect, useState } from "react";

import { useParams } from "react-router";
import { BillingModel, type EntityInvoicesModel, type EntityInvoicesModelRow, EntityModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type FirebaseDocumentSnapshotModel } from "@doitintl/models-firestore/src/core";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import get from "lodash/get";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { assetTypeName, formatCurrency } from "../../../../../utils/common";
import { type Invoice, type InvoiceTableRow } from "../types";
import { formatInvoice } from "../utils";
import { invoiceViewRowColumns } from "./columns";
import InvoiceViewRow from "./InvoiceViewRow";
import IssuedAtButton from "./IssuedAtButton"; // Import the new component

type RowConfig = {
  name: string;
  value?: string;
  func?: (invoice: InvoiceTableRow) => string;
  href?: (invoice: InvoiceTableRow) => string;
};

const rowsConfig: RowConfig[] = [
  { name: "ID", value: "id" },
  { name: "Cloud", func: (invoice) => assetTypeName(invoice.type) },
  {
    name: "Customer",
    func: (invoice) => `${invoice.customerName} (ID: ${invoice.entity.customer?.id})`,
    href: (invoice) => `/customers/${invoice.entity.customer?.id}`,
  },
  { name: "Billing profile", func: (invoice) => `${invoice.entity.name} (ID: ${invoice.entity.id})` },
  { name: "Details", value: "details" },
  { name: "Status", value: "status" },
  { name: "Group", value: "group" },
  { name: "Created at", value: "transform.createdAt" },
  {
    name: "Issued at",
    value: "transform.issuedAt",
  },
  { name: "Canceled at", value: "transform.canceledAt" },
  { name: "Cancellation Reason", value: "cancellationReason" },
  { name: "Note", value: "note" },
  { name: "Total", func: (invoice) => formatCurrency(invoice.total, invoice.currency, 2) },
];

const Row = ({ name, value, href, button }: { name: string; value: string; href: string; button?: JSX.Element }) => (
  <Grid
    key={name}
    container
    sx={{
      mt: 1,
    }}
  >
    <Grid size={3}>{name}</Grid>
    {href ? (
      <Grid sx={{ overflowWrap: "break-word" }} size={9}>
        <Link href={href} target="_blank">
          {value || "-"}
        </Link>
      </Grid>
    ) : (
      <Grid sx={{ overflowWrap: "break-word" }} size={9}>
        {value || (button ?? "-")}
      </Grid>
    )}
  </Grid>
);

const getFirestoreUrl = (entityId: string, billingMonth: string, draftInvoiceId: string) =>
  `https://console.cloud.google.com/firestore/databases/-default-/data/panel/billing/invoicing/invoicingMonths/${billingMonth}/monthInvoices/${entityId}/entityInvoices/${draftInvoiceId}?project=${process.env.REACT_APP_FIREBASE_PROJECT_ID}`;

const InvoiceView = () => {
  const { entityId, billingMonth, draftInvoiceId } = useParams<{
    entityId: string;
    billingMonth: string;
    draftInvoiceId: string;
  }>();

  const [invoice, setInvoice] = useState<InvoiceTableRow>();
  const [invoiceSnap, setInvoiceSnap] = useState<FirebaseDocumentSnapshotModel<EntityInvoicesModel>>();

  const fetchInvoiceData = useCallback(async () => {
    const invoiceSnapshot = await getCollection(BillingModel)
      .doc("invoicing")
      .collection("invoicingMonths")
      .doc(billingMonth)
      .collection("monthInvoices")
      .doc(entityId)
      .collection("entityInvoices")
      .doc(draftInvoiceId)
      .get();

    const invoiceData = invoiceSnapshot.asModelData();

    if (!invoiceData) {
      return;
    }

    const entitySnap = getCollection(EntityModel).doc(entityId).get();
    const entityData = (await entitySnap).asModelData();

    if (!entityData) {
      return;
    }

    const fullInvoice: Invoice = { ...invoiceData, id: draftInvoiceId, entity: { ...entityData, id: entityId } };
    const customerRef = fullInvoice.entity.customer;
    const customerModelData = customerRef && (await customerRef.get()).asModelData();
    const customerName = customerModelData?.primaryDomain ?? "";
    const formattedInvoice = formatInvoice(fullInvoice);

    setInvoice({ ...formattedInvoice, customerName });
    setInvoiceSnap(invoiceSnapshot);
  }, [billingMonth, draftInvoiceId, entityId]);

  useEffect(() => {
    fetchInvoiceData();
  }, [fetchInvoiceData]);

  if (!invoice) {
    return <></>;
  }

  return (
    <Box>
      <Box data-cy="invoice-view">
        <Typography
          variant="h2"
          sx={{
            ml: 2,
            mt: 2,
          }}
        >
          Invoice
        </Typography>
        <Box sx={{ mx: 2 }}>
          {rowsConfig.map((row: RowConfig) => (
            <Row
              key={row.name}
              name={row.name}
              value={row.func ? row.func(invoice) : (get(invoice, row.value as string) ?? "")}
              href={row.href ? row.href(invoice) : ""}
              button={
                row.name === "Issued at" ? (
                  <IssuedAtButton invoiceSnap={invoiceSnap} fetchInvoiceData={fetchInvoiceData} />
                ) : undefined
              }
            />
          ))}
          <Button
            sx={{ mt: 1 }}
            target="_blank"
            startIcon={<OpenInNewIcon />}
            href={getFirestoreUrl(entityId, billingMonth, draftInvoiceId)}
          >
            Open in firestore
          </Button>
          <Box
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
            }}
          >
            <FilterTable<EntityInvoicesModelRow>
              showRowsSelection={false}
              tableItems={invoice.rows}
              rowComponent={InvoiceViewRow}
              headerColumns={invoiceViewRowColumns}
              filterColumns={[]}
              showFilterBar={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceView;
