import { type ReactNode, useLayoutEffect } from "react";

import { Stack, Typography } from "@mui/material";

export const PanelWrapper = ({
  children,
  title,
  subtitle,
  paddingTop,
  id,
}: {
  paddingTop?: number;
  title: string;
  subtitle?: string;
  id?: string;
  children?: ReactNode;
}) => {
  useLayoutEffect(() => {
    // scrolls to this panel on page load, in order to support deep linking
    if (location.hash === `#${id}`) {
      location.assign(location.href);
    }
  }, [id]);

  return (
    <Stack
      sx={{
        pt: paddingTop,
      }}
    >
      <Typography
        variant="h4"
        id={id}
        sx={{
          pb: 1,
          scrollMarginTop: 228,
        }}
      >
        {title}
      </Typography>
      {subtitle !== undefined && (
        <Typography
          sx={{
            color: "text.secondary",
            pb: 3,
          }}
        >
          {subtitle}
        </Typography>
      )}
      <Stack spacing={3}>{children}</Stack>
    </Stack>
  );
};
