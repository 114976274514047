import { useCallback, useState } from "react";

import {
  type AssetType,
  CurrencyCodes,
  Positions,
  type ReportConfig,
  type ReportFilter,
  TimeInterval,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import capitalize from "lodash/capitalize";

import { globalText } from "../../assets/texts";
import { templateDialog } from "../../assets/texts/CloudAnalytics";
import { useAttributionsContext } from "../../Context/AttributionsContext";
import { useReportDimensionsContext } from "../../Pages/CloudAnalytics/Context";
import { defaultTimeSettingsConfig } from "../../Pages/CloudAnalytics/generateReport/utils";
import PreviewReport from "../../Pages/CloudAnalytics/previewReport/PreviewReport";
import { QueryType } from "../../Pages/CloudAnalytics/report/ReportQuery";
import { type DataRecord } from "../../Pages/CloudAnalytics/ReportData";
import { getCloudIcon } from "../../Pages/CloudAnalytics/templateLibrary/utils";
import {
  getMetricLabel,
  sanitizeCustomTimeRange,
  timeRangeInputValue,
  type TimeRangeOption,
} from "../../Pages/CloudAnalytics/utilities";
import { type TemplateConfiguration } from "../../types";

const styles = {
  chip: {
    ".MuiChip-label": {
      px: "6px",
    },
    backgroundColor: "#E0E0E0",
    fontSize: "12px",
    height: "28px",
    lineHeight: "20px",
  },
};

type Props = {
  name: string;
  description: string;
  config: ReportConfig;
  updatedDate: string;
  categories: string[];
  cloud: AssetType[];
  configuration: TemplateConfiguration;
  exploreTemplate: () => void;
  handleClose: () => void;
};

const TemplateDialog = ({
  name,
  description,
  updatedDate,
  categories,
  cloud,
  configuration,
  config,
  exploreTemplate,
  handleClose,
}: Props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { attributions } = useAttributionsContext();
  const { dimensions } = useReportDimensionsContext();
  const handleTabChange = (_, newValue: number) => {
    setCurrentTab(newValue);
  };

  const dimensionRows = useCallback(
    (rows: string[]): DataRecord[] =>
      rows.flatMap((r) => {
        const dimension = dimensions?.find((d) => d.id === r);
        if (dimension) {
          const row: DataRecord = {
            id: dimension.id,
            type: dimension.data.type,
            field: dimension.data.field,
            key: dimension.data.key,
            position: Positions.ROW,
            label: dimension.data.label,
            nullFallback: dimension.data.nullFallback ?? "",
          };
          return row;
        } else {
          return [];
        }
      }),
    [dimensions]
  );

  const Preview = useCallback(
    () => (
      <Box
        sx={{
          display: currentTab === 1 ? "block" : "none",
        }}
      >
        <PreviewReport
          hideExportingMenu={true}
          attributions={attributions}
          previewData={{
            currency: config.currency ?? CurrencyCodes.USD,
            scope: [],
            attributionGroups: [],
            attributionGroupsPayload: [],
            timeInterval: config.timeInterval,
            timeRangeOptions: {
              mode: config.timeSettings?.mode ?? TimeSettingsMode.Last,
              time: config.timeSettings?.unit ?? TimeInterval.MONTH,
              amount: config.timeSettings?.amount ?? 3,
              includeCurrent: config.timeSettings?.includeCurrent,
            },
            rows: dimensionRows(config.rows ?? []),
            filters: config.filters.flatMap((f) => {
              const md = dimensions?.find((d) => f.id === d.id);
              const filter: ReportFilter = {
                ...f,
                type: (f.type || md?.data.type) ?? "",
                position: f.position ?? md?._position,
                id: f.id,
                field: (f.field || md?.data.field) ?? "",
                key: (f.key || md?.data.key) ?? "",
              };
              return filter;
            }),
            cols: config.cols ? dimensionRows(config.cols) : [],
          }}
          renderer={config.renderer}
          runQuery={false}
          setRenderer={() => {}}
          setRunQuery={() => {}}
          queryType={QueryType.TEMPLATE}
        />
      </Box>
    ),
    [currentTab, config, attributions, dimensionRows, dimensions]
  );

  const ChipContainer = useCallback(
    ({ title, values }: { title: string; values: string[] }) => (
      <Stack
        direction="column"
        sx={{
          rowGap: 0.5,
        }}
      >
        <Typography variant="subtitle2">{title}</Typography>
        <Stack
          direction="row"
          sx={{
            columnGap: 0.5,
          }}
        >
          {values.map((g) => (
            <Chip key={g} color="primary" variant="outlined" label={g} size="small" />
          ))}
        </Stack>
      </Stack>
    ),
    []
  );

  const customTimeRange = config?.customTimeRange ? sanitizeCustomTimeRange(config.customTimeRange) : null;
  const timeSettings = config?.timeSettings;
  const timeRangeOption: TimeRangeOption = {
    label: "",
    mode: timeSettings?.mode || defaultTimeSettingsConfig.mode,
    amount: timeSettings?.amount,
    includeCurrent: timeSettings?.includeCurrent,
    time: timeSettings?.unit || defaultTimeSettingsConfig.unit,
    range: { start: customTimeRange?.from ?? null, end: customTimeRange?.to ?? null },
  };

  return (
    <Dialog data-cy="template-dialog" onClose={handleClose} open={true} maxWidth="sm" fullWidth>
      <DialogTitle data-cy="template-dialog-title">{name}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            rowGap: 2,
          }}
        >
          <DialogContentText variant="body2">
            {templateDialog.UPDATE_ON}
            {updatedDate}
          </DialogContentText>
          <Typography variant="body1">{description}</Typography>
          <Grid container spacing={1.5}>
            {cloud?.map((cloud) => (
              <Grid key={`${name}-${cloud}`}>
                <Avatar src={getCloudIcon(cloud)} sx={{ height: 28, width: 28, backgroundColor: "#f5f5f5", p: 0.5 }} />
              </Grid>
            ))}
            <Grid>
              <Chip label={getMetricLabel(config.metric, "", "")} sx={styles.chip} />
            </Grid>
            {categories.map((c) => (
              <Grid key={c}>
                <Chip label={c} sx={styles.chip} />
              </Grid>
            ))}
          </Grid>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs data-cy="template-dialog-tabs" value={currentTab} onChange={handleTabChange}>
              <Tab sx={{ textTransform: "initial" }} label={templateDialog.CONFIGURATION} />
              <Tab sx={{ textTransform: "initial" }} label={templateDialog.PREVIEW} />
            </Tabs>
          </Box>
          <Box
            sx={{
              overflow: "auto",
              height: 300,
            }}
          >
            <Stack
              sx={{
                rowGap: 3,
                display: currentTab === 0 ? "flex" : "none",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  rowGap: 0.5,
                }}
              >
                <Typography variant="subtitle2">{templateDialog.TIME_RANGE}</Typography>
                <Typography variant="body2">{timeRangeInputValue(timeRangeOption)}</Typography>
              </Stack>
              <Stack
                direction="column"
                sx={{
                  rowGap: 0.5,
                }}
              >
                <Typography variant="subtitle2">{templateDialog.TIME_INTERVAL}</Typography>
                <Typography variant="body2">
                  {capitalize(config.timeInterval)}
                  {config.excludePartialData && ` (${templateDialog.EXCLUDE_PARTIAL_INTERVAL})`}
                </Typography>
              </Stack>
              {configuration.filters.map((f) => (
                <ChipContainer key={f.label} title={`${f.label} ${f.desc}`} values={f.values.split(",")} />
              ))}
              <ChipContainer title={templateDialog.GROUPING} values={configuration.grouping} />
              <ChipContainer title={templateDialog.DIMENSIONS} values={configuration.configDimensions} />
            </Stack>
            {Preview()}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button data-cy="template-dialog-close" onClick={handleClose}>
          {globalText.CLOSE}
        </Button>
        <Button
          color="primary"
          data-cy="template-dialog-explore"
          onClick={exploreTemplate}
          size="medium"
          variant="contained"
        >
          {templateDialog.EXPLORE_TEMPLATE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateDialog;
