import UploadIcon from "@mui/icons-material/Upload";
import { Button, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { type FileData } from "../../types";

type Props = {
  isVendorContract?: boolean;
  handleShowPDFPicker: () => void;
  isEditForbidden: boolean;
  contractFile: FileData | null | undefined;
  isError: boolean;
  text: string;
};

export const ContractFileInput = ({
  handleShowPDFPicker,
  isEditForbidden,
  contractFile,
  isError,
  text,
  isVendorContract,
}: Props) => (
  <Stack
    direction="row"
    spacing={2}
    sx={{
      justifyContent: "left",
      alignItems: "center",
      margin: 1,
    }}
  >
    <Button
      variant="outlined"
      data-cy={`upload-${isVendorContract ? "vendor-" : ""}contract-button`}
      color={isError ? "error" : "primary"}
      startIcon={<UploadIcon />}
      onClick={handleShowPDFPicker}
      disabled={isEditForbidden}
      sx={{ flexShrink: 0 }}
    >
      {text}
    </Button>
    {contractFile ? (
      <Link href={contractFile.url} target="_blank" variant="body2" color="inherit">
        {contractFile.name}
      </Link>
    ) : (
      <Typography
        sx={{
          color: "text.disabled",
        }}
      >
        No file selected
      </Typography>
    )}
  </Stack>
);

ContractFileInput.displayName = "ContractFileInput";

export default ContractFileInput;
