import { Box } from "@mui/material";

import { TargetHandle } from "./Handle";

type Props = {
  id: string;
};

export const GhostNode = ({ id }: Props) => (
  <Box
    sx={{
      width: 350,
      height: 2,
    }}
  >
    <TargetHandle id={id} />
  </Box>
);
