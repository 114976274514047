import { Container, Stack } from "@mui/material";

import GSuiteConfirmationCostText from "./gsuiteConfirmation/GSuiteConfirmationCostText";
import GSuiteConfirmationParagraphText from "./gsuiteConfirmation/GSuiteConfirmationParagraphText";

type NewAssetConfirmSummaryProps = {
  domain: string;
  quantity: number;
  skuName: string;
  total: number;
  discount: number;
  payment: "MONTHLY" | "YEARLY";
};

const NewAssetConfirmSummary = ({
  total,
  domain,
  quantity,
  skuName,
  discount,
  payment,
}: NewAssetConfirmSummaryProps) => (
  <Container maxWidth="sm">
    <Stack
      sx={{
        gap: 3,
      }}
    >
      <GSuiteConfirmationParagraphText domain={domain} quantity={quantity} skuName={skuName} />
      <GSuiteConfirmationCostText amount={total} quantity={quantity} discount={discount} payment={payment} />
    </Stack>
  </Container>
);

export default NewAssetConfirmSummary;
