import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import { type CSVError } from "../utils";
import ErrorsAlert from "./ErrorsAlert";
import { type CSVDataType } from "./ImportCSV";

type Props = {
  data?: CSVDataType[];
  errors: CSVError[];
};

const PreviewCSVData = ({ data, errors }: Props) => {
  if (!data || data?.length <= 0) {
    return <></>;
  }

  const first100Items = data.slice(0, 100);

  const headers = Object.keys(first100Items[0]);

  return (
    <div>
      <Typography sx={{ py: 6 }} variant="h1">
        {datahubTxt.PREVIEW}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.primary",
          mb: 3,
        }}
      >
        {datahubTxt.PREVIEW_DESC(data.length, headers.length)}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 0,
          boxShadow: "none",
          border: "1px solid ",
          borderColor: "primary.border",
          maxHeight: 350,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {first100Items.map((row) => (
              <TableRow key={row.event_id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {Object.values(row).map((r) => (
                  <TableCell sx={{ whiteSpace: "nowrap" }} key={r}>
                    {r}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ErrorsAlert errors={errors} sx={{ pt: 3 }} />
    </div>
  );
};

export default PreviewCSVData;
