import { type ChangeEvent, useMemo } from "react";

import { FormControlLabel, FormLabel, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";

import { createTemplateFilters } from "../CloudflowBuilder/utils/templateFilters";
import { type CloudflowTemplate } from "../types";

type TemplateFilterProps = {
  templates: CloudflowTemplate[] | undefined;
  onFiltersChange: (filter: object) => void;
  selectedFilters: Record<string, boolean>;
};

export const TemplateFilters = ({ templates, onFiltersChange, selectedFilters }: TemplateFilterProps) => {
  const handleCheckboxChange = (filterKey: string) => (event: ChangeEvent<HTMLInputElement>) => {
    onFiltersChange({
      [filterKey]: event.target.checked,
    });
  };

  const filters = useMemo(() => createTemplateFilters(templates), [templates]);

  return (
    <Stack
      direction="column"
      sx={{
        gap: 4,
        width: 221,
      }}
    >
      <FormControl component="fieldset" variant="standard" sx={{ mt: -2 }}>
        {Object.keys(filters).map((filterGroupName) => (
          <FormGroup
            key={filterGroupName}
            sx={{
              whiteSpace: "nowrap",
              "& .MuiFormControlLabel-label": {
                fontSize: 14,
              },
              "& .MuiSvgIcon-root": {
                fontSize: 24,
              },
            }}
          >
            <FormLabel
              component="legend"
              sx={{
                color: "text.primary",
                "&.Mui-focused": {
                  color: "text.primary",
                },
                fontSize: 14,
                fontWeight: 500,
                marginTop: 2,
                textTransform: "capitalize",
              }}
            >
              {filterGroupName}
            </FormLabel>
            {filters[filterGroupName].map(({ tagValue, count }) => {
              const filterKey = `${filterGroupName}:${tagValue}`;
              return (
                <FormControlLabel
                  key={filterKey}
                  control={
                    <Checkbox
                      checked={selectedFilters[filterKey] || false}
                      onChange={handleCheckboxChange(filterKey)}
                    />
                  }
                  label={`${tagValue} (${count})`}
                  sx={{ px: 1, textTransform: "capitalize" }}
                />
              );
            })}
          </FormGroup>
        ))}
      </FormControl>
    </Stack>
  );
};
