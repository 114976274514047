import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";

export default function GreenCheck({ content }: { content: string }) {
  return (
    <Box
      data-cy={`ok-${content}`}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <CheckIcon
        sx={{
          color: "success.main",
          fontSize: 26,
          marginRight: "2px",
        }}
      />
      <Typography
        sx={{
          fontSize: 14,
        }}
      >
        {content}
      </Typography>
    </Box>
  );
}
