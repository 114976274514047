import { useCallback } from "react";

import { type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import Typography from "@mui/material/Typography";

import { useCustomerContext } from "../../../../../../Context/CustomerContext";
import { type Customer } from "../../../../../../types";
import { useToggle } from "../../../../../../utils/useToggle";
import NewMpaDialog, { type MpaSubmitObject } from "../../../../../MPA/Components/NewMpaDialog";
import useSubscribedMasterPayerAccounts from "../../../../../MPA/hooks/useSubscribedMasterPayerAccounts";
import { getMPANumberFromName } from "../utils";

const CreateMPASuccessMessage = ({
  newMPA,
  customer,
}: {
  newMPA: MasterPayerAccountsModel | undefined;
  customer: Customer;
}) => (
  <>
    {`MPA #${newMPA ? getMPANumberFromName(newMPA.name) : ""} successfully created${
      newMPA?.customerId === customer.id ? " and added to this contract" : ""
    }.`}
  </>
);

export const CreateNewMPAButton = ({
  onMpaCreate,
  disabled,
}: {
  onMpaCreate: (mpaId: string) => void;
  disabled: boolean;
}) => {
  const [newMpaDialogVisible, showNewMpaDialog, hideNewMpaDialog] = useToggle(false);
  const { masterPayerAccounts, createNewMpa } = useSubscribedMasterPayerAccounts();
  const { customer } = useCustomerContext();

  const submitAndClose = useCallback(
    async (
      submitObject: MpaSubmitObject
    ): Promise<{ newMpaData: MasterPayerAccountsModel | undefined; subprocessFailure: string }> => {
      const { newMpa: newMpaRef, subprocessFailure } = await createNewMpa(submitObject);
      const newMpaData = (await newMpaRef.get()).data();
      if (customer.id === newMpaData?.customerId) {
        onMpaCreate(newMpaRef.id);
      }
      hideNewMpaDialog();

      return { newMpaData, subprocessFailure };
    },
    [createNewMpa, customer.id, hideNewMpaDialog, onMpaCreate]
  );

  const successMessageRenderer = useCallback(
    ({ newMpaData, subprocessFailure }: Awaited<ReturnType<typeof submitAndClose>>) =>
      subprocessFailure || <CreateMPASuccessMessage newMPA={newMpaData} customer={customer} />,
    [customer]
  );

  const successVariantRenderer = useCallback(
    ({ subprocessFailure }: Awaited<ReturnType<typeof submitAndClose>>) => (subprocessFailure ? "warning" : "success"),
    []
  );

  return (
    <>
      <Typography
        variant="caption"
        color="textPrimary"
        style={{ textDecoration: "underline", cursor: "pointer", marginTop: 4 }}
        onClick={disabled ? undefined : showNewMpaDialog}
        gutterBottom
        sx={{
          display: "block",
        }}
      >
        Create new MPA
      </Typography>
      {newMpaDialogVisible && (
        <NewMpaDialog
          open
          onCancel={hideNewMpaDialog}
          onSubmit={submitAndClose}
          masterPayerAccounts={masterPayerAccounts}
          successMessage={successMessageRenderer}
          successVariant={successVariantRenderer}
          failMessage="Failed to create new MPA"
          forCustomer={customer}
        />
      )}
    </>
  );
};
