import { type ChangeEvent, useCallback, useMemo, useState } from "react";

import UploadIcon from "@mui/icons-material/Upload";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useUploadPLES } from "./hooks";
import { type UploadPLESRequest } from "./types";

const UploadPlesForm = () => {
  const [uploadPLESRequest, setUploadPLESRequest] = useState<UploadPLESRequest>({
    ples_accounts: undefined,
    invoice_month: "",
    force_update: false,
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackBar = useErrorSnackbar();
  const uploadPLESHandler = useUploadPLES();

  const handleMonthChange = useCallback(
    (event: SelectChangeEvent) => {
      setUploadPLESRequest({ ...uploadPLESRequest, invoice_month: event.target.value });
    },
    [uploadPLESRequest]
  );

  const handleFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        try {
          setUploadPLESRequest({ ...uploadPLESRequest, ples_accounts: file });
        } catch (error) {
          errorSnackBar("Error reading the file. Please try again.");
        }
      }
    },
    [uploadPLESRequest, errorSnackBar]
  );

  const handleForceUpdateChange = useCallback(
    (_, checked: boolean) => {
      setUploadPLESRequest({ ...uploadPLESRequest, force_update: checked });
    },
    [uploadPLESRequest]
  );

  const currentMonth = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}`;
  }, []);

  const previousMonth = useMemo(() => {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 1);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}`;
  }, []);

  const onSubmit = () => {
    setLoading(true);
    uploadPLESHandler(
      uploadPLESRequest,
      () => {
        successSnackbar("File uploaded successfully.");
        setLoading(false);
      },
      (error: any) => {
        if (error.response.status === 400) {
          setErrors(error.response.data);
        }
        errorSnackBar("Error uploading file. Please try again.");
        setLoading(false);
      }
    );
  };

  const isMonthError = useMemo(() => errors.some((error) => error.includes("month")), [errors]);
  const isFileError = useMemo(() => errors.length > 0 && !isMonthError, [errors.length, isMonthError]);

  return (
    <Stack
      sx={{
        gap: 3,
        mt: 1,
      }}
    >
      <Typography variant="h1">Upload PLES spreadsheet</Typography>
      <FormControl sx={{ width: 459 }} error={isFileError}>
        <div
          style={{
            display: "flex",
            gap: 8,
            padding: "10px",
            width: "459px",
            border: `solid 1px ${isFileError ? "#C62828" : "rgba(0, 0, 0, 0.23)"}`,
            borderRadius: 4,
          }}
        >
          <input type="file" accept=".csv" onChange={handleFileChange} id="upload-file" style={{ display: "none" }} />
          <label htmlFor="upload-file">
            <Button variant="outlined" color="primary" component="span">
              <UploadIcon sx={{ mr: 1 }} />
              Attach CSV file
            </Button>
          </label>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {uploadPLESRequest.ples_accounts?.name ?? "No file selected"}
          </Typography>
        </div>
        {!isMonthError && (
          <FormHelperText>
            {errors.map((error, index) => (
              <span key={index}>
                {error}
                <br />
              </span>
            ))}
          </FormHelperText>
        )}
      </FormControl>
      <div>
        <FormControl sx={{ minWidth: 459 }} error={isMonthError}>
          <InputLabel id="month-label">Select a month *</InputLabel>
          <Select
            labelId="month-label"
            id="month"
            label="Select a month *"
            value={uploadPLESRequest.invoice_month}
            onChange={handleMonthChange}
          >
            <MenuItem value={currentMonth}>{currentMonth}</MenuItem>
            <MenuItem value={previousMonth}>{previousMonth}</MenuItem>
          </Select>
          {isMonthError && <FormHelperText>{errors[0][0].toUpperCase() + errors[0].slice(1)}</FormHelperText>}
        </FormControl>
      </div>
      <FormControlLabel
        control={<Checkbox checked={uploadPLESRequest.force_update} onChange={handleForceUpdateChange} />}
        label="Force update"
      />
      <LoadingButton
        variant="contained"
        sx={{ mt: 2, width: "103px" }}
        disabled={!uploadPLESRequest.invoice_month || !uploadPLESRequest.ples_accounts}
        onClick={onSubmit}
        loading={loading}
      >
        Upload
      </LoadingButton>
    </Stack>
  );
};

export default UploadPlesForm;
