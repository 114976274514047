import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

import { useContractFormContext } from "../../../ContractsFormContext";

export const CustomerCommitmentRadioGroup = () => {
  const { state, setState } = useContractFormContext();

  const handleCommitmentChange = (event) => {
    const isCommitment = event.target.value === "hardCommit";
    const isSoftCommitment = event.target.value === "softCommit";

    setState((prevState) => {
      const { commitmentPeriods, errors } = prevState;
      let { commitmentRollover, endDate } = prevState;

      if (!isCommitment) {
        commitmentPeriods.splice(0);
        commitmentRollover = false;
      }

      if (!isCommitment && !isSoftCommitment) {
        endDate = null;
        errors.endDate = false;
      }

      return {
        ...prevState,
        isCommitment,
        isSoftCommitment,
        commitmentRollover,
        commitmentPeriods,
        endDate,
        errors,
      };
    });
  };

  return (
    <FormControl
      data-cy="commitment-radio-buttons"
      disabled={Boolean(state.isEditForbidden || state.vendorContract?.id)}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
          mb: 1,
        }}
      >
        Customer commitment
      </Typography>
      <RadioGroup onChange={handleCommitmentChange}>
        <FormControlLabel
          checked={!state.isCommitment && !state.isSoftCommitment}
          value="onDemand"
          control={<Radio data-cy="on-demand-button" />}
          label="On-demand"
        />
        {[AssetTypeGoogleCloud, AssetTypeAmazonWebServices].includes(state.type) && (
          <FormControlLabel
            value="softCommit"
            checked={state.isSoftCommitment}
            control={<Radio data-cy="soft-commit-button" />}
            label="Soft commit"
          />
        )}
        <FormControlLabel
          value="hardCommit"
          checked={state.isCommitment}
          control={<Radio data-cy="hard-commit-button" />}
          label="Hard commit"
        />
      </RadioGroup>
    </FormControl>
  );
};
