import { forwardRef, type ReactElement, type Ref, useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton, Slide, Stack, Tab, Tabs, Typography } from "@mui/material";
import { type TransitionProps } from "@mui/material/transitions";
import { withStyles } from "@mui/styles";

import { type Explainer } from "../types";
import ExplainerCostPerAccount from "./Pages/CostPerAccount/ExplainerCostPerAccount";
import ExplainerCostPerService from "./Pages/CostPerService/ExplainerCostPerService";
import { ExplainerSummaryPage } from "./Pages/SummaryPage/ExplainerSummaryPage";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  explainer: Explainer;
  onClose: () => void;
  onCurrencyChange?: (currency: CurrencyCode) => void;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
  <div hidden={value !== index}>{value === index && <Box sx={{ p: 5 }}>{children}</Box>}</div>
);

const CustomTab = withStyles({
  root: {
    textTransform: "none",
    alignItems: "start",
  },
})(Tab);

export function ExplainerDialog({ open, explainer, onClose, onCurrencyChange }: Props) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullScreen>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          maxHeight: "100vh",
          width: "100%",
        }}
      >
        {/* Left side of the dialog */}
        <Box
          sx={{
            backgroundColor: "action.hover",
            width: "20%",
            minWidth: "300px",
            p: 5,
            display: { xs: "none", sm: "block" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              mb: 4,
            }}
          >
            Invoice explainer
          </Typography>

          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={handleChange}
            sx={{
              borderLeft: 1,
              borderColor: "divider",
              ".MuiTabs-indicator": {
                left: 0,
              },
            }}
          >
            <CustomTab label="Summary of your invoice" />
            <CustomTab label="Charges by service" />
            <CustomTab label="Charges by account" />
          </Tabs>
        </Box>

        {/* Right side of the dialog */}
        <Stack
          sx={{
            height: "100%",
            overflow: "auto",
            flex: 1,
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: "fixed",
              top: 0,
              right: 2,
              margin: 2,
            }}
          >
            <CloseIcon />
          </IconButton>

          <TabPanel value={selectedTab} index={0}>
            <ExplainerSummaryPage explainer={explainer} onCurrencyChange={onCurrencyChange} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <ExplainerCostPerService explainer={explainer} onCurrencyChange={onCurrencyChange} />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <ExplainerCostPerAccount explainer={explainer} onCurrencyChange={onCurrencyChange} />
          </TabPanel>
        </Stack>
      </Box>
    </Dialog>
  );
}
