import { useMemo } from "react";

import { type SupportServiceModel } from "@doitintl/cmp-models";
import { AlertTitle, Link, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";

import { SupportTicketRequirementText } from "../../assets/texts";
import { useSessionStorage } from "../../Components/hooks/storageHooks";
import { SafeHtml } from "../../Components/SafeHtml";

type Props = {
  cloudProduct: SupportServiceModel;
  severity: string;
};

export default function DetailsAlertBox({ cloudProduct, severity }: Props) {
  const [disabledAlert, setDisabledAlert] = useSessionStorage<boolean>("supportP1TicketDetails", false);

  const alertDetailsText = useMemo(
    () => SupportTicketRequirementText.find((s) => s.ID === cloudProduct.id) ?? SupportTicketRequirementText[0],
    [cloudProduct.id]
  );

  const onCloseAlert = () => {
    setDisabledAlert(true);
  };

  if (disabledAlert || !alertDetailsText || severity === "low" || severity === "normal") {
    return null;
  }

  return (
    <Alert sx={{ mt: 1.5, mb: 2 }} onClose={onCloseAlert} severity="info" data-cy="alertDialog">
      <AlertTitle>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 500,
          }}
        >
          A detailed description will help us to resolve your issue quickly.
        </Typography>
      </AlertTitle>
      <Typography variant="body2" component="div">
        {alertDetailsText?.TITLE}
        <ul style={{ marginTop: 0 }}>{alertDetailsText?.DESCRIPTION?.map((item) => <li key={item}>{item}</li>)}</ul>
        <SafeHtml html={alertDetailsText?.FOOTER} />
      </Typography>
      {alertDetailsText?.LINK && (
        <Link
          underline="hover"
          color="primary"
          href={alertDetailsText?.LINK.SRC}
          target="_blank"
          rel="noopener noreferrer"
        >
          {alertDetailsText?.LINK.TEXT}
        </Link>
      )}
    </Alert>
  );
}
