import { useEffect, useState } from "react";

import { type CustomerModel, UserModel } from "@doitintl/cmp-models/src";
import { getCollection } from "@doitintl/models-firestore/src";
import { type FirebaseModelReference } from "@doitintl/models-firestore/src/core";
import { Box, Stack, Typography } from "@mui/material";

import DoitLogo from "../../../assets/doit-logo-hero-square.svg";
import HtmlTooltip from "../../../Components/Tooltip/HtmlTooltip";
import { useCustomerContext } from "../../../Context/CustomerContext";
import type { Insight } from "../types";

type Props = {
  insight: Insight;
};

const getPublishingUser = async (
  email: string,
  customerRef: FirebaseModelReference<CustomerModel>
): Promise<UserModel | null> => {
  const userQuery = getCollection(UserModel)
    .where("customer.ref", "==", customerRef)
    .where("email", "==", email.trim().toLowerCase());

  const user = (await userQuery.get()).docs?.[0]?.asModelData();
  if (user) {
    return user as UserModel;
  }

  return null;
};

const CustomInsightSourceLabel = ({ insight }: Props) => {
  const publishingUserEmail = insight.customInsightAttributes?.publishingUserEmail ?? "";

  const { customer } = useCustomerContext();

  const [user, setUser] = useState<UserModel | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getPublishingUser(publishingUserEmail, customer.ref);
      setUser(user);
    };

    if (publishingUserEmail) {
      fetchUser();
    }
  }, [publishingUserEmail, customer.ref]);

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          mt: 1.5,
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        Recommended to you by{" "}
        {insight.customInsightAttributes?.publishingUserEmail &&
        user &&
        insight.customInsightAttributes?.showPublishingUser
          ? user.displayName
          : "DoiT experts"}
      </Typography>
    </>
  );
};

const AutomatedInsightSourceLabel = () => (
  <Box sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
    <Typography
      variant="body1"
      sx={{
        color: "text.secondary",
        fontSize: "14px",
        fontWeight: "500",
      }}
    >
      Monitored by
    </Typography>
    <HtmlTooltip
      title={
        <Box
          sx={{
            p: 1.5,
          }}
        >
          <Typography
            sx={{
              mb: 2,
              fontSize: "14px",
            }}
          >
            DoiT Cloud Intelligence™ checks every day for any potential savings associated with this insight based on
            your recent billing data, excluding the last three days.
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            If no potential savings are found, this insight is automatically marked as optimized.
          </Typography>
        </Box>
      }
      placement="right"
    >
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          ml: 0.5,
          fontSize: "14px",
          fontWeight: "500",
          borderBottom: "1px dotted",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          lineHeight: 1.25,
        }}
      >
        DoiT Cloud Intelligence
      </Typography>
    </HtmlTooltip>
  </Box>
);

export const InsightSourceLabel = ({ insight }: Props) => (
  <Stack
    direction="row"
    spacing={0.5}
    sx={{
      alignItems: "center",
      mt: 2,
    }}
  >
    <Box component="img" src={DoitLogo} sx={{ height: "20px", width: "20px" }} />
    {insight.providerId === "custom" ? <CustomInsightSourceLabel insight={insight} /> : <AutomatedInsightSourceLabel />}
  </Stack>
);
