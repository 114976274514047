import { Link } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";

import NoPermissions from "../assets/no-permissions-graphic.svg";
import NoPermissionsDark from "../assets/no-permissions-graphic-dark-mode.svg";
import { useCustomerId } from "./hooks/useCustomerId";
import { useDarkThemeCheck } from "./hooks/useDarkThemeCheck";

export const NoAccess = () => {
  const isDarkMode = useDarkThemeCheck();
  const customerId = useCustomerId();

  return (
    <Stack
      sx={{
        alignItems: "center",
        mt: 12,
      }}
    >
      <img src={isDarkMode ? NoPermissionsDark : NoPermissions} width={100} />
      <Typography
        variant="h1"
        sx={{
          mt: 4,
        }}
      >
        You don’t have permission to view this page
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.seconday",
          mt: 2,
        }}
      >
        Contact your account administrator to gain access.
      </Typography>
      <Button
        color="primary"
        component={Link}
        variant="contained"
        sx={{ mt: 5 }}
        to={`/customers/${customerId}/dashboards/Account`}
      >
        Go to Dashboards
      </Button>
    </Stack>
  );
};
