import { useCallback, useMemo } from "react";

import { Autocomplete, type AutocompleteRenderInputParams, type SxProps, TextField, type Theme } from "@mui/material";
import startCase from "lodash/startCase";

import { attributionGroupsText } from "../../../assets/texts";
import { createFilterOptions } from "../../../Pages/CloudAnalytics/utilities";
import { type AttributionWRef } from "../../../types";
import { sortAttributions } from "./sortAttributions";

type Props = {
  attribution?: AttributionWRef | null;
  attributions: AttributionWRef[];
  disabledAttributions?: string[];
  disabled?: boolean;
  onChange: (attribution: AttributionWRef | null) => void;
  onClose?: () => void;
  sx?: SxProps<Theme>;
};

const getOptionLabel = (option: AttributionWRef) => option.data.name;

const handleRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AttributionWRef) => (
  <li {...props} key={option.ref.id}>
    {option.data.name}
  </li>
);

const handleRenderInput = (params: AutocompleteRenderInputParams) => (
  <TextField
    {...params}
    required
    variant="outlined"
    placeholder={attributionGroupsText.ADD_ATTRIBUTION}
    fullWidth
    size="small"
    slotProps={{
      inputLabel: {
        shrink: true,
      },
    }}
  />
);

export const AttributionSelect = ({
  attribution,
  attributions,
  disabled,
  disabledAttributions,
  onChange,
  onClose,
  sx,
}: Props) => {
  const sortedAttributions = useMemo(() => sortAttributions(attributions), [attributions]);

  const handleChange = useCallback(
    (_, value: AttributionWRef | null) => {
      onChange(value);
    },
    [onChange]
  );

  const getOptionDisabled = useCallback(
    (option: AttributionWRef) => disabledAttributions?.includes(option.ref.id) || false,
    [disabledAttributions]
  );

  const filterOptions = createFilterOptions<AttributionWRef>({
    trim: true,
    ignoreAccents: true,
    ignoreCase: true,
    matchFrom: "any",
  });

  return (
    <Autocomplete
      disabled={disabled}
      filterOptions={filterOptions}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      groupBy={(option) => startCase(option.data.type)}
      onChange={handleChange}
      onClose={onClose}
      options={sortedAttributions}
      renderInput={handleRenderInput}
      renderOption={handleRenderOption}
      sx={sx}
      value={attribution}
    />
  );
};
