import { type ChangeEvent, Fragment, type JSX } from "react";

import { type DashboardRole, Roles } from "@doitintl/cmp-models";
import Grid from "@mui/material/Grid2";

import RolesTextField from "./RolesTextField";

type AccessDropDownProps = {
  condition?: boolean;
  children?: JSX.Element;
  value: DashboardRole | undefined | boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  owner?: boolean;
  remove?: boolean;
  roles?: { role: DashboardRole | undefined; text?: string }[];
  isCurrentUserOwner: boolean;
  isCurrentUserEditor: boolean;
  availableRoles?: Array<Roles | undefined>;
};

const AccessDropdown = ({
  condition = true,
  children,
  value,
  onChange,
  owner,
  remove,
  roles = [
    { role: Roles.VIEWER, text: Roles.VIEWER },
    { role: Roles.EDITOR, text: Roles.EDITOR },
    { role: Roles.OWNER, text: Roles.OWNER },
    { role: Roles.MIXED, text: Roles.MIXED },
  ],
  isCurrentUserOwner,
  isCurrentUserEditor,
  availableRoles,
}: AccessDropDownProps): JSX.Element => {
  if (!condition) {
    return <></>;
  }

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      sx={{
        alignItems: "center",
      }}
    >
      <Grid size="grow">{children}</Grid>
      <Grid>
        {roles && (
          <RolesTextField
            value={value}
            onChange={onChange}
            owner={owner}
            remove={remove}
            roles={roles}
            isCurrentUserOwner={isCurrentUserOwner}
            isCurrentUserEditor={isCurrentUserEditor}
            availableRoles={availableRoles}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AccessDropdown;
