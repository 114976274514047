import { Typography } from "@mui/material";

import { CustomCard } from "./CustomCard";

type Props = {
  active: boolean;
  onClick: () => void;
  title: string;
  subtitle: string;
};

export const ServiceCard = ({ active, onClick, title, subtitle }: Props) => (
  <CustomCard active={active} onClick={onClick} sx={{ minWidth: 282 }}>
    <Typography
      variant="h2"
      sx={{
        textAlign: "center",
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="caption"
      sx={{
        textAlign: "center",
        color: "text.secondary",
        pt: 2,
      }}
    >
      {subtitle}
    </Typography>
  </CustomCard>
);
