import { NavLink } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, ListSubheader, MenuItem, MenuList, Typography } from "@mui/material";

import { type CategoryContext } from "../../types";

type MobileSubMenuProps = {
  openMenu: () => void;
  closeMenu: () => void;
  selectedCategory: CategoryContext | null;
};
const MobileSubMenu = ({ openMenu, closeMenu, selectedCategory }: MobileSubMenuProps) => (
  <Box>
    <Box
      sx={{
        ml: -2,
      }}
    >
      <IconButton color="header" onClick={openMenu} size="large">
        <ArrowBackIcon />
      </IconButton>
    </Box>
    <Box
      sx={{
        pt: 2,
      }}
    >
      <MenuList>
        <ListSubheader color="inherit" disableGutters sx={{ background: "none" }}>
          <Box
            sx={{
              pb: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.navigation.text,
              })}
            >
              {selectedCategory?.displayName}
            </Typography>
          </Box>
        </ListSubheader>

        {(selectedCategory?.items || []).map((category) => (
          <MenuItem
            disableGutters
            key={category.displayName}
            component={NavLink}
            to={category.fullRoute}
            onClick={closeMenu}
          >
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: theme.palette.navigation.text,
              })}
            >
              {category.displayName}
            </Typography>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  </Box>
);

export default MobileSubMenu;
