import { type JSX } from "react";

import { type FlexsaveType } from "@doitintl/cmp-models";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import { CloudLogo } from "../CloudLogo";

type Props = {
  children?: JSX.Element;
  type: FlexsaveType;
  onBackButtonClick?: () => void;
};

export const OnboardingTasksHeader = ({ children, type, onBackButtonClick }: Props) => (
  <Stack>
    <Stack
      direction="row"
      sx={{
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "center",
          ml: -1,
        }}
      >
        {onBackButtonClick && (
          <Box
            sx={{
              pt: 0.5,
            }}
          >
            <IconButton onClick={onBackButtonClick}>
              <BackIcon sx={{ color: "text.primary" }} />
            </IconButton>
          </Box>
        )}
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            letterSpacing: 0.25,
            pt: 1,
          }}
        >
          Flexsave for {type === "GCP" ? "Google Cloud" : type}
        </Typography>
      </Stack>

      <CloudLogo type={type} size={60} />
    </Stack>
    {children}
  </Stack>
);
