import { useCallback, useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@mui/material";

type Props = {
  handleClose: () => void;
  handleCoverageApplied: (
    value: number | "",
    targetCoverageNote: string,
    forceTargetCoverage?: boolean
  ) => Promise<void>;
  value: number;
  open: boolean;
  fallbackValue?: number;
  forceTargetCoverage?: boolean;
  coverageType: "customer" | "workload";
};

export const TargetCoverageDialog = ({
  handleClose,
  handleCoverageApplied,
  value,
  fallbackValue,
  open,
  coverageType,
  forceTargetCoverage,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [changedValue, setChangedValue] = useState<number | "">(Math.round(value));
  const [clickedForceTargetCoverage, setClickedForceTargetCoverage] = useState<boolean | undefined>(
    forceTargetCoverage
  );
  const [targetCoverageNote, setTargetCoverageNote] = useState<string>("");

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setChangedValue(inputValue);
    } else if (inputValue >= 0 && inputValue <= 100) {
      setChangedValue(parseInt(inputValue));
    }
  };

  const handleSave = useCallback(() => {
    setLoading(true);
    handleCoverageApplied(
      changedValue,
      targetCoverageNote,
      forceTargetCoverage === undefined && !clickedForceTargetCoverage
        ? forceTargetCoverage
        : clickedForceTargetCoverage
    ).then(() => {
      setLoading(false);
      handleClose();
    });
  }, [
    changedValue,
    handleCoverageApplied,
    handleClose,
    clickedForceTargetCoverage,
    forceTargetCoverage,
    targetCoverageNote,
  ]);

  const handleCancel = () => {
    handleClose();
  };

  const isForceTargetCoverageChanged =
    clickedForceTargetCoverage === forceTargetCoverage ||
    (!clickedForceTargetCoverage && forceTargetCoverage === undefined);

  const isSaveButtonDisabled =
    loading ||
    !targetCoverageNote ||
    (coverageType === "customer" && !changedValue && changedValue !== 0) ||
    (changedValue === value && isForceTargetCoverageChanged);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Edit target coverage</DialogTitle>
      <DialogContent>
        <TextField
          name="targetCoverage"
          variant="outlined"
          margin="normal"
          label="Target Coverage"
          type="number"
          helperText="Set customer target coverage between 0 to 100"
          onChange={handleChange}
          value={changedValue}
          disabled={loading}
          fullWidth
          autoFocus
          slotProps={{
            input: {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100 },
            },
          }}
        />
        {coverageType === "workload" && (
          <Alert severity="info">{`Leaving the input empty will inherit the target coverage from the customer level. This is currently ${fallbackValue}%`}</Alert>
        )}
        {coverageType === "customer" && (
          <>
            <Checkbox
              onClick={() => {
                setClickedForceTargetCoverage(!clickedForceTargetCoverage);
              }}
              checked={clickedForceTargetCoverage}
            />
            Force target coverage
          </>
        )}
        <TextField
          name="targetCoverageComments"
          variant="outlined"
          margin="normal"
          label="Reason for change"
          onChange={(e) => {
            setTargetCoverageNote(e.target.value);
          }}
          value={targetCoverageNote}
          disabled={loading}
          fullWidth
          required
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSave}
          loading={loading}
          disabled={isSaveButtonDisabled}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
