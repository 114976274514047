import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DataCouplet from "../../../../../Components/DataCouplet";
import { visuallyHiddenStyle } from "../../../../../constants/common";
import { type Contract } from "../../../../../types";
import { NotAvailable } from "../../../Tabs/components/typographics";
import { contractSupportMap } from "../../utils";

type SupportDetailsProps = {
  contract: Contract;
};

/**
 * Provides contract support details for this asset.
 * @param contract - asset contract
 */
export const GoogleAssetSupportDetails = ({ contract }: SupportDetailsProps) => (
  <Box component="section">
    <Typography variant="h4" sx={visuallyHiddenStyle}>
      Support details
    </Typography>
    <Typography variant="subtitle1" aria-hidden sx={{ fontWeight: 500 }}>
      Support details
    </Typography>
    <Stack
      component="dl"
      sx={{
        gap: 0.75,
      }}
    >
      <DataCouplet field="Support plan" value={contractSupportMap[contract.type] ?? ""} />
      <DataCouplet field="Charge" value={contract?.plpsPercent ? `${contract.plpsPercent}%` : NotAvailable} />
    </Stack>
  </Box>
);

export default GoogleAssetSupportDetails;
