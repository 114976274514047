import { useEffect, useMemo } from "react";

import { EntityInvoicingModes } from "@doitintl/cmp-models";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";

import { billingProfilesTexts } from "../../../../assets/texts";
import { type Entity } from "../../../../Context/customer/EntitiesContext";
import { type EditBillingProfileData } from "../../BillingProfileForm.models";
import { useEntityAllowedAssetsCollection } from "../../useEntityAllowedAssetsCollection";
import { PanelWrapper } from "../common/PanelWrapper";
import { AssetsLogos } from "../components/invoice-settings/AssetsLogos";
import { AssetsMultiselect } from "../components/invoice-settings/AssetsMultiselect";
import { BucketDialog, useBucketDialogPropsGenerator } from "../components/invoice-settings/BucketDialog";
import { DeleteBucketButton } from "../components/invoice-settings/DeleteBucketButton";
import { useBucketsCRUD } from "../components/invoice-settings/useBucketsCRUD";
import { scrollToFormElement } from "../utils";

export const INVOICE_SETTINGS_PANEL_ID = "invoice-settings";

export const InvoiceSettingsPanel = ({
  entity,
  setIsInvalid,
}: {
  entity: Entity;
  setIsInvalid: (isInvalid: boolean) => void;
}) => {
  const {
    values: { invoicingMode, autoAssignGCP, buckets, invoicePerService, separateInvoice },
    handleChange,
    setFieldValue,
  } = useFormikContext<EditBillingProfileData>();
  const theme = useTheme();

  const [availableAssets] = useEntityAllowedAssetsCollection(entity);

  const unassignedAssets = useMemo(() => {
    const alreadyAssignedAssets = buckets.flatMap(({ assets }) => assets.map(({ id }) => id));
    return availableAssets.filter(({ id }) => !alreadyAssignedAssets.includes(id));
  }, [availableAssets, buckets]);

  const { assignAssetsToBucket, createBucket, deleteBucket, setBucketAsDefault, setBucketName } = useBucketsCRUD(
    buckets,
    setFieldValue
  );
  const { bucketDialogProps, openBucketDialogInCreationMode, openBucketDialogInEditionMode } =
    useBucketDialogPropsGenerator(createBucket, setBucketName);

  const alertMessage = useMemo(() => {
    const messageTokens: string[] = [];
    const hasDefault = buckets.find(({ isDefault }) => isDefault);
    if (!hasDefault) {
      messageTokens.push("assign a default bucket");
    }
    if (unassignedAssets.length > 0) {
      messageTokens.push(
        `assign all unassigned assets (${unassignedAssets.length}). Unassigned assets will be moved to the default bucket if left unassigned.`
      );
    }
    return messageTokens.length > 0 ? `Please ${messageTokens.join(" and ")}` : false;
  }, [buckets, unassignedAssets.length]);

  useEffect(() => {
    if (invoicingMode === EntityInvoicingModes.CUSTOM) {
      if (buckets.every((b) => !b.isDefault)) {
        setIsInvalid(true);
        return;
      }
    }

    setIsInvalid(false);
  }, [buckets, invoicingMode, setIsInvalid]);

  return (
    <PanelWrapper title="Invoice settings" id={INVOICE_SETTINGS_PANEL_ID} paddingTop={9}>
      <FormControl sx={{ marginBottom: 25 }}>
        <FormLabel sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{
              color: "text.primary",
              fontWeight: "medium",
            }}
          >
            General
          </Typography>
        </FormLabel>
        <RadioGroup aria-label="Invoice Mode" name="invoicingMode" value={invoicingMode} onChange={handleChange}>
          <FormControlLabel
            value={EntityInvoicingModes.GROUP}
            control={<Radio />}
            label={<Typography variant="body1">Separate invoice by asset type</Typography>}
          />
          <FormControlLabel
            onClick={() => {
              setTimeout(() => {
                scrollToFormElement(INVOICE_SETTINGS_PANEL_ID);
              });
            }}
            value={EntityInvoicingModes.CUSTOM}
            control={<Radio />}
            label={<Typography variant="body1">Invoice using custom bucketing</Typography>}
          />
        </RadioGroup>
        {invoicingMode === EntityInvoicingModes.CUSTOM && (
          <>
            <BucketDialog {...bucketDialogProps} />
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                mt: 3,
                mb: 1,
              }}
            >
              Custom bucketing settings
            </Typography>
            <FormControlLabel
              label={billingProfilesTexts.AUTO_ASSIGN_NEW_GCP_PROJECTS_LABEL}
              control={<Checkbox name="autoAssignGCP" checked={autoAssignGCP} onChange={handleChange} />}
              sx={{ marginBottom: 2 }}
            />
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                }}
              >
                Invoice buckets
              </Typography>
              <Button variant="outlined" color="primary" onClick={openBucketDialogInCreationMode}>
                Create new invoice bucket
              </Button>
            </Stack>
            {alertMessage !== false && (
              <Alert severity="warning" sx={{ marginBottom: 2 }}>
                {alertMessage}
              </Alert>
            )}

            <Stack spacing={2}>
              {buckets.map((bucket, index) => {
                const { id, name, isDefault, assets } = bucket;
                return (
                  <Card key={id || index.toString()}>
                    <CardContent>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            {name}
                          </Typography>
                          <AssetsLogos assets={assets} />
                        </Stack>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            openBucketDialogInEditionMode(bucket);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Stack>
                      <AssetsMultiselect
                        value={assets}
                        availableAssets={unassignedAssets}
                        onChange={(assets) => {
                          assignAssetsToBucket(bucket, assets);
                        }}
                      />
                    </CardContent>
                    <CardActions
                      sx={{ justifyContent: "space-between", borderTop: `1px solid ${theme.palette.divider}` }}
                    >
                      <DeleteBucketButton bucket={bucket} deleteBucket={deleteBucket} />
                      <Stack
                        direction="row"
                        sx={{
                          alignItems: "center",
                          pr: 1,
                        }}
                      >
                        {isDefault ? (
                          <Typography
                            variant="body2"
                            color="text/secondary"
                            sx={{
                              mr: 2,
                            }}
                          >
                            Default bucket
                          </Typography>
                        ) : (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                              setBucketAsDefault(bucket);
                            }}
                          >
                            Set as default
                          </Button>
                        )}
                        <Tooltip title="If all the unassigned assets are of the same asset type, they will be added to the default bucket when generating invoices.">
                          <InfoOutlinedIcon color="action" />
                        </Tooltip>
                      </Stack>
                    </CardActions>
                  </Card>
                );
              })}
            </Stack>
          </>
        )}
      </FormControl>
      <FormControl sx={{ marginBottom: 25 }}>
        <FormLabel sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{
              color: "text.primary",
              fontWeight: "medium",
            }}
          >
            Marketplace
          </Typography>
        </FormLabel>
        <FormControlLabel
          control={<Checkbox checked={separateInvoice} name="separateInvoice" onChange={handleChange} />}
          label="Separate Marketplace purchases"
        />
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          <RadioGroup
            name="invoicePerService"
            value={invoicePerService}
            onChange={(e) => setFieldValue("invoicePerService", e.target.value === "true")}
          >
            <FormControlLabel
              label="Create individual invoices for each purchase"
              control={<Radio />}
              value={true}
              disabled={!separateInvoice}
            />
            <FormControlLabel
              label="Create a single invoice with line items for each purchase"
              control={<Radio />}
              value={false}
              disabled={!separateInvoice}
            />
          </RadioGroup>
        </Box>
      </FormControl>
    </PanelWrapper>
  );
};
