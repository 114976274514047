import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Zapier = () => {
  const theme = useTheme();
  return (
    <Box
      data-cy="zapier-wrapper"
      sx={{
        p: 1,
        border: "thin",
        margin: 2,
        maxWidth: "900px",
      }}
    >
      <zapier-full-experience
        client-id="u9bbj199nFxgWKGJXG9mClHDN4QFyQPoGLQDmzAO"
        theme={theme.palette.mode}
        app-search-bar-display="show"
        zap-limit={10}
        zap-style="row"
        zap-create-from-scratch-display="show"
        data-cy="zapier-body"
      />
    </Box>
  );
};

export default Zapier;
