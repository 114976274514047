import { type JSX } from "react";

import { Paper, Stack } from "@mui/material";
import Box from "@mui/material/Box";

import { useFullScreen } from "../../../utils/dialog";

type Props = {
  menu: JSX.Element;
  title: JSX.Element;
  widgetActions: JSX.Element;
  operations: JSX.Element;
  children: JSX.Element;
};

export function DashboardLayout({ menu, title, widgetActions, operations, children }: Props) {
  const { isMobile } = useFullScreen();

  const heightWithoutHeader = "calc(100vh - 105px)";

  if (isMobile) {
    return (
      <Paper elevation={0}>
        <Stack
          direction="column"
          sx={{
            justifyContent: {
              sm: "center",
            },
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              pt: 2,
              px: 2,
              mb: 2,
            }}
          >
            {menu}
            {operations}
          </Stack>

          <Box
            sx={{
              p: {
                xs: 0,
                sm: 2,
              },
            }}
          >
            {children}
          </Box>
        </Stack>
      </Paper>
    );
  }

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: 208,
          maxHeight: heightWithoutHeader,
          overflowY: "auto",
        }}
      >
        {menu}
      </Box>
      <Paper
        variant="outlined"
        sx={{ borderRadius: 0, borderTop: 0, borderRight: 0, borderBottom: 0, minHeight: heightWithoutHeader }}
      >
        <Stack
          direction="column"
          sx={{
            px: 2,
            maxHeight: heightWithoutHeader,
            width: "calc(100vw - 208px)",
            overflowY: "scroll",
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              py: 2,
            }}
          >
            {title}
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
              }}
            >
              {widgetActions}
              {operations}
            </Stack>
          </Stack>
          {children}
        </Stack>
      </Paper>
    </Stack>
  );
}
