import { Container, Stack, Typography } from "@mui/material";

import { type JourneyStep, steps } from "../AWSOnboarding/constants";
import { getStep } from "../AWSOnboarding/utils";
import { FlexsaveJourney } from "../Common/Onboarding/FlexsaveJourney";

type Props = {
  step: JourneyStep;
};
export default function JourneyAWS({ step }: Props) {
  return (
    <Container maxWidth="lg" sx={{ pt: 3 }}>
      <Stack spacing={2}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "500",
          }}
        >
          Follow your Flexsave journey
        </Typography>
        <FlexsaveJourney stepList={steps} currentStep={getStep(step)} />
      </Stack>
    </Container>
  );
}
