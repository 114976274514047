import { type JSX, type ReactNode } from "react";

import { LoadingButton } from "@mui/lab";
import { AppBar, type Breakpoint, Button, Container, Stack, Toolbar, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: ReactNode) => JSX.Element;
  children: ReactNode;
  // must be wrapped in a fragment in React 17
}) => (condition ? <>{wrapper(children)}</> : <>{children}</>);

function TooltipWrapper(title?: string) {
  return function TooltipWrapper(children: ReactNode) {
    return (
      <Tooltip title={title}>
        <span>{children}</span>
      </Tooltip>
    );
  };
}

export type BottomAppBarProps = {
  primaryButtonProps?: Partial<React.ComponentProps<typeof LoadingButton>>;
  secondaryButtonProps?: Partial<React.ComponentProps<typeof Button>>;
  backButtonProps?: Partial<React.ComponentProps<typeof Button>>;
  primaryButtonName: string;
  secondaryButtonName?: string;
  backButtonName?: string;
  disabled?: boolean;
  disabledTooltipTitle?: string;
  loading?: boolean;
  handlePrimaryButtonClicked?: () => void;
  handleSecondaryButtonClicked?: () => void;
  handleBackButtonClicked?: () => void;
  formId?: string;
  maxWidth?: Breakpoint;
};

export const BottomAppBar = ({
  primaryButtonProps,
  secondaryButtonProps,
  backButtonProps,
  primaryButtonName,
  secondaryButtonName,
  backButtonName,
  disabled = false,
  disabledTooltipTitle,
  loading = false,
  handleSecondaryButtonClicked,
  handlePrimaryButtonClicked,
  handleBackButtonClicked,
  formId,
  maxWidth,
}: BottomAppBarProps) => {
  const theme = useTheme();
  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={{
        top: "auto",
        bottom: 0,
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.common.white,
        borderTop: "solid",
        borderTopWidth: "1px",
        borderTopColor: theme.palette.divider,
      }}
    >
      <Toolbar>
        <Container maxWidth={maxWidth}>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              pl: { md: 3, sm: 3, xs: 2 },
            }}
          >
            {!!backButtonName && (
              <Button
                data-cy="btn-appbar-back"
                variant="outlined"
                onClick={handleBackButtonClicked}
                {...backButtonProps}
              >
                {backButtonName}
              </Button>
            )}
            <Stack
              spacing={2}
              direction="row"
              sx={{
                width: "100%",
                justifyContent: "end",
                pr: { md: 3, sm: 2, xs: 2 },
              }}
            >
              {!!secondaryButtonName && (
                <Button
                  data-cy="btn-appbar-secondary"
                  variant="text"
                  onClick={handleSecondaryButtonClicked}
                  {...secondaryButtonProps}
                >
                  {secondaryButtonName}
                </Button>
              )}
              <ConditionalWrapper condition={disabled} wrapper={TooltipWrapper(disabledTooltipTitle)}>
                <LoadingButton
                  data-cy="btn-appbar-primary"
                  form={formId}
                  type={formId ? "submit" : "button"}
                  variant="contained"
                  loading={loading}
                  disabled={disabled}
                  onClick={handlePrimaryButtonClicked}
                  {...primaryButtonProps}
                >
                  {primaryButtonName}
                </LoadingButton>
              </ConditionalWrapper>
            </Stack>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
