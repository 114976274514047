import { Box, Typography } from "@mui/material";

import LoadingButton from "../../../../Components/LoadingButton";
import { text } from "../../consts";

type Props = {
  variant: "text" | "contained" | "outlined";
  color: "header" | "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
  loading: boolean;
  onClick: (() => Promise<void>) | (() => void);
  disabled: boolean;
  dataCy: string;
  buttonText: string;
  actionsDisabledForNonOwners: boolean;
};

export const FooterActions = ({
  variant,
  color,
  loading,
  onClick,
  disabled,
  dataCy,
  buttonText,
  actionsDisabledForNonOwners,
}: Props) => (
  <Box
    sx={{
      py: 1,
      px: 3,
    }}
  >
    <LoadingButton
      variant={variant}
      color={color}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      data-cy={dataCy}
      mixpanelEventId={`user-view.footer.${dataCy}`}
    >
      {buttonText}
    </LoadingButton>

    {actionsDisabledForNonOwners && (
      <Typography
        variant="caption"
        color="textSecondary"
        component="div"
        sx={{
          ml: 0,
          pt: 1,
        }}
      >
        {text.API_TAB_ACTIONS_DISABLED_TIP}
      </Typography>
    )}
  </Box>
);
