import { useState } from "react";

import { FlexsaveTypes } from "@doitintl/cmp-models";
import { Checkbox, Container, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";

import { Contract } from "../../../Pages/Flexsave/Standalone/Common/Contract";
import { PackageName } from "../Components/PackageName";
import { type PackageType } from "../types";

type Props = {
  packageType: PackageType;
  setContractAgreed: (agreed: boolean) => void;
  contractAgreed: boolean;
};

export const ContractStep = ({ packageType, setContractAgreed, contractAgreed }: Props) => {
  const [authCheck, setAuthCheck] = useState<boolean>(contractAgreed);
  const [agreementCheck, setAgreementCheck] = useState<boolean>(contractAgreed);

  const handleAuthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthCheck(e.target.checked);
    setContractAgreed(e.target.checked && agreementCheck);
  };

  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreementCheck(e.target.checked);
    setContractAgreed(e.target.checked && authCheck);
  };

  return (
    <Container maxWidth="sm" sx={{ pt: 4 }}>
      <Stack
        spacing={1}
        sx={{
          alignSelf: "start",
          pb: 4,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "500",
          }}
        >
          DoiT International Services Agreement
        </Typography>
        <PackageName packageType={packageType} />
      </Stack>
      <Contract type={FlexsaveTypes.AWS} />
      <Stack
        spacing={1}
        sx={{
          alignSelf: "start",
          pt: 4,
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={authCheck} onChange={handleAuthChange} data-cy="i-am-authorized" />}
            label={
              <Typography
                sx={{
                  color: "text.secondary",
                }}
              >
                I am authorized to sign this agreement
              </Typography>
            }
          />
          <FormControlLabel
            control={<Checkbox checked={agreementCheck} onChange={handleAgreementChange} data-cy="i-agree" />}
            label={
              <Typography
                sx={{
                  color: "text.secondary",
                }}
              >
                I agree to the terms of the Agreement
              </Typography>
            }
          />
        </FormGroup>
      </Stack>
    </Container>
  );
};
