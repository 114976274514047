import { Box } from "@mui/material";
import noop from "lodash/noop";

import { StickyFooter } from "../../../../Components/StickyFooter";
import { AssetFieldsArea } from "./Fields/AssetFieldsArea";
import { AssetHeader } from "./Header/AssetHeader";

type Props = {
  backTo: string;
  onCancel: () => void;
};

const GcAssetPageLoading = ({ backTo, onCancel }: Props) => (
  <Box sx={{ ml: 2, maxWidth: "600px" }}>
    <Box
      sx={{
        pb: 9,
      }}
    >
      <AssetHeader transferProjectsAction={noop} manageAccountAction={noop} backTo={backTo} showTransfer={false} />
      <AssetFieldsArea
        displayName=""
        entities={[]}
        selectedEntityId=""
        onSelectedEntityChange={noop}
        assetTag=""
        onTagInputChange={noop}
        suggestedTags={{}}
        loading
      />
    </Box>
    <StickyFooter
      disabled={true}
      onClick={noop}
      buttonText="Save and close"
      onCancel={onCancel}
      buttonTextLoading="Saving..."
      cancelButtonStyle={{ color: "inherit" }}
      sx={{ pr: 3.81 }}
    />
  </Box>
);

export default GcAssetPageLoading;
