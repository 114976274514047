import { Badge, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import { useNotesContext } from "../../../../../Context/customer/NotesContext";

const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: theme.typography.pxToRem(10),
  },
}))(Badge);

export const CustomerNotesRow = () => {
  const notesContextValue = useNotesContext();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "30px",
      }}
    >
      <Typography variant="body2">Customer Notes</Typography>
      <Tooltip title="Customer Notes">
        <IconButton sx={{ marginLeft: 1 }} onClick={notesContextValue.toggle}>
          <StyledBadge
            badgeContent={notesContextValue.data?.pinned || notesContextValue.data?.unpinned}
            color="primary"
            max={20}
            invisible={!notesContextValue.data?.unpinned}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
