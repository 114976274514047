import { useMemo, useRef, useState } from "react";

import { Box, Card, FormHelperText, Link, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";

import useMountEffect from "../../../Components/hooks/useMountEffect";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { AwsTags } from "../AwsTags/AwsTags";
import { PageHeaderWithTimeFilter } from "../Common/PageHeaderWithTimeFilter";
import { type SpotScalingApi } from "../spotScalingApi";
import { FbodDisabledReason } from "../strings";
import { type AsgItem, type AsgTag, AsgTagKeys, OptimizationStatus, SavingsTimeFilter } from "../types";
import { AsgsSkeleton } from "./AsgConfiguration/AsgsSkeleton";
import { type AsgItemWithMandatoryRecommendation, AsgNotOptimized } from "./AsgsCompare/AsgNotOptimized";
import { AsgOptimized } from "./AsgsCompare/AsgOptimized";
import { AsgWithError } from "./AsgsCompare/AsgWithError";
import { FallbackToOnDemand } from "./GeneralSettings/FallbackToOnDemand";
import { MonthUsage } from "./Savings/MonthUsage";

type Props = {
  asg: AsgItem;
  spotScalingApi: SpotScalingApi;
};

export const ApplyContent = ({ asg, spotScalingApi }: Props) => {
  const { customer } = useCustomerContext();
  const [timeFilter, setTimeFilter] = useState<SavingsTimeFilter>(SavingsTimeFilter.CurrentMonth);
  const alertContainer = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useMountEffect(() => {
    setIsMounted(true);
  });

  const usage = useMemo(
    () => (timeFilter === SavingsTimeFilter.CurrentMonth ? asg.currentMonthUsage : asg.lastMonthUsage),
    [timeFilter, asg]
  );

  const fbodDisabledReason: string = useMemo(() => {
    if (asg.configurations.current.isLaunchConfiguration) {
      return FbodDisabledReason.lc;
    } else if (!asg.configurations.current.isMixedTypesInstances) {
      return FbodDisabledReason.noMip;
    }

    if (!asg.isApplyChangesSupported) {
      return asg.spotisizeError ?? "";
    }
    const tags: AsgTag[] = asg.configurations.current.tags.filter((tag) => tag.Key.indexOf(AsgTagKeys.AutoEnroll) >= 0);
    if (tags.length > 0) {
      return FbodDisabledReason.tags;
    }
    return "";
  }, [asg]);

  const fbodDisabledReasonPart2: string = useMemo(() => {
    if (asg.configurations.current.isLaunchConfiguration) {
      return "";
    } else if (!asg.configurations.current.isMixedTypesInstances) {
      return FbodDisabledReason.noMip2;
    }

    return "";
  }, [asg]);

  const fbodDisabledLink = useMemo(() => {
    if (asg.configurations.current.isLaunchConfiguration) {
      return {
        text: "",
        url: "",
      };
    } else if (!asg.configurations.current.isMixedTypesInstances) {
      return { text: FbodDisabledReason.awsNoMipDocs, url: FbodDisabledReason.awsNoMipUrl };
    }

    return {
      text: "",
      url: "",
    };
  }, [asg]);

  const notOptimized = useMemo(
    () =>
      asg.configurations.recommended &&
      (asg.optimizationStatus === OptimizationStatus.NotOptimized ||
        asg.optimizationStatus === OptimizationStatus.NewRecommendations),
    [asg]
  );

  return (
    <>
      <Box
        sx={{
          pb: 1,
        }}
      >
        <Card>
          <PageHeaderWithTimeFilter
            customer={customer}
            title={asg.asgName}
            backButtonTakeSpotScalingMainPage={true}
            timeFilterProps={{
              value: timeFilter,
              onTimeFilterChanged: setTimeFilter,
              showPartialOptions: true,
            }}
          />
        </Card>
      </Box>
      <Box>
        <div ref={alertContainer} />
      </Box>
      {isMounted && alertContainer?.current !== null && (
        <>
          <Box
            sx={{
              pt: 1,
            }}
          >
            <MonthUsage usage={usage} textPrefix={timeFilter} />
          </Box>

          <Box
            sx={{
              pt: 1,
            }}
          >
            {(() => {
              if (asg.spotisizeError || asg.updateError) {
                return <AsgWithError asg={asg} notificationContainer={alertContainer} />;
              } else if (notOptimized) {
                return (
                  <AsgNotOptimized
                    spotScalingApi={spotScalingApi}
                    asg={asg as AsgItemWithMandatoryRecommendation}
                    notificationContainer={alertContainer}
                  />
                );
              } else if (asg) {
                return (
                  <AsgOptimized spotScalingApi={spotScalingApi} asg={asg} notificationContainer={alertContainer} />
                );
              } else {
                return <AsgsSkeleton />;
              }
            })()}
          </Box>

          <Box
            sx={{
              pt: 1,
            }}
          >
            <Card>
              {asg && (
                <CardContent>
                  <Box
                    sx={{
                      ml: 2,
                    }}
                  >
                    <Box
                      sx={{
                        pb: 1,
                      }}
                    >
                      <Typography variant="h5">Additional settings</Typography>
                    </Box>
                    <FallbackToOnDemand
                      spotScalingApi={spotScalingApi}
                      disabled={!asg.isApplyChangesSupported || fbodDisabledReason !== ""}
                      asg={asg}
                      notificationContainer={alertContainer}
                    />

                    {fbodDisabledReason !== "" && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                          }}
                        >
                          <Box
                            style={{ height: 25, width: 30 }}
                            sx={{
                              display: "flex",
                            }}
                          />
                          <Box
                            style={{ minHeight: 25, maxWidth: 500 }}
                            sx={{
                              display: "flex",
                            }}
                          >
                            <FormHelperText>
                              {fbodDisabledReason}
                              <Link href={fbodDisabledLink.url} underline="none" target="_blank">
                                {fbodDisabledLink.text}
                              </Link>
                              {fbodDisabledReasonPart2}
                            </FormHelperText>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </CardContent>
              )}
            </Card>
          </Box>

          <Box
            sx={{
              pt: 1,
            }}
          >
            <Card>
              <CardContent>
                <Box
                  sx={{
                    ml: 2,
                  }}
                >
                  <Box
                    sx={{
                      pb: 1,
                    }}
                  >
                    <Typography variant="h5">AWS tags</Typography>
                  </Box>
                  <AwsTags tags={asg.configurations.current.tags} />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};
