import { useEffect, useRef, useState } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Link, Popover, Stack, Typography } from "@mui/material";

import step1Img from "../../../../assets/guidedExperience/guidedExperience-step1.svg";
import { guidedExperienceText } from "../../../../assets/texts/CloudAnalytics";
import { sendStepMixpanelEvent } from "../utils";

const attributionsDocs = "https://help.doit.com/docs/cloud-analytics/attributing-cloud-spend";
const attributionGroupsDocs = "https://help.doit.com/docs/cloud-analytics/attribution-groups";

const STEP_NUMBER = 1;

type DefinitionPopoverProps = {
  anchorEl: HTMLElement | null;
  docsLink: string;
  firstWord: string;
  id: string;
  open: boolean;
  popoverEnter: () => void;
  popoverLeave: () => void;
  text: string;
};

const DefinitionPopover = ({
  anchorEl,
  docsLink,
  firstWord,
  id,
  open,
  popoverEnter,
  popoverLeave,
  text,
}: DefinitionPopoverProps) => (
  <Popover
    id={id}
    sx={{
      pointerEvents: "none",
      ".MuiPopover-paper": {
        width: "300px",
        pointerEvents: "auto",
      },
    }}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    disableRestoreFocus
    slotProps={{
      paper: {
        onMouseEnter: popoverEnter,
        onMouseLeave: popoverLeave,
      },
    }}
  >
    <Box sx={{ p: 2 }}>
      <Typography
        variant="body2"
        sx={{
          mb: 1,
        }}
      >
        <Typography component="span" variant="body2" sx={{ fontWeight: 500 }}>
          {firstWord}
        </Typography>
        {text}
      </Typography>
      <Link
        href={docsLink}
        target="_blank"
        variant="body2"
        sx={{ textDecoration: "none", display: "flex", alignItems: "center" }}
      >
        {guidedExperienceText.step1.learnMore}
        <OpenInNewIcon sx={{ ml: 0.5, width: "14px", height: "14px" }} fontSize="small" />
      </Link>
    </Box>
  </Popover>
);

type Props = {
  origin: string;
};

const Step1 = ({ origin }: Props) => {
  const [attrPopoverIsOpen, setAttrPopoverIsOpen] = useState(false);
  const attrPopoverAnchor = useRef(null);
  const [agPopoverIsOpen, setAgPopoverIsOpen] = useState(false);
  const agPopoverAnchor = useRef(null);

  useEffect(() => {
    sendStepMixpanelEvent(STEP_NUMBER, "start", origin);
  }, [origin]);

  const handleAttrPopoverOpen = () => {
    setAttrPopoverIsOpen(true);
  };

  const handleAttrPopoverClose = () => {
    setAttrPopoverIsOpen(false);
  };

  const handleAgPopoverOpen = () => {
    setAgPopoverIsOpen(true);
  };

  const handleAgPopoverClose = () => {
    setAgPopoverIsOpen(false);
  };

  return (
    <>
      <Stack
        sx={{
          gap: 3,
        }}
      >
        <Typography>{guidedExperienceText.step1.p1}</Typography>
        <Typography>
          {guidedExperienceText.step1.p2.part1}
          <Typography
            ref={attrPopoverAnchor}
            component="span"
            sx={{ borderBottom: "1px dashed" }}
            onMouseEnter={handleAttrPopoverOpen}
            onMouseLeave={handleAttrPopoverClose}
          >
            {guidedExperienceText.step1.p2.attributions}
          </Typography>
          {guidedExperienceText.step1.p2.and}
          <Typography
            ref={agPopoverAnchor}
            component="span"
            sx={{ borderBottom: "1px dashed" }}
            onMouseEnter={handleAgPopoverOpen}
            onMouseLeave={handleAgPopoverClose}
          >
            {guidedExperienceText.step1.p2.attributionGroups}
          </Typography>
          {guidedExperienceText.step1.p2.part2}
        </Typography>
        <Box
          component="img"
          src={step1Img}
          sx={{
            maxWidth: "700px",
          }}
        />
        <Typography>{guidedExperienceText.step1.p3}</Typography>
      </Stack>
      <DefinitionPopover
        anchorEl={attrPopoverAnchor.current}
        docsLink={attributionsDocs}
        firstWord={guidedExperienceText.step1.attributions}
        id="attribution-popover"
        open={attrPopoverIsOpen}
        popoverEnter={handleAttrPopoverOpen}
        popoverLeave={handleAttrPopoverClose}
        text={guidedExperienceText.step1.attributionsExplainer}
      />
      <DefinitionPopover
        anchorEl={agPopoverAnchor.current}
        docsLink={attributionGroupsDocs}
        firstWord={guidedExperienceText.step1.attributionGroups}
        id="ag-popover"
        open={agPopoverIsOpen}
        popoverEnter={handleAgPopoverOpen}
        popoverLeave={handleAgPopoverClose}
        text={guidedExperienceText.step1.agExplainer}
      />
    </>
  );
};

export default Step1;
