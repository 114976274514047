import { Box, TextField } from "@mui/material";

import { useAuthContext } from "../../../../Context/AuthContext";
import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { text } from "../text";
import { type SettingsValuesType } from "../types";

export function LinesPerInvoice({
  errMsg,
  value,
  onChange,
}: {
  errMsg?: string;
  value?: SettingsValuesType["linesPerInvoice"];
  onChange: (newValue: SettingsValuesType["linesPerInvoice"]) => void;
}) {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  if (!isDoitEmployee) {
    return null;
  }

  return (
    <TextField
      fullWidth
      type="text"
      label={text.LINES_PER_INVOICE_LABEL}
      value={value ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onChange(null);
        } else {
          const numberVal = parseInt(e.target.value);
          if (!isNaN(numberVal) && numberVal !== value) {
            onChange(numberVal);
          }
        }
      }}
      helperText={!!errMsg && <Box sx={{ display: "flex", color: "error.main" }}>{errMsg}</Box>}
      slotProps={{
        htmlInput: { pattern: "[0-9]*", "data-cy": DATA_CY_GENERAL_SETTINGS_SELECTORS.LINES_PER_INVOICE },
      }}
    />
  );
}
