import { type Dispatch, useCallback } from "react";

import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button, Link, Stack, Typography } from "@mui/material";
import { type InputProps } from "@mui/material/Input/Input";

import { globalText, loginTexts, ssoLoginTexts } from "../../../assets/texts";
import { useEmailSchema } from "../helpers";
import { useSsoSignIn } from "../hooks";
import { type LoginHookAction, LoginHookActionType, type LoginState } from "../types";
import EmailFormTemplate from "./EmailFormTemplate";

const SsoLoginForm = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const signInWithSso = useSsoSignIn({ dispatch });
  const EmailSchema = useEmailSchema();

  const emailInputHandler: InputProps["onChange"] = useCallback(
    (e) => {
      dispatch({ type: LoginHookActionType.setEmail, email: e.currentTarget.value });
    },
    [dispatch]
  );

  return (
    <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
      <Typography
        variant="h1"
        sx={{
          alignSelf: "flex-start",
        }}
      >
        {loginTexts.SSO_LOGIN}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          pb: 1,
        }}
      >
        {ssoLoginTexts.ENTER_EMAIL_ADDRESS}
      </Typography>
      <EmailFormTemplate
        email={state.email}
        isLoading={state.isLoading}
        submitHandler={signInWithSso}
        emailInputHandler={emailInputHandler}
        validationSchema={EmailSchema}
        submitButtonText={loginTexts.SIGN_IN}
        submitMixpanelEventId="login.with-sso"
      />
      <Button
        fullWidth={false}
        data-cy="sso-back-button"
        color="primary"
        variant="text"
        onClick={
          state.loginType === "completeSignup"
            ? () => {
                dispatch({ type: LoginHookActionType.setFormType, formType: "completeSignup" });
              }
            : () => {
                dispatch({ type: LoginHookActionType.setFormType, formType: "checkEmail" });
              }
        }
      >
        {globalText.BACK}
      </Button>
      <Button
        sx={{ textTransform: "none" }}
        color="inherit"
        size="small"
        component={Link}
        href="https://help.doit.com/docs/user-management/authentication#single-sign-on"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Learn more"
        startIcon={<OpenNewIcon />}
      >
        {ssoLoginTexts.SSO_LEARN_MORE}
      </Button>
    </Stack>
  );
};
export default SsoLoginForm;
