import { useCallback } from "react";

import { type EntityModel } from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Stack, Typography } from "@mui/material";

import { CreateBillingProfileForm } from "../../../../../Components/CreateBillingProfile/BillingForm/CreateBillingProfileForm";
import { useCreateBillingProfileSubmitFunction } from "../../../../../Components/CreateBillingProfile/useCreateBillingProfileSubmitFunction";
import { IconWithLink } from "../../../Common/IconWithLink";
import { ContactSupport } from "../ContactSupport";
import { HelpPanel } from "../HelpPanel";
import { InviteCollegeLink } from "../InviteCollegeLink";
import { OnboardLayout } from "../OnboardLayout";

type Props = {
  handleBack: () => void;
  handleDone: () => void;
  handleUpdate: (priorityId: string) => Promise<void>;
};

export const CreateNewBillingProfile = ({ handleDone, handleBack, handleUpdate }: Props) => {
  const creationCallback = useCallback(
    (entity: EntityModel) => {
      if (!entity.priorityId) {
        throw new Error("Billing profile doesn't have priorityId");
      }
      handleUpdate(entity.priorityId);
      handleDone();
    },
    [handleDone, handleUpdate]
  );

  const [isSubmitting, createBillingProfile] = useCreateBillingProfileSubmitFunction(creationCallback);

  return (
    <OnboardLayout
      information={
        <HelpPanel text="After activating Flexsave, you will begin to see savings in billing statements from your cloud provider. DoiT will invoice you for a percentage of the savings generated by Flexsave.">
          <IconWithLink
            text="Review our data compliance information"
            icon={<OpenNewIcon />}
            url="https://www.doit-intl.com/compliance/"
          />
          <ContactSupport />
          <InviteCollegeLink />
        </HelpPanel>
      }
      bottomAppBarProps={{
        primaryButtonName: "Confirm payment information",
        secondaryButtonName: "Back",
        handleSecondaryButtonClicked: handleBack,
        loading: isSubmitting,
        formId: "billingForm",
      }}
    >
      <Stack>
        <Typography
          variant="h1"
          sx={{
            pb: 1,
            fontWeight: "500",
          }}
        >
          Payment information
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
            pb: 3.5,
          }}
        >
          Payment information is required in order to receive invoices
        </Typography>
        <CreateBillingProfileForm onSubmit={createBillingProfile} />
      </Stack>
    </OnboardLayout>
  );
};
