import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, IconButton } from "@mui/material";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { useCustomerContext } from "../../../Context/CustomerContext";
import mixpanel from "../../../utils/mixpanel";
import { filters, headers } from "./Columns";
import { useLabels } from "./hooks";
import LabelDeleteDialog from "./LabelDeleteDialog";
import LabelDialog from "./LabelDialog";
import LabelRow from "./LabelRow";
import { type LabelRowItem } from "./types";

export const LabelsBrowser = () => {
  const history = useHistory<any>();
  const { customer } = useCustomerContext();

  const [selectedLabel, setSelectedLabel] = useState<LabelRowItem>();
  const [labelDialogOpen, setLabelDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const [labels, labelsLoading] = useLabels();

  const rows = labels
    ? labels.map<LabelRowItem>((row) => ({ ...row, timesApplied: row.data.objects?.length ?? 0 }))
    : [];

  useEffect(() => {
    mixpanel.track("analytics.labels.list");
  }, []);

  const handleBack = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? `/customers/${customer.id}/analytics/all`);
  }, [history, customer.id]);

  const labelRow = ({ row }) => (
    <LabelRow
      row={row}
      handleEdit={() => {
        setSelectedLabel(row);
        setLabelDialogOpen(true);
      }}
      handleDelete={() => {
        setSelectedLabel(row);
        setDeleteDialogOpen(true);
      }}
    />
  );

  const handleCreateLabel = useCallback(() => {
    setSelectedLabel(undefined);
    setLabelDialogOpen(true);
  }, []);

  if (labelsLoading) {
    return (
      <Box
        sx={{
          p: 1,
        }}
      >
        <FilterTableSkeleton />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
      }}
    >
      <IconButton aria-label="Back" size="large" onClick={handleBack} data-cy="back-icon">
        <BackIcon fontSize="medium" color="action" />
      </IconButton>
      <FilterTable<LabelRowItem>
        showRowsSelection={false}
        tableItems={rows}
        rowComponent={labelRow}
        headerColumns={headers}
        filterColumns={filters}
        filterBarPlaceholder="Filter"
        itemUniqIdentifierField="ref.id"
        defaultSortingColumnValue="data.timeCreated"
        toolbarProps={{
          primaryButton: {
            text: "New Label",
            onClick: handleCreateLabel,
          },
          title: "Label Management",
        }}
      />
      {labelDialogOpen && (
        <LabelDialog
          selectedLabel={selectedLabel}
          open={labelDialogOpen}
          onClose={() => {
            setLabelDialogOpen(false);
          }}
        />
      )}
      {selectedLabel && deleteDialogOpen && (
        <LabelDeleteDialog
          labelId={selectedLabel.ref.id}
          open={deleteDialogOpen}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
};
