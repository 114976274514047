import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import { NotificationProviderType, UserPermissions } from "@doitintl/cmp-models";
import EmailIcon from "@mui/icons-material/Email";
import { Alert, Box, Button, Stack, SvgIcon, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";

import SlackIcon from "../../../../assets/slack-mark.png";
import TeamsIcon from "../../../../assets/teams.svg";
import { slackAppIntegrationURI } from "../../../../Components/Slack/SlackAppIntegrationButton";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../../Context/TierProvider";
import { useUserContext } from "../../../../Context/UserContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { type AuthTestResponse, useSlackApi } from "../../../Integrations/Slack/useSlackApi";
import { ProviderOption } from "./components/ProviderOption";
import { WizardTitle } from "./components/WizardTitle";
import { type ChannelConfig, type ProviderOptionItem } from "./types";

type Props = {
  channelConfig?: ChannelConfig;
  updateChannelConfig: <K extends keyof ChannelConfig>(key: K, value: ChannelConfig[K]) => void;
  viewOnly: boolean;
};

type SlackRequiredActionProps = {
  customerId: string;
  viewOnly: boolean;
};

const SlackRequiredAction = ({ customerId, viewOnly }: SlackRequiredActionProps) => {
  const [slackAppAuth, setSlackAppAuth] = useState<AuthTestResponse>();
  const [loading, setLoading] = useState(true);
  const { authTest } = useSlackApi();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const hasManageSettingsPermission = userRoles.permissions?.has(UserPermissions.Settings);

  useEffect(() => {
    const fetchSlackAuth = async () => {
      if (hasManageSettingsPermission) {
        try {
          const response = await authTest();
          setSlackAppAuth(response.data);
        } catch (error) {
          consoleErrorWithSentry(error);
        }
      }
      setLoading(false);
    };
    fetchSlackAuth();
  }, [authTest, hasManageSettingsPermission]);

  const appInstalled = slackAppAuth?.ok;
  const installOrUpdateRequired = !appInstalled || !slackAppAuth.private_channels_scopes;
  const allertMessage = slackAppAuth?.private_channels_scopes
    ? "DoiT Slack app is installed"
    : "You're using an older version of the DoiT Slack app. To send notifications to private channels, update it to the latest version";

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack spacing={2} direction="column">
          {appInstalled && <Alert severity={installOrUpdateRequired ? "info" : "success"}>{allertMessage}</Alert>}
          {installOrUpdateRequired && (
            <Box
              sx={{
                width: "auto",
              }}
            >
              <Button
                disabled={viewOnly}
                variant="outlined"
                onClick={() => window.open(`${slackAppIntegrationURI}${customerId}`, "_self")}
                color="primary"
              >
                Install DoiT Slack app
              </Button>
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

export const Provider = ({ channelConfig, updateChannelConfig, viewOnly }: Props) => {
  const { customer } = useCustomerContext();
  const isMSTeamsEntitled = useIsFeatureEntitled("notification:msteams");

  const getSVGFromURL = (url: string, color: string) => (
    <SvgIcon sx={{ fontSize: 22 }}>
      <svg>
        <image xlinkHref={url} width="22" height="22" color={color} />
      </svg>
    </SvgIcon>
  );

  const options: ProviderOptionItem[] = useMemo(() => {
    const optns: ProviderOptionItem[] = [
      {
        title: "Slack",
        provider: NotificationProviderType.SLACK,
        renderIcon: (color: string) => getSVGFromURL(SlackIcon, color),
        selected: false,
        disabled: viewOnly,
        requiredActions: {
          title: "Install the DoiT Slack app",
          description: "To start receiving Slack notifications you will need to install the DoiT Slack app",
          content: <SlackRequiredAction customerId={customer.id} viewOnly={viewOnly} />,
        },
        onClick: () => {},
      },
    ];
    if (isMSTeamsEntitled) {
      optns.push({
        title: "Microsoft Teams",
        provider: NotificationProviderType.MSTEAMS,
        renderIcon: (color: string) => getSVGFromURL(TeamsIcon, color),
        selected: false,
        disabled: viewOnly,
        requiredActions: {
          title: "Connect Microsoft Teams to the DoiT console",
          description:
            "To receive notifications, you need to install the DoiT app to the team you want to send notifications",
          content: (
            <Button
              variant="outlined"
              onClick={() =>
                window.open("https://teams.microsoft.com/l/app/3a949400-9470-47c3-98d9-9c5ab5bd1c55", "_blank")
              }
            >
              Install DoiT Teams app
            </Button>
          ),
        },
        onClick: () => {},
      });
    }
    optns.push({
      title: "Email",
      provider: NotificationProviderType.EMAIL,
      renderIcon: (color: string) => (
        <EmailIcon sx={{ fontSize: 22, color: color === "disabled" ? "disabled" : "action.active" }} />
      ),
      selected: false,
      disabled: viewOnly,
      onClick: () => {},
    });
    return optns;
  }, [customer.id, viewOnly, isMSTeamsEntitled]);

  const onProviderClick = useCallback(
    (index: number) => {
      if (viewOnly) {
        return;
      }
      updateChannelConfig("providerType", options[index].provider);
    },
    [options, updateChannelConfig, viewOnly]
  );

  const onChannelNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateChannelConfig("name", e.target.value);
    },
    [updateChannelConfig]
  );

  const selectedProvider = useMemo(
    () => options.findIndex((o) => o.provider === channelConfig?.providerType),
    [channelConfig?.providerType, options]
  );

  return (
    <Box
      sx={{
        maxWidth: 1080,
        alignItems: "center",
        width: "100%",
      }}
    >
      <WizardTitle
        title="Name this notification"
        description="This will help you if you need to update it in the future"
      />
      <TextField
        label="Notification name"
        onChange={onChannelNameChange}
        required={true}
        disabled={viewOnly}
        variant="outlined"
        value={channelConfig?.name ?? ""}
        sx={{ minWidth: 370 }}
      />
      <WizardTitle title="Select provider" description="Choose where you want your notifications to go" />
      <Grid container spacing={2}>
        {options.map((option, i) => (
          <Grid
            key={option.title}
            size={{
              xs: 6,
              sm: 4,
            }}
          >
            <ProviderOption
              title={option.title}
              selected={i === selectedProvider}
              disabled={option.disabled}
              renderIcon={option.renderIcon}
              onClick={() => {
                onProviderClick(i);
              }}
            />
          </Grid>
        ))}
      </Grid>
      {options?.[selectedProvider]?.requiredActions && (
        <Box sx={{ mt: 4 }}>
          <WizardTitle
            title={options[selectedProvider]?.requiredActions?.title}
            description={options[selectedProvider]?.requiredActions?.description}
          />
          {!!options[selectedProvider]?.requiredActions?.content && (
            <Box sx={{ mt: 2 }}>{options[selectedProvider]?.requiredActions?.content}</Box>
          )}
        </Box>
      )}
    </Box>
  );
};
