import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  AppModelCloudHealthPriceBook,
  IntegrationModel,
  type IntegrationModelCloudhealthPricebooksModel,
  PricebookStatus,
} from "@doitintl/cmp-models";
import { getCollection, useCollectionData, type WithFirebaseModel } from "@doitintl/models-firestore";
import Typography from "@mui/material/Typography";
import { type PartialDeep } from "type-fest";

import { useApiContext } from "../../api/context";
import { pricebooksText } from "../../assets/texts";
import DeleteDialog from "../../Components/DeleteDialog";
import AWSPricebookTable from "../../Components/Pricebooks/AWSPricebooks/AmazonWebServicesPricebooks";
import Pricebook from "../../Components/Pricebooks/Pricebook";
import { type AWSPricebook } from "../../Components/Pricebooks/Types";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../utils";

export default function AWSTabs() {
  const [pricebooks] = useCollectionData(
    getCollection(IntegrationModel).doc("cloudhealth").collection("cloudhealthPricebooks"),
    { refField: "ref" }
  );

  const routeMatch = useRouteMatch();
  const api = useApiContext();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [copy, setCopy] = useState<any>(null);
  const [options, setOptions] = useState<any>(null);
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [pricebookIdForDeletion, setPricebookIdForDeletion] = useState<number | null>(null);
  const [pricebookToCreate, setPricebookToCreate] = useState(null);
  const [isDeleteVerified, setIsDeleteVerified] = useState<boolean>(false);

  const backToList = useCallback(() => {
    history.push("/pricebooks/amazon-web-services");
  }, [history]);

  const activePricebooks = useMemo(
    () => pricebooks?.filter((pb) => pb.status !== PricebookStatus.retired),
    [pricebooks]
  );

  useEffect(() => {
    if (id !== "create") {
      setCopy(null);
    }
  }, [id, routeMatch]);

  useEffect(() => {
    const getOptions = async () => {
      const docSnap = await getCollection(AppModelCloudHealthPriceBook).doc("cloudhealth-pricebooks").get();
      setOptions(docSnap.asModelData());
    };
    getOptions().catch(consoleErrorWithSentry);
  }, []);

  const handleCreate = useCallback(
    async (pricebook) => {
      setLoading(true);
      setPricebookToCreate(pricebook);
      try {
        await api.request({
          method: "post",
          url: "/v1/pricebooks/amazon-web-services",
          data: {
            book_name: pricebook.bookName,
            specification: pricebook.specification,
            editable: pricebook.editable,
          },
        });
        backToList();
        setPricebookToCreate(null);
        showSharedSnackbar({
          message: `${pricebook.bookName} successfully created`,
          variant: "success",
          autoHideDuration: 5000,
        });
      } catch (error) {
        consoleErrorWithSentry(error);
        showSharedSnackbar({
          message: `Error creating ${pricebook?.bookName || pricebook}`,
          variant: "error",
          autoHideDuration: 5000,
        });
      } finally {
        setLoading(false);
        setCopy(null);
      }
    },
    [api, backToList, showSharedSnackbar]
  );

  const handleUpdate = useCallback(
    async (pricebook) => {
      setLoading(true);
      try {
        await api.request({
          method: "put",
          url: "/v1/pricebooks/amazon-web-services",
          data: {
            id: pricebook.id,
            file_hash: pricebook.fileHash,
            book_name: pricebook.bookName,
            specification: pricebook.specification,
          },
        });
        backToList();
        showSharedSnackbar({
          message: `${pricebook.bookName} updated successfully`,
          variant: "success",
          autoHideDuration: 5000,
        });
      } catch (error) {
        consoleErrorWithSentry(error);
        showSharedSnackbar({
          message: `Error updating ${pricebook.bookName}`,
          variant: "error",
          autoHideDuration: 5000,
        });
      }
      setLoading(false);
    },
    [api, backToList, showSharedSnackbar]
  );

  const selectedPB = pricebooks?.find((pb) => pb.id === pricebookIdForDeletion);

  const handleAddInactiveFlagToPricebook = useCallback(async () => {
    try {
      pricebookIdForDeletion &&
        isDeleteVerified &&
        (await api.request({
          method: "put",
          url: "/v1/pricebooks/amazon-web-services",
          data: {
            id: pricebookIdForDeletion,
            file_hash: selectedPB?.fileHash,
            book_name: selectedPB?.bookName,
            specification: selectedPB?.specification,
            status: PricebookStatus.retired,
          },
        }));

      showSharedSnackbar({
        message: `${selectedPB?.bookName || ""} Pricebook deleted successfully`,
        variant: "success",
        autoHideDuration: 5000,
      });
      setPricebookIdForDeletion(null);
    } catch (error) {
      consoleErrorWithSentry(error);
      showSharedSnackbar({
        message: "Error deleting pricebook",
        variant: "error",
        autoHideDuration: 5000,
      });
    } finally {
      setIsDeleteVerified(false);
      setPricebookIdForDeletion(null);
    }
  }, [
    pricebookIdForDeletion,
    isDeleteVerified,
    api,
    selectedPB?.fileHash,
    selectedPB?.bookName,
    selectedPB?.specification,
    showSharedSnackbar,
  ]);

  useEffect(() => {
    if (isDeleteVerified && pricebookIdForDeletion !== null) {
      handleAddInactiveFlagToPricebook();
    }
  }, [isDeleteVerified, handleAddInactiveFlagToPricebook, pricebookIdForDeletion]);

  useEffect(() => {
    if (copy) {
      const newPricebook: PartialDeep<WithFirebaseModel<IntegrationModelCloudhealthPricebooksModel>> = {
        editable: true,
        bookName: `${copy.bookName} (copy)`,
        specification: copy.specification,
      };
      handleCreate(newPricebook);
      setCopy(null);
    }
  }, [copy, handleCreate]);

  const openVerifyDeleteDialog = useCallback(async (pricebookId: number) => {
    setPricebookIdForDeletion(pricebookId);
    setOpenDialog(true);
  }, []);

  const handleCopy = useCallback((pricebook: AWSPricebook) => {
    setCopy(pricebook);
  }, []);

  const handleDelete = useCallback(
    (pricebookId: number) => openVerifyDeleteDialog(pricebookId),
    [openVerifyDeleteDialog]
  );
  const onDialogDeleteClick = () => {
    setIsDeleteVerified(true);
  };
  const onDialogClose = () => {
    setOpenDialog(false);
  };

  if (id) {
    if (id === "create") {
      const newPricebook: PartialDeep<WithFirebaseModel<IntegrationModelCloudhealthPricebooksModel>> = {
        editable: true,
        bookName: "",
        specification: {
          comment: "",
          ruleGroups: [],
        },
      } as const;
      return (
        <Pricebook
          variant="create"
          pricebook={pricebookToCreate || newPricebook}
          onSave={handleCreate}
          loading={loading}
          options={options}
        />
      );
    }

    const pricebook = pricebooks?.find((pricebookItem) => pricebookItem.ref.id === id);
    if (!pricebook) {
      return null;
    }
    pricebook.editable = true;

    return (
      <Pricebook
        key={pricebook.ref.id}
        variant="edit"
        pricebook={pricebook}
        onSave={handleUpdate}
        loading={loading}
        options={options}
      />
    );
  } else {
    const customerList = selectedPB?.assignments;

    const styledCustomerList = customerList?.map((acc, index) => (
      <Fragment key={acc.id}>
        {index > 0 && " and "}
        <Typography component="span" sx={{ fontWeight: 600 }}>
          {acc?.customerName}
        </Typography>
      </Fragment>
    ));

    const deletionMessage = (
      <Typography
        component="span"
        sx={{
          color: "text.primary",
        }}
      >
        <Typography component="span" sx={{ fontWeight: 600 }}>
          {selectedPB?.bookName}
        </Typography>
        {pricebooksText.DELETE_MESSAGE}
        {styledCustomerList && (
          <Typography component="span" sx={{ mt: 2, display: "block" }}>
            {pricebooksText.EFFECT_MESSAGE}
            {styledCustomerList}
          </Typography>
        )}
      </Typography>
    );

    if (!activePricebooks) {
      return null;
    }
    return (
      <>
        <AWSPricebookTable pricebooks={activePricebooks} onCopy={handleCopy} onDelete={handleDelete} />
        {openDialog && (
          <DeleteDialog
            open={openDialog}
            title={pricebooksText.DELETE_PRICEBOOK}
            message={deletionMessage}
            onDelete={onDialogDeleteClick}
            onClose={onDialogClose}
          />
        )}
      </>
    );
  }

  return null;
}
