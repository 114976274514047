import * as React from "react";

import Lottie from "react-lottie-player";
import { Avatar } from "@mui/material";
import { Stack } from "@mui/system";

import pulseLoading from "./Assets/pulse-loading.json";

export const AvaReportLoader = () => (
  <Stack
    sx={{
      alignItems: "left",
    }}
  >
    <Avatar
      variant="circular"
      sx={{
        mt: 1.5,
        height: 40,
        width: 40,
        mr: 28,
        backgroundColor: "background.paper",
        borderColor: "general.divider",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      <Lottie
        style={{ width: 60, height: 24, marginTop: 2 }}
        loop
        play
        animationData={pulseLoading}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
        speed={1.4}
      />
    </Avatar>
  </Stack>
);
