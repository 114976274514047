import { type AssetType, AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import { Skeleton, Stack } from "@mui/material";

import { type Entity } from "../../../../../Context/customer/EntitiesContext";
import { type SuggestedTags } from "../types";
import { AssetTagsField } from "./AssetTagsField";
import { BillingAccountField } from "./BillingAccountField";
import { BillingProfileField } from "./BillingProfileField";

type Props = {
  displayName: string;
  entities: Entity[];
  selectedEntityId: string;
  onSelectedEntityChange: (s: string) => void;
  assetTag: string;
  onTagInputChange: (str: string) => void;
  suggestedTags: SuggestedTags;
  assetType?: AssetType;
  loading: boolean;
};

export const AssetFieldsArea = ({
  displayName,
  entities,
  selectedEntityId,
  onSelectedEntityChange,
  assetTag,
  onTagInputChange,
  suggestedTags,
  assetType,
  loading,
}: Props) => {
  const assetFormItemSkeleton = <Skeleton variant="rectangular" sx={{ width: "600px", height: "54px" }} />;

  const billingAccountItem = assetType === AssetTypeGoogleCloud && <BillingAccountField value={displayName || ""} />;
  const billingProfileItem = (
    <BillingProfileField
      entities={entities}
      selectedEntityId={selectedEntityId || ""}
      onSelectedEntityChange={onSelectedEntityChange}
    />
  );
  const assetTagsItem = (
    <AssetTagsField
      assetTag={assetTag || ""}
      onTagInputChange={onTagInputChange}
      suggestedTags={suggestedTags}
      assetType={assetType}
    />
  );

  return (
    <Stack
      sx={{
        gap: 5,
        mb: 3,
      }}
    >
      {loading ? assetFormItemSkeleton : billingAccountItem}
      {loading ? assetFormItemSkeleton : billingProfileItem}
      {loading ? assetFormItemSkeleton : assetTagsItem}
    </Stack>
  );
};
