import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import {
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  PerkTypes,
} from "@doitintl/cmp-models";
import { Avatar, Box, Card, CardContent, Chip, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import AWSLogo from "../../assets/aws-logo.svg";
import AzureLogo from "../../assets/azure-logo.svg";
import GSuiteLogo from "../../assets/g-suite.png";
import GCPLogo from "../../assets/gcp-logo.svg";
import DoitLogoWhite from "../../assets/logo-white.svg";
import { type PerksWData } from "../../types";
import { useIsProfessionalService, useServicesMixpanelHandler } from "./hooks";

type Props = {
  perk: PerksWData;
};

const marketplaceMap = {
  [AssetTypeAmazonWebServices]: {
    title: "AWS",
    logo: AWSLogo,
  },
  [AssetTypeGoogleCloud]: { title: "GCP", logo: GCPLogo },
  [AssetTypeGSuite]: { title: "Google Workspace", logo: GSuiteLogo },
  [AssetTypeMicrosoftAzure]: { title: "Microsoft Azure", logo: AzureLogo },
};

export default function PerkCard({ perk }: Props) {
  const { mixpanelServicesHandler } = useServicesMixpanelHandler();
  const history = useHistory();
  const theme = useTheme();
  const isProfessionalService = useIsProfessionalService();

  const goToPerkPage = useCallback(() => {
    if (
      [PerkTypes.Accelerator, PerkTypes.Training, PerkTypes.Workshop].some(
        (perkType) => perkType === perk.data.fields.type
      )
    ) {
      mixpanelServicesHandler(`nav.services.ps.${perk.data.fields.name.toLowerCase().replace(" ", "-")}`);
    }
    history.push(`${history.location.pathname?.replace(/\/+$/, "")}/${perk.id}`);
  }, [history, mixpanelServicesHandler, perk.data.fields.name, perk.data.fields.type, perk.id]);

  return (
    <Card
      data-cy={`perk-card-${perk.id}`}
      data-testid="perk-card"
      onClick={goToPerkPage}
      sx={{
        cursor: "pointer",
        "&:hover": { border: "1px solid", borderColor: theme.palette.primary.main },
        width: "100%",
        height: isProfessionalService(perk?.data.fields.type) ? "196px" : "260px",
        paddingBottom: 2,
      }}
    >
      <Stack
        sx={{
          height: "100%",
        }}
      >
        <CardContent sx={{ "&:last-child": { pb: 2 } }}>
          {!isProfessionalService(perk?.data.fields.type) && (
            <Stack spacing={2}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Stack direction="row" spacing={2}>
                  <Avatar
                    src={perk.data.enrichment?.logoUrl ? perk.data.enrichment?.logoUrl : DoitLogoWhite}
                    sx={{
                      height: "64px",
                      width: "64px",
                      alignSelf: "center",
                      border: `0.5px solid ${theme.palette.grey[300]}`,
                    }}
                    slotProps={{
                      img: { sx: { objectFit: "contain", bgcolor: "white" } },
                    }}
                  />
                  {!!perk.data.fields.title && (
                    <Typography variant="body1" sx={{ alignSelf: "center" }}>
                      {perk.data.fields.title}{" "}
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Stack>
          )}

          <Box
            sx={{
              mt: 1,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
              }}
            >
              {perk.data.fields.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginTop: 1,
                color: "text.secondary",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
              }}
            >
              {perk.data.fields.shortDescription}
            </Typography>
          </Box>
        </CardContent>
        <Stack
          direction="row"
          sx={{
            mt: "auto",
            mx: 2,
          }}
        >
          <Box
            sx={{
              minHeight: "30px",
              display: "flex",
              flexFlow: "initial",
              alignItems: "self-end",
            }}
          >
            <Typography variant="caption">
              <Stack direction="row" spacing={1}>
                {perk.data.fields.platform.map((platform) => (
                  <Box
                    key={platform}
                    sx={{ bgcolor: theme.palette.grey[100], borderRadius: 6 }}
                    data-cy={`platform-icon-${platform}`}
                  >
                    <img
                      src={marketplaceMap[platform]?.logo}
                      alt="logo"
                      height="20px"
                      width="20px"
                      style={{ margin: theme.spacing(0.25, 1, -0.5, 1) }}
                    />
                  </Box>
                ))}
              </Stack>
            </Typography>
          </Box>
          {!perk.data.fields.active && (
            <Box
              sx={{
                mt: 1,
                ml: "auto",
              }}
            >
              <Chip
                label="Draft"
                size="small"
                sx={{
                  color: theme.palette.common.white,
                  backgroundColor: "navigation.accent",
                }}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
