import { useEffect, useMemo, useState } from "react";

import sortBy from "lodash/sortBy";

import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Link,
  Typography,
  Tooltip,
  Stack,
} from "@mui/material";
import { getCollection } from "@doitintl/models-firestore";
import { IntegrationModel } from "@doitintl/cmp-models";
import WarningIcon from "@mui/icons-material/Warning";
import { yellow, orange } from "@mui/material/colors";
import filter from "lodash/filter";
import { formatNumber } from "../../utils/common";
import Hide from "../HideChildren/Hide";
import { useCustomerContext } from "../../Context/CustomerContext";
import { AWSFeatureName } from "../../Pages/Settings/AWS/types";
import { useCloudConnectContext } from "../../Context/customer/CloudCOnnectContext";
import { SkeletonCard } from "./SkeletonCard";
import { CARD_HEADER_HEIGHT } from "./Analytics/cloudCardStyle";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    borderBottom: "none",
  },
  tableRow: {
    height: 32,
  },
  statusBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  bar: {
    width: 7,
    height: 15,
    borderRadius: 3,
    backgroundColor: "#e0dfe0",
    marginLeft: 3,
  },
  barFill: {
    width: 7,
    height: 15,
    borderRadius: 3,
    backgroundColor: "red",
    marginLeft: 3,
  },
  serviceName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: 140,
    [theme.breakpoints.down("md")]: {
      maxWidth: 90,
    },
  },
}));
const titleText = {
  "amazon-web-service": {
    title: "Amazon Web Services Quotas",
    subTitle: "monitor your amazon web services service quotas and usage",
    link: "https://aws.amazon.com/support/createCase?type=service_limit_increase",
  },
  "google-cloud": {
    title: "Google Cloud Quotas",
    subTitle: "monitor your google cloud service quotas and usage",
    link: "https://console.cloud.google.com/iam-admin/quotas?project={{projectId}}&service=compute.googleapis.com&usage=USED",
  },
};

const StatusRating = ({ limit, usage, num, colorBar }) => {
  const classes = useStyles();
  const fillColor =
    parseInt((((usage * 100) / limit) * 5) / 100) >= num ? (colorBar === "Yellow" ? yellow[600] : colorBar) : "#e0dfe0";
  return <div style={{ backgroundColor: fillColor }} className={classes.bar} />;
};

export default function ServiceLimits({ raised, data, type, fallbackComponent, widgetHeight = 200 }) {
  const classes = useStyles();
  const { customer } = useCustomerContext();
  const [page, setPage] = useState(0);
  const rowsPerPage = 6;
  const [rows, setRows] = useState([]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const missingAccountInfo = useMemo(() => {
    if (type === "google-cloud") {
      return {
        message: "To use this feature, please connect your account/s using Google Cloud on the",
        link: "gcp",
      };
    } else if (type === "amazon-web-service") {
      return {
        message: "To enable this feature, please connect your AWS account/s and check the Quotas feature. Go to",
        link: "aws",
      };
    }
  }, [type]);

  useEffect(() => {
    if (type === "google-cloud") {
      return;
    }
    let services = [];
    if (!data?.data) {
      setRows([]);
      return;
    }
    const tmp = data.data.services;
    const arr = Object.entries(tmp);
    arr.forEach((account) => {
      const accountServices = Object.entries(account[1]);
      accountServices.forEach((item) => {
        const service = Object.entries(item[1]);
        service.forEach((subService) => {
          subService[1].forEach((sub) => {
            services.push({
              name: subService[0],
              data: sub,
              accountId: account[0],
            });
          });
        });
      });
    });

    services = sortBy(services, [(o) => (o.data.usage * 100) / o.data.limit]);
    setRows(services.reverse().slice(0, 6));
  }, [data, type]);

  useEffect(() => {
    if (type === "amazon-web-service" || !data?.data) {
      return;
    }
    const services = [];

    if (data.data.services && typeof data.data.services === "object") {
      Object.keys(data.data.services).forEach((orgId) => {
        if (data.data.services[orgId]) {
          data.data.services[orgId].forEach((service) => {
            services.push({
              name: service.service.replace(/_/g, " ").toLowerCase(),
              data: service,
            });
          });
        }
      });
    }

    setRows(services);
  }, [data, type]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  if (data === null) {
    return fallbackComponent;
  }

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Card raised={raised}>
      <WidgetCardHeader title={titleText[type].title} subheader={titleText[type].subTitle} />
      <CardContent sx={{ height: widgetHeight }}>
        {data.isServiceAccountExist === false ? (
          <Stack
            sx={{
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <WarningIcon style={{ color: orange[400] }} fontSize="large" />
            <Typography variant="subtitle1" color="textPrimary">
              No Access
            </Typography>
            <Typography style={{ maxWidth: "85%", textAlign: "center" }} variant="body2" color="textSecondary">
              {missingAccountInfo.message}{" "}
              <Link href={`/customers/${customer.id}/settings/${missingAccountInfo.link}`}>Settings page</Link>
            </Typography>
          </Stack>
        ) : rows.length === 0 ? (
          <Stack
            sx={{
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Typography style={{ textAlign: "center" }} color="textSecondary" variant="subtitle1">
              There is no usage over 50% of the quotas, well done
            </Typography>
          </Stack>
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell padding="none" size="small">
                  Service
                </TableCell>
                <TableCell padding="none" size="small">
                  Scope
                </TableCell>
                <TableCell padding="none" size="small">
                  Usage
                </TableCell>
                <TableCell padding="none" align="center" size="small">
                  Limit
                </TableCell>
                <Hide mdDown>
                  <TableCell padding="none" align="right" size="small" />
                </Hide>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                <Tooltip
                  key={row.name + i}
                  arrow
                  placement="right"
                  title={type === "google-cloud" ? row.data.projectName : row.accountId}
                >
                  <TableRow hover className={classes.tableRow}>
                    <TableCell className={classes.serviceName} padding="none" align="left" size="small">
                      {row.name}
                    </TableCell>

                    <TableCell padding="none" align="left" size="small">
                      {row.data.region}
                    </TableCell>
                    <TableCell padding="none" size="small">
                      <div className={classes.statusBox}>
                        {[1, 2, 3, 4, 5].map((i) => (
                          <StatusRating
                            limit={row.data.limit}
                            usage={row.data.usage}
                            num={i}
                            key={i}
                            colorBar={row.data.status}
                          />
                        ))}
                        &nbsp;&nbsp;{formatNumber(row.data.usage, 1)}
                      </div>
                    </TableCell>

                    <TableCell padding="none" align="center" size="small">
                      {formatNumber(row.data.limit, 1)}
                    </TableCell>
                    <Hide mdDown>
                      <TableCell padding="none" align="right" size="small">
                        <Link
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(titleText[type].link.replace("{{projectId}}", row.data.projectId), "_blank")
                          }
                        >
                          Modify
                        </Link>
                      </TableCell>
                    </Hide>
                  </TableRow>
                </Tooltip>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 32 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.tablePagination}
                  rowsPerPageOptions={[rowsPerPage]}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                    size: "small",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                    size: "small",
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}

export const ServiceLimitsGCP = ({ raised, fallbackComponent, widgetHeight }) => {
  const [data, setData] = useState();
  const { customer } = useCustomerContext();
  const cloudConnect = useCloudConnectContext();
  const [serviceAccountExist, setServiceAccountExist] = useState();

  useEffect(() => {
    const gcpServices = filter(cloudConnect, { cloudPlatform: "google-cloud" });
    if (!gcpServices) {
      setServiceAccountExist(false);
      return;
    }
    setServiceAccountExist(true);
  }, [cloudConnect]);

  useEffect(() => {
    if (serviceAccountExist === false) {
      setData(null);
      return;
    }

    if (!serviceAccountExist) {
      return;
    }

    return getCollection(IntegrationModel)
      .doc("google-cloud")
      .collection("service-limits")
      .doc(customer.id)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          setData(null);
          return;
        }

        const limitsData = {
          data: querySnapshot.data(),
          id: querySnapshot.id,
        };
        setData(limitsData);
      });
  }, [customer.id, serviceAccountExist]);

  return (
    <ServiceLimits
      data={data}
      widgetHeight={widgetHeight}
      raised={raised}
      type="google-cloud"
      fallbackComponent={fallbackComponent}
    />
  );
};

export const ServiceLimitsAWS = ({ raised, fallbackComponent, widgetHeight }) => {
  const [data, setData] = useState();
  const { customer } = useCustomerContext();
  const cloudConnect = useCloudConnectContext();
  const [serviceAccountExist, setServiceAccountExist] = useState();

  useEffect(() => {
    const awsServices = filter(cloudConnect, { cloudPlatform: "amazon-web-services" });
    if (!awsServices) {
      setServiceAccountExist(false);
      return;
    }

    const accountsWithoutQuotasFeature = awsServices.reduce((acc, awsService) => {
      const supportedFeatures = awsService.supportedFeatures;

      if (
        supportedFeatures.findIndex(
          (feature) => feature.name === AWSFeatureName.quotas && feature.hasRequiredPermissions
        ) === -1
      ) {
        acc++;
      }

      return acc;
    }, 0);

    setServiceAccountExist(accountsWithoutQuotasFeature < awsServices.length);
  }, [cloudConnect]);

  useEffect(() => {
    if (serviceAccountExist === false) {
      setData(null);
      return;
    }

    if (!serviceAccountExist) {
      return;
    }

    return getCollection(IntegrationModel)
      .doc("amazon-web-services")
      .collection("service-limits")
      .doc(customer.id)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          setData(null);
          return;
        }

        const limitsData = {
          data: querySnapshot.data(),
          id: querySnapshot.id,
        };
        setData(limitsData);
      });
  }, [customer.id, serviceAccountExist]);

  return (
    <ServiceLimits
      data={data}
      widgetHeight={widgetHeight}
      raised={raised}
      type="amazon-web-service"
      fallbackComponent={fallbackComponent}
    />
  );
};
