import { Stack, Typography } from "@mui/material";

export const ThreadsOverviewNoFilterResults = () => (
  <Stack
    spacing={2}
    sx={{
      alignItems: "center",
      my: "70px",
    }}
  >
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "32px",
      }}
    >
      No threads found
    </Typography>
    <Typography
      sx={{
        fontSize: "16px",
        lineHeight: "24px",
      }}
    >
      Your filter(s) did not return any results. Please try changing the filter criteria.
    </Typography>
  </Stack>
);
