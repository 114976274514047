import { type EntityModel } from "@doitintl/cmp-models";
import { type ModelIdRef } from "@doitintl/models-firestore";
import { type AutocompleteRenderInputParams, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { type TextFieldProps } from "@mui/material/TextField/TextField";

type Value = ModelIdRef<EntityModel>;

type EntitySelectProps = {
  value: Value | undefined | null;
  loading?: boolean;
  options: Value[];
  setFieldValue: (field: "entity", value: Value | null) => void;

  TextFieldProps?: TextFieldProps;
};

const EntitySelect = ({ value, setFieldValue, options, loading, TextFieldProps }: EntitySelectProps) => (
  <Autocomplete<Value>
    getOptionLabel={(entity) => `${entity.priorityId} - ${entity.name}`}
    options={options}
    loading={loading}
    value={value}
    onChange={(_event, newValue) => {
      setFieldValue("entity", newValue);
    }}
    renderInput={(params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        name="entity"
        label="Billing Profile"
        variant="outlined"
        margin="dense"
        {...TextFieldProps}
        slotProps={{
          input: {
            ...params.InputProps,
            ...TextFieldProps?.slotProps?.input,
            "data-cy": "entity-select",
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          } as any,

          inputLabel: { shrink: true },
        }}
      />
    )}
  />
);

export default EntitySelect;
