import { type JSX } from "react";

import { Stack } from "@mui/material";
import { Box } from "@mui/system";

type Props = {
  avatar: JSX.Element;
  children: JSX.Element;
};

export const ContentWithAvatar = ({ avatar, children }: Props) => (
  <Stack direction="row" spacing={1.5}>
    <Box
      sx={{
        pt: 0.8,
      }}
    >
      {avatar}
    </Box>
    {children}
  </Stack>
);
