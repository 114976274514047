import { useMemo } from "react";

import { type DataConnectorSnowflakeModel } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../../../Components/FilterTable/FilterTableSkeleton";
import { filters, headerColumns } from "./SnowflakeAssetColumns";
import SnowflakeAssetRow, { type SnowflakeAssetRowData } from "./SnowflakeAssetRow";

const SnowflakeAssetTable = ({ configs }: { configs: ReadonlyArray<DataConnectorSnowflakeModel> }) => {
  const data = useMemo<SnowflakeAssetRowData[]>(() => {
    const data: SnowflakeAssetRowData[] = [];

    if (!configs || configs.length === 0) {
      return data;
    }

    configs.forEach((config) => {
      if (!config) {
        return;
      }
      data.push({
        accountId: config.accountId,
        orgName: config.orgId,
        status: config.status === "Active" || config.status === "active" ? "Active" : "Inactive",
      } as SnowflakeAssetRowData);
    });

    return data;
  }, [configs]);

  const rowWrapper = ({ row }: { row: SnowflakeAssetRowData }) => <SnowflakeAssetRow data={row} />;

  return (
    <Box
      sx={{
        mb: 4,
      }}
    >
      {configs === undefined ? (
        <FilterTableSkeleton />
      ) : (
        <FilterTable<SnowflakeAssetRowData>
          tableItems={data}
          rowComponent={rowWrapper}
          headerColumns={headerColumns}
          filterColumns={filters}
          persistenceKey="SnowflakeAssetTable"
          data-cy="SnowflakeAssetTable"
        />
      )}
    </Box>
  );
};

export default SnowflakeAssetTable;
