import { FlexsaveTypes, type MonthSavings } from "@doitintl/cmp-models";
import { Box, Card, Stack } from "@mui/material";

import { type FlexsaveData } from "../../../types";
import { getMonthLabelFromDate } from "../../../utils/dateUtils";
import { SavingsChart } from "../Chart/SavingsChart";
import { CardTextPlaceholder } from "./CardTextPlaceholder";
import { SavingCardsPlaceholder } from "./SavingCardsPlaceholder";

const generateHistory = () => {
  const history = new Map<string, MonthSavings>();
  const currentDate = new Date("11/11/2022");
  for (let i = 0; i < 8; i++) {
    let currentData;
    if (i === 4) {
      currentData = {
        totalOnDemandSpend: 7000,
        onDemandSpend: 4500,
        savings: 2500,
      };
    } else {
      const onDemandSpend = (Math.random() / 2 + 0.5) * 10000;
      const savings = (Math.random() / 2 + 0.5) * onDemandSpend;
      currentData = {
        totalOnDemandSpend: onDemandSpend + savings,
        onDemandSpend,
        savings,
      };
    }
    history.set(getMonthLabelFromDate(currentDate), currentData);
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  return history;
};

const mockData: FlexsaveData = {
  enabled: {
    months: ["April 2022", "May 2022", "June 2022", "July 2022", "August 2022", "September 2022", "October 2022"],
    currentMonthId: "10_2022",
    savings: [
      {
        type: FlexsaveTypes.GCP,
        nextMonth: {
          savings: 2638.658959713773,
          onDemandSpend: 6253.372877574694,
          savingsRate: 0.29674420964718506,
        },
        currentMonth: {
          savingsRate: 0.2838338924795338,
          onDemandSpend: 5394.273951218519,
          savings: 2137.880802508589,
        },
        history: generateHistory(),
      },
    ],
  },
  potentialFlexSaveSavings: undefined,
  info: undefined,
};

export const OverviewChartsPlaceholder = () => (
  <Stack
    spacing={2}
    sx={{
      pt: 1,
    }}
  >
    <SavingCardsPlaceholder />
    <Card sx={{ position: "relative" }}>
      <Box
        sx={{
          pointerEvents: "none",
          filter: "blur(12px)",
          width: "100%",
          userSelect: "none",
        }}
      >
        <SavingsChart flexsaveData={mockData} />
      </Box>
      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <CardTextPlaceholder title="Flexsave summary" />
      </Box>
    </Card>
  </Stack>
);
