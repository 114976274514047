import { FlexsaveTypes } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import DisableCloud from "../Resold/Overview/DisableFlexsave";
import { useCanDisable } from "./hooks";

export default function DisableFlexsave() {
  const canDisable = useCanDisable(FlexsaveTypes.AWS);

  if (!canDisable) {
    return null;
  }

  return (
    <Box
      sx={{
        mb: 6,
        mt: 4,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <DisableCloud type={FlexsaveTypes.AWS} />
    </Box>
  );
}
