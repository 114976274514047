import { Box, Chip, LinearProgress, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { ThemeModes } from "../../muiThemeTypes";
import { TrendType } from "../../types";
import TrendIcon from "./TrendIcon";
import { TestId } from "./types";

const getColor = (type: TrendType, level: number, isDarkMode?: boolean) => {
  if (isDarkMode) {
    return type === TrendType.Up ? "#123a1769" : "#4a252569";
  }
  return type === TrendType.Up ? green[level] : red[level];
};

const useAlign = {
  alignItems: "center",
};

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  clickable: {
    "&:hover": {
      background: (props: { type: TrendType }) =>
        getColor(props.type, theme.palette.mode === ThemeModes.DARK ? 900 : 100, false),
    },
    "&:focus": {
      background: (props: { type: TrendType }) =>
        getColor(props.type, theme.palette.mode === ThemeModes.DARK ? 900 : 100, false),
    },
  },
  chip: {
    background: (props: { type: TrendType }) => getColor(props.type, 50, theme.palette.mode === ThemeModes.DARK),
  },
  base: {
    width: "100%",
    maxWidth: 80,
    borderRadius: 4,
    paddingLeft: 2,
    paddingRight: 2,
  },
  loader: {
    position: "relative",
    maxWidth: 76,
    height: 2,
    marginTop: -2,
    marginLeft: 2,
  },
}));

type Props = {
  type: TrendType;
  sign?: string;
  caption?: string;
  amount: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const TrendChip = ({ type, caption, amount, sign, loading, disabled, onClick }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ type });

  return (
    <>
      <Chip
        classes={{ label: classes.label, clickable: classes.clickable }}
        disabled={disabled}
        className={clsx({
          [classes.chip]: !disabled,
          [classes.base]: true,
        })}
        onClick={!disabled ? onClick : undefined}
        label={
          <Box
            sx={[
              {
                color: theme.palette.text.secondary,
                display: "flex",
              },
              ...(Array.isArray(useAlign) ? useAlign : [useAlign]),
            ]}
          >
            {!disabled && (
              <Box
                data-testid={TestId.TrendIcon}
                sx={[
                  {
                    pr: 0.1,
                    display: "flex",
                  },
                  ...(Array.isArray(useAlign) ? useAlign : [useAlign]),
                ]}
              >
                <TrendIcon type={type} />
              </Box>
            )}

            <Box>
              <Typography
                style={{ color: theme.palette.mode === ThemeModes.DARK ? "white" : getColor(type, 800) }}
                variant="button"
                data-testid={TestId.Amount}
              >
                {amount}
                {sign}
              </Typography>
            </Box>
            <Typography variant="body2" data-testid={TestId.Caption}>
              {caption}
            </Typography>
          </Box>
        }
      />
      {loading && <LinearProgress className={classes.loader} />}
    </>
  );
};

export default TrendChip;
