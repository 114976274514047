import { useCallback, useMemo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import capitalize from "lodash/capitalize";
import * as yup from "yup";

import { useApiContext } from "../../../api/context";
import { globalText } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import EntitySelect from "../../../Components/Selects/EntitySelect";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { type PayerAccountOption } from "../types";
import { discernError, FormField, helperText } from "./utils";

function onKeyDown(event) {
  if (event.key === "Enter") {
    event.preventDefault();
  }
}

type Props = {
  entities: Entity[];
  onClose: () => void;
  type: string | false;
  payerAccountOptions: PayerAccountOption[];
};

type Values = {
  entity: string;
  payerAccountId: string | null;
  target: string;
  notes: string;
};

const amazonAccountIdPattern = /^\d{12}$/;

const schema = yup.object().shape({
  entity: yup.string().required().label("Billing profile"),
  target: yup.string().required().label("AWS account ID").matches(amazonAccountIdPattern, {
    message: "Invalid Amazon Web Services Account ID",
    excludeEmptyString: true,
  }),
  payerAccountId: yup.string().nullable().required().label("Payer account"),
  notes: yup.string().max(280),
});

const InviteAmazonWebServicesAsset = ({ onClose, type, entities, payerAccountOptions }: Props) => {
  const api = useApiContext();
  const { isDoitEmployee } = useAuthContext();
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const { isMobile: matches } = useFullScreen("sm");
  const { customer } = useCustomerContext();
  const disabledOptions = useMemo<string[]>(
    () => payerAccountOptions.filter((opt) => opt.disabled).map((opt) => opt.value),
    [payerAccountOptions]
  );

  const optionDisabled = useCallback((option) => disabledOptions.includes(option), [disabledOptions]);

  const transformPayerAccountOptions = useMemo(
    () => payerAccountOptions.map((p: PayerAccountOption) => p.value),
    [payerAccountOptions]
  );

  if (!customer) {
    return null;
  }

  return (
    <Formik<Values>
      initialValues={{
        entity: entities.length > 0 ? entities[0].id : "",
        payerAccountId: isDoitEmployee || payerAccountOptions.length === 1 ? payerAccountOptions[0].value : null,
        target: "",
        notes: "",
      }}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={schema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        try {
          await api.request({
            method: "post",
            url: `/v1/customers/${customer.id}/entities/${values.entity}/amazon-web-services/${type}`,
            data: {
              target: values.target,
              payerAccountId: values.payerAccountId,
              notes: values.notes,
            },
          });

          mixpanel.track("assets.amazon-web-services.account.invite", {
            target: values.target,
            payerAccountId: values.payerAccountId,
            notes: values.notes,
          });

          resetForm();
          onClose();
        } catch (error: any) {
          consoleErrorWithSentry(error);
          if (error.response) {
            showSnackbar({
              message: error.response.data?.message || "Invite account operation failed, try again later",
              variant: "error",
              autoHideDuration: 5000,
              action: [
                <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
                  <CloseIcon />
                </IconButton>,
              ],
            });
          }
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid, values, setFieldValue, handleChange, handleBlur, errors, touched, handleSubmit }) => (
        <Dialog
          open={true}
          aria-labelledby="amazon-web-services-invite-form"
          onClose={preventOnCloseWhile(isSubmitting, onClose)}
          fullScreen={matches}
          fullWidth
          maxWidth="sm"
          data-cy="inviteAccountDialog"
        >
          <form onKeyDown={onKeyDown} onSubmit={handleSubmit}>
            <DialogTitle id="amazon-web-services-invite-form" data-cy="dialogTitle">
              Invite existing account
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  gap: 2,
                  mt: 1,
                }}
              >
                {entities.length !== 1 && (
                  <EntitySelect
                    name="entity"
                    value={values.entity}
                    onChange={({ target: { value, name } }) => setFieldValue(name, value)}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    helperText={helperText(touched, errors, FormField.ENTITY)}
                    error={discernError(touched, errors, FormField.ENTITY)}
                    fullWidth
                    data-cy="entitySelect"
                  />
                )}
                <Autocomplete
                  id="payerAccountSelection"
                  options={transformPayerAccountOptions}
                  getOptionLabel={(option) => payerAccountOptions.find((item) => item.value === option)?.label ?? ""}
                  getOptionDisabled={optionDisabled}
                  value={values.payerAccountId !== undefined ? values.payerAccountId : null}
                  onChange={(event, value) => setFieldValue("payerAccountId", value)}
                  onBlur={handleBlur}
                  disabled={isSubmitting || payerAccountOptions.length === 1}
                  data-cy="payerAccountAutocomplete"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="payerAccountId"
                      label="Payer Account"
                      variant="outlined"
                      helperText={helperText(touched, errors, FormField.PAYER_ACCOUNT)}
                      error={discernError(touched, errors, FormField.PAYER_ACCOUNT)}
                      fullWidth
                      data-cy="payerAccountIdInput"
                      slotProps={{
                        inputLabel: { shrink: true },
                      }}
                    />
                  )}
                />
                <TextField
                  name="target"
                  label="AWS Account ID"
                  variant="outlined"
                  disabled={isSubmitting}
                  helperText={helperText(touched, errors, FormField.TARGET)}
                  error={discernError(touched, errors, FormField.TARGET)}
                  value={values.target.trim()}
                  onChange={(e) => {
                    e.target.value = e.target.value.trim();
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  data-cy="accountIdInput"
                  slotProps={{
                    inputLabel: { shrink: true },
                  }}
                />
                <TextField
                  name="notes"
                  label="Notes"
                  variant="outlined"
                  placeholder="Optional"
                  disabled={isSubmitting}
                  helperText={helperText(touched, errors, FormField.NOTES)}
                  error={discernError(touched, errors, FormField.NOTES)}
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  data-cy="notesInput"
                  slotProps={{
                    input: { inputProps: { maxLength: 280 } },
                    inputLabel: { shrink: true },
                  }}
                />
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button variant="text" onClick={onClose} disabled={isSubmitting} data-cy="cancelBtn">
                {globalText.CANCEL}
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
                data-cy="inviteBtn"
                mixpanelEventId={`assets.invite-aws-asset.${capitalize(`${type}`)}`}
              >
                {capitalize(`${type}`)}
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default InviteAmazonWebServicesAsset;
