import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const PermissionText = ({ text }: { text: string }) => (
  <Typography
    sx={{
      fontFamily: "monospace",
      pl: 2,
      color: "text.secondary",
    }}
  >
    • {text}
  </Typography>
);

export const RequiredPermissionsDialog = ({ open, handleClose }: Props) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Required permissions</DialogTitle>
    <DialogContent>
      <Stack spacing={2}>
        <Typography>
          • IAM permissions that allows you to create a policy, a role, and attach that policy to a role:
        </Typography>
        <Stack spacing={1}>
          <PermissionText text="iam:CreatePolicy" />
          <PermissionText text="iam:CreateRole" />
          <PermissionText text="iam:AttachRolePolicy" />
          <PermissionText text="iam:GetRole" />
        </Stack>
        <Typography>
          • CloudFormation permissions for creating stack and seeing parameters DoiT is setting up to enable usage of
          the DoiT Console:
        </Typography>
        <Stack spacing={1}>
          <PermissionText text="cloudformation:CreateStack" />
          <PermissionText text="cloudformation:GetTemplateSummary" />
        </Stack>
        <Typography>• SNS permission to enable a callback callback when stack is complete:</Typography>
        <PermissionText text="sns:Publish" />
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={handleClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
