import { Typography } from "@mui/material";

type props = {
  title: string;
};

export const ContentHeader = ({ title }: props) => (
  <Typography
    variant="h4"
    sx={{
      color: "text.primary",
      mb: 2,
      mt: 3,
    }}
  >
    {title}
  </Typography>
);
