import { useCallback } from "react";

import DownloadIcon from "@mui/icons-material/GetApp";
import { Alert, Box, IconButton, Stack, useTheme } from "@mui/material";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { CopyToClipboardButton } from "../../../../Components/CopyToClipboardButton";
import { text } from "../../consts";

export const GeneratedTokenBlock = ({ generatedToken, email }: { generatedToken: string; email: string }) => {
  const theme = useTheme();

  const downloadTokenAsJson = useCallback(() => {
    const fileName = `${email}-cmp-token`;
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(generatedToken))}`;
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `${fileName}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }, [generatedToken, email]);

  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          bgcolor: theme.palette.mode === "dark" ? cmpBaseColors.backgroundDark : "rgba(0, 0, 0, 0.04)",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            flexBasis: "48px",
          }}
        >
          <Box
            sx={{
              ml: "12px",
              mt: "5px",
            }}
          >
            <CopyToClipboardButton text={generatedToken} />
          </Box>
          <Box
            sx={{
              ml: "5px",
            }}
          >
            <IconButton onClick={downloadTokenAsJson} size="medium" data-cy="download-token">
              <DownloadIcon />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            py: 1.5,
            pr: 1.5,
            fontFamily: "monospace",
            overflowWrap: "anywhere",
            fontSize: "14px",
            lineSize: "20px",
          }}
        >
          {generatedToken}
        </Box>
      </Stack>
      <Alert sx={{ mt: 2 }} severity="warning">
        {text.API_TAB_GENERATED_KEY_WARNING}
      </Alert>
    </>
  );
};
