import { useMemo } from "react";

import { SandboxStatus } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LinearProgress, Link, TableCell, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { tooltipClasses, type TooltipProps } from "@mui/material/Tooltip";
import { Stack } from "@mui/system";
import clsx from "clsx";
import startCase from "lodash/startCase";
import { DateTime } from "luxon";

import { cmpBaseColors } from "../../cmpBaseColors";
import Hide from "../../Components/HideChildren/Hide";
import { formatCurrency, formatDecimalNumber } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import { type SandboxRowData, type SandboxTableRow } from "./types";
import useStyles from "./useStyles";

const CustomPopperTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: cmpBaseColors.darkContrast,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: cmpBaseColors.darkContrast,
  },
}));
const SandboxRow = ({
  projectId,
  amount,
  interval,
  action,
  progressVal,
  spend,
  createdAt,
  email,
  status,
}: SandboxRowData) => {
  const { isMobile: smDown } = useFullScreen();
  const classes = useStyles({ smDown });

  const tooltipTitle = useMemo(
    () =>
      amount
        ? `${formatCurrency(spend, "USD", 2)} / ${formatCurrency(
            amount,
            "USD",
            2
          )} (${formatDecimalNumber(progressVal, 0)}%)`
        : "",
    [spend, amount, progressVal]
  );

  const created = useMemo(
    () => DateTime.fromJSDate(createdAt.toDate()).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
    [createdAt]
  );

  const getCurrentStatus = (status: SandboxStatus) => {
    switch (status) {
      case SandboxStatus.ACTIVE:
      case SandboxStatus.DELETED:
        return startCase(status);
      case SandboxStatus.DISABLED:
        return (
          <Stack
            direction="row"
            spacing={0.4}
            sx={{
              alignItems: "center",
            }}
          >
            <span>{startCase(status)}</span>
            <CustomPopperTooltip
              title="CMP disconnected the project from billing when the maximum spend was reached."
              placement="right"
              arrow
            >
              <InfoOutlinedIcon sx={{ height: "13.33px", width: "13.33px" }} htmlColor={grey[600]} fontSize="small" />
            </CustomPopperTooltip>
          </Stack>
        );
    }
  };

  const linearProgressClasses = useMemo(
    () => ({
      root: clsx(classes.linearProgressRoot, {
        [classes.greyProgressRoot]: progressVal <= 0,
        [classes.greenProgressRoot]: progressVal > 0 && progressVal < 50,
        [classes.amberProgressRoot]: progressVal >= 50 && progressVal < 100,
        [classes.redProgressRoot]: progressVal >= 100,
      }),
      bar: clsx(classes.linearProgressBar, {
        [classes.greyProgressBar]: progressVal <= 0,
        [classes.greenProgressBar]: progressVal > 0 && progressVal < 50,
        [classes.amberProgressBar]: progressVal >= 50 && progressVal < 100,
        [classes.redProgressBar]: progressVal >= 100,
      }),
    }),
    [
      progressVal,
      classes.amberProgressBar,
      classes.amberProgressRoot,
      classes.greenProgressBar,
      classes.greenProgressRoot,
      classes.greyProgressBar,
      classes.greyProgressRoot,
      classes.linearProgressBar,
      classes.linearProgressRoot,
      classes.redProgressBar,
      classes.redProgressRoot,
    ]
  );

  return (
    <>
      <TableCell component="th" scope="row">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`https://console.cloud.google.com/home/dashboard?project=${projectId}`}
          color="inherit"
          underline="always"
        >
          {projectId}
        </Link>
      </TableCell>
      <Hide mdDown>
        <TableCell>{email}</TableCell>
        <TableCell data-cy={`sandbox-status-${projectId}`}>{getCurrentStatus(status)}</TableCell>
        <TableCell>{created}</TableCell>
        <TableCell>{startCase(interval)}</TableCell>
        <TableCell>{startCase(action)}</TableCell>
      </Hide>
      <TableCell align="right">{formatCurrency(spend, "USD", 2)}</TableCell>
      <Hide mdDown>
        <TableCell className={classes.progressCell}>
          <Tooltip arrow title={tooltipTitle}>
            <LinearProgress variant="determinate" value={Math.min(progressVal, 100)} classes={linearProgressClasses} />
          </Tooltip>
        </TableCell>
      </Hide>
    </>
  );
};

export const SandboxRowWrapper = ({ row }: { row: SandboxTableRow }) => {
  const { _policy, spend } = row;

  const progressVal = _policy?.data.amount ? Math.max(0, 100 * (spend / _policy?.data.amount)) : 0;
  const { amount, action, interval } = _policy?.data || {};
  const data: SandboxRowData = {
    ...row.data,
    amount: startCase(`${amount}`),
    action: startCase(action),
    interval,
    spend,
    progressVal,
  };
  return <SandboxRow {...data} />;
};

export default SandboxRow;
