import { type ReactElement, useEffect, useMemo, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { SuperQueryModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Alert, Box, Card, Link as MUILink } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { useCloudConnect } from "../../hooks/useCloudConnect";
import { Loader } from "../Loader";
import { BillingModeChanger } from "./BillingModeChanger";
import BQInactiveStateDashboard from "./BQInactiveStateDashboard/BQInactiveStateDashboard";
import MissingPermissionsBanner from "./MissingPermissions";
import { TimeRangeChanger } from "./TimeRangeChanger";
import { alertActionButton, dataScanMsg, getServiceAccountsBQFinOps, processAfterScanMsg } from "./utils";

const useStyles = makeStyles((theme) => ({
  alertIcon: {
    alignItems: "center",
  },
  message: {
    padding: 2,
    display: "flex",
    alignItems: "center",
  },
  action: {
    alignItems: "center",
    padding: 2,
    color: "inherit",
    borderRadius: 4,
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light"
          ? alpha(theme.palette.warning.light, 0.15)
          : alpha(theme.palette.warning.dark, 0.2),
    },
  },
}));

type Props = {
  hideWidgetsDisplay: () => void;
  showWidgetsDisplay: () => void;
};

export const BQAlertText = ({ customerId }) => (
  <Box
    sx={{
      p: 2,
    }}
  >
    BigQuery Lens identifies opportunities to optimize your BigQuery usage. Visit our{" "}
    <MUILink to={`/customers/${customerId}/insights`} component={Link} underline="hover">
      Insights
    </MUILink>{" "}
    page for more recommendations on improving performance and reducing costs.
  </Box>
);

export default function BigQueryLensAlert({ hideWidgetsDisplay, showWidgetsDisplay }: Props) {
  const classes = useStyles();
  const { customerOrPresentationModeCustomer: customer, customer: originalCustomer } = useCustomerContext();
  const [cloudConnect, loadingCloudConnect] = useCloudConnect(customer);
  const [scanningProgress, setScanningProgress] = useState(0);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [backfillDone, setBackfillDone] = useState<boolean | null>(null);
  const [optimisationLoading, setOptimisationLoading] = useState(true);
  const [backfillLoading, setBackfillLoading] = useState(true);
  const [errorDisplayMessage, setErrorDisplayMessage] = useState<string | null>(null);
  const [alertActionItem, setAlertActionItem] = useState<ReactElement>(<></>);
  const [alertText, setAlertText] = useState<ReactElement | string>("");
  const [showEmptyStateDashboard, setShowEmptyStateDashboard] = useState(false);

  const backfillListener = useRef<any>(null);
  const optimisationListener = useRef<any>(null);

  const serviceAccountsWithBigQueryFinops = useMemo(() => getServiceAccountsBQFinOps(cloudConnect), [cloudConnect]);

  const isInPresentationMode =
    isCustomerInPresentationMode(originalCustomer) || originalCustomer.presentationMode?.isPredefined;

  /** Alert stages of BQ data processing:
   * stage 1: BQ not yet connected
   * stage 2: scanning historical usage data
   * stage 3: processing scanned data
   * stage 4: processing finished
   */

  useEffect(() => {
    const getAlertByStage = (stage: number) => {
      switch (stage) {
        case 1: // BQ not yet connected
          setShowEmptyStateDashboard(true);
          hideWidgetsDisplay();
          break;
        case 2: // scanning historical usage
          setAlertText(dataScanMsg(scanningProgress));
          setAlertActionItem(
            alertActionButton(`/customers/${customer.id}/isv-solutions`, "EXPLORE TRAINING ISV SOLUTIONS")
          );
          break;
        case 3: // processing scanned data
          // print the error message first, if any
          setAlertText(errorDisplayMessage ?? processAfterScanMsg(Math.round(processingProgress)));
          break;
        case 4: // processing finished
          setAlertText(BQAlertText({ customerId: customer.id }));
          showWidgetsDisplay();
          setShowEmptyStateDashboard(false);
          break;
        default:
          setAlertText("");
          setAlertActionItem(<></>);
      }
    };

    if (isInPresentationMode) {
      getAlertByStage(4);
    } else if (serviceAccountsWithBigQueryFinops && serviceAccountsWithBigQueryFinops?.length > 0) {
      if (processingProgress >= 100) {
        // stage 4: processing finished
        getAlertByStage(4);
      } else {
        if (backfillDone === false) {
          // stage 2: scanning historical usage data
          getAlertByStage(2);
        } else {
          // stage 3: processing scanned data
          getAlertByStage(3);
        }
      }
    } else {
      // stage 1: BQ not yet connected
      getAlertByStage(1);
    }
  }, [
    backfillDone,
    customer.id,
    processingProgress,
    errorDisplayMessage,
    hideWidgetsDisplay,
    scanningProgress,
    serviceAccountsWithBigQueryFinops,
    cloudConnect,
    showWidgetsDisplay,
    isInPresentationMode,
  ]);

  useEffect(() => {
    if (loadingCloudConnect) {
      return;
    }

    if (serviceAccountsWithBigQueryFinops?.[0]?.clientId) {
      backfillListener.current = getCollection(SuperQueryModel)
        .doc("jobs-sinks")
        .collection("jobsSinksMetadata")
        .doc(`google-cloud-${serviceAccountsWithBigQueryFinops?.[0]?.clientId}`)
        .onSnapshot((snap) => {
          setBackfillLoading(false);

          if (!snap.exists()) {
            return;
          }

          const data = snap.data();

          const progress = data?.backfillProgress ?? 0;
          if (progress > 0) {
            setScanningProgress(progress);
            setBackfillDone(data?.backfillDone ?? null);
          }
        });

      optimisationListener.current = getCollection(SuperQueryModel)
        .doc("simulation-optimisation")
        .collection("output")
        .doc(customer.id)
        .onSnapshot((snap) => {
          setOptimisationLoading(false);

          if (!snap.exists()) {
            return;
          }

          const data = snap.data();

          if (data?.displayMessage) {
            setErrorDisplayMessage(data.displayMessage);
          }

          const progress = data?.progress ?? 0;
          if (progress > 0) {
            setProcessingProgress(progress);
          }
        });
    } else {
      setOptimisationLoading(false);
      setBackfillLoading(false);
    }

    return () => {
      if (backfillListener.current) {
        backfillListener.current();
      }
      if (optimisationListener.current) {
        optimisationListener.current();
      }
    };
  }, [customer.id, loadingCloudConnect, serviceAccountsWithBigQueryFinops]);

  const filterBanner: ReactElement = (
    <Card>
      <Grid>
        <MissingPermissionsBanner />
      </Grid>
      <Grid container>
        <Grid
          style={{ alignItems: "center", display: "flex" }}
          size={{
            xs: 12,
            md: 8,
          }}
        >
          <BQAlertText customerId={customer.id} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <TimeRangeChanger />
            <BillingModeChanger />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );

  const bqAlert: ReactElement = (
    <>
      {showEmptyStateDashboard ? (
        <BQInactiveStateDashboard enticementState="activate" />
      ) : (
        <Alert
          classes={{
            icon: classes.alertIcon,
            message: !serviceAccountsWithBigQueryFinops ? classes.message : "",
            action: classes.action,
          }}
          severity="warning"
          action={alertActionItem}
        >
          <>{alertText}</>
        </Alert>
      )}
    </>
  );

  const alertOrFilterBanner: ReactElement =
    (processingProgress >= 100 && serviceAccountsWithBigQueryFinops) || isInPresentationMode ? filterBanner : bqAlert;

  return optimisationLoading || backfillLoading || loadingCloudConnect ? <Loader /> : alertOrFilterBanner;
}
