import { useEffect, useMemo } from "react";
import clsx from "clsx";
import { useCountUp } from "react-countup";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormGroup } from "@mui/material";
import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";

import TrendDownIcon from "@mui/icons-material/TrendingDownRounded";
import TrendUpIcon from "@mui/icons-material/TrendingUpRounded";

import { red, green, purple, cyan } from "@mui/material/colors";
import { assetTypeName, formatCurrency } from "../../../utils/common";
import AmazonWebServicesIcon from "../../../assets/amazon-web-services.png";
import GoogleCloudIcon from "../../../assets/google-cloud.png";
import GSuiteIcon from "../../../assets/g-suite.png";
import Office365Icon from "../../../assets/office-365.png";
import MicrosoftAzure from "../../../assets/microsoft-azure.png";
import FlexsaveStandalone from "../../../assets/flexsave-logo-small.png";
import lookerLogo from "../../../assets/looker-small.png";
import doitNavigatorLogo from "../../../assets/doit-navigator.svg";
import doitSolveLogo from "../../../assets/doit-solve.svg";
import doitSolveAcceleratorLogo from "../../../assets/doit-solve-accelerator.svg";
import doitLogo from "../../../assets/doit-logo-hero-square.svg";
import { ThemeModes } from "../../../muiThemeTypes";

const useStyles = makeStyles((theme) => ({
  cardContentDense: {
    padding: 8,
  },
  trendUp: {
    marginRight: 4,
    color: green[500],
  },
  trendDown: {
    marginRight: 4,
    color: red[500],
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  formControlLabel: {
    marginRight: 4,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
  },
  chipsContainer: {
    minHeight: 32,
  },
  chip: {
    height: 20,
    fontSize: theme.typography.pxToRem(12),
    margin: theme.spacing(0.25),
  },
  chipLabel: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
  },
  creditsChip: {
    backgroundColor: theme.palette.mode === ThemeModes.DARK ? purple[800] : purple[100],
  },
  adjustmentsChip: {
    backgroundColor: theme.palette.mode === ThemeModes.DARK ? cyan[800] : cyan[100],
  },
}));

const overrideTypes = ["amazon-web-services", "navigator", "solve", "solve-accelerator", "doit-cloud-intelligence"];

const RevenueCard = ({
  type,
  include,
  overrideActivated,
  onChange,
  onChangeOverride,
  currData,
  prevData,
  cogs,
  readyInvoices,
  disableInclude,
}) => {
  const classes = useStyles();
  const { countUp, update } = useCountUp({
    end: currData.total,
    separator: ",",
    prefix: "$",
  });
  const prettyType = assetTypeName(type);

  useEffect(() => {
    update(currData.total);
  }, [currData.total, update]);

  let diff = null;
  let trend = null;
  if (prevData) {
    diff = ((currData.total - prevData.total) / prevData.total) * 100;
    if (diff > 0) {
      trend = <TrendUpIcon className={classes.trendUp} />;
    } else {
      trend = <TrendDownIcon className={classes.trendDown} />;
    }
  }

  let avatar = null;
  let avatarVariant = "circular";
  switch (type) {
    case "google-cloud":
      avatar = GoogleCloudIcon;
      break;
    case "amazon-web-services":
      avatar = AmazonWebServicesIcon;
      break;
    case "g-suite":
      avatar = GSuiteIcon;
      break;
    case "office-365":
      avatar = Office365Icon;
      break;
    case "microsoft-azure":
      avatar = MicrosoftAzure;
      break;
    case "looker":
      avatar = lookerLogo;
      break;
    case "google-cloud-standalone":
      avatar = FlexsaveStandalone;
      break;
    case "amazon-web-services-standalone":
      avatar = FlexsaveStandalone;
      avatarVariant = "square";
      break;
    case "navigator":
      avatar = doitNavigatorLogo;
      break;
    case "solve":
      avatar = doitSolveLogo;
      break;
    case "solve-accelerator":
      avatar = doitSolveAcceleratorLogo;
      break;
    case "doit-cloud-intelligence":
      avatar = doitLogo;
      break;
    default:
  }

  const cogsSum = useMemo(() => cogs.reduce((memo, c) => memo + c.data.value, 0), [cogs]);
  const profit = useMemo(() => (100 * (currData.total - cogsSum)) / currData.total, [currData.total, cogsSum]);

  const chips = useMemo(() => {
    const result = [];
    if (currData.credits) {
      result.push({
        value: formatCurrency(currData.credits, "USD", 0),
        label: "Credits",
        type: "credits",
      });
    }
    if (currData.adjustments) {
      result.push({
        value: formatCurrency(currData.adjustments, "USD", 0),
        label: "Invoice Adjustments",
        type: "adjustments",
      });
    }
    return result;
  }, [currData.credits, currData.adjustments]);

  function OverrideLastUpdateTimestamp() {
    return (
      <Grid
        container
        sx={{
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={overrideActivated} onChange={onChangeOverride} defaultValue={false} />}
            label="Override Last Update"
          />
        </FormGroup>
      </Grid>
    );
  }

  return (
    <Card>
      <CardHeader
        avatar={<Avatar alt="Product Icon" src={avatar} variant={avatarVariant} />}
        title={`${prettyType} Revenue`}
        titleTypographyProps={{ variant: "h6", className: classes.title }}
        action={
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch checked={include} onChange={onChange} color="primary" size="small" disabled={disableInclude} />
            }
            label="Include"
          />
        }
      />
      <CardContent className={classes.cardContentDense}>
        <Grid container spacing={1}>
          <Grid
            container
            wrap="nowrap"
            size={12}
            sx={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Grid container />
            <Grid
              container
              direction="column"
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4">{countUp}</Typography>
              {/* <Typography variant="caption">{subtext}</Typography> */}
            </Grid>
            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {diff !== null && (
                <div className={classes.flex}>
                  {trend}
                  <Typography variant="body1">{`${Math.abs(Math.round(diff))}%`}</Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.chipsContainer}
            size={12}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {chips.map((chip) => (
              <Grid key={chip.label}>
                <Chip
                  size="small"
                  label={`${chip.label}: ${chip.value}`}
                  className={clsx(classes.chip, {
                    [classes.creditsChip]: chip.type === "credits",
                    [classes.adjustmentsChip]: chip.type === "adjustments",
                  })}
                  classes={{
                    label: classes.chipLabel,
                  }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            size={12}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {include && overrideTypes.includes(type) && <OverrideLastUpdateTimestamp />}
            <Grid>
              <Tooltip title={formatCurrency(cogsSum, "USD", 2)}>
                <Typography variant="body2">{`${Math.round(profit * 100) / 100}%`}</Typography>
              </Tooltip>
            </Grid>

            {type === "amazon-web-services" && (
              <>
                <Grid>
                  <Typography variant="body2" numberOfLines={1}>
                    Issued: {currData.numCustomersIssued ? currData.numCustomersIssued : 0}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body2">Ready: {readyInvoices}</Typography>
                </Grid>
              </>
            )}

            <Grid>
              <Typography variant="body2" align="center">
                Customers: {currData.numCustomers}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <div />
    </Card>
  );
};

export default RevenueCard;
