import { Link, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { NumberedList } from "../../../Flexsave/Common/NumberedList";
import { UsefulAzureLinks } from "./UsefulAzureLinks";
import { getTemplate } from "./utils";

type Props = {
  storageAccountName: string;
};

export default function StepTemplate({ storageAccountName }: Props) {
  return (
    <Stack
      spacing={4}
      data-cy="template-step"
      sx={{
        pb: 5,
      }}
    >
      <Stack spacing={4}>
        <Typography variant="h1">Deploy a custom template</Typography>
        <NumberedList
          list={[
            <Typography
              key="0"
              sx={{
                mb: 2,
              }}
            >
              Sign in to the{" "}
              <Link href="https://portal.azure.com" target="_blank">
                Azure Portal
              </Link>{" "}
              and search for{" "}
              <Link href="https://portal.azure.com/#create/Microsoft.Template" target="_blank">
                DEPLOY CUSTOM TEMPLATE
              </Link>
              .
            </Typography>,

            <Typography
              key="1"
              sx={{
                mb: 2,
              }}
            >
              Select BUILD YOUR OWN TEMPLATE IN THE EDITOR, and replace the blank template with the following JSON
              template:
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <CopyCodeBlock base={getTemplate(storageAccountName)} maxHeight={220} />
              </Box>
            </Typography>,

            <Typography
              key="3"
              sx={{
                mb: 2,
              }}
            >
              In the SUBSCRIPTION field, select the Azure subscription you want to use for the deployment.
            </Typography>,

            <Typography
              key="4"
              sx={{
                mb: 2,
              }}
            >
              In the REGION field, for compliance reasons, you may want to select the region where most of your
              resources are located.
            </Typography>,

            <Typography
              key="5"
              sx={{
                mb: 2,
              }}
            >
              Select SAVE, and then REVIEW + CREATE.
            </Typography>,

            <Typography
              key="6"
              sx={{
                mb: 2,
              }}
            >
              After the template has been validated, select CREATE.
            </Typography>,
          ]}
        />
      </Stack>
      <UsefulAzureLinks azureLink="https://learn.microsoft.com/en-us/azure/azure-resource-manager/templates/quickstart-create-templates-use-the-portal#edit-and-deploy-the-template" />
    </Stack>
  );
}
