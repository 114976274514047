import { useHistory } from "react-router";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { globalText, googleCloudBillingAccountAdminTexts } from "../../../../assets/texts";
import LoadingButton from "../../../../Components/LoadingButton";
import { MultiEmailsInputText } from "../../../../Components/MultiEmailsInputText";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { constructPathToManageDomains } from "../utils";
import { type ValidatedEmailType } from "./types";

type AddEmailsDialogProps = {
  newEmailValue: string;
  emails: string[];
  validatedEmails: ValidatedEmailType;
  isFullScreen: boolean;
  isLoading: boolean;
  isValid: boolean;
  isThereSignedContract: boolean;
  onEmailsChange: (newEmails: string[]) => void;
  onNewEmailValueChange: (newEmailValue: string) => void;
  onClose: () => void;
  onCancel: () => void;
  onAdd: () => void;
};

export function AddEmailsDialog({
  isFullScreen,
  onClose,
  onEmailsChange,
  onNewEmailValueChange,
  emails,
  validatedEmails,
  newEmailValue,
  isThereSignedContract,
  isLoading,
  onCancel,
  onAdd,
  isValid,
}: AddEmailsDialogProps) {
  const history = useHistory();
  const { customer } = useCustomerContext();

  return (
    <Dialog
      open={true}
      aria-labelledby="add-billing-account-admins-title"
      onClose={onClose}
      fullScreen={isFullScreen}
      fullWidth
      maxWidth={isFullScreen ? "xs" : "sm"}
    >
      <DialogTitle data-cy="dialogTitle" id="add-billing-account-admins-title">
        {googleCloudBillingAccountAdminTexts.ADD_ADMIN_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
        }}
      >
        <MultiEmailsInputText
          emails={emails}
          validatedEmails={validatedEmails}
          onEmailsChange={onEmailsChange}
          emailValue={newEmailValue}
          onEmailValueChange={onNewEmailValueChange}
          inputLabel={googleCloudBillingAccountAdminTexts.ADD_ADMIN_INPUT_LABEL}
          invalidEmailsHelperComponent={
            isThereSignedContract ? (
              <span>
                <Typography
                  variant="caption"
                  sx={{
                    display: "inline",
                  }}
                >
                  {googleCloudBillingAccountAdminTexts.CONTRACT_SIGNED_ADD_ADMIN_ERR}
                </Typography>
                <Link
                  underline="always"
                  sx={{ cursor: "pointer" }}
                  color="error.main"
                  onClick={() => {
                    history.push(constructPathToManageDomains(customer.ref.id));
                  }}
                >
                  {googleCloudBillingAccountAdminTexts.MANAGE_DOMAINS}
                </Link>
              </span>
            ) : (
              <span>{googleCloudBillingAccountAdminTexts.CONTRACT_NOT_SIGNED_ADD_ADMIN_ERR}</span>
            )
          }
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="text"
          data-cy="dialogCancel"
          onClick={onCancel}
          disabled={isLoading}
          sx={{ textTransform: "unset" }}
        >
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          data-cy="dialogAdd"
          color="primary"
          variant="contained"
          onClick={onAdd}
          loading={isLoading}
          disabled={isLoading || !isValid}
          sx={{ textTransform: "unset" }}
          mixpanelEventId="assets.admin-emails.add"
        >
          {globalText.ADD}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
