import { Link, Stack, Typography } from "@mui/material";

import { ContactSupportButton } from "../../../Flexsave/Standalone/GCP/Steps/Common/ContactSupportButton";

type Props = {
  snowflakeLink?: string;
};

export const UsefulSnowflakeLinks = ({ snowflakeLink }: Props) => (
  <Stack
    direction="row"
    spacing={2}
    sx={{
      alignItems: "center",
      pb: 2,
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: "500",
      }}
    >
      Useful links:
    </Typography>

    <ContactSupportButton />

    <Link href="https://help.doit.com/docs/general/saas" target="_blank" underline="hover">
      DoiT Help Center
    </Link>

    {snowflakeLink ? (
      <Link href={snowflakeLink} target="_blank" underline="hover">
        Snowflake Help Center
      </Link>
    ) : null}
  </Stack>
);
