import { type ReactNode } from "react";

import Grid from "@mui/material/Grid2";

import SectionHeader from "../SectionHeader";

export const WelcomeBlockWrapper = ({ children }: { children: ReactNode }) => (
  <Grid container spacing={2}>
    <Grid size={12}>
      <SectionHeader text="Welcome to the DoiT Console" />
    </Grid>
    {children}
  </Grid>
);
