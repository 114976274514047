import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Card, CardHeader, Stack } from "@mui/material";

import { cloudflowTexts } from "../../../../../assets/texts";
import ConditionForm from "../../FilterAction/ConditionForm";
import FieldReferenceForm from "../../FilterAction/FieldReferenceForm";
import SwitchDataSourceDialog from "../../FilterAction/SwitchDataSourceDialog";
import { useNodeDataSourceUpdate } from "../hooks";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const FilterParametersTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.FILTER>();

  const {
    selectedNode,
    setSelectedNode,
    referenceableNodes,
    switchDataSourceOpen,
    handleConfirm,
    handleClose,
    confirmSwitchDataSource,
  } = useNodeDataSourceUpdate({
    nodeId: nodeConfig.id,
    referencedNodeId: nodeConfig.parameters.referencedNodeId,
  });

  return (
    <Stack
      sx={{
        px: 2,
        justifyContent: "center",
        gap: 2,
        mt: 1,
      }}
    >
      <Card>
        <CardHeader
          avatar={<FilterAltIcon color="primary" />}
          subheader={cloudflowTexts.PICK_STEP_TO_FILTER}
          title={cloudflowTexts.FILTER_TITLE}
          titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
          subheaderTypographyProps={{ variant: "caption" }}
          sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
        />
      </Card>
      <FieldReferenceForm
        selectedNode={selectedNode}
        confirmSwitchDataSource={confirmSwitchDataSource}
        referenceableNodes={referenceableNodes}
        setSelectedNode={setSelectedNode}
        texts={{
          datasource: {
            title: cloudflowTexts.SELECT_DATA_SOURCE,
            subtitle: cloudflowTexts.PICK_STEP_TO_FILTER,
            label: cloudflowTexts.DATA_SOURCES,
          },
          referenceField: {
            title: cloudflowTexts.SELECT_FIELD_TO_FILTER,
            subtitle: cloudflowTexts.FROM_CHOSEN_STEP,
            label: cloudflowTexts.REFERENCED_FIELD,
            placeholderTooltip: cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE,
          },
        }}
      />
      <ConditionForm
        selectedNode={selectedNode}
        referenceableNodes={referenceableNodes}
        texts={{
          addNewGroupText: cloudflowTexts.ADD_NEW_FILTER_GROUP,
          defineCriteriaText: cloudflowTexts.DEFINE_FILTER_CRITERIA,
          fromChosenFieldText: cloudflowTexts.FROM_CHOSEN_STEP,
          groupText: cloudflowTexts.FILTER_GROUP,
          addNewText: cloudflowTexts.ADD_NEW_FILTER,
        }}
      />
      <SwitchDataSourceDialog
        isDialogOpened={switchDataSourceOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </Stack>
  );
};

export default FilterParametersTab;
