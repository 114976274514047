import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Tooltip, Typography } from "@mui/material";
import { common } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

import { cmpBaseColors } from "../../../cmpBaseColors";

export const OutsideOfTierTooltip = () => (
  <Stack
    spacing={1}
    direction="row"
    sx={{
      alignItems: "center",
      bgcolor: cmpBaseColors.primaryDark,
      color: alpha(common.black, 0.87),
      borderRadius: 1,
      pr: 1,
      pt: 0.25,
      pb: 0.25,
      pl: 1,
    }}
  >
    <Typography variant="subtitle2" color="inherit" noWrap>
      Outside of tier
    </Typography>
    <Tooltip
      placement="right"
      title="This feature is not available to the customer as it is not in their current tier."
    >
      <InfoOutlinedIcon sx={{ fontSize: 14 }} color="inherit" />
    </Tooltip>
  </Stack>
);
