import { useCallback, useMemo } from "react";

import { type BillingStandaloneOnboardingAWSModel, SaaSConsoleType } from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Alert, AlertTitle, Button, Stack, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { NumberedList } from "../../../Flexsave/Common/NumberedList";
import { step1Billing, step2Billing, step3Billing } from "../../../Flexsave/Standalone/AWS/Steps/Common/texts";
import { ErrorStepLocation, getErrorAlert } from "../../../Flexsave/Standalone/Common/helpers/errorsHelper";
import { OnBoardingAlert } from "../../../Flexsave/Standalone/Common/OnBoardingAlert";
import { trackConnectView } from "../Common/mixpanelEvents";
import { AWS_BILLING_REPORT_URL, S3BucketNameRegex, S3BucketNameRegexError } from "./consts";
import { updateStackCreationState } from "./db";
import { getBillingStackURL } from "./stackCreationUtils";

type Props = {
  accountId: string;
  bucket: string;
  setBucket: (s3Bucket: string) => void;
  onboarding?: BillingStandaloneOnboardingAWSModel;
};

export const BillingPermissions = ({ accountId, bucket, setBucket, onboarding }: Props) => {
  const customerId = useCustomerId();
  const onboardingData = onboarding?.accounts?.[accountId];

  useMountEffect(() => {
    trackConnectView(SaaSConsoleType.AWS);
  });

  const handleStackCreation = useCallback(async () => {
    await updateStackCreationState(accountId, customerId, SaaSConsoleType.AWS);
  }, [accountId, customerId]);

  const helperText = useMemo(() => {
    if (bucket.length === 0) {
      return "";
    }
    if (bucket.length < 3 || bucket.length > 63) {
      return S3BucketNameRegexError.InvalidLength;
    }
    if (!S3BucketNameRegex.test(bucket)) {
      return S3BucketNameRegexError.InvalidCharacters;
    }
    return "";
  }, [bucket]);

  const validBucket = useMemo(() => S3BucketNameRegex.test(bucket), [bucket]);

  const secondStep = (
    <>
      {step2Billing}
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 4,
          ml: -2,
        }}
      >
        <TextField
          data-cy="s3-bucket"
          variant="outlined"
          label="S3 Bucket"
          value={bucket}
          onChange={(e) => {
            setBucket(e.target.value.trim());
          }}
          required
          size="medium"
          error={!validBucket}
          helperText={helperText}
        />
      </Stack>
    </>
  );

  const firstStep = (
    <>
      {step1Billing}
      <Button
        href={AWS_BILLING_REPORT_URL}
        target="_blank"
        data-cy="go-to-aws-button"
        variant="outlined"
        sx={{ alignSelf: "flex-start", px: 3, py: 1, mb: 4, ml: -2 }}
        startIcon={<OpenNewIcon />}
      >
        Go to AWS Console
      </Button>
    </>
  );

  const billingSteps = [firstStep, secondStep, step3Billing];

  const loadingTimedOut = useMemo((): boolean => {
    if (onboardingData?.lastUpdated !== undefined) {
      return DateTime.now().diff(DateTime.fromJSDate(onboardingData.lastUpdated.toDate()), "minutes").minutes > 30;
    }
    return false;
  }, [onboardingData?.lastUpdated]);

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "500",
          }}
        >
          Connect your AWS account to DoiT Console
        </Typography>
      </Stack>
      <Alert severity="info">
        <AlertTitle>Reminder of required policy</AlertTitle>
        <ul style={{ marginLeft: "-12px", marginTop: "-2px", marginBottom: "-2px" }}>
          <li>AdministratorAccess</li>
        </ul>
      </Alert>
      {getErrorAlert(onboardingData?.errors?.activation?.type, ErrorStepLocation.AWS_ACTIVATION)}
      <NumberedList list={billingSteps} />
      <Stack spacing={3}>
        {onboardingData?.stackCreationState === "loading" && !onboardingData?.curPaths && !loadingTimedOut && (
          <OnBoardingAlert
            title="Accessing organization"
            body="After you create stack in the AWS Console it may take up to 60 seconds for this page to update."
            type="info"
          />
        )}

        <Button
          href={getBillingStackURL(customerId, bucket)}
          target="_blank"
          data-cy="create-stack-button"
          disabled={!validBucket}
          variant="outlined"
          sx={{ alignSelf: "flex-start", py: 1 }}
          startIcon={<OpenNewIcon />}
          onClick={handleStackCreation}
        >
          Open AWS CloudFormation Console
        </Button>
      </Stack>
    </Stack>
  );
};
