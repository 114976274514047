import { useCallback, useEffect, useRef, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import DeleteSAConfirmationDialog from "./ConfirmationDialog";
import { ServiceAccountStatusChip } from "./ServiceAccountStatusChip";
import { type GCPCloudConnectData } from "./useCloudConnectData";
import { useDeleteServiceAccount } from "./useDeleteServiceAccount";

const ROWS_PER_PAGE = 3;

type ServiceAccountListProps = {
  cloudConnectData: GCPCloudConnectData[];
};

export const ServiceAccountList = ({ cloudConnectData }: ServiceAccountListProps) => {
  const headerRowRef = useRef<HTMLTableRowElement | null>(null);
  const columnCount = headerRowRef.current?.children.length;

  const [serviceAccountToDelete, setServiceAccountToDelete] = useState<GCPCloudConnectData | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [serviceAccountsToDisplay, setServiceAccountsToDisplay] = useState<GCPCloudConnectData[]>([]);

  const [isDeletionInProgress, deleteServiceAccount] = useDeleteServiceAccount();
  const showSuccess = useSuccessSnackbar();
  const showError = useErrorSnackbar();

  const onConfirmDelete = useCallback(() => {
    if (serviceAccountToDelete !== null) {
      (async () => {
        try {
          await deleteServiceAccount(serviceAccountToDelete);
          setServiceAccountToDelete(null);
          showSuccess("Service account has been successfully deleted");
        } catch {
          showError("An error occurred during service account deletion");
        }
      })();
    }
  }, [deleteServiceAccount, serviceAccountToDelete, showError, showSuccess]);

  useEffect(() => {
    setServiceAccountsToDisplay(cloudConnectData.slice(currentPage * ROWS_PER_PAGE, (currentPage + 1) * ROWS_PER_PAGE));
  }, [cloudConnectData, currentPage]);

  return (
    <>
      <DeleteSAConfirmationDialog
        open={serviceAccountToDelete !== null}
        isDeletionInProgress={isDeletionInProgress}
        onConfirmDelete={onConfirmDelete}
        onClose={() => {
          setServiceAccountToDelete(null);
        }}
      />
      <Table>
        <TableHead>
          <TableRow ref={headerRowRef}>
            <TableCell>Organization</TableCell>
            <TableCell>Service account</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceAccountsToDisplay.map((serviceAccount) => (
            <TableRow key={serviceAccount.id} data-cy={`service-account-row-${serviceAccount.id}`}>
              <TableCell data-cy="org-cell">
                {serviceAccount.data.organizations ? serviceAccount.data.organizations[0]?.displayName : "N/A"}
              </TableCell>
              <TableCell data-cy="email-cell">{serviceAccount.data.clientEmail}</TableCell>
              <TableCell data-cy="status-cell">
                <ServiceAccountStatusChip status={serviceAccount.data.status ?? -1} />
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    setServiceAccountToDelete(serviceAccount);
                  }}
                  size="small"
                  edge="end"
                  aria-label="delete"
                  title="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {cloudConnectData.length === 0 && (
            <TableRow>
              <TableCell colSpan={columnCount}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    textAlign: "center",
                  }}
                >
                  You haven't connected service accounts yet. Connect a new service account in the features section
                  below.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {cloudConnectData.length > ROWS_PER_PAGE && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                colSpan={columnCount}
                count={cloudConnectData.length}
                rowsPerPage={ROWS_PER_PAGE}
                page={currentPage}
                onPageChange={(_, page) => {
                  setCurrentPage(page);
                }}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </>
  );
};
