import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Divider, IconButton, Step, StepLabel, Stepper, Typography } from "@mui/material";

import { globalText } from "../../assets/texts";
import LoadingButton from "../../Components/LoadingButton";
import { StepperBreadcrumbs } from "./StepperBreadcrumbs";
import { type StepperProps } from "./Types";

export const StepperWrapper = ({
  steps,
  backButtonLabel,
  onCancel,
  onSubmit,
  onComplete, // add another step to the end of the stepper
  disableSubmit,
  loading,
  overrideStep, // optional - change steps from parent
  getCurrentStep, // optional - get current step from parent
  maxWidth, // optional - set max width of stepper
  submitButtonLabel, // optional - set submit button label
  footerMaxWidth, // optional - set max width of footer buttons
  relativeFooter, // optional - set if footer will be position relative
  contentSx,
  nextButtonClickHandler, // Optional - set this callback if additional actions are required upon clicking the "Next" button before advancing the active step.
  prevStepHandler, // Optional - set this callback if additional actions are required upon clicking the "Back" button before going back to the previous step.
  isNextButtonHidden, // Optional - set this to hide the next button
}: StepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [contentWidthOffset, setContentWidthOffset] = useState(0);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const step = useMemo(() => steps[activeStep], [activeStep, steps]);
  const { t } = useTranslation("services");

  const handleCancel = () => {
    onCancel();
  };

  const handleNextButtonClick = async () => {
    if (nextButtonClickHandler) {
      setNextButtonLoading(true);
      try {
        const shouldIncreaseStep = await nextButtonClickHandler(activeStep);
        if (shouldIncreaseStep) {
          setActiveStep(activeStep + 1);
        }
      } finally {
        setNextButtonLoading(false);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const submit = async () => {
    if (activeStep === steps.length - 1 && onComplete) {
      onComplete();
      return;
    }

    await onSubmit();
  };

  const orderedSteps = useMemo(() => [...steps].sort((a, b) => a.order - b.order), [steps]);

  useEffect(() => {
    if (overrideStep !== undefined && overrideStep !== -1) {
      setActiveStep(overrideStep);
    }
  }, [overrideStep]);

  useEffect(() => {
    if (getCurrentStep !== undefined) {
      getCurrentStep(activeStep);
    }
  }, [activeStep, getCurrentStep]);

  const totalSteps = steps.length - 1;

  const currentStepDisabled = orderedSteps[activeStep].state !== "complete" && orderedSteps[activeStep].required;

  const offsetWidth = useMemo(() => contentWidthOffset / steps.length / 2 - 24, [contentWidthOffset, steps.length]);

  const finalStep = +!!onComplete;

  const isLastStep = finalStep && activeStep === totalSteps;

  return (
    <Box>
      {!!backButtonLabel && (
        <Box sx={{ gridColumn: "1/13" }}>
          <IconButton aria-label="Back" onClick={handleCancel} size="large">
            <BackIcon color="primary" />
          </IconButton>
          {backButtonLabel}
        </Box>
      )}
      <Box sx={{ margin: "auto", justifyContent: "center", maxWidth }}>
        <Box
          ref={(el: any) => {
            if (el) {
              setContentWidthOffset(el.getBoundingClientRect().width);
            }
          }}
        >
          <Stepper sx={{ width: "inherit" }} activeStep={activeStep} alternativeLabel>
            {orderedSteps.map((step) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (!step.required) {
                labelProps.optional = (
                  <Typography sx={{ textAlign: "center" }} variant="caption">
                    {globalText.OPTIONAL}
                  </Typography>
                );
              }
              stepProps.completed = step.state === "complete";
              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel
                    sx={{ ".MuiStepLabel-labelContainer": { display: "flex", flexDirection: "column" } }}
                    {...labelProps}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <Box sx={{ px: `${offsetWidth}px`, mb: 10, ...contentSx }}>
          <StepperBreadcrumbs steps={orderedSteps} activeStep={activeStep} setActiveStep={setActiveStep} />
          {orderedSteps[activeStep].children}
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: "background.paper",
          position: relativeFooter ? "relative" : "fixed",
          bottom: 0,
          width: "100%",
          left: 0,
          zIndex: 20,
        }}
      >
        <Divider sx={{ gridColumn: "1/13" }} />
        <Box
          sx={{
            gridColumn: "1/13",
            display: "flex",
            py: 1,
            px: footerMaxWidth ? 2 : 1,
            maxWidth: footerMaxWidth,
            margin: "auto",
          }}
        >
          {activeStep > 0 && !isLastStep && !step.hideBackButton && (
            <Button
              data-cy="stepper-back-button"
              color="primary"
              onClick={() => {
                prevStepHandler ? prevStepHandler() : setActiveStep(activeStep - 1);
              }}
              size="large"
              disabled={loading}
              variant="outlined"
            >
              {t("Stepper.back")}
            </Button>
          )}

          {activeStep > 0 && <Box sx={{ flex: "1 1 auto" }} />}

          <Box sx={{ flex: "1 1 auto" }} />

          {!isLastStep && (
            <Button
              color="primary"
              onClick={handleCancel}
              disabled={loading}
              variant="text"
              size="large"
              sx={{ marginRight: 3 }}
            >
              {t("Stepper.cancel")}
            </Button>
          )}

          {activeStep + finalStep < totalSteps ? (
            !isNextButtonHidden && (
              <LoadingButton
                color="primary"
                onClick={handleNextButtonClick}
                disabled={currentStepDisabled || loading}
                variant="contained"
                size="large"
                mixpanelEventId="stepper.next"
                loading={nextButtonLoading}
                data-cy="stepper-next-button"
              >
                {step.nextButtonLabel ? step.nextButtonLabel : t("Stepper.next")}
              </LoadingButton>
            )
          ) : (
            <LoadingButton
              color="primary"
              onClick={submit}
              disabled={currentStepDisabled || disableSubmit || loading}
              loading={loading}
              variant="contained"
              data-cy="stepper-complete-button"
              mixpanelEventId={`stepper.${submitButtonLabel ?? globalText.SAVE}`}
              size="large"
            >
              {submitButtonLabel ?? t("Stepper.save")}
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};
