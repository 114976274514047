import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

export const OpenFolderRow = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      height: "30px",
    }}
  >
    <Typography variant="body2">Google Drive</Typography>
    <Tooltip title="Open Shared Drive folder">
      <IconButton>
        <OpenInNewIcon sx={{ fontSize: "20px" }} />
      </IconButton>
    </Tooltip>
  </Box>
);
