import { useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/AddBoxRounded";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";

import { pricebooksText } from "../../../assets/texts";
import { useAuthContext } from "../../../Context/AuthContext";
import { FilterTable } from "../../FilterTable/FilterTable";
import Hide from "../../HideChildren/Hide";
import useRouteMatchURL from "../../hooks/useRouteMatchURL";
import { AWSfilterColumns, AWSHeaders } from "../Columns";
import { type AWSPricebook, type AWSPricebookRowProps } from "../Types";
import { AWSPricebookRow } from "./AWSPricebookRow";

type AWSPricebookTableProps = {
  pricebooks: AWSPricebook[];
  onCopy: AWSPricebookRowProps["onCopy"];
  onDelete: AWSPricebookRowProps["onDelete"];
};

export default function AmazonWebServicesPricebooks({ pricebooks, onCopy, onDelete }: AWSPricebookTableProps) {
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();
  const { isDoitOwner } = useAuthContext();

  const AWSPricebooksRowWrapper = ({ row }) => <AWSPricebookRow row={row} onCopy={onCopy} onDelete={onDelete} />;

  return (
    <FilterTable<AWSPricebook>
      showRowsSelection={false}
      tableItems={pricebooks}
      rowComponent={AWSPricebooksRowWrapper}
      headerColumns={AWSHeaders}
      filterColumns={AWSfilterColumns}
      filterBarPlaceholder={pricebooksText.FILTER_PRICEBOOKS}
      persistenceKey="pricebooks_aws_tab"
      itemUniqIdentifierField="ref.id"
      defaultSortingColumnValue="bookName"
    >
      {isDoitOwner && (
        <Grid>
          <Hide mdDown>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`${routeMatchURL}/create`);
              }}
              startIcon={<AddIcon />}
            >
              {pricebooksText.NEW_PRICEBOOK}
            </Button>
          </Hide>
        </Grid>
      )}
    </FilterTable>
  );
}
