import { Box, Link, Stack, Typography } from "@mui/material";

import Envelope from "../../../assets/envelop.svg";
import { loginTexts } from "../../../assets/texts";
import { useFullScreen } from "../../../utils/dialog";
import { type LoginType } from "../types";

const CheckInbox = ({ email, loginType }: { email: string; loginType: LoginType | undefined }) => {
  const { isMobile } = useFullScreen("sm");
  return (
    <Stack
      id="login-check-inbox"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        pt: 2,
        pb: 2,
      }}
    >
      <img src={Envelope} alt="envelope" />
      <Typography
        variant="h2"
        sx={{
          fontWeight: "fontWeightMedium",
          marginBottom: "16px",
        }}
      >
        {loginTexts.CHECK_INBOX_TITLE}
      </Typography>
      <Box sx={{ maxWidth: 350 }}>
        {loginType === "completeSignup" ? (
          <Box
            sx={{
              color: "text.secondary",
              mb: 3,
              textAlign: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                mb: 0.5,
              }}
            >
              We’ve just sent an email to:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                mb: 2,
              }}
            >
              {email}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 2,
              }}
            >
              Please open it and click the Join DoiT Console button
            </Typography>
            <Typography variant="body2">You may need to check your spam folder</Typography>
          </Box>
        ) : (
          <Typography
            variant="body2"
            align="center"
            sx={{
              color: "text.secondary",
              mb: 3,
            }}
          >
            We sent an email to <strong>{email}</strong> with a magic link to sign in to the DoiT Console. This link is
            valid for 6 hours and you can only use it once. Check your spam folder if you don't see an email in a couple
            of minutes.
          </Typography>
        )}
        <Typography
          variant="body2"
          align="center"
          sx={{
            color: "text.secondary",
          }}
        >
          If you don't receive an email,&nbsp;
          {isMobile ? (
            <>
              <Link href="mailto:support@doitintl.zendesk.com" underline="none">
                click here
              </Link>
              &nbsp;to contact support.
            </>
          ) : (
            <>
              contact support at&nbsp;
              <Link href="mailto:support@doitintl.zendesk.com" underline="none">
                support@doitintl.zendesk.com
              </Link>
              .
            </>
          )}
        </Typography>
      </Box>
    </Stack>
  );
};
export default CheckInbox;
