import { useCallback, useState } from "react";

import { Link } from "react-router-dom";
import { EarlyAccessFeature, PaymentMethodType } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/CloseRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useApiContext } from "../../../../api/context";
import { globalText } from "../../../../assets/texts";
import { invoicesTxt } from "../../../../assets/texts/Billing/invoices";
import { type SavedPaymentMethod } from "../../../../Components/CreateBillingProfile/BillingForm/api";
import { getPaymentMethodDescriptor } from "../../../../Components/CreateBillingProfile/BillingForm/components/payment-methods/PaymentMethodDescriptor";
import { useFetchSavedPaymentMethods } from "../../../../Components/CreateBillingProfile/useFetchSavedPaymentMethods";
import { useFeatureFlag } from "../../../../Components/hooks/useFeatureFlag";
import LoadingButton from "../../../../Components/LoadingButton";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { formatCurrency, getCurrencySymbol } from "../../../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../../../utils/dialog";
import mixpanel from "../../../../utils/mixpanel";
import { GetStripeCreditCardFeeAlert } from "../../../Entity/PaymentMethods/Stripe";
import { useCreditCardSurchargeCheck } from "../utils";
import { CreditCardFeesSection } from "./CreditCardFeesSection";

const minAmount = 1;

const InvoicePayDialog = ({ onClose, entity, invoice: { customer, IVNUM, SYMBOL, USDEXCH }, maxAmount }) => {
  const sharedSnackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(maxAmount < minAmount);
  const [paymentMethodListLoadError, setPaymentMethodListLoadError] = useState<string>();
  const [refreshPaymentMethodsList, setRefreshPaymentMethodsList] = useState(0);
  const [isLoadingPaymentMethodsList, paymentMethodList] = useFetchSavedPaymentMethods(
    entity,
    setPaymentMethodListLoadError,
    refreshPaymentMethodsList
  );
  const [paymentMethod, setPaymentMethod] = useState<SavedPaymentMethod | null>(null);
  const [amount, setAmount] = useState(maxAmount);
  const disableCreditCardFees = useFeatureFlag(EarlyAccessFeature.DISABLE_CREDIT_CARD_FEES);

  const api = useApiContext();

  const usingCreditCard = paymentMethod?.type === PaymentMethodType.CreditCard;

  const [creditCardFees, fetchingCCFees] = useCreditCardSurchargeCheck(api, customer.id, entity.id, amount);

  const resetState = useCallback(() => {
    setLoading(false);
    setAmount(maxAmount);
    setError(maxAmount < minAmount);
    if (paymentMethodList.length > 0) {
      for (let i = 0; i <= paymentMethodList.length; i++) {
        const pm = paymentMethodList[i];

        if (
          (entity.payment.type === PaymentMethodType.CreditCard &&
            entity.payment.card &&
            entity.payment.card.id === pm.id) ||
          ((entity.payment.type === PaymentMethodType.BankAccount ||
            entity.payment.type === PaymentMethodType.USBankAccount) &&
            entity.payment.bankAccount &&
            entity.payment.bankAccount.id === pm.id)
        ) {
          setPaymentMethod(pm);
          return;
        }
      }
    }
    setPaymentMethod(null);
  }, [maxAmount, entity, paymentMethodList]);

  const showSnackbar = (message, variant) => {
    sharedSnackbar.onOpen({
      message,
      variant,
      autoHideDuration: 30000,
      action: [
        <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
          <CloseIcon />
        </IconButton>,
      ],
    });
  };

  const handlePay = async () => {
    if (!paymentMethod) {
      return;
    }
    try {
      const newBalance = maxAmount - amount;
      if (newBalance !== 0 && newBalance <= 1) {
        showSnackbar(
          `Invalid payment amount: Balance after payment` +
            ` (${formatCurrency(newBalance, SYMBOL)})` +
            ` would be too low.`,
          "error"
        );
        return;
      }
      setLoading(true);
      await api.request({
        method: "post",
        url: `/v1/customers/${customer.id}/entities/${entity.id}/invoices/${IVNUM}`,
        data: {
          type: paymentMethod.type,
          payment_method_id: paymentMethod.id,
          amount,
        },
      });

      mixpanel.track("invoices.invoice.pay.completed", {
        id: IVNUM,
        amount,
        amountUSD: amount / USDEXCH,
        currency: SYMBOL,
      });

      onClose();
      showSnackbar(invoicesTxt.SUCCESSFUL_PAYMENT(amount, SYMBOL, IVNUM), "success");
      resetState();
    } catch (error: any) {
      consoleErrorWithSentry(error);
      let message = error.response?.data?.error;
      if (!message || error.response?.status === 500) {
        message = invoicesTxt.PAYMENT_FAILED;
      }
      mixpanel.track("invoices.invoice.pay.error", {
        id: IVNUM,
        amount,
        amountUSD: amount / USDEXCH,
        currency: SYMBOL,
        error: message,
      });
      onClose();
      showSnackbar(message, "error");
    }
    setLoading(false);
  };

  const handleChangeAmount = ({ target: { value, max } }: any) => {
    let amount = 0;
    if (value !== "") {
      amount = Math.round(Math.min(Number(max), Number(value)) * 100) / 100;
      setError(amount < minAmount);
    }
    setAmount(amount);
  };

  const handleCancel = () => {
    onClose();
    resetState();
  };

  const { fullScreen } = useFullScreen();
  const step = "0.01";

  return (
    <Dialog
      open={true}
      aria-labelledby="invoice-pay-dialog"
      onClose={preventOnCloseWhile(loading, onClose)}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="invoice-pay-dialog"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Pay invoice
        {!isLoadingPaymentMethodsList && (
          <Button
            startIcon={<RefreshIcon />}
            onClick={() => {
              setRefreshPaymentMethodsList(refreshPaymentMethodsList + 1);
              setPaymentMethod(null);
            }}
          >
            Refresh
          </Button>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid size={12}>
            {isLoadingPaymentMethodsList && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!!paymentMethodListLoadError && (
              <Alert severity="error">
                <AlertTitle>{invoicesTxt.SAVED_PAYMENT_METHODS_ERROR}</AlertTitle>
              </Alert>
            )}
            {paymentMethodList.length > 0 && !isLoadingPaymentMethodsList && (
              <List
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {invoicesTxt.SELECT_A_PAYMENT_METHOD}
                  </ListSubheader>
                }
                disablePadding
              >
                {paymentMethodList.map((pm) => {
                  const { icon, primary, secondary } = getPaymentMethodDescriptor(pm);
                  return (
                    <ListItem
                      disableGutters
                      divider
                      key={pm.id}
                      onClick={() => {
                        if (pm.disabledReason) {
                          return;
                        }
                        if (paymentMethod && paymentMethod.id === pm.id) {
                          setPaymentMethod(null);
                          return;
                        }
                        setPaymentMethod(pm);
                      }}
                    >
                      <ListItemButton disabled={!!pm.disabledReason} key={pm.id} disableGutters>
                        <ListItemIcon>
                          <Radio
                            checked={pm.id === paymentMethod?.id}
                            value={pm.id}
                            name="paymentMethodId"
                            inputProps={{ "aria-labelledby": pm.id }}
                          />
                        </ListItemIcon>
                        <ListItemIcon sx={{ minWidth: 80, marginRight: 2 }}>{icon}</ListItemIcon>
                        <ListItemText
                          id={pm.id}
                          primary={primary}
                          primaryTypographyProps={{ fontWeight: 500 }}
                          secondary={secondary}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Grid>
          <Grid size={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {invoicesTxt.NEW_PAYMENT_METHOD}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {invoicesTxt.CREATE_NEW_METHOD}
                </Typography>
              </Box>
              <Link
                to={`/customers/${customer.id}/entities/${entity.id}/edit/#payments`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="outlined" color="primary">
                  {invoicesTxt.CREATE_NEW}
                </Button>
              </Link>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                marginTop: 1,
              }}
            >
              {!isLoadingPaymentMethodsList && paymentMethodList.length === 0 && !paymentMethodListLoadError && (
                <Alert severity="info">
                  <AlertTitle>{invoicesTxt.NO_PAYMENT_METHODS_FOUND}</AlertTitle>
                </Alert>
              )}
              {!disableCreditCardFees && paymentMethod?.type === PaymentMethodType.CreditCard && (
                <Alert severity="info">
                  <AlertTitle>{GetStripeCreditCardFeeAlert(SYMBOL)}</AlertTitle>
                </Alert>
              )}
            </Box>
          </Grid>
          <Grid size={12}>
            <TextField
              label="Payment amount"
              variant="outlined"
              fullWidth
              value={amount}
              type="number"
              onChange={handleChangeAmount}
              disabled={loading || !paymentMethod}
              helperText={
                error
                  ? invoicesTxt.MINIMUM_AMOUNT_BALANCE(minAmount, SYMBOL)
                  : invoicesTxt.MAXIMUM_AMOUNT_BALANCE(maxAmount, SYMBOL)
              }
              error={error}
              slotProps={{
                input: {
                  inputProps: {
                    max: maxAmount.toString(),
                    step,
                  },
                  startAdornment: <InputAdornment position="start">{getCurrencySymbol(SYMBOL)}</InputAdornment>,
                },
              }}
            />
          </Grid>
        </Grid>
        {usingCreditCard && (creditCardFees.feesAllowed || fetchingCCFees) && (
          <CreditCardFeesSection
            amount={amount}
            creditCardFees={creditCardFees}
            fetchingCCFees={fetchingCCFees}
            symbol={SYMBOL}
          />
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button variant="text" onClick={handleCancel} disabled={loading}>
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handlePay}
          disabled={loading || error || amount < minAmount || !paymentMethod || fetchingCCFees}
          loading={loading}
          mixpanelEventId="invoices.details.pay"
        >
          {invoicesTxt.PAY_NOW}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InvoicePayDialog;
