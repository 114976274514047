import { ProductEnum } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { options } from "./consts";
import { PackageSelectorGroup } from "./PackageSelectorGroup";
import { type Option, type PackageOptions } from "./type";

export type Props = {
  packageOptions: PackageOptions;
  onSelect: (group: keyof PackageOptions, option: Option) => void;
};

const PackageSelector = ({ packageOptions, onSelect }: Props) => {
  const filteredOptions = options.filter((option) => option.value !== ProductEnum.Other);
  return (
    <Stack
      spacing={2.5}
      sx={{
        pt: 1.5,
      }}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        Select which packages the customer is interested in
      </Typography>
      <PackageSelectorGroup
        groupName="advantage"
        title="DoiT Cloud Advantage"
        options={options}
        selectedOptions={packageOptions.advantage}
        onSelect={onSelect}
      />
      <PackageSelectorGroup
        groupName="navigator"
        title="DCI"
        options={filteredOptions}
        selectedOptions={packageOptions.navigator}
        onSelect={onSelect}
      />
    </Stack>
  );
};

export default PackageSelector;
