import { useCallback } from "react";

import { useHistory } from "react-router";
import { Box, Button } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { useTheme } from "@mui/material/styles";

import { FilterTable } from "../../../../../../Components/FilterTable/FilterTable";
import { useCurrencyFormat } from "../../../../../../Components/hooks/useCurrencyFormat";
import { AccountsTableRow } from "./AccountsTableRow";
import { headers } from "./columns";
import { type ActiveAccountItem, useAccounts } from "./hooks";

export const AccountsTable = () => {
  const history = useHistory();
  const accounts = useAccounts();
  const currencyFormat = useCurrencyFormat();
  const theme = useTheme();

  const handleNewEstimationClick = useCallback(
    (accountId?: string) => {
      history.push("./flexsave-aws-onboarding", { accountId });
    },
    [history]
  );

  return (
    <Box
      sx={{
        pt: 5,
      }}
    >
      <Box sx={{ border: `1px solid ${theme.palette.general.divider}`, borderRadius: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pr: 4,
            borderBottom: `1px solid ${theme.palette.general.divider}`,
          }}
        >
          <CardHeader
            titleTypographyProps={{ variant: "h4" }}
            title="Flexsave AWS accounts"
            subheaderTypographyProps={{ variant: "body2" }}
          />

          <Button
            variant="outlined"
            size="large"
            onClick={() => {
              handleNewEstimationClick();
            }}
          >
            Start Another Estimate
          </Button>
        </Box>

        <Box
          sx={{
            px: 2,
            pb: 4,
          }}
        >
          <FilterTable<ActiveAccountItem>
            fillEmptySpace={false}
            showFilterBar={false}
            rowComponent={({ row }) => (
              <AccountsTableRow
                row={row}
                currencyFormat={currencyFormat}
                onSetupFlexsaveClicked={handleNewEstimationClick}
              />
            )}
            headerColumns={headers}
            filterColumns={[]}
            tableItems={accounts}
          />
        </Box>
      </Box>
    </Box>
  );
};
