import { memo, type ReactNode, useCallback, useLayoutEffect, useRef, useState } from "react";

import { type ComparativeFeature, type ForecastMode, Sort } from "@doitintl/cmp-models";
import {
  Box,
  Divider,
  Popover,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  type Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { type SystemStyleObject } from "@mui/system";

import { cmpBaseColors } from "../../../cmpBaseColors";
import { type AggregatorObject, type ColKeySort, type DataRecord, type ForecastItem } from "../ReportData";
import { isSubtotalRow, nullCell, subtotal } from "../subtotals";
import { Labels } from "../utilities";
import { vw } from "../utils/viewportCalc";
import { ResizableCellHeader } from "./hooks";
import type ReportData from "../ReportData";

const ROW_HEIGHT = 2;
const toRem = (n: number) => `${n}rem`;
const defaultRowHeight = toRem(ROW_HEIGHT);
const defaultCellWidth = vw(70) / 6;

const spanSize = (arr: string[][], i: number, j: number) => {
  let x: number;
  if (i !== 0) {
    let asc: boolean;
    let end: number;
    let noDraw = true;
    for (x = 0, end = j, asc = end >= 0; asc ? x <= end : x >= end; asc ? x++ : x--) {
      if (arr[i - 1][x] !== arr[i][x]) {
        noDraw = false;
      }
    }
    if (noDraw) {
      return -1;
    }
  }
  let len = 0;
  while (i + len < arr.length) {
    let asc1: boolean;
    let end1: number;
    let stop = false;
    for (x = 0, end1 = j, asc1 = end1 >= 0; asc1 ? x <= end1 : x >= end1; asc1 ? x++ : x--) {
      if (arr[i][x] !== arr[i + len][x]) {
        stop = true;
      }
    }
    if (stop) {
      break;
    }
    len++;
  }
  return len;
};

const useTableStyles = (smDown: boolean) => ({
  width: "unset",
  position: smDown ? "relative" : "sticky",
  left: 0,
  zIndex: 20,
  height: "100%",
  borderCollapse: "separate",
});

const containerStyles = (height: string, theme: Theme) => ({
  display: "flex",
  flex: 1,
  maxHeight: height,
  borderTop: `solid ${theme.typography.pxToRem(1)} ${theme.palette.general.divider}`,
  borderLeft: `solid ${theme.typography.pxToRem(1)} ${theme.palette.general.divider}`,
});

const rowBodyStyles: { root: SxProps<Theme>; innerCell: SxProps<Theme> } = {
  root: {
    height: defaultRowHeight,
  },
  innerCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const valueTableStyles = {
  position: "relative",
  zIndex: 10,
  height: "100%",
  borderCollapse: "separate",
};

const valueHeadStyles = {
  "& th": {
    position: "sticky",
  },
};

const tableSortLabelStyles = {
  width: "100%",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
};

const tableRowStyles = {
  root: (index: number) => ({
    height: defaultRowHeight,
    "& th": {
      top: toRem(index * ROW_HEIGHT),
    },
  }),
  last: {
    "&:last-child": { height: toRem(2 * ROW_HEIGHT) },
  },
};

const forecastStyle = {
  textDecorationLine: "underline",
  textDecorationStyle: "dotted",
};

const forecastTotalStyle = {
  ...forecastStyle,
  fontWeight: 700,
};

const forecastPopoverRowStyle = {
  display: "flex",
  justifyContent: "space-between",
};

const topLeftCellStyles = (length: number) => ({
  height: toRem(length * ROW_HEIGHT),
});

const getForecastCellBackgroundColor = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: theme.palette.mode === "light" ? "#FAFAFA" : "#2D2D39",
});

const getTotalsBackgroundColor = (theme: Theme) => (theme.palette.mode === "light" ? "#D8DAEC" : "#4E4F61");

const getSubtotalsBackgroundColor = (theme: Theme) => (theme.palette.mode === "light" ? "#E1E3F3" : "#3B3C4A");

const getSubtotalsBorderColor = (theme: Theme) =>
  theme.palette.mode === "light"
    ? `solid ${theme.typography.pxToRem(2)} #898EBD`
    : `solid ${theme.typography.pxToRem(2)} #A5A4A6`;

const getTotalsTableHeadStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: getTotalsBackgroundColor(theme),
  fontWeight: 500,
});

const getTotalsTableSumBottomStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: getTotalsBackgroundColor(theme),
  fontWeight: 700,
});

const getTotalsTableSumRightStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: getTotalsBackgroundColor(theme),
  fontWeight: 500,
});

const getSubtotalsHeadTableCellStyles = (theme: Theme): SxProps<Theme> => ({
  borderBottom: getSubtotalsBorderColor(theme),
  backgroundColor: getSubtotalsBackgroundColor(theme),
  fontWeight: 500,
});

const getSubtotalsSumTableCellStyles = (theme: Theme): SxProps<Theme> => ({
  borderBottom: getSubtotalsBorderColor(theme),
  backgroundColor: getSubtotalsBackgroundColor(theme),
  fontWeight: 700,
});

const getSubtotalsTableCellStyles = (theme: Theme): SxProps<Theme> => ({
  borderBottom: getSubtotalsBorderColor(theme),
  fontWeight: 500,
});

const getTableCellStyles = (theme: Theme): SxProps<Theme> => ({
  borderRight: `solid ${theme.typography.pxToRem(1)} ${theme.palette.general.divider}`,
  borderBottom: `solid ${theme.typography.pxToRem(1)} ${theme.palette.general.divider}`,
  borderCollapse: "separate",
  whiteSpace: "nowrap",
  fontSize: theme.typography.pxToRem(12),
  padding: theme.spacing(0.25, 0.5),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.default,
});

const getTableHeadCellStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: theme.palette.general.cellBackground,
  fontWeight: 500,
});

const getTableSpacerCellStyles = (theme: Theme): SxProps<Theme> => ({
  width: theme.spacing(5),
});

const getMuiTableCellStyles =
  (variant?: string, totalVariant?: string, spacer?: boolean, sx?: SxProps<Theme>) => (theme: Theme) => {
    let variantStyle: SxProps<Theme>;
    switch (totalVariant) {
      case "totalHeadCell":
        variantStyle = getTotalsTableHeadStyles(theme);
        break;
      case "totalSumBottomCell":
        variantStyle = getTotalsTableSumBottomStyles(theme);
        break;
      case "totalSumRightCell":
        variantStyle = getTotalsTableSumRightStyles(theme);
        break;
      case "subtotalHeadCell":
        variantStyle = getSubtotalsHeadTableCellStyles(theme);
        break;
      case "subtotalSumCell":
        variantStyle = getSubtotalsSumTableCellStyles(theme);
        break;
      case "subtotalCell":
        variantStyle = getSubtotalsTableCellStyles(theme);
        break;
      case "forecastCell":
        variantStyle = getForecastCellBackgroundColor(theme);
        break;
      default:
        variantStyle = {};
    }

    const headStyles = variant === "head" ? getTableHeadCellStyles(theme) : {};
    const spacerStyles = spacer ? getTableSpacerCellStyles(theme) : {};

    return {
      ...getTableCellStyles(theme),
      ...spacerStyles,
      ...headStyles,
      ...variantStyle,
      ...sx,
    };
  };

type TableCellProps = {
  align?: "left" | "center" | "right";
  children?: ReactNode;
  colSpan?: number;
  rowSpan?: number;
  spacer?: boolean;
  // TODO(yoni): try to remove the sx prop
  sx?: SxProps<Theme>;
  variant?: "head";
  totalVariant?:
    | "totalHeadCell"
    | "totalSumBottomCell"
    | "totalSumRightCell"
    | "subtotalCell"
    | "subtotalHeadCell"
    | "subtotalSumCell"
    | "forecastCell";
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: () => void;
  ariaOwns?: string;
};

const TableCell = (props: TableCellProps) => {
  const { children, spacer, sx, variant, totalVariant, onMouseEnter, onMouseLeave, ariaOwns, ...other } = props;

  return (
    <MuiTableCell
      align="center"
      sx={getMuiTableCellStyles(variant, totalVariant, spacer, sx)}
      {...other}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-owns={ariaOwns}
      aria-haspopup={true}
    >
      {children}
    </MuiTableCell>
  );
};

const fixedCellWidth = (len: number) => `calc(70vw / ${len * 2})`;

type FixedKeysAndAttributesHeaderRowProps = {
  rowsLength: number;
  colsLength: number;
  j: number;
  label?: string;
};

const FixedKeysAndAttributesHeaderRow = ({
  rowsLength,
  colsLength,
  j,
  label,
}: FixedKeysAndAttributesHeaderRowProps) => (
  <TableRow sx={tableRowStyles.root(j)}>
    {j === 0 && rowsLength !== 0 && (
      <TableCell
        variant="head"
        align="left"
        colSpan={rowsLength}
        rowSpan={colsLength}
        spacer
        sx={topLeftCellStyles(colsLength)}
      />
    )}

    <TableCell variant="head" align="left">
      {label}
    </TableCell>
  </TableRow>
);

type FixedRowCellProps = {
  widthChange: (colNumber: number, w: number) => void;
  colNumber: number;
  id?: string;
  text: string;
  width?: string | number;
};

const FixedRowCell = ({ widthChange, colNumber, id, text, width }: FixedRowCellProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      widthChange(colNumber, ref.current.clientWidth);
    }
  }, [colNumber, widthChange]);

  return (
    <Box ref={ref} id={id} sx={{ ...rowBodyStyles.innerCell }} style={{ width }}>
      {text}
    </Box>
  );
};

type FixedKeysAndAttributesTableProps = {
  rows: DataRecord[];
  cols: DataRecord[];
  tableStyles: SystemStyleObject<Theme>;
  rowKeys: string[][];
  stickyBottomRowClasses: SxProps<Theme>;
  forecastMode?: ForecastMode;
};

// Render the left side of the table with the keys (i.e. year, month, service, SUK). This part is fixed to the left
const FixedKeysAndAttributesTable = ({
  rows,
  cols,
  tableStyles,
  rowKeys,
  stickyBottomRowClasses,
  forecastMode,
}: FixedKeysAndAttributesTableProps) => {
  const [columnWidth, setColumnWidth] = useState<(number | string)[]>([]);
  const [maxWidth, setMaxWidth] = useState<number[]>([]);
  const onResize = useCallback((id: number, w: number | string) => {
    setColumnWidth((prev) => {
      const newColumnWidth = [...prev];
      if (newColumnWidth[id] !== w) {
        newColumnWidth[id] = w;
      }
      return newColumnWidth;
    });
  }, []);

  const widthChange = useCallback(
    (colNumber: number, w: number) => {
      if (rows.length < 3) {
        setMaxWidth((prev) => {
          const newMaxWidth = [...prev];
          const currMaxWidth = Math.min(Math.max(newMaxWidth[colNumber] || 0, w), defaultCellWidth);
          if (newMaxWidth[colNumber] !== currMaxWidth) {
            newMaxWidth[colNumber] = currMaxWidth;
          }
          return newMaxWidth;
        });
      }
    },
    [rows]
  );

  return (
    <Table sx={(theme) => ({ backgroundColor: theme.palette.background.default, ...tableStyles })}>
      <TableHead sx={valueHeadStyles}>
        {cols.map((col, j) => (
          <FixedKeysAndAttributesHeaderRow
            key={`colAttr-${col.key}`}
            rowsLength={rows.length}
            colsLength={cols.length}
            j={j}
            label={col.label}
          />
        ))}
        {rows.length !== 0 && (
          <TableRow sx={stickyBottomRowClasses}>
            {rows.map((row, i) => (
              <TableCell variant="head" key={`rowAttr-${row.key}`} align="left">
                <ResizableCellHeader
                  title={row.label}
                  colId={i}
                  width={rows.length >= 3 ? fixedCellWidth(rows.length) : maxWidth[i]}
                  onResizeElement={onResize}
                  sx={rowBodyStyles.innerCell}
                />
              </TableCell>
            ))}
            {cols.length !== 0 && <TableCell variant="head" spacer />}
          </TableRow>
        )}
      </TableHead>

      <TableBody>
        {rowKeys.map((rowKey, i) => {
          const rowKeyJoin = rowKey.join("/");
          return (
            <TableRow key={`tableRowKeyRow-${rowKeyJoin}`} sx={rowBodyStyles.root}>
              {rowKey.map((attr, j) => {
                let totalVariant;
                let colSpan = j === rows.length - 1 && cols.length !== 0 ? 2 : 1;
                if (attr === subtotal) {
                  colSpan = rowKey.length - j + 1;
                  totalVariant = "subtotalHeadCell";
                }

                const rowSpan = spanSize(rowKeys, i, j);
                if (rowSpan === -1 || attr === nullCell) {
                  return null;
                }
                return (
                  <TableCell
                    key={`rowKeyLabel-${rowKeyJoin}-${attr}-${j}`}
                    variant={totalVariant ? undefined : "head"}
                    align="left"
                    rowSpan={rowSpan}
                    colSpan={colSpan}
                    totalVariant={totalVariant}
                    sx={{ borderLeft: "none" }}
                  >
                    <FixedRowCell colNumber={j} widthChange={widthChange} text={attr} width={columnWidth[j] || ""} />
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}

        <TableRow sx={rowBodyStyles.root}>
          <TableCell totalVariant="totalHeadCell" align="left" colSpan={rows.length + (cols.length === 0 ? 0 : 1)}>
            Totals
          </TableCell>
        </TableRow>
        {forecastMode === "totals" && (
          <TableRow sx={rowBodyStyles.root}>
            <TableCell variant="head" align="left" colSpan={rows.length + (cols.length === 0 ? 0 : 1)}>
              {Labels.ML_FORECAST}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

type ColHeaderCellProps = {
  colSpan: number;
  colKey: string[];
  i: number;
  j: number;
  rowSpan: number;
  colKeySort?: ColKeySort | null;
  sortByCol?: (colKey: string[]) => void;
  isActive: boolean;
};

const colHeaderCell = ({ colSpan, colKey, i, j, rowSpan, colKeySort, sortByCol, isActive }: ColHeaderCellProps) => {
  const sortLabel = (label: string) => (
    <TableSortLabel
      active={isActive}
      direction={colKeySort?.order === Sort.ASC ? "asc" : "desc"}
      onClick={() => {
        sortByCol?.(colKey);
      }}
      sx={(theme) => (isActive ? { paddingLeft: theme.spacing(1), ...tableSortLabelStyles } : {})}
      hideSortIcon={true}
    >
      {label}
    </TableSortLabel>
  );
  return (
    <TableCell variant="head" key={`colKey-${i}`} colSpan={colSpan} rowSpan={rowSpan}>
      {colKey.length - 1 === j && sortByCol ? sortLabel(colKey[j]) : colKey[j]}
    </TableCell>
  );
};

const abcFunction = (_a, _b, _c) => ({});

type TotalColors = (a: number, b?: number, c?: number) => object;

type Formatter = (value: number | undefined, short?: boolean, comparative?: ComparativeFeature) => number;

type Heatmap = "full" | "row" | "col" | "";

type ScrollableValuesTableProps = {
  rows: DataRecord[];
  forecastRow: string[];
  cols: DataRecord[];
  colKeys: string[][];
  nonForecastColKeys: string[][];
  isForecast: boolean;
  forecastStart?: number;
  forecastMode?: ForecastMode;
  rowKeys: string[][];
  formatter: Formatter;
  data: ReportData;
  valueCellColors: (rowKey: string[], colKey: string[], value: number) => object;
  colTotalColors: TotalColors;
  rowTotalColors: TotalColors;
  grandTotalAggregator: AggregatorObject;
  sortByCol?: (colKey: string[]) => void;
  colKeySort?: ColKeySort | null;
  totalsColHidden?: boolean;
  heatmap?: Heatmap;
};

const getPeriodDifference = (currentElementValue, prevElementValue) => {
  let prevPeriodDiff = currentElementValue && prevElementValue ? currentElementValue / prevElementValue : 0;
  let prevPeriodDiffPercent = "";

  if (prevPeriodDiff) {
    if (prevPeriodDiff > 1) {
      prevPeriodDiff -= 1;
      prevPeriodDiffPercent = `+${Math.round(prevPeriodDiff * 10000) / 100}%`;
    } else {
      prevPeriodDiff = 1 - prevPeriodDiff;
      prevPeriodDiffPercent = `-${Math.round(prevPeriodDiff * 10000) / 100}%`;
    }
  }
  return prevPeriodDiffPercent;
};

// Render the right side of the table with the values. This part is scrollable to the right
const ScrollableValuesTable = ({
  rows,
  forecastRow,
  cols,
  colKeys,
  nonForecastColKeys,
  forecastStart,
  forecastMode,
  rowKeys,
  formatter,
  data,
  valueCellColors,
  colTotalColors,
  rowTotalColors,
  grandTotalAggregator,
  sortByCol,
  colKeySort,
  totalsColHidden,
  heatmap,
}: ScrollableValuesTableProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [currentForecastItem, setCurrentForecastItem] = useState<{
    forecastItem: ForecastItem | null;
    prevValue: number | null;
  } | null>(null);

  const handleForecastPopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    forecastItem: ForecastItem | null,
    prevValue: number | null
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentForecastItem({ forecastItem, prevValue });
  };

  const handleForecastPopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      {!!data && (
        <Popover
          id="forecast-data-popover"
          open={popoverOpen}
          style={{ pointerEvents: "none" }}
          anchorEl={anchorEl}
          onClose={handleForecastPopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Stack
            sx={{
              width: 250,
              p: 2,
              gap: 1,
            }}
          >
            <Box sx={forecastPopoverRowStyle}>
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                Forecast
              </Typography>
              <Typography variant="caption">{formatter(currentForecastItem?.forecastItem?.value)}</Typography>
            </Box>
            {currentForecastItem?.forecastItem?.yhatUpper !== null && (
              <Box sx={forecastPopoverRowStyle}>
                <Typography variant="caption">Upper bound</Typography>
                <Typography variant="caption">{formatter(currentForecastItem?.forecastItem?.yhatUpper)}</Typography>
              </Box>
            )}
            {currentForecastItem?.forecastItem?.yhatLower !== null && (
              <Box sx={forecastPopoverRowStyle}>
                <Typography variant="caption">Lower bound</Typography>
                <Typography variant="caption">{formatter(currentForecastItem?.forecastItem?.yhatLower)}</Typography>
              </Box>
            )}
            {currentForecastItem?.prevValue !== null && (
              <>
                <Divider />
                <Box sx={forecastPopoverRowStyle}>
                  <Typography variant="caption">Diff. from prev period</Typography>
                  <Typography variant="caption">
                    {getPeriodDifference(currentForecastItem?.forecastItem?.value, currentForecastItem?.prevValue)}
                  </Typography>
                </Box>
              </>
            )}
          </Stack>
        </Popover>
      )}
      <Table sx={valueTableStyles}>
        <TableHead sx={valueHeadStyles}>
          {cols.length > 0
            ? cols.map((col, j) => (
                <TableRow
                  key={`colAttr-${col.key}`}
                  sx={{ ...tableRowStyles.root(j), ...(rows.length > 0 ? tableRowStyles.last : undefined) }}
                >
                  {colKeys.map((colKey, i) => {
                    const colSpan = spanSize(colKeys, i, j);
                    if (colSpan === -1) {
                      return null;
                    }
                    const rowSpan = j === cols.length - 1 && rows.length !== 0 ? 2 : 1;
                    const isActive = colKeySort?.key && colKeySort.key.join("-") === colKey.join("-");
                    return colHeaderCell({
                      colSpan,
                      colKey,
                      i,
                      j,
                      rowSpan,
                      colKeySort,
                      sortByCol,
                      isActive: !!isActive,
                    });
                  })}

                  {j === 0 && !totalsColHidden && (
                    <TableCell
                      totalVariant="totalHeadCell"
                      rowSpan={cols.length + (rows.length === 0 ? 0 : 1)}
                      sx={{
                        fontWeight: 900,
                      }}
                    >
                      Totals{forecastMode === "grouping" ? " incl. forecast" : ""}
                    </TableCell>
                  )}
                </TableRow>
              ))
            : rows.length !== 0 &&
              (() => (
                <TableRow sx={tableRowStyles.root(0)}>
                  <TableCell
                    totalVariant="totalHeadCell"
                    sx={{
                      fontWeight: 900,
                    }}
                  >
                    Totals
                  </TableCell>
                </TableRow>
              ))()}
        </TableHead>

        <TableBody>
          {rowKeys.map((rowKey) => {
            const subtotalRow = isSubtotalRow(rowKey);
            let colTotalAggregator;
            if (rowKey[0] !== Labels.FORECAST) {
              colTotalAggregator = data.getAggregator(rowKey, []);
            }
            const rowKeyJoin = rowKey.join("/");
            const totalColorCell =
              heatmap === "col" && !subtotalRow ? colTotalColors(colTotalAggregator.value()) : undefined;

            let forecastRowTotal = 0;

            return (
              <TableRow key={`rowKeyRow-${rowKeyJoin}`} sx={rowBodyStyles.root}>
                {colKeys.map((colKey, index) => {
                  let forecastValue: number | null = null;
                  let forecastItem: ForecastItem | null = null;
                  let prevForecastValue: number | null = null;
                  let id = "";
                  let date = "";

                  if (forecastMode === "grouping" && !!data.forecasts && forecastStart && index >= forecastStart) {
                    id = rowKey.join(";");
                    date = colKey.join("-");
                    const forecastItemIndex = data.forecasts.findIndex((f) => f.id === id && f.date === date) ?? null;
                    forecastItem = data.forecasts?.[forecastItemIndex] ?? null;
                    prevForecastValue =
                      index > forecastStart ? data.forecasts?.[forecastItemIndex - 1]?.value || null : null;
                  }

                  forecastValue = forecastItem?.value ?? null;
                  forecastRowTotal += forecastValue ?? 0;

                  const cellValueAggregator = data.getAggregator(rowKey, colKey);
                  const colorCell =
                    (!heatmap || heatmap === "full" || heatmap === "col") && subtotalRow
                      ? undefined
                      : valueCellColors(rowKey, colKey, cellValueAggregator.value());

                  return forecastValue === null ? (
                    <TableCell
                      key={`cellVal${rowKeyJoin}-${colKey.join("/")}`}
                      sx={colorCell}
                      totalVariant={subtotalRow ? "subtotalCell" : undefined}
                    >
                      {formatter(cellValueAggregator.value(), false, cellValueAggregator.comparative)}
                    </TableCell>
                  ) : (
                    <TableCell
                      ariaOwns={popoverOpen ? "forecast-data-popover" : undefined}
                      key={`cellVal${rowKeyJoin}-${colKey.join("/")}`}
                      sx={{ ...colorCell, ...forecastStyle, cursor: "pointer" }}
                      totalVariant={subtotalRow ? "subtotalCell" : "forecastCell"}
                      onMouseEnter={(event) => {
                        handleForecastPopoverOpen(event, forecastItem, prevForecastValue);
                      }}
                      onMouseLeave={() => {
                        handleForecastPopoverClose();
                      }}
                    >
                      {formatter(forecastValue)}
                    </TableCell>
                  );
                })}

                {!totalsColHidden && (
                  <TableCell
                    totalVariant={subtotalRow ? "subtotalSumCell" : "totalSumRightCell"}
                    sx={forecastRowTotal ? { ...totalColorCell, ...forecastTotalStyle } : totalColorCell}
                  >
                    {formatter(Number(colTotalAggregator.value(true)) + forecastRowTotal)}
                  </TableCell>
                )}
              </TableRow>
            );
          })}

          <TableRow sx={rowBodyStyles.root}>
            {nonForecastColKeys.map((colKey, index) => {
              const rowTotalAggregator = data.getAggregator([], colKey);

              const colorCell =
                !heatmap || heatmap === "full" || heatmap === "col"
                  ? undefined
                  : rowTotalColors(rowTotalAggregator.value());

              let cellStyle = colorCell as any;
              let forecastColTotal = 0;
              if (forecastMode === "grouping" && !!data.forecasts && forecastStart && index >= forecastStart) {
                const date = colKey.join("-");
                forecastColTotal = data.forecasts.filter((f) => f.date === date).reduce((a, b) => a + b.value, 0);
                cellStyle = { ...colorCell, ...forecastTotalStyle };
              }

              return (
                <TableCell key={`total${colKey.join("/")}`} sx={cellStyle} totalVariant="totalSumBottomCell">
                  {formatter(
                    Number(rowTotalAggregator.value(true) + forecastColTotal),
                    false,
                    rowTotalAggregator.comparative
                  )}
                </TableCell>
              );
            })}

            {forecastMode === "totals" &&
              colKeys.slice(forecastStart).map((col) => <TableCell key={`empty${col.join("/")}`} />)}

            {!totalsColHidden &&
              (forecastMode !== "grouping" ? (
                <TableCell totalVariant="totalSumBottomCell">{formatter(grandTotalAggregator.value(true))}</TableCell>
              ) : (
                <TableCell totalVariant="totalSumBottomCell" sx={forecastMode ? forecastTotalStyle : undefined}>
                  {formatter(
                    grandTotalAggregator.value(true) + Number(data?.forecasts?.reduce((a, b) => a + b.value, 0))
                  )}
                </TableCell>
              ))}
          </TableRow>
          {forecastRow?.map((forecastRowString) => (
            <TableRow key={`forecastRowKeyRow${forecastRowString}`} sx={rowBodyStyles.root}>
              {data?.forecasts?.map((forecastItem, index) => {
                let prevForecastValue: number | null = null;
                if (forecastStart && index >= forecastStart) {
                  prevForecastValue = data?.forecasts?.[index - 1]?.value ?? null;
                }
                return forecastItem?.value !== null ? (
                  <TableCell
                    key={`cellVal${forecastRowString}-${forecastItem.date}-${forecastItem.value}`}
                    sx={{ ...forecastStyle, cursor: "pointer" }}
                    onMouseEnter={(event) => {
                      handleForecastPopoverOpen(event, forecastItem, prevForecastValue);
                    }}
                    onMouseLeave={() => {
                      handleForecastPopoverClose();
                    }}
                  >
                    {formatter(forecastItem.value)}
                  </TableCell>
                ) : (
                  <TableCell key={`cellVal${forecastRowString}-${forecastItem.date}`} />
                );
              })}
              <TableCell>{formatter(data?.forecasts?.reduce((a, b) => a + b.value, 0))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

type TableRendererProps = {
  data: ReportData;
  isForecast: boolean;
  formatter: Formatter;
  reverseColors: boolean;
  height?: string;
  smDown: boolean;
  sortByCol?: (colKey: string[]) => void;
  colKeySort?: ColKeySort | null;
  heatmap?: Heatmap;
  forecastStart?: number;
  totalsColHidden?: boolean;
  showSubtotals?: boolean;
};

const TableRenderer = ({
  data,
  isForecast,
  formatter,
  reverseColors,
  height = "",
  smDown = false,
  sortByCol,
  colKeySort,
  heatmap,
  forecastStart,
  totalsColHidden,
  showSubtotals,
}: TableRendererProps) => {
  const theme = useTheme();
  const tableStyles = useTableStyles(smDown);
  const stickyBottomRowClasses = tableRowStyles.root(data.getCols().length);

  const colorScaleGenerator = useCallback(
    (values) => {
      const maxValue = Math.max(...values);
      const minValue = Math.min(...values);
      const min = Math.max(0, minValue);
      const max = maxValue;
      const negMin = minValue;
      const negMax = Math.min(0, maxValue);
      let getAlpha = (_a) => 0;
      let getNegAlpha = (_a) => 0;
      if (Math.abs(max - min) < 1e-8) {
        getAlpha = () => 0;
      } else {
        getAlpha = (val) => (val - min) / (max - min);
      }
      if (Math.abs(negMax - negMin) < 1e-8) {
        getNegAlpha = () => 0;
      } else {
        getNegAlpha = (val) => 1 - (val - negMin) / (negMax - negMin);
      }

      return (x) => {
        const green = {
          light: cmpBaseColors.greenLightHeatMap,
          dark: cmpBaseColors.greenDarkHeatMap,
        };
        const red = {
          light: cmpBaseColors.redLightHeatMap,
          dark: cmpBaseColors.redDarkHeatMap,
        };

        const value = x ?? 0;
        const color1 = reverseColors ? green : red;
        const color2 = reverseColors ? red : green;

        const alphaMaxValue = Math.max(value >= 0 ? getAlpha(value) : getNegAlpha(value), 0);

        const alphaValue = Math.min(alphaMaxValue, 1); // alpha must be in [0, 1]
        const baseColor = value >= 0 ? color1 : color2;
        return {
          backgroundColor: alpha(baseColor[theme.palette.mode], alphaValue),
        };
      };
    },
    [theme, reverseColors]
  );

  if (!data) {
    return null;
  }

  const rows = data.getRows();
  const cols = data.getCols();
  const rowKeys = data.getRowKeys(showSubtotals);
  const colKeys = data.getColKeys();
  const grandTotalAggregator = data.getAggregator([], []);
  let valueCellColors = abcFunction;
  let rowTotalColors = abcFunction;
  let colTotalColors = abcFunction;
  let forecastRow: string[] = [];
  let nonForecastColKeys = colKeys;
  const nonForecastRowKeys = rowKeys;
  const forecastMode = isForecast ? (data?.forecastSettings?.mode ?? "totals") : undefined;
  if (isForecast && !!data.forecasts && forecastMode === "totals") {
    forecastRow = [Labels.FORECAST];
    nonForecastColKeys = colKeys.slice(0, forecastStart);
  }
  if (heatmap) {
    const rowTotalValues = nonForecastColKeys.map((colKey) => data.getAggregator([], colKey).value(true));
    rowTotalColors = colorScaleGenerator(rowTotalValues);
    const colTotalValues = nonForecastRowKeys.map((rowKey) => data.getAggregator(rowKey, []).value(true));
    colTotalColors = colorScaleGenerator(colTotalValues);
    if (heatmap === "full") {
      const allValues: number[] = [];
      nonForecastRowKeys.forEach((rowKey) => {
        nonForecastColKeys.forEach((colKey) => {
          allValues.push(data.getAggregator(rowKey, colKey).value());
        });
      });
      const colorScale = colorScaleGenerator(allValues);
      valueCellColors = (r, c, v) => colorScale(v);
    } else if (heatmap === "row") {
      const rowColorScales = nonForecastRowKeys.reduce((memo, rowKey: string[]) => {
        const rowKeyJoin = rowKey.join(String.fromCharCode(0));
        const rowValues = nonForecastColKeys.map((colKey) => data.getAggregator(rowKey, colKey).value());
        memo[rowKeyJoin] = colorScaleGenerator(rowValues);
        return memo;
      }, {});
      valueCellColors = (r, c, v) => {
        const rJoin = r.join(String.fromCharCode(0));
        return rowColorScales[rJoin](v);
      };
    } else if (heatmap === "col") {
      const colColorScales = colKeys.reduce((memo, colKey: string[]) => {
        const colKeyJoin = colKey.join(String.fromCharCode(0));
        const colValues = nonForecastRowKeys.map((rowKey) => data.getAggregator(rowKey, colKey).value());
        memo[colKeyJoin] = colorScaleGenerator(colValues);
        return memo;
      }, {});
      valueCellColors = (r, c, v) => {
        const cJoin = c.join(String.fromCharCode(0));
        return colColorScales[cJoin](v);
      };
    }
  }

  return (
    <TableContainer sx={(theme) => containerStyles(height, theme)}>
      <FixedKeysAndAttributesTable
        cols={cols}
        rowKeys={rowKeys}
        rows={rows}
        stickyBottomRowClasses={stickyBottomRowClasses}
        tableStyles={tableStyles}
        forecastMode={forecastMode}
      />
      <ScrollableValuesTable
        rows={rows}
        cols={cols}
        colKeys={colKeys}
        rowKeys={rowKeys}
        nonForecastColKeys={nonForecastColKeys}
        isForecast={isForecast}
        forecastStart={forecastStart}
        forecastRow={forecastRow}
        forecastMode={forecastMode}
        formatter={formatter}
        data={data}
        valueCellColors={valueCellColors}
        colTotalColors={colTotalColors}
        rowTotalColors={rowTotalColors}
        grandTotalAggregator={grandTotalAggregator}
        sortByCol={sortByCol}
        colKeySort={colKeySort}
        totalsColHidden={totalsColHidden}
        heatmap={heatmap}
      />
    </TableContainer>
  );
};

export default memo(TableRenderer);
