import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { RampModel, type RampPlanCommitmentPeriods } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Box, InputAdornment, TextField } from "@mui/material";
import { type DateTime } from "luxon";

import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../Components/SimpleDialog";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";

type Props = {
  open: boolean;
  onClose: () => void;
  planStart: DateTime;
  planDateLabel: string;
  origCommitmentPeriods: RampPlanCommitmentPeriods[];
  commitmentPeriodIndex: number;
};

const EditPlannedSpendDialog = ({
  open,
  onClose,
  planStart,
  planDateLabel,
  origCommitmentPeriods,
  commitmentPeriodIndex,
}: Props) => {
  const [currentPlannedSpend, setCurrentPlannedSpend] = useState<number[]>(
    origCommitmentPeriods[commitmentPeriodIndex].planned.map((i) => Math.round(i * 100) / 100)
  );
  const [growthFields, setGrowthFields] = useState<number[]>(
    new Array(origCommitmentPeriods[commitmentPeriodIndex].planned.length).fill(0)
  );
  const [disableConfirm, setDisableConfirm] = useState<boolean>(true);

  const { rampId } = useParams<{ rampId: string }>();
  const snackbar = useSnackbar();

  const clearFields = () => {
    setCurrentPlannedSpend(origCommitmentPeriods[commitmentPeriodIndex].planned.map((i) => Math.round(i * 100) / 100));
    setGrowthFields(new Array(origCommitmentPeriods[commitmentPeriodIndex].planned.length).fill(0));
  };

  const submitUpdate = async () => {
    const newCommitmentPeriods = origCommitmentPeriods;
    newCommitmentPeriods[commitmentPeriodIndex] = {
      ...origCommitmentPeriods[commitmentPeriodIndex],
      planned: currentPlannedSpend,
    };

    // submit
    const submitObj: Partial<RampModel> = { commitmentPeriods: newCommitmentPeriods };
    try {
      await getCollection(RampModel).doc(rampId).update(submitObj);
      clearFields();
      snackbar.onOpen({
        autoHideDuration: 3000,
        message: "Planned spend successfully updated",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        withClose: true,
        variant: "success",
      });
    } catch (error) {
      snackbar.onOpen({
        autoHideDuration: 3000,
        message: "Failed to update planned spend",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        withClose: true,
        variant: "error",
      });
      consoleErrorWithSentry(error);
    }
  };

  const onChangeValue = (index: number, value: number) => {
    // update corresponding growth field
    const origValue = Math.round(origCommitmentPeriods[commitmentPeriodIndex].planned[index] * 100) / 100;
    const growthCopy = new Array(...growthFields);
    growthCopy[index] = ((value - origValue) / origValue) * 100;
    setGrowthFields(growthCopy);

    // update currentPlannedSpend array
    const newSpendArray = new Array(...currentPlannedSpend);
    newSpendArray[index] = Math.round(value * 100) / 100;
    setCurrentPlannedSpend(newSpendArray);
  };
  const onChangeGrowth = (index: number, growth: number) => {
    if (isNaN(growth)) {
      return;
    }

    const newSpendArray = new Array(...currentPlannedSpend);
    const newGrowthArray = new Array(...growthFields);
    for (let offset = index; offset < newSpendArray.length; offset++) {
      const P = Math.round(origCommitmentPeriods[commitmentPeriodIndex].planned[offset] * 100) / 100;
      const R = growth / 100;
      const T = offset - index;
      newSpendArray[offset] = Math.round(P * Math.pow(1 + R, T) * 100) / 100;
      newGrowthArray[offset] = growth;
    }
    setCurrentPlannedSpend(newSpendArray);
    setGrowthFields(newGrowthArray);
  };

  // enable submit button when a change is detected
  useEffect(() => {
    const noChangeGrowth = growthFields.every((growth) => growth === 0);
    const noChangeValues = currentPlannedSpend.every(
      (cps, idx) => Math.round(origCommitmentPeriods[commitmentPeriodIndex].planned[idx] * 100) / 100 === cps
    );
    const nanExists = currentPlannedSpend.some((cps) => isNaN(cps));

    setDisableConfirm(nanExists || (noChangeValues && noChangeGrowth));
  }, [commitmentPeriodIndex, currentPlannedSpend, growthFields, origCommitmentPeriods]);

  const editPlannedForm = currentPlannedSpend.map((_datum, i) => (
    <Box key={planStart.plus({ months: i }).toFormat("MMM-yy")} sx={{ mt: 3 }}>
      <TextField
        label={planStart.plus({ months: i }).toFormat("MMMM yy")}
        type="number"
        value={currentPlannedSpend[i]}
        onChange={(e) => {
          onChangeValue(i, parseFloat(e.target.value));
        }}
        size="small"
        sx={{ width: 230 }}
        data-cy={`plannedSpendField-${i}`}
        slotProps={{
          input: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          },

          inputLabel: { shrink: true },
        }}
      />
      <TextField
        label="Growth"
        type="number"
        sx={{ ml: 1, width: 150 }}
        value={growthFields[i]}
        onChange={(e) => {
          onChangeGrowth(i, parseFloat(e.target.value));
        }}
        data-cy={`plannedGrowthField-${i}`}
        slotProps={{
          input: {
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          },

          inputLabel: { shrink: true },
        }}
      />
    </Box>
  ));
  return (
    <SimpleDialog
      open={open}
      title={`Edit planned spend (${planDateLabel})`}
      onConfirm={() => {
        mixpanel.track("customers.ramps.ramp-plan-view.edit-planned-spend.save-edit-planned-spend");
        submitUpdate().finally(() => {
          onClose();
        });
      }}
      onCancel={() => {
        clearFields();
        onClose();
      }}
      confirmButtonText="Edit planned spend"
      disableConfirm={disableConfirm}
      headingDividerVisible={true}
      headingDividerProps={{ sx: { mt: 2 } }}
      titleCy="editPlannedSpendDialog"
      confirmButtonCy="confirmPlannedSpend"
    >
      <Box sx={{ overflowY: "auto", maxHeight: 480 }}>
        <Box sx={{ mx: 3, my: 2 }}>{editPlannedForm}</Box>
      </Box>
    </SimpleDialog>
  );
};

export default EditPlannedSpendDialog;
