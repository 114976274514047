import { useCallback } from "react";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { handleSubscribe, handleUnsubscribe } from "../../../../../Pages/Customer/db";

export const FollowCustomerButton = () => {
  const { customer } = useCustomerContext({ allowNull: true });
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const memoHandleUnsubscribe = useCallback(
    (e) => {
      e.stopPropagation();
      handleUnsubscribe(customer?.id ?? "", currentUser.email);
    },
    [currentUser.email, customer?.id]
  );
  const memoHandleSubscribe = useCallback(
    (e) => {
      e.stopPropagation();
      handleSubscribe(customer?.id ?? "", currentUser.email);
    },
    [currentUser.email, customer?.id]
  );

  return (
    <Grid>
      {customer?.subscribers?.includes(currentUser.email) ? (
        <Tooltip title="Unfollow Customer">
          <IconButton onClick={memoHandleUnsubscribe}>
            <BookmarkIcon color="primary" sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Follow Customer">
          <IconButton onClick={memoHandleSubscribe}>
            <BookmarkBorderIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};
