import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";
import { Box, Button } from "@mui/material";

import { analyticsAlertText, cloudAnalyticsText } from "../../../assets/texts";
import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { useSideFiltersData } from "../../../Components/FilterTable/hook";
import {
  useTierLimitReachedAnalyticsAttributionGroups,
  useTierLimitReachedAnalyticsGovernanceBudgets,
} from "../../../Context/AnalyticsTierProvider";
import { useAuthContext } from "../../../Context/AuthContext";
import { useUserContext } from "../../../Context/UserContext";
import mixpanel from "../../../utils/mixpanel";
import { useUserEmailNotification } from "../../UserView/UserViewTabs/useUserEmailNotification";
import { AttributionSettingsModal } from "../attributions/AttributionSettingsModal";
import ShareDialog, { type ShareableEntity } from "../dialogs/shareDialog/ShareDialog";
import AssignLabelsButton from "../labels/components/AssignLabelsButton";
import { useLabels } from "../labels/hooks";
import { isEditor } from "../utilities";
import { buildFilterColumns, buildSideFilterColumns, headers } from "./AnalyticsResourcesColumns";
import { AnalyticsResourcesRow } from "./AnalyticsResourcesRow";
import { useDialogDispatchContext, useDialogStateContext } from "./Context";
import { useCloudAnalyticsResources, useDeleteByType, useShareHandlerByType } from "./hooks";
import { NewAnalyticsResourceButton } from "./NewAnalyticsResourceButton";
import { type AnalyticsResources, type AnalyticsResourcesBrowserProps } from "./types";

export const AnalyticsResourcesBrowser = ({
  reports,
  attributions,
  attributionGroups,
  alerts,
}: AnalyticsResourcesBrowserProps) => {
  const [selectedResources, setSelectedResources] = useState<AnalyticsResources[]>([]);
  const [labels, labelsLoading] = useLabels();

  const analyticsResources = useCloudAnalyticsResources({
    reports,
    attributions,
    attributionGroups,
    alerts,
  });

  const sideFilterColumns = useMemo(() => buildSideFilterColumns(labels), [labels]);
  const sideFilters = useSideFiltersData({
    data: analyticsResources,
    columns: sideFilterColumns,
    columnsForFiltering: ["Owner"],
  });
  const state = useDialogStateContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { userModel } = useUserContext({ allowNull: false });
  const dispatch = useDialogDispatchContext();
  const shareHandlerByType = useShareHandlerByType({ row: state.clickedRow });
  const deleteHandlerByType = useDeleteByType({ row: state.clickedRow });
  const { updateUserEmailNotification, userEmailNotification } = useUserEmailNotification(userModel.ref);
  const [budgetsTierLimitReached, setBudgetsTierLimitReached] = useState(false);
  const [agTierLimitReached, setAgTierLimitReached] = useState(false);
  const attributionGroupsEnabledLimitReached = useTierLimitReachedAnalyticsAttributionGroups();
  const analyticsGovernanceBudgets = useTierLimitReachedAnalyticsGovernanceBudgets();

  useEffect(() => {
    setBudgetsTierLimitReached(attributionGroupsEnabledLimitReached.limitReached ?? true);
  }, [attributionGroupsEnabledLimitReached]);

  useEffect(() => {
    setAgTierLimitReached(analyticsGovernanceBudgets.limitReached ?? true);
  }, [analyticsGovernanceBudgets]);

  useEffect(() => {
    mixpanel.track("analytics.resources.list");
  }, []);

  const RowWrapper = useCallback(
    ({ row }: { row: AnalyticsResources }) => (
      <AnalyticsResourcesRow
        row={row}
        labels={labels}
        userEmailNotification={userEmailNotification}
        updateUserEmailNotification={updateUserEmailNotification}
        budgetsTierLimitReached={budgetsTierLimitReached}
        agTierLimitReached={agTierLimitReached}
      />
    ),
    [agTierLimitReached, budgetsTierLimitReached, labels, userEmailNotification, updateUserEmailNotification]
  );

  useEffect(() => {
    const updatedSelectedResources = analyticsResources.filter((resource) =>
      selectedResources.some((x) => x.ref.id === resource.ref.id)
    );

    setSelectedResources(updatedSelectedResources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsResources]);

  const canEditSelectedResources = useMemo<boolean>(() => {
    const canEdit = selectedResources.every((res) =>
      res.contentType === "metric" ? res.data.owner === currentUser.email : isEditor(currentUser.email, res.data)
    );

    return canEdit && selectedResources.length > 0;
  }, [currentUser.email, selectedResources]);

  const history = useHistory();

  const navigateToLabelsPage = useCallback(() => {
    history.push(`./labels`);
  }, [history]);

  const filterColumns = useMemo(() => buildFilterColumns(labels), [labels]);

  if (labelsLoading) {
    return (
      <Box
        sx={{
          p: 1,
        }}
      >
        <FilterTableSkeleton />
      </Box>
    );
  }

  return (
    <>
      <FilterTable<AnalyticsResources>
        toolbarProps={{
          title: "All",
          allowToEditColumns: true,
          customSlot: (
            <>
              <NewAnalyticsResourceButton
                agCreateDisabled={agTierLimitReached}
                budgetCreateDisabled={budgetsTierLimitReached}
              />
              <Button onClick={navigateToLabelsPage}>Manage labels</Button>
              <AssignLabelsButton
                labels={labels ?? []}
                selectedResources={selectedResources}
                disabled={!canEditSelectedResources}
              />
            </>
          ),
        }}
        tableItems={analyticsResources}
        rowComponent={RowWrapper}
        headerColumns={headers}
        filterColumns={filterColumns}
        filterBarPlaceholder={cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.FILTER}
        persistenceKey="analytics_resources_table"
        itemUniqIdentifierField="id"
        defaultSortingColumnValue="data.timeModified"
        defaultSortDirection="desc"
        sideFilters={sideFilters}
        sideFilterColumns={sideFilterColumns}
        showRowsSelection
        mixpanelEventNames={{
          sideFilter: "analytics.resources.sideFilters",
          searchBarFilter: "analytics.resources.filterBar",
        }}
        reverseOperatorPrecedence
        onRowsSelected={setSelectedResources}
      />
      {state.dialogType === "share" && state.cloudResourceType && (
        <ShareDialog
          open={state.dialogType === "share"}
          title={state.dialogTitle || ""}
          onClose={() => {
            dispatch({ type: "close" });
          }}
          entity={state.cloudResourceType}
          handleChangeSharing={shareHandlerByType[state.cloudResourceType]}
          loading={state.isLoading}
          shareEntities={[state.clickedRow?.data as ShareableEntity]}
          {...state.additionalDialogProps}
        />
      )}
      {state.dialogType === "delete" && state.cloudResourceType && (
        <DeleteDialog
          open={state.dialogType === "delete"}
          title={analyticsAlertText.DELETE_SELECTED}
          message={analyticsAlertText.DELETE_MESSAGE}
          onDelete={deleteHandlerByType[state.cloudResourceType]}
          onClose={() => {
            dispatch({ type: "close" });
          }}
        />
      )}
      {state.dialogType === "settings" && state.cloudResourceType && (
        <AttributionSettingsModal
          onClose={() => {
            dispatch({ type: "close" });
          }}
          attributionId={state.clickedRow?.id ?? ""}
        />
      )}
    </>
  );
};

export default AnalyticsResourcesBrowser;
