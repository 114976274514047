import { type Dispatch, type SetStateAction } from "react";

import { Renderer } from "@doitintl/cmp-models";
import DownloadIcon from "@mui/icons-material/SaveAlt";
import { IconButton, MenuItem, Popover, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { tooltipText } from "../../../../assets/texts";
import { type ReportWSnap } from "../../../../types";
import { useReportConfig, useReportExportContext } from "../../Context";
import {
  ChartsDownloadOptions,
  exportDownloadOptions,
  ExportOptions,
  ExportType,
  notifyMixpanelOnExport,
} from "../../utilities";

type props = {
  exportAnchorEl;
  report: ReportWSnap;
  setExportAnchorEl: Dispatch<SetStateAction<any>>;
  setGoogleDialogOpen: Dispatch<SetStateAction<boolean>>;
  reportError;
  wasRun: boolean;
};

const ExportMenuButton = ({
  exportAnchorEl,
  setExportAnchorEl,
  report,
  setGoogleDialogOpen,
  wasRun,
  reportError,
}: props) => {
  const { reportConfig } = useReportConfig();
  const { handleDownload, handleCSVExport, handleCSVCopyToClipboard } = useReportExportContext();
  const { renderer } = reportConfig;
  return (
    <Grid>
      <Tooltip title={tooltipText.REPORT_EXPORT_BUTTON}>
        <span>
          <IconButton
            size="small"
            aria-owns={exportAnchorEl ? "export-menu" : undefined}
            aria-haspopup="true"
            disabled={!wasRun || !!reportError}
            onClick={(event) => {
              setExportAnchorEl(event.currentTarget);
            }}
          >
            <DownloadIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        id="export-menu"
        anchorEl={exportAnchorEl}
        open={Boolean(exportAnchorEl)}
        onClose={() => {
          setExportAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          key={ExportOptions.csv_export.value}
          value={ExportOptions.csv_export.value}
          dense
          onClick={() => {
            setExportAnchorEl(null);
            notifyMixpanelOnExport(report.snapshot.id, ExportType.CSV);
            handleCSVExport();
          }}
        >
          {ExportOptions.csv_export.label}
        </MenuItem>
        <MenuItem
          key={ExportOptions.csv_clipboard.value}
          value={ExportOptions.csv_clipboard.value}
          dense
          onClick={() => {
            setExportAnchorEl(null);
            notifyMixpanelOnExport(report.snapshot.id, ExportType.CLIPBOARD);
            handleCSVCopyToClipboard();
          }}
        >
          {ExportOptions.csv_clipboard.label}
        </MenuItem>
        <MenuItem
          key={ExportOptions.sheets_export.value}
          value={ExportOptions.sheets_export.value}
          dense
          onClick={() => {
            setExportAnchorEl(null);
            notifyMixpanelOnExport(report.snapshot.id, ExportType.GOOGLE_SHEETS);
            setGoogleDialogOpen(true);
          }}
        >
          {ExportOptions.sheets_export.label}
        </MenuItem>
        {![Renderer.TABLE, Renderer.HEATMAP, Renderer.ROW_HEATMAP, Renderer.COL_HEATMAP].includes(renderer) && (
          <>
            <MenuItem
              key={ChartsDownloadOptions.png_download.value}
              value={ChartsDownloadOptions.png_download.value}
              dense
              onClick={() => {
                setExportAnchorEl(null);
                notifyMixpanelOnExport(report.snapshot.id, ExportType.PNG);
                handleDownload(exportDownloadOptions.PNG_DOWNLOAD);
              }}
            >
              {ChartsDownloadOptions.png_download.label}
            </MenuItem>
          </>
        )}
        <MenuItem
          key={ExportOptions.csv_raw_export.value}
          value={ExportOptions.csv_raw_export.value}
          dense
          onClick={() => {
            setExportAnchorEl(null);
            notifyMixpanelOnExport(report.snapshot.id, ExportType.CSV_RAW);
            handleCSVExport(true);
          }}
        >
          {ExportOptions.csv_raw_export.label}
        </MenuItem>
      </Popover>
    </Grid>
  );
};

export default ExportMenuButton;
