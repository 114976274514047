import { type JSX } from "react";

import { Box } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { type InstanceTypeDetails } from "../../../types";
import { getInstanceTypeFamily } from "../../../Utils/instanceTypeUtils";
import { EditList } from "./EditList";

type Props = {
  allowEdit: boolean;
  selectedInstanceTypes: string[];
  allInstanceTypes: string[];
  instanceTypesDetails: Record<string, InstanceTypeDetails>;
  onSelectionChange: (selectedItems?: string[]) => void;
  onHeightChange: () => void;
};

export const EditInstanceTypes = ({
  allowEdit,
  selectedInstanceTypes,
  allInstanceTypes,
  instanceTypesDetails,
  onSelectionChange,
  onHeightChange,
}: Props) => {
  const renderInstanceTypesOptionDetails = (instanceType: string): JSX.Element | undefined => {
    const details = instanceTypesDetails?.[instanceType];
    if (details) {
      return (
        <Box>
          <Box>{instanceType}</Box>
          <Box
            sx={{
              display: "flex",
              fontSize: "body2.fontSize",
              color: "text.secondary",
            }}
          >
            <Box
              sx={{
                width: 80,
              }}
            >
              {details.compute}vCPU
            </Box>
            <Box>{details.memory} GiB Memory</Box>
          </Box>
        </Box>
      );
    }
  };

  const getTooltipString = (selectedItems: string[]): string =>
    selectedItems
      .map((instance) => {
        const details = instanceTypesDetails?.[instance];
        return details ? `${instance} - ${details.compute}vCPU ${details.memory}GiB Memory` : "";
      })
      .join("\n");

  const instanceTypeFamilies = allInstanceTypes.reduce<Record<string, string>>((result, key) => {
    result[key] = getInstanceTypeFamily(key);
    return result;
  }, {});

  // sort by family is required for groupBy
  const optionsItems = [...allInstanceTypes].sort((a, b) =>
    instanceTypeFamilies[a] > instanceTypeFamilies[b] ? 1 : -1
  );

  // enables filtering autocomplete by instance type family
  const filterOptions = createFilterOptions({
    stringify: (option: string) => option + instanceTypeFamilies[option],
  });

  return (
    <EditList
      title="Instance Types"
      optionsItems={optionsItems}
      groupBy={getInstanceTypeFamily}
      filterOptions={filterOptions}
      selectedItems={selectedInstanceTypes}
      onEditModeChange={onSelectionChange}
      allowEdit={allowEdit}
      onChange={onHeightChange}
      renderOptionDetails={renderInstanceTypesOptionDetails}
      getTooltipString={getTooltipString}
    />
  );
};
