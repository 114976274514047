import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import {
  AlertCondition,
  type AnalyticsDataSource,
  CurrenciesMap,
  type CurrencyCode,
  type CurrencyCodes,
  type DatahubMetrics,
  type ExtendedMetric,
  Metadata,
  Metric,
  MetricFilterOperator,
  TimeInterval,
} from "@doitintl/cmp-models";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { analyticsAlertText } from "../../../../assets/texts";
import { AlertsTxt } from "../../../../assets/texts/CloudAnalytics";
import { helpURLs } from "../../../../assets/urls";
import { DimensionSelector } from "../../../../Components/Selects/CloudAnalytics/DimensionSelector";
import MetricSelect from "../../../../Components/Selects/CloudAnalytics/MetricSelect";
import { type MetadataOption, type MetricWSnap } from "../../../../types";
import { onKeyPressPreventNonNumericAllowFloatNegative } from "../../../../utils/common";
import { textFieldBaseProps, textFieldSelectProps } from "../../budgets/shared";
import {
  AlertConditionOptions,
  CurrencyOptions,
  getCurrencyOption,
  MetricFiltersOptions,
  timeIntervalOptions,
} from "../../utilities";
import { type IgnoreValuesRangeForStepper } from "../AnalyticsAlertStepper";

type ConditionStepProps = {
  alertValue: number | "";
  calculatedMetric: MetricWSnap | null;
  condition: AlertCondition;
  currency: CurrencyCode;
  evaluateByDimension: MetadataOption | null;
  dataSource: AnalyticsDataSource;
  datahubMetrics: DatahubMetrics[];
  dimensions: MetadataOption[];
  extendedMetric: string | null;
  extendedMetrics: ExtendedMetric[];
  infoPanel: JSX.Element;
  isCurrentUserEditor: boolean;
  metric: Metric;
  operator: MetricFilterOperator;
  usingIgnoreValues: boolean;
  ignoreValuesRange: IgnoreValuesRangeForStepper | null;
  setAlertEdited: (alertEdited: boolean) => void;
  setAlertValue: (alertValue: number | "") => void;
  setCalculatedMetric: (calculatedMetric: MetricWSnap | null) => void;
  setCondition: (condition: AlertCondition) => void;
  setCurrency: (currency: CurrencyCode) => void;
  setUsingIgnoreValues: (ignore: boolean) => void;
  setIgnoreValuesRange: (values: IgnoreValuesRangeForStepper) => void;
  setEvaluateByDimension: (evaluateByDimension: MetadataOption | null) => void;
  setExtendedMetric: (extendedMetric: string | null) => void;
  setMetric: (metric: Metric) => void;
  setOperator: (operator: MetricFilterOperator) => void;
  setTimeInterval: (timeInterval: TimeInterval) => void;
  timeInterval: TimeInterval;
};

export const getBounds = (bound: "upper" | "lower", value: string, ignoreValuesRange: IgnoreValuesRangeForStepper) => {
  const { lowerBound, upperBound } = ignoreValuesRange;
  const valueAsNum = Number(value);
  const lowerBoundAsNum = Number(lowerBound);
  const upperBoundAsNum = Number(upperBound);

  if (isNaN(valueAsNum) && value !== "-") {
    return ignoreValuesRange;
  }

  if (bound === "lower") {
    if (!isNaN(upperBoundAsNum) && valueAsNum > upperBoundAsNum) {
      return {
        lowerBound: upperBound,
        upperBound,
      };
    } else {
      return {
        lowerBound: value,
        upperBound,
      };
    }
  } else {
    if (!isNaN(lowerBoundAsNum) && valueAsNum < lowerBoundAsNum) {
      return {
        lowerBound,
        upperBound: lowerBound,
      };
    } else {
      return {
        lowerBound,
        upperBound: value,
      };
    }
  }
};

export const ConditionStep = ({
  alertValue,
  calculatedMetric,
  condition,
  currency,
  evaluateByDimension,
  datahubMetrics,
  dataSource,
  dimensions,
  extendedMetric,
  extendedMetrics,
  infoPanel,
  isCurrentUserEditor,
  metric,
  operator,
  setAlertEdited,
  setAlertValue,
  setCalculatedMetric,
  setCondition,
  setCurrency,
  setEvaluateByDimension,
  setExtendedMetric,
  setMetric,
  setOperator,
  setTimeInterval,
  timeInterval,
  usingIgnoreValues,
  setUsingIgnoreValues,
  ignoreValuesRange,
  setIgnoreValuesRange,
}: ConditionStepProps) => {
  const [valueSymbol, setValueSymbol] = useState<string>("");

  const showCurrencyField = useMemo(() => {
    if (metric === Metric.COST || metric === Metric.SAVINGS) {
      return true;
    }

    if (metric === Metric.EXTENDED && extendedMetric) {
      const isDataHubMetric = datahubMetrics.some((m) => m.key === extendedMetric);
      if (isDataHubMetric) {
        return false;
      }

      const extMetric = extendedMetrics.find((m) => m.key === extendedMetric);
      return extMetric?.type === "cost";
    }

    return false;
  }, [datahubMetrics, extendedMetric, extendedMetrics, metric]);

  useEffect(() => {
    if (condition === AlertCondition.PERCENTAGE) {
      setValueSymbol("%");
    } else if (showCurrencyField) {
      setValueSymbol(CurrenciesMap[currency]);
    } else {
      setValueSymbol("");
    }
  }, [condition, currency, showCurrencyField]);

  const filteredDimensions = useMemo(() => {
    if (dimensions.length > 0) {
      return dimensions.filter(
        (dimension) =>
          dimension.data.type !== Metadata.GKE_LABEL &&
          dimension.data.type !== Metadata.GKE &&
          dimension.id !== `${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}`
      );
    } else {
      return [];
    }
  }, [dimensions]);

  const selectMenuItem = (value, label) => (
    <MenuItem key={value} value={value} dense>
      {label}
    </MenuItem>
  );

  const handleIgnoreValuesRange = (value: boolean) => {
    setUsingIgnoreValues(value);
  };

  const handleMetricSelect = (metricVal: Metric, value: MetricWSnap | string) => {
    setMetric(metricVal);
    setCalculatedMetric(null);
    setExtendedMetric(null);
    if (metricVal === Metric.EXTENDED && typeof value === "string") {
      setExtendedMetric(value);
    } else if (metricVal === Metric.CALCULATED && typeof value === "object") {
      setCalculatedMetric(value);
    }
  };

  const onChangeBound = useCallback(
    (event) => {
      const { value, name } = event.target;
      if (ignoreValuesRange) {
        setIgnoreValuesRange(getBounds(name, value, ignoreValuesRange));
      }
    },
    [ignoreValuesRange, setIgnoreValuesRange]
  );

  return (
    <Box key="stepConditions">
      <Grid
        container
        key="grid-key"
        sx={{
          mt: "2.5rem",
        }}
      >
        {infoPanel}
        <Grid sx={{ mb: "1rem" }} size={12}>
          <Typography>{analyticsAlertText.STEPPER.ALERT_ME_WHEN}</Typography>
        </Grid>
        <Grid sx={{ mb: "2.5rem" }} size={12}>
          <MetricSelect
            datahubMetrics={datahubMetrics}
            handleMetricSelect={handleMetricSelect}
            extendedMetrics={extendedMetrics}
            isCSP={false}
            selectedMetric={metric}
            selectedCalculatedMetric={calculatedMetric}
            selectedExtendedMetric={extendedMetric}
            size="medium"
            dataSource={dataSource}
            disabled={!isCurrentUserEditor}
          />
        </Grid>
        {showCurrencyField && (
          <Grid sx={{ mb: "2.5rem" }} size={12}>
            <Autocomplete
              disabled={!isCurrentUserEditor}
              onChange={(_, val) => {
                if (val) {
                  setCurrency(val.split(" ")[0] as CurrencyCodes);
                  setAlertEdited(true);
                }
              }}
              options={CurrencyOptions.map((c) => getCurrencyOption(c))}
              value={getCurrencyOption(currency)}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={analyticsAlertText.STEPPER.CURRENCY}
                  placeholder={analyticsAlertText.STEPPER.SELECT_CURRENCY}
                  fullWidth
                  size="medium"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid container sx={{ mb: "2.5rem" }} size={12}>
          <Grid sx={{ pr: "1rem" }} size={6}>
            <TextField
              disabled={!isCurrentUserEditor}
              size="medium"
              label={analyticsAlertText.STEPPER.TIME_INTERVAL}
              value={timeInterval}
              {...textFieldBaseProps}
              {...textFieldSelectProps}
              onChange={(event) => {
                setAlertEdited(true);
                if (event.target.value === TimeInterval.DAY && condition === AlertCondition.FORECAST) {
                  setCondition(AlertCondition.VALUE);
                }
                setTimeInterval(event.target.value as TimeInterval);
              }}
            >
              {timeIntervalOptions
                .filter(
                  (o) =>
                    o.value !== TimeInterval.HOUR &&
                    (o.value !== TimeInterval.DAY || condition !== AlertCondition.FORECAST)
                )
                .map((o) => selectMenuItem(o.value, o.alternateLabel))}
            </TextField>
          </Grid>
          <Grid size={6}>
            <TextField
              disabled={!isCurrentUserEditor}
              size="medium"
              label={analyticsAlertText.STEPPER.CONDITION}
              value={condition}
              {...textFieldBaseProps}
              {...textFieldSelectProps}
              onChange={(event) => {
                setAlertEdited(true);
                if (event.target.value === AlertCondition.FORECAST && timeInterval === TimeInterval.DAY) {
                  setTimeInterval(TimeInterval.WEEK);
                }
                setCondition(event.target.value as AlertCondition);
              }}
            >
              {AlertConditionOptions.map((o) => selectMenuItem(o.value, o.label))}
            </TextField>
          </Grid>
        </Grid>
        {timeInterval === TimeInterval.DAY && (
          <Grid sx={{ mb: "2.5rem" }} size={12}>
            <Alert severity="warning">
              <Link target="_blank" rel="noopener noreferrer" href={helpURLs.CLOUD_ANALYTICS_ALERTS_CREATE}>
                {analyticsAlertText.STEPPER.DAILY_ALERT_WARNING}
              </Link>
            </Alert>
          </Grid>
        )}
        <Grid container sx={{ mb: "2.5rem" }} size={12}>
          <Grid sx={{ pr: "1rem" }} size={6}>
            <TextField
              disabled={!isCurrentUserEditor}
              size="medium"
              label={analyticsAlertText.STEPPER.OPERATOR}
              value={operator}
              {...textFieldBaseProps}
              {...textFieldSelectProps}
              onChange={(event) => {
                setAlertEdited(true);
                setOperator(event.target.value as MetricFilterOperator);
              }}
            >
              {MetricFiltersOptions.filter(
                (o) => o.value === MetricFilterOperator.GREATER_THAN || o.value === MetricFilterOperator.LESS_THAN
              ).map((o) => selectMenuItem(o.value, o.label))}
            </TextField>
          </Grid>
          <Grid size={6}>
            <TextField
              required
              disabled={!isCurrentUserEditor}
              size="medium"
              onKeyPress={onKeyPressPreventNonNumericAllowFloatNegative}
              label={analyticsAlertText.STEPPER.VALUE}
              value={alertValue}
              {...textFieldBaseProps}
              onChange={(event) => {
                setAlertEdited(true);
                setAlertValue(event.target.value === "" ? "" : (event.target.value as unknown as number));
              }}
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">{valueSymbol}</InputAdornment>,
                },
              }}
            />
          </Grid>
        </Grid>
        {condition === AlertCondition.PERCENTAGE && (
          <Grid container size={12}>
            <Grid sx={usingIgnoreValues ? {} : { mb: "2.5rem" }} size={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isCurrentUserEditor}
                    size="small"
                    name="defaultValue"
                    onChange={(event) => {
                      handleIgnoreValuesRange(event.target.checked);
                    }}
                    checked={usingIgnoreValues}
                    color="primary"
                  />
                }
                label={<Typography variant="caption">{analyticsAlertText.STEPPER.IGNORE_VALUES_BETWEEN}</Typography>}
              />
            </Grid>
          </Grid>
        )}
        {usingIgnoreValues && condition === AlertCondition.PERCENTAGE && (
          <Grid container sx={{ mb: "2.5rem" }} size={12}>
            <Grid sx={{ pr: "1rem" }} size={6}>
              <TextField
                required
                name="lower"
                disabled={!isCurrentUserEditor}
                onKeyPress={onKeyPressPreventNonNumericAllowFloatNegative}
                size="medium"
                label={analyticsAlertText.LOWER_BOUND}
                value={ignoreValuesRange?.lowerBound}
                onChange={onChangeBound}
                {...textFieldBaseProps}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">{CurrenciesMap[currency]}</InputAdornment>,
                    inputProps: { max: ignoreValuesRange?.upperBound },
                  },
                }}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                required
                disabled={!isCurrentUserEditor}
                onKeyPress={onKeyPressPreventNonNumericAllowFloatNegative}
                size="medium"
                name="upper"
                label={analyticsAlertText.UPPER_BOUND}
                value={ignoreValuesRange?.upperBound}
                {...textFieldBaseProps}
                onChange={onChangeBound}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">{CurrenciesMap[currency]}</InputAdornment>,
                    inputProps: { min: ignoreValuesRange?.lowerBound },
                  },
                }}
              />
            </Grid>
          </Grid>
        )}
        {condition !== AlertCondition.FORECAST && (
          <Grid sx={{ mb: "2.5rem" }} size={12}>
            <DimensionSelector
              handleSelectOption={setEvaluateByDimension}
              selectedDimension={evaluateByDimension}
              dimensions={filteredDimensions}
              onChange={() => {
                setAlertEdited(true);
              }}
              basicAutoCompleteProps={{
                openOnFocus: true,
              }}
              disabled={!isCurrentUserEditor}
              textFieldProps={{
                label: AlertsTxt.EVALUATE_FOR_EACH,
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
