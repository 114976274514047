import { useCallback, useEffect, useState } from "react";

import { type AccountManagerModel, type AccountManagersCompany } from "@doitintl/cmp-models";
import { getBatch, type ModelIdRef } from "@doitintl/models-firestore";
import { Box, Card, CardContent, CardHeader, Stack, Tooltip, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import { useApiContext } from "../../api/context";
import { globalText } from "../../assets/texts";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { useCustomerContext } from "../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../utils";
import { companiesLookup } from "../../utils/common";
import { useAccountTeam } from "../hooks/useAccountTeam";
import { Loader } from "../Loader";
import LoadingButton from "../LoadingButton";
import AccountManagerRow from "./AccountManagerRow";
import { addToLegacyAccountManagerStructure, companiesToList, removeFromLegacyAccountManagerStructure } from "./utils";
import type { OptionalAmWithIndex } from "./utils";

const AccountManagersForm = () => {
  const { customer } = useCustomerContext();
  const { accountTeam } = useAccountTeam({ customer });
  const api = useApiContext();
  const [allAccountManagers] = AccountManagersHooks.useAllAccountManagers();
  const [accountManagers, setAccountManagers] = useState<OptionalAmWithIndex[]>([]); // AM plus empty row for each company
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [isFormNotChanged, setIsFormNotChanged] = useState<boolean>(true);

  useEffect(() => {
    const emptyRows = Object.keys(companiesLookup).map((company) => ({
      company: company as AccountManagersCompany,
      supportNotificationLevel: 0,
    }));

    const savedAccountTeam = cloneDeep(accountTeam);
    const accountTeamWithId = [...savedAccountTeam, ...emptyRows].map((am, index) => ({ ...am, index }));
    setAccountManagers(accountTeamWithId);
  }, [accountTeam, allAccountManagers, customer.enrichment?.geo?.countryCode]);

  useEffect(() => {
    if (!accountManagers.length) {
      return;
    }

    Object.keys(companiesLookup).forEach((company) => {
      const hasEmptyRow = accountManagers.some((am) => am.company === company && !am.ref);
      if (!hasEmptyRow) {
        // add empty row if previous empty row was filled
        setAccountManagers([
          ...accountManagers,
          { company: company as AccountManagersCompany, supportNotificationLevel: 0, index: accountManagers.length },
        ]);
      }
    });
  }, [accountManagers]);

  useEffect(() => {
    const updatedAccountManagers = accountManagers.flatMap((am) => {
      if (!am.ref) {
        return [];
      }

      return [
        {
          company: am.company,
          refId: am.ref.id,
          supportNotificationLevel: am.supportNotificationLevel ?? 0,
        },
      ];
    });

    setIsFormNotChanged(
      isEqual(
        accountTeam.map((am) => ({
          company: am.company,
          supportNotificationLevel: am.supportNotificationLevel,
          refId: am.ref.id,
        })),
        updatedAccountManagers
      )
    );
  }, [accountManagers, accountTeam]);

  const updateSupportNotificationLevel = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
      setAccountManagers(
        accountManagers.map((am) => {
          if (am.index === index) {
            am.supportNotificationLevel = parseInt(event.target.value);
          }
          return am;
        })
      );
    },
    [accountManagers]
  );

  const handleChange = useCallback(
    (accountManager: ModelIdRef<AccountManagerModel> | null, index: number) => {
      setAccountManagers(
        accountManagers.map((am) =>
          am.index === index
            ? {
                company: am.company,
                ref: accountManager ? accountManager.ref : undefined,
                supportNotificationLevel: am.supportNotificationLevel ?? 0,
                index,
              }
            : am
        )
      );
    },
    [accountManagers]
  );

  const handleSave = useCallback(async (): Promise<void> => {
    setFormLoading(true);
    try {
      const accountManagersToSave = accountManagers.flatMap((am) => {
        if (!am.ref) {
          return [];
        }

        return [
          {
            company: am.company,
            ref: am.ref,
            supportNotificationLevel: am.supportNotificationLevel ?? 0,
          },
        ];
      });
      const batch = getBatch();
      batch.update(customer.ref, { accountTeam: accountManagersToSave });
      removeFromLegacyAccountManagerStructure(accountManagersToSave, batch, customer);

      const needToRemove = accountTeam.filter((a) => accountManagersToSave.every((v) => v.ref.id !== a.ref.id));
      const emailsToRemove = needToRemove.map((a) => allAccountManagers?.find((am) => am.id === a.ref.id)?.email);
      await Promise.all([
        addToLegacyAccountManagerStructure(accountManagersToSave, batch, customer, allAccountManagers ?? []),
      ]);
      await batch.commit();
      await api.request({
        method: "post",
        url: `/support/customers/${customer.ref.id}/remove-cc`,
        data: { emails: emailsToRemove },
      });
    } catch (e) {
      consoleErrorWithSentry(e);
    } finally {
      setFormLoading(false);
    }
  }, [allAccountManagers, accountTeam, api, customer, accountManagers]);

  return (
    <Loader loading={!allAccountManagers?.length}>
      <Stack
        sx={{
          alignItems: "center",
        }}
      >
        <Card sx={{ minWidth: 275, border: "none", height: "100vh" }}>
          <CardHeader id="dialog-title" titleTypographyProps={{ variant: "h1" }} title="Account Managers" />
          <CardContent>
            {Object.keys(companiesLookup)
              .filter((company) => companiesToList.includes(company as AccountManagersCompany))
              .map((company) => {
                const companyAMs = accountManagers.filter((am) => am.company === company);

                return (
                  <Box sx={{ mb: "15px" }} key={company}>
                    {companyAMs.length > 0 && (
                      <>
                        <Stack
                          direction="row"
                          sx={{
                            alignItems: "center",
                          }}
                        >
                          <CardMedia
                            sx={{ width: "30px", height: "30px", mr: 1 }}
                            image={companiesLookup[company].icon}
                            title={companiesLookup[company].name}
                          />
                          <Typography variant="subtitle1">{companiesLookup[company].name}</Typography>
                        </Stack>
                        <Divider sx={{ mt: 0.25, mb: 1 }} />
                      </>
                    )}
                    {companyAMs.map((am, index) => (
                      <AccountManagerRow
                        key={am?.ref?.id || index}
                        handleChange={handleChange}
                        updateSupportNotificationLevel={updateSupportNotificationLevel}
                        accountManager={am}
                        company={company as AccountManagersCompany}
                        formLoading={formLoading}
                        accountManagers={accountManagers}
                      />
                    ))}
                  </Box>
                );
              })}
          </CardContent>
        </Card>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "background.default",
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            width: "105%",
            display: "flex",
            justifyContent: "center",
            paddingY: "4px",
            zIndex: 1000,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", paddingY: 1, width: "770px" }}>
            <Tooltip arrow placement="top" title={isFormNotChanged ? globalText.NO_CHANGES : ""}>
              <span>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  disabled={formLoading || isFormNotChanged}
                  loading={formLoading}
                  data-cy="save-account-manager"
                  mixpanelEventId="account-manager.save"
                >
                  Save
                </LoadingButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Stack>
    </Loader>
  );
};

export default AccountManagersForm;
