import { type ChangeEvent } from "react";

import { Stack, TextField } from "@mui/material";

import { INVALID_DATE_ERROR_MSG, isDateBefore, validateDate } from "./dateRangeUtils";

type DateRangeInputProps = {
  start: string;
  end: string;
  onStartDateChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onEndDateChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};
const DateRangeInput = ({ start, end, onStartDateChange, onEndDateChange }: DateRangeInputProps) => {
  const startDateValidation = validateDate(start);
  const endDateValidation = validateDate(end);
  const isDateInOrder = isDateBefore(startDateValidation.date, endDateValidation.date);
  const startErr = !startDateValidation.isValid || !isDateInOrder;
  const endErr = !endDateValidation.isValid || !isDateInOrder;
  return (
    <Stack
      direction="row"
      spacing={4}
      sx={{
        justifyContent: "space-between",
        px: "1rem",
        py: 3,
        height: "90px",
      }}
    >
      <TextField
        data-cy="start-date-input-container"
        error={startErr}
        fullWidth
        helperText={startErr ? INVALID_DATE_ERROR_MSG : ""}
        label="From"
        onChange={onStartDateChange}
        value={start}
        slotProps={{
          htmlInput: { "data-cy": "start-date-input" },
        }}
      />
      <TextField
        data-cy="end-date-input-container"
        error={endErr}
        fullWidth
        helperText={endErr ? INVALID_DATE_ERROR_MSG : ""}
        label="To"
        onChange={onEndDateChange}
        value={end}
        slotProps={{
          htmlInput: { "data-cy": "end-date-input" },
        }}
      />
    </Stack>
  );
};

export default DateRangeInput;
