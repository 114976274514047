import { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { queryKeys } from "../../../constants";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { handleBackClick } from "../../../utils/navigation";
import { createInsight as postInsight, useInsights } from "../api";
import { useMdxContent } from "../hooks";
import { type CustomProblems, type PotentialDailySavings } from "../types";

const providers = [
  { label: "AWS", value: "amazon-web-services" },
  { label: "Google Cloud", value: "google-cloud" },
  { label: "Azure", value: "microsoft-azure" },
  { label: "Snowflake", value: "snowflake" },
];

const valueToProviderTag = {
  "amazon-web-services": "aws",
  "google-cloud": "gcp",
  "microsoft-azure": "azure",
  snowflake: "snowflake",
};

const providerTagToValue = {
  aws: "amazon-web-services",
  gcp: "google-cloud",
  azure: "microsoft-azure",
  snowflake: "snowflake",
};

const categories = ["FinOps", "Operational excellence", "Reliability", "Performance efficiency", "Sustainability"];

const timeIntervals = [
  { label: "per day", value: "per-day" },
  { label: "per month", value: "per-month" },
  { label: "per year", value: "per-year" },
];

const savingsDividerByTime = {
  "per-day": 1,
  "per-month": 30.4,
  "per-year": 365,
};

const emptyMonetaryOutcome = { savings: null, time: "per-month" };

type Product = {
  platform: string;
  id: string;
  name: string;
};

export const NewInsight = () => {
  const [products, setProducts] = useState<Product[]>([]);

  const { insights } = useInsights();

  const { insightKey } = useParams<{ insightKey: string }>();

  useEffect(() => {
    getCollection(AppModel)
      .doc("support")
      .collection("services")
      .where("blacklisted", "==", false)
      .get()
      .then((platforms) => {
        setProducts(platforms.docs.map((platform) => platform.data()));
      });
  }, []);

  const { customer } = useCustomerContext();
  const history = useHistory();

  const showSuccess = useSuccessSnackbar();
  const showError = useErrorSnackbar();

  const api = useApiContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const [selectedProduct, setSelectedProduct] = useState<Omit<Product, "platform"> | null>(null);

  const [title, setTitle] = useState<string>("");
  const [summary, setSummary] = useState<string>("");

  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const [tab, selectTab] = useState("edit-tab");
  const [description, setDescription] = useState("");

  const insightDescription = useMdxContent(description ?? "");

  const [reportUrl, setReportUrl] = useState("");

  const [monetaryOutcome, setMonetaryOutcome] = useState<{ savings: number | null; time: string } | null>(null);
  const [nonMonetaryOutcomes, setNonMonetaryOutcomes] = useState<{ value: string; description: string }[]>([]);

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const removeMonetaryOutcome = () => {
    setMonetaryOutcome(null);
  };

  const addMonetaryOutcome = () => {
    setMonetaryOutcome(emptyMonetaryOutcome);
  };

  useEffect(() => {
    if (insightKey) {
      const insight = insights?.find((insight) => insight.key === insightKey && insight.providerId === "custom");

      if (insight) {
        setTitle(insight.title);
        setDescription(insight.detailedDescriptionMdx);
        setSummary(insight.shortDescription);
        setReportUrl(insight.reportUrl);
        setSelectedCategory(insight.categories?.[0]);
        setSelectedProvider(providerTagToValue[insight.cloudTags[0]]);
        setSelectedProduct(products.find((product) => product.id === insight.supportCategory) || null);

        const otherOutcomes = insight?.results?.customProblems?.breakdown?.data?.map((outcome) => ({
          value: outcome.value.toString(),
          description: outcome.dimensionValues[0],
        }));
        setNonMonetaryOutcomes(otherOutcomes || []);

        const savings = insight?.results?.potentialDailySavings?.breakdown?.data[0].value || null;
        setMonetaryOutcome({
          savings,
          time: "per-day",
        });
      }
    }
  }, [insightKey, insights, products]);

  const monetaryOutcomeInvalid = monetaryOutcome ? monetaryOutcome.savings === null : false;

  const nonMonetaryOutcomesInvalid = !nonMonetaryOutcomes?.every((outcome) => outcome.description && outcome.value);

  const disabled =
    !title ||
    !description ||
    !summary ||
    !selectedProvider ||
    !selectedProduct ||
    !selectedCategory ||
    !(monetaryOutcome || nonMonetaryOutcomes.length) ||
    monetaryOutcomeInvalid ||
    nonMonetaryOutcomesInvalid;

  const handleChange = (index) => (event) => {
    const newStrings = [...nonMonetaryOutcomes];
    newStrings[index] = { ...newStrings[index], description: event.target.value };
    setNonMonetaryOutcomes(newStrings);
  };

  const handleChangeValue = (index) => (event) => {
    const newStrings = [...nonMonetaryOutcomes];
    newStrings[index] = { ...newStrings[index], value: event.target.value };
    setNonMonetaryOutcomes(newStrings);
  };

  const createInsight = async () => {
    queryClient.invalidateQueries([customer.id, queryKeys.insights]);

    let potentialDailySavings: PotentialDailySavings | null = null;

    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (monetaryOutcome && monetaryOutcome.savings) {
      const value = monetaryOutcome.savings / savingsDividerByTime[monetaryOutcome.time];

      potentialDailySavings = {
        isOptimized: false,
        breakdown: {
          dimensions: ["Savings"],
          data: [{ value, baseValue: 0, dimensionValues: ["Savings"] }],
        },
      };
    }

    let customProblems: CustomProblems | null = null;

    if (nonMonetaryOutcomes.length) {
      const customProblemsBreakdown = nonMonetaryOutcomes.map((outcome) => ({
        dimensionValues: [outcome.description],
        value: parseInt(outcome.value),
        baseValue: 0,
      }));

      customProblems = {
        isOptimized: false,
        unitLongSingular: "Other impacts",
        unitLongPlural: "Other impacts",
        unitShortSingular: "Other impacts value",
        unitShortPlural: "Other impacts value",
        breakdown: {
          dimensions: ["Outcome"],
          data: customProblemsBreakdown,
        },
      };
    }

    const insightKey = title.toLowerCase().replace(/ /g, "-");

    const insight = {
      providerID: "custom",
      results: [
        {
          key: insightKey,
          canBeIndividuallyExecuted: false,
          title,
          shortDescription: summary,
          detailedDescriptionMdx: description,
          status: "success",
          customerId: customer.id,
          cloudTags: [valueToProviderTag[selectedProvider!]],
          categories: [selectedCategory],
          isInternal: false,
          supportCategory: selectedProduct!.id,
          reportUrl,
          customInsightAttributes: {
            published: DateTime.fromJSDate(new Date()).toISO(),
            publishingUserEmail: currentUser.email,
            showPublishingUser: false,
          },
          results: {
            isRelevant: true,
            potentialDailySavings,
            customProblems,
          },
        },
      ],
    };

    try {
      setLoading(true);

      const { data: results } = await postInsight({ api, insight });
      queryClient.invalidateQueries([customer.id, queryKeys.insights]);

      const anyErrors = results.some((result) => result.error);

      const newInsightUrl = `/customers/${customer.id}/insights/${insight.providerID}/${insightKey}`;

      if (anyErrors) {
        showError("An error occurred while creating the insight");
      } else {
        showSuccess("Insight created successfully");
        history.push(newInsightUrl);
      }
    } catch (error) {
      showError("An error occurred while creating the insight");
    } finally {
      setLoading(false);
    }
  };

  const productsByProvider =
    selectedProvider === "snowflake"
      ? [{ name: "Snowflake", id: "Snowflake" }]
      : products.filter((product) => product.platform === selectedProvider);

  const reportUrlInvalid =
    Boolean(reportUrl) && !reportUrl.startsWith(`https://console.doit.com/customers/${customer.id}/analytics/reports`);

  return (
    <>
      <CardHeader
        sx={{
          ".MuiCardHeader-avatar": {
            alignSelf: "flex-start",
          },
        }}
        title={<Typography variant="h4">{insightKey ? "Edit" : "New"} Insight</Typography>}
        avatar={
          <IconButton aria-label="Back" onClick={handleBackClick(history, `/customers/${customer.id}/insights`)}>
            <BackIcon fontSize="inherit" sx={{ fontSize: "22px", color: "text.primary" }} />
          </IconButton>
        }
      />
      <Container maxWidth={false} sx={{ maxWidth: "688px" }} disableGutters>
        <Typography
          variant="h4"
          sx={{
            mb: 3,
            fontSize: 16,
          }}
        >
          General information
        </Typography>
        <Stack spacing={3}>
          <TextField
            value={title}
            disabled={Boolean(insightKey)}
            onChange={(e) => {
              const alphanumericRegex = /^[a-zA-Z0-9\s-]*$/;

              const inputValue = e.target.value;
              if (alphanumericRegex.test(inputValue)) {
                setTitle(inputValue); // Update state if input is valid
              }
            }}
            required
            id="title"
            label="Title"
            variant="outlined"
          />
          <TextField
            value={summary}
            onChange={(e) => {
              setSummary(e.target.value);
            }}
            required
            id="summary"
            label="Summary"
            variant="outlined"
          />
          <Stack direction="row" spacing={2}>
            <TextField
              id="provider"
              select
              required
              label="Provider"
              value={selectedProvider || ""}
              sx={{ width: "50%" }}
              onChange={(e) => {
                setSelectedProduct(null);
                setSelectedProvider(e.target.value);
              }}
            >
              {providers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Autocomplete
              getOptionLabel={(option) => option?.name || ""}
              options={productsByProvider}
              value={selectedProduct}
              sx={{ width: "50%" }}
              onChange={(event, newValue) => {
                setSelectedProduct(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Product"
                  variant="outlined"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
            />
          </Stack>
          <TextField
            id="category"
            select
            label="Category"
            value={selectedCategory || ""}
            required
            fullWidth
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
          >
            {categories.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Typography
          variant="h4"
          sx={{
            mt: 3,
            fontSize: 16,
          }}
        >
          Description
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(_, newTab) => {
              selectTab(newTab);
            }}
          >
            <Tab label="Edit" id="edit" value="edit-tab" sx={{ textTransform: "none" }} />
            <Tab label="Preview" id="preview" value="preview-tab" sx={{ textTransform: "none" }} />
          </Tabs>
        </Box>
        {tab === "edit-tab" ? (
          <>
            <TextField
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              id="description"
              label="Description"
              fullWidth
              multiline
              rows={8}
              sx={{ mt: 3 }}
              required
            />
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                display: "block",
              }}
            >
              Use{" "}
              <Link target="_blank" href="https://www.markdownguide.org/cheat-sheet">
                markdown syntax
              </Link>{" "}
              for advanced text formatting.
            </Typography>
          </>
        ) : (
          <>
            <Card variant="outlined" sx={{ p: 3, mt: 3, backgroundColor: "transparent" }}>
              {insightDescription}
            </Card>
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                display: "block",
              }}
            >
              If available, enter the URL of a report that can offer the customer more detail about this insight.
            </Typography>
          </>
        )}
        <Typography
          variant="h4"
          sx={{
            mt: 3,
            mb: 2,
            fontSize: 16,
          }}
        >
          Linked report
        </Typography>
        <TextField
          value={reportUrl}
          onChange={(e) => {
            setReportUrl(e.target.value);
          }}
          id="report-url"
          label="Report URL"
          variant="outlined"
          fullWidth
          error={reportUrlInvalid}
          helperText={
            reportUrlInvalid
              ? `URL should start with https://console.doit.com/customers/${customer.id}/analytics/reports`
              : "If available, enter the URL of a report that can offer the customer more detail about this insight."
          }
        />

        <Typography
          variant="h4"
          sx={{
            mt: 3,
            mb: 1,
            fontSize: 16,
          }}
        >
          Financial impact
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            mb: 2,
          }}
        >
          If applicable, enter the maximum estimated potential savings if the recommendations are implemented.
        </Typography>
        {monetaryOutcome ? (
          <Stack direction="row" spacing={2}>
            <TextField
              onChange={(e) => {
                setMonetaryOutcome({
                  ...monetaryOutcome,
                  savings: e.target.value ? parseInt(e.target.value) : null,
                });
              }}
              value={monetaryOutcome.savings}
              id="estimated-potential-savings"
              label="Estimated potential savings"
              fullWidth
              sx={{ mt: 3, width: "60%" }}
              type="number"
              required
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              }}
            />
            <TextField
              id="outlined-select-time-interval"
              select
              value={monetaryOutcome.time}
              sx={{ width: "30%" }}
              onChange={(e) => {
                setMonetaryOutcome({ ...monetaryOutcome, time: e.target.value });
              }}
            >
              {timeIntervals.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <IconButton aria-label="remove-monetary-outcome" onClick={removeMonetaryOutcome}>
              <DeleteIcon fontSize="inherit" sx={{ fontSize: "22px", color: "error" }} />
            </IconButton>
          </Stack>
        ) : (
          <Button variant="outlined" onClick={addMonetaryOutcome}>
            Add
          </Button>
        )}

        <Typography
          variant="h4"
          sx={{
            mt: 3,
            mb: 1,
            fontSize: 16,
          }}
        >
          Other impacts
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            mb: 2,
          }}
        >
          If applicable, enter any outcome that is difficult to quantify in monetary terms in case the recommendations
          are implemented.
        </Typography>
        {nonMonetaryOutcomes?.map((outcomeDescription, index) => (
          <Stack
            direction="row"
            spacing={2}
            key={index}
            sx={{
              mb: 2,
            }}
          >
            <TextField
              onChange={handleChangeValue(index)}
              id="non-monetary-outcome"
              label="Value"
              type="number"
              sx={{ width: "30%", mt: 3 }}
              value={outcomeDescription.value}
              required
            />
            <TextField
              onChange={handleChange(index)}
              id="non-monetary-outcome"
              label="Description"
              fullWidth
              value={outcomeDescription.description}
              sx={{ mt: 3 }}
              required
            />
            <IconButton
              aria-label="delete-non-monetary-outcome"
              onClick={() => {
                setNonMonetaryOutcomes((prev) => prev.filter((_, i) => i !== index));
              }}
            >
              <DeleteIcon fontSize="inherit" sx={{ fontSize: "22px", color: "error" }} />
            </IconButton>
          </Stack>
        ))}
        <Button
          sx={{ mb: 3 }}
          variant="outlined"
          onClick={() => {
            setNonMonetaryOutcomes((prev) => [...prev, { value: "0", description: "" }]);
          }}
        >
          Add
        </Button>
        {customer.id !== "EE8CtpzYiKp0dVAESVrB" && (
          <Alert severity="info" sx={{ mb: 3 }}>
            By creating this insight, the customer will be instantly notified by email, in-app and Slack.
          </Alert>
        )}
      </Container>
      <Divider sx={{ ml: "-16px", mr: "-16px" }} />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "flex-end",
          paddingTop: 1,
          paddingBottom: 1,
          height: "58px",
          width: "688px",
          margin: "0 auto",
        }}
      >
        <Stack direction="row">
          <Button variant="text" color="primary">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            disabled={Boolean(disabled)}
            onClick={createInsight}
          >
            {insightKey ? "Update insight" : "Create insight"}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};
