import { useState } from "react";

import { EarlyAccessFeature } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Card, Container, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { CopyCodeBlock } from "../../Components/CopyCodeBlock/CopyCodeBlock";
import { useFeatureFlag } from "../../Components/hooks/useFeatureFlag";
import { useCustomerContext } from "../../Context/CustomerContext";
import mixpanel from "../../utils/mixpanel";
import {
  getCLICreateCommand,
  getQuickCreateUrl,
} from "../Settings/AWS/ManagePermissions/FeaturesSelection/cloudFormationHelper";
import { RequiredPermissions } from "./RequiredPermissionsDialog";

export const SetUpSpotScaling = () => {
  const [value, setValue] = useState<string>("aws");
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const { customer } = useCustomerContext();
  const isFSK8SFeatureEnabled = useFeatureFlag(EarlyAccessFeature.FSK8S);

  const navigateToAwsQuickLinkPage = () => {
    mixpanel.track("spot-scaling.aws-console.set-up-spot-scaling-on-aws-console");
    const [url] = getQuickCreateUrl(customer.id, ["spot-scaling"], isFSK8SFeatureEnabled, "");
    window.open(url, "_blank");
  };

  const command = getCLICreateCommand(customer.id, ["spot-scaling"], isFSK8SFeatureEnabled, "");

  return (
    <>
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <Card sx={{ p: 3 }}>
          <Grid container spacing={4} sx={{ alignItems: "flex-start" }}>
            <Grid
              size={{
                xs: 7,
                sm: 9.5,
              }}
            >
              <Typography variant="h4" data-testid="connect-to-aws">
                Connect your AWS account to Spot Scaling
              </Typography>
            </Grid>
            <Grid
              size={{
                xs: 5,
                sm: 2.5,
              }}
            >
              <Button
                onClick={() => {
                  setOpenDialog(true);
                }}
                data-testid="list-permissions"
              >
                List of required permissions
              </Button>
            </Grid>
          </Grid>

          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                mb: 1,
                pt: 2,
              }}
            >
              How do you want to connect Spot Scaling?
            </Typography>

            <RadioGroup name="connectOptions" value={value} onChange={handleChange}>
              <FormControlLabel
                value="aws"
                control={<Radio />}
                label="AWS Console (CloudFormation stack)"
                data-testid="aws-console"
              />
              <FormControlLabel
                value="cli"
                control={<Radio />}
                label="CLI commands (CloudFormation stack)"
                data-testid="cli"
              />
            </RadioGroup>
          </Box>

          {value === "aws" && (
            <Box
              sx={{
                pt: 3,
              }}
            >
              <Typography>1. Select ‘Set up Spot Scaling’ to add Spot Scaling to your AWS account.</Typography>
              <Typography>
                2. You'll be taken to your AWS console to create a CloudFormation stack from a template.
              </Typography>
              <Typography>
                3. Ensure you are signed in to the AWS account you wish to connect Spot Scaling to
              </Typography>
              <Typography>
                4. After creating the CloudFormation stack, you may have to wait about 30 seconds while we update your
                account.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                startIcon={<OpenInNewIcon />}
                sx={{ mt: 3 }}
                onClick={() => {
                  navigateToAwsQuickLinkPage();
                }}
                data-testid="setup-spot-scaling-on-aws-console"
              >
                Set up Spot Scaling on AWS Console
              </Button>
            </Box>
          )}

          {value === "cli" && (
            <Stack
              spacing={2}
              sx={{
                pt: 3,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                }}
              >
                Run the following command in AWS CloudShell
              </Typography>
              <Box data-testid="aws-cli-command">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<OpenInNewIcon />}
                  href="https://console.aws.amazon.com/cloudshell/home"
                  target="_blank"
                  onClick={() => {
                    mixpanel.track("spot-scaling.CLI-commands.set-up-spot-scaling-on-aws-console");
                  }}
                >
                  Open AWS CloudShell
                </Button>
              </Box>
              <CopyCodeBlock base={command[0]} />
            </Stack>
          )}
        </Card>
      </Container>
      <RequiredPermissions
        onClose={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
      />
    </>
  );
};
