import { useCallback, useEffect, useState } from "react";

import { Button, Card, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles } from "@mui/styles";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import union from "lodash/union";

import SearchIcon from "../../assets/SearchIcon.svg";
import { transferListText } from "../../assets/texts";
import { useFullScreen } from "../../utils/dialog";
import FilterBar from "./FilterBar";

export type Direction = "left" | "right";

type TransferListProps<T> = {
  left: T[];
  right: T[];
  handleTransfer?: (l: T[], d: Direction) => void;
  leftTitle: string;
  rightTitle: string;
  textDisplay?: (item: T) => string;
  subTextDisplay?: (item: T) => string;
  itemPropGetter?: (item: T, prop: string) => string;
  listClass?: any;
  showFilter?: boolean;
  filterKeys?: string[];
  listType: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  root: {
    margin: "auto",
    marginTop: 5,
  },
  list: (props) => {
    let sizing = {
      [theme.breakpoints.up("xl")]: {
        height: 230,
      },
      [theme.breakpoints.down("xl")]: {
        height: 115,
      },
    };
    if (props) {
      sizing = props;
    }
    return {
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      ...sizing,
    };
  },
  msgContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer: {
    padding: theme.spacing(3, 2),
    display: "flex",
  },
  textMsgContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 6),
  },
  msgText: {
    textAlign: "center",
  },
  button: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
    marginBottom: theme.spacing(1),
  },
}));

function TransferList<T>({
  left,
  right,
  handleTransfer,
  leftTitle,
  rightTitle,
  textDisplay = () => "Default Value",
  subTextDisplay = () => "",
  listClass = null,
  filterKeys,
  itemPropGetter = () => "",
  listType,
}: TransferListProps<T>) {
  const classes = useStyles(listClass);
  const [checked, setChecked] = useState<T[]>([]);
  const [leftOptions, setLeftOptions] = useState<T[]>(left);
  const [rightOptions, setRightOptions] = useState<T[]>(right);
  const [filterValue, setFilterValue] = useState<string>("");
  const { isMobile } = useFullScreen();

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: T[]): number => intersection(checked, items).length;

  const handleToggleAll = (items: T[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(difference(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleTransferRight = () => {
    handleTransfer?.(leftChecked, "right");
    setChecked(difference(checked, leftChecked));
  };

  const handleTransferLeft = () => {
    handleTransfer?.(rightChecked, "left");
    setChecked(difference(checked, rightChecked));
  };

  const filterSearch = useCallback(
    (rows: T[]) =>
      rows.filter((item: T) =>
        filterKeys?.some((filterKey: string) => {
          const itemVal = itemPropGetter(item, filterKey);
          return itemVal?.toString().toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
        })
      ),
    [filterValue, filterKeys, itemPropGetter]
  );

  useEffect(() => {
    if (filterValue && filterValue.length > 0) {
      const leftFilteredRows: T[] = left.length > 0 ? filterSearch(left) : [];
      const rightFilteredRows: T[] = right.length > 0 ? filterSearch(right) : [];
      setLeftOptions(leftFilteredRows);
      setRightOptions(rightFilteredRows);
    }
  }, [filterValue, filterSearch, left, right]);

  const noResultsFound = () => (
    <div className={`${classes.list} ${classes.msgContainer}`}>
      <div className={classes.iconContainer}>
        <img src={SearchIcon} alt="search" />
      </div>
      <div className={classes.textMsgContainer}>
        <Typography
          variant="body1"
          component="span"
          className={classes.msgText}
          sx={{
            display: "block",
          }}
        >
          {transferListText.NO_RESULTS_TITLE(listType)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="span"
          className={classes.msgText}
          sx={{
            display: "block",
          }}
        >
          {transferListText.NO_RESULTS_TEXT(listType)}
        </Typography>
      </div>
    </div>
  );

  const optionsList = (items) => (
    <List className={classes.list} dense component="div" role="list">
      {items.map((value, i) => {
        const labelId = `transfer-list-all-item-${value}-label`;
        return (
          <ListItemButton
            key={i}
            role="listitem"
            disableGutters={isMobile}
            dense={isMobile}
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checked.includes(value)}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={textDisplay(value)} secondary={subTextDisplay(value)} />
          </ListItemButton>
        );
      })}
      <ListItem />
    </List>
  );

  const customList = (title: string, items: T[], totalLength: number) => (
    <Card>
      <ListItem role="listitem" disableGutters={isMobile} dense={isMobile}>
        <ListItemIcon>
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              "data-testid": `select-all-${title}`,
            }}
          />
        </ListItemIcon>
        <ListItemText primary={title} secondary={`${numberOfChecked(items)}/${totalLength} selected`} />
      </ListItem>
      <Divider />
      {/* Show empty list if filter is clean or there is no filter in use */}
      {items.length || !filterKeys || !filterValue ? optionsList(items) : noResultsFound()}
    </Card>
  );

  return (
    <div className={classes.container}>
      {filterKeys && (
        <FilterBar
          placeholder={`Filter ${listType}`}
          handleChange={(query: string) => {
            setFilterValue(query);
          }}
        />
      )}
      <Grid
        container
        wrap="wrap"
        className={classes.root}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid data-cy="transfer-list-left" size={5}>
          {customList(leftTitle, filterValue ? leftOptions : left, left.length)}
        </Grid>
        <Grid size={2}>
          <Grid
            container
            direction="column"
            sx={{
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              classes={{
                root: classes.button,
              }}
              onClick={handleTransferRight}
              disabled={!leftChecked.length}
              aria-label="move selected right"
              data-testid={`move-right-${leftTitle}`}
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              classes={{
                root: classes.button,
              }}
              onClick={handleTransferLeft}
              disabled={!rightChecked.length}
              aria-label="move selected left"
              data-testid={`move-left-${rightTitle}`}
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid data-cy="transfer-list-right" size={5}>
          {customList(rightTitle, filterValue ? rightOptions : right, right.length)}
        </Grid>
      </Grid>
    </div>
  );
}

export default TransferList;
