import { type ChangeEvent, type Dispatch, type SetStateAction, useCallback } from "react";

import { TimeInterval } from "@doitintl/cmp-models";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { MenuItem, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";

import { globalText } from "../../../../assets/texts";
import { type BudgetInfo, type DraftBudget } from "../../../../types";
import { sanitizeDate } from "../../../../utils/common";
import { dateFormatFullDateWithDay } from "../../../../utils/dateTimeFormats";
import { DimensionsDropdown } from "../../dimenstions/DimensionsDropdown";
import { BudgetTypes, TypeAndFrequencyOptions } from "../../utilities";
import ScopeSelector from "./ScopeSelector";

const maxDescriptionLength = 1000;
const maxTitleLength = 64;

type Props = {
  budgetInfo: BudgetInfo;
  setBudgetInfo: Dispatch<SetStateAction<BudgetInfo>>;
  isCurrentUserEditor: boolean;
  budget: DraftBudget;
};

export const MenuProps = {
  anchorOrigin: {
    vertical: "bottom" as const,
    horizontal: "left" as const,
  },
  transformOrigin: {
    vertical: "top" as const,
    horizontal: "left" as const,
  },
};

export const textFieldBaseProps = {
  variant: "outlined" as const,
  margin: "dense" as const,
  fullWidth: true,
};

export const textFieldSelectProps = {
  select: true,
  SelectProps: { MenuProps },
};

const Step1 = ({ budgetInfo, setBudgetInfo, isCurrentUserEditor }: Props) => {
  const handleChangeType = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;
      const currentOption = TypeAndFrequencyOptions.find((option) => option.label === value);
      if (currentOption) {
        setBudgetInfo((prevState) => {
          const temp = { ...prevState };
          temp.currentTypeAndFrequency = currentOption;
          if (currentOption.type === BudgetTypes.RECURRING) {
            temp.startPeriod = temp.startPeriod.startOf(currentOption.period);
          }
          return temp;
        });
      }
    },
    [setBudgetInfo]
  );

  const handleChangeStartPeriod = useCallback(
    (value: DateTime) => {
      setBudgetInfo((prevState) => {
        const temp = { ...prevState };
        if (temp.currentTypeAndFrequency.type === BudgetTypes.RECURRING) {
          temp.startPeriod = sanitizeDate(value).startOf(temp.currentTypeAndFrequency.period);
        } else {
          temp.startPeriod = sanitizeDate(value);
          const newStartPeriod = sanitizeDate(value);
          if (temp.endPeriod && newStartPeriod > temp.endPeriod) {
            temp.endPeriod = newStartPeriod.plus({ days: 1 });
          }
        }
        return temp;
      });
    },
    [setBudgetInfo]
  );

  const getStartPeriodDatePickerProps = useCallback(() => {
    const shouldDisableDate = (day) => {
      if (budgetInfo.currentTypeAndFrequency.type === BudgetTypes.RECURRING) {
        switch (budgetInfo.currentTypeAndFrequency.period) {
          case TimeInterval.WEEK:
            return day.weekday !== 1;
          case TimeInterval.MONTH:
            return day.day !== 1;
          case TimeInterval.QUARTER:
            return day.day !== 1 || (day.month !== 1 && day.month !== 4 && day.month !== 7 && day.month !== 10);
          case TimeInterval.YEAR:
            return day.day !== 1 || day.month !== 1;
          default:
            return false;
        }
      }
      return false;
    };
    return {
      shouldDisableDate,
    };
  }, [budgetInfo.currentTypeAndFrequency.period, budgetInfo.currentTypeAndFrequency.type]);

  return (
    <Stack
      spacing={4}
      sx={{
        mt: 4,
      }}
    >
      <TextField
        value={budgetInfo.name}
        onChange={(e) => {
          setBudgetInfo((prev) => ({ ...prev, name: e.target.value }));
        }}
        fullWidth
        required
        margin="dense"
        variant="outlined"
        label={globalText.NAME}
        disabled={!isCurrentUserEditor}
        slotProps={{
          htmlInput: { maxLength: maxTitleLength },
        }}
      />
      <TextField
        value={budgetInfo.description}
        onChange={(e) => {
          setBudgetInfo((prev) => ({ ...prev, description: e.target.value }));
        }}
        fullWidth
        margin="dense"
        variant="outlined"
        label={globalText.DESCRIPTION}
        disabled={!isCurrentUserEditor}
        slotProps={{
          htmlInput: { maxLength: maxDescriptionLength },
        }}
      />
      {budgetInfo.scope.length > 0 ? (
        <ScopeSelector
          budgetInfo={budgetInfo}
          setBudgetInfo={setBudgetInfo}
          isCurrentUserEditor={isCurrentUserEditor}
        />
      ) : (
        <DimensionsDropdown
          dataSource={budgetInfo.dataSource}
          isCurrentUserEditor={isCurrentUserEditor}
          showCreateNewAttributionOnDropDown={true}
          defaultFilters={budgetInfo.filters}
          onFiltersChanged={(filters) => {
            setBudgetInfo((prev) => ({ ...prev, filters }));
          }}
          onDataSourceChanged={(dataSource) => {
            setBudgetInfo((prev) => ({ ...prev, dataSource }));
          }}
        />
      )}
      <TextField
        label="Type"
        value={budgetInfo.currentTypeAndFrequency.label}
        {...textFieldBaseProps}
        {...textFieldSelectProps}
        onChange={handleChangeType}
        disabled={!isCurrentUserEditor}
      >
        {TypeAndFrequencyOptions.map((budgetType) => (
          <MenuItem key={budgetType.label} value={budgetType.label} dense>
            {budgetType.label}
          </MenuItem>
        ))}
      </TextField>
      <DatePicker
        label="Start Date"
        renderInput={(params) => <TextField required fullWidth margin="dense" {...params} />}
        value={budgetInfo.startPeriod}
        onChange={handleChangeStartPeriod as any}
        components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
        inputFormat={dateFormatFullDateWithDay}
        disableMaskedInput
        componentsProps={{
          actionBar: {
            actions: ["today"],
          },
        }}
        disabled={!isCurrentUserEditor}
        {...getStartPeriodDatePickerProps()}
      />
      {budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED && (
        <DatePicker
          label="End Date"
          renderInput={(params) => <TextField required fullWidth margin="dense" data-testid="endPeriod" {...params} />}
          value={budgetInfo.endPeriod}
          disableMaskedInput
          onChange={(value) => {
            if (value) {
              setBudgetInfo((prevState) => ({ ...prevState, endPeriod: sanitizeDate(value) }));
            }
          }}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat={dateFormatFullDateWithDay}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
          shouldDisableDate={(day) => !!day && day <= budgetInfo.startPeriod}
          disabled={!isCurrentUserEditor}
          disablePast
        />
      )}
    </Stack>
  );
};

export default Step1;
