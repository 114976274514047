import React, { type FC, useCallback, useMemo, useState } from "react";

import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../Components/SimpleDialog";
import { useTeamsBot } from "./hooks";

export const cyIds = {
  title: "connect-teams-channel-title",
  content: "connect-teams-channel-content",
  controls: {
    cancel: "connect-teams-channel-cancel",
    confirm: "connect-teams-channel-confirm",
    input: "connect-teams-channel-input",
  },
};

export type ConnectTeamsChannelDialogProps = {
  open: boolean;
  handleClose: () => void;
};

/**
 * Dialog to connect a Teams channel to the DoiT teams app.
 * This should be used after the app has already been added to a team, and the channel must belong to that team.
 * @param open - Whether the dialog is open
 * @param handleClose - Function to close the dialog
 */
export const ConnectTeamsChannelDialog: FC<ConnectTeamsChannelDialogProps> = ({ open, handleClose }) => {
  const { addTeamChannel } = useTeamsBot();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [link, setLink] = useState<string | undefined>();
  const [inputErr, setInputErr] = useState<string | undefined>();

  const handleConfirm = useCallback<() => Promise<void>>(async () => {
    if (inputErr) {
      return;
    }

    // Asserting presence of `link` because if it's absent, inputErr would be truthy, and we'd have returned already.
    const addTeamErrorMessage = await addTeamChannel(link!);

    if (addTeamErrorMessage) {
      errorSnackbar(addTeamErrorMessage);
      return;
    }

    successSnackbar("Microsoft Teams channel successfully connected");
    handleClose();
  }, [addTeamChannel, errorSnackbar, handleClose, inputErr, link, successSnackbar]);

  const validateLink = useCallback((link?: string) => {
    if (!link) {
      setInputErr("Link is required");
      return;
    }

    // check that the link is of the right format
    const regex = /^https:\/\/teams\.microsoft\.com\/l\/channel\/([^/]+)\/([^?]+)\?groupId=([0-9a-fA-F-]+)(?:&.*)?$/;
    if (!regex.test(link)) {
      setInputErr("Invalid link format");
      return;
    }

    setInputErr(undefined);
  }, []);

  const handleLinkChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLink(e.target.value);
      validateLink(e.target.value);
    },
    [validateLink]
  );

  const disableConfirm = useMemo<boolean>(() => !!inputErr || !link, [inputErr, link]);

  return (
    <SimpleDialog
      open={open}
      title="Connect Teams channel"
      titleCy={cyIds.title}
      titleSx={{ pb: "auto" }}
      onConfirm={handleConfirm}
      confirmButtonText="Add channel"
      confirmButtonCy={cyIds.controls.confirm}
      cancelButtonCy={cyIds.controls.cancel}
      onCancel={handleClose}
      disableConfirm={disableConfirm}
    >
      <Stack
        data-cy={cyIds.content}
        component={DialogContent}
        sx={{
          gap: 2,
        }}
      >
        <Typography>
          To find a channel's link, click the three-dot menu next to its name, then select ‘Get link to channel’, and
          copy the link.
        </Typography>
        <TextField
          aria-invalid={!!inputErr}
          label="Microsoft Teams channel link"
          error={!!inputErr}
          helperText={inputErr}
          onChange={handleLinkChange}
          size="small"
          sx={{ width: "100%" }}
          data-cy={cyIds.controls.input}
          required
          slotProps={{
            inputLabel: { shrink: true },
          }}
        />
      </Stack>
    </SimpleDialog>
  );
};
