import { TemplateApprovalStatus } from "@doitintl/cmp-models";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { templateLibraryTxt } from "../../../assets/texts/CloudAnalytics";
import TemplateCard from "../../../Components/TemplateCard/TemplateCard";
import { getMetricLabel } from "../utilities";
import { type ReportTemplateCard } from "./types";

type Props = {
  activeTab: number;
  reportTemplateCards: ReportTemplateCard[];
  tabValue: number;
  handleCardClicked: (card: ReportTemplateCard) => void;
};

const ReportTemplatesTab = ({ activeTab, reportTemplateCards, tabValue, handleCardClicked }: Props) => (
  <Box data-cy="report-templates-tab-content" role="tabpanel" hidden={activeTab !== tabValue}>
    {activeTab === tabValue && (
      <Grid container spacing={2}>
        {reportTemplateCards.map((card) => {
          const { categories, cloud, config, description, name, status } = card.visibleVersion;
          let statusChip: { label: string; bgColor: string; color: string } | undefined = undefined;

          if (status === TemplateApprovalStatus.PENDING) {
            if (card.activeReportId) {
              statusChip = { label: templateLibraryTxt.CHANGES_PENDING, bgColor: "#FFA726", color: "black" };
            } else {
              statusChip = { label: templateLibraryTxt.PENDING_APPROVAL, bgColor: "#3176B8", color: "white" };
            }
          } else if (status === TemplateApprovalStatus.REJECTED) {
            statusChip = { label: templateLibraryTxt.CHANGES_REJECTED, bgColor: "#E53935", color: "white" };
          }

          return (
            <Grid
              key={card.templateRefId}
              size={{
                xs: 12,
                sm: 6,
                lg: 4,
              }}
            >
              <Box
                onClick={() => {
                  handleCardClicked(card);
                }}
                sx={{
                  height: "100%",
                }}
              >
                <TemplateCard
                  cloud={cloud}
                  description={description}
                  title={name}
                  categories={categories}
                  statusChip={statusChip}
                  metric={getMetricLabel(config.metric, "", "")}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    )}
  </Box>
);

export default ReportTemplatesTab;
