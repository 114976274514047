import { useMemo, useState } from "react";

import { type CurrencyCode, CurrencyCodes } from "@doitintl/cmp-models";
import { Box, Link, Stack, Typography } from "@mui/material";
import sumBy from "lodash/sumBy";

import useMountEffect from "../../../../../../Components/hooks/useMountEffect";
import { useAssetsContext } from "../../../../../../Context/customer/AssetContext";
import { formatCurrency } from "../../../../../../utils/common";
import mixpanel from "../../../../../../utils/mixpanel";
import { type CostDifferencesData, type Explainer } from "../../../types";
import ExplainerCostDifferencesFiltersComponent from "../../Components/CostDifferencesFiltersComponent";
import ExplainerCostDifferencesTableComponent from "../../Components/CostDifferencesTableComponent";
import { parseCostDifferenceData } from "../utils";

type Props = {
  explainer: Explainer;
  onCurrencyChange?: (currency: CurrencyCode) => void;
};

const ExplainerCostPerAccount = ({ explainer, onCurrencyChange }: Props) => {
  const [currency, setCurrency] = useState<CurrencyCode>(CurrencyCodes.USD);
  const { assets } = useAssetsContext();

  const data = useMemo(() => {
    const assetByAccountId = Object.values(assets)
      .flat()
      .reduce((acc, asset) => ({ ...acc, [asset.data.properties.accountId]: asset }), {} as Record<string, any>);
    const costData = parseCostDifferenceData(explainer.account);

    return costData.map((item) => ({
      ...item,
      costName: assetByAccountId[item.costName]
        ? `${item.costName} (${assetByAccountId[item.costName].data.properties.name})`
        : item.costName,
    }));
  }, [assets, explainer.account]);

  const [filteredData, setFilteredData] = useState<CostDifferencesData[]>(data);

  const handleCurrencyChange = (currency: CurrencyCode) => {
    setCurrency(currency);
    if (onCurrencyChange) {
      onCurrencyChange(currency);
    }
  };

  useMountEffect(() => {
    mixpanel.track("invoices.invoice.explainer.accountbreakdown.open");
  });

  return (
    <Box
      sx={{
        maxWidth: "800px",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          mb: 3,
        }}
      >
        Comparing accounts
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
        }}
      >
        Use this breakdown to understand in which accounts there are differences in cost between AWS and DoiT. All
        values are displayed before tax.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
        }}
      >
        For more information, read about DoiT and AWS invoice differences in our{" "}
        <Link
          href="https://help.doit.com/docs/billing/billing-explained/billing-differences"
          target="_blank"
          onClick={() => {
            mixpanel.track("invoices.invoice.explainer.helpcenter.open");
          }}
        >
          {" "}
          Help Center
        </Link>
        .
      </Typography>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          mt: 3,
          mb: "18px",
        }}
      >
        <Stack>
          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
            }}
          >
            Total accounts
          </Typography>
          <Typography variant="h4">{data.length}</Typography>
        </Stack>
        <Stack
          sx={{
            alignItems: "flex-end",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
            }}
          >
            Total pre-tax charges
          </Typography>
          <Typography variant="h4">{formatCurrency(sumBy(data, "doitCost"), currency)}</Typography>
        </Stack>
      </Stack>
      <ExplainerCostDifferencesFiltersComponent
        data={data}
        onFilterChange={setFilteredData}
        onCurrencyChange={handleCurrencyChange}
        storageKey="explainer_cost_per_account"
      />
      <ExplainerCostDifferencesTableComponent
        data={filteredData}
        currency={currency}
        costNameLabel="Account"
        storageKey="explainer_cost_per_account"
      />
    </Box>
  );
};

export default ExplainerCostPerAccount;
