import { useCallback } from "react";

import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { formatCurrency } from "../../../../../utils/common";
import { useContractFormContext } from "../../ContractsFormContext";
import { type CommitmentPeriodType, type VendorContract } from "../../types";
import { CommitmentPeriodsList } from "./components/CommitmentPeriodsList";

export const VendorCommitmentStep = () => {
  const { state, setState } = useContractFormContext();
  const { commitmentPeriods, startDate, endDate } = state.vendorContract ?? {};

  const handleCommitPeriodsChange = useCallback(
    (commitmentPeriods: CommitmentPeriodType[]) => {
      setState((prevState) => ({
        ...prevState,
        vendorContract: {
          ...(prevState.vendorContract as VendorContract),
          commitmentPeriods,
        },
      }));
    },
    [setState]
  );

  if (!state.vendorContract) {
    return null;
  }

  return (
    <Container maxWidth="sm" data-cy={"vendor-commitment-step"}>
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h4" data-cy="total-commitment">
            Total Vendor Commitment:{" "}
            {formatCurrency(
              commitmentPeriods?.reduce((acc, cp) => acc + (cp.value ?? 0), 0),
              "USD"
            )}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            pt: 2,
          }}
        >
          <CommitmentPeriodsList
            commitmentPeriods={commitmentPeriods ?? []}
            contractEndDate={endDate!}
            contractStartDate={startDate!}
            disabled={state.isEditForbidden}
            onChange={handleCommitPeriodsChange}
            showDiscount
          />
        </Grid>
      </Grid>
    </Container>
  );
};
