import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormControl, type SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { type AvaConversationRef } from "../useAvaConversationRefs";
import { AvaHistorySelect } from "./AvaHistorySelect";
import { AvaIcon } from "./AvaIcon";

type Props = {
  conversations: AvaConversationRef[];
  selectedConversationId?: string;
  onDeleteClicked: (conversationId: string) => void;
  onNewChatClicked: () => void;
  onSelectConversation: (conversationId: string) => void;
  onCloseClicked: () => void;
  expandedQuery: boolean;
  setExpandedQuery: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ChatToolbar({
  conversations,
  selectedConversationId,
  onDeleteClicked,
  onNewChatClicked,
  onSelectConversation,
  onCloseClicked,
  expandedQuery,
  setExpandedQuery,
}: Props) {
  const handleChatHistoryChanged = (event: SelectChangeEvent): void => {
    onSelectConversation(event.target.value);
  };

  const newChatMode =
    Boolean(!selectedConversationId) ||
    conversations.find((ref) => ref.conversationId === selectedConversationId) === undefined;

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        spacing={1.5}
        sx={{
          alignItems: "center",
        }}
      >
        <AvaIcon />
        <Typography variant="h6" sx={{ flex: 1 }}>
          Ava - AI assistant
        </Typography>
      </Stack>
      <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
        <IconButton
          size="small"
          sx={{
            borderRadius: 2,
            border: "1px solid",
            borderColor: "error",
          }}
          color="error"
          onClick={() => {
            if (selectedConversationId) {
              onDeleteClicked(selectedConversationId);
            }
          }}
          disabled={newChatMode}
        >
          <DeleteIcon />
        </IconButton>

        <Box
          sx={{
            ml: 2,
          }}
        >
          <FormControl size="small">
            <AvaHistorySelect
              conversations={conversations}
              selectedConversationId={selectedConversationId}
              newChatMode={newChatMode}
              handleChatHistoryChanged={handleChatHistoryChanged}
              expandedQuery={expandedQuery}
              setExpandedQuery={setExpandedQuery}
            />
          </FormControl>
        </Box>
        <Button variant="outlined" onClick={onNewChatClicked} disabled={newChatMode}>
          Start new chat
        </Button>
        <IconButton onClick={onCloseClicked}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
