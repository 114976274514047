import { Box, Button, Container, Typography } from "@mui/material";

import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import BorderedBox from "../BorderedBox";
import RDSIcon from "../SavingsByService/assets/rds.svg";
import { useEnableFlexsaveRDS } from "./hooks";

export default function EnableFlexsaveRDS() {
  const darkMode = useDarkThemeCheck();
  const color = darkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)";
  const enable = useEnableFlexsaveRDS();

  return (
    <Container>
      <Box
        sx={{
          mt: 4,
          mb: 1,
        }}
      >
        <BorderedBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 3,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h2">Flexsave now supports RDS</Typography>

              <Typography
                sx={{
                  mt: 2,
                  color,
                }}
              >
                Now you can save even more with Flexsave for RDS. Flexsave currently covers m6g, r6g and t3 for MySQL
                and PostgreSQL in the North Virginia (us-east-1) and Ireland (eu-west-1) regions. You will automatically
                get covered as we add new SKUs.
              </Typography>
            </Box>

            <img src={RDSIcon} alt="AWS RDS icon symbol" width={82} height={82} />
          </Box>

          <Box sx={{ paddingLeft: 4, paddingRight: 4, marginBottom: 3, marginTop: 1 }}>
            <Button variant="contained" color="primary" onClick={enable} data-cy="enable-flexsave-rds">
              Enable for RDS
            </Button>
          </Box>
        </BorderedBox>
      </Box>
    </Container>
  );
}
