import { Download } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Link, Stack, Typography } from "@mui/material";

import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { downloadFromBucket } from "../../../utils/firebaseStorage";
import CopyPasteBlock from "../CopyPasteBlock";

const docsLink = "https://help.doit.com/docs/datahub/import-data/upload-csv";
const allowedKeysLink = "https://developer.doit.com/docs/datahub-api#allowed-keys-for-fixed-dimensions";
const datahubBucketName = `${process.env.REACT_APP_FIREBASE_PROJECT_ID}-datahub-static`;
const csvExampleFileName = "CSV-example.csv";

const BoldTypography = ({ children }) => (
  <Typography
    component="span"
    variant="subtitle1"
    sx={{
      fontWeight: 500,
      color: "text.primary",
    }}
  >
    {children}
  </Typography>
);

const CodeTypography = ({ children }) => (
  <Typography
    component="span"
    variant="body2"
    sx={{
      fontFamily: "monospace",
    }}
  >
    {children}
  </Typography>
);

const InformationalStep = () => {
  const snackbar = useSnackbar();

  const handleDownload = async () => {
    const error = await downloadFromBucket({
      bucketName: datahubBucketName,
      path: csvExampleFileName,
      fileName: csvExampleFileName,
    });

    if (!error) {
      return;
    }

    snackbar.onOpen({
      message: error ?? "Missing file or test environment",
      variant: "error",
      autoHideDuration: 5000,
      withClose: true,
    });
  };

  return (
    <Box>
      <Typography sx={{ py: 6 }} variant="h1">
        {datahubTxt.PREPARE_FILE}
      </Typography>
      <Stack
        sx={{
          gap: 3,
          alignItems: "start",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            color: "text.primary",
          }}
        >
          {datahubTxt.INFORMATIONAL_STEP.TITLE}
        </Typography>
        <Box>
          <Typography
            sx={{
              mb: 3,
            }}
          >
            {datahubTxt.INFORMATIONAL_STEP.SUB_HEADER}
          </Typography>
          <ul>
            <li>Separate values with commas.</li>
            <li>Add each data record as a new line, using Carriage Return and Line Feed (CRLF) as the line break.</li>
            <li>
              The csv must contain the following headers (columns):
              <ul>
                <li>
                  <BoldTypography>usage_date</BoldTypography>, which must be within the last 12 months, using the ISO
                  8601 format. Example:
                  <br />
                  <CodeTypography> 2024-07-25T18:00:00Z</CodeTypography>
                </li>
                <li>
                  at least one <BoldTypography>label</BoldTypography> (e.g. <CodeTypography>team</CodeTypography>,{" "}
                  <CodeTypography>environment</CodeTypography>, <CodeTypography>cost_center</CodeTypography>, etc) or
                  one <BoldTypography>fixed dimension</BoldTypography> from the{" "}
                  <Link href={allowedKeysLink} target="_blank" rel="noreferrer">
                    list of allowed keys
                    <OpenInNewIcon sx={{ ml: 0.5, mb: -0.5, width: "16px", height: "16px" }} fontSize="small" />
                  </Link>{" "}
                  , such as <CodeTypography>service</CodeTypography>, <CodeTypography>sku</CodeTypography>,{" "}
                  <CodeTypography>project_name</CodeTypography>, <CodeTypography>resource</CodeTypography>, etc.
                </li>
                <li>
                  at least one <BoldTypography>metric</BoldTypography>. Examples: <CodeTypography>cost</CodeTypography>,{" "}
                  <CodeTypography>kilometers</CodeTypography>, etc.
                </li>
              </ul>
              <li>The file size must not exceed 100 MB.</li>
            </li>
          </ul>
          <Typography
            sx={{
              mt: 2,
            }}
          >
            Add the first line as a header row, using the following conventions:
          </Typography>
        </Box>

        <CopyPasteBlock
          texts={[datahubTxt.INFORMATIONAL_STEP.CODE_BLOCK_1]}
          title={datahubTxt.INFORMATIONAL_STEP.SYNTAX}
        />
        <Typography
          sx={{
            fontWeight: 500,
          }}
        >
          {datahubTxt.INFORMATIONAL_STEP.EXAMPLE}
        </Typography>
        <CopyPasteBlock texts={datahubTxt.INFORMATIONAL_STEP.CODE_BLOCK_2} />

        <Button onClick={handleDownload} startIcon={<Download />} variant="outlined" color="primary">
          {datahubTxt.INFORMATIONAL_STEP.DOWNLOAD_BTN}
        </Button>
        <Typography>
          {datahubTxt.INFORMATIONAL_STEP.LINK_TEXT[0]}{" "}
          <Link href={docsLink} target="_blank" rel="noreferrer">
            {datahubTxt.INFORMATIONAL_STEP.LINK_TEXT[1]}
          </Link>{" "}
          {datahubTxt.INFORMATIONAL_STEP.LINK_TEXT[2]}
        </Typography>
      </Stack>
    </Box>
  );
};

export default InformationalStep;
