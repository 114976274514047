import { InputAdornment, MenuItem, TextField } from "@mui/material";

import { useContractFormContext } from "../../../ContractsFormContext";

export const LookerSalesProcessField = () => {
  const { state, handleChange } = useContractFormContext();

  return (
    <div>
      <TextField
        select
        fullWidth
        label="Looker sales process"
        value={state.lookerSalesProcess}
        helperText="Select Looker sales process"
        margin="dense"
        variant="outlined"
        onChange={handleChange("lookerSalesProcess")}
        error={state.errors?.lookerSalesProcess}
        disabled={state.isEditForbidden}
      >
        {[
          "New Looker Logo Renewal",
          "Existing Looker Customer Renewal",
          "Existing Customer Looker Upsell",
          "Greenfield Looker Logo",
        ].map((value) => (
          <MenuItem key={value} value={value} dense>
            {value}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        value={state.discount ?? 0}
        onChange={handleChange("discount")}
        label="Discount"
        margin="dense"
        variant="outlined"
        type="number"
        helperText="Enter the contract discount percentage"
        disabled={state.isEditForbidden}
        slotProps={{
          input: {
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          },
        }}
      />
    </div>
  );
};
