import { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { globalText } from "../../assets/texts";
import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import LoadingButton from "../../Components/LoadingButton";
import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDataHubContext } from "../../Context/DataHubContext";
import { preventOnCloseWhile, useFullScreen } from "../../utils/dialog";

type Props = {
  onClose: () => void;
};

const validDatasetNameChars = /^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$/;

const CreateDatahubDialog = ({ onClose }: Props) => {
  const { fullScreen: xsDown } = useFullScreen("sm");
  const { createNewDataset, datahubItems } = useDataHubContext();
  const errorSnackbar = useErrorSnackbar();
  const history = useHistory();
  const { customer } = useCustomerContext();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);

  const validateName = useCallback((formattedName: string) => {
    const trimmedName = formattedName.trim();
    return !!trimmedName && validDatasetNameChars.test(trimmedName);
  }, []);

  const handleDatasetNameChange = useCallback(
    (name: string) => {
      const formattedName = name.replace(/\s+/g, " ");
      setName(formattedName);

      const isValid = validateName(formattedName);
      setIsNameValid(isValid);

      if (!isValid) {
        setError(true);
        setHelperText(datahubTxt.INVALID_DATASET_NAME);
      } else {
        setError(false);
        setHelperText("");
      }
    },
    [validateName]
  );

  const createDataset = useCallback(async () => {
    const trimmedName = name.trim();
    try {
      setLoading(true);
      if (datahubItems.some((item) => item.dataset === trimmedName)) {
        errorSnackbar(datahubTxt.DATASET_NAME_ALREADY_EXISTS);
        return;
      }
      await createNewDataset(trimmedName, description);
      onClose();
      history.push(`/customers/${customer.id}/datasets/${trimmedName}`);
    } catch (error: any) {
      errorSnackbar(datahubTxt.FAILED_TO_CREATE_DATASET);
    } finally {
      setLoading(false);
    }
  }, [createNewDataset, customer.id, datahubItems, description, errorSnackbar, history, name, onClose]);

  return (
    <Dialog
      open={true}
      onClose={preventOnCloseWhile(loading, () => {
        onClose();
      })}
      fullScreen={xsDown}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle> {datahubTxt.CREATE_DATASET}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            gap: 3,
          }}
        >
          <Typography
            sx={{
              color: "primary.light",
            }}
          >
            {datahubTxt.CREATE_DATASET_DIALOG_DESCRIPTION}
          </Typography>
          <TextField
            label={globalText.NAME}
            required
            value={name}
            onChange={(e) => {
              handleDatasetNameChange(e.target.value);
            }}
            error={error}
            helperText={helperText}
          />
          <TextField
            label={globalText.DESCRIPTION}
            multiline
            rows={5}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            slotProps={{
              input: { inputProps: { maxLength: 280 } },
            }}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={createDataset}
          disabled={loading || !isNameValid}
          loading={loading}
          data-cy="submit-button"
          mixpanelEventId="datahub.datasets.create"
        >
          {globalText.CREATE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDatahubDialog;
