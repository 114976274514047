import { type JSX } from "react";

import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Card, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useChiliPiper } from "../../../../Components/hooks/useChiliPiper";
import { useUserContext } from "../../../../Context/UserContext";
import { ThemeModes } from "../../../../muiThemeTypes";
import { IconWithLink } from "../../Common/IconWithLink";

type Props = {
  title?: string;
  text: string;
  children: JSX.Element[] | JSX.Element;
};

export const HelpPanel = ({ title = "More Information", text, children }: Props) => {
  const submitChiliPiper = useChiliPiper();
  const { userModel: user } = useUserContext();
  const handleContactSalesClicked = () =>
    submitChiliPiper({
      email: user?.email ?? "",
      firstname: user?.firstName ?? "",
      lastname: user?.lastName,
      companyName: user?.customer?.name,
      companyDomain: user?.domain,
    });

  return (
    <Card
      sx={(theme) => ({
        bgcolor: theme.palette.mode === ThemeModes.LIGHT ? grey[50] : cmpBaseColors.backgroundDark,
        p: 3,
        maxWidth: 400,
        height: "fit-content",
      })}
    >
      <Stack>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            mb: 4,
          }}
        >
          {text}
        </Typography>
        {children}
        <IconWithLink text="Contact Sales" icon={<OpenNewIcon />} onClick={handleContactSalesClicked} />
      </Stack>
    </Card>
  );
};
