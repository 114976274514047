import { type JSX, type ReactNode } from "react";

import { SaaSConsoleType } from "@doitintl/cmp-models";
import Grid from "@mui/material/Grid2";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useShowFreeTrialCard } from "../../../../Navigation/Components/Header/hooks";
import CreateBillingProfileCard from "../../CreateBillingProfileCard";
import { useShouldShowOnboardingCards } from "../hooks";
import { SaaSCloudConnectCard } from "../NavigatorOnlyDashboard/CloudConnectCard";
import { FreeTrialCard } from "../NavigatorOnlyDashboard/FreeTrialCard";
import NavigatorOnlyWelcomeBlock from "../NavigatorOnlyDashboard/NavigatorOnlyWelcomeBlock";
import SolveOnlyWelcomeBlock from "../SolveOnlyDashboard/SolveOnlyWelcomeBlock";
import WelcomeBlockWrapper, { renderPackageCloudConnectCards } from "./WelcomeBlockWrapper";

const WelcomeBlock = ({
  wrapper,
}: {
  wrapper?: ({ children }: { children: ReactNode | undefined }) => JSX.Element;
}) => {
  const Wrapper = wrapper ?? WelcomeBlockWrapper;
  const { customer } = useCustomerContext();
  const shouldShowFreeTrial = useShowFreeTrialCard();

  const {
    shouldShowNavigatorWelcomeBlock,
    shouldShowSolveWelcomeBlock,
    shouldShowAzureCloudConnect,
    shouldShowBillingProfileCard,
    shouldShowBillingProfileCardForHybridCustomer,
    shouldShowWelcomeBlock,
    shouldShowHeritageCloudConnect,
    shouldShowSnowflakeOnboardingCard,
  } = useShouldShowOnboardingCards();

  // Flexsave customer
  if (shouldShowAzureCloudConnect) {
    return (
      <Wrapper>
        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 6,
          }}
        >
          <SaaSCloudConnectCard cloudType={SaaSConsoleType.AZURE} />
        </Grid>
      </Wrapper>
    );
  }

  // Customer with advantage only prospect
  if (shouldShowBillingProfileCard) {
    return (
      <Wrapper>
        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 6,
          }}
        >
          <CreateBillingProfileCard />
        </Grid>
      </Wrapper>
    );
  }

  // Customer with navigator only prospect
  if (shouldShowNavigatorWelcomeBlock) {
    if (shouldShowSnowflakeOnboardingCard === undefined) {
      return null;
    }

    return (
      <Wrapper>
        <NavigatorOnlyWelcomeBlock showSnowflakeCard={shouldShowSnowflakeOnboardingCard} />
      </Wrapper>
    );
  }

  // Customer with solve only prospect
  if (shouldShowSolveWelcomeBlock) {
    return (
      <Wrapper>
        <SolveOnlyWelcomeBlock />
      </Wrapper>
    );
  }

  // Heritage customer in trial and no navigator/solve preferences
  if (shouldShowHeritageCloudConnect) {
    return (
      <Wrapper>
        {renderPackageCloudConnectCards(customer, "navigator", true, shouldShowSnowflakeOnboardingCard)}
      </Wrapper>
    );
  }

  if (!shouldShowWelcomeBlock) {
    return null;
  }

  // Customer with hybrid prospects
  return (
    <Wrapper>
      {shouldShowFreeTrial ? (
        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 6,
          }}
        >
          <FreeTrialCard />
        </Grid>
      ) : (
        <>
          {shouldShowBillingProfileCardForHybridCustomer && (
            <Grid
              size={{
                xs: 12,
                sm: 12,
                md: 6,
              }}
            >
              <CreateBillingProfileCard />
            </Grid>
          )}
          {renderPackageCloudConnectCards(customer, "navigator", false, shouldShowSnowflakeOnboardingCard)}
          {renderPackageCloudConnectCards(customer, "solve")}
        </>
      )}
    </Wrapper>
  );
};

export default WelcomeBlock;
