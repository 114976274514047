import { useEffect } from "react";

import { type CustomerEnrichment } from "@doitintl/cmp-models";
import { Box, Collapse, Divider, TableCell, TableRow, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";

import Hide from "../../Components/HideChildren/Hide";
import { consoleErrorWithSentry } from "../../utils";
import { headers } from "./columns";
import { AccountHealth } from "./CustomerDetails/AccountHealth";
import { ActiveUsersChart } from "./CustomerDetails/ActiveUsersChart";
import { Quicklinks } from "./CustomerDetails/Quicklinks";
import { useCustomerDetails } from "./hooks";

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 18,
    marginBottom: 18,
    marginLeft: 3,
    marginRight: 3,
  },
}));

export const CustomerRowContent = ({
  open,
  customerId,
  enrichment,
  canHaveAwsFlexsave,
}: {
  open: boolean;
  customerId: string;
  enrichment: CustomerEnrichment;
  canHaveAwsFlexsave: boolean;
}) => {
  const classes = useStyles();
  const { accountHealth, activeUsersChart, fetchCustomerDetails } = useCustomerDetails(customerId);

  useEffect(() => {
    if (open) {
      fetchCustomerDetails().catch(consoleErrorWithSentry);
    }
  }, [fetchCustomerDetails, open]);

  return (
    <>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={headers.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid sx={{ pt: 1, pb: 1, mt: 0, ml: -2.5 }} container spacing={3}>
              <Hide smDown>
                <Grid
                  size={{
                    sm: 4,
                    xs: 12,
                  }}
                >
                  <Box sx={{ m: 1, ml: 7 }}>
                    <Typography variant="h6" component="div">
                      About
                    </Typography>
                    <Box
                      sx={{
                        mt: 1,
                      }}
                    >
                      <Typography variant="body2" color="textSecondary" component="div">
                        {enrichment.description}
                      </Typography>
                    </Box>
                    <br />
                    <Typography variant="h6" component="div">
                      Industry
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div">
                      {enrichment.category?.industry}
                    </Typography>
                  </Box>
                </Grid>
                <Divider className={classes.divider} orientation="vertical" variant="middle" flexItem />
              </Hide>
              <Grid
                style={{ minWidth: 275 }}
                size={{
                  lg: 2,
                  md: 3,
                  sm: 5,
                  xs: 12,
                }}
              >
                <AccountHealth accountHealth={accountHealth} customerId={customerId} />
              </Grid>

              <Divider className={classes.divider} orientation="vertical" variant="middle" flexItem />
              <Grid
                size={{
                  lg: 3,
                  md: 4,
                  sm: 6,
                  xs: 12,
                }}
              >
                <ActiveUsersChart data={activeUsersChart} />
              </Grid>
              <Hide mdDown>
                <Divider className={classes.divider} orientation="vertical" variant="middle" flexItem />
                <Grid
                  size={{
                    sm: 2,
                    xs: 12,
                  }}
                >
                  <Quicklinks customerId={customerId} canHaveAwsFlexsave={canHaveAwsFlexsave} />
                </Grid>
              </Hide>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
