import { useHistory } from "react-router";
import { type SaaSConsoleType } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { getSaaSConsoleOnboardingRoute } from "../../../../utils/common";
import { CloudConnectCard } from "../CloudConnectCard";
import { headerMap } from "../consts";

export const SolveOnlyCloudConnectCard = ({ cloudType }: { cloudType: SaaSConsoleType }) => {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const cardContent = (
    <Typography
      sx={{
        ml: 3,
        mt: 3,
        mb: 1,
      }}
    >{`Connect your cloud to use DoiT Cloud Solve for ${headerMap[cloudType]}`}</Typography>
  );
  const onClick = () => {
    history.push(getSaaSConsoleOnboardingRoute(customer, cloudType));
  };
  return <CloudConnectCard cloudType={cloudType} cardContent={cardContent} onGetStarted={onClick} />;
};
