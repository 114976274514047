import { type TierPackageType, TierPackageTypes } from "@doitintl/cmp-models";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useTier } from "../../../../Context/TierProvider";
import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { text } from "../text";

type props = {
  navTierId: string | undefined;
  solveTierId: string | undefined;
  onChangeNavTier: (tierId: string) => void;
  onChangeSolveTier: (tierId: string) => void;
  isDisabled: boolean;
};

export const TiersManagement = ({ navTierId, solveTierId, onChangeNavTier, onChangeSolveTier, isDisabled }: props) => {
  const { allTiers } = useTier();

  const getTiersMenuOptions = (packageType: TierPackageType) => {
    const navTiers = allTiers
      ?.filter((tier) => tier.packageType === packageType)
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    return navTiers?.map((tier) => (
      <MenuItem key={tier.ref.id} value={tier.ref.id}>
        {tier.displayName}
      </MenuItem>
    ));
  };

  return (
    <Stack
      sx={{
        gap: 2.5,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
          cursor: "auto",
        }}
      >
        {text.TIER_MANAGEMENT_LABEL}
      </Typography>
      <FormControl fullWidth>
        <InputLabel>{text.TIER_MANAGEMENT_NAVIGATOR_LABEL}</InputLabel>
        <Select
          label={text.TIER_MANAGEMENT_NAVIGATOR_LABEL}
          sx={{ height: 45 }}
          disabled={isDisabled}
          value={navTierId ?? ""}
          data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.NAV_TIER_DROPDOWN}
          onChange={(e) => {
            const val = e.target.value;
            onChangeNavTier(allTiers?.find((tier) => tier.ref.id === val)?.ref.id ?? "");
          }}
          inputProps={{
            "aria-label": text.TIER_MANAGEMENT_NAVIGATOR_LABEL,
          }}
        >
          {getTiersMenuOptions(TierPackageTypes.NAVIGATOR)}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{text.TIER_MANAGEMEMT_SOLVE_LABEL}</InputLabel>
        <Select
          label={text.TIER_MANAGEMEMT_SOLVE_LABEL}
          sx={{ height: 45 }}
          value={solveTierId ?? ""}
          disabled={isDisabled}
          data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.SOLVE_TIER_DROPDOWN}
          onChange={(e) => {
            const val = e.target.value;
            onChangeSolveTier(allTiers?.find((tier) => tier.ref.id === val)?.ref.id ?? "");
          }}
          inputProps={{
            "aria-label": text.TIER_MANAGEMEMT_SOLVE_LABEL,
          }}
        >
          {getTiersMenuOptions(TierPackageTypes.SOLVE)}
        </Select>
      </FormControl>
    </Stack>
  );
};
