import { type FC, useEffect, useState } from "react";

import { areReferencedNodeValuesEqual, getModelByPath } from "@doitintl/cloudflow-commons";
import { ModelType, NodeTransformationType, type ReferencedNodeValue } from "@doitintl/cmp-models";
import { Stack, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import * as yup from "yup";

import { cloudflowTexts } from "../../../../../../assets/texts";
import { ReferencedFieldStandalone } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { type NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { useTransformationNodeSchemaContext } from "./TransformationNodeForm";

const newFieldNameLabel = cloudflowTexts.NEW_FIELD_NAME_LABEL;

function generateFirstItemActionSchema(
  referenceableNodes: NodeWitOutputModel[],
  rootReferencedNodeValue: ReferencedNodeValue = {
    referencedField: [],
    referencedNodeId: "",
  }
) {
  return yup.object({
    type: yup.string().default(NodeTransformationType.FIRST_ITEM),
    newFieldName: yup.string().default("").required().label(newFieldNameLabel).trim(),
    payload: yup
      .object({
        referencedNodeId: yup.string().default(rootReferencedNodeValue.referencedNodeId),
        referencedField: yup.array().of(yup.string().required()).default(rootReferencedNodeValue.referencedField),
      })
      .test("referenced-field-model", cloudflowTexts.REFERENCED_FIELD_MUST_BE_ARRAY, (value) => {
        const referencedNodeModel = referenceableNodes.find(({ id }) => id === value.referencedNodeId)?.outputModel;
        if (referencedNodeModel === undefined) {
          return false;
        }
        const referencedModel = getModelByPath(referencedNodeModel, value.referencedField);
        return referencedModel.type === ModelType.LIST && !areReferencedNodeValuesEqual(value, rootReferencedNodeValue);
      }),
  });
}

export const FirstItemTransformationForm: FC = () => {
  const { setTransformationSchema, referenceableNodes } = useTransformationNodeSchemaContext();
  const { getFieldProps, setFieldValue } = useFormikContext();

  const { value: rootReferencedNodeValue } = getFieldProps("referencedNodeField");

  const [validationSchema, setValidationSchema] = useState<yup.AnyObjectSchema>(
    generateFirstItemActionSchema(referenceableNodes, rootReferencedNodeValue)
  );

  const [dataSourceNodes, setDataSourceNodes] = useState<NodeWitOutputModel[]>(
    referenceableNodes.filter(({ id }) => rootReferencedNodeValue.referencedNodeId === id)
  );

  const { value: transformationFieldValue } = getFieldProps("transformation");

  const newFieldNameCommonProps = useFieldCommonProps(
    getFieldProps("transformation.newFieldName"),
    newFieldNameLabel,
    true
  );
  const payloadCommonProps = useFieldCommonProps(
    getFieldProps("transformation.payload"),
    cloudflowTexts.REFERENCED_VALUE_LABEL,
    true
  );

  useEffect(() => {
    setTransformationSchema(validationSchema);
  }, [setTransformationSchema, validationSchema]);

  useEffect(() => {
    setValidationSchema(generateFirstItemActionSchema(referenceableNodes, rootReferencedNodeValue));
  }, [referenceableNodes, rootReferencedNodeValue]);

  useEffect(() => {
    if (!transformationFieldValue) {
      setFieldValue("transformation", validationSchema.getDefault());
    }
  }, [setFieldValue, validationSchema, transformationFieldValue]);

  useEffect(() => {
    setDataSourceNodes(referenceableNodes.filter(({ id }) => rootReferencedNodeValue.referencedNodeId === id));
  }, [referenceableNodes, rootReferencedNodeValue.referencedNodeId]);

  const isReferencedFieldDisabled = dataSourceNodes.length === 0;

  return (
    <Stack
      spacing={2}
      sx={{
        pb: 4,
      }}
    >
      <TextField fullWidth variant="outlined" size="small" {...newFieldNameCommonProps} />
      <FieldSectionHeader
        title={cloudflowTexts.FIRST_ITEM_TRANSFORMATION_TITLE}
        subtitle={cloudflowTexts.FIRST_ITEM_TRANSFORMATION_SUBTITLE}
      />
      <ReferencedFieldStandalone
        {...payloadCommonProps}
        referenceableNodes={dataSourceNodes}
        disabled={isReferencedFieldDisabled}
        tooltip={isReferencedFieldDisabled ? cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE : ""}
        rootReferencedNodeValue={rootReferencedNodeValue}
      />
    </Stack>
  );
};
