import { Alert, AlertTitle, Box, CircularProgress } from "@mui/material";
import capitalize from "lodash/capitalize";

type WaitingForUpdateAlertProps = {
  operationTitle: string;
  message: string;
  onClose: () => void;
};

/**
 * Alert that will show update messages while waiting for update
 * @param operationTitle - can be used as update operation or creation operation
 * @param message - message
 * @param onClose - close callback
 * @constructor
 */
export const WaitingForUpdateAlert = ({ operationTitle, message, onClose }: WaitingForUpdateAlertProps) => (
  <Alert onClose={onClose} severity="info">
    <AlertTitle>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {capitalize(operationTitle)} account
        <Box
          sx={{
            pl: 1,
          }}
        >
          <CircularProgress size={22} thickness={4} color="inherit" />
        </Box>
      </Box>
    </AlertTitle>
    {message}
  </Alert>
);
