import { Autocomplete, type AutocompleteRenderInputParams, Stack, TextField, Typography } from "@mui/material";

import { metricSplittingDataCy, metricSplittingTxt } from "../../assets/texts/CloudAnalytics";
import { type ItemToSplitProps } from "./types";

const ItemToSplit = ({ options, isOptionDisabled, onSelectOrigin, origin }: ItemToSplitProps) => (
  <Stack direction="column" spacing={2} sx={{ mt: 2, height: "400px" }}>
    <Typography variant="subtitle1">{metricSplittingTxt.ITEM_TO_SPLIT.DESCRIPTION}</Typography>
    <Autocomplete
      data-cy={metricSplittingDataCy.ITEMS_AUTOCOMPLETE}
      fullWidth
      options={options}
      getOptionDisabled={isOptionDisabled}
      filterSelectedOptions={true}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      size="small"
      onChange={(_, newValue) => {
        onSelectOrigin(newValue || undefined);
      }}
      openOnFocus
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          placeholder={metricSplittingTxt.ITEM_TO_SPLIT.PLACEHOLDER}
          label={metricSplittingTxt.ITEM_TO_SPLIT.TITLE}
          variant="outlined"
          margin="dense"
          size="small"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
      )}
      value={origin || null}
    />
  </Stack>
);

export default ItemToSplit;
