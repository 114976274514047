import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { reportText } from "../../../../assets/texts";
import { useKeyPress } from "../../../../Components/hooks/useKeyPress";
import useUpdateEffect from "../../../../Components/hooks/useUpdateEffect";
import { useHotkeyContext } from "../../../../Context/HotkeyContext";
import { useReportConfig } from "../../Context/ReportConfigContext";
import { useStyles } from "../../ReportStyles";

export type ExcludePartialSwitchProps = {
  disabled: boolean;
};

export const ExcludePartialSwitch = ({ disabled }: ExcludePartialSwitchProps) => {
  const classes = useStyles();
  const {
    reportConfig: { excludePartialData },
    dispatchReportConfig,
  } = useReportConfig();
  const { state: hotKeysEnabled } = useHotkeyContext();
  const hotkeyPressed = useKeyPress("p");
  const onChange = (val: boolean) => {
    dispatchReportConfig({ payload: { excludePartialData: val } });
  };
  useUpdateEffect(() => {
    if (hotKeysEnabled && hotkeyPressed) {
      onChange(!excludePartialData);
    }
  }, [hotkeyPressed, dispatchReportConfig, hotKeysEnabled]);

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <FormControlLabel
        className={classes.excludePartialForm}
        control={
          <Checkbox
            size="small"
            className={classes.excludePartialCheckbox}
            name="defaultValue"
            disabled={disabled}
            onChange={(event) => {
              onChange(event.target.checked);
            }}
            checked={!!excludePartialData}
            color="primary"
          />
        }
        value={reportText.EXCLUDE_PARTIAL_LABEL}
        label={
          <Typography variant="caption">
            {reportText.EXCLUDE_PARTIAL_LABEL}
            <Tooltip title={reportText.EXCLUDE_PARTIAL_TOOLTIP}>
              <InfoOutlinedIcon
                htmlColor={grey[600]}
                sx={{ fontSize: "16px", position: "relative", top: "3px", left: "3px" }}
              />
            </Tooltip>
          </Typography>
        }
        classes={{
          label: classes.excludePartialLabel,
          root: classes.excludePartialRoot,
        }}
      />
    </Stack>
  );
};
