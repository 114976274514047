import { useEffect, useMemo, useRef, useState } from "react";

import DescriptionIcon from "@mui/icons-material/DescriptionRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { useHotkeyContext } from "../Context/HotkeyContext";
import { addAnalyticsWindowFunction } from "../utils/windowInit";

export const DescriptionDialog = ({ open, description, onClose, onSave }) => {
  const [value, setValue] = useState(description ?? "");
  useEffect(() => {
    // see here: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    let isMounted = true;
    let timeoutId;
    if (!open) {
      timeoutId = setTimeout(() => {
        if (isMounted) {
          setValue(description);
        }
      }, 300);
    }
    return () => {
      clearTimeout(timeoutId);
      isMounted = false;
    };
  }, [open, description]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth aria-labelledby="description-dialog-title">
      <DialogTitle id="description-dialog-title">Description</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          slotProps={{
            htmlInput: { "data-testid": "description-input" },
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave(value)} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useCardTitleStyles = makeStyles((theme) => ({
  input: {
    padding: theme.spacing(0.5, 1),
    // Override new mui5 value for disabled inputs
    "-webkit-text-fill-color": theme.palette.text.primary,
  },
  untitled: {
    color: theme.palette.text.secondary,
  },
  root: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(0.25),
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.palette.text.primary,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover:enabled fieldset": {
        borderColor: theme.palette.text.primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
      },
      "&.Mui-focused input": {
        color: theme.palette.text.primary,
      },
      "&:hover button": {
        visibility: "visible",
      },
      "&.Mui-focused button": {
        visibility: "visible",
      },
    },
    "& .MuiInputAdornment-root": {
      "& button": {
        visibility: "hidden",
      },
    },
  },
}));

type Props = {
  onUpdate: (key: "description" | "name", value: string) => Promise<void>;
  includesDefaultValue: boolean;
  defaultValue: string;
  name: string;
  description: string | undefined;
  disabled: boolean;
  invalidValuesList?: string[];
  errorMessage?: string;
  disableAutoFocus?: boolean;
  onNameChanged?: (name: string) => void;
  onDescriptionChanged?: (description: string) => void;
};

const EditableCardTitle = ({
  onUpdate,
  includesDefaultValue,
  defaultValue,
  name,
  description,
  disabled,
  invalidValuesList = [],
  errorMessage = "",
  disableAutoFocus = false,
  onNameChanged,
  onDescriptionChanged,
}: Props) => {
  const classes = useCardTitleStyles();
  const [value, setValue] = useState<string>(name);
  const [previousValue, setPreviousValue] = useState<string>(name || defaultValue);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled } = useHotkeyContext();

  const showError = useMemo(
    () => value !== previousValue && invalidValuesList.includes(value),
    [previousValue, invalidValuesList, value]
  );

  useEffect(() => {
    setValue(() => name);
  }, [name]);

  useEffect(() => {
    if (
      !disableAutoFocus &&
      !disabled &&
      (value === defaultValue || (includesDefaultValue && value.includes(defaultValue)))
    ) {
      inputRef.current?.focus();
    }
  }, [defaultValue, value, includesDefaultValue, disabled, inputRef, disableAutoFocus]);

  // Hotkeys disabled when description dialog open
  const handleOpenDialog = () => {
    setDialogOpen(true);
    setHotKeysDisabled();
  };

  const handleChangeDescription = (description: string) => async () => {
    setDialogOpen(false);
    setHotKeysEnabled();
    if (!disabled) {
      await onUpdate("description", description);
      onDescriptionChanged?.(description);
    }
  };

  const handleBlur = async () => {
    // Hot keys enabled when blur on title input
    setHotKeysEnabled();
    if (!disabled && value !== name) {
      if (!showError) {
        const newValue = value.length > 0 ? value : previousValue;
        setPreviousValue(newValue);
        await onUpdate("name", newValue);
        onNameChanged?.(newValue);
        return;
      }

      setValue(previousValue);
    }
  };

  useEffect(() => {
    addAnalyticsWindowFunction({ setTitle: setValue });
  }, [setValue]);

  const handleFocus = (event) => {
    event.preventDefault();
    setPreviousValue(value);
    if (!disabled) {
      const { target } = event;
      // Hot keys disabled when focus on title input
      setHotKeysDisabled();
      const isAlternativeDefaultValue = includesDefaultValue && target.value.includes(defaultValue);
      if (target.value === defaultValue || isAlternativeDefaultValue) {
        target.focus();

        target.setSelectionRange(0, isAlternativeDefaultValue ? target.value.length : defaultValue.length);
      }
    }
  };
  return (
    <>
      <Grid data-testid="title-container" container>
        <Grid
          size={{
            xs: 12,
            md: 4,
            lg: 3,
          }}
        >
          <TextField
            inputRef={inputRef}
            variant="outlined"
            fullWidth
            autoFocus={
              !disableAutoFocus &&
              !disabled &&
              (value === defaultValue || (includesDefaultValue && value.includes(defaultValue)))
            }
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            onBlur={handleBlur}
            onFocus={handleFocus}
            classes={{
              root: classes.root,
            }}
            helperText={showError && errorMessage}
            error={showError}
            slotProps={{
              input: {
                disabled,
                classes: {
                  input: clsx(classes.input, {
                    [classes.untitled]: value === defaultValue,
                  }),
                },
                endAdornment: !disabled && (
                  <InputAdornment position="end">
                    <Tooltip title="Description">
                      <IconButton data-testid="description-dialog" onClick={handleOpenDialog} size="small">
                        <DescriptionIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              },

              htmlInput: { "data-testid": "title-input" },
            }}
          />
        </Grid>
      </Grid>
      <DescriptionDialog
        open={dialogOpen}
        description={description}
        onClose={() => {
          setHotKeysEnabled();
          setDialogOpen(false);
        }}
        onSave={handleChangeDescription}
      />
    </>
  );
};

export default EditableCardTitle;
