import { Tooltip, type TooltipProps } from "@mui/material";

const HtmlTooltip = ({ children, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    slotProps={{
      ...props.slotProps,
      tooltip: {
        sx: {
          backgroundColor: "background.paper",
          color: "text.primary",
          boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          padding: "16px",
          my: "0!important",
          "& div": {
            padding: "0!important",
          },
          ...(props.slotProps?.tooltip as any)?.sx,
        },
      },
      popper: {
        sx: {
          "& .MuiTooltip-tooltip p": {
            margin: "0!important",
          },
          ...(props.slotProps?.popper as any)?.sx,
        },
      },
    }}
  >
    {children}
  </Tooltip>
);

export default HtmlTooltip;
