import { useMemo } from "react";

import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, MenuItem, Stack, Typography } from "@mui/material";

import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useUserContext } from "../../../../Context/UserContext";
import { type FirestoreTimestamp } from "../../../../utils/firebase";
import mixpanel from "../../../../utils/mixpanel";
import { type RampPlanModel } from "../../types";
import { timestampToFormat } from "../../utils";
import { useRampPlanCsvExport } from "../useRampPlanCsvExport";
import { useRampPlanCsvImport } from "../useRampPlanCsvImport";
import { RampPlanDeletePrompt } from "./RampPlanDeletePrompt";
import { RampPlanNameEditor } from "./RampPlanNameEditor";
import { useRampPlanDeleteFacade } from "./useRampPlanDeletePromptFacade";
import { useRampPlanNameEditorFacade } from "./useRampPlanNameEditorFacade";

function timestampToFullYear(timestamp: FirestoreTimestamp) {
  return timestampToFormat(timestamp, "yyyy");
}

type RampPlanHeaderProps = {
  rampPlan: RampPlanModel;
};

export const RampPlanHeader = ({ rampPlan }: RampPlanHeaderProps) => {
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext();
  const hasEditPermission = userRoles?.invoicesViewer ?? false;

  const exportRampPlanCsv = useRampPlanCsvExport(rampPlan);
  const importRampPlanCsv = useRampPlanCsvImport(rampPlan);
  const [rampPlanNameEditorProps, openRampPlanNameEditor] = useRampPlanNameEditorFacade(rampPlan);
  const [rampPlanDeletePromptProps, deleteRampPlan] = useRampPlanDeleteFacade(rampPlan.id);
  const fromYear = timestampToFullYear(rampPlan.startDate);
  const toYear = timestampToFullYear(rampPlan.origEstEndDate);

  const menuOptions = useMemo(() => {
    const options: ThreeDotsMenuOption[] = [
      {
        label: "Download as CSV",
        key: "download-ramp-plan-csv",
        action: () => {
          mixpanel.track("customers.ramps.ramp-plan-view.3-dots-button.download-as-CSV");
          exportRampPlanCsv();
        },
      },
    ];

    if (hasEditPermission) {
      options.unshift({
        label: "Edit ramp plan name",
        key: "edit-ramp-plan-name",
        action: () => {
          mixpanel.track("customers.ramps.ramp-plan-view.3-dots-button.edit-ramp-plan.name");
          openRampPlanNameEditor();
        },
      });
      options.push({
        label: "Upload planned spend CSV",
        key: "upload-ramp-plan-csv",
        action: () => {
          mixpanel.track("customers.ramps.ramp-plan-view.3-dots-button.upload-planned-spend-CSV");
          importRampPlanCsv();
        },
      });
      options.push({
        menuItem: (
          <MenuItem
            key="delete-ramp-plan"
            color="error"
            onClick={() => {
              mixpanel.track("customers.ramps.ramp-plan-view.3-dots-button.delete-ramp-plan");
              deleteRampPlan();
            }}
          >
            <Typography color="error">Delete ramp plan</Typography>
          </MenuItem>
        ),
      });
    }

    return options;
  }, [exportRampPlanCsv, hasEditPermission, openRampPlanNameEditor, importRampPlanCsv, deleteRampPlan]);

  const threeDotsOnClick = () => {
    mixpanel.track("customers.ramps.ramp-plan-view.3-dots-button");
  };

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        mb: 2,
        mt: 2,
      }}
    >
      <IconButton aria-label="Back" component={Link} to={`/customers/${customer.id}/contracts/ramps`} size="small">
        <ArrowBackIcon color="primary" />
      </IconButton>
      <Typography
        variant="h1"
        sx={{
          ml: 1,
          mr: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {rampPlan.name}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          mr: 1,
          whiteSpace: "nowrap",
        }}
      >
        ({fromYear}
        {fromYear !== toYear && ` - ${toYear}`})
      </Typography>
      <ThreeDotsMenu options={menuOptions} size="medium" closeAfterSelect onClick={threeDotsOnClick} />
      <RampPlanNameEditor {...rampPlanNameEditorProps} />
      <RampPlanDeletePrompt {...rampPlanDeletePromptProps} />
    </Stack>
  );
};
