import { type AssetType } from "@doitintl/cmp-models";
import { Avatar, Card, CardContent, CardHeader, Chip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { getCloudIcon } from "../../Pages/CloudAnalytics/templateLibrary/utils";

const styles = {
  chip: {
    ".MuiChip-label": {
      px: "6px",
    },
    backgroundColor: "#E0E0E0",
    fontSize: "12px",
    height: "20px",
    lineHeight: "20px",
  },
};

type Props = {
  categories?: string[];
  cloud?: AssetType[];
  description: string;
  metric: string;
  statusChip?: { label: string; bgColor: string; color: string };
  title: string;
};

const TemplateCard = ({ categories, cloud, description, metric, statusChip, title }: Props) => (
  <Card
    data-cy="template-card"
    sx={{
      width: "100%",
      height: "100%",
      ".MuiCardContent-root": {
        px: 3,
        pb: 0,
        "&:last-child": {
          pb: 3,
        },
      },
      cursor: "pointer",
    }}
  >
    <CardHeader
      title={title}
      titleTypographyProps={{ variant: "h4" }}
      sx={{
        p: 3,
        pb: 0,
        ".MuiCardHeader-action": {
          alignSelf: "center",
        },
      }}
    />
    {statusChip && (
      <CardContent sx={{ pt: 1 }}>
        <Chip
          data-cy="template-card-status-chip"
          label={statusChip.label}
          sx={{ ...styles.chip, backgroundColor: statusChip.bgColor, color: statusChip.color }}
        />
      </CardContent>
    )}
    <CardContent>
      <Typography
        sx={{
          color: "text.secondary",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 4,
          WebkitBoxOrient: "vertical",
        }}
      >
        {description}
      </Typography>
    </CardContent>
    <CardContent>
      <Grid container spacing={1.5}>
        {cloud?.map((cloud) => (
          <Grid key={`${title}-${cloud}`}>
            <Avatar src={getCloudIcon(cloud)} sx={{ height: 28, width: 28, backgroundColor: "#f5f5f5", p: 0.5 }} />
          </Grid>
        ))}
        <Grid>
          <Chip label={metric} sx={styles.chip} />
        </Grid>
        {categories?.map((category) => (
          <Grid key={`${title}-${category}`}>
            <Chip label={category} sx={styles.chip} />
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

export default TemplateCard;
