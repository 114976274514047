import { Link as RouterLink } from "react-router-dom";
import { Autocomplete, List, ListItem } from "@mui/material";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import capitalize from "lodash/capitalize";

import { type AttributionGroupWithRef } from "../../../../CloudAnalytics/attributionGroups/types";

type AttributionGroupSelectionFieldProps = {
  onChange: (attributionGroup: AttributionGroupWithRef | null) => void;
  attributionGroupsList: AttributionGroupWithRef[];
  selectedAttributionGroup: AttributionGroupWithRef | null;
  customerId: string;
  cloudProvider?: string;
};
export const AttributionGroupSelectionField = (props: AttributionGroupSelectionFieldProps) => {
  const { onChange, attributionGroupsList, selectedAttributionGroup, customerId, cloudProvider } = props;
  attributionGroupsList.sort((a, b) => {
    if (a.data.type < b.data.type) {
      return -1;
    }
    if (a.data.type > b.data.type) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Autocomplete
        id="attribution-group"
        options={attributionGroupsList}
        getOptionLabel={(option) => option?.data?.name || ""}
        value={selectedAttributionGroup}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Attribution group"
            slotProps={{
              inputLabel: { required: true },
            }}
          />
        )}
        onChange={(_, value) => {
          onChange(value);
        }}
        groupBy={(option) => (option?.data?.type ? capitalize(option?.data?.type) : "")}
        sx={{ my: 2 }}
      />
      {selectedAttributionGroup?.data.name === "Ramp plan eligible spend" &&
        (cloudProvider === "google-cloud" || cloudProvider === "amazon-web-services") && (
          <Alert severity="info">
            You will need to{" "}
            <Link
              component={RouterLink}
              to={`/customers/${customerId}/analytics/attribution-groups/create`}
              target="_blank"
              rel="noopener noreferrer"
            >
              create a custom attribution
            </Link>{" "}
            if you want to include other services. This attribution group includes the following cloud consumption:
            <br />
            {cloudProvider === "google-cloud" && (
              <List sx={{ listStyleType: "disc", pl: 3, pt: 0 }}>
                <ListItem sx={{ display: "list-item", padding: "2px" }}>Google Cloud</ListItem>
                <ListItem sx={{ display: "list-item", padding: "2px" }}>Marketplace</ListItem>
                <ListItem sx={{ display: "list-item", padding: "2px" }}>Looker</ListItem>
              </List>
            )}
            {cloudProvider === "amazon-web-services" && (
              <List sx={{ listStyleType: "disc", pl: 3, pt: 0 }}>
                <ListItem sx={{ display: "list-item", padding: "2px" }}>Amazon Web Services</ListItem>
                <ListItem sx={{ display: "list-item", padding: "2px" }}>Marketplace</ListItem>
              </List>
            )}
          </Alert>
        )}
    </>
  );
};

export default AttributionGroupSelectionField;
