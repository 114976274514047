import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { type DateTime } from "luxon";

import { ThemeModes } from "../../../../muiThemeTypes";

type Props = {
  estimatedDate: DateTime;
  text?: string;
};

export const StackStyledBackground = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.mode === ThemeModes.DARK ? "#2D2D38" : "#F5F6FF",
}));

export const EstimatedDate = ({ estimatedDate, text }: Props) => (
  <StackStyledBackground
    sx={{
      p: 1,
      borderRadius: 3,
      width: "fit-content",
    }}
  >
    <Typography
      variant="caption"
      sx={{
        color: "text.secondary",
      }}
    >
      {text ?? "Estimated completion date:"}{" "}
      <Typography
        variant="caption"
        sx={{
          color: "text.secondary",
          fontWeight: "700",
          display: "inline",
        }}
      >
        {estimatedDate.toFormat("d LLL yyyy")}
      </Typography>
    </Typography>
  </StackStyledBackground>
);
