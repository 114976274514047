import { type JSX, useMemo } from "react";

import { DoitRole, TemplateApprovalStatus, TemplateVisibility } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";

import { reportTxt, templateCreateText } from "../../../../../assets/texts/CloudAnalytics";
import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCreateTemplateContext, useReportSaverContext, useReportTemplateContext } from "../../../Context";
import AddToReports from "./AddToReports";
import DeleteTemplate from "./DeleteTemplate";
import Reject from "./Reject";
import SaveAndApprove from "./SaveAndApprove";
import SubmitForApproval from "./SubmitForApproval";

const TemplateButtons = () => {
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { isFavoriteReportTemplate, reportTemplateCards, reportTemplateId } = useReportTemplateContext();
  const { reportSaver } = useReportSaverContext();
  const { createTemplateData } = useCreateTemplateContext();
  const isTemplateLibraryAdmin = useDoitRoleCheck(DoitRole.TemplateLibraryAdmin);

  const reportTemplate = useMemo(
    () => reportTemplateCards.find((rt) => rt.templateRefId === reportTemplateId),
    [reportTemplateCards, reportTemplateId]
  );

  const createdByCurrentUser = reportTemplate?.visibleVersion.createdBy === currentUser.email;
  const templateHasChanges = reportSaver.hasUnsavedChanges || createTemplateData.hasUnsavedChanges;

  const getAdminButtons = () => {
    const saveDisabled = !createTemplateData.isValid || !templateHasChanges;
    const templateIsApproved = reportTemplate?.visibleVersion.status === TemplateApprovalStatus.APPROVED;
    const addToReportsDisabled = !isFavoriteReportTemplate ? templateHasChanges : false;
    const addToReportsTooltip =
      !isFavoriteReportTemplate && addToReportsDisabled ? reportTxt.ADD_TO_MY_REPORTS_DOER_SAVE_TOOLTIP : "";
    return (
      <>
        <DeleteTemplate />
        {templateIsApproved && <AddToReports disabled={addToReportsDisabled} tooltip={addToReportsTooltip} />}
        {!templateIsApproved && (
          <Reject disabled={reportTemplate?.visibleVersion.status === TemplateApprovalStatus.REJECTED} />
        )}
        <SaveAndApprove
          disabled={templateIsApproved ? saveDisabled : !createTemplateData.isValid}
          saveOnly={templateIsApproved}
          templateHasChanges={templateHasChanges}
        />
      </>
    );
  };

  const getDoerButtons = () => {
    const isSubmitForApprovalDisabled =
      !createTemplateData.isValid ||
      (!createdByCurrentUser && reportTemplate?.visibleVersion.status === TemplateApprovalStatus.PENDING) ||
      !templateHasChanges;
    const submitForApprovalTooltip = !createTemplateData.isValid ? templateCreateText.FILL_REQUIRED_FIELDS : "";
    const showDeleteButton =
      !reportTemplate?.activeReportId || reportTemplate?.visibleVersion.visibility === TemplateVisibility.PRIVATE;

    const showAddToReports = !!reportTemplate?.activeReportId;
    let addToReportsTooltip = "";
    let addToReportsDisabled = false;

    if (!isFavoriteReportTemplate) {
      if (reportTemplate?.visibleVersion.status !== TemplateApprovalStatus.APPROVED) {
        addToReportsDisabled = true;
      } else if (templateHasChanges) {
        addToReportsDisabled = true;
        addToReportsTooltip =
          reportTemplate?.visibleVersion.visibility === TemplateVisibility.PRIVATE
            ? reportTxt.ADD_TO_MY_REPORTS_DOER_SAVE_TOOLTIP
            : reportTxt.ADD_TO_MY_REPORTS_DOER_SUBMIT_TOOLTIP;
      }
    }

    return (
      <>
        {showDeleteButton && <DeleteTemplate disabled={!createdByCurrentUser} outlined={!showAddToReports} />}
        {showAddToReports && <AddToReports disabled={addToReportsDisabled} tooltip={addToReportsTooltip} />}
        <SubmitForApproval disabled={isSubmitForApprovalDisabled} tooltip={submitForApprovalTooltip} />
      </>
    );
  };

  let buttons: JSX.Element | null = null;

  if (isTemplateLibraryAdmin) {
    buttons = getAdminButtons();
  } else if (isDoitEmployee) {
    buttons = getDoerButtons();
  }

  return (
    <Stack
      direction="row"
      sx={{
        columnGap: 1.5,
      }}
    >
      {buttons}
    </Stack>
  );
};

export default TemplateButtons;
