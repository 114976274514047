import { useState, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spacing(4),
  },
  progressContainer: {
    width: "100%",
    marginTop: "25vh",
    textAlign: "center",
  },
  noTicketsImg: {
    width: (props) => {
      if (props.matches.xs) {
        return "75%";
      }
      if (props.matches.sm) {
        return "50%";
      }
      return "35%";
    },
    height: "auto",
    margin: theme.spacing(3, 0, 3),
  },
}));

const NoTickets = () => {
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.only("xs")),
    sm: useMediaQuery(theme.breakpoints.only("sm")),
  };
  const classes = useStyles({ matches });
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const noTicketsImageSrc = useMemo(
    () => `https://storage.googleapis.com/hello-static-assets/images/support-${Math.floor(Math.random() * 5) + 1}.svg`,
    []
  );
  const handleImageLoaded = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className={classes.root}>
      <Stack
        sx={{
          gap: 4,
          alignItems: "center",
          mt: 4,
        }}
      >
        <img
          src={noTicketsImageSrc}
          alt="No Requests"
          className={classes.noTicketsImg}
          aria-label="no-requests"
          onLoad={handleImageLoaded}
        />
        {isImageLoaded && (
          <>
            <Typography variant="h1">You have no open requests</Typography>
            <Typography variant="body1">
              Create a new request if you require consulting, training, or support
            </Typography>
          </>
        )}
      </Stack>
    </div>
  );
};

export default NoTickets;
