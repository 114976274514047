import { useCallback, useEffect, useState } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import CloudflowGraphicDark from "../../assets/cloudflow/Cloudflow-graphic-dark.svg";
import CloudflowGraphicLight from "../../assets/cloudflow/Cloudflow-graphic-light.svg";
import { cloudflowTexts } from "../../assets/texts";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../utils";
import { useFullScreen } from "../../utils/dialog";

type LandingPageProps = {
  onRegisterInterest?: () => void;
  isRegistered?: { customer: boolean; user: boolean };
};

const LandingPage = ({ onRegisterInterest, isRegistered }: LandingPageProps) => {
  const [registered, setRegistered] = useState(isRegistered?.customer);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const isDarkMode = useDarkThemeCheck();
  const { isMobile } = useFullScreen();
  const Graphic = isDarkMode ? CloudflowGraphicDark : CloudflowGraphicLight;

  const handleRegister = useCallback(() => {
    try {
      onRegisterInterest?.();
      setRegistered(true);
      successSnackbar(cloudflowTexts.SUCCESS_REGISTER);
    } catch (error) {
      consoleErrorWithSentry(error);
      errorSnackbar(cloudflowTexts.ERROR_REGISTER);
    }
  }, [onRegisterInterest, successSnackbar, errorSnackbar]);

  useEffect(() => {
    if (registered === false && isRegistered?.customer) {
      setRegistered(true);
    }
  }, [isRegistered, registered]);

  return (
    <Box
      sx={{
        mx: -2,
        mt: -1,
        bgcolor: "general.backgroundDark",
      }}
    >
      <Container maxWidth="lg" sx={{ pb: 8, pt: 6 }}>
        <Grid container>
          <Grid
            size={{
              sm: 6,
              xs: 12,
            }}
          >
            <Typography variant="h1">{cloudflowTexts.TITLE}</Typography>
            <Box
              sx={{
                mb: 1,
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
              }}
            >
              {cloudflowTexts.COMING_SOON}
            </Typography>
            <Box
              sx={{
                mb: 2,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
              }}
            >
              CloudFlow enables you to automate process and workflows within your organization through an intuitive and
              user-friendly UI. This GenAI-powered, no-code automation platform allows you to put your FinOps processes
              on autopilot, reduce cloud expenses, and ensure compliance.
            </Typography>
            <Box
              sx={{
                mb: 2,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
              }}
            >
              CloudFlow will let you:
              <Box component="ul" sx={{ pl: 4, m: 0 }}>
                <li>Schedule resources</li>
                <li>Automate tagging</li>
                <li>Take action on recommendations</li>
              </Box>
              ...and much more
            </Typography>
            <Box
              sx={{
                mb: 2,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
              }}
            >
              Want to be the first to know when CloudFlow launches?
            </Typography>
            <Box
              sx={{
                mb: 1,
              }}
            />
            {!registered && (
              <Button variant="contained" color="primary" onClick={handleRegister}>
                {cloudflowTexts.REGISTER_YOUR_INTEREST}
              </Button>
            )}
            {registered && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    mt: 2,
                  }}
                >
                  <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                  <Typography
                    variant="body1"
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {isRegistered?.user ? cloudflowTexts.INTEREST_REGISTERED : cloudflowTexts.ALREADY_REGISTERED}
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
          <Grid
            size={{
              sm: 6,
              xs: 12,
            }}
          >
            <Box
              component="img"
              src={Graphic}
              alt="CloudFlow graphic"
              sx={{
                display: "block",
                margin: "0 auto",
                p: isMobile ? 4 : undefined,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPage;
