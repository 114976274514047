import { type CurrencyCode } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { formatCurrency } from "../../../../utils/common";
import { type Values } from "./types";

type Props = {
  values: Values;
  customerDomain: string;
  total: number;
  currency: CurrencyCode | undefined;
};

const SummaryStep = ({ values, total, customerDomain, currency }: Props) => (
  <Grid container>
    <Grid size="grow" />
    <Grid
      size={{
        xs: 12,
        lg: 3,
      }}
    >
      <Grid container spacing={1}>
        <Grid size={12}>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "16px",
            }}
          >
            Order summary
          </Typography>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          size={12}
        >
          <Grid size={3}>
            <Typography variant="subtitle2">Domain</Typography>
          </Grid>
          <Grid size={9}>
            <Typography variant="subtitle1" data-cy="customer-domain">
              {customerDomain}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          size={12}
        >
          <Grid size={3}>
            <Typography variant="subtitle2">License</Typography>
          </Grid>
          <Grid size={9}>
            <Typography variant="subtitle1">{values.service?.data?.skuName}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          size={12}
        >
          <Grid size={3}>
            <Typography variant="subtitle2">Quantity</Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="subtitle1">{values.quantity}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          size={12}
        >
          <Grid size={3}>
            <Typography variant="subtitle2">Plan</Typography>
          </Grid>
          <Grid size={9}>
            <Typography variant="subtitle1">
              {values.service?.data?.payment === "MONTHLY" ? "Monthly Payment" : "Yearly"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          size={12}
        >
          <Grid size={3}>
            <Typography variant="subtitle2">Total</Typography>
          </Grid>
          <Grid size={9}>
            <Typography variant="subtitle1">{formatCurrency(total, currency)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid size="grow" />
  </Grid>
);

export default SummaryStep;
