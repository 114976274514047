import { type ReactNode } from "react";

import { Card, Stack, Typography } from "@mui/material";

import BackgroundImage from "../../../assets/FeaturedCardBackground.svg";
import BackgroundDarkImage from "../../../assets/FeaturedCardBackgroundDark.svg";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";

type FeaturedCardProps = {
  title?: ReactNode;
  content?: ReactNode;
  button?: ReactNode;
};

export const FeaturedCard = ({ title, content, button }: FeaturedCardProps) => {
  const isDarkMode = useDarkThemeCheck();

  return (
    <Card
      elevation={0}
      variant="elevation"
      sx={{
        backgroundImage: `url(${isDarkMode ? BackgroundDarkImage : BackgroundImage})`,
        backgroundSize: "cover",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        p: 3,
        gap: 2,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography variant="h4">{title}</Typography>
        {content}
      </Stack>
      <Stack
        direction="row"
        sx={{
          mt: "auto",
          justifyContent: "flex-start",
        }}
      >
        {button}
      </Stack>
    </Card>
  );
};
