import { type Dispatch, useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { type AuthenticationSettingsId } from "../../../Navigation/config/pages";
import { useReducerWithSideEffects } from "./hooks";
import reducer from "./reducer";
import { Settings } from "./Settings/Settings";
import Sso from "./SSO/Sso";
import SsoConfiguration from "./SSO/SsoConfiguration";
import { type AuthAction, AuthActionKind, type AuthState } from "./types";

type Props = {
  pageId: AuthenticationSettingsId;
};

const AuthenticationSettings = ({ pageId }: Props) => {
  const { customer } = useCustomerContext();
  const history = useHistory();
  const [state, dispatch]: [AuthState, Dispatch<AuthAction>] = useReducerWithSideEffects(reducer, customer);

  const backPageCallback = useCallback(() => {
    history.push(`/customers/${customer.id}/iam/authentication`);
  }, [customer.id, history]);

  useEffect(() => {
    dispatch({ type: AuthActionKind.RESET_ERROR_STATE });
  }, [dispatch, pageId]);

  return (
    <Box
      sx={{
        pt: 1,
      }}
    >
      {pageId === "iam:sso" && <Sso state={state} dispatch={dispatch} />}
      {pageId === "iam:sso:configure" && (
        <SsoConfiguration state={state} dispatch={dispatch} backPageCallback={backPageCallback} />
      )}
      {pageId === "iam:auth-provider" && <Settings state={state} dispatch={dispatch} />}
    </Box>
  );
};
export default AuthenticationSettings;
