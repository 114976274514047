import { type JSX, useMemo } from "react";

import { Link } from "react-router-dom";
import { DashboardType } from "@doitintl/cmp-models";
import ForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar, Button, Card, CardHeader, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { type Dashboard, useDashboardsContext } from "../../Context/DashboardContext";
import { customersRoute } from "../../Navigation/core/utils";
import { GlobalDashboardsById } from "../../utils/common";
import mixpanel from "../../utils/mixpanel";
import SectionHeader from "./SectionHeader";
import { useDashboardInfo } from "./useDashboardInfo";

type CardData = {
  title: string;
  text: string;
  link: string;
  dashboardType: DashboardType;
  icon: JSX.Element;
};

type PickNonNullable<T, K extends keyof T> = T & { [P in K]-?: NonNullable<T[P]> };

function isPublicDashboardWithType(dashboard: Dashboard): dashboard is PickNonNullable<Dashboard, "dashboardType"> {
  return !!dashboard.isPublic && !!dashboard.dashboardType;
}

const dashboardSortOrder = [
  DashboardType.Pulse,
  DashboardType.AwsLens,
  DashboardType.SaaSAwsLens,
  DashboardType.GcpLens,
  DashboardType.SaaSGcpLens,
  DashboardType.BqLens,
  DashboardType.GkeLensV2,
  DashboardType.AzureLens,
  DashboardType.EKSLens,
  DashboardType.AWSMAPLens,
];

export default function Dashboards() {
  const {
    palette: { primary },
  } = useTheme();
  const customerId = useCustomerId();
  const { dashboards } = useDashboardsContext();
  const getDashboardInfo = useDashboardInfo();

  const dashboardCardsToDisplay = useMemo<CardData[]>(() => {
    if (dashboards.length > 0) {
      return dashboards
        .filter(isPublicDashboardWithType)
        .sort((a, b) => dashboardSortOrder.indexOf(a.dashboardType) - dashboardSortOrder.indexOf(b.dashboardType))
        .map((d) => {
          const [text, iconSrc] = getDashboardInfo(d.dashboardType);
          return {
            title: GlobalDashboardsById[d.id]?.name ?? d.name,
            dashboardType: d.dashboardType,
            text,
            link: customersRoute(customerId, `dashboards/${d.name}`),
            icon: <Avatar sx={{ width: 32, height: 32 }} src={iconSrc} />,
          };
        });
    }
    return [];
  }, [customerId, dashboards, getDashboardInfo]);

  const link = useMemo(
    () => (
      <Button
        component={Link}
        to={customersRoute(customerId, `dashboards`)}
        endIcon={<ForwardIcon />}
        onClick={() => {
          mixpanel.track("home.dashboards.all.click", { $referrer: location.href });
        }}
      >
        Go to dashboards
      </Button>
    ),
    [customerId]
  );

  const loadingCard = useMemo(
    () => (
      <Card>
        <CardHeader
          avatar={<Skeleton sx={{ width: 32, height: 32 }} variant="circular" />}
          title={<Skeleton variant="text" />}
          subheader={<Skeleton variant="text" />}
          titleTypographyProps={{ variant: "subtitle1", textDecoration: "none", fontWeight: 500 }}
        />
      </Card>
    ),
    []
  );

  if (!dashboardCardsToDisplay.length) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      sx={{
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Grid size={12}>
        <SectionHeader text="Manage and optimize cloud infrastructure with dashboards" link={link} />
      </Grid>
      {dashboardCardsToDisplay.map((card, index) => (
        <Grid
          key={index}
          size={{
            xs: 12,
            md: 3,
          }}
        >
          {card === null ? (
            loadingCard
          ) : (
            <Link
              to={card.link}
              style={{ textDecoration: "none" }}
              onClick={() => {
                mixpanel.track(`home.dashboards.${card.dashboardType}.click`, { $referrer: location.href });
              }}
            >
              <Card sx={{ "&:hover": { borderColor: primary.main } }}>
                <CardHeader
                  avatar={card.icon}
                  title={card.title}
                  subheader={card.text}
                  titleTypographyProps={{ variant: "subtitle1", textDecoration: "none", fontWeight: 500 }}
                />
              </Card>
            </Link>
          )}
        </Grid>
      ))}
    </Grid>
  );
}
