import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import CountUpCard from "../../../../Components/CountUp/CountUpCard";
import { useCurrency } from "../../../../Components/hooks/useCurrency";
import { type SavingsTimeFilter } from "../../types";

export type SavingsStatisticsProps = {
  savings: number;
  savingsRatio: number;
  spotSpending: number;
  odSpending: number;
  timeFilter: SavingsTimeFilter;
};

export const SavingsStatisticsCards = ({
  savings,
  savingsRatio,
  odSpending,
  spotSpending,
  timeFilter,
}: SavingsStatisticsProps) => {
  const { customerCurrencySymbol } = useCurrency();

  return (
    <Box>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <CountUpCard
            tooltipText="Spot Spending"
            number={spotSpending}
            prefix={customerCurrencySymbol}
            duration={1}
            decimals={2}
            text={`${timeFilter} Spot Spending`}
          />
        </Grid>

        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <CountUpCard
            tooltipText="On-Demand Spending"
            number={odSpending}
            prefix={customerCurrencySymbol}
            duration={1}
            decimals={2}
            text={`${timeFilter} On-Demand spending`}
          />
        </Grid>

        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <CountUpCard
            tooltipText="Savings over On-Demand"
            number={savings}
            prefix={customerCurrencySymbol}
            duration={1}
            decimals={2}
            text={`${timeFilter} Savings`}
          />
        </Grid>

        <Grid
          size={{
            xs: 6,
            sm: 3,
          }}
        >
          <CountUpCard
            tooltipText="Savings over On-Demand rate"
            number={savingsRatio}
            suffix="%"
            decimals={0}
            text={`${timeFilter} Savings Rate`}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
