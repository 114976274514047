import {
  type ChangeEvent,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  type SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  type AccessLevel,
  AccessLevels,
  CategoryStatus,
  type CloudConnectCategory,
  type CloudConnectGoogleCloud,
  EarlyAccessFeature,
  SaaSConsoleType,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { type AccordionProps } from "@mui/material/Accordion/Accordion";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual";

import { ActiveWidgetsListText, tiersText } from "../../../assets/texts";
import { CategoryCheckbox } from "../../../Components/CategoryCheckbox";
import { EarlyAccessFeatureGuard } from "../../../Components/Guard/EarlyAccessFeatureGuard";
import Hide from "../../../Components/HideChildren/Hide";
import { SafeHtml } from "../../../Components/SafeHtml";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { isSaaSConsoleByType } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { AccessLevelsOptions } from "../consts";
import { ActiveWidgetsListStyles } from "./ActiveWidgetsList.styles";
import { ServiceAccountStatusChip } from "./ServiceAccountStatusChip";

type ActiveWidgetsListTypes = {
  permissionsByCategory: CloudConnectCategory[];
  onOpenDialog: (s: CloudConnectCategory[], i: boolean) => void;
  children?: ReactNode;
  activeCategories: WithFirebaseModel<CloudConnectGoogleCloud>[];
  isEditMode: boolean;
  onChangeCategory: (c: CloudConnectCategory[]) => void;
  selectedOrg: number;
  accessLevel: AccessLevel;
  setAccessLevel: Dispatch<SetStateAction<AccessLevel>>;
  additionalWidgets?: ReactNode;
};

type CategoryExpand = Record<CloudConnectCategory["id"], boolean>;

const AccordionCategory = ({
  category,
  onChange,
  ...props
}: {
  category: CloudConnectCategory;
  onChange: (category: CloudConnectCategory, expanded: boolean) => void;
} & Omit<AccordionProps, "onChange">) => {
  const onChangeCallback = useCallback(
    (e: SyntheticEvent, expanded: boolean) => {
      onChange(category, expanded);
    },
    [category, onChange]
  );

  const { children, ...restProps } = props;
  return (
    <Accordion onChange={onChangeCallback} {...restProps}>
      {children}
    </Accordion>
  );
};

const saasConsoleCategories = ["core", "bigquery-finops", "bigquery-finops-advanced", "bigquery-finops-editions"];

export default function ActiveWidgetsList({
  permissionsByCategory,
  onOpenDialog,
  children,
  activeCategories,
  isEditMode,
  onChangeCategory,
  selectedOrg,
  accessLevel,
  setAccessLevel,
  additionalWidgets,
}: ActiveWidgetsListTypes) {
  const theme = useTheme();
  const classes = ActiveWidgetsListStyles(theme);
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const isEntitledBigQuery = useIsFeatureEntitled("lens:bigquery");
  const [selectedCategories, setSelectedCategories] = useState<CloudConnectCategory[]>([]);
  const [activePermissionsArr, setActivePermissionsArr] = useState<string[]>([]);
  const [expand, setExpand] = useState<CategoryExpand>({});

  const toggleAccordion = useCallback((category: CloudConnectCategory) => {
    setExpand((prev) => ({ ...prev, [category.name]: !prev[category.name] }));
  }, []);

  useEffect(() => {
    if (Object.keys(expand).length === 0 && selectedCategories.length > 0) {
      selectedCategories.forEach((c: CloudConnectCategory) => {
        expand[c.name] = false;
      });
    }
  }, [selectedCategories, expand]);

  useEffect(() => {
    if (activeCategories[selectedOrg]) {
      setSelectedCategories([]);
      setActivePermissionsArr([]);
      Object.keys(activeCategories[selectedOrg].categoriesStatus ?? []).forEach((key, i) => {
        if (
          find(permissionsByCategory, { id: key }) &&
          (activeCategories[selectedOrg].categoriesStatus?.[key] === CategoryStatus.Healthy ||
            (accessLevel === AccessLevels.PROJECT &&
              key === "core" &&
              activeCategories[selectedOrg].categoriesStatus?.[key] === CategoryStatus.Partial))
        ) {
          setActivePermissionsArr((permissions) => [...permissions, key].sort());
          setSelectedCategories((categories) => {
            const tmpCategories = i === 0 ? [] : categories;
            if (!find(tmpCategories, { id: key })) {
              const categoryPermission = find(permissionsByCategory, { id: key });
              if (categoryPermission) {
                return [...tmpCategories, categoryPermission];
              }
              return [...tmpCategories];
            } else {
              return [...tmpCategories];
            }
          });
        }
      });
    } else {
      if (!isEditMode) {
        const activePermissions = permissionsByCategory.filter(
          (category) =>
            accessLevel === AccessLevels.ORGANIZATION || category.id === "core" || category.allowPartialAccessLevel
        );
        onChangeCategory([...activePermissions]);
        setSelectedCategories([...activePermissions]);
      }
    }
  }, [activeCategories, isEditMode, onChangeCategory, permissionsByCategory, selectedOrg, accessLevel]);

  useEffect(() => {
    if (activeCategories[selectedOrg] && permissionsByCategory && permissionsByCategory.length > 0) {
      Object.keys(activeCategories[selectedOrg]?.categoriesStatus ?? []).some((category) => {
        if (activeCategories[selectedOrg]?.categoriesStatus?.[category] === CategoryStatus.Unhealthy) {
          const categoryPermissions = find(permissionsByCategory, { id: category });
          setSelectedCategories((categories) => {
            if (categoryPermissions) {
              return [...categories, categoryPermissions];
            }
            return categories;
          });
          return true;
        }
      });
    }
  }, [activeCategories, permissionsByCategory, selectedOrg]);

  const connectBtn = useCallback(() => {
    mixpanel.track("gcp.link-account");
    if (selectedCategories.some((category) => category.id === "core")) {
      mixpanel.track("gcp.link-account.quotas");
    }
    onOpenDialog(selectedCategories, isEditMode);
  }, [isEditMode, onOpenDialog, selectedCategories]);
  const handleChange = useCallback(
    (category: CloudConnectCategory, event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedCategories((categories) => {
          onChangeCategory([...categories, category]);
          return [...categories, category];
        });
      } else {
        setSelectedCategories((categories) => {
          const idx = findIndex(categories, { name: category.name });
          const newCategories = [...categories];
          if (idx > -1) {
            newCategories.splice(idx, 1);
            onChangeCategory(newCategories);
          }
          return newCategories;
        });
      }
    },
    [onChangeCategory]
  );

  const getValidStatus = useCallback(
    (category: CloudConnectCategory): number => {
      if (activeCategories[selectedOrg]?.categoriesStatus?.[category.id]) {
        if (
          accessLevel === AccessLevels.PROJECT &&
          category.id === "core" &&
          activeCategories[selectedOrg].categoriesStatus?.[category.id] === CategoryStatus.Partial
        ) {
          return CategoryStatus.Healthy;
        } else {
          return activeCategories[selectedOrg].categoriesStatus?.[category.id] ?? 0;
        }
      }
      return 0;
    },
    [activeCategories, accessLevel, selectedOrg]
  );

  const isNotEntitledBQLens = useCallback(
    (category: CloudConnectCategory) => category.id.startsWith("bigquery") && !isEntitledBigQuery,
    [isEntitledBigQuery]
  );

  const getStatusBadge = useCallback(
    (category: CloudConnectCategory) => {
      let validStatus;
      if (isNotEntitledBQLens(category)) {
        validStatus = CategoryStatus.NotConfigured;
      } else {
        validStatus = getValidStatus(category);
      }
      return (
        <Box className={classes.accordionSummaryBadge}>
          <ServiceAccountStatusChip status={validStatus} />
        </Box>
      );
    },
    [getValidStatus, classes.accordionSummaryBadge, isNotEntitledBQLens]
  );

  const expandIcon = useCallback(
    (category: CloudConnectCategory) => {
      const Icon = expand[category.name] ? ExpandLessIcon : ExpandMoreIcon;
      return <Icon sx={{ color: theme.palette.action.active }} />;
    },
    [expand, theme.palette.action.active]
  );

  return (
    <div className={classes.root} id="cloud-connect-widgets">
      <Grid container direction="row">
        <EarlyAccessFeatureGuard
          customer={customer}
          featureName={EarlyAccessFeature.GCP_CLOUD_CONNECT_V2}
          allowDoitEmployees={false}
          fallbackToNoneExistPage={false}
        >
          <Grid
            sx={{
              mb: 2,
            }}
            size={4}
          >
            <TextField
              value={accessLevel}
              label="GCP access level"
              variant="outlined"
              margin="dense"
              fullWidth
              select
              disabled={isEditMode}
              onChange={(e) => {
                e.stopPropagation();
                setAccessLevel(e.target.value as AccessLevels);
              }}
            >
              {AccessLevelsOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} dense>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </EarlyAccessFeatureGuard>
      </Grid>
      {additionalWidgets}
      {permissionsByCategory
        ?.filter(
          (category) =>
            !isSaaSConsoleByType(customer, isProductOnlyCustomer, SaaSConsoleType.GCP, true) ||
            saasConsoleCategories.includes(category.id)
        )
        ?.filter((category) => !category.featureFlag || customer.earlyAccessFeatures?.includes(category.featureFlag))
        ?.map((category) => (
          <AccordionCategory
            key={category.id}
            category={category}
            onChange={toggleAccordion}
            expanded={expand[category.name]}
            elevation={0}
          >
            <AccordionSummary
              sx={{
                ".MuiAccordionSummary-content.Mui-expanded": { margin: 0 },
              }}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
              classes={{
                content: classes.accordionSummaryCategory,
              }}
            >
              <Box className={classes.checkboxCategory}>
                <CategoryCheckbox
                  category={category}
                  accessLevel={accessLevel}
                  handleChange={handleChange}
                  selectedCategories={selectedCategories}
                  isNotEntitledBQLens={isNotEntitledBQLens(category)}
                />
              </Box>
              {getStatusBadge(category)}
              <Box className={classes.expandIconBox}>{expandIcon(category)}</Box>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {isNotEntitledBQLens(category) && (
                <Typography variant="body2">{tiersText.UPGRADE_SUBSCRIPTION(category.name)}</Typography>
              )}
              {!isNotEntitledBQLens(category) && (
                <>
                  <Typography variant="body2">
                    <SafeHtml html={category.description} />
                  </Typography>
                  <br />
                  {category.permissions
                    .filter(
                      (permission) =>
                        accessLevel === AccessLevels.ORGANIZATION ||
                        (category.orgLevelOnlyPermissions && !category.orgLevelOnlyPermissions.includes(permission))
                    )
                    .map((permission) => (
                      <div key={permission}>
                        <Typography component="div" variant="body2" color="textPrimary">
                          - {permission}
                        </Typography>
                      </div>
                    ))}
                </>
              )}
            </AccordionDetails>
          </AccordionCategory>
        ))}
      <Accordion expanded={false} elevation={0}>
        <AccordionSummary className={classes.accordionSumRoot}>
          <Hide>
            <div className={classes.accordionSumContent}>
              <div>
                {isEditMode ? (
                  <Button
                    onClick={connectBtn}
                    color="primary"
                    variant="contained"
                    disabled={
                      isEqual(selectedCategories.map((category) => category.id)?.sort(), activePermissionsArr) ||
                      !find(selectedCategories, { id: "core" }) ||
                      accessLevel === AccessLevels.PROJECT
                    }
                  >
                    {ActiveWidgetsListText.updateRoleButton}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={connectBtn}
                    variant="contained"
                    disabled={
                      (selectedCategories && selectedCategories.length === 0) ||
                      !find(selectedCategories, { id: "core" })
                    }
                  >
                    {ActiveWidgetsListText.serviceAccountButton}
                  </Button>
                )}
              </div>
            </div>
          </Hide>
        </AccordionSummary>
      </Accordion>
      {!isEditMode && (
        <Accordion expanded={false} elevation={0}>
          <AccordionSummary className={classes.accordionSumRoot}>
            <div className={classes.accordionSumContent}>
              <div>{children}</div>
            </div>
          </AccordionSummary>
        </Accordion>
      )}
    </div>
  );
}
