import { Box, Skeleton } from "@mui/material";

type FilterTableSkeletonProps = {
  withActionButton?: boolean;
};

export const filterTableSkeletonCyId = "filterTableSkeleton-body";

/**
 * Skeleton to use while table data is loading
 * @param withActionButton - if true the skeleton will also show action button
 * @constructor
 */
export const FilterTableSkeleton = ({ withActionButton = false }: FilterTableSkeletonProps) => (
  <>
    {withActionButton ? (
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "88%",
            pr: 2,
          }}
        >
          <Skeleton variant="rectangular" height={40} />
        </Box>
        <Box
          sx={{
            width: "12%",
          }}
        >
          <Skeleton variant="rectangular" height={40} />
        </Box>
      </Box>
    ) : (
      <Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Skeleton variant="rectangular" height={40} />
        </Box>
      </Box>
    )}
    <Box
      sx={{
        pt: 2,
        pb: 2,
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={35} />
    </Box>
    <Skeleton variant="rectangular" width="100%" height={250} data-cy={filterTableSkeletonCyId} />
  </>
);
