import { Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import darkModeImg from "../../../../assets/standalone/flexsave-no-savings-dark.svg";
import lightModeImg from "../../../../assets/standalone/flexsave-no-savings-light.svg";
import { OnboardLayout } from "./OnboardLayout";

type Props = {
  handleDone: () => void;
};

export const NoSavings = ({ handleDone }: Props) => {
  const theme = useTheme();
  const img = theme.palette.mode === "dark" ? darkModeImg : lightModeImg;

  return (
    <OnboardLayout
      bottomAppBarProps={{
        primaryButtonName: "Return to home",
        handlePrimaryButtonClicked: handleDone,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          maxWidth: "sm",
        }}
      >
        <img src={img} alt="No savings" width={100} />
        <Typography variant="h1">Wow, you're really optimized...</Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
          }}
        >
          We did not detect any eligible on-demand workloads. If you recently enabled discount sharing, please check
          back in 48 hours.
          <Link href="mailto:sales@doit-intl.com">contact sales</Link>.
        </Typography>
      </Stack>
    </OnboardLayout>
  );
};
