import { useCallback, useEffect, useState } from "react";

import PhoneInput from "react-phone-input-2";
import { Box, Card, CardContent, TextField, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";

import { updateAccountManagerTexts } from "../../assets/texts";
import useMountEffect from "../../Components/hooks/useMountEffect";
import SimpleDialog from "../../Components/SimpleDialog";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { consoleErrorWithSentry } from "../../utils";
import { updateAccountManager } from "./db";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
  },
}));

export const UpdateAccountManagerDialog = () => {
  const theme = useTheme();
  const { accountManager } = AccountManagersHooks.useAccountManager();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(accountManager?.name);
  const [phone, setPhone] = useState(accountManager?.phone);
  const [nameError, setNameError] = useState(false);

  const handleUpdateAccountManager = useCallback(async () => {
    if (name && accountManager) {
      try {
        await updateAccountManager(name, phone ?? "", accountManager.ref);
        setOpen(false);
      } catch (e) {
        consoleErrorWithSentry(e);
      }
    }
  }, [accountManager, name, phone]);

  useMountEffect(() => {
    const openedInSession = sessionStorage.getItem("updateAccountManagerDialog");
    if (!openedInSession) {
      setOpen(true);
      sessionStorage.setItem("updateAccountManagerDialog", "true");
    }
  });

  const handleNameBlur = () => {
    if (!name) {
      setNameError(true);
    }
  };

  useEffect(() => {
    if (name) {
      setNameError(false);
    }
  }, [name]);

  if (!accountManager) {
    return null;
  }

  return (
    <SimpleDialog
      open={open}
      title={updateAccountManagerTexts.TITLE}
      onConfirm={handleUpdateAccountManager}
      onCancel={() => {
        setOpen(false);
      }}
      cancelButtonText={updateAccountManagerTexts.NOT_NOW}
      confirmButtonText={updateAccountManagerTexts.SAVE}
      disableConfirm={!name}
    >
      <Box
        sx={{
          pr: 1,
          pl: 1,
        }}
      >
        <Card elevation={0}>
          <CardContent
            classes={{
              root: classes.root,
            }}
          >
            <Grid container spacing={1}>
              <Grid size={12}>
                <TextField
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onBlur={handleNameBlur}
                  margin="dense"
                  defaultValue={name}
                  label={updateAccountManagerTexts.NAME}
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  error={nameError}
                  helperText={nameError ? updateAccountManagerTexts.NAME_REQUIRED : ""}
                  slotProps={{
                    input: {
                      inputProps: {
                        maxLength: 100,
                      },
                    },
                  }}
                />
              </Grid>
              <Grid size={12}>
                <PhoneInput
                  searchPlaceholder={updateAccountManagerTexts.PHONE_SEARCH_PLACEHOLDER}
                  value={phone}
                  onChange={(p) => {
                    setPhone(p);
                  }}
                  countryCodeEditable
                  enableSearch
                  disableSearchIcon
                  enableAreaCodes
                  enableAreaCodeStretch
                  preferredCountries={["il", "us", "fr", "au", "de", "gb", "nl"]}
                  country="us"
                  inputStyle={{
                    width: "100%",
                    height: 40,
                    backgroundColor: "transparent",
                    color: theme.palette.text.primary,
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  onChange={(e) => setPhotoURL(e.target.value)}
                  margin="dense"
                  variant="outlined"
                  defaultValue={photoURL}
                  label={updateAccountManagerTexts.PHOTO_URL}
                  type="text"
                  fullWidth
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </SimpleDialog>
  );
};
