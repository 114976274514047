import { type Dispatch, type SetStateAction, useCallback } from "react";

import { TimeSettingsMode } from "@doitintl/cmp-models";
import { Box, Button, Divider, MenuItem, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { globalText } from "../../../../../assets/texts";
import { type TimeRangeOption, TimeRangeOptions } from "../../../utilities";
import TimeSelectionTabs from "./Tabs/TimeSelectionTabs";

type TimeRangeSelectionProps = {
  onSave: () => void;
  timeRangeOption: TimeRangeOption;
  setTimeRangeOption: Dispatch<SetStateAction<TimeRangeOption>>;
  handleClose: (updateTimeRangeLocal?: boolean) => void;
};

const TimeRangeSelection = ({ onSave, timeRangeOption, setTimeRangeOption, handleClose }: TimeRangeSelectionProps) => {
  const selected = useCallback(
    (option: TimeRangeOption) => {
      if (option.mode === timeRangeOption.mode && option.time === timeRangeOption.time) {
        if (option.amount) {
          return option.amount === timeRangeOption.amount;
        }
        return true;
      }
    },
    [timeRangeOption]
  );

  const disabled =
    timeRangeOption.mode === TimeSettingsMode.Fixed && (!timeRangeOption.range?.start || !timeRangeOption.range?.end);
  return (
    <Box>
      <Grid container data-cy="time-range-selection">
        <Grid sx={{ width: 160 }}>
          <Typography sx={{ px: 2, pb: 0.5, pt: 2 }} variant="subtitle2">
            {globalText.PRESETS}
          </Typography>
          {TimeRangeOptions.map((option) => (
            <MenuItem
              data-cy={option.label}
              dense
              key={option.label}
              onClick={() => {
                setTimeRangeOption(option);
              }}
              selected={selected(option)}
              value={option.label}
            >
              {option.label}
            </MenuItem>
          ))}
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid>
          <TimeSelectionTabs setTimeRangeOption={setTimeRangeOption} timeRangeOption={timeRangeOption} />
        </Grid>
      </Grid>
      <Divider />
      <Stack
        direction="row"
        sx={{
          justifyContent: "flex-end",
          p: 1,
        }}
      >
        <Button
          onClick={() => {
            handleClose(true);
          }}
          data-cy="time-range-cancel"
        >
          {globalText.CANCEL}
        </Button>
        <Button disabled={disabled} onClick={onSave} variant="contained" data-cy="time-range-save">
          {globalText.SAVE}
        </Button>
      </Stack>
    </Box>
  );
};

export default TimeRangeSelection;
