import { type Key, useMemo } from "react";

import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { AlertTitle, Box, IconButton, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import find from "lodash/find";

import { useCustomerContext } from "../../Context/CustomerContext";
import { type IssueWidget, KnownIssuePlatform } from "../../Pages/KnownIssues/types";
import { useSessionStorage } from "../hooks/storageHooks";

const GOOGLE_PLATFORM = "google_cloud_platform";

type Props = {
  issues: any;
  customerServices: any[];
};

export default function KnownIssuesWidget({ issues, customerServices }: Props) {
  const { customer } = useCustomerContext();
  const [disabledKnownIssueWidget, setDisabledKnownIssueWidget] = useSessionStorage<boolean>("knownIssueWidget", false);

  const filterdIssues = useMemo(() => {
    if (!customerServices || customerServices.length === 0) {
      return [];
    }
    return issues.filter((element: IssueWidget) => {
      const platform =
        typeof customerServices[0] === "string" && element.platform === KnownIssuePlatform.GCP
          ? GOOGLE_PLATFORM
          : element.platform;
      return find(customerServices, { asset: platform }) ?? customerServices.includes(platform);
    });
  }, [customerServices, issues]);

  const formatText = (txt: string): string => {
    const i = txt?.indexOf("- Alert");
    if (i && i !== -1) {
      txt = txt.substring(0, i);
    }
    return txt;
  };

  const navigateToIssue = (id: string) => {
    window.open(`/customers/${customer.id}/known-issues/${id}`, "_blank");
  };

  const onCloseAlert = () => {
    setDisabledKnownIssueWidget(true);
  };

  if (disabledKnownIssueWidget || !filterdIssues || filterdIssues?.length === 0) {
    return null;
  }

  return (
    <Alert onClose={onCloseAlert} severity="warning" data-cy="alertDialog">
      <AlertTitle>Cloud incidents</AlertTitle>
      {filterdIssues?.map((item: IssueWidget, key: Key | null | undefined) => (
        <Box
          key={key}
          sx={{
            mb: -0.4,
          }}
        >
          <Typography variant="body2">
            {formatText(item.title)}
            <IconButton
              onClick={() => {
                navigateToIssue(item.docId);
              }}
              color="inherit"
              size="small"
            >
              <OpenNewIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Typography>
        </Box>
      ))}
    </Alert>
  );
}
