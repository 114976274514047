import { useEffect, useMemo } from "react";

import { Criteria, type FlexsavePayerConfigs } from "@doitintl/cmp-models";
import { Box, CircularProgress, Toolbar, Typography } from "@mui/material";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { AWSOpsRows } from "./AWSOpsRows";
import { type ExtendedFlexsavePayerConfigs } from "./AWSPayerConfigForm/types";
import { makeFormDiscountValues } from "./AWSPayerConfigForm/utils";
import { filterColumns, headerColumns } from "./columns";
import { useFlexsavePayerConfigs } from "./hooks";

const preprocessTableData = (data: FlexsavePayerConfigs[]): ExtendedFlexsavePayerConfigs[] =>
  data.map((item) => {
    const discountVals = item.discountDetails ? makeFormDiscountValues(item.discountDetails) : {};
    return {
      ...item,
      computeDiscount: discountVals[Criteria.flexsave]?.discount,
      computeEffectiveDate: discountVals[Criteria.flexsave]?.effectiveDate,
      sagemakerDiscount: discountVals[Criteria.sagemaker]?.discount,
      sagemakerEffectiveDate: discountVals[Criteria.sagemaker]?.effectiveDate,
      rdsDiscount: discountVals[Criteria.rds]?.discount,
      rdsEffectiveDate: discountVals[Criteria.rds]?.effectiveDate,
    };
  });

export const FlexsaveAWSOps = () => {
  const { data: rawTableData, error } = useFlexsavePayerConfigs();
  const showError = useErrorSnackbar();

  useEffect(() => {
    if (!error) {
      return;
    }
    showError("Failed to fetch payer configs");
  }, [error, showError]);

  const tableData = useMemo(() => {
    if (rawTableData) {
      return preprocessTableData(rawTableData);
    }
    return [];
  }, [rawTableData]);

  if (!rawTableData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 6,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (rawTableData.length === 0) {
    return (
      <Toolbar>
        <Typography variant="body2">No Data Available</Typography>
      </Toolbar>
    );
  }

  return (
    <FilterTable
      tableItems={tableData}
      rowComponent={AWSOpsRows}
      headerColumns={headerColumns}
      filterColumns={filterColumns}
      toolbarProps={{
        title: "Flexsave AWS Ops",
        allowToEditColumns: true,
      }}
    />
  );
};

export default FlexsaveAWSOps;
