import { useMemo } from "react";

import {
  CalcMetricFormat,
  type ComparativeFeature,
  CurrencyCodes,
  Metadata,
  Metric,
  Positions,
} from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Box, Chip, Drawer, IconButton, List, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import clsx from "clsx";

import { cloudAnalyticsText, metricFiltersText, reportText } from "../../../assets/texts";
import useFormatter from "../../../Components/hooks/useFormatter";
import { type MetadataOption } from "../../../types";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import { useReportConfig } from "../Context/ReportConfigContext";
import { useStyles } from "../ReportStyles";
import { getMetricFilterCondition, getMetricLabel, maxChips } from "../utilities";

type Props = {
  metadata: MetadataOption[];
  open: boolean;
  handleClose: () => void;
  extendedMetric?: string;
  calculatedMetricFormatter: (value: any, short?: any, comparative?: ComparativeFeature) => any;
};

const ReportRightMenu = ({ metadata, open, handleClose, extendedMetric = "", calculatedMetricFormatter }: Props) => {
  const classes: any = useStyles();
  const { transforms } = useAnalyticsContext();
  const { reportConfig } = useReportConfig();
  const { aggregator, currency, metric, description, calculatedMetric, metricFilters } = reportConfig;
  const baseFormatter = useFormatter({ aggregator, currency: currency || CurrencyCodes.USD, metric });

  const currentFilters = useMemo(
    () =>
      metadata.filter((option) => {
        const limit = option._position === Positions.ROW ? option._limit : null;
        const hasFilters =
          (option._filter || [])?.length > 0 || (option._regexp ?? "")?.length > 0 || (limit && limit > 0);
        return option._visible && hasFilters;
      }),
    [metadata]
  );

  const rowsColsData = useMemo(() => {
    const rows: MetadataOption[] = [];
    const cols: MetadataOption[] = [];

    metadata.forEach((option) => {
      if (option._visible && option._position === Positions.ROW) {
        rows.push(option);
      } else if (option._visible && option._position === Positions.COL) {
        cols.push(option);
      }
    });

    return [
      { items: rows, title: reportText.GROUPINGS },
      { items: cols, title: reportText.DIMENSIONS },
    ];
  }, [metadata]);

  const formatLimitDescription = (mdFilter: MetadataOption, extendedMetric: string, calculatedMetric): string => {
    const limitMetric = mdFilter._limitMetric || Metric.COST;
    const order = mdFilter._limitOrder === "asc" ? cloudAnalyticsText.LIMITS.BOTTOM : cloudAnalyticsText.LIMITS.TOP;
    const label = getMetricLabel(limitMetric, extendedMetric, calculatedMetric?.data.name);

    return `Limit to ${order} ${mdFilter._limit} ${cloudAnalyticsText.LIMITS.BY} ${label}`;
  };

  const getFilterText = (mdFilter: MetadataOption) => {
    const negation = mdFilter._inverse ? "not" : "";

    if (mdFilter._filter && mdFilter._filter.length > 0) {
      if (mdFilter._filter.length > 1) {
        return ` is ${negation} one of`;
      }
      return ` ${negation} equals`;
    } else if (mdFilter._regexp && mdFilter._regexp.length > 0) {
      return ` ${negation} matches regexp`;
    }

    return "";
  };

  const getMetadatKey = (mdFilterID: string) => {
    if (mdFilterID.startsWith(Metadata.ATTRIBUTION_GROUP)) {
      return Metadata.ATTRIBUTION_GROUP;
    }
    return mdFilterID;
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        ".MuiDrawer-paper": {
          margin: 1,
          borderRadius: 0.5,
          height: "100%",
          overflow: "hidden",
        },
      }}
    >
      <Box
        sx={{
          minWidth: 250,
          maxWidth: 450,
          height: "100%",
          position: "relative",
          overflow: "auto",
        }}
      >
        <Stack
          spacing={4}
          direction="row"
          sx={(theme) => ({
            mt: 0,
            px: 3,
            py: 2,
            alignItems: "center",
            position: "sticky",
            top: 0,
            justifyContent: "space-between",
            backgroundColor: theme.palette.background.paper,
            zIndex: 100,
          })}
        >
          <Typography variant="h3">{reportText.REPORT_DETAILS}</Typography>
          <IconButton data-cy="close-drawer" onClick={handleClose} sx={{ ml: "auto" }} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack spacing={4} sx={{ px: 3, mt: 2 }}>
          {description.length > 0 && (
            <Stack>
              <Typography variant="h4">{reportText.REPORT_DESCRIPTION}</Typography>
              <Typography variant="body1" color="textSecondary">
                {description}
              </Typography>
            </Stack>
          )}

          {(currentFilters.length > 0 || metricFilters.length > 0) && (
            <Stack>
              <Box>
                <Typography variant="h4">{reportText.FILTERS}</Typography>
                <List sx={{ pt: 1 }} disablePadding>
                  {metricFilters.length > 0 && (
                    <ListItem key="metric-filter" sx={{ pt: 0, pb: 2 }} disableGutters>
                      <Grid container>
                        <Grid size={12}>
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                              fontWeight: "500",
                              display: "inline",
                            }}
                          >
                            {metricFiltersText.TITLE}
                          </Typography>
                        </Grid>
                        <Grid size={12}>
                          {metricFilters.map((f, i) => {
                            const values = f.values.map((v) => {
                              if (f.metric === Metric.CALCULATED) {
                                // we don't want the filter value be multiplied by 100 in formatter
                                return calculatedMetric?.data.format === CalcMetricFormat.PRECENTAGE
                                  ? calculatedMetricFormatter(v / 100)
                                  : calculatedMetricFormatter(v);
                              }
                              return baseFormatter(v);
                            });
                            return (
                              <Grid key={`metric-filter-${i}`} size={12}>
                                <Typography variant="body1" color="textSecondary">
                                  {getMetricFilterCondition(f, values, calculatedMetric, extendedMetric)}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </ListItem>
                  )}
                  {currentFilters.map((mdFilter) => (
                    <ListItem key={mdFilter.id} sx={{ py: 2 }} disableGutters>
                      <Grid container>
                        <Grid size={12}>
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                              fontWeight: "500",
                              display: "inline",
                            }}
                          >
                            {mdFilter?.data.label ?? ""}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                              display: "inline",
                            }}
                          >
                            {getFilterText(mdFilter)}
                          </Typography>
                        </Grid>

                        <Grid size={12}>
                          {mdFilter._filter && mdFilter._filter.length > 0 ? (
                            <>
                              {mdFilter._filter.slice(0, maxChips).map((v) => (
                                <Tooltip title={v} key={v}>
                                  <Chip
                                    label={
                                      transforms?.[getMetadatKey(mdFilter.id)]?.(v, mdFilter.data.nullFallback) ?? v
                                    }
                                    size="small"
                                    variant="outlined"
                                    color={mdFilter._inverse ? "secondary" : "primary"}
                                    className={classes.chip}
                                  />
                                </Tooltip>
                              ))}
                              {mdFilter._filter.length > maxChips && (
                                <Chip
                                  label={`+${mdFilter._filter.length - maxChips}`}
                                  size="small"
                                  color="default"
                                  variant="outlined"
                                  className={classes.chip}
                                />
                              )}
                            </>
                          ) : (
                            !!mdFilter._regexp &&
                            mdFilter._regexp.length > 0 && (
                              <Chip
                                label={mdFilter._regexp || ""}
                                size="small"
                                variant="outlined"
                                color={mdFilter._inverse ? "secondary" : "primary"}
                                className={classes.chip}
                              />
                            )
                          )}
                          {!!mdFilter._limit && mdFilter._limit > 0 && (
                            <Chip
                              label={formatLimitDescription(mdFilter, extendedMetric, calculatedMetric)}
                              size="small"
                              variant="outlined"
                              color="default"
                              className={clsx(classes.chip, classes.chipGreen)}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Stack>
          )}
          {rowsColsData.map(
            ({ items, title }) =>
              items.length > 0 && (
                <Stack key={title}>
                  <Box>
                    <Typography variant="h4">{title}</Typography>
                    <List sx={{ pt: 1 }} disablePadding>
                      <ListItem key={title} sx={{ py: 1 }} disableGutters>
                        <Grid container size={12}>
                          {items.map((mdFilter) => (
                            <Chip
                              key={mdFilter.id}
                              label={mdFilter?.data.label ?? ""}
                              size="small"
                              variant="outlined"
                              color="primary"
                              className={classes.chip}
                            />
                          ))}
                        </Grid>
                      </ListItem>
                    </List>
                  </Box>
                </Stack>
              )
          )}
        </Stack>
      </Box>
    </Drawer>
  );
};

export default ReportRightMenu;
