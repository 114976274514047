import { useCallback, useMemo, useState } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData, type WithFirebaseModel } from "@doitintl/models-firestore";
import { Box, Button, Typography } from "@mui/material";

import OnHoldAddForm from "./OnHoldAddForm";
import OnHoldList from "./OnHoldList";
import { texts } from "./texts";
import { type CustomersOnHold } from "./types";

const OnHold = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const transform = useCallback(
    (data: WithFirebaseModel<CustomerModel>, snapshot) => ({
      primaryDomain: data.primaryDomain,
      invoicesOnHold: data.invoicesOnHold,
      id: snapshot.id,
    }),
    []
  );

  const [customersOnHoldData, customersLoading] = useCollectionData(
    getCollection(CustomerModel).where("invoicesOnHold", "!=", ""),
    {
      transform,
    }
  );

  const customersOnHold = useMemo(() => {
    const customersOnHold: CustomersOnHold = {};
    customersOnHoldData?.forEach((customer) => {
      if (!customer.invoicesOnHold) {
        return;
      }
      customersOnHold[customer.id] = {
        invoicesOnHold: customer.invoicesOnHold,
        primaryDomain: customer.primaryDomain,
      };
    });
    return customersOnHold;
  }, [customersOnHoldData]);

  return (
    <Box
      sx={{
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: 3,
        }}
      >
        <Typography variant="h1" data-cy="page-title">
          {texts.CUSTOMERS_ON_HOLD_TITLE}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setDialogOpen(true);
          }}
          data-cy="add-hold"
        >
          {texts.ADD_HOLD_BUTTON_TEXT}
        </Button>
      </Box>
      <OnHoldList customersOnHold={customersOnHold} customersLoading={customersLoading} />
      <OnHoldAddForm
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        customersLoading={customersLoading}
        customersOnHold={customersOnHold}
      />
    </Box>
  );
};

export default OnHold;
