import { useCallback, useState } from "react";

import { DoitRole } from "@doitintl/cmp-models";
import { getBatch } from "@doitintl/models-firestore";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";

import { globalText, marketplaceText } from "../../../../assets/texts";
import DeleteDialog from "../../../../Components/DeleteDialog";
import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { useDoitRoleCheck } from "../../../../Components/hooks/useDoitRoles";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import mixpanel from "../../../../utils/mixpanel";
import { type AdjustmentConfig } from "../hooks/useMarketplaceAdjustments";
import { useMarketplaceContext } from "../MarketplaceContext";
import { AdjustmentRow } from "./AdjustmentRow";
import { filterColumns, headerColumns } from "./columns";
import CreateEditDialog from "./CreateEditDialog";

const MarketplaceGCPAdjustments = () => {
  const { adjustments } = useMarketplaceContext();
  const isMarketplaceAdmin = useDoitRoleCheck(DoitRole.MarketplaceAdmin);
  const [clickedRow, setClickedRow] = useState<AdjustmentConfig | null>(null);
  const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selected, setSelected] = useState<AdjustmentConfig[]>([]);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const adjustmentRowWrapper = useCallback(
    ({ row }: { row: AdjustmentConfig }) => (
      <AdjustmentRow
        setOpenDeleteDialog={setOpenDeleteDialog}
        setEditDialogOpen={setOpenCreateEditDialog}
        setClickedRow={setClickedRow}
        row={row}
      />
    ),
    []
  );

  const handleDeleteAdjustment = useCallback(async () => {
    try {
      if (!clickedRow) {
        const batch = getBatch();
        selected.forEach((adjustment) => {
          batch.delete(adjustment.ref);
        });
        await batch.commit();
        successSnackbar(marketplaceText.ADJUSTMENT_DELETED_SUCCESSFULLY);
        setSelected([]);
      } else {
        clickedRow.ref.delete();
        setClickedRow(null);
      }
      mixpanel.track("marketplace.gcp.adjustment.delete");
    } catch (error) {
      consoleErrorWithSentry(error);
      errorSnackbar(marketplaceText.ADJUSTMENT_DELETE_ERROR);
    }
  }, [clickedRow, errorSnackbar, selected, successSnackbar]);

  if (!isMarketplaceAdmin || !adjustments) {
    return null;
  }

  return (
    <>
      <FilterTable<AdjustmentConfig>
        defaultSortingColumnValue="transform.targetTime"
        filterBarPlaceholder={marketplaceText.FILTER_ADJUSTMENTS}
        filterColumns={filterColumns}
        headerColumns={headerColumns}
        itemUniqIdentifierField="ref.id"
        onRowsSelected={setSelected}
        persistenceKey="adjustment-gcp-marketplace"
        rowComponent={adjustmentRowWrapper}
        showRowsSelection={true}
        tableItems={adjustments}
        toolbarProps={{ allowToEditColumns: true }}
      >
        <Grid>
          <Button
            disabled={!selected.length || selected.some((b) => b.data.status === "DONE")}
            variant="contained"
            color="error"
            onClick={() => {
              setOpenDeleteDialog(true);
            }}
          >
            {globalText.DELETE}
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenCreateEditDialog(true);
            }}
          >
            {marketplaceText.NEW_ADJUSTMENT}
          </Button>
        </Grid>
      </FilterTable>
      {openDeleteDialog && (
        <DeleteDialog
          message={marketplaceText.ADJUSTMENT_DELETE_MESSAGE}
          onClose={() => {
            setOpenDeleteDialog(false);
            setClickedRow(null);
          }}
          onDelete={handleDeleteAdjustment}
          open={openDeleteDialog}
          title={marketplaceText.DELETE_ADJUSTMENT}
        />
      )}
      {openCreateEditDialog && (
        <CreateEditDialog
          clickedRow={clickedRow}
          onClose={() => {
            setClickedRow(null);
            setOpenCreateEditDialog(false);
          }}
        />
      )}
    </>
  );
};

export default MarketplaceGCPAdjustments;
