import { Container, Stack, Typography } from "@mui/material";

import { type FAQ, FAQAccordion } from "../../../Components/FAQ/FAQAccordion";
import useMountEffect from "../../../Components/hooks/useMountEffect";
import { PackagesSelector } from "../Components/PackagesSelector";
import { type PackageType, type ServiceType } from "../types";

type Props = {
  selectedPackageType: PackageType;
  setSelectedPackageType: (serviceType: PackageType) => void;
  selectedServiceType: ServiceType;
};

const faqs: FAQ[] = [
  {
    question: "What is the difference between Standard, Enhanced, and Premium?",
    answer:
      "Standard is ideal for digital native Startups. Enhanced is ideal for scaling digital natives. Premium is ideal for established digital natives.",
  },
  {
    question: "What is an accelerator?",
    answer: "An accelerator is a tool that helps you speed up your cloud journey.",
  },
  {
    question: "What is the difference between support and premium support?",
    answer: "Premium support is a higher tier of support that includes more features.",
  },
];

export const PackageTypeStep = ({ selectedPackageType, setSelectedPackageType, selectedServiceType }: Props) => {
  useMountEffect(() => {
    if (selectedServiceType === "subscription" && ["accelerator", "single-ticket"].includes(selectedPackageType)) {
      setSelectedPackageType("standard");
    } else if (
      selectedServiceType === "one-time" &&
      ["standard", "enhanced", "premium"].includes(selectedPackageType)
    ) {
      setSelectedPackageType("single-ticket");
    }
  });

  return (
    <Container maxWidth="sm" sx={{ pt: 6 }}>
      <Stack
        sx={{
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            pb: 1,
          }}
        >
          Select package type
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
          }}
        >
          {selectedServiceType === "subscription"
            ? "Gain access to our best-in-class Cloud Reliability Engineers and ensure your cloud infrastructure performs at its best."
            : "Utilise our best-in-class Cloud Reliability Engineers on a one-time basis"}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={4}
          sx={{
            justifyContent: "center",
            pt: 4,
          }}
        >
          <PackagesSelector
            selectedPackageType={selectedPackageType}
            setSelectedPackageType={setSelectedPackageType}
            selectedServiceType={selectedServiceType}
          />
        </Stack>
        <Stack
          spacing={2}
          sx={{
            pt: 6,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "500",
            }}
          >
            Frequently asked questions
          </Typography>
          <FAQAccordion faqs={faqs} />
        </Stack>
      </Stack>
    </Container>
  );
};
