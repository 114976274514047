import { Box, Typography } from "@mui/material";
import { type TypographyProps } from "@mui/material/Typography/Typography";

import { type ParsedAttributeWithStyle } from "./types";

type Props = {
  parts: ParsedAttributeWithStyle[];
  typographyProps?: TypographyProps;
};
export const Highlight = ({ parts, typographyProps = {} }: Props) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
    }}
  >
    {parts?.map((part, index) => (
      <Typography
        key={`${index}${part.value}`}
        {...typographyProps}
        sx={[
          {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "pre",
            display: "inline-block",

            ...(part.isHighlighted && {
              fontWeight: 700,
              color: "primary.main",
            }),

            ...(part.bold && {
              fontWeight: 700,
            }),
          },
          ...(Array.isArray(typographyProps.sx) ? typographyProps.sx : [typographyProps.sx]),
        ]}
      >
        {part.value}
      </Typography>
    ))}
  </Box>
);
