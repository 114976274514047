import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";

import { cyIds } from "./LicenseChangeDialog";

type AddOrRemoveProps = {
  adding: boolean;
  setAdding: (boolean) => void;
  delta: number;
  maxNegativeDelta: number;
  usageSummary: JSX.Element;
  setDelta: (value: number) => void;
  setError: (error: boolean) => void;
  removeDisabled: boolean;
  commitment: boolean;
};

/**
 * First step of the {@link LicenseChangeDialog} process, wherein the user chooses whether they are adding or removing licences.
 * @param adding whether the user is adding or removing licenses
 * @param setAdding function that tells the rest of the dialog whether we are adding or removing licenses
 * @param delta the amount of licences to add or remove. Will only be non-zero here if the user has come _back_ to this page.
 * @param maxNegativeDelta The greatest quantity of licences that can be removed from the asset
 * @param usageSummary Element showing the usage of the licenses on the asset
 * @param setDelta function that sets the parent dialog's ∆ licenses value
 * @param setError function that sets the parent error state
 * @param removeDisabled whether to disable the ability to remove licenses
 * @param commitment Is the asset on a commitment plan?
 * @see The tests for LicenseChangeDialog for test coverage of this component's rendering and behaviour in-context.
 */
const AddOrRemoveLicenses = ({
  adding,
  setAdding,
  delta,
  maxNegativeDelta,
  usageSummary,
  setDelta,
  setError,
  removeDisabled,
  commitment,
}: AddOrRemoveProps) => {
  const [allowNext, setAllowNext] = useState<boolean>(delta > 0 || maxNegativeDelta === 0 || commitment);

  const removeDisabledReason = useMemo<string | undefined>(() => {
    if (!removeDisabled) {
      return undefined;
    }

    if (commitment) {
      return "Cannot remove seats on a commitment plan";
    }

    if (maxNegativeDelta === 0) {
      return "Cannot remove used seats";
    }
  }, [commitment, maxNegativeDelta, removeDisabled]);

  const handleRadioClick = useCallback(
    (event) => {
      setAdding(event.target.value === "add");
      setDelta(0);
      setAllowNext(true);
    },
    [setAdding, setDelta]
  );

  useEffect(() => {
    setError(!allowNext);
  }, [allowNext, setError]);

  const conditionallyPersistedDefault = delta > 0 && (adding ? "add" : "remove");

  return (
    <Stack
      data-cy={cyIds.content.steps[0]}
      sx={{
        gap: 2,
      }}
    >
      {usageSummary}
      <FormControl>
        <RadioGroup defaultValue={removeDisabled ? "add" : conditionallyPersistedDefault} sx={{ width: "19ch" }}>
          <FormLabel>
            <Typography
              variant="subtitle1"
              sx={{
                color: "text.primary",
                fontWeight: "medium",
              }}
            >
              License management
            </Typography>
          </FormLabel>
          <FormControlLabel
            label="Add licenses"
            control={
              <Radio color="primary" value="add" onClick={handleRadioClick} data-cy={cyIds.content.step0.adding} />
            }
          />
          <Tooltip title={removeDisabledReason} arrow placement="right">
            <FormControlLabel
              label="Remove licenses"
              control={
                <Radio
                  color="primary"
                  value="remove"
                  onClick={handleRadioClick}
                  data-cy={cyIds.content.step0.removing}
                  disabled={removeDisabled}
                />
              }
            />
          </Tooltip>
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default AddOrRemoveLicenses;
