import { Box, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

export const CircularProgressWithLabel = ({ value }) => (
  <Grid
    size={{
      sm: 4,
    }}
  >
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress variant="determinate" value={value} color="primary" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {value}%
        </Typography>
      </Box>
    </Box>
  </Grid>
);
