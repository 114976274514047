import { useCallback, useEffect, useMemo, useState } from "react";

import { Metric, Roles, type TimeInterval } from "@doitintl/cmp-models";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { budgetText, globalText } from "../../../assets/texts";
import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { generateHeadersAndFilters } from "../../../Components/FilterTable/filterTableUtils";
import FilterTableToolbarMeteredTitle from "../../../Components/FilterTable/Toolbar/FilterTableToolbarMeteredTitle";
import Hide from "../../../Components/HideChildren/Hide";
import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { useTierLimitReachedAnalyticsGovernanceBudgets } from "../../../Context/AnalyticsTierProvider";
import { useAuthContext } from "../../../Context/AuthContext";
import { useTier } from "../../../Context/TierProvider";
import { type Budget } from "../../../types";
import { formatNumber, getCurrencySymbol, getDateTimeFromFirestoreTimestamp } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { addAnalyticsWindowFunction } from "../../../utils/windowInit";
import { budgetsToAnalyticsResources } from "../analyticsResources/utils";
import ShareDialog from "../dialogs/shareDialog/ShareDialog";
import AssignLabelsButton from "../labels/components/AssignLabelsButton";
import { useLabels } from "../labels/hooks";
import {
  type BudgetRowActions,
  BudgetTypes,
  BudgetTypesOptions,
  CloudAnalyticsEntities,
  timeIntervalOptions,
  useCanEditSelectedObjects,
  useCanEditSelectedObjectsPermissions,
} from "../utilities";
import { itemsDescriptions } from "./BudgetBrowserColumns";
import { BudgetRow, type BudgetRowData } from "./BudgetBrowserRow";
import { useCreateBudgetHandler, useDeleteBudgetHandler, useShareBudgetHandler } from "./hooks";
import { getUtilizationProgressBarColor } from "./shared";
import { fullPreviewAmountByInterval, previewAmountByInterval } from "./utils";

const { budgets: budgetIds } = cloudAnalytics;
const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);

export const BudgetBrowser = () => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { getFeatureLimit, getFeatureName } = useTier();
  const routeMatchURL = useRouteMatchURL();

  const [selected, setSelected] = useState<Budget[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const isBulk = selected.length >= 1;

  const analyticsGovernanceBudgets = useTierLimitReachedAnalyticsGovernanceBudgets();

  const [_allBudgets, rawBaudget, budgetsLoading] = useBudgets();

  const getBudgetAmountToDate = useCallback((budgetAmount: number, timeInterval: TimeInterval): number => {
    if (!timeInterval) {
      return 0;
    }

    const intervalValue = fullPreviewAmountByInterval[timeInterval];
    const currentIntervalValue = previewAmountByInterval[timeInterval];

    const currentAmount = (budgetAmount / intervalValue) * currentIntervalValue;
    return currentAmount;
  }, []);

  const budgets = useMemo(
    () =>
      rawBaudget.map((b) => {
        const budgetAmountToDate = getBudgetAmountToDate(b.data.config.amount, b.data.config.timeInterval);

        return {
          ...b,
          data: {
            ...b.data,
            varianceToDate: budgetAmountToDate - (b.data?.utilization?.current ?? 0),
            budgetAmountToDate,
            utilization: {
              ...b.data.utilization,
              current: b.data.utilization?.current ?? 0,
              forecasted: b.data.utilization?.forecasted ?? 0,
            },
          },
        };
      }),
    [getBudgetAmountToDate, rawBaudget]
  );

  const [labels, labelsLoading] = useLabels();

  useEffect(() => {
    const updatedSelectedBudgets = budgets.filter((budget) => selected.some((x) => x.ref.id === budget.ref.id));

    setSelected(updatedSelectedBudgets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgets]);

  const handleNewBudget = useCreateBudgetHandler({
    baseUrl: routeMatchURL,
    mixpanelEventName: "analytics.budgets.new",
  });

  useEffect(() => {
    addAnalyticsWindowFunction({ handleNewBudget });
  }, [handleNewBudget]);

  const handleDeleteBudget = useDeleteBudgetHandler({
    selected,
    closeDialog: () => {
      setSelected([]);
      setOpenDeleteDialog(false);
    },
  });

  useEffect(() => {
    mixpanel.track("analytics.budgets.list");
  }, []);

  const getBudgetProjectedDate = useCallback((r) => {
    if (r?.data?.utilization?.forecastedTotalAmountDate) {
      return getDateTimeFromFirestoreTimestamp(r?.data?.utilization?.forecastedTotalAmountDate).toFormat(
        "LLL dd, yyyy"
      );
    }
    return "N/A";
  }, []);

  const getBudgetPeriodValue = useCallback((r) => {
    if (r?.data?.config?.type === BudgetTypes.RECURRING) {
      return (timeIntervalOptions ?? []).find((option) => option.value === r.data?.config?.timeInterval)
        ?.alternateLabel;
    }
    return BudgetTypesOptions.find((option) => option.value === BudgetTypes.FIXED)?.label;
  }, []);

  const BudgetRowWrapper = ({ row }: { row: Budget }) => {
    const data: BudgetRowData = {
      name: row.data.name,
      timeModified: row.data.timeModified,
      amount: `${row.data?.config?.metric === Metric.COST ? getCurrencySymbol(row.data?.config?.currency) : "(usage)"}${formatNumber(row.data.config.amount)}`,
      currentPercentage:
        row.data.config.amount && row.data.utilization?.current
          ? (row.data.utilization?.current / (row.data.config.amount || 1)) * 100
          : 0,
      period: getBudgetPeriodValue(row),
      isRecipient: !!row.data?.recipients?.find((r) => r === currentUser.email),
      progressBarColor: getUtilizationProgressBarColor(row.data),
      budgetProjectedDate: getBudgetProjectedDate(row),
      snapshotId: row.ref.id,
      collaborators: row.data.collaborators,
      public: row.data.public,
      recipients: row.data.recipients,
      recipientsSlackChannels: row.data.recipientsSlackChannels ?? [],
      row,
      labels,
      currentSpend: `${getCurrencySymbol(row.data?.config?.currency)}${formatNumber(row.data?.utilization?.current)}`,
      budgetAmountToDate: `${getCurrencySymbol(row.data?.config?.currency)}${formatNumber(row.data.budgetAmountToDate)}`,
      varianceToDate: `${getCurrencySymbol(row.data?.config?.currency)}${formatNumber(row.data.varianceToDate)}`,
    };

    const actions: BudgetRowActions = {
      setOpenDeleteDialog,
      setSelected,
      setShareDialogOpen,
    };

    return (
      <BudgetRow actions={actions} data={data} tierLimitReached={analyticsGovernanceBudgets?.limitReached ?? true} />
    );
  };

  const disableDeleteBudget = useMemo(
    () =>
      !selected.length ||
      !selected?.every((s) =>
        s.data.collaborators.find((c) => c.role === Roles.OWNER && c.email === currentUser.email)
      ),
    [currentUser.email, selected]
  );
  const handleChangeSharing = useShareBudgetHandler({
    setLoading: setShareLoading,
    closeDialog: () => {
      setShareDialogOpen(false);
    },
    selected,
  });

  const canEditSelectedBudgets = useCanEditSelectedObjects(
    currentUser.email,
    selected.map((x) => x.data)
  );

  const canEditPermissions = useCanEditSelectedObjectsPermissions(
    currentUser.email,
    selected.map((x) => x.data)
  );

  const toolbarTitle = useMemo(() => {
    const featureName = getFeatureName("governance:budgets") as string;
    const limit = getFeatureLimit("governance:budgets") as number | null;
    if (limit) {
      return (
        <FilterTableToolbarMeteredTitle
          value={analyticsGovernanceBudgets?.size ?? 0}
          limit={limit}
          featureName={featureName}
          loading={analyticsGovernanceBudgets.loading}
        />
      );
    }
    return featureName;
  }, [analyticsGovernanceBudgets, getFeatureLimit, getFeatureName]);

  if (labelsLoading || budgetsLoading) {
    return (
      <Box
        sx={{
          p: 1,
        }}
      >
        <FilterTableSkeleton />
      </Box>
    );
  }

  if (!labels || !budgets) {
    return null;
  }

  return (
    <>
      <FilterTable<Budget>
        showRowsSelection={true}
        onRowsSelected={setSelected}
        tableItems={budgets}
        rowComponent={BudgetRowWrapper}
        headerColumns={headerColumns}
        filterColumns={filters}
        filterBarPlaceholder={budgetText.FILTER_BUDGETS}
        persistenceKey="metric_tab"
        itemUniqIdentifierField="ref.id"
        defaultSortingColumnValue="data.timeModified"
        toolbarProps={{
          title: toolbarTitle,
        }}
      >
        <Grid>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenDeleteDialog(true);
            }}
            disabled={disableDeleteBudget}
            data-cy={budgetIds.browser.delete}
          >
            {globalText.DELETE}
          </Button>
        </Grid>
        <Grid>
          <AssignLabelsButton
            labels={labels}
            selectedResources={budgetsToAnalyticsResources(selected)}
            disabled={!canEditSelectedBudgets}
          />
        </Grid>
        <Grid>
          <Button
            variant="text"
            onClick={() => {
              setShareDialogOpen(true);
            }}
            disabled={!canEditPermissions}
          >
            {globalText.EDIT_PERMISSIONS}
          </Button>
        </Grid>
        <Grid>
          <Hide mdDown>
            <Button
              disabled={analyticsGovernanceBudgets.limitReached ?? true}
              variant="contained"
              color="primary"
              onClick={handleNewBudget}
              data-cy={budgetIds.browser.newBudget}
            >
              {budgetText.CREATE_NEW_BUDGET}
            </Button>
          </Hide>
        </Grid>
      </FilterTable>
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          title={budgetText.DELETE_SELECTED}
          message={budgetText.DELETE_MESSAGE}
          onDelete={handleDeleteBudget}
          onClose={() => {
            setSelected([]);
            setOpenDeleteDialog(false);
          }}
        />
      )}
      {shareDialogOpen && (
        <ShareDialog
          open={shareDialogOpen}
          title={isBulk ? budgetText.SHARE_BUDGETS : budgetText.SHARE_BUDGET}
          onClose={() => {
            setShareDialogOpen(false);
          }}
          entity={CloudAnalyticsEntities.BUDGET}
          handleChangeSharing={handleChangeSharing}
          loading={shareLoading}
          shareEntities={selected.map((x) => x.data)}
        />
      )}
    </>
  );
};
