import { type ReactNode } from "react";

import { UserNotification } from "@doitintl/cmp-models/src/User";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox, FormControl, FormControlLabel, Stack, Tooltip, Typography } from "@mui/material";
import { useField } from "formik";
import identity from "lodash/identity";

import { type NotificationWithGroupDescriptor } from "./useNotificationDescriptors";

type NotificationCheckboxProps = {
  notification: NotificationWithGroupDescriptor;
  disabled?: boolean;
  children?: ReactNode;
};

function areChildrenNotEmpty(children: ReactNode) {
  return children !== null && ((Array.isArray(children) && children.some(identity)) || !Array.isArray(children));
}

const NotificationCheckbox = ({ notification, disabled, children }: NotificationCheckboxProps) => {
  const [fieldProps, , { setValue }] = useField<object | undefined>({
    name: notification.value.toString(),
    type: "checkbox",
  });

  const { name } = fieldProps;

  return (
    <FormControl sx={{ paddingLeft: 2, marginTop: 0 }}>
      <FormControlLabel
        sx={{ maxWidth: "sm" }}
        control={
          <Checkbox
            {...fieldProps}
            disabled={disabled}
            onChange={(_, checked) => {
              if (!checked) {
                setValue(undefined);
                return;
              }

              // only applies to Daily and Weekly digests
              if ([UserNotification.WeeklyDigest.toString(), UserNotification.DailyDigest.toString()].includes(name)) {
                setValue({ attributions: [] });
                return;
              }

              setValue({});
            }}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            data-cy={`notification-${notification.value}`}
          />
        }
        label={
          <Tooltip
            title={notification.enabled ? notification.description : notification.missingPermissionsTooltip}
            placement="right"
          >
            <Typography variant="inherit">{notification.name}</Typography>
          </Tooltip>
        }
      />
      {areChildrenNotEmpty(children) && (
        <Stack
          sx={{
            ml: 3.5,
            mt: 1,
            maxWidth: 500,
          }}
        >
          {children}
        </Stack>
      )}
    </FormControl>
  );
};

export default NotificationCheckbox;
