import { type JSX } from "react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import Divider from "@mui/material/Divider";
import Drawer, { type DrawerProps } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { type SxProps, type Theme } from "@mui/system";

type Props = {
  open: boolean;
  title: string;
  onClose: () => void;
  disableGutters?: boolean;
  hideOverflow?: boolean;
  children: JSX.Element;
  headerElement?: JSX.Element;
  variant?: DrawerProps["variant"];
  sideDrawerSx?: SxProps<Theme>;
  dataCy?: string;
};
const drawerWidth = 450;

export const SideDrawer = ({
  headerElement,
  open,
  onClose,
  title,
  disableGutters = false,
  hideOverflow = true,
  children,
  variant,
  sideDrawerSx,
  dataCy,
}: Props) => (
  <Drawer
    anchor="right"
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        m: 1,
        borderRadius: 2,
        overflow: hideOverflow ? "hidden" : undefined,
        color: (theme) => theme.palette.text.primary,
        height: (theme) => `calc(100vh - ${theme.spacing(2)})`,
        ...sideDrawerSx,
      },
    }}
    variant={variant}
    sx={{
      width: open && variant === "persistent" ? drawerWidth : 0,
      flexShrink: 0,
    }}
  >
    <Stack
      data-cy={dataCy}
      sx={{
        width: drawerWidth,
        height: "100%",
      }}
    >
      <Stack
        direction="row"
        sx={{
          py: 1,
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
        }}
      >
        <Typography variant="h3">{title}</Typography>
        {headerElement}
        <IconButton data-cy="closeButton" aria-label="Close" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider sx={{ mb: 1 }} />
      <Stack
        sx={{
          px: disableGutters ? 0 : 2,
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {children}
      </Stack>
    </Stack>
  </Drawer>
);
