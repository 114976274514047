import { type JSX } from "react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import { Alert, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";

type Props = {
  setHidden: (hidden: boolean) => void;
  text: string;
  actions?: JSX.Element;
};

const Info = ({ setHidden, text, actions }: Props) => (
  <Grid
    container
    spacing={1}
    data-testid="info"
    data-cy="info"
    sx={{
      alignItems: "flex-start",
      pb: 1,
    }}
  >
    <Grid size={12}>
      <Alert
        severity="info"
        action={[
          actions ? actions : <></>,
          <IconButton
            key="dismiss"
            aria-label="Dismiss"
            size="small"
            sx={{ ml: 1 }}
            onClick={() => {
              setHidden(true);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>,
        ]}
      >
        {text}
      </Alert>
    </Grid>
  </Grid>
);

export default Info;
