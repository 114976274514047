import { useCallback, useEffect } from "react";

import { TimeInterval } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { reportText } from "../../../../assets/texts";
import { useKeyPress } from "../../../../Components/hooks/useKeyPress";
import useUpdateEffect from "../../../../Components/hooks/useUpdateEffect";
import { analytics } from "../../../../constants/jestIds";
import { useHotkeyContext } from "../../../../Context/HotkeyContext";
import { useReportConfig } from "../../Context";
import { useStyles } from "../../ReportStyles";

export type IncludeCreditsSwitchProps = {
  disabled: boolean;
};

export const IncludeCreditsSwitch = ({ disabled }: IncludeCreditsSwitchProps) => {
  const classes = useStyles();
  const {
    reportConfig: { includeCredits, timeInterval },
    dispatchReportConfig,
  } = useReportConfig();
  const { state: hotKeysEnabled } = useHotkeyContext();
  const hotkeyPressed = useKeyPress("c", { shiftKey: true });

  const onChange = useCallback(
    (val: boolean) => {
      dispatchReportConfig({ payload: { includeCredits: val } });
    },
    [dispatchReportConfig]
  );

  useUpdateEffect(() => {
    if (hotKeysEnabled && hotkeyPressed) {
      onChange(!includeCredits);
    }
  }, [hotkeyPressed, hotKeysEnabled]);

  const isValidTimeInterval = [TimeInterval.MONTH, TimeInterval.QUARTER, TimeInterval.YEAR].includes(timeInterval);

  useEffect(() => {
    if (!isValidTimeInterval) {
      onChange(false);
    }
  }, [isValidTimeInterval, onChange, timeInterval]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <FormControlLabel
        className={classes.includeCreditsForm}
        control={
          <Checkbox
            size="small"
            className={classes.includeCreditsCheckbox}
            name="includeCreditsCheckbox"
            disabled={disabled || !isValidTimeInterval}
            onChange={(event) => {
              onChange(event.target.checked);
            }}
            checked={!!includeCredits}
            color="primary"
          />
        }
        value={reportText.INCLUDE_PROMOTIONAL_CREDITS_LABEL}
        label={
          <Typography variant="caption">
            {reportText.INCLUDE_PROMOTIONAL_CREDITS_LABEL}
            <Tooltip
              title={reportText.INCLUDE_PROMOTIONAL_CREDITS_TOOLTIP}
              data-testid={analytics.includeCreditsTooltip}
            >
              <InfoOutlinedIcon
                htmlColor={grey[600]}
                sx={{ fontSize: "16px", position: "relative", top: "3px", left: "3px" }}
              />
            </Tooltip>
          </Typography>
        }
        classes={{
          root: classes.includeCreditsRoot,
        }}
      />
    </Box>
  );
};
