import { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import {
  AssetModel,
  AssetSettingModel,
  type BetterCloudCloudAssetModel,
  CatalogModel,
  ContractModel,
  type ZendeskCloudAssetModel,
  type ZendeskOrBetterCloudService,
} from "@doitintl/cmp-models";
import { getBatch, getCollection, type Model, type QueryDocumentSnapshotModel } from "@doitintl/models-firestore";
// Material UI Icons
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import ClearIcon from "@mui/icons-material/ClearRounded";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FieldArray, Form, type FormikProps, getIn, withFormik } from "formik";
// Project
import uniqBy from "lodash/uniqBy";
import { DateTime } from "luxon";
import * as Yup from "yup";

import { globalText } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { assetTypeName } from "../../../utils/common";
import { type FirestoreTimestamp } from "../../../utils/firebase";

const useStyles = makeStyles((theme) => ({
  root: {},
  addButton: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  formButton: {
    marginLeft: 4,
  },
  selectPaper: {
    maxHeight: 46 * 5 + 8,
  },
}));

const requiredError = "Required";
const Schema = Yup.object().shape({
  contract: Yup.object().required(requiredError),
  domain: Yup.string()
    .matches(/^[a-z0-9_.-]+$/, "Invalid. Allowed characters: a-z, 0-9, _-.")
    .required(requiredError),
  services: Yup.array(
    Yup.object().shape({
      skuId: Yup.string().required(requiredError),
      billingCycle: Yup.string().required(requiredError),
      quantity: Yup.number()
        .typeError("Must be a number")
        .moreThan(0, "Must be at least 1")
        .integer("Must be an integer")
        .required(requiredError),
    })
  )
    .min(1, "Must have at least one service")
    .required(requiredError),
});

const contractName = (contract: Contract) =>
  `${assetTypeName(contract.data.type)} - ${DateTime.fromJSDate(contract.data.startDate.toDate()).toLocaleString(
    DateTime.DATE_MED
  )}`;

type FormValues = {
  contract: Contract | null;
  domain: string;
  services: (Model<ZendeskOrBetterCloudService> & { quantity: number })[];
};

type MyFormProps = {
  contracts: Contract[];
  services: Service[];
  type: "bettercloud" | "zendesk";
  specialFieldLabel: string;
  specialFieldSuffix: string;
  specialFieldHelperText: string;
  onClose: () => void;
};

const MyForm = (props: MyFormProps & FormikProps<FormValues>) => {
  const classes = useStyles();

  const { values, errors, touched, isSubmitting, handleChange, handleBlur } = props;

  return (
    <Form>
      <CardContent>
        <Grid container direction="column">
          <Grid size="grow">
            <Typography variant="h6">Asset Properties</Typography>
          </Grid>
          <Grid container spacing={1}>
            <Grid
              size={{
                xs: 12,
                md: 6,
                lg: 3,
              }}
            >
              <TextField
                id="contract"
                name="contract"
                helperText={(touched.contract && errors.contract) || "Select the related contract"}
                error={touched.contract && Boolean(errors.contract)}
                label="Contract"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                slotProps={{
                  select: {
                    value: values.contract,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  },
                }}
              >
                {props.contracts.map((contract) => (
                  <MenuItem key={contract.snapshot.id} value={contract.snapshot.id}>
                    {contractName(contract)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 6,
                lg: 3,
              }}
            >
              <TextField
                id="domain"
                name="domain"
                helperText={(touched.domain && errors.domain) || props.specialFieldHelperText}
                error={touched.domain && Boolean(errors.domain)}
                label={props.specialFieldLabel}
                value={values.domain}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                slotProps={{
                  input: props.specialFieldSuffix
                    ? {
                        endAdornment: <InputAdornment position="end">{props.specialFieldSuffix}</InputAdornment>,
                      }
                    : undefined,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container direction="column">
          <Grid size="grow">
            <Typography variant="h6">Services</Typography>
          </Grid>
          <FieldArray
            name="services"
            render={(arrayHelpers) => {
              const selected = values.services.map((service) => service.skuId);
              const uniqueServices = uniqBy(props.services, "data.skuId");
              return (
                <Grid container direction="column">
                  {values.services &&
                    values.services.length > 0 &&
                    values.services.map((value, index) => {
                      const current = props.services.filter((service) => service.data.skuId === value.skuId);
                      const error = getIn(errors, `services[${index}]`);
                      const touch = getIn(touched, `services[${index}]`);
                      return (
                        <Grid
                          key={index}
                          container
                          spacing={1}
                          size={{
                            xs: 12,
                            md: 12,
                            lg: 6,
                          }}
                        >
                          <Grid
                            size={{
                              xs: 12,
                              md: 6,
                            }}
                          >
                            <TextField
                              id={`services[${index}].skuId`}
                              name={`services[${index}].skuId`}
                              label="SKU"
                              helperText={
                                (touch && error && touch.skuId && error.skuId) ||
                                `Select a ${assetTypeName(props.type)} SKU`
                              }
                              error={touch && error && touch.skuId && Boolean(error.skuId)}
                              value={value.skuId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              select
                              variant="outlined"
                              margin="dense"
                              slotProps={{
                                select: {
                                  MenuProps: {
                                    classes: {
                                      paper: classes.selectPaper,
                                    },
                                  },
                                },
                              }}
                            >
                              {uniqueServices
                                .filter(
                                  (service) =>
                                    !selected.includes(service.data.skuId) ||
                                    (current.length > 0 && value.skuId === current[0].data.skuId)
                                )
                                .map((service) => (
                                  <MenuItem key={service.snapshot.id} value={service.data.skuId}>
                                    {service.data.skuName}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                          <Grid
                            size={{
                              xs: 12,
                              md: 3,
                            }}
                          >
                            <TextField
                              id={`services[${index}].billingCycle`}
                              name={`services[${index}].billingCycle`}
                              label="Billing Cycle"
                              helperText={
                                (touch && error && touch.billingCycle && error.billingCycle) ||
                                "Select billing cycle plan"
                              }
                              error={touch && error && touch.billingCycle && Boolean(error.billingCycle)}
                              value={value.billingCycle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!value.skuId}
                              fullWidth
                              select
                              variant="outlined"
                              margin="dense"
                            >
                              {current.map((service) => (
                                <MenuItem key={service.snapshot.id} value={service.data.billingCycle}>
                                  {service.data.billingCycle}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            size={{
                              xs: 12,
                              md: 3,
                            }}
                          >
                            <TextField
                              id={`services[${index}].quantity`}
                              name={`services[${index}].quantity`}
                              type="number"
                              label="Quantity"
                              helperText={(touch && error && touch.quantity && error.quantity) || "Enter quantity"}
                              error={touch && error && touch.quantity && Boolean(error.quantity)}
                              value={value.quantity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              slotProps={{
                                input: {
                                  endAdornment: values.services.length > 1 && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        size="large"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  <Grid
                    size={{
                      xs: 12,
                      md: 12,
                      lg: 6,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        arrayHelpers.push({
                          skuId: "",
                          billingCycle: "",
                          quantity: "",
                        });
                      }}
                      disabled={!values.contract || !values.domain || values.services.length >= props.services.length}
                      className={classes.addButton}
                    >
                      Add SKU
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          />
          {/* </Grid> */}
        </Grid>
      </CardContent>
      <CardActions>
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          variant="outlined"
          color="primary"
          mixpanelEventId="assets.offline-asset.create"
        >
          {globalText.CREATE}
        </LoadingButton>
      </CardActions>
    </Form>
  );
};

const MyEnhancedForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    domain: "",
    contract: props.contracts.length === 1 ? props.contracts[0] : null,
    services: [],
  }),

  validateOnChange: true,
  validateOnBlur: true,

  validationSchema: Schema,
  // validate: asyncValidate,

  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    if (!values.contract) {
      return;
    }
    const querySnapshot = await getCollection(AssetModel)
      .where("type", "==", props.type)
      .where("properties.contract", "==", values.contract.snapshot.ref)
      .where("properties.customerDomain", "==", values.domain + props.specialFieldSuffix)
      .narrow<BetterCloudCloudAssetModel | ZendeskCloudAssetModel>()
      .get();

    if (!querySnapshot.empty) {
      const assets = querySnapshot.docs.map((q) => q.asModelData());
      let error = false;
      values.services.forEach((service, index) => {
        if (
          assets.findIndex(
            (asset) =>
              asset.properties.subscription.skuId === service.skuId &&
              asset.properties.subscription.billingCycle === service.billingCycle
          ) > -1
        ) {
          setFieldError(`services[${index}].skuId`, "This asset already exists");
          error = true;
        }
      });

      if (error) {
        setSubmitting(false);
        return;
      }
    }

    const batch = getBatch();
    const assetsCollection = getCollection(AssetModel);
    values.services.forEach(({ skuId, billingCycle, quantity }) => {
      const service = props.services.find((service) => service.data.skuId === skuId);
      if (!service || !values.contract) {
        return;
      }

      const doc = assetsCollection.newDoc();
      const assetRef = assetsCollection
        .doc(`${props.type}-${doc.id}`)
        .narrow<BetterCloudCloudAssetModel | ZendeskCloudAssetModel>();
      const assetSettingsRef = getCollection(AssetSettingModel).doc(assetRef.id);
      batch.set(assetRef, {
        customer: values.contract.data.customer,
        entity: values.contract.data.entity,
        contract: values.contract.snapshot.ref,
        properties: {
          customerDomain: values.domain + props.specialFieldSuffix,
          subscription: {
            skuId: service.data.skuId,
            skuName: service.data.skuName,
            billingCycle,
            quantity: Number(quantity),
            isCommitment: values.contract.data.isCommitment,
            startDate: values.contract.data.startDate.toDate(),
            endDate: values.contract.data.isCommitment ? values.contract.data.endDate.toDate() : null,
          },
        },
        bucket: null,
        type: props.type,
      });

      batch.set(assetSettingsRef, {
        type: props.type,
        customer: values.contract.data.customer,
        entity: values.contract.data.entity,
        contract: values.contract.snapshot.ref,
      });
    });

    try {
      await batch.commit();
      setSubmitting(false);
      props.onClose();
    } catch (error) {
      setSubmitting(false);
      consoleErrorWithSentry(error);
    }
  },

  displayName: "CreateAssetForm",
})(MyForm);

type Props = {
  type: "bettercloud" | "zendesk";
  specialFieldSuffix: string;
  specialFieldLabel: string;
  specialFieldHelperText: string;
};

type ContractModelWithEndDate = Model<ContractModel> & {
  isCommitment: true;
  endDate: FirestoreTimestamp;
};

type Contract = {
  data: ContractModelWithEndDate;
  snapshot: QueryDocumentSnapshotModel<ContractModel>;
};

type Service = {
  data: Model<ZendeskOrBetterCloudService>;
  snapshot: QueryDocumentSnapshotModel<ZendeskOrBetterCloudService>;
};

const CreateOfflineAsset = ({ type, specialFieldSuffix, specialFieldLabel, specialFieldHelperText }: Props) => {
  const { customer } = useCustomerContext();
  const history = useHistory();
  const classes = useStyles();
  const [contracts, setContracts] = useState<Contract[]>();
  const [services, setServices] = useState<Service[]>();

  useEffect(() => {
    const now = DateTime.utc().plus({ months: 2 }).startOf("day");

    getCollection(ContractModel)
      .where("type", "==", type)
      .where("customer", "==", customer.ref)
      .where("active", "==", true)
      .where("startDate", "<=", now.toJSDate())
      .get()
      .then((contractsSnapshot) => {
        const contract = contractsSnapshot.docs
          // Since we don't know if the contract has an end date, filter on client to avoid 2 firestore queries
          .flatMap((docSnap): Contract[] => {
            const data = docSnap.asModelData();

            if (!data.isCommitment) {
              return [];
            }

            if (!data.endDate) {
              return [];
            }

            return [{ data: { ...data, isCommitment: data.isCommitment, endDate: data.endDate }, snapshot: docSnap }];
          });

        setContracts(contract);
      });
  }, [customer.ref, type]);

  useEffect(() => {
    getCollection(CatalogModel)
      .doc(type)
      .collection("services")
      .orderBy("skuId")
      .get()
      .then((querySnap) => {
        setServices(
          querySnap.docs.map((docSnap) => ({
            data: docSnap.asModelData(),
            snapshot: docSnap,
          }))
        );
      });
  }, [type]);

  const handleClose = () => {
    history.push(`/customers/${customer.id}/assets`);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <IconButton aria-label="Back" component={Link} to={`/customers/${customer.id}/assets`} size="large">
            <BackIcon color="primary" />
          </IconButton>
        }
        title={`Create ${assetTypeName(type)} Asset`}
        subheader={customer.name}
      />

      {contracts && services ? (
        contracts.length === 0 ? (
          <CardContent>
            <Typography variant="body1">
              Customer has no active {assetTypeName(type)} contracts. To get started
              <Button
                size="small"
                variant="text"
                color="primary"
                component={Link}
                to={`/customers/${customer.id}/contracts/create`}
                className={classes.formButton}
              >
                CREATE A CONTRACT
              </Button>
            </Typography>
          </CardContent>
        ) : (
          services.length > 0 && (
            <MyEnhancedForm
              contracts={contracts}
              services={services}
              onClose={handleClose}
              type={type}
              specialFieldSuffix={specialFieldSuffix}
              specialFieldLabel={specialFieldLabel}
              specialFieldHelperText={specialFieldHelperText}
            />
          )
        )
      ) : null}
    </Card>
  );
};

export const CreateBetterCloudAsset = () => (
  <CreateOfflineAsset
    type="bettercloud"
    specialFieldSuffix=""
    specialFieldLabel="BetterCloud Domain"
    specialFieldHelperText="Enter the customer's BetterCloud domain"
  />
);

export const CreateZendeskAsset = () => (
  <CreateOfflineAsset
    type="zendesk"
    specialFieldSuffix=".zendesk.com"
    specialFieldLabel="Zendesk Subdomain"
    specialFieldHelperText="Enter the customer's Zendek subdomain"
  />
);
