import { Box, LinearProgress, Typography } from "@mui/material";

type Props = {
  value: number;
};

export const LinearProgressWithLabel = ({ value }: Props) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      width: "100%",
    }}
  >
    <Box
      sx={{
        width: "100%",
        mr: 1,
      }}
    >
      <LinearProgress variant="determinate" value={value} color="primary" />
    </Box>
    <Box
      sx={{
        minWidth: 35,
      }}
    >
      <Typography variant="caption" color="textSecondary">
        {value}%
      </Typography>
    </Box>
  </Box>
);
