import React, { type ReactNode } from "react";

import { useCurrentPresetPrompt } from "@doitintl/ava-components/src/Common/PresetPromptsContext";
import { BarChart } from "@mui/icons-material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Card, CardContent, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";

type PresetQuestion = {
  displayQuestion: string;
  detailedQuestion: string;
  icon: ReactNode;
};

type Props = {
  darkMode?: boolean;
  isHelpCenter?: boolean;
};

export const PresetPrompts = ({ darkMode, isHelpCenter }: Props) => {
  const theme = useTheme();
  const { setSelectedPrompt } = useCurrentPresetPrompt();
  const isDarkMode = darkMode ? darkMode : false;
  const presetQuestions: PresetQuestion[] = [
    {
      displayQuestion: !isHelpCenter
        ? "Show me my top 3 services by spend for the past month"
        : "How do I gain a deeper understanding of my cloud usage?",
      detailedQuestion: !isHelpCenter
        ? "Generate a report showing my top 3 services by spend in the past month"
        : "Explain how DoiT Cloud analytics reports can help me have a deeper understanding of my cloud usage and costs",
      icon: !isHelpCenter ? (
        <BarChart fontSize="small" color="primary" />
      ) : (
        <ChatBubbleOutlineIcon fontSize="small" color="primary" />
      ),
    },
    {
      displayQuestion: "How do I run workloads on EC2 Spot Instances?",
      detailedQuestion:
        "Provide detailed information about how DoiT Spot Scaling can help me run workloads reliably on EC2 Spot Instances",
      icon: <ChatBubbleOutlineIcon fontSize="small" color="primary" />,
    },
    {
      displayQuestion: !isHelpCenter
        ? "Show my top services by spend in the last 2 weeks"
        : "How do I allocate cost to my team(s)?",
      detailedQuestion: !isHelpCenter
        ? "Show my top services by spend in the last 2 weeks"
        : "Explain how to allocate cost to my team(s) using DoiT attribution groups",
      icon: !isHelpCenter ? (
        <BarChart fontSize="small" color="primary" />
      ) : (
        <ChatBubbleOutlineIcon fontSize="small" color="primary" />
      ),
    },
    {
      displayQuestion: "How do I grant DoiT Console access to my colleagues?",
      detailedQuestion: "Explain the steps to invite a new user to the DoiT console",
      icon: <ChatBubbleOutlineIcon fontSize="small" color="primary" />,
    },
  ];
  const handlePresetPromptSelect = (detailedQuestion: string) => {
    setSelectedPrompt(detailedQuestion);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: "center",
        margin: 0,
        width: isHelpCenter ? "98%" : "60%",
      }}
    >
      {presetQuestions.map((question, index) => (
        <Grid
          key={index}
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <Card
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: theme.palette.background.paper,
              borderRadius: "16px",
              borderColor: isDarkMode ? "#FFFFFF1F" : "#0000001F",
              cursor: "pointer",
              "&:hover": {
                borderColor: theme.palette.primary.main,
              },
              "& .MuiCardContent-root": {
                color: isDarkMode ? theme.palette.primary.main : "#00000099",
              },
            }}
            onClick={() => {
              handlePresetPromptSelect(question.detailedQuestion);
            }}
          >
            <CardContent>
              {question.icon}
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                }}
              >
                {question.displayQuestion}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
