import { type InputProps as StandardInputProps } from "@mui/material/Input/Input";
import MenuItem from "@mui/material/MenuItem";
import { type Theme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { type TextFieldProps } from "@mui/material/TextField/TextField";
import { type SxProps } from "@mui/system";

import { useCustomerContext } from "../../Context/CustomerContext";

const ALL_ENTITIES = "all-entities";

type Props = TextFieldProps & {
  value?: string | null;
  allOption?: boolean;
  onChange?: StandardInputProps["onChange"];
  label?: string;
  sx?: SxProps<Theme>;
};

const EntitySelect = (props: Props) => {
  const { value, allOption, onChange, ...otherProps } = props;
  const { entities } = useCustomerContext();

  const filteredEntities = entities.filter((entity) => entity.active);

  return (
    <TextField
      select
      label={props.label ?? "Billing Profile"}
      variant="outlined"
      data-cy="entity-select"
      value={value ?? ""}
      onChange={onChange}
      {...otherProps}
      slotProps={{
        select: {
          MenuProps: {
            MenuListProps: {
              dense: true,
            },
          },
        },
      }}
    >
      {allOption && (
        <MenuItem key={ALL_ENTITIES} value={ALL_ENTITIES}>
          All billing profiles
        </MenuItem>
      )}
      {filteredEntities.map((entity) => (
        <MenuItem key={entity.id} value={entity.id}>
          {entity.priorityId} - {entity.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default EntitySelect;
