import {
  Children,
  type Dispatch,
  forwardRef,
  type JSX,
  type ReactElement,
  type Ref,
  type SetStateAction,
  type UIEvent,
  useEffect,
  useState,
} from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, Divider, IconButton, Slide, Stack, Typography } from "@mui/material";
import { type TransitionProps } from "@mui/material/transitions";

import { WorkflowFooter, type WorkflowFooterProps } from "./WorkflowFooter";
import { WorkflowSteps, type WorkflowStepsProps } from "./WorkflowSteps";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  children: JSX.Element[];
  footerProps?: WorkflowFooterProps;
  gotoStep?: number;
  onClose: () => void;
  onLastStepConfirm?: () => void;
  nextStepCustomCallback?: (step: number) => Promise<boolean>;
  open: boolean;
  setGoToStep?: Dispatch<SetStateAction<number | undefined>>;
  stepsProps: WorkflowStepsProps;
};

export function WorkflowDialog({
  children,
  footerProps,
  gotoStep,
  onClose,
  onLastStepConfirm,
  nextStepCustomCallback,
  open,
  setGoToStep,
  stepsProps,
}: Props) {
  const [step, setStep] = useState(0);

  const contentArray = Children.toArray(children);
  const numOfSteps = stepsProps.stepNames.length;
  const isLastStep = step === numOfSteps - 1;
  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleNextStep = async () => {
    const success = (await nextStepCustomCallback?.(step)) ?? true;

    if (!success) {
      return;
    }

    if (step < numOfSteps - 1) {
      setStep(step + 1);
    } else if (isLastStep) {
      onLastStepConfirm?.();
    }
  };

  const handlePreviousStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleScroll = (event: UIEvent<HTMLElement>) => {
    setShowDropShadow((event.target as HTMLElement).scrollTop > 0);
  };

  useEffect(() => {
    if (gotoStep !== undefined && gotoStep !== step && setGoToStep) {
      setStep(gotoStep);
      setGoToStep(undefined);
    }
  }, [gotoStep, step, setGoToStep]);

  useEffect(() => {
    if (open && stepsProps.resetOnOpen) {
      setStep(0);
    }
  }, [open, stepsProps.resetOnOpen]);

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullScreen>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          maxHeight: "100vh",
          width: "100%",
        }}
      >
        {/* Left side */}
        <Box sx={{ backgroundColor: "action.hover", width: "20%", p: 2, display: { xs: "none", sm: "block" } }}>
          <WorkflowSteps
            {...stepsProps}
            step={step}
            onStepChanged={setStep}
            disabled={stepsProps.disableOnLastStep && isLastStep}
          />
        </Box>

        <Stack
          sx={{
            justifyContent: "space-between",
            height: "100%",
            flex: 1,
          }}
        >
          <Stack>
            {/* Right header */}
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                pl: 4,
                py: 1,
                boxShadow: showDropShadow ? "0px 4px 3px -3px rgba(0, 0, 0, 0.3)" : undefined,
              }}
            >
              <Stack
                sx={{
                  pt: 2,
                  mb: 1,
                }}
              >
                <Typography
                  variant="overline"
                  sx={{
                    color: "text.secondary",
                    fontWeight: 400,
                  }}
                >
                  STEP {step + 1} OF {numOfSteps}
                </Typography>
                <Typography variant="h3">{stepsProps.stepNames[step]}</Typography>
              </Stack>
              <Stack
                sx={{
                  mr: "10px",
                }}
              >
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>

            {/* Right content goes here */}
            <Stack
              onScroll={handleScroll}
              sx={{
                pt: 2,
                px: 4,
                maxHeight: "80.5vh",
                overflowY: "auto",
              }}
            >
              {contentArray[step]}
            </Stack>
          </Stack>

          <Box>
            <Divider />
            <WorkflowFooter
              {...footerProps}
              step={step}
              hideBack={step === 0 || (isLastStep && footerProps?.hideBackOnLastStep)}
              onPreviousStepClicked={handlePreviousStep}
              onNextStepClicked={handleNextStep}
              onCancelClicked={onClose}
            />
          </Box>
        </Stack>
      </Box>
    </Dialog>
  );
}
