import { useState } from "react";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Alert, Button, List, ListItem, Stack, Typography } from "@mui/material";

import { onboardingTxt } from "../../../assets/texts/EKS/onboarding";
import { useEKSApi, useFirstCloudFormationDeploy } from "../hooks";
import { type ClusterConfig } from "../types";
import { getCloudFormationLink, getTemplateUrl } from "../utils";

type Props = {
  clusterConfig: ClusterConfig;
  stepCompleted: boolean;
};

export const PermissionStep = ({ clusterConfig, stepCompleted }: Props) => {
  const [linkClicked, setLinkClicked] = useState(false);
  const { firstDeployInAccount, firstDeployInRegion } = useFirstCloudFormationDeploy(
    clusterConfig.accountId,
    clusterConfig.region
  );
  const eksApi = useEKSApi();
  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h1">Add required permission to cluster</Typography>
        <Typography
          sx={{
            color: "text.secondary",
          }}
        >
          Account: {clusterConfig.accountId}, Region: {clusterConfig.region}, Cluster: {clusterConfig.clusterName},{" "}
          Deployment ID: {clusterConfig.desiredDeploymentId}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography>
          You can review CloudFormation template and the deployment you will need to set up in the next steps by
          downloading the following files:
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={() => {
              setLinkClicked(true);
            }}
            startIcon={<FileDownloadIcon />}
            sx={{ width: "fit-content" }}
            href={getTemplateUrl(clusterConfig)}
            target="_blank"
          >
            Download CloudFormation template
          </Button>
          <Button
            startIcon={<FileDownloadIcon />}
            variant="outlined"
            onClick={() => eksApi.downloadDeploymentFile(clusterConfig)}
            sx={{ width: "fit-content" }}
          >
            Download Deployment YAML
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setLinkClicked(true);
            }}
            startIcon={<OpenNewIcon />}
            sx={{ width: "fit-content" }}
            href="https://github.com/doitintl/doit-eks-lens-helm-chart"
            target="_blank"
          >
            {onboardingTxt.HELM_CHART_REPOSITORY}
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <Typography>To successfully run the cloud formation stack ensure you have following role:</Typography>
        <List sx={{ listStyleType: "disc", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }} alignItems="flex-start" disablePadding>
            AdministratorAccess
          </ListItem>
        </List>
      </Stack>
      <Stack spacing={2}>
        <Typography>
          Running the cloud formation stack will add the required permission so we can access this cluster
        </Typography>
        <Button
          variant="outlined"
          onClick={() => {
            setLinkClicked(true);
          }}
          startIcon={<OpenNewIcon />}
          sx={{ width: "fit-content" }}
          href={getCloudFormationLink(clusterConfig, firstDeployInAccount, firstDeployInRegion)}
          target="_blank"
        >
          Open CloudFormation Stack
        </Button>
        {clusterConfig.status !== "in-progress" && linkClicked && !stepCompleted && (
          <Alert severity="info" sx={{ width: "fit-content" }}>
            Checking for the new permission. Status will change once the stack creation is completed.
          </Alert>
        )}
        {clusterConfig.status !== "not-connected" && clusterConfig.status !== "deleted" && (
          <Alert severity="success" sx={{ width: "fit-content" }}>
            Permission successfully added
          </Alert>
        )}
      </Stack>
    </Stack>
  );
};
