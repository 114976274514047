import { useState } from "react";

import { CheckCircle } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import LoadingButton from "../../../Components/LoadingButton";
import { useDataHubContext } from "../../../Context/DataHubContext";

type Props = {
  datasetName: string;
  onClose: () => void;
};

const SuccessfulPage = ({ datasetName, onClose }: Props) => {
  const { fetchDatasetBatches, fetchDataHubItems } = useDataHubContext();
  const [loading, setLoading] = useState(false);

  const handleReturnToOverview = async () => {
    setLoading(true);
    await fetchDataHubItems(true);
    await fetchDatasetBatches(datasetName, false);
    setLoading(false);
    onClose();
  };

  return (
    <Stack
      sx={{
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          alignItems: "center",
          pt: 8,
          maxWidth: 650,
        }}
      >
        <CheckCircle sx={{ width: 64, height: 64, color: "success.light" }} />
        <Typography
          variant="h1"
          sx={{
            mt: 4,
            mb: 2,
          }}
        >
          {datahubTxt.SUCCESS_PAGE_TITLE}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            mb: 4,
          }}
        >
          {datahubTxt.SUCCESS_PAGE_DESCRIPTION}
        </Typography>
        <LoadingButton
          variant="contained"
          onClick={handleReturnToOverview}
          loading={loading}
          mixpanelEventId="datahub.datasets.return"
        >
          {datahubTxt.RETURN_TO_OVERVIEW}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default SuccessfulPage;
