import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { type EntitlementKey, type ZendeskPlatformModel } from "@doitintl/cmp-models";
import { Box, Link, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../Context/CustomerContext";
import { useIsFeatureEntitled, useTier } from "../../Context/TierProvider";
import { isDoitConsoleBaseService } from "../../Support/utils";
import { useGetCustomerContractAndAssetDetails } from "../hooks/useGetCustomerContractAndAssetDetails";
import { useIsDCIEssentialsTier } from "../hooks/useIsDCIEssentialsTier";
import SupportItemIcon from "./SupportItemIcon";

type Props = {
  zendPlatform: ZendeskPlatformModel;
  dataCyItems: any;
};

const SupportPlatformMenuItemContent = ({ zendPlatform }: Props) => {
  const { customer } = useCustomerContext();
  const { tiers } = useTier();
  const { customerDoesNotHaveAssets, isPlatformWithoutBillingAccount, isStandaloneAppWithoutContractOrAssets } =
    useGetCustomerContractAndAssetDetails();
  const { t } = useTranslation("services");
  const isDCIEssentialsTier = useIsDCIEssentialsTier();

  const history = useHistory();

  const platformName = zendPlatform.asset.replace("-project", "");
  const platformEntitlementKey = `support:ticket:menu:platform:${platformName}`;
  const isAllPlatformsEntitled = useIsFeatureEntitled("support:ticket:menu:platform:all");
  const isThisPlatformEntitled = useIsFeatureEntitled(platformEntitlementKey as EntitlementKey);
  const featureUnlocked = isDoitConsoleBaseService(platformName) || isAllPlatformsEntitled || isThisPlatformEntitled;

  const isMissingBillingAccount = isPlatformWithoutBillingAccount(zendPlatform.asset);
  const skipAssetCheck = platformName === "office-365" || platformName === "g-suite";

  const isMissingAssets = customerDoesNotHaveAssets(zendPlatform.asset) && !skipAssetCheck;

  const missingBillingAcctOrAssetsText = isMissingBillingAccount
    ? t("SupportPlatformMennuItemContent.connectAccount")
    : t("SupportPlatformMennuItemContent.createAsset");

  const disableAppPlatformLink = isStandaloneAppWithoutContractOrAssets(zendPlatform.asset);

  const isMissingAsset = isMissingBillingAccount || isMissingAssets;

  const renderTypography = () => {
    const enabledLink = (
      <Typography
        sx={{
          ml: 1,
        }}
      >
        {zendPlatform.title}
      </Typography>
    );

    if (isDCIEssentialsTier) {
      return enabledLink;
    }

    // preserve original behavior for customers with no subscription configurations
    if (!tiers) {
      return (
        <Typography component={Box} variant="body1" sx={{ ml: 1 }}>
          {zendPlatform.title}
        </Typography>
      );
    }

    const disabledMainTextTypography = (
      <Typography
        component={Box}
        variant="body1"
        sx={{
          color: "text.disabled",
        }}
      >
        {zendPlatform.title}
      </Typography>
    );

    // disabled - not available
    if (!featureUnlocked || disableAppPlatformLink) {
      return (
        <Stack
          direction="row"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            ml: 1,
          }}
        >
          {disabledMainTextTypography}
        </Stack>
      );
    }

    // disabled - missing billing account or relevant assets
    if (isMissingAsset) {
      return (
        <Stack
          direction="row"
          onClick={() => {
            history.push(`/customers/${customer.id}/assets/${zendPlatform.asset.replace("-project", "")}`);
          }}
          sx={{
            width: "100%",
            justifyContent: "space-between",
            ml: 1,
          }}
        >
          {disabledMainTextTypography}
          <Link variant="body1" underline="none" sx={{ cursor: "pointer" }}>
            {missingBillingAcctOrAssetsText}
          </Link>
        </Stack>
      );
    }

    // enabled
    return enabledLink;
  };
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        width: "100%",
      }}
    >
      <SupportItemIcon
        platformValue={zendPlatform.value}
        disabled={!!tiers && (!featureUnlocked || isMissingAsset) && !isDCIEssentialsTier}
        onClick={
          !!tiers && isMissingAsset && !disableAppPlatformLink && !isDCIEssentialsTier
            ? () => {
                history.push(`/customers/${customer.id}/assets/${zendPlatform.asset.replace("-project", "")}`);
              }
            : undefined
        }
      />
      {renderTypography()}
    </Stack>
  );
};

export default SupportPlatformMenuItemContent;
