import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../../../assets/texts";
import { type RFNode } from "../../types";
import { SourceHandle } from "./Handle";

type Props = {
  id: string;
  data: RFNode;
};

const buttons = [
  { label: cloudflowTexts.CUSTOM_SCHEDULE, nodeType: CloudFlowNodeType.TRIGGER },
  { label: cloudflowTexts.MANUALLY_START, nodeType: CloudFlowNodeType.MANUAL_TRIGGER },
];

export const StartStepConfigurator = ({ id, data }: Props) => (
  <Card
    onClick={noop}
    sx={{
      width: 350,
      pb: 1,
      borderRadius: 2,
    }}
  >
    <CardHeader
      title={
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
        >
          {`${data.stepNumber}. ${data.nodeData.name}`}
        </Typography>
      }
      sx={{
        ".MuiCardHeader-avatar": {
          mr: 1,
        },
      }}
    />
    <CardContent sx={{ pt: 0, pb: 2, pl: 1 }}>
      <Stack
        sx={{
          gap: 1,
          pl: 1,
        }}
      >
        {buttons.map((button) => (
          <Button
            key={button.label}
            variant="outlined"
            onClick={() => {
              if (data.onAddNode) {
                data.onAddNode(button.nodeType, id, data.nodeData.display?.position);
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </Stack>
    </CardContent>
    <SourceHandle id={id} />
  </Card>
);
