import { type JSX, useCallback, useMemo, useState } from "react";

import Grid from "@mui/material/Grid2";

import { useCloudConnectContext } from "../../../Context/customer/CloudCOnnectContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { useIsEntitledToCurrentDashboardType } from "../hooks/useIsEntitledByDashboardType";
import BigQueryLensAlert from "./BigQueryLensAlert";
import { useBigQueryLensDashboardContext } from "./BigQueryLensDashboard";
import BQInactiveStateDashboard from "./BQInactiveStateDashboard/BQInactiveStateDashboard";
import { getServiceAccountsBQFinOps } from "./utils";

type Props = {
  widgets: JSX.Element;
};

const BigQueryLensGuard = ({ widgets }: Props) => {
  // This guard is responsible for deciding which state of the BigQuery Lens dashboard to show
  // this guard is only accessed if SKU usage has already been detected for the customer
  const [hideWidgets, setHideWidgets] = useState(false);
  const isEntitled = useIsEntitledToCurrentDashboardType();
  const { spendProfile } = useBigQueryLensDashboardContext();
  const cloudConnect = useCloudConnectContext();
  const serviceAccountsWithBigQueryFinops = getServiceAccountsBQFinOps(cloudConnect);
  const { customer } = useCustomerContext();
  const presentationMode = isCustomerInPresentationMode(customer) || customer.presentationMode?.isPredefined;
  const isBQLensConfigured = useMemo(
    () => presentationMode || (serviceAccountsWithBigQueryFinops && serviceAccountsWithBigQueryFinops?.length > 0),
    [serviceAccountsWithBigQueryFinops, presentationMode]
  );

  // spend profile is an internal optimizer feature that tracks active spend on BigQuery
  // this is separate than the SKU usage check
  const hasActiveBQSpend = Object.values(spendProfile).includes(true);

  const hideWidgetsDisplay = useCallback(() => {
    setHideWidgets(true);
  }, []);
  const showWidgetsDisplay = useCallback(() => {
    setHideWidgets(false);
  }, []);

  // 1. not entitled to BQ Lens: show upgrade guard
  if (!isEntitled) {
    return (
      <Grid
        sx={{
          ml: -1,
          mb: 1,
        }}
        size={12}
      >
        <BQInactiveStateDashboard enticementState="upgrade" />
      </Grid>
    );
  }

  // 2. if not onboarded, configured OR has no spend profile data detected: show enable guard
  // if in presentation mode, bypass configuration check
  if (!isBQLensConfigured || !hasActiveBQSpend) {
    return (
      <Grid
        sx={{
          ml: -1,
          mb: 1,
        }}
        size={12}
      >
        <BQInactiveStateDashboard enticementState="activate" />
      </Grid>
    );
  }

  // 3. entitled to BQ lens and has spend profile data: render dahsboard or processing stages
  return (
    <>
      <Grid
        sx={{
          ml: -1,
          mb: 1,
        }}
        size={12}
      >
        <BigQueryLensAlert hideWidgetsDisplay={hideWidgetsDisplay} showWidgetsDisplay={showWidgetsDisplay} />
      </Grid>
      {!hideWidgets && widgets}
    </>
  );
};

export default BigQueryLensGuard;
