import { type CloudSelectorType } from "@doitintl/cmp-models";
import { Card, CardContent, Checkbox, FormControlLabel, type Theme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { type SystemStyleObject } from "@mui/system";

import { convertHexToRGBA } from "../../../../../utils/color";
import { type CloudProviderOptionType } from "../../types";
import { CloudBoxContent } from "./CloudBoxContent";

type Props = {
  cloud: CloudProviderOptionType;
  selected: boolean;
  onClick: (provider: CloudSelectorType) => void;
  sx?: SystemStyleObject<Theme>;
};

export const CloudBox = ({ cloud, selected, onClick, sx }: Props) => {
  const enabledBgColor = convertHexToRGBA("#CAD2FF", 10);

  const onCardClick = () => {
    onClick(cloud.type);
  };

  return (
    <Card
      sx={(theme) => ({
        cursor: "pointer",
        "&:hover": {
          borderColor: theme.palette.primary.main,
          background: enabledBgColor,
        },
        borderRadius: 2,
        minWidth: 250,
        maxWidth: 300,
        pb: 1,
        borderColor: selected ? theme.palette.primary.main : undefined,
        background: selected ? enabledBgColor : undefined,
        ...sx,
      })}
      onClick={onCardClick}
    >
      <CardContent>
        <Grid container direction="column">
          <Grid>
            <FormControlLabel control={<Checkbox checked={selected} sx={{ mt: -2, mb: 1 }} />} label="" />
          </Grid>
          <Grid
            sx={{
              px: 2,
              mb: 3,
            }}
            size={10}
          >
            <CloudBoxContent type={cloud.type} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
