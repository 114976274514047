import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { type StackProps } from "@mui/system";

import { type LabelWithRef } from "../Pages/CloudAnalytics/labels/types";

type LabelListProps = {
  labels: Array<LabelWithRef>;
} & StackProps;

// Displays a list of labels as chips
// Used in the display of labels for cloud analytics types
const LabelList = ({ labels, sx }: LabelListProps) => {
  if (labels.length === 0) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      useFlexGap
      sx={[
        {
          flexWrap: "wrap",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {labels.map((label) => (
        <Chip key={label.data.name} size="small" sx={{ bgcolor: label.data.color }} label={label.data.name} />
      ))}
    </Stack>
  );
};

export default LabelList;
