import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
};
export const RequiredPermissions = ({ open, onClose }: Props) => (
  <Dialog open={open}>
    <DialogTitle>Required permissions for Spot Scaling</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:Describe*
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:CreateLaunchTemplate
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:CreateLaunchTemplateVersion
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:ModifyLaunchTemplate
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:RunInstances
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:TerminateInstances
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:CreateTags
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:DeleteTags
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:CreateLaunchTemplateVersion
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - ec2:CancelSpotInstanceRequests
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - autoscaling:CreateOrUpdateTags
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - autoscaling:UpdateAutoScalingGroup
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - autoscaling:Describe*
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - autoscaling:AttachInstances
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - autoscaling:BatchDeleteScheduledAction
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - autoscaling:BatchPutScheduledUpdateGroupAction
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - cloudformation:ListStacks
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - cloudformation:Describe*
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - iam:PassRole
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - events:PutRule
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - events:PutTargets
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          - events:PutEventss
        </Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="contained">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
