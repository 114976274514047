import { type PerkModel } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import { useMarketplaceListingHandler } from "../hooks";
import { RegisterInterestFromType } from "../types";
import { MarketplaceListingButton } from "./MarketplaceListingButton";
import { Section } from "./PerkContent";

type Props = {
  perk: PerkModel;
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>;
};

export const MarketplaceListings = ({ perk, registerInterest }: Props) => {
  const onRegisterInterest = useMarketplaceListingHandler(registerInterest);
  const listings = Object.entries(perk.fields?.platformUrl || {});

  if (!listings.length) {
    return null;
  }

  return (
    <Section>
      <Typography variant="h4">Marketplace Listings</Typography>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {listings.map(([platform, url]) => (
          <MarketplaceListingButton
            key={platform}
            platform={platform}
            handler={() => onRegisterInterest(RegisterInterestFromType[platform], url)}
          />
        ))}
      </Stack>
    </Section>
  );
};
