import { useMemo } from "react";

import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { computeReportElementId, rdsReportElementId, sageMakerReportElementId } from "../AWS/consts";
import { useFlexsaveComputeData, useFlexsaveRDSData, useFlexsaveSageMakerData } from "../AWS/hooks";
import { getMonthDate, getTotalSavings } from "../AWSOnboarding/stateHeaderUtils";
import ComputeIcon from "./assets/compute.svg";
import RDSIcon from "./assets/rds.svg";
import SageMakerIcon from "./assets/sagemaker.svg";
import Service from "./Service";

export default function SavingsByService() {
  const { sageMakerData } = useFlexsaveSageMakerData();
  const { data: computeData } = useFlexsaveComputeData();
  const { rdsData } = useFlexsaveRDSData();

  const currentMonth = computeData?.savingsSummary?.currentMonth?.month;

  const computeMonthSavings = useMemo(() => {
    if (!currentMonth || !computeData?.savingsHistory) {
      return null;
    }

    return computeData.savingsHistory[currentMonth]?.savings;
  }, [computeData, currentMonth]);

  const rdsMonthSavings = useMemo(() => {
    if (!currentMonth || !rdsData?.savingsHistory) {
      return null;
    }

    return rdsData.savingsHistory[currentMonth]?.savings;
  }, [rdsData, currentMonth]);

  const sageMakerMonthSavings = useMemo(() => {
    if (!currentMonth || !sageMakerData?.savingsHistory) {
      return null;
    }

    return sageMakerData.savingsHistory[currentMonth]?.savings;
  }, [sageMakerData, currentMonth]);

  const monthName = useMemo(() => {
    if (!computeData?.savingsSummary?.currentMonth) {
      return null;
    }

    return getMonthDate(computeData.savingsSummary.currentMonth.month).toLocaleString("default", {
      month: "long",
    });
  }, [computeData]);

  const computeSavings = useMemo(
    () => getTotalSavings({ savingsHistory: computeData?.savingsHistory ?? null }),
    [computeData]
  );

  const sageMakerSavings = useMemo(
    () => getTotalSavings({ savingsHistory: sageMakerData?.savingsHistory ?? null }),
    [sageMakerData]
  );

  const rdsSavings = useMemo(() => getTotalSavings({ savingsHistory: rdsData?.savingsHistory ?? null }), [rdsData]);

  const enabledServices = useMemo(() => {
    const showCompute = Boolean(computeSavings?.savings && computeData?.timeEnabled);
    const showSageMaker = Boolean(sageMakerSavings?.savings && sageMakerData?.timeEnabled);
    const showRDS = Boolean(rdsSavings?.savings && rdsData?.timeEnabled);

    return [showCompute, showSageMaker, showRDS].filter(Boolean).length;
  }, [computeData, computeSavings, rdsData, rdsSavings, sageMakerData, sageMakerSavings]);

  if (enabledServices < 2 || !monthName) {
    return null;
  }

  const maxAmountOfItemsPerRow = 3;
  const itemWidth = 12 / Math.min(enabledServices, maxAmountOfItemsPerRow);

  return (
    <Container data-cy="savings-by-service">
      <Typography
        variant="h4"
        sx={{
          fontWeight: 500,
          marginTop: 4,
          marginBottom: 3,
        }}
      >
        Total savings by service
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: "stretch",
        }}
      >
        {computeData?.timeEnabled && !!computeSavings?.savings && computeMonthSavings !== null && (
          <Service
            itemWidth={itemWidth}
            monthSavings={computeMonthSavings}
            serviceName="Compute spend"
            serviceIcon={ComputeIcon}
            linkText="Explore your Compute coverage"
            linkUrl={computeReportElementId}
            lifetimeSavings={computeSavings.savings}
            savingsRate={Math.round(computeSavings.savingsRate)}
            month={monthName}
            serviceInfo="EC2, ECS, Lambda and EKS"
          />
        )}

        {rdsData?.timeEnabled && !!rdsSavings?.savings && rdsMonthSavings !== null && (
          <Service
            itemWidth={itemWidth}
            monthSavings={rdsMonthSavings}
            serviceName="Amazon RDS"
            serviceIcon={RDSIcon}
            linkText="Explore your RDS coverage"
            linkUrl={rdsReportElementId}
            lifetimeSavings={rdsSavings.savings}
            savingsRate={Math.round(rdsSavings.savingsRate)}
            month={monthName}
          />
        )}

        {sageMakerData?.timeEnabled && !!sageMakerSavings?.savings && sageMakerMonthSavings !== null && (
          <Service
            itemWidth={itemWidth}
            monthSavings={sageMakerMonthSavings}
            serviceName="Amazon SageMaker"
            serviceIcon={SageMakerIcon}
            linkText="Explore your SageMaker coverage"
            linkUrl={sageMakerReportElementId}
            lifetimeSavings={sageMakerSavings.savings}
            savingsRate={Math.round(sageMakerSavings.savingsRate)}
            month={monthName}
          />
        )}
      </Grid>
    </Container>
  );
}
