import { Box, TableCell, Typography } from "@mui/material";

import { formatToLocaleTimestamp } from "../../../utils/common";
import { LabelThreeDotsMenu } from "./LabelThreeDotsMenu";
import { type LabelRowItem } from "./types";

type Props = {
  row: LabelRowItem;
  handleEdit: () => void;
  handleDelete: () => void;
};

const LabelRow = ({ row, handleEdit, handleDelete }: Props) => {
  const labelColorIndicator = (
    <Box
      sx={{
        width: "1em",
        height: "1em",
        borderRadius: "50%",
        bgcolor: row.data.color,
      }}
    />
  );

  return (
    <>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {labelColorIndicator}
          <Typography
            sx={{
              pl: 1,
            }}
          >
            {row.data.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>{row.timesApplied}</TableCell>
      <TableCell>{formatToLocaleTimestamp(row.data.timeCreated)}</TableCell>
      <TableCell>{row.data.createdBy}</TableCell>
      <TableCell>
        <LabelThreeDotsMenu row={row} handleEdit={handleEdit} handleDelete={handleDelete} />
      </TableCell>
    </>
  );
};

export default LabelRow;
