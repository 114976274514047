import { useEffect, useMemo, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { AssetTypeGSuite, EntityModel, type GSuiteAssetModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { type FormikProps } from "formik/dist/types";
import { number, object, string } from "yup";

import { useApiContext } from "../../../api/context";
import { CatalogHooks, type LegacyDataFormat } from "../../../Components/Catalog/Catalog.context";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Asset } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { formatCurrency } from "../../../utils/common";
import sku, { transformWorkspacePlanName } from "../../../utils/gsuite";
import NewAssetConfirmSummary from "../components/NewAssetConfirmSummary";
import { getNewAssetTotal, type NewAssetValues } from "../Tabs/components/gsuite/gSuiteAssetUtils";

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginBottom: theme.spacing(2),
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
  selectPaper: {
    maxHeight: 46 * 5 + 8,
  },
}));

type Values = {
  gsuiteSubscription: Asset<GSuiteAssetModel> | "";
  plan: string;
  quantity: number | "";
  service: LegacyDataFormat | "";
};

type ServiceStepValues = Values & {
  gsuiteSubscription: Asset<GSuiteAssetModel>;
};

type ConfirmStepValues = ServiceStepValues & {
  plan: string;
  quantity: number;
  service: LegacyDataFormat;
};

type ConfirmStepProps = FormikProps<ConfirmStepValues>;

type DomainStepProps = FormikProps<Values> & {
  assets: Asset[];
};

const DomainStep = (props: DomainStepProps) => {
  const gsuiteSubscriptions = props.assets.filter(
    (asset) => asset.data.entity && sku.isMainSKU(asset.data.properties.subscription.skuId)
  );

  return (
    <Grid container>
      <Grid size="grow" />
      <Grid
        container
        direction="column"
        spacing={1}
        size={{
          xs: 12,
          md: 6,
          lg: 4,
          xl: 3,
        }}
      >
        <Grid>
          <Typography variant="subtitle1">Select the domain for which you're adding a subscription</Typography>
        </Grid>
        <Grid>
          <TextField
            name="gsuiteSubscription"
            helperText={
              (props.touched.gsuiteSubscription && props.errors.gsuiteSubscription) ||
              "Your order will be added to the selected domain"
            }
            error={props.touched.gsuiteSubscription && Boolean(props.errors.gsuiteSubscription)}
            label="Google Workspace Domain"
            fullWidth
            select
            variant="outlined"
            margin="dense"
            data-cy="gsuiteDomainSelect"
            slotProps={{
              select: {
                value: props.values.gsuiteSubscription,
                onChange: props.handleChange,
                onBlur: props.handleBlur,
              },
            }}
          >
            {gsuiteSubscriptions.length > 0 ? (
              gsuiteSubscriptions.map((s) => (
                <MenuItem key={s.id} value={s as any}>
                  {s.data.properties.customerDomain} ({s.data.properties.subscription.skuName})
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No active subscriptions
              </MenuItem>
            )}
          </TextField>
        </Grid>
      </Grid>
      <Grid size="grow" />
    </Grid>
  );
};

type ServiceStepProps = FormikProps<ServiceStepValues> & { assets: Asset[] };
const ServiceStep = (props: ServiceStepProps) => {
  const classes = useStyles();
  const [catalog] = CatalogHooks.useGSuiteCatalogContext();
  const [isIsrael, setIsIsrael] = useState<boolean>(false);

  const { gsuiteSubscription } = props.values;
  const gsuitePlan = gsuiteSubscription.data.properties.settings?.plan
    ? gsuiteSubscription.data.properties.settings.plan.planName
    : transformWorkspacePlanName(gsuiteSubscription.data.properties.subscription.plan.planName);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const entitySnap = getCollection(EntityModel)
          .doc(gsuiteSubscription.data.entity?.id ?? "")
          .get();
        const entityData = (await entitySnap).asModelData();
        if (!entityData) {
          return;
        }
        setIsIsrael(entityData.country === "Israel");
      } catch {
        setIsIsrael(false);
      }
    };
    fetchCountry();
  }, [gsuiteSubscription.data.entity?.id]);

  // Filter available services
  const services = useMemo(
    () =>
      catalog?.filter((service) => {
        const gsuiteSub = gsuiteSubscription.data.properties.subscription;
        if (
          sku.includesDrive(gsuiteSub.skuId) &&
          (sku.isDrive(service.data.skuId) || service.data.skuId === sku.GoogleVault)
        ) {
          return false;
        }

        if (
          // G Suite Business - Archived User
          (service.data.skuId === sku.GSuiteBusinessArchivedUser && gsuiteSub.skuId !== sku.GSuiteBusiness) ||
          // Google Workspace Enterprise Plus - Archived User
          (service.data.skuId === sku.WorkspaceEnterprisePlusArchivedUser &&
            gsuiteSub.skuId !== sku.WorkspaceEnterprisePlus) ||
          // Google Workspace Enterprise Standard - Archived User
          (service.data.skuId === sku.WorkspaceEnterpriseStandardArchivedUser &&
            gsuiteSub.skuId !== sku.WorkspaceEnterpriseStandard) ||
          // Google Workspace Business Plus - Archived User
          (service.data.skuId === sku.WorkspaceBusinessPlusArchivedUser &&
            gsuiteSub.skuId !== sku.WorkspaceBusinessPlus)
        ) {
          return false;
        }

        // Remove voice for billing profiles from Israel - due to region location, they cannot utilize Google Voice
        if (isIsrael && sku.isVoice(service.data.skuId)) {
          return false;
        }
        // Remove if service is a Voice subscription and the customer already has a Voice asset
        if (
          sku.isVoice(service.data.skuId) &&
          props.assets.findIndex(
            (asset) =>
              gsuiteSubscription.data.properties.customerId === asset.data.properties.customerId &&
              [sku.GoogleVoiceStarter, sku.GoogleVoiceStandard, sku.GoogleVoicePremier].includes(
                asset.data.properties.subscription.skuId
              )
          ) > -1
        ) {
          return false;
        }

        // Filter services by reseller and remove voice for other resellers
        if (
          gsuiteSubscription.data.properties.reseller !== "admin@na.doit-intl.com" &&
          sku.isVoice(service.data.skuId)
        ) {
          return false;
        }

        if (service.data.plan === gsuitePlan || service.data.skuId === sku.CloudIdentityPremium) {
          return (
            props.assets.findIndex(
              (asset) =>
                gsuiteSubscription.data.properties.customerId === asset.data.properties.customerId &&
                asset.data.properties.subscription.skuId === service.data.skuId &&
                (asset.data.properties.subscription.skuId === sku.CloudIdentityFree ||
                  asset.data.properties.subscription.seats?.maximumNumberOfSeats > 0 ||
                  asset.data.properties.subscription.seats?.numberOfSeats > 0)
            ) < 0
          );
        }

        return false;
      }) ?? [],
    [
      catalog,
      gsuitePlan,
      gsuiteSubscription.data.properties.customerId,
      gsuiteSubscription.data.properties.reseller,
      gsuiteSubscription.data.properties.subscription,
      isIsrael,
      props.assets,
    ]
  );

  return (
    <Grid container>
      <Grid container size={12}>
        <Grid size="grow" />
        <Grid
          container
          direction="column"
          spacing={1}
          size={{
            xs: 12,
            md: 6,
            lg: 4,
            xl: 3,
          }}
        >
          <Grid>
            <Typography variant="subtitle1">Select a license and quantity</Typography>
          </Grid>

          <Grid>
            <TextField
              name="service"
              helperText={
                (props.touched.service && props.errors.service) || "Select the subscription you would like to order"
              }
              error={props.touched.service && Boolean(props.errors.service)}
              label="License Type"
              select
              fullWidth
              variant="outlined"
              margin="dense"
              data-cy="gsuiteServiceSelect"
              slotProps={{
                select: {
                  value: props.values.service,
                  onChange: props.handleChange,
                  onBlur: props.handleBlur,
                  MenuProps: {
                    classes: {
                      paper: classes.selectPaper,
                    },
                  },
                },
              }}
            >
              {services.length > 0 ? (
                services.map((service) => (
                  <MenuItem key={service.snapshot.id} value={service as any}>
                    {service.data.skuName} {service.data.skuId === sku.CloudIdentityPremium && `(${service.data.plan})`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  There are no available services for selected domain and plan
                </MenuItem>
              )}
            </TextField>
          </Grid>

          <Grid>
            <TextField
              name="quantity"
              helperText={
                (props.touched.quantity && props.errors.quantity) ||
                `Enter the number of seats for the selected subscription`
              }
              error={props.touched.quantity && Boolean(props.errors.quantity)}
              label="Quantity"
              value={props.values.quantity ?? ""}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              fullWidth
              variant="outlined"
              margin="dense"
              type="number"
              data-cy="gsuiteServiceQuantity"
            />
          </Grid>
        </Grid>

        <Grid size="grow" />
      </Grid>
    </Grid>
  );
};

const Schema = object().shape({
  gsuiteSubscription: object().required(),
  service: object().required(),
  plan: string().required(),
  quantity: number()
    .typeError("Must be a number")
    .moreThan(0, "Must be at least 1")
    .lessThan(5000, "Must be less than 5000")
    .integer("Must be an integer")
    .required(),
});

export const CreateGSuiteAsset = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { customer, contracts, assets } = useCustomerContext();
  const history = useHistory();
  const classes = useStyles();
  const api = useApiContext();
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();

  const handleClose = () => {
    history.push(`/customers/${customer.id}/assets/g-suite`);
  };

  const getSteps = () => ["Select your Google Workspace Domain", "What would you like to order?", "Order Summary"];

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const handleBack = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const handleSubmit = async (values: ConfirmStepValues, total: number) => {
    try {
      await api.post(`/licenses/gsuite/customers/${values.gsuiteSubscription.data.properties.customerId}`, {
        reseller: values.gsuiteSubscription.data.properties.reseller,
        gsuite: values.gsuiteSubscription.ref.path,
        item: values.service.snapshot.ref.path,
        quantity: Number(values.quantity),
        total: formatCurrency(total, "USD"),
        payment: values.service.data.payment,
      });

      showSnackbar({
        message: "Order completed successfully",
        variant: "success",
        autoHideDuration: 20000,
        action: [
          <IconButton key="close" aria-label="Close" color="default" onClick={hideSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      handleClose();
    } catch (error) {
      showSnackbar({
        message: "Order failed",
        variant: "error",
        autoHideDuration: 20000,
        action: [
          <IconButton key="close" aria-label="Close" color="default" onClick={hideSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(error);
    }
  };

  const getStep = (props: FormikProps<Values>) => {
    const filterAssets = Object.entries(assets).flatMap(([_entryId, entityAssets]) =>
      entityAssets.filter((asset) => asset.data.type === AssetTypeGSuite)
    );

    switch (activeStep) {
      case 0:
        return (
          <>
            <CardContent>
              <DomainStep assets={filterAssets} {...props} />
            </CardContent>
            <CardActions>
              <Button disabled className={classes.actionButton}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={
                  props.isSubmitting ||
                  props.values.gsuiteSubscription === "" ||
                  !props.values.gsuiteSubscription.data.entity
                }
                className={classes.actionButton}
                data-cy="gSuiteAssetNext"
              >
                Next
              </Button>
            </CardActions>
          </>
        );
      case 1:
        return (
          <>
            <CardContent>
              <ServiceStep {...(props as ServiceStepProps)} assets={filterAssets} />
            </CardContent>
            <CardActions>
              <Button onClick={handleBack} className={classes.actionButton} disabled={props.isSubmitting}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={props.isSubmitting || !props.values.service || !props.values.quantity}
                className={classes.actionButton}
                data-cy="gSuiteAssetNext"
              >
                Next
              </Button>
            </CardActions>
          </>
        );
      case 2: {
        const { values } = props as ConfirmStepProps;

        const total = getNewAssetTotal(
          props.values as NewAssetValues,
          contracts.filter(
            (contract) => contract.type === AssetTypeGSuite && contract.active && !contract.assets?.length
          )
        );

        return (
          <>
            <CardContent>
              <NewAssetConfirmSummary
                quantity={values.quantity}
                discount={total.discount}
                total={total.total}
                domain={values.gsuiteSubscription.data.properties.customerDomain}
                skuName={values.service.data.skuName}
                payment={values.service.data.payment}
              />
            </CardContent>
            <CardActions>
              <Button onClick={handleBack} className={classes.actionButton} disabled={props.isSubmitting}>
                Back
              </Button>
              <LoadingButton
                key="submit-button"
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                loading={props.isSubmitting}
                onClick={async () => {
                  props.setSubmitting(true);
                  try {
                    await handleSubmit(values, total.total);
                  } finally {
                    props.setSubmitting(false);
                  }
                }}
                className={classes.actionButton}
                data-cy="gSuiteAssetSubmit"
                mixpanelEventId="assets.g-suite-asset.submit"
              >
                Submit
              </LoadingButton>
            </CardActions>
          </>
        );
      }
      default:
        return "";
    }
  };

  const steps = getSteps();

  return (
    <Card>
      <CardHeader
        avatar={
          <IconButton aria-label="Back" component={Link} to={`/customers/${customer.id}/assets/g-suite`} size="large">
            <BackIcon color="primary" />
          </IconButton>
        }
        title="Add Google Workspace Subscription"
        subheader={customer.name}
      />

      <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Formik<Values>
        initialValues={{ gsuiteSubscription: "", service: "", quantity: "", plan: "" }}
        validationSchema={Schema}
        onSubmit={() => {}} // do nothing
      >
        {(props) => <Form>{activeStep === steps.length ? null : getStep(props)}</Form>}
      </Formik>
    </Card>
  );
};

export default CreateGSuiteAsset;
