import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";

import { metricSplittingDataCy, metricSplittingTxt } from "../../assets/texts/CloudAnalytics";
import NumericTextField from "../../Components/NumericTextField/NumericTextField";
import { type SplitCostTargetsProps, type SplitOption, type SplitTarget } from "./types";
import { checkedTargetsLength, roundToDecimalPercentage } from "./utils";

const splitOptions: SplitOption[] = [
  {
    title: "Evenly split",
    desc: "",
    mode: "even",
  },
  {
    title: "Proportional",
    desc: "Distribute based on the report metric, e.g. cost or usage",
    mode: "proportional",
  },
  {
    title: "Custom",
    desc: "Manually define proportions among items to distribute",
    mode: "custom",
  },
];

const SplitCostTargets = ({
  origin,
  onCheckedTarget,
  splitData,
  checkAll,
  splitMode,
  onSplitModeChanged,
  onChangePercentage,
}: SplitCostTargetsProps) => {
  const targets = useMemo(
    () => splitData?.targets.filter((t) => t.id !== splitData.origin?.id) ?? [],
    [splitData?.targets, splitData?.origin?.id]
  );
  const [numChecked, setNumChecked] = useState<number>(checkedTargetsLength(targets || []));
  const [searchText, setSearchText] = useState<string>("");
  const [filteredTargets, setFilterTargets] = useState<SplitTarget[]>(targets);
  const splitOption = useMemo(() => splitOptions.find((so) => so.mode === splitMode), [splitMode]);
  const description = metricSplittingTxt.SPLIT_COST_TARGETS.DESCRIPTION;

  const handleToggle = (label: string) => () => {
    onCheckedTarget(label);
  };

  const handleCheckAll = () => {
    let checked = true;
    if (numChecked === targets.filter((target) => !target.disabled || target.checked).length) {
      checked = false;
    }
    checkAll(checked);
  };

  const handleSplitModeChange = ({ target: { value } }: SelectChangeEvent<any>) => {
    const so = splitOptions.find((st) => st.title === value);
    if (so) {
      onSplitModeChanged(so.mode);
    }
  };

  useEffect(() => {
    setNumChecked(checkedTargetsLength(targets || []));
  }, [targets]);

  const filterOptions = useCallback(
    () =>
      createFilterOptions<SplitTarget>({
        trim: true,
        ignoreAccents: true,
        ignoreCase: true,
        matchFrom: "any",
      }),
    []
  );

  useEffect(() => {
    const filterFunc = filterOptions()(targets, { inputValue: searchText, getOptionLabel: (option) => option.label });
    setFilterTargets(filterFunc);
  }, [searchText, targets, filterOptions]);

  return (
    <Stack direction="column" sx={{ mt: 2, height: "400px" }}>
      <Typography data-cy={metricSplittingDataCy.TITLE} sx={{ my: 2 }} variant="subtitle1">
        {description.SPLIT}
        <b>{origin?.label}</b>
        {description.BETWEEN}
      </Typography>
      <TextField
        sx={{ my: 2 }}
        placeholder={metricSplittingTxt.SPLIT_COST_TARGETS.INPUT_PLACEHOLDER}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <List>
        <ListItem disablePadding>
          <ListItemButton dense>
            <Checkbox
              data-cy={metricSplittingDataCy.SELECT_ALL}
              edge="start"
              onClick={handleCheckAll}
              disableRipple
              checked={numChecked > 0 && numChecked === targets.length}
              indeterminate={numChecked !== 0 && numChecked !== targets.length}
            />
            <ListItemText onClick={handleCheckAll} primary={`${numChecked} out of ${targets.length} selected`} />
            <FormControl>
              <Select
                data-cy={metricSplittingDataCy.SPLIT_MODE}
                value={splitOption?.title}
                label=""
                onChange={handleSplitModeChange}
                displayEmpty
                renderValue={(value) => value}
                size="small"
                autoWidth
              >
                {splitOptions.map((st) => (
                  <MenuItem value={st.title} key={st.mode}>
                    <Stack>
                      <Typography variant="body1">{st.title}</Typography>
                      <Typography variant="caption">{st.desc}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List sx={{ overflow: "auto" }}>
        {filteredTargets.map(({ label, checked, value, id, disabled }) => (
          <ListItem key={label} disablePadding>
            <ListItemButton dense>
              <Checkbox
                data-cy={`${metricSplittingDataCy.TARGET_CHECK_BTN}-${label}`}
                edge="start"
                checked={checked}
                disabled={!!disabled}
                tabIndex={-1}
                disableRipple
                onClick={handleToggle(label)}
              />
              <ListItemText onClick={!disabled ? handleToggle(label) : undefined} primary={label} />
              {splitOption?.mode === "even" && (
                <Typography
                  data-cy={`${metricSplittingDataCy.PERCENTAGE}-${label}`}
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {roundToDecimalPercentage(+value)}%
                </Typography>
              )}
              {splitOption?.mode === "custom" && (
                <NumericTextField
                  value={roundToDecimalPercentage(+value)}
                  setValue={(num) => {
                    onChangePercentage(id, num);
                  }}
                  allowedInputs={{
                    decimalLimit: 2,
                    allowNegative: false,
                    allowScientificNotation: false,
                  }}
                  textFieldProps={{
                    disabled: !checked,
                    style: { width: 102 },
                    size: "small",
                    inputProps: {
                      "data-cy": `${metricSplittingDataCy.PERCENTAGE_INPUT}-${label}`,
                    },
                    InputProps: {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    },
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default SplitCostTargets;
