import { type Dispatch, type SetStateAction, useState } from "react";

import { CardHeader } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useCustomerContext } from "../../Context/CustomerContext";
import { DashboardsContextProvider } from "../../Context/DashboardContext";
import { type IAMPageId } from "../../Navigation/config/pages";
import { type InviteWithRole, type Role, type UserOrInviteWithRole, type UserWithRole } from "../../types";
import { useIsRestrictedIamDueToSso } from "./hooks";
import { IamTabsThreeDotsMenu } from "./IamTabsThreeDotsMenu";
import Orgs from "./Organizations/Orgs";
import Roles from "./Roles";
import Users from "./Users";
import UsersAuthHelper from "./UsersAuthHelper";

type Props = {
  roles: Role[];
  usersTabParams: {
    users?: UserWithRole[];
    invites?: InviteWithRole[];
  };
  pageId: IAMPageId;
  setSuccessfulImpersonation: Dispatch<SetStateAction<boolean>>;
};

const IamTabs = ({ usersTabParams, roles, pageId, setSuccessfulImpersonation }: Props) => {
  const { customer } = useCustomerContext();
  const [selectedUser, setSelectedUser] = useState<UserOrInviteWithRole | null>();
  const [impersonating, setImpersonating] = useState(false);
  const { isRestrictedIamDueToSso } = useIsRestrictedIamDueToSso();

  return (
    <DashboardsContextProvider>
      <>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {pageId === "iam:users" && (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid size={11}>
                <CardHeader title="Users" sx={{ pl: "8px" }} titleTypographyProps={{ variant: "h1" }} />
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "flex-end" }} size={1}>
                <IamTabsThreeDotsMenu
                  selectedUser={selectedUser}
                  roles={roles}
                  users={[...(usersTabParams.invites ?? []), ...(usersTabParams.users ?? [])]}
                  onImpersonating={() => {
                    setImpersonating(true);
                  }}
                />
              </Grid>
              <Grid size={6}>
                <UsersAuthHelper auth={customer.auth} customerId={customer.id} />
              </Grid>
            </Grid>
          )}
        </div>

        {pageId === "iam:users" && (
          <Users
            isRestrictedIamDueToSso={isRestrictedIamDueToSso}
            userTab={usersTabParams}
            rolesList={roles}
            setSelectedUser={setSelectedUser}
            impersonating={impersonating}
            finishImpersonating={() => {
              setImpersonating(false);
            }}
            successfulImpersonation={setSuccessfulImpersonation}
          />
        )}
        {pageId === "iam:roles" && <Roles roles={roles} />}
        {pageId === "iam:organizations" && (
          <Orgs users={[...(usersTabParams.users ?? []), ...(usersTabParams.invites ?? [])]} />
        )}
      </>
    </DashboardsContextProvider>
  );
};

export default IamTabs;
