import { ProductEnum } from "@doitintl/cmp-models";
import { MenuItem, TextField } from "@mui/material";

import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { SettingsAdminItemWrapper } from "../SettingsAdminItemWrapper";
import { text } from "../text";
import { type SettingsValuesType } from "../types";

export function CloudProvider({
  value,
  onChange,
  errMsg,
}: {
  value: SettingsValuesType["cloudProvider"];
  onChange: (newVal: SettingsValuesType["cloudProvider"]) => void;
  errMsg: string | undefined;
}) {
  return (
    <SettingsAdminItemWrapper disabledForTrial={true}>
      {(isDisabled) => (
        <TextField
          fullWidth
          disabled={isDisabled}
          select
          label={value ? "" : text.CLOUD_PROVIDER_SELECT_LABEL}
          value={value}
          data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.CLOUD_PROVIDER}
          onChange={(e) => {
            if (e.target.value === "") {
              onChange(null);
            } else {
              onChange(e.target.value as unknown as SettingsValuesType["cloudProvider"]);
            }
          }}
          helperText={errMsg ?? ""}
          slotProps={{
            input: {
              sx: { height: 45 },
            },

            htmlInput: {
              "data-cy": DATA_CY_GENERAL_SETTINGS_SELECTORS.CLOUD_PROVIDER,
              "aria-label": text.CLOUD_PROVIDER_SELECT_LABEL,
            },

            inputLabel: {
              shrink: false,
            },

            formHelperText: {
              sx: { display: "flex", color: "error.main" },
            },
          }}
        >
          <MenuItem key={ProductEnum.AmazonWebServices} value={ProductEnum.AmazonWebServices}>
            {text.CLOUD_PROVIDER_AWS_LABEL}
          </MenuItem>
          <MenuItem key={ProductEnum.GoogleCloud} value={ProductEnum.GoogleCloud}>
            {text.CLOUD_PROVIDER_GCP_LABEL}
          </MenuItem>
          <MenuItem key={ProductEnum.Other} value={ProductEnum.Other}>
            {text.CLOUD_PROVIDER_OTHER_LABEL}
          </MenuItem>
        </TextField>
      )}
    </SettingsAdminItemWrapper>
  );
}
