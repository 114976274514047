import { Link } from "react-router-dom";
import { type SaaSConsoleType } from "@doitintl/cmp-models";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { homeTexts } from "../../../assets/texts";
import { headerMap } from "../../../Components/Dashboard/CloudConnection/consts";
import mixpanel from "../../../utils/mixpanel";

export const CloudConnectCard = ({
  logo,
  cloudType,
  route,
}: {
  logo: string;
  cloudType: SaaSConsoleType;
  route: string;
}) => {
  const theme = useTheme();
  const {
    palette: { primary },
  } = theme;

  const cardContent = (
    <Typography
      variant="body2"
      sx={{
        color: "text.secondary",
      }}
    >
      {homeTexts.CLOUD_CONNECT_CARD_CONTENT(headerMap[cloudType])}
    </Typography>
  );

  return (
    <Link
      to={route}
      style={{ textDecoration: "none" }}
      onClick={() => {
        mixpanel.track(`home.cloud-connect.${cloudType}.click`, { $referrer: location.href });
      }}
    >
      <Card sx={{ "&:hover": { borderColor: primary.main }, height: "100%" }}>
        <CardHeader
          sx={{ alignItems: "start" }}
          titleTypographyProps={{ variant: "subtitle1", fontWeight: 500 }}
          avatar={<Box component="img" src={logo} sx={{ height: 24, width: 24 }} />}
          title={homeTexts.CLOUD_CONNECT_CARD_TITLE(headerMap[cloudType])}
          subheader={cardContent}
        />
      </Card>
    </Link>
  );
};
