import { Stack, Typography } from "@mui/material";

export const CustomerInformation = ({
  customerInfoLabel,
  customerInfoValue,
}: {
  customerInfoLabel: string;
  customerInfoValue?: string;
}) => {
  if (!customerInfoValue) {
    return null;
  }
  return (
    <Stack
      direction="row"
      sx={{
        gap: 0.5,
      }}
    >
      <Typography variant="body2">{`${customerInfoLabel}:`}</Typography>
      <Typography variant="body2" style={{ fontWeight: 600 }}>
        {customerInfoValue}
      </Typography>
    </Stack>
  );
};
