import { Link } from "react-router-dom";
import { Button, Container, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import NoPresentationAccessDark from "../../assets/no-presentation-access-dark.svg";
import NoPresentationAccessLight from "../../assets/no-presentation-access-light.svg";
import NoTierAccessDark from "../../assets/no-tier-access-dark.svg";
import NoTierAccessLight from "../../assets/no-tier-access-light.svg";
import { NoEntitlementsText } from "../../assets/texts";
import { useCustomerContext } from "../../Context/CustomerContext";
import { customersRoute } from "../../Navigation/core/utils";
import { getCustomerProspect } from "../Dashboard/CloudConnection/utils";
import { useDarkThemeCheck } from "../hooks/useDarkThemeCheck";
import LoadingButton from "../LoadingButton";
import StartFreeTrialButton from "../StartTrialButton/StartTrialButton";
import useFetchAmTamEmail from "./hooks";

const UpgradeButton = () => (
  // TODO: change this to go to new subscriptions page when it is done
  <Button /* href="mailto:sales@doit-intl.com" */ variant="contained">Upgrade</Button>
);

type ContactSalesButtonProps = {
  sendTo?: string[];
  loading?: boolean;
  customMixpanelEventId?: string;
  fullWidth?: boolean;
  buttonText?: string;
  variant?: "contained" | "text" | "outlined";
};

export const ContactSalesButton = ({
  sendTo = ["team-saas@doit.com"],
  loading = false,
  customMixpanelEventId,
  fullWidth = false,
  buttonText = "Contact us to upgrade",
  variant = "contained",
}: Readonly<ContactSalesButtonProps>) => (
  <LoadingButton
    sx={fullWidth ? {} : { width: "fit-content" }}
    href={`mailto:${sendTo?.join(",")}`}
    variant={variant}
    loading={loading}
    mixpanelEventId={customMixpanelEventId ? customMixpanelEventId : "tiers.entitlement-contact.sales"}
    fullWidth={fullWidth}
  >
    {buttonText}
  </LoadingButton>
);

type Props = {
  feature: string;
  contactSales?: boolean;
  inPresentationMode?: boolean;
  contactSalesButtonText?: string;
  customTitle?: string;
};

type CTAButtonProps = Omit<Props, "feature"> & {
  accountManagers: string[];
  loading: boolean;
  ctaButtonText?: string;
};

const CTAButton = ({ contactSales, accountManagers, loading, inPresentationMode, ctaButtonText }: CTAButtonProps) => {
  const { customer } = useCustomerContext();
  // Hide start free trial button for advantage only customers in presentation mode
  const hideStartTrial = inPresentationMode && getCustomerProspect(customer) === "advantage";

  if (hideStartTrial) {
    return null;
  }

  if (inPresentationMode) {
    return <StartFreeTrialButton />;
  }

  return contactSales ? (
    <ContactSalesButton sendTo={accountManagers} loading={loading} buttonText={ctaButtonText} />
  ) : (
    <UpgradeButton />
  );
};

function NoEntitlement({
  feature,
  contactSales = true,
  contactSalesButtonText,
  inPresentationMode,
  customTitle = "",
}: Readonly<Props>) {
  const dark = useDarkThemeCheck();
  const { customer } = useCustomerContext();
  const { accountManagers, loading } = useFetchAmTamEmail();

  const noTierAccess = dark ? NoTierAccessDark : NoTierAccessLight;
  const noPresentationAccess = dark ? NoPresentationAccessDark : NoPresentationAccessLight;

  return (
    <Container maxWidth="md" data-cy="noEntitlement">
      <Stack
        spacing={3}
        direction="column"
        useFlexGap
        sx={{
          mt: 8,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <img src={inPresentationMode ? noPresentationAccess : noTierAccess} alt="no-tier-access" />
        <Typography variant="h1">{customTitle || NoEntitlementsText.TITLE(inPresentationMode, feature)}</Typography>
        <Typography variant="body1" color="textSecondary">
          {NoEntitlementsText.SUBTITLE(inPresentationMode)}
        </Typography>
        <CTAButton
          accountManagers={accountManagers}
          contactSales={contactSales}
          inPresentationMode={inPresentationMode}
          loading={loading}
          ctaButtonText={contactSalesButtonText}
        />
        {!inPresentationMode && (
          <>
            <Divider variant="fullWidth" component="span" flexItem />
            <Typography variant="body1" color="textSecondary">
              {NoEntitlementsText.FEATURE_DETAILS}
            </Typography>
            <Button component={Link} to={customersRoute(customer.id, "support/new")} variant="outlined">
              {NoEntitlementsText.CONTACT_SUPPORT}
            </Button>
          </>
        )}
      </Stack>
    </Container>
  );
}

export default NoEntitlement;
