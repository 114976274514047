import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import CountUpCard from "../../Components/CountUp/CountUpCard";
import { type CountUpCardProps } from "./types";

type AggregationCardsProps = {
  cards: CountUpCardProps[];
};

export const AggregationCards = ({ cards }: AggregationCardsProps) => {
  if (cards === null) {
    return null;
  }

  return (
    <Box
      sx={{
        pt: 1,
        pb: 1,
      }}
    >
      <Grid spacing={1} container>
        {cards.map((card, i) => (
          <Grid
            key={`card_${i}`}
            size={{
              sm: 4,
              xs: 12,
            }}
          >
            <CountUpCard
              contentPadding={4}
              number={card.number}
              suffix={card?.suffix}
              prefix={card?.prefix}
              duration={1}
              decimals={card?.decimals}
              text={card.text}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
