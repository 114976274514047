import { type CustomerSavingsPlan } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import { Box, CircularProgress } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { FilterTable } from "../../../../../../Components/FilterTable/FilterTable";
import BorderedBox from "../../../../BorderedBox";
import { customerSavingsPlansColumns } from "./columns";
import { CustomerSavingsPlansRow } from "./CustomerSavingsPlansRow";

type Props = {
  customerName: string;
  data: WithFirebaseModel<CustomerSavingsPlan>[] | null;
};

export const CustomerSavingsPlansTable = ({ customerName, data }: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        pt: 5,
      }}
    >
      <BorderedBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pr: 4,
            borderBottom: `1px solid ${theme.palette.general.divider}`,
          }}
        >
          <CardHeader
            titleTypographyProps={{ variant: "h4" }}
            title={`${customerName} Savings Plans overview`}
            subheaderTypographyProps={{ variant: "body2" }}
          />
        </Box>

        <Box
          sx={{
            px: 2,
            pb: 4,
            paddingBottom: "0px",
          }}
        >
          {!data ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 6,
              }}
            >
              <CircularProgress />
            </Box>
          ) : data.length === 0 ? (
            <Typography
              variant="body2"
              component="p"
              sx={{
                textAlign: "center",
                padding: 2,
                color: theme.palette.text.secondary,
              }}
            >
              You don't have any Savings Plans to display
            </Typography>
          ) : (
            <Box
              sx={{
                overflow: "auto",
              }}
            >
              <FilterTable
                tableItems={data}
                rowComponent={CustomerSavingsPlansRow}
                headerColumns={customerSavingsPlansColumns}
                filterColumns={[]}
                showFilterBar={false}
                defaultSortingColumnValue="expirationDate"
                defaultSortDirection="asc"
                fillEmptySpace={false}
              />
            </Box>
          )}
        </Box>
      </BorderedBox>
    </Box>
  );
};
