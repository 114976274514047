import { type JSX, useCallback } from "react";

import { Box, Button, Stack } from "@mui/material";
import TableCell from "@mui/material/TableCell";

import { useDarkThemeCheck } from "../../../../../../Components/hooks/useDarkThemeCheck";
import { ThreeDotsMenu } from "../../../../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../../../../Context/AuthContext";
import DeleteEstimation from "../../../../Standalone/AWS/DeleteEstimation";
import { type ActiveAccountItem } from "./hooks";

type Props = {
  row: ActiveAccountItem;
  currencyFormat: (value: number) => string;
  onSetupFlexsaveClicked: (accountsId: string) => void;
};

export const AccountsTableRow = ({
  row: { accountId, estimation, enabledDate, completed },
  currencyFormat,
  onSetupFlexsaveClicked,
}: Props) => {
  const { isDoitEmployee } = useAuthContext();

  const formatEstimation = useCallback(
    (estimation: string | number): JSX.Element | string => {
      if (typeof estimation === "number") {
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Box>{`${currencyFormat(estimation)} (estimate)`}</Box>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                onSetupFlexsaveClicked(accountId);
              }}
              sx={{ fontWeight: 400 }}
            >
              Set up Flexsave
            </Button>
          </Stack>
        );
      } else {
        return estimation;
      }
    },
    [currencyFormat, onSetupFlexsaveClicked, accountId]
  );

  const darkMode = useDarkThemeCheck();

  return (
    <>
      <TableCell>{accountId}</TableCell>
      <TableCell>{formatEstimation(estimation)}</TableCell>
      <TableCell>{enabledDate}</TableCell>
      <TableCell align="right" padding="none">
        {isDoitEmployee && !completed && (
          <ThreeDotsMenu
            options={[
              {
                menuItem: (
                  <Box
                    key={accountId}
                    sx={{
                      px: 2,
                      py: 1,
                    }}
                  >
                    <DeleteEstimation accountId={accountId} underline="none" color={darkMode ? "white" : "black"} />
                  </Box>
                ),
              },
            ]}
          />
        )}
      </TableCell>
    </>
  );
};
