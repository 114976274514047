import { useEffect, useId, useState } from "react";

import { type ReferencedNodeValue } from "@doitintl/cmp-models";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useFormikContext } from "formik";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../../../../../assets/texts";
import { useToggle } from "../../../../../../utils/useToggle";
import { FieldChip } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldChip";
import { ReferencedFieldStandalone } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { type NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import SwitchDataSourceDialog from "../../../FilterAction/SwitchDataSourceDialog";
import { type TransformationNodeFormValues } from "./TransformationNodeForm";

export const TransformationNodeDataSourceForm = ({
  referenceableNodes,
}: {
  referenceableNodes: NodeWitOutputModel[];
}) => {
  const [showDataSourceDialog, openDataSourceDialog, closeDataSourceDialog] = useToggle(false);
  const [confirmDataSourceChange, setConfirmDataSourceChange] = useState<() => void>(noop);
  const { getFieldProps, setValues } = useFormikContext<TransformationNodeFormValues>();
  const referencedNodeIdProps = getFieldProps("referencedNodeField.referencedNodeId");

  const [selectedNode, setSelectedNode] = useState<NodeWitOutputModel[]>(
    referenceableNodes.filter(({ id }) => referencedNodeIdProps.value === id)
  );
  const [rootReferencedNodeValue, setRootReferencedNodeValue] = useState<ReferencedNodeValue>({
    referencedField: [],
    referencedNodeId: referencedNodeIdProps.value,
  });

  const transformationTypeProps = getFieldProps("type");

  const referencedNodeLabelId = useId();
  const referencedNodeIdCommonProps = useFieldCommonProps(referencedNodeIdProps, cloudflowTexts.DATA_SOURCES, true);

  const referencedNodeFieldProps = getFieldProps("referencedNodeField");
  const referencedNodeFieldCommonProps = useFieldCommonProps(
    referencedNodeFieldProps,
    cloudflowTexts.REFERENCED_FIELD,
    true
  );

  useEffect(() => {
    setSelectedNode(referenceableNodes.filter(({ id }) => referencedNodeIdProps.value === id));
  }, [referenceableNodes, referencedNodeIdProps.value]);

  useEffect(() => {
    setRootReferencedNodeValue({
      referencedField: [],
      referencedNodeId: referencedNodeIdProps.value,
    });
  }, [referencedNodeIdProps.value]);

  function changeDataSourceGuard<T>(
    generateValues: (input: T) => TransformationNodeFormValues
  ): (event: SelectChangeEvent<T>) => void {
    return ({ target: { value } }) => {
      const valueSetter = () => {
        setValues(generateValues(value as T), false);
      };

      if (!transformationTypeProps.value) {
        valueSetter();
      } else {
        openDataSourceDialog();
        setConfirmDataSourceChange(() => valueSetter);
      }
    };
  }

  const onReferencedNodeIdChange = changeDataSourceGuard((value: string) => ({
    type: null,
    referencedNodeField: {
      referencedField: [],
      referencedNodeId: value,
    },
    transformation: null,
  }));

  const onReferencedNodeFieldChange = changeDataSourceGuard((value: ReferencedNodeValue) => ({
    type: null,
    referencedNodeField: value,
    transformation: null,
  }));

  const isReferencedFieldDisabled = selectedNode.length === 0;

  return (
    <Stack
      spacing={2}
      sx={{
        mb: 3,
      }}
    >
      <SwitchDataSourceDialog
        isDialogOpened={showDataSourceDialog}
        content="Changing your data source will remove any action configuration you have assigned. Do you want to change your data source?"
        handleClose={closeDataSourceDialog}
        handleConfirm={() => {
          confirmDataSourceChange();
          closeDataSourceDialog();
        }}
      />
      <FieldSectionHeader title={cloudflowTexts.SELECT_DATA_SOURCE} subtitle={cloudflowTexts.PICK_STEP_TO_TRANSFORM} />
      <FormControl fullWidth sx={{ mb: 3 }} required error={referencedNodeIdCommonProps.error}>
        <InputLabel size="small" id={referencedNodeLabelId}>
          {referencedNodeIdCommonProps.label}
        </InputLabel>
        <Select
          labelId={referencedNodeLabelId}
          size="small"
          disabled={referenceableNodes.length === 0}
          renderValue={() => <FieldChip tokens={selectedNode.map(({ name }) => name)} />}
          {...referencedNodeIdCommonProps}
          onChange={onReferencedNodeIdChange}
        >
          {referenceableNodes.map((node) => (
            <MenuItem key={node.id} value={node.id}>
              {node.name}
            </MenuItem>
          ))}
        </Select>
        {referencedNodeIdCommonProps.error && <FormHelperText>{referencedNodeIdCommonProps.helperText}</FormHelperText>}
      </FormControl>
      <FieldSectionHeader
        title={cloudflowTexts.SELECT_FIELD_TO_TRANSFORM}
        subtitle={cloudflowTexts.FROM_CHOSEN_STEP_TO_TRANSFORM}
      />
      <ReferencedFieldStandalone
        {...referencedNodeFieldCommonProps}
        onChange={onReferencedNodeFieldChange}
        disabled={isReferencedFieldDisabled}
        referenceableNodes={selectedNode}
        rootReferencedNodeValue={rootReferencedNodeValue}
        tooltip={isReferencedFieldDisabled ? cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE : ""}
      />
    </Stack>
  );
};

export default TransformationNodeDataSourceForm;
