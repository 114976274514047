import { Link } from "react-router-dom";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

type Props = {
  title: string;
  backButtonRoute?: string;
};
export const Title = ({ title, backButtonRoute }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ml: backButtonRoute ? -2 : 0,
        pb: 2,
      }}
    >
      {!!backButtonRoute && (
        <IconButton aria-label="Back" component={Link} to={backButtonRoute} size="large">
          <BackIcon htmlColor={theme.palette.text.primary} />
        </IconButton>
      )}
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
};
