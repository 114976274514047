import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

export const Quicklinks = ({ customerId, canHaveAwsFlexsave }: { customerId: string; canHaveAwsFlexsave: boolean }) => {
  const getLink = (text: string, path: string) => (
    <Box>
      <Typography component={Link} to={`/customers/${customerId}/${path}`} color="inherit" variant="body2">
        {text}
      </Typography>
    </Box>
  );
  return (
    <Box
      sx={{
        ml: 2,
        margin: 1,
      }}
    >
      <Typography variant="h6" component="div">
        Quick Links
      </Typography>
      <Box
        style={{ maxWidth: 250 }}
        sx={{
          mt: 1,
        }}
      >
        {canHaveAwsFlexsave && getLink("Flexsave", `flexsave-aws`)}
        {getLink("Spot Scaling", `spot-scaling`)}
        {getLink("Reports", `analytics/reports`)}
        {getLink("Anomalies", `anomaly`)}
        {getLink("General Settings", `settings/general`)}
      </Box>
    </Box>
  );
};
