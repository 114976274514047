import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useField } from "formik";

import { RequiredLabel } from "../helpers";
import { type SubscriptionFormValues, type SubscriptionInputProps } from "../types";

export function StartDateInput({ disabled }: SubscriptionInputProps) {
  const [startDate, meta, formikHelpers] = useField<SubscriptionFormValues["startDate"]>("startDate");

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={navigator.language}>
      <DatePicker
        disableMaskedInput
        label={RequiredLabel("Start date")}
        value={startDate.value}
        inputFormat="dd MMMM yyyy"
        onChange={(e) => {
          formikHelpers.setValue(e as any);
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!meta.error}
            fullWidth
            variant="outlined"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
