import React, { useContext } from "react";

import Grid from "@mui/material/Grid2";

import { SortableWidget } from "../SortableWidget";
import { WidgetsContext } from "../WidgetsGrid";

type Props = {
  singleWidgetInRow: boolean;
};

export function StaticWidgetsGrid({ singleWidgetInRow }: Props) {
  const [widgets] = useContext(WidgetsContext);

  return (
    <Grid container spacing={2}>
      {widgets.map((item) => (
        <Grid
          key={item.name}
          sx={{
            mb: 3,
            height: "380px",
          }}
          size={singleWidgetInRow ? 12 : 6}
        >
          <SortableWidget key={item.name} id={item.name} width={"calc(100% - 10px)"} height={380} />
        </Grid>
      ))}
    </Grid>
  );
}
