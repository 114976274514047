import { type JSX } from "react";

import { Box, Container, Stack } from "@mui/material";

import { BottomAppBar, type BottomAppBarProps } from "../../../../Components/BottomAppBar";

type Props = {
  information?: JSX.Element;
  children: JSX.Element;
  bottomAppBarProps: BottomAppBarProps;
};

export const OnboardLayout = ({ children, information, bottomAppBarProps }: Props) => (
  <Container>
    <Stack
      direction="column"
      sx={{
        pb: 16,
      }}
    >
      <Stack
        sx={{
          flexDirection: { sm: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "56%" },
            mr: { sm: 0, md: 2 },
            mb: { xs: 6, sm: 6, md: 0 },
          }}
        >
          {children}
        </Box>
        {information}
      </Stack>
    </Stack>
    <BottomAppBar {...bottomAppBarProps} />
  </Container>
);
