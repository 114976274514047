import { Stack, Typography } from "@mui/material";

export const CardTextPlaceholder = ({ title }: { title: string }) => (
  <Stack
    sx={{
      alignItems: "center",
    }}
  >
    <Typography
      variant="body2"
      sx={{
        fontWeight: "600",
        textAlign: "center",
      }}
    >
      {title}
    </Typography>
    <Typography
      data-cy="flexsave-activation"
      variant="body2"
      sx={{
        textAlign: "center",
      }}
    >
      will start showing here 7 days after Flexsave activation
    </Typography>
  </Stack>
);
