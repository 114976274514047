import { useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";

import doitLogo from "../../assets/doit-logo-hero-square.svg";

type FeedbackTitleProps = {
  ticketId: number;
  ticketSummary: string;
  handleClose: () => void;
};

const FeedbackTitle = ({ ticketId, ticketSummary, handleClose }: FeedbackTitleProps) => {
  const { customerId } = useParams<{ customerId: string }>();
  const { t } = useTranslation("services");

  return (
    <DialogTitle id="form-dialog-title" component={Box}>
      <Typography variant="h3">{t("SurveyDialog.title")}</Typography>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          gap: "0.5ch",
          mt: 1,
        }}
      >
        <Box component="img" src={doitLogo} sx={{ width: "2rem", height: "2rem", ml: "-0.5ch" }} />
        <Typography variant="body2" color="textSecondary">
          <Typography
            variant="body2"
            component={RouterLink}
            sx={{ color: "inherit" }}
            to={`/customers/${customerId}/support/tickets/${ticketId}`}
            onClick={handleClose}
          >
            {`#${ticketId}`}
          </Typography>
          {` - ${ticketSummary}`}
        </Typography>
      </Stack>
    </DialogTitle>
  );
};

export default FeedbackTitle;
