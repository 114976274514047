import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";

import resourceDetailsComponent from "./getResourceDetailsComponent";
import { type ResourceDetailType } from "./resourceDetailType";

const defaultFirstIndex: string = "initial-record";

type DetailsSet = {
  [id: string]: ResourceDetailType;
};

type Props = {
  resourceType: string;
  onChange: (data: ResourceDetailType[]) => void;
};

const ResourceDetailsWrapper = ({ resourceType, onChange }: Props) => {
  const [detailsSets, setDetailsSets] = useState<DetailsSet>({
    [defaultFirstIndex]: { assetIndex: defaultFirstIndex },
  });

  const Component = resourceDetailsComponent(resourceType);

  useEffect(() => {
    const affectedAssetDetails = Object.values(detailsSets);
    onChange(affectedAssetDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsSets]);

  const onDelete = (index: string) => {
    const dataCopy: DetailsSet = {};
    Object.keys(detailsSets).forEach((key) => {
      if (key !== index) {
        dataCopy[key] = detailsSets[key];
      }
    });
    setDetailsSets(dataCopy);
  };

  const onResourceDetailsChange = (index: string, data: ResourceDetailType) => {
    const dataCopy = { ...detailsSets };
    dataCopy[index] = data;
    setDetailsSets(dataCopy);
  };

  return (
    <>
      <Typography
        variant="h4"
        sx={{
          color: "text.primary",
          mb: 1,
          mt: 3,
        }}
      >
        Resource details
      </Typography>
      {Object.keys(detailsSets).map((key, idx) => (
        <Component
          key={detailsSets[key].assetIndex}
          index={idx}
          onDelete={onDelete}
          onChange={onResourceDetailsChange}
          values={detailsSets[key]}
        />
      ))}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Button
          onClick={() => {
            const newId = uuid();
            const dataCopy = { ...detailsSets };
            dataCopy[newId] = { assetIndex: newId };
            setDetailsSets(dataCopy);
          }}
          data-cy="addResourceBtn"
          variant="outlined"
        >
          Add another resource
        </Button>
      </Box>
    </>
  );
};

export default ResourceDetailsWrapper;
