import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";

import { formatDecimalNumber, formatNumber } from "../../../utils/common";
import { type CardConfig, TrendType } from "./types";

export const cyIds = {
  arrow: {
    up: "cardHeaderArrowUp",
    down: "cardHeaderArrowDown",
  },
  secondaryText: "secondaryText",
  na: "cardHeaderNa",
};

export const CardHeaderTitle = ({ trend, trendDirection, monthOffset, prefix }: CardConfig) => {
  if (trend === 0 && monthOffset === 0) {
    return (
      <Box
        data-cy={cyIds.na}
        sx={{
          pt: 0.5,
          textAlign: "center",
        }}
      >
        <Typography color="textSecondary">N/A</Typography>
      </Box>
    );
  }

  const trendOutput: string = `${trend ? formatNumber(Math.abs(trend), 1) : 0}% or `;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        textAlign: "center",
      }}
    >
      <Typography style={{ marginTop: 1 }}>
        {trendDirection === TrendType.Up ? (
          <ArrowUpwardIcon htmlColor={red[400]} fontSize="small" data-cy={cyIds.arrow.up} />
        ) : (
          <ArrowDownwardIcon htmlColor={green[400]} fontSize="small" data-cy={cyIds.arrow.down} />
        )}
      </Typography>
      <Typography color="textSecondary" data-cy={cyIds.secondaryText}>
        {trendDirection === TrendType.Up ? "Up" : "Down"}
        {` `}
        {trend !== Infinity && trendOutput}
        {!!monthOffset && `${prefix ?? ""}${formatDecimalNumber(Math.abs(monthOffset), 0)}`}
      </Typography>
    </Box>
  );
};
