import { useRef } from "react";

import Lottie from "react-lottie-player";
import { DashboardType } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import awsLoading from "./aws-loading.json";
import gcpLoading from "./gcp-loading.json";
import pulseLoading from "./pulse-loading.json";

type Props = {
  dashboardType: string | DashboardType;
};

const LoadingState = {
  [DashboardType.Pulse]: {
    title: "Pulse dashboard",
    subtitle1: "We are currently processing your billing data.",
    subtitle2: "The Pulse dashboard will provide you with an at-a-glance view of your cloud spend.",
    animation: pulseLoading,
  },
  [DashboardType.GcpLens]: {
    title: "GCP Lens",
    subtitle1: "We are currently processing your billing data.",
    subtitle2:
      "The GCP Lens provides a way for you to consistently measure your Google Cloud workloads against best practices and identify areas for improvement.",
    animation: gcpLoading,
  },
  [DashboardType.AzureLens]: {
    title: "Azure Lens",
    subtitle1: "We are currently processing your billing data.",
    subtitle2:
      "The Azure Lens provides a way for you to consistently measure your Microsoft Azure workloads against best practices and identify areas for improvement.",
    animation: gcpLoading,
  },
  [DashboardType.AwsLens]: {
    title: "AWS Lens",
    subtitle1: "We are currently processing your billing data.",
    subtitle2:
      "The AWS Lens dashboard will help you to decide how and when to optimize costs, monitor performance, and more.",
    animation: awsLoading,
  },
  [DashboardType.EKSLens]: {
    title: "EKS Lens",
    subtitle1: "We are currently processing your EKS data.",
    subtitle2: "The EKS Lens dashboard will breakdown your spend, view trends and more.",
    animation: awsLoading,
  },
};

const ProcessingState = ({ dashboardType }: Props) => {
  const lottieRef = useRef<any>();

  const getDashboardType = (dashboardType: string): string => {
    switch (dashboardType) {
      case DashboardType.SaaSGcpLens:
        return DashboardType.GcpLens;
      case DashboardType.SaaSAwsLens:
        return DashboardType.AwsLens;
      default:
    }

    return dashboardType;
  };

  const dType = getDashboardType(dashboardType);

  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "center",
        my: 15,
      }}
    >
      <Lottie
        ref={lottieRef}
        onClick={() => lottieRef.current?.stop()}
        style={{ width: 300, height: 200 }}
        loop
        play
        animationData={LoadingState[dType].animation}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
        speed={1}
      />
      <Typography variant="h1">{LoadingState[dType].title}</Typography>
      <Typography variant="body1">{LoadingState[dType].subtitle1}</Typography>
      <Typography variant="body1">{LoadingState[dType].subtitle2}</Typography>
    </Stack>
  );
};

export default ProcessingState;
