import { type CurrencyCode, CurrencyCodes } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import { fractionOfMonthRemaining, fractionOfYearRemaining } from "../../../../utils";
import { formatNumber, getCurrencySymbol } from "../../../../utils/common";
import { cyIds } from "../LicenseChangeConfirmSummary";

type GSuiteConfirmationCostTextProps = {
  amount: number;
  quantity: number;
  discount: number;
  payment: "MONTHLY" | "YEARLY";
  currency?: CurrencyCode;
  endTime?: DateTime;
  isCommitmentPlan?: boolean;
};

const GSuiteConfirmationCostText = ({
  amount,
  quantity,
  discount,
  payment,
  currency = CurrencyCodes.USD,
  endTime,
  isCommitmentPlan = false,
}: GSuiteConfirmationCostTextProps) => {
  if (!quantity) {
    return null;
  }

  const monthlyPay = payment === "MONTHLY";
  const remainingFractionOfUnit = monthlyPay ? fractionOfMonthRemaining() : fractionOfYearRemaining();
  const cycle = {
    unit: monthlyPay ? "month" : "year",
    frequency: monthlyPay ? "monthly" : "annual",
  };
  const formatCurrency = (amount: number) => `${getCurrencySymbol(currency)}${formatNumber(amount, 2)}`;

  const initialCostText = (
    <Stack component="span" data-cy={cyIds.section.initial}>
      <Stack
        direction="row"
        component="span"
        sx={{
          gap: 0.5,
        }}
      >
        <Typography
          variant="body1"
          component="span"
          sx={{
            color: "text.primary",
          }}
        >
          Initial cost
        </Typography>
        <Tooltip
          title={`Recurring ${cycle.frequency} cost prorated for the time already elapsed in the current ${cycle.unit}.`}
          arrow
          sx={{ m: 0.5 }}
          placement="right"
          color="text.secondary"
        >
          <InfoOutlinedIcon color="inherit" fontSize="inherit" />
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        component="span"
        sx={{
          gap: 0.5,
        }}
      >
        <Typography
          variant="body1"
          component="span"
          data-cy={cyIds.text.initial}
          sx={{
            color: "text.primary",
            fontWeight: "bold",
          }}
        >
          {formatCurrency(amount * remainingFractionOfUnit)}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          data-cy={cyIds.text.discountInitial}
          sx={{
            color: "text.secondary",
          }}
        >
          {`(${discount}% discount applied)`}
        </Typography>
      </Stack>
    </Stack>
  );

  const recurringCostText = (
    <Stack component="span" data-cy={cyIds.section.recurring}>
      <Stack
        direction="row"
        component="span"
        sx={{
          gap: 0.5,
        }}
      >
        <Typography
          variant="body1"
          component="span"
          sx={{
            color: "text.primary",
          }}
        >
          {`Recurring ${cycle.frequency} cost`}
        </Typography>
        <Tooltip
          title={`Recurring ${cycle.frequency} costs for future invoice periods.`}
          arrow
          sx={{ m: 0.5 }}
          placement="right"
          color="text.secondary"
        >
          <InfoOutlinedIcon color="inherit" fontSize="inherit" />
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        component="span"
        sx={{
          gap: 0.5,
        }}
      >
        <Typography
          variant="body1"
          component="span"
          data-cy={cyIds.text.recurring}
          sx={{
            color: "text.primary",
            fontWeight: "bold",
          }}
        >
          {formatCurrency(amount)}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          data-cy={cyIds.text.discountRecurring}
          sx={{
            color: "text.secondary",
          }}
        >
          {`(${discount}% discount applied)`}
        </Typography>
      </Stack>
    </Stack>
  );

  let endsInCurrentMonth = false;
  if (isCommitmentPlan && endTime) {
    const currentDate = DateTime.now().setZone("utc");
    endsInCurrentMonth = endTime.year === currentDate.year && endTime.month === currentDate.month;
  }

  const singlePaymentAmount =
    monthlyPay && endsInCurrentMonth ? formatCurrency(amount * remainingFractionOfUnit) : formatCurrency(amount);
  const endDateText = endTime ? (
    <Typography
      variant="body1"
      component="span"
      sx={{
        color: "text.primary",
      }}
    >
      {`Up to commitment end date ${endTime.toLocaleString()}.`}
    </Typography>
  ) : null;

  const useOneCharge = isCommitmentPlan || !monthlyPay || (monthlyPay && endsInCurrentMonth);

  const oneChargeText = (
    <Stack component="span" data-cy={cyIds.section.single}>
      <Stack
        direction="row"
        component="span"
        sx={{
          gap: 0.5,
        }}
      >
        <Typography
          variant="body1"
          component="span"
          data-cy={cyIds.text.single}
          sx={{
            color: "text.primary",
            fontWeight: "bold",
          }}
        >
          {singlePaymentAmount}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          data-cy={cyIds.text.discountSingle}
          sx={{
            color: "text.secondary",
          }}
        >
          {`(${discount}% discount applied)`}
        </Typography>
      </Stack>
      {endDateText}
    </Stack>
  );

  return useOneCharge ? (
    oneChargeText
  ) : (
    <>
      {initialCostText}
      {recurringCostText}
    </>
  );
};

export default GSuiteConfirmationCostText;
