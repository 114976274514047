import { DashboardModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { Box, Dialog, DialogContent, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { useFullScreen } from "../../../utils/dialog";
import { AttributionSettingsForm } from "./AttributionSettingsForm";

const Heading = styled(Typography)`
  font-size: 20px;
`;

const AttributionsDialog = styled(DialogContent)`
  width: 100%;
`;

const SubTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
`;

type Props = {
  onClose: () => void;
  attributionId: string;
};

export const AttributionSettingsModal = ({ onClose, attributionId }: Props) => {
  const ref = getCollection(DashboardModel).doc("google-cloud-reports").collection("attributions").doc(attributionId);

  const [attributionData] = useDocumentDataOnce(ref);
  const { isMobile: isSmallScreen } = useFullScreen("sm");
  return (
    <Dialog open={true} onClose={onClose} fullScreen={isSmallScreen} fullWidth maxWidth="xs">
      <AttributionsDialog sx={{ p: 0 }}>
        {!attributionData && (
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              display: "flex",
              height: "250px",
              p: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {attributionData && (
          <Box>
            <Stack sx={{ p: 2 }}>
              <Heading variant="h6">Anomaly detection</Heading>
              <SubTitle variant="subtitle2" color="textSecondary" sx={{ mt: 2, mb: 1 }}>
                {attributionData.name}
              </SubTitle>
            </Stack>

            <AttributionSettingsForm
              initialValues={{ anomalyDetection: attributionData.anomalyDetection ?? false }}
              onCancel={onClose}
              onSubmit={async (data) => {
                await ref.update(data);
                onClose();
              }}
            />
          </Box>
        )}
      </AttributionsDialog>
    </Dialog>
  );
};
