import "./codemirror.css";

import { useEffect, useMemo } from "react";

import { sql } from "@codemirror/lang-sql";
import { Box, CircularProgress, DialogActions, DialogContent, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { material } from "@uiw/codemirror-theme-material";
import CodeMirror from "@uiw/react-codemirror";
import { format as sqlFormatter } from "sql-formatter";

import { useGetBigQueryJobQuery } from "../../../api/endpoints";
import { formatDecimalNumber } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { useErrorSnackbar } from "../../SharedSnackbar/SharedSnackbar.context";
import { DialogTitle } from "./DialogTitle";

type Props = {
  open: boolean;
  onClose: () => void;
  selectedValue: {
    name: string;
    data: {
      userId?: string;
      avgSlotsPerSec?: number;
      executedQueries?: number;
      location?: string;
      billingProjectId: string;
      query: string;
      jobId: string;
    };
  };
};

export function QueryViewerDialog({ onClose, selectedValue, open }: Props) {
  const {
    name,
    data: { location = "US", billingProjectId },
  } = selectedValue;

  const showError = useErrorSnackbar();
  const { fullScreen } = useFullScreen();
  const { isLoading, data, error } = useGetBigQueryJobQuery(name, location, billingProjectId);

  useEffect(() => {
    if (!error) {
      return;
    }
    showError("Unable to display query");
  }, [error, showError]);

  const formattedQuery = useMemo(() => {
    try {
      return sqlFormatter(data?.query ?? "", { language: "bigquery" });
    } catch (e) {
      return data?.query;
    }
  }, [data]);

  return (
    <Dialog maxWidth="lg" fullWidth={true} fullScreen={fullScreen} onClose={onClose} open={open}>
      <DialogTitle>Job ID: {selectedValue.name}</DialogTitle>
      <DialogContent>
        {!!selectedValue.data?.userId && (
          <>
            <Typography variant="subtitle2" component="span">
              User ID:{" "}
            </Typography>
            <Typography variant="body2" component="span">
              {selectedValue.data.userId}
            </Typography>
            <br />
          </>
        )}
        {!!selectedValue.data?.avgSlotsPerSec && (
          <>
            <Typography variant="subtitle2" component="span">
              Average Slots Per Sec:{" "}
            </Typography>
            <Typography variant="body2" component="span">
              {formatDecimalNumber(selectedValue.data.avgSlotsPerSec, 1)}
            </Typography>
            <br />
          </>
        )}
        {!!selectedValue.data?.executedQueries && (
          <>
            <Typography variant="subtitle2" component="span">
              Executed Queries:{" "}
            </Typography>
            <Typography variant="body2" component="span">
              {selectedValue.data.executedQueries}
            </Typography>
          </>
        )}

        <div style={{ height: "100%" }}>
          {isLoading ? (
            <Box
              sx={{
                pt: 5,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            data && (
              <Box sx={{ marginTop: "8px", padding: "6px" }}>
                <CodeMirror value={formattedQuery} extensions={[sql({})]} theme={material} />
              </Box>
            )
          )}
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
