import { useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, TextField } from "@mui/material";

import { type RampPlanModel } from "../types";

type DialogSelectRampPlanProps = {
  open: boolean;
  value: string;
  onOpen: () => void;
  onSave: (value: string) => void;
  onClose: () => void;
  rampPlansList: Readonly<Array<RampPlanModel>>;
};

const DialogSelectRampPlan = ({ open, value, onOpen, onClose, onSave, rampPlansList }: DialogSelectRampPlanProps) => {
  const [selectedRampPlanId, setSelectedRampPlanId] = useState<string>(value);

  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [open, onOpen]);

  return (
    <Dialog open={open} onClose={onClose} data-cy="selectRampPlanDialog">
      <DialogTitle>Select ramp plan for dashboard widget</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          select
          variant="outlined"
          fullWidth
          label="Ramp plan"
          value={selectedRampPlanId}
          onChange={(event) => {
            setSelectedRampPlanId(event.target.value);
          }}
          sx={{ mt: 1 }}
          data-cy="rampPlanSelect"
          slotProps={{
            htmlInput: { "data-testid": "description-input" },
          }}
        >
          {rampPlansList.map((rampPlan) => (
            <MenuItem key={rampPlan.id} value={rampPlan.id} data-cy={`ramp-option-${rampPlan.id}`}>
              {rampPlan.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onSave(selectedRampPlanId);
            onClose();
          }}
          color="primary"
          variant="contained"
          data-cy="save-button"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSelectRampPlan;
