import { useEffect, useState } from "react";

import { NODE_STATUS } from "@doitintl/cmp-models";
import { type CloudFlowNodeType, type ReferencedNodeValue } from "@doitintl/cmp-models";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

import { cloudflowTexts } from "../../../../assets/texts";
import { PlaceholderParam } from "../ApiActionParametersForm/parameters/PlaceholderParam";
import { FieldChip } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldChip";
import { ReferencedFieldStandalone } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { getFieldRef } from "../Common/utils";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";

type FieldReferenceFormProps = {
  referenceableNodes: NodeWitOutputModel[];
  confirmSwitchDataSource: (
    onConfirm: (() => Promise<void>) | undefined,
    onClose: (() => Promise<void>) | undefined
  ) => Promise<void>;
  selectedNode: NodeWitOutputModel | undefined;
  setSelectedNode: (node: NodeWitOutputModel) => void;
  texts: {
    datasource: {
      title: string;
      subtitle: string;
      label: string;
    };
    referenceField: {
      title: string;
      subtitle: string;
      label: string;
      placeholderTooltip: string;
    };
  };
};

const FieldReferenceForm = ({
  referenceableNodes,
  confirmSwitchDataSource,
  setSelectedNode,
  selectedNode,
  texts: {
    datasource: { title: datasourceTitle, subtitle: datasourceSubtitle, label: datasourceLabel },
    referenceField: {
      title: referenceFieldTitle,
      subtitle: referenceFieldSubtitle,
      label: referenceFieldLabel,
      placeholderTooltip,
    },
  },
}: FieldReferenceFormProps) => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.FILTER>();
  const fieldReference = getFieldRef(nodeConfig);
  const conditionGroups = nodeConfig.parameters.conditionGroups;

  const [errors, setErrors] = useState<{ dataSource?: string; fieldReference?: string }>({});
  const [touched, setTouched] = useState<{ dataSource?: boolean; fieldReference?: boolean }>({});

  useEffect(() => {
    setErrors({});
    setTouched({});
  }, [nodeConfig.id]);

  const validationSchema = Yup.object({
    dataSource: Yup.object().required(cloudflowTexts.DATA_SOURCE_REQUIRED),
    fieldReference: Yup.object()
      .required(cloudflowTexts.FIELD_REFERENCE_REQUIRED)
      .test("not-empty", cloudflowTexts.FIELD_REFERENCE_REQUIRED, (value) => value && !isEmpty(value)),
  });

  const validate = (values) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      setErrors({});
      return {};
    } catch (err) {
      const validationErrors: { dataSource?: string; fieldReference?: string } = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path as keyof typeof validationErrors] = error.message;
          }
        });
      }
      setErrors(validationErrors);

      return validationErrors;
    }
  };

  const handleDataSourceChange = async (event: SelectChangeEvent<string>) => {
    const nodeId = event.target.value;
    const node = referenceableNodes.find((node) => node.id === nodeId);
    if (!node) return;

    const onConfirm = async () => {
      const newFieldReference = { referencedField: [], referencedNodeId: node.id };

      setSelectedNode(node);

      const validationErrors = validate({ dataSource: node, fieldReference: newFieldReference });
      const isValid = isEmpty(validationErrors);
      const statusMessage = isValid ? "" : Object.values(validationErrors).join(", ");

      updateNode((prevNode) => ({
        parameters: { ...prevNode.parameters, ...newFieldReference, conditionGroups: [] },
        statusMessage,
      }));
    };

    if (!selectedNode || (!fieldReference?.referencedField?.length && !conditionGroups?.length)) {
      await onConfirm();
    } else {
      await confirmSwitchDataSource(onConfirm, undefined);
    }
  };

  const handleFieldReferenceChange = (event: { target: { name: string; value: ReferencedNodeValue } }) => {
    const newFieldReference = {
      referencedNodeId: event.target.value.referencedNodeId || selectedNode?.id || "",
      referencedField: event.target.value.referencedField || [],
    };

    setTouched((prev) => ({ ...prev, fieldReference: true }));

    const validationErrors = validate({ dataSource: selectedNode, fieldReference: newFieldReference });

    const isValid = isEmpty(validationErrors);
    const statusMessage = isValid ? "" : Object.values(validationErrors).join(", ");

    updateNode((prevNode) => ({
      parameters: { ...prevNode.parameters!, ...newFieldReference },
      status: isValid ? NODE_STATUS.VALIDATED : NODE_STATUS.ERROR,
      statusMessage,
    }));
  };

  const handleBlur = (field: keyof typeof touched) => () => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const validationErrors = validate({ dataSource: selectedNode, fieldReference });

    const isValid = isEmpty(validationErrors);
    // TODO: rian what should we do with this validation info in status message

    // const statusMessage = isValid ? "" : Object.values(validationErrors).join(", ");

    updateNode({
      status: isValid ? NODE_STATUS.VALIDATED : NODE_STATUS.ERROR,
    });
  };

  return (
    <Stack
      sx={{
        mb: 1,
      }}
    >
      <Stack
        sx={{
          mb: 2,
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {datasourceTitle}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {datasourceSubtitle}
        </Typography>
      </Stack>
      <Stack
        sx={{
          mb: 3,
        }}
      >
        <FormControl sx={{ minWidth: 300 }} required error={!!(touched.dataSource && errors.dataSource)}>
          <InputLabel size="small" id="dataSourceLabel">
            {datasourceLabel}
          </InputLabel>
          <Tooltip title={referenceableNodes.length === 0 ? cloudflowTexts.ADD_ACTION_NODES : ""} placement="top-start">
            <Select
              input={<OutlinedInput size="small" label={cloudflowTexts.DATA_SOURCES} />}
              labelId="dataSourceLabel"
              required
              disabled={referenceableNodes.length === 0}
              name="dataSource"
              value={selectedNode?.id || ""}
              onChange={handleDataSourceChange}
              onBlur={handleBlur("dataSource")}
              renderValue={() => selectedNode && <FieldChip tokens={[selectedNode.name]} />}
            >
              {referenceableNodes.map((node) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.name}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>

          {touched.dataSource && errors.dataSource && <FormHelperText>{errors.dataSource}</FormHelperText>}
        </FormControl>
      </Stack>
      <Stack
        sx={{
          mb: 2,
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {referenceFieldTitle}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {referenceFieldSubtitle}
        </Typography>
      </Stack>
      {!selectedNode ? (
        <PlaceholderParam label={referenceFieldLabel} required placeholderTooltip={placeholderTooltip} />
      ) : (
        <ReferencedFieldStandalone
          value={fieldReference}
          name="fieldReference"
          label={cloudflowTexts.REFERENCED_FIELD}
          onChange={handleFieldReferenceChange}
          onBlur={handleBlur("fieldReference")}
          required
          helperText={touched.fieldReference && errors.fieldReference ? errors.fieldReference : ""}
          error={!!(touched.fieldReference && errors.fieldReference)}
          referenceableNodes={[selectedNode]}
        />
      )}
    </Stack>
  );
};

export default FieldReferenceForm;
