import { makeStyles } from "@mui/styles";
import NoSsr from "@mui/material/NoSsr";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    padding: 16,
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 5,
    bottom: 8,
    fontSize: 16,
    padding: 8,
  },
  paper: {
    position: "absolute",
    zIndex: 90,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

export default function CreatableSelectBox(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NoSsr>
        <Autocomplete
          freeSolo
          id="requester_select"
          options={props.options}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => {
            props.onChange(newValue);
          }}
          onInputChange={(event, newValue) => {
            props.onChange(newValue);
          }}
          sx={{ mt: 2 }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="medium"
              required
              label={props.placeholder}
              variant="outlined"
              helperText={props.helperText ?? " "}
              error={props?.error}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          )}
        />
      </NoSsr>
    </div>
  );
}
