import { useCallback, useEffect, useMemo, useState } from "react";

import { type AssetType } from "@doitintl/cmp-models";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import uniq from "lodash/uniq";
import { type DateTime } from "luxon";

import LoadingButton from "../../../../Components/LoadingButton";
import { assetTypeName } from "../../../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../../../utils/dialog";
import { texts } from "./texts";
import { type Invoice } from "./types";

type Props = {
  open: boolean;
  onCancel: (type?: AssetType, cancellationReason?: string) => Promise<void> | void;
  onClose: () => void;
  selectedCustomer: { id: string; name: string } | null;
  billingMonth: DateTime<true>;
  invoices: Invoice[];
};

export const CancelDialog = ({ open, onCancel, onClose, selectedCustomer, billingMonth, invoices }: Props) => {
  const { isMobile: xsDown } = useFullScreen("sm");
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState<AssetType | "all">("all");
  const [typeOptions, setTypeOptions] = useState<(AssetType | "all")[]>([]);
  const [reasonValue, setReasonValue] = useState<string>("");

  const issuedInvoices = useMemo(() => invoices.filter((invoice) => invoice.issuedAt), [invoices]);

  const handleCancel = useCallback(async () => {
    setLoading(true);
    try {
      await (selectedType !== "all" ? onCancel(selectedType, reasonValue) : onCancel());
    } finally {
      setLoading(false);
    }
    onClose();
  }, [onClose, onCancel, selectedType, reasonValue]);

  useEffect(() => {
    const types: (AssetType | "all")[] = ["all", ...uniq(issuedInvoices.map((invoice) => invoice.type))];
    if (types.length <= 2) {
      setSelectedType(types[1]);
    } else {
      setSelectedType("all");
    }

    setTypeOptions(types);
  }, [invoices, issuedInvoices]);

  const issuedInvoicesNumForType = useMemo(
    () => issuedInvoices.filter((invoice) => selectedType === "all" || invoice.type === selectedType).length,
    [selectedType, issuedInvoices]
  );

  return (
    <Dialog
      fullScreen={xsDown}
      open={open}
      onClose={preventOnCloseWhile(loading, onClose)}
      data-cy="cancel-invoices-dialog"
      maxWidth={false}
    >
      <DialogTitle>{texts.CANCEL_INVOICES_TEXT}</DialogTitle>
      <DialogContent sx={{ width: 600 }}>
        <DialogContentText component="div">
          <TextField
            select
            data-cy="invoice-type-select"
            sx={{ width: "300px" }}
            disabled={typeOptions.length <= 2}
            label="Invoice type to cancel"
            margin="normal"
            variant="outlined"
            value={selectedType}
            onChange={(event) => {
              setSelectedType(event.target.value as AssetType | "all");
            }}
            slotProps={{
              select: {
                MenuProps: {
                  MenuListProps: {
                    dense: true,
                  },
                },
              },
            }}
          >
            {typeOptions.map((type) => (
              <MenuItem key={type} value={type}>
                {type !== "all" ? assetTypeName(type) : "All types"}
              </MenuItem>
            ))}
          </TextField>
          <Box>
            <Typography
              data-cy="confirmation-text"
              sx={{
                mb: 1,
              }}
            >
              Are you sure you want to cancel {selectedType !== "all" ? `${assetTypeName(selectedType)} ` : ""}
              issued invoices?
            </Typography>
            <Typography
              sx={{
                mb: 1,
              }}
            >
              (Issued timestamp will be removed)
            </Typography>
            <Typography data-cy="customer-text">Customer: {selectedCustomer?.name}</Typography>
            <Typography data-cy="type-text">
              Type:{" "}
              {selectedType !== "all"
                ? assetTypeName(selectedType)
                : typeOptions
                    .filter((type) => type !== "all")
                    .map((type) => assetTypeName(type))
                    .join(", ")}
            </Typography>
            <Typography data-cy="invoice-number-text">Number of invoices: {issuedInvoicesNumForType}</Typography>
            <Typography data-cy="month-text">Month: {billingMonth.toFormat("LLL yyyy")}</Typography>
          </Box>
          <Box
            sx={{
              mt: 2,
            }}
          >
            <TextField
              data-cy="cancellation-reason"
              autoFocus
              label="Reason"
              variant="outlined"
              fullWidth
              value={reasonValue}
              onChange={(event) => {
                setReasonValue(event.target.value);
              }}
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="text" data-cy="close-dialog-btn" disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={handleCancel}
          disabled={loading}
          loading={loading}
          data-cy="confirm-cancel-btn"
          mixpanelEventId="dialog.delete"
        >
          {texts.CANCEL_INVOICES_TEXT}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;
